import styled from "styled-components";

import styleConst from "constants/style";
import { PAGE_CONTENT_WIDTH, PAGE_WIDTH } from "constants/layout";

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 700px;
`;

export const NoInternetConnectionPlaceholderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 700px;
`;

export const OverflowLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 130px;
  z-index: 10;

  width: 100%;
  height: 700px;

  background-color: ${styleConst.colors.white50};
`;

export const ScrollContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
  min-width: ${PAGE_WIDTH.SMALL_DESKTOP};

  overflow-y: auto;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 50px;
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 10px auto;

  width: ${PAGE_CONTENT_WIDTH.SMALL_DESKTOP};
`;

export const SectionTitleAdornment = styled.div`
  margin-right: 14px;

  width: 4px;
  height: 28px;
  background-color: ${styleConst.colors.secondary};
`;

export const SectionTitle = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;
  color: ${styleConst.colors.text};
  line-height: 48px;
`;
