import { parseISO, format, addDays, startOfDay, isSameDay } from "date-fns";
import { summary, streakRanges } from "date-streaks";

import { SERVER_DATE_FORMAT } from "utils/date";
import { UTCToZonedTime } from "utils/timezones";

type MarkedDates = {
  [key: string]: {
    startingDay?: boolean;
    endingDay?: boolean;
    scheduled?: boolean;
    completed?: boolean;
  };
};

type Range = {
  duration: number;
  start: Date;
  end: Date | null;
};

type MarkingDates = {
  [date: string]: any;
};

const getUniqDates = (items: string[]) => {
  return items.filter(
    (item, index) =>
      items.findIndex(date => isSameDay(parseISO(item), parseISO(date))) ===
      index,
  );
};

export const getStreak = (completed: string[]) => {
  const dates = Object.keys(getMarkedCompleted(completed)).map(date =>
    parseISO(date),
  );
  return summary({ dates }).longestStreak;
};

const getRange = (completed: string[]) => {
  const dates = getUniqDates(completed).map(date => startOfDay(parseISO(date)));
  return streakRanges({ dates });
};

const getMarkedScheduled = (scheduled: string[]) => {
  const dates: MarkedDates = {};

  const mappedScheduled = scheduled.map(date => UTCToZonedTime(parseISO(date)));

  mappedScheduled.forEach(date => {
    dates[format(date, SERVER_DATE_FORMAT)] = {
      scheduled: true,
    };
  });

  return dates;
};

const getMarkedCompleted = (completed: string[]) => {
  const dates: MarkedDates = {};

  const mappedCompleted = completed.map(date =>
    UTCToZonedTime(parseISO(date)).toISOString(),
  );

  const ranges = getRange(mappedCompleted);

  Object.values(ranges).forEach(({ duration, start }: Range) => {
    if (duration === 1) {
      dates[format(start, SERVER_DATE_FORMAT)] = {
        completed: true,
        startingDay: true,
        endingDay: true,
      };
    } else {
      for (let i = 0; i < duration; i++) {
        dates[format(addDays(start, i), SERVER_DATE_FORMAT)] = {
          completed: true,
          startingDay: i === 0,
          endingDay: i === duration - 1,
        };
      }
    }
  });

  return dates;
};

export const getMarkedDates = ({
  scheduled,
  completed,
}: {
  scheduled: string[];
  completed: string[];
}): MarkingDates => {
  const markedScheduled = getMarkedScheduled(scheduled);
  const markedCompleted = getMarkedCompleted(completed);

  return { ...markedScheduled, ...markedCompleted };
};
