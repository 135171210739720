import React from "react";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import ProfileStore from "stores/ProfileStore";

import ButtonInline from "components/ButtonInline";
import Input from "components/Input";
import Spinner from "components/Spinner";
import { SnackbarTypeValues } from "components/Snackbar";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";
import { NoInternetConnectionError } from "services/api";

import { LINKS } from "constants/links";
import { PHONE_MASK } from "constants/masks";
import styleConst from "constants/style";

import {
  Buttons,
  ButtonWrapper,
  Container,
  Column,
  Form,
  LoaderContainer,
  Row,
  Title,
  SubmitButton,
} from "./styles";

type Props = {} & RouteComponentProps & SnackbarContextProps;

type Field = "phone" | "email" | "intagramHandle";

interface Values {
  phone?: string;
  email?: string;
  intagramHandle?: string;
}

@observer
class Contacts extends React.Component<Props> {
  @observable isNoInternetConnectionError: boolean = false;

  @observable values: Values = {
    phone: "",
    email: "",
    intagramHandle: "",
  };

  @observable error: Values = {
    phone: "",
    email: "",
    intagramHandle: "",
  };

  @computed get isLoading() {
    return ProfileStore.isContactsLoading;
  }

  async componentDidMount() {
    try {
      this.values = await ProfileStore.fetchContacts();
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }
  }

  onChange =
    (field: Field) =>
    (text: string = "") => {
      this.values[field] = text.trim();
      this.error[field] = "";
    };

  onUpdateEmailPress = () => {
    window.open(LINKS.UPDATE_EMAIL);
  };

  onSubmit = async () => {
    const { setMessage } = this.props;

    try {
      this.values = await ProfileStore.updateContacts(this.values);
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    }
  };

  render() {
    if (this.isNoInternetConnectionError) {
      return <NoInternetConnectionPlaceholder type={"element"} />;
    }

    if (this.isLoading) {
      return (
        <LoaderContainer>
          <Spinner color={styleConst.colors.primary} large />
        </LoaderContainer>
      );
    }

    return (
      <Container>
        <Title>My Contacts</Title>
        <Form>
          <Row>
            <Column>
              <Input
                label="Phone Number"
                value={this.values.phone}
                onChangeText={this.onChange("phone")}
                error={this.error.phone}
                mask={PHONE_MASK}
              />
            </Column>
            <Column>
              <Input
                label="Instagram Handle"
                value={this.values.intagramHandle}
                onChangeText={this.onChange("intagramHandle")}
                error={this.error.intagramHandle}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Input
                label="Email"
                value={this.values.email}
                onChangeText={this.onChange("email")}
                error={this.error.phone}
                disabled
              />
            </Column>
          </Row>

          <Buttons>
            <ButtonWrapper>
              <ButtonInline
                text="Update Email"
                onPress={this.onUpdateEmailPress}
                fontSize={14}
                fontWeight={500}
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <SubmitButton
                loading={ProfileStore.isContactsLoading}
                fontWeight="bold"
                text="Done"
                onClick={this.onSubmit}
              />
            </ButtonWrapper>
          </Buttons>
        </Form>
      </Container>
    );
  }
}

export default withGlobalSnackbar(withRouter(Contacts));
