import styled, { css } from "styled-components";
import { Swiper as DefaultSwiper } from "swiper/react";

import Button from "components/Button";
import { IconRight } from "components/Button/styles";

import { MEDIA_QUERIES } from "constants/layout";
import styleConst from "constants/style";

interface ISwiperWrapper {
  isMoreLoading?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  width: 100%;

  ${MEDIA_QUERIES.DESKTOP} {
    margin: 0 -11px;
  }
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ControlWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  z-index: 10;

  width: 100%;
  height: 100%;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  padding-bottom: 40px;
`;

export const PlaceholderContainer = styled.div`
  padding: 4px 0 40px;
`;

export const TitleAdornment = styled.div`
  margin-right: 14px;

  width: 4px;
  height: 28px;
  background-color: ${styleConst.colors.secondary};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 11px 28px;
`;

export const Title = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;
  color: ${styleConst.colors.text};
  line-height: 48px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SwiperContainer = styled.div`
  position: relative;

  height: 100%;
  width: 100%;
`;

export const SwiperWrapper = styled.div<ISwiperWrapper>`
  ${({ isMoreLoading }) =>
    isMoreLoading &&
    css`
      opacity: 0.3;
    `}
`;

export const Swiper = styled(DefaultSwiper)`
  width: 100%;
`;

export const ViewAllButton = styled(Button)`
  margin-top: 2px;
  padding-right: 0px;

  ${IconRight} {
    margin-left: 3px;
    margin-bottom: 2px;
  }
`;
