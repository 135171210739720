import styled from "styled-components";

import styleConst from "constants/style";
import { PAGE_CONTENT_WIDTH } from "constants/layout";

export const ExtraItems = styled.div`
  display: flex;
  padding-left: 38px;

  width: 25%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 48px;

  background: ${styleConst.colors.dark5};
  padding: 20px 0;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: ${PAGE_CONTENT_WIDTH.SMALL_DESKTOP};
`;

export const Title = styled.div`
  width: 62%;

  font-size: 18px;
  font-weight: 700;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.dark};
`;

export const SuggestContainer = styled.div`
  align-items: center;
  justify-content: center;
  padding: 0 40px;
`;

export const ColumnTitle = styled.div`
  text-align: center;

  min-width: 80px;
  padding: 0 10px;

  font-weight: 400;
  font-size: 14px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.black50};
`;

export const ColumnTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  width: 48%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  width: 75%;
`;
