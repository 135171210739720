import * as React from "react";

type Props = {
  width?: number;
  height?: number;
};

const IPhotoPlaceholder = ({ width = 40, height = 40 }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none">
      <circle
        cx="20"
        cy="20"
        r="19"
        fill="#84CFCB"
        stroke="white"
        strokeWidth="2"
      />
      <circle cx="20" cy="18" r="8" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.815 34.4613C30.2244 37.8926 25.3579 40 19.9993 40C14.6406 40 9.7742 37.8926 6.18359 34.4613C9.48547 30.5121 14.449 28 19.9993 28C25.5496 28 30.5131 30.5121 33.815 34.4613Z"
        fill="white"
      />
    </svg>
  );
};

export default IPhotoPlaceholder;
