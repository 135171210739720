import styled, { css } from "styled-components";

interface IContainer {
  color?: string;
  hoverColor?: string;
}

export const Container = styled.div<IContainer>`
  display: flex;

  ${({ color }) =>
    color &&
    css`
      & path,
      & use {
        fill: ${color};
      }
    `}

  ${({ color, hoverColor }) =>
    (color || hoverColor) &&
    css`
      &:hover path,
      &:hover use {
        fill: ${hoverColor ?? color};
      }
    `}
`;
