import React from "react";

import I24CrossFilled from "assets/icons/I24CrossFilled";

import styleConst from "constants/style";

import { Action, Actions, ActionTooltip } from "../styles";

type Props = {
  id: number;
  onRemove: (id: number) => void;
};

const MyFood = (props: Props) => {
  const { id, onRemove } = props;

  const onPress = (action: (id: number) => void) => (event: any) => {
    event.stopPropagation();
    action(id);
  };

  return (
    <Actions>
      <Action onClick={onPress(onRemove)}>
        <I24CrossFilled color={styleConst.colors.black25} />
        <ActionTooltip>{"Remove"}</ActionTooltip>
      </Action>
    </Actions>
  );
};

export default MyFood;
