import styled from "styled-components";

import styleConst from "constants/style";

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 48px;

  background: ${styleConst.colors.dark5};
  border-radius: 10px;
  padding: 20px;
`;

export const Title = styled.div`
  width: 56%;

  font-size: 18px;
  font-weight: 700;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.dark};
`;

export const SuggestContainer = styled.div`
  align-items: center;
  justify-content: center;
  padding: 0 40px;
`;

export const ColumnTitle = styled.div`
  text-align: center;

  width: 80px;
  padding: 0 10px;

  font-weight: 400;
  font-size: 14px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.black50};
`;

export const ColumnTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  width: 44%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  width: 75%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: calc(100vh - 100px - 345px);
`;
