import React from "react";

import { Container, Label, Value } from "./styles";

type Props = {
  className?: any;
  label?: string;
  value?: string;
};

const InlineListItem: React.FC<Props> = ({ className, label, value }) => {
  return (
    <Container className={className}>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Container>
  );
};

export default React.memo(InlineListItem);
