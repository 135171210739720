import React from "react";

import styleConst from "constants/style";

import {
  Container,
  IconLeft,
  IconRight,
  Label,
  LabelContainer,
} from "./styles";

type Props = {
  color?: string;
  fontSize?: number;
  fontWeight?: number;
  disabled?: boolean;
  loading?: boolean;
  iconLeft?: React.ReactElement | null;
  iconRight?: React.ReactElement | null;
  onPress?: (event: any) => void;
  text?: string;
  style?: any;
};

const ButtonInline = ({
  color = styleConst.colors.primary,
  fontSize = 16,
  fontWeight = 500,
  disabled,
  loading,
  iconLeft,
  iconRight,
  onPress: onButtonPress,
  text,
  style,
}: Props) => {
  const onClick = (event: any) =>
    !disabled && !loading ? onButtonPress?.(event) : undefined;

  return (
    <Container
      style={style}
      disabled={disabled}
      onClick={onClick}
      activeOpacity={disabled ? 1 : 0.4}
    >
      <LabelContainer>
        {iconLeft ? <IconLeft>{iconLeft}</IconLeft> : null}
        {text ? (
          <Label color={color} fontSize={fontSize} fontWeight={fontWeight}>
            {text}
          </Label>
        ) : null}
        {iconRight ? <IconRight>{iconRight}</IconRight> : null}
      </LabelContainer>
    </Container>
  );
};

export const NegativeInlineButton = (props: Props) => (
  <ButtonInline {...props} color={styleConst.colors.red} />
);

export default ButtonInline;
