import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24AddFilled = ({ color = styleConst.colors.white }: Props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      fill={color}
      fillRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-15a1 1 0 011 1v3h3a1 1 0 010 2h-3v3a1 1 0 01-2 0v-3H8a1 1 0 010-2h3V8a1 1 0 011-1z"
      clipRule="evenodd"
    />
  </svg>
);

export default I24AddFilled;
