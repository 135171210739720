import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24CrossFilled = ({ color = styleConst.colors.white }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.46492 8.05022C9.0744 7.65969 8.44123 7.65969 8.05071 8.05022C7.66018 8.44074 7.66018 9.07391 8.05071 9.46443L10.5862 12L8.05071 14.5355C7.66018 14.926 7.66018 15.5592 8.05071 15.9497C8.44123 16.3402 9.0744 16.3402 9.46492 15.9497L12.0005 13.4142L14.536 15.9497C14.9265 16.3402 15.5597 16.3402 15.9502 15.9497C16.3407 15.5592 16.3407 14.926 15.9502 14.5355L13.4147 12L15.9502 9.46443C16.3407 9.07391 16.3407 8.44074 15.9502 8.05022C15.5597 7.65969 14.9265 7.65969 14.536 8.05022L12.0005 10.5858L9.46492 8.05022Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
      fill={color}
    />
  </svg>
);

export default I24CrossFilled;
