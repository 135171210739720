import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24RadioButtonOff = ({ color = styleConst.colors.white }: Props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      fill={color}
      fillRule="evenodd"
      d="M12 20a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
      clipRule="evenodd"
    />
  </svg>
);

export default I24RadioButtonOff;
