import React from "react";
import { observer } from "mobx-react";

import Card from "../Card";

import { Container, ListContainer, Title } from "./styles";

type Props = {
  title?: string;
  items: any[];
  onCardClick?: (item: any) => void;
  rightIcon?: React.ReactElement;
};

@observer
class List extends React.Component<Props> {
  render() {
    const { title, items, onCardClick } = this.props;

    return items.length ? (
      <Container>
        {title ? <Title>{title}</Title> : null}
        <ListContainer>
          {items.map((item, index) => (
            <Card
              key={`${item.id}-${index}`}
              date={item.date}
              title={item.label || item.title}
              duration={item.duration}
              imageUrl={item.imageUrl}
              onClick={() => {
                onCardClick?.(item);
              }}
              height={155}
              width={281}
            />
          ))}
        </ListContainer>
      </Container>
    ) : null;
  }
}

export default List;
