import React from "react";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import I40Facebook from "assets/icons/I40Facebook";
import I40Instagram from "assets/icons/I40Instagram";
import IGoogleStore from "assets/icons/IGoogleStore";
import IAppStore from "assets/icons/IAppStore";
import I200TextLogo from "assets/icons/I200TextLogo";

import { Footer } from "components/Layout";

import ROUTES from "navigation/routes";

import { LINKS } from "constants/links";
import { SUPPORT_EMAIL } from "constants/support";

import {
  BottomContentContainer,
  BottomContent,
  BottomTextWrapper,
  CenterContainer,
  CenterColumn,
  Gap,
  Icons,
  IconWrapper,
  Link,
  Logo,
  Menu,
  SideContainer,
  Text,
  Title,
} from "./styles";

type Props = RouteComponentProps & {
  disabled?: boolean;
};

const MAIN_MENU_ITEMS = [
  { title: "Today", path: ROUTES.TODAY },
  { title: "Nutrition", path: ROUTES.NUTRITION_FOOD_LOG },
  { title: "Studio", path: ROUTES.STUDIO },
  { title: "Resources", path: ROUTES.RESOURCES },
];

const PROFILE_ITEMS = [
  { title: "My Macro Goals", path: ROUTES.MACROGOALS },
  { title: "My Measurements", path: ROUTES.MEASUREMENTS },
];

class AppFooter extends React.Component<Props> {
  renderLeft = () => (
    <SideContainer left>
      <Logo>
        <I200TextLogo />
      </Logo>
      <Text>
        The premier virtual fitness and nutrition program, the FASTer Way helps
        clients burn fat, prevent disease, and thrive with energy and
        confidence. Visit us on social media below!
      </Text>
      <Icons left>
        <IconWrapper onClick={this.openExternalLink(LINKS.FACEBOOK)}>
          <I40Facebook />
        </IconWrapper>
        <IconWrapper onClick={this.openExternalLink(LINKS.INSTAGRAM)}>
          <I40Instagram />
        </IconWrapper>
      </Icons>
    </SideContainer>
  );

  renderCenter = () => (
    <CenterContainer>
      <CenterColumn>
        <Title>Main Menu</Title>
        {MAIN_MENU_ITEMS.map(item => (
          <Menu key={`footer-${item.title}`}>
            <Link onClick={this.openInternalLink(item.path)}>{item.title}</Link>
          </Menu>
        ))}
      </CenterColumn>
      <CenterColumn>
        <Title>Profile</Title>
        {PROFILE_ITEMS.map(item => (
          <Menu key={`footer-${item.title}`}>
            <Link onClick={this.openInternalLink(item.path)}>{item.title}</Link>
          </Menu>
        ))}
      </CenterColumn>
    </CenterContainer>
  );

  renderRight = () => (
    <SideContainer right>
      <Title>Download App</Title>
      <Text>
        Download our comprehensive client app to unlock exclusive features!
      </Text>
      <Icons>
        <IconWrapper
          onClick={this.openExternalLink(LINKS.GOOGLE_PLAY_LINK)}
          storeIcon
        >
          <IGoogleStore />
        </IconWrapper>
        <IconWrapper
          onClick={this.openExternalLink(LINKS.APP_STORE_LINK)}
          storeIcon
        >
          <IAppStore />
        </IconWrapper>
      </Icons>
    </SideContainer>
  );

  renderBottom = () => (
    <BottomContentContainer>
      <BottomContent>
        <BottomTextWrapper>
          <Text small>© 2022 FasterWay. All Rights Reserved</Text>
        </BottomTextWrapper>
      </BottomContent>
      <BottomContent>
        <BottomTextWrapper>
          <Link small href={`mailto:${SUPPORT_EMAIL}`}>
            Support
          </Link>
        </BottomTextWrapper>
        <BottomTextWrapper>
          <Link small onClick={this.openExternalLink(LINKS.PRIVACY_POLICY)}>
            Privacy Policy
          </Link>
        </BottomTextWrapper>
        <BottomTextWrapper>
          <Link
            small
            onClick={this.openExternalLink(LINKS.TERMS_AND_CONDITIONS)}
          >
            Terms and Conditions
          </Link>
        </BottomTextWrapper>
      </BottomContent>
      <BottomContent>
        <Gap />
      </BottomContent>
    </BottomContentContainer>
  );

  openExternalLink = (link: string) => () => {
    window.open(link);
  };

  openInternalLink = (path: string) => () => {
    this.props.history.push(generatePath(path));
  };

  render() {
    const { disabled } = this.props;
    return (
      <Footer
        centerContent={this.renderCenter()}
        leftContent={this.renderLeft()}
        rightContent={this.renderRight()}
        bottomContent={this.renderBottom()}
        disabled={disabled}
      />
    );
  }
}

export default withRouter(AppFooter);
