import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IPreviewBadgeContainer {
  top?: number;
  left?: number;
}

export const Badge = styled.p`
  padding: 4px;

  font-size: 12px;
  line-height: 14px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.primaryLight};

  border-radius: 2px;

  background-color: ${styleConst.colors.primary70};

  overflow: hidden;
`;

export const PreviewBadgeContainer = styled.div<IPreviewBadgeContainer>`
  position: absolute;

  ${({ top = 16, left = 20 }) =>
    css`
      top: ${top}px;
      left: ${left}px;
    `};

  z-index: 5;
`;

export const Wrapper = styled.div`
  position: relative;

  flex: 1;
  flex-shrink: 0;
`;
