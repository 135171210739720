import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { Instance } from "mobx-state-tree";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import StudioContentStore from "stores/StudioContentStore";

import { Page } from "components/Layout";
import WorkoutsArea from "components/Studio/Workouts";

import NavList from "pages/Studio/NavList";

import { capitalize } from "utils/common";

import ROUTES from "navigation/routes";

import { WEEK } from "constants/common";
import { CONTENT_STORE_TYPE } from "constants/studio";

import { Background, Wrapper } from "./styles";

type Props = {} & RouteComponentProps<{
  categoryId: string;
  workoutGroup: string;
}>;

@observer
class Workouts extends React.Component<Props> {
  historyUnlistenCallback: (() => void) | undefined = undefined;

  @observable store: Instance<typeof StudioContentStore> | null = null;

  get url() {
    const {
      match: { url },
    } = this.props;

    return url;
  }

  get categoryId() {
    const {
      match: {
        params: { categoryId },
      },
    } = this.props;

    return Number.parseInt(categoryId);
  }

  get workoutGroup() {
    const {
      match: {
        params: { workoutGroup },
      },
    } = this.props;

    return workoutGroup;
  }

  get isFavorites() {
    return !this.categoryId;
  }

  get isWeekCategory() {
    return Object.values(WEEK).includes(this.workoutGroup);
  }

  get dayOfWeek() {
    return Object.keys(WEEK).find(key => WEEK[key] === this.workoutGroup);
  }

  get workoutGroupIsNewOrAll() {
    return [
      CONTENT_STORE_TYPE.STANDARD_NEWEST,
      CONTENT_STORE_TYPE.STANDARD_ALL,
    ].includes(this.workoutGroup);
  }

  get workoutGroupIsValid() {
    return (
      (this.isWeekCategory && this.dayOfWeek !== undefined) ||
      this.isFavorites ||
      this.workoutGroupIsNewOrAll
    );
  }

  @computed
  get title() {
    return this.isFavorites ? this.store?.title : capitalize(this.workoutGroup);
  }

  componentDidMount() {
    const { history } = this.props;

    this.historyUnlistenCallback = history.listen(() => {
      if (!this.workoutGroupIsValid) {
        history.replace(generatePath(ROUTES.STUDIO));
      }
    });
  }

  componentWillUnmount() {
    this.historyUnlistenCallback?.();
  }

  onNavigateToStudio = () => {
    const { history } = this.props;
    history.replace(generatePath(ROUTES.STUDIO));
  };

  onNavigateToWorkout = (workoutGroup?: string) => (workout: any) => {
    const { history } = this.props;
    const { id: workoutId } = workout;

    history.push(
      generatePath(
        this.isFavorites
          ? ROUTES.STUDIO_WORKOUT_FAVORITES_WORKOUT_DETAILS
          : ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUTS_WORKOUT_DETAILS,
        {
          categoryId: this.categoryId,
          workoutGroup: workoutGroup || this.workoutGroup,
          workoutId,
        },
      ),
    );
  };

  render() {
    const { history } = this.props;
    return (
      <Page
        isLoading={this.store?.isLoading}
        title={this.title}
        contentBackground={<Background />}
        topNavigation={<NavList />}
      >
        <Wrapper>
          <WorkoutsArea
            categoryId={this.categoryId}
            workoutGroup={this.workoutGroup}
            onNavigateToWorkout={this.onNavigateToWorkout}
            onNavigateToStudio={this.onNavigateToStudio}
            url={this.url}
            history={history}
          />
        </Wrapper>
      </Page>
    );
  }
}

export default withRouter(Workouts);
