import React from "react";

import { Container, Tab, Title } from "./styles";

type Props = {
  tabs: ReadonlyArray<string>;
  activeTab: string;
  onClick: (tab: string) => void;
};

const Tabs: React.FC<Props> = ({ tabs, activeTab, onClick: onTabClick }) => {

  const renderTabs = React.useCallback(() => {
    return tabs.map(tab => {
      const isActive = activeTab === tab;
      return (
        <Tab key={tab} isActive={isActive} onClick={() => onTabClick(tab)}>
          <Title isActive={isActive}>{tab}</Title>
        </Tab>
      );
    });
  }, [tabs, activeTab]);

  return <Container>{renderTabs()}</Container>;
};

export default React.memo(Tabs);
