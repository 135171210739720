import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";

import WaterCalculatorStore from "stores/WaterCalculatorStore";

import Button from "components/Button";
import Input from "components/Input";
import { SnackbarTypeValues } from "components/Snackbar";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";
import { NoInternetConnectionError } from "services/api";

import {
  Container,
  Content,
  ButtonWrapper,
  Title,
  errorTextStyles,
  ItemWrapper,
} from "./styles";

type Props = {
  onClose: () => void;
} & SnackbarContextProps;

@observer
class WaterCalculatorPopup extends React.Component<Props> {
  @observable weight: string = "";
  @observable minutesOfActivity: string = "";
  @observable isLoading: boolean = false;
  @observable isNoInternetConnectionError: boolean = false;

  @observable error = {
    weight: "",
    minutesOfActivity: "",
  };

  async componentDidMount() {
    try {
      await WaterCalculatorStore.fetch();
      this.weight = WaterCalculatorStore.prefilledWeight;
      this.minutesOfActivity = WaterCalculatorStore.prefilledMinutesOfActivity;
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }
  }

  onClick = async () => {
    const { onClose, setMessage } = this.props;
    try {
      this.isLoading = true;

      await WaterCalculatorStore.publishAnswers({
        weight: this.weight,
        minutesOfActivity: this.minutesOfActivity,
      });
      onClose();
    } catch (error: any) {
      console.log(`error`, error);
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    } finally {
      this.isLoading = false;
    }
  };

  onWeightChange = (value: string) => {
    this.weight = value;
  };

  onMinutesOfActivityChange = (value: string) => {
    this.minutesOfActivity = value;
  };

  render() {
    const { onClose } = this.props;

    return (
      <Container>
        {this.isNoInternetConnectionError ? (
          <NoInternetConnectionPlaceholder type={"popup"} onSubmit={onClose} />
        ) : (
          <Content>
            <Title>Calculate your daily water goal</Title>

            <ItemWrapper>
              <Input
                label="Your weight in pounds"
                value={this.weight}
                onChangeText={this.onWeightChange}
                error={this.error.weight}
                errorTextStyles={errorTextStyles}
                numbersOnly
                onlyPositiveNumbers
                required
              />
            </ItemWrapper>
            <ItemWrapper>
              <Input
                label={"Your daily activity level in minutes"}
                value={this.minutesOfActivity}
                onChangeText={this.onMinutesOfActivityChange}
                error={this.error.minutesOfActivity}
                errorTextStyles={errorTextStyles}
                numbersOnly
                onlyPositiveNumbers
                required
              />
            </ItemWrapper>

            <ButtonWrapper>
              <Button
                height={50}
                onClick={this.onClick}
                text={"Done"}
                loading={this.isLoading}
                disabled={
                  !this.weight ||
                  !this.minutesOfActivity ||
                  !!this.error.weight ||
                  !!this.error.minutesOfActivity
                }
              />
            </ButtonWrapper>
          </Content>
        )}
      </Container>
    );
  }
}

export default withGlobalSnackbar(WaterCalculatorPopup);
