import React from "react";

import Button from "components/Button";

import { ButtonWrapper, Container, Content, Text, Title } from "./styles";

type Props = {
  title: string;
  description: string;
  onClose: () => void;
};

class AlertPopup extends React.Component<Props> {
  onClick = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { title, description } = this.props;
    return (
      <Container>
        <Content top>
          <Title>{title}</Title>
        </Content>
        <Content bottom>
          <Text>{description}</Text>
          <ButtonWrapper>
            <Button text={"Ok"} onClick={this.onClick} height={50} />
          </ButtonWrapper>
        </Content>
      </Container>
    );
  }
}

export default AlertPopup;
