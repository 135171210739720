import { types } from "mobx-state-tree";

import {
  PERMISSION_TYPE,
  PUBLISH_STATUS,
  STRUCTURE_TYPE,
} from "constants/common";

import { ImageSize } from "models";

export const Category = types
  .model({
    id: types.number,
    title: types.maybe(types.string),
    subTitle: types.maybe(types.string),
    imageUrl: types.maybe(types.string),
    startDate: types.maybe(types.string),
    structureType: types.optional(
      types.enumeration(Object.values(STRUCTURE_TYPE)),
      STRUCTURE_TYPE.STANDARD,
    ),
    permission: types.optional(
      types.enumeration(Object.values(PERMISSION_TYPE)),
      PERMISSION_TYPE.FULL_ACCESS,
    ),
    publishStatus: types.enumeration(Object.values(PUBLISH_STATUS)),
    images: types.array(ImageSize),
  })
  .views(self => ({
    get isWeekStructure() {
      return self.structureType === STRUCTURE_TYPE.WEEK;
    },
    get isPermissionPreview() {
      return self.permission === PERMISSION_TYPE.PREVIEW;
    },
    get isPublishStatusPreview() {
      return self.publishStatus === PUBLISH_STATUS.PREVIEW;
    },
  }));

export const MacroNutrients = types.model({
  calories: types.maybe(types.number),
  carbs: types.maybe(types.number),
  fiber: types.maybe(types.number),
  fat: types.maybe(types.number),
  protein: types.maybe(types.number),
  sugar: types.maybe(types.number),
  saturatedFat: types.maybe(types.number),
  transFat: types.maybe(types.number),
  cholesterol: types.maybe(types.number),
  sodium: types.maybe(types.number),
  vitaminA: types.maybe(types.number),
  vitaminC: types.maybe(types.number),
  calcium: types.maybe(types.number),
  iron: types.maybe(types.number),
  potassium: types.maybe(types.number),
  magnesium: types.maybe(types.number),
  zinc: types.maybe(types.number),
  vitaminD: types.maybe(types.number),
});

export const Serving = types.model({
  id: types.maybe(types.string),
  serving: types.maybe(types.number),
  unit: types.maybe(types.string),
  description: types.maybe(types.string),
  equivalent: types.maybe(types.number),
  amount: types.maybe(types.number),
  nutritionalInfo: MacroNutrients,
});

export const Ingredient = types.model({
  id: types.maybe(types.union(types.string, types.number)),
  brand: types.maybe(types.string),
  barcode: types.maybe(types.string),
  title: types.maybe(types.string),
  unit: types.maybe(types.string),
  name: types.maybe(types.string),
  info: types.maybe(types.string),
  servings: types.array(Serving),
  isFasterWayApproved: types.optional(types.boolean, false),
  isFavorite: types.maybe(types.boolean),
  isArchived: types.maybe(types.boolean),
  favoriteId: types.maybe(types.number),
});

export const Recipe = types
  .model("Recipe", {
    id: types.number,
    day: types.maybe(types.number),
    title: types.string,
    imageUrl: types.string,
    duration: types.maybe(types.number),
    instruction: types.maybe(types.string),
    macronutrients: types.maybe(MacroNutrients),
    ingredients: types.array(Ingredient),
    isFavorite: types.optional(types.boolean, false),
    publishStatus: types.optional(
      types.enumeration(Object.values(PUBLISH_STATUS)),
      PUBLISH_STATUS.PREVIEW,
    ),
    permission: types.optional(
      types.enumeration(Object.values(PERMISSION_TYPE)),
      PERMISSION_TYPE.FULL_ACCESS,
    ),
    images: types.array(ImageSize),
    isArchived: types.maybe(types.boolean),
  })
  .views(self => ({
    get isPublishStatusPreview() {
      return self.publishStatus === PUBLISH_STATUS.PREVIEW;
    },
    get isPermissionPreview() {
      return self.permission === PERMISSION_TYPE.PREVIEW;
    },
  }));

export const UserRecipe = types.model("UserRecipe", {
  id: types.number,
  name: types.string,
  created: types.string,
  macronutrients: MacroNutrients,
  isArchived: types.maybe(types.boolean),
});
