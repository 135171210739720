import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I32Union = ({ color = styleConst.colors.white }: Props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 6.66666C4 5.93028 4.59695 5.33333 5.33333 5.33333H26.6667C27.403 5.33333 28 5.93028 28 6.66666C28 7.40304 27.403 7.99999 26.6667 7.99999H5.33333C4.59695 7.99999 4 7.40304 4 6.66666Z"
      fill={color}
    />
    <path
      d="M4 14.6667C4 13.9303 4.59695 13.3333 5.33333 13.3333H26.6667C27.403 13.3333 28 13.9303 28 14.6667C28 15.403 27.403 16 26.6667 16H5.33333C4.59695 16 4 15.403 4 14.6667Z"
      fill={color}
    />
    <path
      d="M5.33333 21.3333C4.59695 21.3333 4 21.9303 4 22.6667C4 23.403 4.59695 24 5.33333 24H16C16.7364 24 17.3333 23.403 17.3333 22.6667C17.3333 21.9303 16.7364 21.3333 16 21.3333H5.33333Z"
      fill={color}
    />
    <path
      d="M20 22.6667C20 21.9303 20.597 21.3333 21.3333 21.3333H22.6667V20C22.6667 19.2636 23.2636 18.6667 24 18.6667C24.7364 18.6667 25.3333 19.2636 25.3333 20V21.3333H26.6667C27.403 21.3333 28 21.9303 28 22.6667C28 23.403 27.403 24 26.6667 24H25.3333V25.3333C25.3333 26.0697 24.7364 26.6667 24 26.6667C23.2636 26.6667 22.6667 26.0697 22.6667 25.3333V24H21.3333C20.597 24 20 23.403 20 22.6667Z"
      fill={color}
    />
  </svg>
);

export default I32Union;
