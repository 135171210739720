import { types } from "mobx-state-tree";

import StudioContentStore from "stores/StudioContentStore";

import {
  PERMISSION_TYPE,
  PUBLISH_STATUS,
  STRUCTURE_TYPE,
} from "constants/common";

import { ImageSize } from "models";

export const Standard = types.model("Standard", {
  favorites: types.late(() => StudioContentStore),
  newest: types.late(() => StudioContentStore),
  all: types.late(() => StudioContentStore),
});

export const Week = types.model("Week", {
  monday: types.late(() => StudioContentStore),
  tuesday: types.late(() => StudioContentStore),
  wednesday: types.late(() => StudioContentStore),
  thursday: types.late(() => StudioContentStore),
  friday: types.late(() => StudioContentStore),
  saturday: types.late(() => StudioContentStore),
  sunday: types.late(() => StudioContentStore),
});

export const Category = types
  .model("Category", {
    id: types.number,
    title: types.maybe(types.string),
    subTitle: types.maybe(types.string),
    imageUrl: types.maybe(types.string),
    startDate: types.maybe(types.string),
    structureType: types.optional(
      types.enumeration(Object.values(STRUCTURE_TYPE)),
      STRUCTURE_TYPE.STANDARD,
    ),
    week: types.maybe(Week),
    standard: types.maybe(Standard),
    permission: types.optional(
      types.enumeration(Object.values(PERMISSION_TYPE)),
      PERMISSION_TYPE.FULL_ACCESS,
    ),
    publishStatus: types.enumeration(Object.values(PUBLISH_STATUS)),
    images: types.array(ImageSize),
  })
  .views(self => ({
    get isWeekStructure() {
      return self.structureType === STRUCTURE_TYPE.WEEK;
    },
    get isPermissionPreview() {
      return self.permission === PERMISSION_TYPE.PREVIEW;
    },
    get isPublishStatusPreview() {
      return self.publishStatus === PUBLISH_STATUS.PREVIEW;
    },
  }));
