import React from "react";
import { computed } from "mobx";
import { observer } from "mobx-react";
import { SnapshotOrInstance } from "mobx-state-tree";

import Input from "components/Input";

import { PHONE_MASK } from "constants/masks";

import { Answer, Field } from "models/Questionnaire";

import { postProcessValue } from "utils/question";

import { QuestionContainer, RequiredLabel } from "../styles";

type FieldItem = SnapshotOrInstance<typeof Field>;
type AnswerItem = SnapshotOrInstance<typeof Answer>;

type Props = {
  field: FieldItem;
  onAnswer: (answer: AnswerItem) => void;
  parentFieldName?: string;
  number?: boolean;
  password?: boolean;
  multiline?: boolean;
  store: any;
};

@observer
class Text extends React.Component<Props> {
  @computed get answer() {
    const { field, store } = this.props;
    return store.getAnswersByField(field.name);
  }

  get mask() {
    const { field } = this.props;
    return field.name === "phone" ? PHONE_MASK : undefined;
  }

  get type() {
    const { field, number, password } = this.props;

    if (number) {
      return "number";
    }
    if (password) {
      return "password";
    }
    if (field.name === "phone") {
      return "tel";
    }
    if (field.name === "email") {
      return "email";
    }
  }

  get autoCapitalize() {
    const { field } = this.props;
    if (field.name === "email" || field.name === "instagram_handle") {
      return "none";
    }

    return "sentences";
  }

  get label() {
    const { field } = this.props;
    if (field.required) {
      return (
        <>
          {field.label}
          <RequiredLabel> *</RequiredLabel>
        </>
      );
    }

    return field.label;
  }

  onChange = (value: string) => {
    const { onAnswer, field } = this.props;

    onAnswer({
      fieldName: field.name,
      value: postProcessValue(value, field.name),
    });
  };

  render() {
    const { field, multiline, password, store } = this.props;

    return (
      <QuestionContainer>
        <Input
          label={this.label ?? ""}
          value={this.answer?.value ?? ""}
          onChangeText={this.onChange}
          autoCapitalize={this.autoCapitalize}
          maxLength={!this.mask ? 150 : undefined}
          mask={this.mask}
          type={this.type}
          multiple={multiline}
          toggleableSecureText={password}
          error={store.getErrorByField(field.name)}
        />
      </QuestionContainer>
    );
  }
}

export default Text;
