import React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import ResourcesStore from "stores/ResourcesStore";

import { NavListItem } from "components/Layout";
import { NavLinkItemType } from "components/Layout/NavListItem/NavListItem";

import { capitalize } from "utils/common";

import ROUTES from "navigation/routes";

type Props = { workoutLabel?: string } & RouteComponentProps<{
  categoryId?: string;
  quickLinkId?: string;
}>;

@observer
class NavList extends React.Component<Props> {
  get pathname() {
    const {
      location: { pathname },
    } = this.props;

    return pathname;
  }

  get categoryId() {
    const {
      match: {
        params: { categoryId },
      },
    } = this.props;

    return categoryId ? Number.parseInt(categoryId) : undefined;
  }

  get quickLinkId() {
    const {
      match: {
        params: { quickLinkId },
      },
    } = this.props;

    return quickLinkId ? Number.parseInt(quickLinkId) : undefined;
  }

  get firstItem(): NavLinkItemType {
    return {
      title: "Resources",
      onClick: this.onClick(ROUTES.RESOURCES),
    };
  }

  @computed get categoryTitle() {
    return ResourcesStore.categoryDetails?.title
      ? capitalize(ResourcesStore.categoryDetails?.title)
      : undefined;
  }

  @computed get quickLinkTitle() {
    return ResourcesStore.resourceDetails?.title
      ? capitalize(ResourcesStore.resourceDetails?.title)
      : undefined;
  }

  @computed get navListItems() {
    const firstItem = this.firstItem;

    if (this.categoryId) {
      const secondItem = {
        title: this.categoryTitle,
        onClick: this.onClick(ROUTES.RESOURCES_CATEGORY_DETAILS, {
          categoryId: this.categoryId,
        }),
      };

      if (this.quickLinkId) {
        const thirdItem = this.quickLinkId
          ? {
              title: this.quickLinkTitle,
              onClick: this.onClick(
                ROUTES.RESOURCES_CATEGORY_DETAILS_QUICK_LINK_DETAILS,
                {
                  categoryId: this.categoryId,
                  quickLinkId: this.quickLinkId,
                },
              ),
            }
          : undefined;

        return [firstItem, secondItem, thirdItem].filter(Boolean);
      }

      return [firstItem, secondItem].filter(Boolean);
    }

    return [];
  }

  onClick = (route: string, routeParams?: any) => () => {
    const { history } = this.props;

    history.push(generatePath(route, routeParams));
  };

  render() {
    return (
      <>
        {this.navListItems.map((item: any, index: number) => (
          <NavListItem
            key={`${index}-${item.title}`}
            title={item.title}
            onClick={item.onClick}
            isLastItem={index === this.navListItems.length - 1}
          />
        ))}
      </>
    );
  }
}

export default withRouter(NavList);
