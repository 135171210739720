import styled, { css } from "styled-components";

import Button from "components/Button";

import styleConst from "constants/style";

interface IContainer {
  type: "page" | "element" | "popup";
}

export const BREAKPOINTS = { DESKTOP: 1200, SMALL_DESKTOP: 900 };

export const MEDIA_QUERIES = {
  DESKTOP: `@media screen and (min-height: ${BREAKPOINTS.DESKTOP}px)`,
  MEDIUM_DESKTOP: `@media screen and (max-height: ${
    BREAKPOINTS.DESKTOP - 1
  }px)`,
  SMALL_DESKTOP: `@media screen and (max-height: ${
    BREAKPOINTS.SMALL_DESKTOP - 1
  }px)`,
};

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${({ type }) =>
    type === "page" &&
    css`
      min-height: 456px;

      ${MEDIA_QUERIES.DESKTOP} {
        height: 1100px;
      }

      ${MEDIA_QUERIES.MEDIUM_DESKTOP} {
        height: 930px;
      }

      ${MEDIA_QUERIES.SMALL_DESKTOP} {
        height: 700px;
      }
    `}

  ${({ type }) =>
    type === "element" &&
    css`
      padding-bottom: 100px;
    `}

  ${({ type }) =>
    type === "popup" &&
    css`
      width: 100%;
      height: 100%;

      ${Title} {
        font-size: 26px;
        padding-bottom: 25px;
        padding-top: 0;
      }

      ${Text} {
        font-size: 14px;
        font-family: ${styleConst.fonts.roboto};
        line-height: 18px;
        font-weight: 400;
        color: ${styleConst.colors.black50};
        padding-top: 30px;
      }
    `}
`;

export const Text = styled.div`
  max-width: 750px;

  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.dark};
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 44px;
  line-height: 48px;
  text-align: center;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.dark};

  padding: 16px 0;
`;

export const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SubmitButton = styled(Button)`
  background: radial-gradient(
      83.3% 179.17% at 56.29% -31.25%,
      ${styleConst.colors.primaryMedium} 0%,
      ${styleConst.colors.primary} 100%
    ),
    ${styleConst.colors.primary};

  box-shadow: 0px 20px 40px -10px rgba(97, 198, 194, 0.25);
  border-radius: 10px;
  border: none;
  margin-bottom: 58px;
`;
