import * as React from "react";

const IPodcast = () => {
  return (
    <svg width="40" height="73" viewBox="0 0 40 73" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.267 6.13335C36.9607 6.13335 38.3337 4.76036 38.3337 3.06668C38.3337 1.373 36.9607 0 35.267 0C33.5734 0 32.2004 1.373 32.2004 3.06668C32.2004 4.76036 33.5734 6.13335 35.267 6.13335ZM19.9341 4.59999C12.3126 4.59999 6.13409 10.7785 6.13409 18.4V36.8001C6.13409 44.4217 12.3126 50.6002 19.9341 50.6002C27.5557 50.6002 33.7342 44.4217 33.7342 36.8001V18.4C33.7342 10.7785 27.5557 4.59999 19.9341 4.59999ZM9.20077 18.4C9.20077 12.4722 14.0063 7.66667 19.9341 7.66667C25.862 7.66667 30.6675 12.4722 30.6675 18.4V36.8001C30.6675 42.728 25.862 47.5335 19.9341 47.5335C14.0063 47.5335 9.20077 42.728 9.20077 36.8001V18.4ZM26.0672 18.4H18.4005L15.3328 18.4V36.8001H19.9328V27.6001H24.5338V26.0668H19.9328V19.9334H26.0672V18.4ZM1.53334 35.2669C2.38018 35.2669 3.06668 35.9534 3.06668 36.8002V38.3336C3.06668 46.802 9.93167 53.667 18.4001 53.667H21.4667C29.9351 53.667 36.8001 46.802 36.8001 38.3336V36.8002C36.8001 35.9534 37.4866 35.2669 38.3335 35.2669C39.1803 35.2669 39.8668 35.9534 39.8668 36.8002V38.3336C39.8668 48.4955 31.6291 56.7334 21.4672 56.7336V69.0003H30.6667C31.5135 69.0003 32.2 69.6868 32.2 70.5336C32.2 71.3804 31.5135 72.0669 30.6667 72.0669H9.19997C8.35313 72.0669 7.66663 71.3804 7.66663 70.5336C7.66663 69.6868 8.35313 69.0003 9.19997 69.0003H18.4005V56.7336H18.4001C8.23799 56.7336 0 48.4956 0 38.3336V36.8002C0 35.9534 0.686499 35.2669 1.53334 35.2669Z"
        fill="white"
      />
    </svg>
  );
};

export default IPodcast;
