import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
`;

export const IconWrapper = styled.div`
  margin-left: 6px;
`;

export const UserPhoto = styled.img`
  height: 40px;
  width: 40px;

  border-radius: 100%;
  object-fit: cover;
`;
