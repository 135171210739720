import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IRow {
  align: string;
  last?: boolean;
  highlightSelected?: boolean;
  selected?: boolean;
  modal?: boolean;
  form?: boolean;
  activeOpacity?: number;
  isQuestionType?: boolean;
  showInLine?: boolean;
}

interface IRowLabel {
  form?: boolean;
}

interface IRadioButtonWrapper {
  showInLine?: boolean;
}

interface IContainer {
  showIndicator?: boolean;
}

export const Container = styled.div<IContainer>`
  flex-grow: 1;

  ${({ showIndicator }) =>
    showIndicator
      ? css`
          margin: 0 -15px 0 -20px;
        `
      : css`
          margin: 0 -20px;
        `}}
`;

export const Content = styled.div`
  padding: 0 20px;
`;

export const RadioContainer = styled.div`
  display: flex;
`;

export const Row = styled.div<IRow>`
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;

  ${({ last, modal, form }) => {
    if (modal) {
      return css`
        padding: 14px 0;
      `;
    }
    if (form) {
      return css`
        flex-direction: row-reverse;

        padding: 8px 0;
      `;
    }
    if (last) {
      return css`
        border-top-color: ${styleConst.colors.black4};
        border-bottom-color: ${styleConst.colors.black4};
        border-top-width: 2px;
        border-bottom-width: 2px;
      `;
    }
    return css`
      border-top-color: ${styleConst.colors.black4};
      border-top-width: 2px;
    `;
  }};

  ${({ align }) =>
    css`
      align-items: ${align};
    `};

  ${({ activeOpacity }) =>
    css`
      opacity: ${activeOpacity};
    `};

  ${({ showInLine }) =>
    showInLine
      ? css`
          padding: 0px 41px 16px 0;
        `
      : css`
          padding: 5px 41px 16px 0;
        `};

  ${({ isQuestionType }) =>
    isQuestionType &&
    css`
      padding: 30px 0;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    `};
`;

export const RowLabel = styled.div<IRowLabel>`
  font-size: 16px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.text};
  letter-spacing: -0.41px;

  ${({ form }) =>
    form &&
    css`
      font-size: 16px;
      margin-left: 8px;
    `};
`;

export const RowLabelContainer = styled.div`
  flex-direction: row;
  display: flex;

  margin-left: 8px;
`;

export const Label = styled.div`
  margin-right: 2px;
  margin-bottom: 13px;

  font-size: 14px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.black50};
`;

export const LabelContainer = styled.div`
  flex-direction: row;
`;

export const RadioButtonWrapper = styled.div<IRadioButtonWrapper>`
  ${({ showInLine }) =>
    showInLine &&
    css`
      display: flex;
      flex-direction: row;
    `};
`;
