import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IContainer {
  color?: string;
  disabled?: boolean;
  activeOpacity: number;
}

interface ILabel {
  color?: string;
  textColor?: string;
  fontSize?: number;
  fontWeight?: number;
}

export const Container = styled.div<IContainer>`
  align-items: center;
  justify-content: center;

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.4;
        `
      : css`
          cursor: pointer;
        `};
`;

export const IconLeft = styled.div`
  display: flex;

  margin-right: 5px;
`;

export const IconRight = styled.div`
  display: flex;

  margin-left: 5px;
`;

export const LabelContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const Label = styled.div<ILabel>`
  font-family: ${styleConst.fonts.roboto};
  text-align: center;

  color: ${styleConst.colors.primary};

  ${({ color }) =>
    css`
      color: ${color};
    `};

  ${({ fontSize }) => css`
    font-size: ${fontSize}px;
  `}

  ${({ fontWeight }) => css`
    font-weight: ${fontWeight};
  `}
`;
