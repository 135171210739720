import styled, { css } from "styled-components";

import { Title as RecipeTitle } from "components/Recipe/styles";

import styleConst from "constants/style";

interface IRow {
  showControls?: boolean;
}

export const ButtonContainer = styled.div`
  width: 90px;
`;

export const Container = styled.div`
  background-color: ${styleConst.colors.white};
  padding-bottom: 50px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 48px;

  background: ${styleConst.colors.dark5};
  border-radius: 10px;
  padding: 20px;
`;

export const Title = styled.div`
  width: 56%;

  font-size: 18px;
  font-weight: 700;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.dark};
`;

export const SuggestContainer = styled.div`
  align-items: center;
  justify-content: center;
  padding: 0 40px;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;

  width: 25%;
`;

export const Row = styled.div<IRow>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  line-height: 18.75px;
  padding: 28px 20px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  ${Controls} {
    visibility: hidden;
  }

  &:hover {
    background: linear-gradient(
      89.98deg,
      rgba(132, 207, 203, 0) 0%,
      rgba(132, 207, 203, 0.08) 12.5%,
      rgba(132, 207, 203, 0.08) 87.48%,
      rgba(132, 207, 203, 0) 99.97%
    );

    ${Controls} {
      visibility: visible;
    }

    ${RecipeTitle} {
      color: ${styleConst.colors.primaryDark};
    }
  }

  ${({ showControls }) =>
    showControls &&
    css`
      background: linear-gradient(
        89.98deg,
        rgba(132, 207, 203, 0) 0%,
        rgba(132, 207, 203, 0.08) 12.5%,
        rgba(132, 207, 203, 0.08) 87.48%,
        rgba(132, 207, 203, 0) 99.97%
      );

      ${Controls} {
        visibility: visible;
      }
    `}
`;

export const ColumnTitle = styled.div`
  text-align: center;

  width: 80px;
  padding: 0 10px;

  font-weight: 400;
  font-size: 14px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.black50};
`;

export const ColumnTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  width: 44%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  width: 72%;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 3%;
`;
