import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";

import I24ThumbsUp from "assets/icons/I24ThumbsUp";

import Button from "components/Button";
import FormTitle from "components/Form/FormTitle";
import IconButton from "components/IconButton";
import TrimmedText from "components/TrimmedText";

import styleConst from "constants/style";

import { formatDuration } from "utils/common";

import {
  Buttons,
  ButtonLabel,
  ButtonWrapper,
  Column,
  Container,
  IconButtons,
  IconButtonWrapper,
  Row,
  Text,
  WorkoutContainer,
  Image,
  Info,
  Title,
  Duration,
} from "./styles";

type Props = {
  details: any;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
  onAccept: () => Promise<void>;
  goBack?: () => void;
};

@observer
class CompleteWorkout extends React.Component<Props> {
  @observable showQuestion: boolean = true;

  @observable isEnjoy?: boolean = undefined;
  @observable difficulty?: number = undefined;

  @computed get submitIsDisabled() {
    return this.isEnjoy === undefined || this.difficulty === undefined;
  }

  onAcceptClick = async () => {
    const { onAccept, onClose } = this.props;

    try {
      await onAccept();

      this.showQuestion = false;
    } catch (error) {
      onClose();
    }
  };

  onDeclineClick = () => {
    const { onClose, goBack } = this.props;

    if (!this.showQuestion) {
      goBack?.();
    }

    onClose();
  };

  onEnjoyClick = (isEnjoy: boolean) => () => {
    this.isEnjoy = isEnjoy;
  };

  onDifficultyClick = (difficulty: number) => () => {
    this.difficulty = difficulty;
  };

  onSubmit = () => {
    const { onSubmit } = this.props;

    onSubmit({ isEnjoy: this.isEnjoy, difficulty: this.difficulty });
  };

  render() {
    const { isLoading, details } = this.props;

    return (
      <Container>
        {this.showQuestion ? (
          <>
            <FormTitle title={"Complete Workout"} />

            <Row>
              <Text>Would you like to mark this workout as completed?</Text>
            </Row>
            <Buttons>
              <ButtonWrapper>
                <Button
                  text={"No"}
                  onClick={this.onDeclineClick}
                  height={50}
                  color="transparent"
                  borderColor={styleConst.colors.primary}
                  textColor={styleConst.colors.primary}
                />
              </ButtonWrapper>
              <ButtonWrapper>
                <Button
                  text={"Yes"}
                  onClick={this.onAcceptClick}
                  height={50}
                  loading={isLoading}
                />
              </ButtonWrapper>
            </Buttons>
          </>
        ) : (
          <>
            <FormTitle title={"Complete Workout"} />
            <WorkoutContainer>
              <Image imageUrl={details.imageUrl} />
              <Info>
                <TrimmedText lines={1} text={details.label ?? details.title}>
                  <Title>{details.label ?? details.title}</Title>
                </TrimmedText>

                {details.duration || details.date ? (
                  <Duration>{formatDuration(details.duration)}</Duration>
                ) : null}
              </Info>
            </WorkoutContainer>
            <Row>
              <Column>
                <Text>Did you enjoy this workout?</Text>

                <IconButtons>
                  <IconButtonWrapper>
                    <IconButton
                      icon={<I24ThumbsUp />}
                      onClick={this.onEnjoyClick(true)}
                      backgroundColor={
                        this.isEnjoy === true
                          ? styleConst.colors.primary
                          : styleConst.colors.primaryLight
                      }
                    />
                  </IconButtonWrapper>
                  <IconButtonWrapper>
                    <IconButton
                      icon={<I24ThumbsUp rotated />}
                      onClick={this.onEnjoyClick(false)}
                      backgroundColor={
                        this.isEnjoy === false
                          ? styleConst.colors.red
                          : styleConst.colors.redLight
                      }
                    />
                  </IconButtonWrapper>
                </IconButtons>
              </Column>
            </Row>
            <Row>
              <Column>
                <Text>How difficult was this workout?</Text>
                <IconButtons>
                  {[...Array(5).keys()]
                    .map(count => count + 1)
                    .map(count => (
                      <IconButtonWrapper key={count}>
                        <IconButton
                          icon={
                            <ButtonLabel
                              fontColor={
                                count === this.difficulty
                                  ? styleConst.colors.white
                                  : styleConst.colors.black50
                              }
                            >
                              {count}
                            </ButtonLabel>
                          }
                          backgroundColor={
                            count === this.difficulty
                              ? styleConst.colors.primary
                              : styleConst.colors.black4
                          }
                          onClick={this.onDifficultyClick(count)}
                        />
                      </IconButtonWrapper>
                    ))}
                </IconButtons>
              </Column>
            </Row>
            <Buttons>
              <ButtonWrapper>
                <Button
                  text={"Cancel"}
                  onClick={this.onDeclineClick}
                  height={50}
                  color="transparent"
                  borderColor={styleConst.colors.primary}
                  textColor={styleConst.colors.primary}
                />
              </ButtonWrapper>
              <ButtonWrapper>
                <Button
                  text={"Save"}
                  onClick={this.onSubmit}
                  height={50}
                  loading={isLoading}
                  disabled={this.submitIsDisabled}
                />
              </ButtonWrapper>
            </Buttons>
          </>
        )}
      </Container>
    );
  }
}

export default CompleteWorkout;
