import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const ItemWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 40px;
  }
`;
