import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24CheckArrow = ({ color = styleConst.colors.white }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3431 7.89299L16.7071 11.257C17.0976 11.6475 17.0976 12.2806 16.7071 12.6712L13.3431 16.0351C12.9526 16.4257 12.3195 16.4257 11.9289 16.0351C11.5384 15.6446 11.5384 15.0114 11.9289 14.6209L13.5858 12.9641L8 12.9641C7.44771 12.9641 7 12.5163 7 11.9641C7 11.4118 7.44771 10.9641 8 10.9641L13.5858 10.9641L11.9289 9.3072C11.5384 8.91668 11.5384 8.28352 11.9289 7.89299C12.3195 7.50247 12.9526 7.50247 13.3431 7.89299Z"
      fill={color}
    />
  </svg>
);

export default I24CheckArrow;
