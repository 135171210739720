import {
  format,
  parseISO,
  subMinutes,
  getMinutes,
  getHours,
  isYesterday,
  isToday,
  isTomorrow,
  roundToNearestMinutes,
  set,
} from "date-fns";

export const DATE_FORMAT = "MM/dd/yyyy";
export const TIME_FORMAT = "hh:mm a";
export const SERVER_DATE_FORMAT = "yyyy-MM-dd";
export const SERVER_DATE_TIME_FORMAT = "yyyy-MM-dd hh:mm";

export const replaceDotsWithSlashes = (date: string = "") =>
  date.replaceAll(".", "/");

export const getDate = (date?: string | Date) =>
  date ? (typeof date === "string" ? parseISO(date) : date) : undefined;

export const formatDate = (
  date?: string | Date,
  mask: string = DATE_FORMAT,
) => {
  const value = getDate(date);
  return value ? format(value, mask) : "";
};

export const formatDateToISO = (date?: Date) => {
  const offset = new Date().getTimezoneOffset();

  return date
    ? new Date(subMinutes(date.setSeconds(0, 0), offset)).toISOString()
    : undefined;
};

export const getDateTimeExpression = ({
  date,
  dateMask = "MMMM d",
  timeMask = TIME_FORMAT,
  divider = " ",
  withTime = true,
}: {
  date: Date;
  dateMask?: string;
  timeMask?: string;
  divider?: string;
  withTime?: boolean;
}) => {
  const datePart = getDateExpression({ date, dateMask });
  const timePart = withTime ? formatDate(date, timeMask) : "";
  return [datePart, timePart].filter(Boolean).join(divider);
};

export const getDateExpression = ({
  date,
  dateMask,
}: {
  date: Date;
  dateMask?: string;
}) => {
  if (isYesterday(date)) {
    return "Yesterday";
  }
  if (isToday(date)) {
    return "Today";
  }
  if (isTomorrow(date)) {
    return "Tomorrow";
  }
  return formatDate(date, dateMask);
};

export const ignoreTimezoneGMT = (date: string = "") => {
  return date.replace("GMT", "");
};

export const ignoreTimezone = (date: string = "") => {
  return date.slice(0, -1);
};

export const roundTime = (date: Date, minutesStep: number) =>
  minutesStep <= 30
    ? roundToNearestMinutes(date, {
        nearestTo: minutesStep,
      })
    : set(date, {
        hours: getHours(date) + Math.round(getMinutes(date) / 60),
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });

export const isValidDate = (date: any) =>
  date instanceof Date && !isNaN(date.valueOf());
