import * as React from "react";

import style from "constants/style";

type Props = {
  color?: string;
};

const I24ArrowRightSmall = ({ color = style.colors.primary }: Props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.37534 17.7809C8.94408 17.4359 8.87416 16.8066 9.21917 16.3753L12.7194 12L9.21917 7.62473C8.87416 7.19347 8.94408 6.56418 9.37534 6.21917C9.8066 5.87416 10.4359 5.94408 10.7809 6.37534L15.2807 12L10.7809 17.6247C10.4359 18.056 9.8066 18.1259 9.37534 17.7809Z"
        fill={color}
      />
    </svg>
  );
};

export default I24ArrowRightSmall;
