import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import {
  RouteComponentProps,
  generatePath,
  withRouter,
} from "react-router-dom";

import MyRecipesStore from "stores/MyRecipesStore";

import I32Add from "assets/icons/I32Add";
import I24Edit from "assets/icons/I24Edit";

import { Page, NavListItem } from "components/Layout";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";
import MealSchedulePopUp from "components/MealSchedulePopup";
import FoodLogControl from "components/FoodLogControl";
import Label from "components/Label";
import { SnackbarTypeValues } from "components/Snackbar";

import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";
import { NoInternetConnectionError, NotFoundError } from "services/api";

import { MEAL_SCHEDULE_TYPE } from "constants/nutrition";
import styleConst from "constants/style";

import ROUTES from "navigation/routes";

import {
  getNutrientsList,
  getNutrientLabel,
  getNutrientValue,
  getMainFields,
} from "utils/nutrients";
import { capitalize } from "utils/common";
import { redirectToRootRoute } from "utils/route";

import {
  Button,
  InfoContainer,
  Section,
  TitleAdornment,
  SectionTitleContainer,
  Title,
  Background,
  FoodNutrientsRow,
  TitleContainer,
  IconButton,
  Input,
  NutritionFactsContainer,
  InlineListItem,
  LabelContainer,
} from "./styles";

type Params = {
  recipeId: string;
};

interface Props
  extends RouteComponentProps<Params>,
    GlobalPopupProps,
    SnackbarContextProps {}

@observer
class MyRecipeDetails extends React.Component<Props> {
  @observable isEditing: boolean = false;
  @observable recipeName: string = "";
  @observable isNoInternetConnectionError: boolean = false;

  get id() {
    const {
      match: {
        params: { recipeId },
      },
    } = this.props;

    return recipeId;
  }

  get url() {
    const {
      match: { url },
    } = this.props;

    return url;
  }

  @computed get recipe() {
    return MyRecipesStore.details;
  }

  @computed get nutritionalInfo() {
    return this.recipe?.servings[0].nutritionalInfo;
  }

  @computed get ingredients() {
    return this.recipe?.ingredients;
  }

  @computed get nutritionFacts() {
    return getNutrientsList(this.nutritionalInfo ?? {});
  }

  @computed get mainFields() {
    return getNutrientsList(getMainFields(this.nutritionalInfo ?? {}));
  }

  @computed get isArchived() {
    return this.recipe?.isArchived;
  }

  @computed get isLoading() {
    return MyRecipesStore.isDetailsLoading;
  }

  @computed get navigationItems() {
    const { history } = this.props;

    return [
      {
        title: "My Recipes",
        onClick: () =>
          history.push(generatePath(ROUTES.NUTRITION_MY_ITEMS_MY_RECIPES)),
      },
      {
        title: capitalize(this.recipe?.name),
        onClick: () =>
          history.push(
            generatePath(ROUTES.NUTRITION_MY_ITEMS_MY_RECIPES_DETAILS, {
              recipeId: this.id,
            }),
          ),
      },
    ];
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    MyRecipesStore.resetDetails();
  }

  init = async () => {
    const { setMessage, history } = this.props;

    try {
      if (this.id) {
        await MyRecipesStore.fetchDetails(this.id);

        this.recipeName = MyRecipesStore.details?.name ?? "";
      }
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
      if (error instanceof NotFoundError) {
        redirectToRootRoute(history, this.url);
      }
    }
  };

  onEditClick = () => {
    this.isEditing = true;
  };

  onSave = async () => {
    if (this.id) {
      await MyRecipesStore.updateRecipe(this.id, this.recipeName);
    }

    this.isEditing = false;
  };

  onChangeTitle = (value: string) => {
    this.recipeName = value;
  };

  openMealSchedule = (mode: string) => {
    const { openPopup } = this.props;

    openPopup({
      key: "MealSchedulePopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <MealSchedulePopUp
          mode={mode}
          recipe={{ id: this.recipe?.id, isCustomRecipe: true }}
          onClose={close}
        />
      ),
    });
  };

  onToday = () => {
    this.openMealSchedule(MEAL_SCHEDULE_TYPE.TODAY);
  };

  onSelectDay = () => {
    this.openMealSchedule(MEAL_SCHEDULE_TYPE.SCHEDULE);
  };

  renderTitle = (): any => {
    return this.isEditing ? (
      <TitleContainer>
        <Input value={this.recipeName} onChangeText={this.onChangeTitle} />
      </TitleContainer>
    ) : (
      <TitleContainer>
        {this.recipeName}
        {!this.isArchived ? (
          <IconButton
            icon={<I24Edit color={styleConst.colors.black} />}
            onClick={this.onEditClick}
          />
        ) : (
          <LabelContainer>
            <Label text={"Archived"} />
          </LabelContainer>
        )}
      </TitleContainer>
    );
  };

  renderNavigationList = () =>
    this.navigationItems.map(({ title, onClick }, index) => (
      <NavListItem
        key={title}
        title={title}
        onClick={onClick}
        isLastItem={index === this.navigationItems.length - 1}
      />
    ));

  renderTitleControl = () => {
    if (this.isArchived) return null;

    return this.isEditing ? (
      <Button
        text="Save"
        height={50}
        fontSize={16}
        fontWeight={500}
        loading={MyRecipesStore.isUpdating}
        disabled={Boolean(this.recipeName.length === 0)}
        onClick={this.onSave}
      />
    ) : (
      <FoodLogControl
        onToday={this.onToday}
        onSelectDay={this.onSelectDay}
        element={
          <Button
            iconLeft={<I32Add />}
            text="Add to Food Log"
            height={50}
            fontSize={16}
            fontWeight={500}
          />
        }
      />
    );
  };

  render() {
    return (
      <Page
        isNoInternetConnectionError={this.isNoInternetConnectionError}
        isLoading={this.isLoading}
        title={this.renderTitle()}
        topNavigation={this.renderNavigationList()}
        titleControls={this.renderTitleControl()}
        contentBackground={
          <>
            <Background />
            <Background leftDots />
          </>
        }
      >
        <FoodNutrientsRow nutrients={this.mainFields} />

        <InfoContainer>
          <Section extended>
            <SectionTitleContainer>
              <TitleAdornment />
              <Title>Nutrition Facts</Title>
            </SectionTitleContainer>
            <NutritionFactsContainer>
              {this.nutritionFacts.map(({ label, value }) => (
                <InlineListItem
                  key={`${label}-${value}`}
                  width={40}
                  label={getNutrientLabel(label)}
                  value={getNutrientValue(label, value)}
                />
              ))}
            </NutritionFactsContainer>
          </Section>

          <Section>
            <SectionTitleContainer>
              <TitleAdornment />
              <Title>Ingredients</Title>
            </SectionTitleContainer>
            <NutritionFactsContainer>
              {this.ingredients?.map((ingredient, index) => (
                <InlineListItem
                  width={90}
                  key={`${ingredient.name}-${index}`}
                  label={ingredient.name}
                  value={
                    ingredient.quantity
                      ? `${ingredient.quantity}`
                      : ingredient.grams
                      ? `${ingredient.grams} g`
                      : ``
                  }
                />
              ))}
            </NutritionFactsContainer>
          </Section>
        </InfoContainer>
      </Page>
    );
  }
}

export default withGlobalSnackbar(withGlobalPopup(withRouter(MyRecipeDetails)));
