import React from "react";

import { Container, Text } from "./styles";

type Props = {
  text: string;
};

const Label: React.FC<Props> = ({ text }) => {
  return (
    <Container>
      <Text>{text}</Text>
    </Container>
  );
};

export default Label;
