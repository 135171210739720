import React from "react";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";
import { SnapshotOrInstance } from "mobx-state-tree";

import Checkbox from "components/Checkbox";

import { Field } from "models/Questionnaire";

import {
  CheckboxContainer,
  CheckboxWrapper,
  AgreeDescription,
  RequiredLabel,
  Error,
} from "../styles";

type FieldItem = SnapshotOrInstance<typeof Field>;
type AnswerItem = { fieldName: string; value: any; parentFieldName?: string };

type Props = {
  field: FieldItem;
  onAnswer: (answer: AnswerItem) => void;
  parentFieldName?: string;
  store: any;
};

@observer
class Agree extends React.Component<Props> {
  @observable isAgreeChecked: boolean = false;

  @computed get answer(): AnswerItem {
    const { field, store } = this.props;
    return store.getAnswersByField(field.name);
  }

  @computed get error() {
    const { field, store } = this.props;

    return store.getErrorByField(field.name);
  }

  get description() {
    const { field } = this.props;
    if (field.description) {
      return (
        <>
          {field.description}
          <RequiredLabel> *</RequiredLabel>
        </>
      );
    }

    return field.description;
  }

  componentDidMount() {
    this.isAgreeChecked = this.answer?.value === "true";
  }

  onChangeAgree = () => {
    this.isAgreeChecked = !this.isAgreeChecked;

    this.onChange(this.isAgreeChecked.toString());
  };

  onChange = (value: string) => {
    const { onAnswer, field, parentFieldName } = this.props;

    onAnswer({
      fieldName: field.name,
      value: value === "true" ? value : "",
      parentFieldName,
    });
  };

  render() {
    const { field } = this.props;

    return (
      <CheckboxContainer>
        <AgreeDescription>{this.description}</AgreeDescription>
        <CheckboxWrapper>
          <Checkbox
            checked={this.isAgreeChecked}
            toggleChecked={this.onChangeAgree}
            label={field.label}
          />
        </CheckboxWrapper>
        {this.error ? <Error>{this.error}</Error> : null}
      </CheckboxContainer>
    );
  }
}

export default Agree;
