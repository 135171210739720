import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I36CalendarScheduled = ({ color = styleConst.colors.primary }: Props) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 1.5C11.3284 1.5 12 2.17157 12 3L12 6H24V3C24 2.17157 24.6716 1.5 25.5 1.5C26.3284 1.5 27 2.17157 27 3V6.00621C27.7469 6.01685 28.4145 6.0456 28.9917 6.12321C29.975 6.25541 30.9175 6.55353 31.682 7.31802C32.4465 8.08251 32.7446 9.02504 32.8768 10.0083C33.0002 10.9258 33.0001 12.0715 33 13.4013V13.5H3V13.4013C2.99993 12.0715 2.99986 10.9258 3.12322 10.0083C3.25542 9.02504 3.55353 8.08251 4.31803 7.31802C5.08252 6.55353 6.02505 6.25541 7.00832 6.12321C7.58555 6.0456 8.25312 6.01685 9.00001 6.00621L9.00001 3C9.00001 2.17157 9.67158 1.5 10.5 1.5ZM3 16.5V24.0987C2.99993 25.4285 2.99986 26.5742 3.12322 27.4917C3.25542 28.475 3.55353 29.4175 4.31803 30.182C5.08252 30.9465 6.02505 31.2446 7.00832 31.3768C7.92582 31.5001 9.07155 31.5001 10.4013 31.5H20.4911C19.0684 29.733 19.1774 27.1406 20.818 25.5C22.2035 24.1145 24.2676 23.8213 25.9403 24.6204L28.2426 22.318C29.5289 21.0317 31.4001 20.6869 33 21.2836V16.5H3ZM22.9393 29.7426C22.3536 29.1569 22.3536 28.2071 22.9393 27.6213C23.5251 27.0355 24.4749 27.0355 25.0607 27.6213L26.1213 28.682L30.364 24.4393C30.9497 23.8536 31.8995 23.8536 32.4853 24.4393C33.0711 25.0251 33.0711 25.9749 32.4853 26.5607L27.182 31.864C26.5962 32.4497 25.6464 32.4497 25.0607 31.864L22.9393 29.7426Z"
      fill={color}
    />
  </svg>
);

export default I36CalendarScheduled;
