import React from "react";

import {
  Container,
  IconWrapper,
  SubTitle,
  Title,
  TitleContainer,
} from "./styles";

type Props = { title?: string; icon?: React.ReactNode; subtitle?: string };

const FormTitle = ({ title, icon, subtitle }: Props) => (
  <Container withSubtitle={!!subtitle}>
    <TitleContainer>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <Title>{title}</Title>
    </TitleContainer>
    {subtitle && <SubTitle>{subtitle}</SubTitle>}
  </Container>
);

export default FormTitle;
