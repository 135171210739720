import styled, { css } from "styled-components";

import DefaultRow from "components/Form/Row";

import styleConst from "constants/style";

interface IButtonLabel {
  fontColor?: string;
}

interface IImage {
  imageUrl: string;
}

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 60px;
`;

export const ButtonLabel = styled.p<IButtonLabel>`
  font-size: 18px;
  font-family: ${styleConst.fonts.roboto};

  ${({ fontColor }) =>
    css`
      color: ${fontColor};
    `}
`;

export const ButtonWrapper = styled.div`
  width: 150px;

  &:not(:last-child) {
    margin-right: 50px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 50px 0px;

  width: 600px;
  height: auto;

  border-radius: 10px;
`;

export const IconButtons = styled.div`
  display: flex;

  margin-top: 10px;
`;

export const IconButtonWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const Row = styled(DefaultRow)`
  align-items: center;
  justify-content: center;
  padding: 0 60px;
`;

export const WorkoutContainer = styled(DefaultRow)`
  position: relative;

  padding: 20px 60px;

  border-top: 1px solid ${styleConst.colors.black10};
  border-bottom: 1px solid ${styleConst.colors.black10};
`;

export const Text = styled.div`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 18px;
  font-weight: 500;

  color: ${styleConst.colors.dark};
`;

export const Image = styled.div<IImage>`
  border-radius: 10px;
  background-color: ${styleConst.colors.black4};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `}

  width: 64px;
  height: 64px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0 16px;
`;

export const Title = styled.div`
  margin-bottom: 4px;

  font-size: 16px;
  font-family: ${styleConst.fonts.roboto};
`;

export const Duration = styled.div`
  font-size: 14px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.black25};
`;
