import * as React from "react";

import style from "constants/style";

type Props = {
  color?: string;
  viewBox?: string;
};

const I24ArrowRightSwiper = ({ color = style.colors.text }: Props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.87868 18.7071C8.48816 18.3166 8.48816 17.6834 8.87868 17.2929L14.1716 12L8.87868 6.70711C8.48815 6.31658 8.48815 5.68342 8.87868 5.29289C9.2692 4.90237 9.90237 4.90237 10.2929 5.29289L17 12L10.2929 18.7071C9.90237 19.0976 9.2692 19.0976 8.87868 18.7071Z"
        fill={color}
      />
    </svg>
  );
};

export default I24ArrowRightSwiper;
