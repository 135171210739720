import React from "react";
import { FieldRenderProps } from "react-final-form";

import Input, { InputProps } from "components/Input";

import withFieldControl from "../FieldControl";

type Props = {
  required?: boolean;
  onlyPositiveNumbers?: boolean;
} & InputProps;

const DecimalNumberFieldControl = ({
  input,
  label,
  required,
  type,
  error,
  meta,
  onlyPositiveNumbers,
  ...props
}: FieldRenderProps<any> & Props) => {
  const onChange = (value: any) => {
    const event: any = { target: { value } };
    input.onChange(event);
  };

  return (
    <Input
      input={input}
      onChangeText={onChange}
      value={input.value}
      type={type}
      label={label}
      error={(meta.touched && meta.error) || error}
      required={required}
      onlyPositiveDecimalNumbers={onlyPositiveNumbers}
      decimalNumbersOnly
      {...props}
    />
  );
};

export default withFieldControl(DecimalNumberFieldControl);
