import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IContainer {
  withSubtitle?: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 30px;
  padding: 0 62px;

  width: 100%;

  ${({ withSubtitle }) =>
    withSubtitle &&
    css`
      margin-bottom: 20px;
    `}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  margin-right: 10px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;
  line-height: 48px;
  color: ${styleConst.colors.dark};

  text-align: center;
`;

export const SubTitle = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: ${styleConst.colors.dark50};

  text-align: center;
`;
