import { generatePath } from "react-router-dom";
import ROUTES from "navigation/routes";

export const redirectToRootRoute = (history: any, path: string) => {
  let newRoute = "/";
  const map: any = {
    "/nutrition/food-log": ROUTES.NUTRITION_FOOD_LOG,
    "/nutrition/search": ROUTES.NUTRITION_FOOD_LOG,
    "/nutrition/recipe-library": ROUTES.NUTRITION_RECIPE_LIBRARY,
    "/nutrition/my-items/my-recipes": ROUTES.NUTRITION_MY_ITEMS_MY_RECIPES,
    "/nutrition/my-items/my-food": ROUTES.NUTRITION_MY_ITEMS_MY_FOOD,
    "/nutrition/my-items/favorites": ROUTES.NUTRITION_MY_ITEMS_FAVORITES,
    "/today": ROUTES.TODAY,
    "/studio/categories": ROUTES.STUDIO,
    "/resources": ROUTES.RESOURCES,
    "/calendar": ROUTES.CALENDAR,
  };

  for (const prop in map) {
    if (path.includes(prop)) {
      newRoute = map[prop];
    }
  }

  history.push(generatePath(newRoute));
};
