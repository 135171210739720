import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { VimeoProps, PauseEvent } from "@u-wave/react-vimeo";

import I100Play from "assets/icons/I100Play";

import IconButton from "components/IconButton";

import { formatDurationOnlyNumbers } from "utils/common";

import {
  Background,
  Container,
  Control,
  Duration,
  DurationText,
  Image,
  Vimeo,
} from "./styles";

type Props = { imageUrl: string; duration: number } & VimeoProps;

@observer
class Video extends React.Component<Props> {
  @observable needThumbnail: boolean = true;
  @observable paused?: boolean = true;

  get duration() {
    const { duration } = this.props;

    return formatDurationOnlyNumbers(duration);
  }

  playVideo = () => {
    this.paused = false;
    this.needThumbnail = false;
  };

  onPause = (event: PauseEvent) => {
    const { onPause } = this.props;

    this.paused = true;

    onPause?.(event);
  };

  render() {
    const { imageUrl } = this.props;

    return (
      <Container>
        {this.needThumbnail ? (
          <>
            <Background />
            <Image imageUrl={imageUrl} />
            <Control>
              <IconButton icon={<I100Play />} onClick={this.playVideo} />
            </Control>
            <Duration>
              <DurationText>{this.duration}</DurationText>
            </Duration>
          </>
        ) : null}
        <Vimeo
          {...this.props}
          onPause={this.onPause}
          paused={this.paused}
          style={{ borderRadius: "10px" }}
        />
      </Container>
    );
  }
}

export default Video;
