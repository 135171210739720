import React from "react";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";

import FoodLogStore from "stores/FoodLogStore";

import Input from "components/Input";
import WaterProgress from "./WaterProgress";

import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";

import { round } from "utils/common";

import {
  Container,
  Content,
  Amount,
  Control,
  ControlButton,
  ControlInputWrapper,
  Goal,
  ImageMeasurement,
  NumberMeasurement,
  Placeholder,
  PlaceholderContainer,
  PlaceholderText,
  inputStyles,
  Title,
  SubmitButton,
} from "./styles";
import { SnackbarTypeValues } from "../Snackbar";

type Props = {
  activeDay: string;
  editMode: boolean;
  onClose: () => void;
} & SnackbarContextProps;

@observer
class AddWaterPopup extends React.Component<Props> {
  @observable value: string = "0";
  @observable isLoading: boolean = false;

  @observable error = "";

  @computed get waterData() {
    return FoodLogStore.summary?.water;
  }

  @computed get isNeedToShowWaterGoalAnimation() {
    return FoodLogStore.isNeedToShowWaterGoalAnimation;
  }

  @computed get amount() {
    return this.waterData?.amount ?? 0;
  }

  @computed get goal() {
    return this.waterData?.goal ?? 100;
  }

  @computed get unit() {
    return this.waterData?.unit ?? "oz";
  }

  @computed get progress() {
    const percents = round((this.amount / this.goal) * 100);

    return percents > 100 ? 100 : percents;
  }

  async componentDidMount() {
    const { editMode } = this.props;

    if (editMode) {
      this.value = this.amount.toString();
    }
  }

  onChangeInput = (value: string) => {
    this.value = value;
  };

  onButtonPress = (type: "incr" | "decr") => () => {
    if (!this.value) {
      this.value = "0";
    }
    if (type === "incr") {
      const nextValue = parseInt(this.value) + 4;
      this.value = nextValue.toString();
    } else {
      const nextValue = parseInt(this.value) - 4;
      this.value = nextValue.toString();
    }
    if (parseInt(this.value) < 0) {
      this.value = "0";
    }
    this.onChangeInput(this.value);
  };

  onSubmit = async () => {
    const { activeDay, editMode, onClose, setMessage } = this.props;
    try {
      this.isLoading = true;
      const value = parseInt(this.value);

      if (editMode) {
        await FoodLogStore.editWater(activeDay, value);
      } else {
        await FoodLogStore.addWater(activeDay, value);
        this.value = "0";
      }

      await FoodLogStore.fetch(activeDay);
      onClose();
    } catch (error: any) {
      console.log(`error`, error);
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    } finally {
      this.isLoading = false;
    }
  };

  render() {
    const { editMode } = this.props;
    return this.waterData ? (
      <Container>
        <Content>
          <Title>{editMode ? "Edit Water" : "Add Water"}</Title>
          <NumberMeasurement>
            <Amount>{`${this.amount} ${this.unit}`}</Amount>
            <Goal>{`of ${this.goal} ${this.unit}`}</Goal>
          </NumberMeasurement>

          <ImageMeasurement>
            {this.amount ? (
              <WaterProgress progress={this.progress} />
            ) : (
              <PlaceholderContainer>
                <Placeholder />
                <PlaceholderText>{"No water added\ntoday yet"}</PlaceholderText>
              </PlaceholderContainer>
            )}
          </ImageMeasurement>

          <Control>
            <ControlButton
              disabled={Number.parseFloat(this.value) < 0}
              onClick={this.onButtonPress("decr")}
              leftControl
            >
              -
            </ControlButton>

            <ControlInputWrapper>
              <Input
                value={this.value.toString()}
                onChangeText={this.onChangeInput}
                inputStyles={inputStyles}
                error={this.error}
                numbersOnly
                onlyPositiveNumbers
              />
            </ControlInputWrapper>

            <ControlButton rightControl onClick={this.onButtonPress("incr")}>
              +
            </ControlButton>
          </Control>

          <SubmitButton
            height={50}
            onClick={this.onSubmit}
            text={"Save"}
            loading={this.isLoading}
            disabled={!!this.error}
          />
        </Content>
      </Container>
    ) : null;
  }
}

export default withGlobalSnackbar(AddWaterPopup);
