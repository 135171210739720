import React from "react";
import {
  generatePath,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";

import NavMenuItem from "./NavMenuItem";

import { Container, ItemWrapper } from "./styles";

type NavMenuItemType = {
  title: string;
  route: string;
  rootRoute: string;
};

type Props = { items: NavMenuItemType[] } & RouteComponentProps;

class NavMenu extends React.Component<Props> {
  getIsSelected = (item: NavMenuItemType) => {
    const {
      match: { url },
    } = this.props;

    return url.includes(item.route) || url.includes(item.rootRoute);
  };

  onClick = (item: NavMenuItemType) => () => {
    this.props.history.push(generatePath(item.route));
  };

  render() {
    const { items } = this.props;

    return (
      <Container>
        {items.map(item => (
          <ItemWrapper key={`key:${item.route}-${item.rootRoute}`}>
            <NavMenuItem
              title={item.title}
              onClick={this.onClick(item)}
              selected={this.getIsSelected(item)}
            />
          </ItemWrapper>
        ))}
      </Container>
    );
  }
}

export default withRouter(NavMenu);
