import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I36Calendar = ({ color = styleConst.colors.black }: Props) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 1.5C11.3284 1.5 12 2.17157 12 3L12 6H24L24 3C24 2.17157 24.6716 1.5 25.5 1.5C26.3284 1.5 27 2.17157 27 3V6.00621C27.7469 6.01685 28.4145 6.0456 28.9917 6.12321C29.975 6.25541 30.9175 6.55353 31.682 7.31802C32.4465 8.08251 32.7446 9.02504 32.8768 10.0083C33.0001 10.9258 33.0001 12.0715 33 13.4013V24.0987C33.0001 25.4285 33.0001 26.5742 32.8768 27.4917C32.7446 28.475 32.4465 29.4175 31.682 30.182C30.9175 30.9465 29.975 31.2446 28.9917 31.3768C28.0742 31.5001 26.9285 31.5001 25.5987 31.5H10.4013C9.07155 31.5001 7.92582 31.5001 7.00832 31.3768C6.02505 31.2446 5.08252 30.9465 4.31803 30.182C3.55353 29.4175 3.25542 28.475 3.12322 27.4917C2.99986 26.5742 2.99993 25.4285 3 24.0987V13.4013C2.99993 12.0715 2.99986 10.9258 3.12322 10.0083C3.25542 9.02504 3.55353 8.08251 4.31803 7.31802C5.08252 6.55353 6.02505 6.25541 7.00832 6.12321C7.58555 6.0456 8.25311 6.01685 9.00001 6.00621L9.00001 3C9.00001 2.17157 9.67158 1.5 10.5 1.5ZM6.00001 13.5V24C6.00001 25.4566 6.00319 26.3982 6.09647 27.0919C6.18394 27.7426 6.32516 27.9465 6.43935 28.0607C6.55353 28.1748 6.75745 28.3161 7.40806 28.4035C8.10182 28.4968 9.04339 28.5 10.5 28.5H25.5C26.9566 28.5 27.8982 28.4968 28.592 28.4035C29.2426 28.3161 29.4465 28.1748 29.5607 28.0607C29.6749 27.9465 29.8161 27.7426 29.9035 27.0919C29.9968 26.3982 30 25.4566 30 24V13.5H6.00001Z"
      fill={color}
      fillOpacity="0.25"
    />
  </svg>
);

export default I36Calendar;
