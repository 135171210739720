import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Call = ({ color = styleConst.colors.white }: Props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      fill={color}
      fillRule="evenodd"
      d="M10.857 8.338c.519-.698.704-1.434.118-2.084C9.66 4.414 8.776 3.276 8.221 2.73c-1.054-1.042-2.789-.901-3.702-.001-.49.483-.657.649-1.16 1.16C.552 6.697 2.263 12.63 6.811 17.184c4.548 4.552 10.482 6.264 13.293 3.45.465-.447.858-.841 1.169-1.169.895-.946 1.03-2.604-.006-3.682-.532-.552-1.617-1.393-3.534-2.765-.587-.525-1.284-.411-1.922.007-.307.202-.53.406-.952.828l-.767.767c-.1.1-1.471-.586-2.801-1.917C9.96 11.37 9.275 9.999 9.375 9.899l.767-.768c.134-.134.198-.198.28-.285.171-.18.313-.343.435-.508zm4.65 7.695l.766-.767c.232-.232.383-.375.505-.474 1.68 1.206 2.653 1.965 3.05 2.378.239.248.201.7-.006.919-.287.303-.66.677-1.117 1.118-1.818 1.819-6.608.437-10.479-3.438-3.872-3.876-5.253-8.666-3.449-10.472.502-.509.66-.668 1.144-1.144.182-.179.675-.22.896-.001.427.422 1.22 1.436 2.385 3.06-.06.074-.136.159-.228.255a9.633 9.633 0 01-.246.25l-.766.767c-1.303 1.303-.193 3.52 1.916 5.632 2.107 2.11 4.326 3.22 5.629 1.917z"
      clipRule="evenodd"
    />
  </svg>
);

export default I24Call;
