import React from "react";
import { FieldRenderProps } from "react-final-form";

import Input, { InputProps } from "components/Input";

import withFieldControl from "../FieldControl";

type Props = {
  required?: boolean;
} & InputProps;

const TextFieldControl = ({
  autoFocus,
  autoComplete,
  input,
  label,
  required,
  type,
  error,
  meta,
  ...props
}: FieldRenderProps<any> & Props) => {
  const onChange = (value: any) => {
    const event: any = { target: { value } };
    input.onChange(event);
  };

  return (
    <Input
      onChangeText={onChange}
      value={input.value}
      type={type}
      label={label}
      error={(meta.touched && meta.error) || error}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      required={required}
      {...props}
    />
  );
};

export default withFieldControl(TextFieldControl);
