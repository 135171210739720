import React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import StudioStore from "stores/StudioStore";
import WorkoutDetailsStore from "stores/WorkoutDetailsStore";

import { NavListItem } from "components/Layout";
import { NavLinkItemType } from "components/Layout/NavListItem/NavListItem";

import { capitalize } from "utils/common";

import ROUTES from "navigation/routes";

import { CONTENT_STORE_TYPE_LABEL } from "constants/studio";

type Props = RouteComponentProps<{
  categoryId?: string;
  workoutGroup?: string;
  workoutId?: string;
  movementId?: string;
}>;

@observer
class NavList extends React.Component<Props> {
  get pathname() {
    const {
      location: { pathname },
    } = this.props;

    return pathname;
  }

  get categoryId() {
    const {
      match: {
        params: { categoryId },
      },
    } = this.props;

    return categoryId ? Number.parseInt(categoryId) : undefined;
  }

  get workoutId() {
    const {
      match: {
        params: { workoutId },
      },
    } = this.props;

    return workoutId ? Number.parseInt(workoutId) : undefined;
  }

  get workoutGroup() {
    const {
      match: {
        params: { workoutGroup },
      },
    } = this.props;

    return workoutGroup;
  }

  get movementId() {
    const {
      match: {
        params: { movementId },
      },
    } = this.props;

    return movementId ? Number.parseInt(movementId) : undefined;
  }

  get isFavorites() {
    const {
      match: { url },
    } = this.props;

    return url.includes(ROUTES.STUDIO_WORKOUTS_FAVORITES);
  }

  get firstItem(): NavLinkItemType {
    return {
      title: "Studio",
      onClick: this.onClick(ROUTES.STUDIO),
    };
  }

  @computed get categoryTitle() {
    return StudioStore.details?.title
      ? capitalize(StudioStore.details?.title)
      : undefined;
  }

  @computed get workoutTitle() {
    const title =
      WorkoutDetailsStore.details?.label || WorkoutDetailsStore.details?.title;

    return title ? capitalize(title) : undefined;
  }

  @computed get movementTitle() {
    return this.movementId
      ? capitalize(
          WorkoutDetailsStore.getMovementDetails(this.movementId)?.title,
        )
      : undefined;
  }

  @computed get navListItems() {
    const firstItem = this.firstItem;

    if (this.isFavorites) {
      const secondItem = {
        title: CONTENT_STORE_TYPE_LABEL.STANDARD_FAVORITES,
        onClick: this.onClick(ROUTES.STUDIO_WORKOUTS_FAVORITES),
      };
      const thirdItem = this.workoutId
        ? {
            title: this.workoutTitle,
            onClick: this.onClick(
              ROUTES.STUDIO_WORKOUT_FAVORITES_WORKOUT_DETAILS,
              { workoutId: this.workoutId },
            ),
          }
        : undefined;
      const fourthItem = this.movementId
        ? {
            title: this.movementTitle,
            onClick: this.onClick(
              ROUTES.STUDIO_WORKOUT_FAVORITES_WORKOUT_DETAILS_MOVEMENT_DETAILS,
              { workoutId: this.workoutId, movementId: this.movementId },
            ),
          }
        : undefined;

      return [firstItem, secondItem, thirdItem, fourthItem].filter(Boolean);
    } else if (this.categoryId) {
      const secondItem = {
        title: this.categoryTitle,
        onClick: this.onClick(ROUTES.STUDIO_CATEGORY_DETAILS, {
          categoryId: this.categoryId,
        }),
      };

      if (this.workoutGroup) {
        const thirdItem = {
          title: capitalize(this.workoutGroup),
          onClick: this.onClick(ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUTS, {
            categoryId: this.categoryId,
            workoutGroup: this.workoutGroup,
          }),
        };
        const fourthItem = this.workoutId
          ? {
              title: capitalize(this.workoutTitle),
              onClick: this.onClick(
                ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUTS_WORKOUT_DETAILS,
                {
                  categoryId: this.categoryId,
                  workoutGroup: this.workoutGroup,
                  workoutId: this.workoutId,
                },
              ),
            }
          : undefined;
        const fifthItem = this.movementId
          ? {
              title: this.movementTitle,
              onClick: this.onClick(
                ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUTS_WORKOUT_DETAILS_MOVEMENT_DETAILS,
                {
                  categoryId: this.categoryId,
                  workoutGroup: this.workoutGroup,
                  workoutId: this.workoutId,
                  movementId: this.movementId,
                },
              ),
            }
          : undefined;

        return [firstItem, secondItem, thirdItem, fourthItem, fifthItem].filter(
          Boolean,
        );
      } else if (this.workoutId) {
        const thirdItem = this.workoutId
          ? {
              title: this.workoutTitle,
              onClick: this.onClick(
                ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUT_DETAILS,
                {
                  categoryId: this.categoryId,
                  workoutId: this.workoutId,
                },
              ),
            }
          : undefined;
        const fourthItem = this.movementId
          ? {
              title: this.movementTitle,
              onClick: this.onClick(
                ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUT_DETAILS_MOVEMENT_DETAILS,
                {
                  categoryId: this.categoryId,
                  workoutId: this.workoutId,
                  movementId: this.movementId,
                },
              ),
            }
          : undefined;

        return [firstItem, secondItem, thirdItem, fourthItem].filter(Boolean);
      }

      return [firstItem, secondItem].filter(Boolean);
    }

    return [];
  }

  onClick = (route: string, routeParams?: any) => () => {
    const { history } = this.props;

    history.push(generatePath(route, routeParams));
  };

  render() {
    return (
      <>
        {this.navListItems.map((item: any, index: number) => (
          <NavListItem
            key={`${index}-${item.title}`}
            title={item.title}
            onClick={item.onClick}
            isLastItem={index === this.navListItems.length - 1}
          />
        ))}
      </>
    );
  }
}

export default withRouter(NavList);
