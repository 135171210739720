import { types } from "mobx-state-tree";

import { IMAGE_SIZE_TYPE } from "constants/common";

export const Context = types.model({
  page: types.number,
  size: types.number,
  pages: types.number,
  totalRows: types.number,
});

export const SimpleModel = types.model({
  id: types.number,
  name: types.string,
});

export const ImageSize = types.model("ImageSize", {
  imageType: types.enumeration(Object.values(IMAGE_SIZE_TYPE)),
  imageUrl: types.string,
});
