import React from "react";
import { observable, computed } from "mobx";
import { observer } from "mobx-react";
import { addDays, format, startOfDay, startOfWeek } from "date-fns";

import FoodLogStore from "stores/FoodLogStore";
import MacroPlanStore from "stores/MacroPlanStore";

import Spinner from "components/Spinner";
import GreenBarMacroGoals from "components/GreenBars/MacroGoals";
import DaySelect from "components/DaySelect";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import View from "./View";

import { NoInternetConnectionError } from "services/api";

import styleConst from "constants/style";

import { SERVER_DATE_FORMAT } from "utils/date";
import {
  Title,
  LoaderContainer,
  Container,
  DaySelectContainer,
  GreyBarWrapper,
} from "./styles";

type Props = {
  needUpdate: boolean;
};

@observer
class MacroGoals extends React.Component<Props> {
  @observable isLoading: boolean = false;
  @observable activeDay: Date = startOfDay(new Date());
  @observable page: number = 1;
  @observable isNoInternetConnectionError: boolean = false;

  @computed get week() {
    const start = startOfWeek(this.activeDay, { weekStartsOn: 1 });
    return [...Array(7).keys()].map(count => addDays(start, count));
  }

  @computed get summary() {
    return FoodLogStore.summary;
  }

  @computed get foodDayType() {
    return this.summary?.foodDayType.name;
  }

  @computed get formattedActiveDay() {
    return format(startOfDay(this.activeDay), SERVER_DATE_FORMAT);
  }

  @computed get nutritionGoal() {
    return this.summary?.macronutrients;
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps: Props) {
    const { needUpdate } = this.props;

    if (needUpdate && needUpdate !== prevProps.needUpdate) {
      this.fetch();
    }
  }

  onDayChange = async (day: Date) => {
    this.activeDay = day;
    await FoodLogStore.fetch(this.formattedActiveDay);
  };

  fetch = async () => {
    this.isLoading = true;

    try {
      await Promise.all([
        FoodLogStore.fetch(this.formattedActiveDay),
        MacroPlanStore.fetch(),
      ]);
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    } finally {
      this.isLoading = false;
    }
  };

  render() {
    if (this.isNoInternetConnectionError) {
      return <NoInternetConnectionPlaceholder type={"element"} />;
    }

    if (this.isLoading) {
      return (
        <LoaderContainer>
          <Spinner color={styleConst.colors.primary} large />
        </LoaderContainer>
      );
    }

    return (
      <React.Fragment>
        <Container>
          <Title>My Macro Goals</Title>
          <DaySelectContainer>
            <DaySelect
              active={this.activeDay}
              week={this.week}
              onDayChange={this.onDayChange}
            />
          </DaySelectContainer>
          <GreyBarWrapper>
            <GreenBarMacroGoals
              foodDayType={this.foodDayType}
              nutritionGoal={this.nutritionGoal}
              greyStyle
            ></GreenBarMacroGoals>
          </GreyBarWrapper>
          <View
            plan={{
              name: MacroPlanStore.macroPlanLabel,
              items: MacroPlanStore.mappedItems,
            }}
            onClosePopup={this.fetch}
          ></View>
        </Container>
      </React.Fragment>
    );
  }
}

export default MacroGoals;
