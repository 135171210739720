import { createTheme } from "@material-ui/core/styles";

import styleConst from "./style";

export const DEFAULT_THEME = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: styleConst.colors.primary,
      dark: styleConst.colors.primaryDark,
      contrastText: styleConst.colors.white,
    },
    text: { primary: styleConst.colors.dark },
  },
  typography: {
    fontFamily: "Roboto",
    h1: {
      fontSize: 28,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "32px",
      letterSpacing: "0.5px",
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "22px",
      letterSpacing: "0.5px",
    },
    h4: {
      fontSize: 16,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "24px",
    },
    subtitle1: {
      fontSize: 16,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "24px",
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "24px",
    },
    body1: {
      fontSize: 16,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 14,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.29,
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "20px",
    },
    caption: {
      opacity: 0.4,
      fontSize: 16,
      fontWeight: 500,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "4px",
        padding: "8px 16px",
        textTransform: "none",
        minHeight: "36px",
      },

      contained: {
        boxShadow: undefined,

        "&:hover": {
          boxShadow: undefined,
        },
      },

      containedPrimary: {
        "&:not(:disabled):hover": {
          backgroundColor: styleConst.colors.primary70,
          color: styleConst.colors.white,
        },

        "&:disabled": {
          backgroundColor: styleConst.colors.primary50,
          color: styleConst.colors.white,
        },
      },

      containedSecondary: {
        color: styleConst.colors.primary,
        backgroundcolor: styleConst.colors.white,

        "&:not(:disabled):hover": {
          backgroundColor: styleConst.colors.white80,
          color: styleConst.colors.primary,
        },

        "&:disabled": {
          backgroundColor: styleConst.colors.white50,
          color: styleConst.colors.primary,
        },
      },

      outlinedPrimary: {
        border: `2px solid ${styleConst.colors.primary}`,
        backgroundColor: "transparent",

        "&:not(:disabled):hover": {
          borderColor: `${styleConst.colors.primary70}`,
          borderWidth: "2px",
          backgroundColor: styleConst.colors.primary70,
          color: styleConst.colors.white,
        },

        "&:disabled": {
          border: "2px solid",
          backgroundColor: styleConst.colors.primary50,
          color: styleConst.colors.white,
        },
      },

      text: {
        backgroundColor: "transparent",

        "&:not(:disabled):hover": {
          color: styleConst.colors.primary,
          backgroundColor: "transparent",
        },

        "&:disabled": {
          color: styleConst.colors.black50,
          opacity: 0.4,
        },
      },
    },

    MuiIconButton: {
      root: { padding: "4px", margin: "8px 0px" },
    },

    MuiFormControl: {
      root: {
        margin: undefined,
      },
    },

    MuiFormControlLabel: { root: { minHeight: "38px", marginRight: "40px" } },

    MuiFormLabel: { root: { "&$error": { color: styleConst.colors.red } } },

    MuiOutlinedInput: {
      root: {
        fontSize: "14px",
        borderRadius: "10px",
        "&:hover $notchedOutline": {
          borderColor: styleConst.colors.black10,
        },
      },
      input: { padding: "17.5px 14px" },
      notchedOutline: {
        borderWidth: "2px",
        borderColor: styleConst.colors.black10,

        "& span": {
          padding: "0 10px",
        },
      },
    },

    MuiFormHelperText: {
      root: {
        color: styleConst.colors.red,
        fontSize: "12px",
        lineHeight: "16px",
        opacity: 1,
        "&$error": { color: styleConst.colors.red },
        position: "absolute",
        bottom: "-18px",
      },
    },
  },
});
