import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24CheckboxOn = ({ color = styleConst.colors.white }: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      d="M19 3.04297H5C3.89 3.04297 3 3.94297 3 5.04297V19.043C3 20.143 3.89 21.043 5 21.043H19C20.11 21.043 21 20.143 21 19.043V5.04297C21 3.94297 20.11 3.04297 19 3.04297Z"
      fill="#84CFCB"
    />
    <path
      d="M10 17.043L5 12.043L6.41 10.633L10 14.213L17.59 6.62305L19 8.04305L10 17.043Z"
      fill={color}
    />
  </svg>
);

export default I24CheckboxOn;
