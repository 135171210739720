import styled from "styled-components";

import Button from "components/Button";

import styleConst from "constants/style";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  padding-left: 101px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 48px;

  background: ${styleConst.colors.dark5};
  border-radius: 10px;
  padding: 20px;
`;

export const Title = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 48px;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.dark};

  border-left: 4px solid ${styleConst.colors.primary};

  padding-left: 14px;
  margin-bottom: 20px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: calc(100vh - 100px - 345px);
`;

export const Form = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  width: 595px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 290px;
  height: 50px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 22px;
`;

export const ItemDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
  font-family: ${styleConst.fonts.roboto};
  line-height: 16px;
  color: ${styleConst.colors.black50};

  padding: 7px 16px;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  width: 595px;

  padding: 52px 0px;
`;

export const ButtonInlineWrapper = styled.div`
  width: 180px;
  height: 24px;

  cursor: pointer;
`;

export const SubmitButton = styled(Button)`
  width: 180px;
  height: 50px;

  margin-left: 20px;

  background: radial-gradient(
      83.3% 179.17% at 56.29% -31.25%,
      ${styleConst.colors.primaryMedium} 0%,
      ${styleConst.colors.primary} 100%
    ),
    ${styleConst.colors.primary};
  box-shadow: 0px 20px 40px -10px ${styleConst.colors.shadow};
  border-radius: 10px;
  border: none;
`;
