import styled, { css } from "styled-components";

import backgroundImageSmall from "assets/images/svg/dots_background_small.svg";
import backgroundImage from "assets/images/svg/dots_background.svg";

import DefaultButton from "components/Button";
import { IconLeft, Label } from "components/Button/styles";

import { MEDIA_QUERIES } from "constants/layout";
import styleConst from "constants/style";

interface IButton {
  backgroundColor?: string;
}

interface IBackground {
  leftDots?: boolean;
}

interface ISection {
  isEquipment?: boolean;
}

export const Background = styled.div<IBackground>`
  position: absolute;
  top: 1010px;
  z-index: -1;

  height: 388px;
  width: 100%;

  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  background-image: url(${backgroundImageSmall});

  ${MEDIA_QUERIES.DESKTOP} {
    top: 1180px;
    background-image: url(${backgroundImage});
  }

  ${({ leftDots }) =>
    leftDots &&
    css`
      top: 1360px;

      transform: rotate(180deg);

      ${MEDIA_QUERIES.DESKTOP} {
        top: 1480px;
      }
    `}
`;

export const Button = styled(DefaultButton)<IButton>`
  color: ${styleConst.colors.black50};

  ${IconLeft} {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 15px;

    width: 60px;
    height: 60px;
    border-radius: 50%;

    ${({ backgroundColor }) =>
      backgroundColor &&
      css`
        background-color: ${backgroundColor};
      `}
  }

  ${Label} {
    color: ${styleConst.colors.dark};
  }
`;

export const Container = styled.div`
  display: flex;

  flex-direction: column;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 38px 220px 30px;
`;

export const InfoContainer = styled.div`
  margin-top: 63px;
`;

export const Section = styled.div<ISection>`
  margin-bottom: 100px;

  ${({ isEquipment }) =>
    isEquipment &&
    css`
      margin-bottom: 90px;
    `}
`;

export const Text = styled.p`
  font-family: ${styleConst.fonts.roboto};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
`;

export const TitleAdornment = styled.div`
  margin-right: 14px;

  width: 4px;
  height: 28px;
  background-color: ${styleConst.colors.secondary};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 0 11px 20px;

  ${MEDIA_QUERIES.DESKTOP} {
    margin: 0 0 20px;
  }
`;

export const Title = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;
  color: ${styleConst.colors.text};
  line-height: 48px;
`;

export const Workout = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 11px;

  ${MEDIA_QUERIES.DESKTOP} {
    padding: 0px;
  }
`;
