import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24RadioButtonOn = ({ color = styleConst.colors.white }: Props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path fill={color} d="M12 17a5 5 0 10-.001-10.001A5 5 0 0012 17z" />
    <path
      fill={color}
      fillRule="evenodd"
      d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-2 0a8 8 0 11-16 0 8 8 0 0116 0z"
      clipRule="evenodd"
    />
  </svg>
);

export default I24RadioButtonOn;
