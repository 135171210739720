export const DEFAULT_TABS = {
  PROFILE: "Profile",
  DOWNLOADS: "Downloads",
};

export const PREVIEW_TABS = {
  PROFILE: "Profile",
  ACCESS_PROGRAMS: "Access Programs",
};

export const GENDER_TYPE = {
  FEMALE: "Female",
  MALE: "Male",
  OTHER: "Other",
};

export const MY_MACRO_GOALS_TABS = {
  DAY: "Day",
  WEEK: "Week",
};

export const PROFILE_TABS = {
  MACROGOALS: "My Macro Goals",
  MEASUREMENTS: "My Measurements",
  CONTACTS: "My Contacts",
  CHANGE_PASSWORD: "Change Password",
  LEGAL: "Legal",
  UPDATE_MEMBERSHIP: "Update Membership",
};
