import React from "react";

import {
  BottomBackground,
  Container,
  ContentWrapper,
  FooterContent,
  Wrapper,
} from "./styles";

type Props = {
  leftContent: React.ReactNode;
  centerContent: React.ReactNode;
  rightContent: React.ReactNode;
  bottomContent: React.ReactNode;
  disabled?: boolean;
};

class Footer extends React.Component<Props> {
  render() {
    const {
      leftContent,
      centerContent,
      rightContent,
      bottomContent,
      disabled,
    } = this.props;

    return (
      <Container disabled={disabled}>
        <Wrapper isTop>
          <FooterContent isTop>
            <ContentWrapper>{leftContent}</ContentWrapper>
            <ContentWrapper>{centerContent}</ContentWrapper>
            <ContentWrapper>{rightContent}</ContentWrapper>
          </FooterContent>
        </Wrapper>

        <Wrapper>
          <BottomBackground />
          <FooterContent isBottom>{bottomContent}</FooterContent>
        </Wrapper>
      </Container>
    );
  }
}

export default Footer;
