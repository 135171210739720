import styled, { css } from "styled-components";

import Button from "components/Button";

import styleConst from "constants/style";

interface IItemWrapper {
  inline?: boolean;
}

interface ISubmitButton {
  inline?: boolean;
}

interface IItemsContainer {
  inline?: boolean;
}

export const SubmitButton = styled(Button)<ISubmitButton>`
  border-radius: 10px;
  border: none;
  background: radial-gradient(
      83.3% 179.17% at 56.29% -31.25%,
      ${styleConst.colors.primaryMedium} 0%,
      ${styleConst.colors.primary} 100%
    ),
    ${styleConst.colors.primary};
  box-shadow: 0px 20px 40px -10px ${styleConst.colors.shadow};

  ${({ inline }) =>
    inline &&
    css`
      margin-left: 40px;
    `}
`;

export const ItemWrapper = styled.div<IItemWrapper>`
  display: flex;

  justify-content: flex-start;
  align-items: flex-start;

  ${({ inline }) =>
    inline
      ? css`
          width: 240px;
          &:not(:first-child) {
            margin-left: 20px;
          }
        `
      : css`
          padding-top: 22px;
          width: 278px;
        `}
`;

export const ItemsContainer = styled.div<IItemsContainer>`
  ${({ inline }) =>
    inline &&
    css`
      display: flex;
    `}
`;
