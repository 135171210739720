export const CATEGORY_LIST_PAGE_SIZE = 50;
export const CATEGORY_CONTENT_PAGE_SIZE = 50;

export const CONTENT_STORE_TYPE = {
  WEEK: "week",
  STANDARD_FAVORITES: "favorites",
  STANDARD_ALL: "all",
  STANDARD_NEWEST: "newest",
};

export const CONTENT_STORE_TYPE_LABEL = {
  WEEK: "Week",
  STANDARD_FAVORITES: "Favorites",
  STANDARD_ALL: "All",
  STANDARD_NEWEST: "Newest",
};
