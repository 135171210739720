import * as React from "react";

import style from "constants/style";

type Props = {
  color?: string;
  viewBox?: string;
};

const I24ArrowLeftSwiper = ({ color = style.colors.text }: Props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1213 5.29289C15.5118 5.68342 15.5118 6.31658 15.1213 6.70711L9.82843 12L15.1213 17.2929C15.5118 17.6834 15.5118 18.3166 15.1213 18.7071C14.7308 19.0976 14.0976 19.0976 13.7071 18.7071L7 12L13.7071 5.29289C14.0976 4.90237 14.7308 4.90237 15.1213 5.29289Z"
        fill={color}
      />
    </svg>
  );
};

export default I24ArrowLeftSwiper;
