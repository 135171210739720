import styled, { css } from "styled-components";

import { SnackbarType, SnackbarTypeValues } from "./Snackbar";

import styleConst from "constants/style";

interface IContainer {
  type: SnackbarType;
  position: string;
}

export const Container = styled.div<IContainer>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  position: fixed;

  left: calc(100% - 50px);
  width: max-content;
  max-width: calc(100% - 200px);
  height: auto;
  z-index: 10000;
  transform: translate(-100%, -100%);
  padding: 20px;

  border-radius: 4px;
  box-shadow: 0 6px 16px 0 ${styleConst.colors.black10};

  ${({ type }) => {
    switch (type) {
      case SnackbarTypeValues.ALERT:
        return css`
          background-color: ${styleConst.colors.red};
        `;
      case SnackbarTypeValues.WARNING:
        return css`
          background-color: ${styleConst.colors.redLight};
        `;
      default:
        return css`
          background-color: ${styleConst.colors.primary};
        `;
    }
  }}

  ${({ position }) =>
    position === "bottom"
      ? css`
          top: calc(100% - 50px);
        `
      : css`
          top: 125px;
        `}
`;

export const Text = styled.p`
  margin-left: 6px;

  font-family: ${styleConst.fonts.roboto};
  font-size: 14px;
  color: ${styleConst.colors.white};
`;
