import React from "react";

import I24RadioOn from "assets/icons/I24RadioButtonOn";
import I24RadioOff from "assets/icons/I24RadioButtonOff";

import styleConst from "constants/style";

import {
  Container,
  Content,
  RadioContainer,
  Row,
  RowLabel,
  RowLabelContainer,
  LabelContainer,
  Label,
  RadioButtonWrapper,
} from "./styles";

export type Option = {
  value: string;
  label?: string;
  component?: React.ReactNode;
  align?: string;
};

type Props = {
  label?: string;
  options: Array<Option>;
  selected?: string;
  disabled?: boolean;
  onSelect: (option: Option) => void;
  error?: boolean;
  highlightSelected?: boolean;
  modal?: boolean;
  form?: boolean;
  numberOfLabelLines?: number;
  showIndicator?: boolean;
  showInLine?: boolean;
  isQuestionType?: boolean;
};

type RadioButtonProps = {
  selected?: boolean;
  disabled?: boolean;
  option: Option;
  onSelect: (option: Option) => void;
  error?: boolean;
  highlightSelected?: boolean;
  last?: boolean;
  modal?: boolean;
  form?: boolean;
  numberOfLabelLines?: number;
  isQuestionType?: boolean;
  showInLine?: boolean;
};

class RadioButton extends React.Component<RadioButtonProps> {
  onClick = () => {
    const { option, onSelect, disabled } = this.props;
    if (disabled) return;
    onSelect(option);
  };

  render() {
    const {
      option,
      selected,
      disabled,
      last,
      highlightSelected,
      modal,
      form,
      isQuestionType,
      showInLine,
    } = this.props;

    return (
      <Row
        activeOpacity={disabled ? 0.2 : 1}
        onClick={this.onClick}
        last={last}
        align={option.align ?? "center"}
        highlightSelected={highlightSelected}
        selected={selected}
        modal={modal}
        form={form}
        isQuestionType={isQuestionType}
        showInLine={showInLine}
      >
        <RadioContainer>
          {selected ? (
            <I24RadioOn color={styleConst.colors.primary} />
          ) : (
            <I24RadioOff color={styleConst.colors.black25} />
          )}
        </RadioContainer>
        <RowLabelContainer>
          {option.label ? (
            <RowLabel form={form}>{option.label}</RowLabel>
          ) : (
            option.component
          )}
        </RowLabelContainer>
      </Row>
    );
  }
}

class RadioButtonList extends React.Component<Props> {
  render() {
    const {
      label,
      options,
      selected,
      disabled,
      error,
      onSelect,
      highlightSelected,
      modal,
      form,
      numberOfLabelLines,
      showIndicator,
      showInLine,
      isQuestionType,
    } = this.props;

    return (
      <Container showIndicator={showIndicator}>
        <Content>
          {label ? (
            <LabelContainer>
              <Label>{label}</Label>
            </LabelContainer>
          ) : null}
          <RadioButtonWrapper showInLine={showInLine}>
            {options.map((option, index) => (
              <RadioButton
                key={`${option.value}-${index}`}
                option={option}
                onSelect={onSelect}
                selected={selected?.toString() === option.value?.toString()}
                highlightSelected={highlightSelected}
                disabled={disabled}
                error={error}
                last={options.length === index + 1}
                modal={modal}
                form={form}
                numberOfLabelLines={numberOfLabelLines}
                isQuestionType={isQuestionType}
                showInLine={showInLine}
              />
            ))}
          </RadioButtonWrapper>
        </Content>
      </Container>
    );
  }
}

export default RadioButtonList;
export { RadioButton };
