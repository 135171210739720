import React from "react";
import { observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroll-component";

import Spinner from "components/Spinner";

import styleConst from "constants/style";
import { CONTAINER_SCROLLABLE_DIV_ID } from "constants/layout";

import { Container, ScrollLoaderWrapper } from "./styles";

type Props<ItemT> = {
  items: ReadonlyArray<ItemT> | null;
  needMore?: boolean;
  style?: React.CSSProperties;
  onLoadMore?: () => void;
  renderItem: (item: ItemT, index?: number) => React.ReactElement | null;
  scrollableTarget?: React.ReactNode;
  dataLength?: number;
};

@observer
class List<ItemT = any> extends React.Component<Props<ItemT>> {
  get onLoadMore() {
    const { onLoadMore } = this.props;
    const fn = () => null;
    return onLoadMore ?? fn;
  }

  get dataLength() {
    const { items, dataLength } = this.props;

    return dataLength || items?.length;
  }

  renderContent = () => {
    const { items, renderItem } = this.props;

    return (
      <Container>
        {items?.map((item, index) => renderItem(item, index))}
      </Container>
    );
  };

  render() {
    const { needMore, style, scrollableTarget } = this.props;

    return (
      <InfiniteScroll
        dataLength={this.dataLength ?? 0}
        hasMore={Boolean(needMore)}
        next={this.onLoadMore}
        loader={
          <ScrollLoaderWrapper>
            <Spinner color={styleConst.colors.primary} large />
          </ScrollLoaderWrapper>
        }
        scrollableTarget={scrollableTarget || CONTAINER_SCROLLABLE_DIV_ID}
        style={style}
      >
        {this.renderContent()}
      </InfiniteScroll>
    );
  }
}

export default List;
