import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import ReactResizeDetector from "react-resize-detector";

import Tooltip from "components/Tooltip";

import { Container, Wrapper } from "./styles";

type Props = {
  lines?: number;
  text?: string;
  duration?: [number | null, number | null] | number;
  children: any;
};

type TrimmedProps = {
  lines?: number;
  className?: any;
  children: any;
  getRef?: (ref: any) => void;
};

const CONTENT_IS_TRIMMED_ACCURACY = 2;

const Trimmed = ({ getRef, lines, children, className }: TrimmedProps) => (
  <Wrapper lines={lines} ref={getRef} className={className}>
    {children}
  </Wrapper>
);

@observer
class TrimmedText extends React.Component<Props> {
  @observable contentRef: any = null;
  @observable shouldOpenTooltip: boolean = false;

  @computed get isContentTrimmed() {
    if (this.contentRef) {
      const { offsetHeight, offsetWidth, scrollHeight, scrollWidth } =
        this.contentRef;

      return (
        Math.abs(scrollHeight - offsetHeight) >= CONTENT_IS_TRIMMED_ACCURACY ||
        Math.abs(scrollWidth - offsetWidth) >= CONTENT_IS_TRIMMED_ACCURACY
      );
    }

    return false;
  }

  componentDidMount() {
    this.shouldOpenTooltip = this.isContentTrimmed;
  }

  getRef = (ref: any) => {
    this.contentRef = ref;
  };

  onResize = () => {
    this.shouldOpenTooltip = this.isContentTrimmed;
  };

  render() {
    const { lines, text, children, duration } = this.props;

    if (!lines) return children ?? text;

    return (
      <Tooltip
        content={text}
        shouldOpen={this.shouldOpenTooltip}
        duration={duration}
        childrenWithEllipsis
      >
        <Container>
          <ReactResizeDetector onResize={this.onResize}>
            <Trimmed {...this.props} getRef={this.getRef} />
          </ReactResizeDetector>
        </Container>
      </Tooltip>
    );
  }
}

export default TrimmedText;
