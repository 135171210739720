import { applySnapshot, flow, types } from "mobx-state-tree";

import api from "services/api";

import { Recipe } from "models/Recipe";

const RecipeFavorites = types
  .model("RecipeFavorites", {
    items: types.array(Recipe),
    isLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    fetch: flow(function* () {
      self.isLoading = true;
      try {
        const { items } = yield api.get("/mobile/food/recipe/favorite/list");
        applySnapshot(self, { ...self, items });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    addToFavorites: flow(function* (id: number) {
      try {
        yield api.post(`/mobile/food/recipe/${id}/favorite`);
      } catch (error) {
        console.log("error :>> ", error);
      }
    }),

    removeFromFavorites: flow(function* (id: number) {
      try {
        yield api.delete(`/mobile/food/recipe/${id}/favorite`);
      } catch (error) {
        console.log("error :>> ", error);
      }
    }),
  }));

const RecipeFavoritesStore = RecipeFavorites.create();

export default RecipeFavoritesStore;
