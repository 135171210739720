import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { generatePath } from "react-router-dom";

import { Placeholders } from "assets/images/placeholders";

import RecipeLibraryStore from "stores/RecipeLibraryStore";

import List from "components/List";
import Spinner from "components/Spinner";
import FormTitle from "components/Form/FormTitle";
import Placeholder from "components/Placeholder";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import GroupHeader from "../../GroupHeader";
import FormRecipeItem from "../../FormRecipeItem";

import { NoInternetConnectionError } from "services/api";

import ROUTES from "navigation/routes";

import { capitalize, generateAppSectionLink } from "utils/common";

import {
  Loader,
  OverflowLoader,
  ScrollContentWrapper,
  SectionWrapper,
  SectionTitle,
  SectionTitleAdornment,
  SectionTitleContainer,
  NoInternetConnectionPlaceholderWrapper,
} from "./styles";

type Props = {
  onSubmit: (recipeId: number) => void;
  submitIsLoading?: boolean;
  onClose: () => void;
};

const COLUMN_NAMES = ["Carbs", "Fiber", "Fat", "Protein"];

@observer
class RecipesListForm extends React.Component<Props> {
  @observable page: number = 1;
  @observable isLoading: boolean = false;
  @observable isNoInternetConnectionError: boolean = false;

  @computed get details() {
    return RecipeLibraryStore.details;
  }

  @computed get categoryId() {
    return this.details?.id;
  }

  @computed get isWeekStructure() {
    return RecipeLibraryStore.details?.isWeekStructure;
  }

  @computed get items() {
    return RecipeLibraryStore.availableRecipes;
  }

  @computed get sectionsAll() {
    return RecipeLibraryStore.availableSections;
  }

  @computed get filteredSections() {
    return this.sectionsAll.filter(
      ({ data }: { data: any[] }) => !!data.length,
    );
  }

  @computed get loadedSections() {
    return this.needMore ? this.filteredSections : this.sectionsAll;
  }

  @computed get needMore() {
    const { recipeContext } = RecipeLibraryStore;
    return recipeContext && this.page < recipeContext.pages;
  }

  async componentDidMount() {
    await this.fetch();
  }

  fetch = async () => {
    this.isLoading = true;

    try {
      if (this.categoryId) {
        await RecipeLibraryStore.fetchRecipes({
          page: this.page,
          categoryId: this.categoryId,
        });
      }
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    } finally {
      this.isLoading = false;
    }
  };

  onLoadMore = async () => {
    if (this.needMore) {
      this.page++;
      await RecipeLibraryStore.fetchRecipes({
        page: this.page,
        categoryId: this.categoryId,
      });
    }
  };

  onRecipeClick = (item: any) => () => {
    const { id } = item;

    if (id && this.categoryId) {
      const link = generateAppSectionLink(
        generatePath(ROUTES.NUTRITION_RECIPE_LIBRARY_RECIPE_DETAILS, {
          categoryId: this.categoryId,
          recipeId: id,
        }),
      );

      window.open(link);
    }
  };

  renderItem = (item: any) => (
    <FormRecipeItem
      key={item.id}
      recipe={item}
      onRecipeClick={this.onRecipeClick(item)}
      onSubmit={this.props.onSubmit}
    />
  );

  renderSection = (section: any) => (
    <>
      <SectionTitleContainer>
        <SectionTitleAdornment />
        <SectionTitle>{capitalize(section.title)}</SectionTitle>
      </SectionTitleContainer>
      {section.data.length ? (
        <SectionWrapper>
          <GroupHeader
            title={"Recipes"}
            items={COLUMN_NAMES}
            extraItems={["# Of Servings"]}
          />
          {section.data.map((recipe: any) => this.renderItem(recipe))}
        </SectionWrapper>
      ) : (
        this.renderPlaceholder()
      )}
    </>
  );

  renderPlaceholder = () => (
    <Placeholder imageUrl={Placeholders.Recipes} text="No recipes yet" />
  );

  render() {
    const { submitIsLoading, onClose } = this.props;

    if (this.isNoInternetConnectionError) {
      return (
        <NoInternetConnectionPlaceholderWrapper>
          <NoInternetConnectionPlaceholder type={"popup"} onSubmit={onClose} />
        </NoInternetConnectionPlaceholderWrapper>
      );
    }

    return !this.isLoading ? (
      <>
        <FormTitle title={this.details?.title} />

        {submitIsLoading && (
          <OverflowLoader>
            <Spinner large />
          </OverflowLoader>
        )}
        <ScrollContentWrapper id="scrollableDiv">
          {!this.isWeekStructure ? (
            <>
              {this.items.length ? (
                <>
                  <GroupHeader
                    title={"Recipes"}
                    items={COLUMN_NAMES}
                    extraItems={["# Of Servings"]}
                  />
                  <List
                    items={this.items}
                    renderItem={this.renderItem}
                    onLoadMore={this.onLoadMore}
                    needMore={this.needMore}
                    scrollableTarget={"scrollableDiv"}
                    style={{ overflowY: "hidden" }}
                  />
                </>
              ) : (
                this.renderPlaceholder()
              )}
            </>
          ) : (
            <List
              dataLength={this.items.length}
              items={this.loadedSections}
              renderItem={this.renderSection}
              onLoadMore={this.onLoadMore}
              needMore={this.needMore}
              scrollableTarget={"scrollableDiv"}
              style={{ overflowY: "hidden" }}
            />
          )}
        </ScrollContentWrapper>
      </>
    ) : (
      <Loader>
        <Spinner large />
      </Loader>
    );
  }
}

export default RecipesListForm;
