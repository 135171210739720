import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Calculator = ({ color = styleConst.colors.black }: Props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      fill={color}
      fillRule="evenodd"
      d="M18 1H6c-1.105 0-2 .895-2 2v18c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2V3c0-1.105-.895-2-2-2zM6 7V3h12v4H6zm0 2v12h12V9H6zm4 10H8v-2h2v2zm1 0h2v-2h-2v2zm5 0h-2v-5h2v5zm-8-3h2v-2H8v2zm5 0h-2v-2h2v2zm-3-3H8v-2h2v2zm1 0h2v-2h-2v2zm5 0h-2v-2h2v2z"
      clipRule="evenodd"
    />
  </svg>
);

export default I24Calculator;
