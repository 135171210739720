import styled, { css } from "styled-components";

import styleConst from "constants/style";

import background from "assets/images/svg/sign_in_background.svg";
import logo from "assets/images/svg/logo_full.svg";

export const Background = styled.div`
  display: flex;

  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: inline-block;

  width: 360px;

  padding: 40px 27px;

  box-shadow: 5px 20px 30px ${styleConst.colors.shadow};

  border-radius: 10px;

  background-color: ${styleConst.colors.white};
`;

export const LogoContainer = styled.div`
  display: flex;

  justify-content: center;

  padding: 19px 0;
`;

export const Logo = styled.img.attrs({ src: logo })``;

export const Title = styled.p`
  margin-top: 30px;

  font-family: ${styleConst.fonts.montserrat};
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;

  text-align: center;
`;

export const ItemWrapper = styled.div`
  margin-top: 20px;

  text-align: center;
`;

export const Link = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;

  color: ${styleConst.colors.primary};
  cursor: pointer;
`;

export const errorTextStyles = css`
  color: ${styleConst.colors.primaryRed1};
`;
