import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IValue {
  indent?: boolean;
  color?: string;
}

interface ILabel {
  color?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 100%;
`;

export const Label = styled.div<ILabel>`
  font-weight: 500;
  font-size: 16px;
  font-family: ${styleConst.fonts.roboto};
  text-align: center;
  color: ${({ color = styleConst.colors.dark }) => color};
  text-transform: capitalize;
`;

export const Nutrient = styled.div`
  width: 80px;
  padding: 0 10px;
`;

export const Value = styled.div<IValue>`
  ${({ indent }) =>
    indent &&
    css`
      margin-bottom: 7px;
    `}

  color: ${({ color = styleConst.colors.dark }) => color};

  font-size: 16px;
  font-weight: 500;
  font-family: ${styleConst.fonts.roboto};
  text-align: center;
  line-height: 18.75px;
`;
