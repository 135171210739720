import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { generatePath } from "react-router-dom";

import I24MyItems from "assets/icons/I24MyItems";
import I24ArrowBack from "assets/icons/I24ArrowBack";
import { Placeholders } from "assets/images/placeholders";

import FoodFavoritesStore from "stores/FoodFavoritesStore";
import MyRecipesStore from "stores/MyRecipesStore";
import RecipeFavoritesStore from "stores/RecipeFavoritesStore";

import ButtonInline from "components/ButtonInline";
import FormTitle from "components/Form/FormTitle";
import List from "components/List";
import Spinner from "components/Spinner";
import Tabs from "components/Tabs";
import Placeholder from "components/Placeholder/Placeholder";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import FormRecipeItem from "../FormRecipeItem";
import GroupHeader from "../GroupHeader";
import FoodDetailsForm from "../FoodDetailsForm";
import { AddFoodSubmitPayloadType, ADD_RECIPE_TYPE } from "../AddFoodControl";

import { NoInternetConnectionError } from "services/api";

import { generateAppSectionLink } from "utils/common";

import { MY_ITEMS_TABS } from "constants/nutrition";
import ROUTES from "navigation/routes";

import {
  BackButtonWrapper,
  Container,
  Loader,
  ScrollContentWrapper,
  TabsWrapper,
  OverflowLoader,
} from "./styles";

type Props = {
  onSubmit: (payload: AddFoodSubmitPayloadType) => void;
  submitIsLoading?: boolean;
  onClose: () => void;
};

type StoreType = {
  items: any[];
  context?: any;
  fetch: (page?: number) => Promise<void>;
};

const COLUMN_NAMES = ["Carbs", "Fiber", "Fat", "Protein"];

@observer
class MyItemsForm extends React.Component<Props> {
  @observable activeTab: string = MY_ITEMS_TABS.MY_RECIPES;
  @observable formPage: number = 1;
  @observable isLoading: boolean = false;
  @observable page: number = 1;
  @observable isNoInternetConnectionError: boolean = false;

  @computed get store(): StoreType | undefined {
    switch (this.activeTab) {
      case MY_ITEMS_TABS.FAVORITES: {
        return RecipeFavoritesStore;
      }
      case MY_ITEMS_TABS.MY_FOOD: {
        return FoodFavoritesStore;
      }
      case MY_ITEMS_TABS.MY_RECIPES: {
        return MyRecipesStore;
      }
    }
  }

  @computed get items() {
    return this.store?.items ?? [];
  }

  @computed get needMore() {
    const context = this.store?.context;
    return context && this.page < context.pages;
  }

  @computed get isMyFood() {
    return this.activeTab === MY_ITEMS_TABS.MY_FOOD;
  }

  @computed get isFavorites() {
    return this.activeTab === MY_ITEMS_TABS.FAVORITES;
  }

  @computed get isMyRecipes() {
    return this.activeTab === MY_ITEMS_TABS.MY_RECIPES;
  }

  @computed get addRecipeType() {
    switch (this.activeTab) {
      case MY_ITEMS_TABS.FAVORITES: {
        return ADD_RECIPE_TYPE.RECIPE_LIBRARY;
      }
      case MY_ITEMS_TABS.MY_FOOD: {
        return ADD_RECIPE_TYPE.DEFAULT;
      }
      case MY_ITEMS_TABS.MY_RECIPES: {
        return ADD_RECIPE_TYPE.MY_ITEMS_MY_RECIPES;
      }
    }
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    this.isLoading = true;

    try {
      await this.store?.fetch(this.page);
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    } finally {
      this.isLoading = false;
    }
  };

  goBack = () => {
    this.formPage--;

    if (this.isMyFood) {
      this.init();
    }
  };

  goForward = () => {
    this.formPage++;
  };

  onLoadMore = async () => {
    if (this.needMore) {
      this.page++;
      await this.store?.fetch(this.page);
    }
  };

  onChangeTab = (tab: string) => {
    this.activeTab = tab;

    this.resetPage();
    this.init();
  };

  onRecipeClick = (recipe: any) => () => {
    if (this.isMyFood) {
      FoodFavoritesStore.fetchDetails(recipe.id);
      this.goForward();
    }

    if (this.isFavorites) {
      const { id: recipeId } = recipe;
      if (recipeId) {
        const link = generateAppSectionLink(
          generatePath(ROUTES.NUTRITION_MY_ITEMS_FAVORITES_RECIPE, {
            recipeId,
          }),
        );

        window.open(link);
      }
    }

    if (this.isMyRecipes) {
      const { id: recipeId } = recipe;
      if (recipeId) {
        const link = generateAppSectionLink(
          generatePath(ROUTES.NUTRITION_MY_ITEMS_MY_RECIPES_DETAILS, {
            recipeId,
          }),
        );

        window.open(link);
      }
    }
  };

  onSubmit = (values: any) => {
    const { onSubmit } = this.props;

    this.addRecipeType &&
      onSubmit({ values, addRecipeType: this.addRecipeType });
  };

  renderHeader = () => {
    const title = this.isMyFood ? "Food" : "Recipes";
    const items = this.isMyFood ? [] : COLUMN_NAMES;
    const extraItems = this.isMyFood ? [] : ["# Of Servings"];

    return <GroupHeader title={title} items={items} extraItems={extraItems} />;
  };

  renderItem = (item: any) => {
    const recipe = this.isFavorites ? item : { ...item, title: item.name };

    return (
      <FormRecipeItem
        key={item.id}
        recipe={recipe}
        onRecipeClick={this.onRecipeClick(item)}
        onSubmit={this.onSubmit}
        withoutControls={this.isMyFood || item.isArchived}
        withoutImage
      />
    );
  };

  renderPlaceholder = () => {
    switch (this.activeTab) {
      case MY_ITEMS_TABS.MY_RECIPES:
      case MY_ITEMS_TABS.FAVORITES:
        return (
          <Placeholder
            inline={false}
            imageUrl={Placeholders.Recipes}
            text="No recipes yet"
          />
        );
      default:
        return (
          <Placeholder
            inline={false}
            text={"Food will be displayed here"}
            imageUrl={Placeholders.Favorites}
          />
        );
    }
  };

  resetPage = () => {
    this.page = 1;
  };

  render() {
    const { submitIsLoading, onClose } = this.props;

    return (
      <Container isFoodDetails={this.formPage === 2}>
        {this.isNoInternetConnectionError ? (
          <NoInternetConnectionPlaceholder type={"popup"} onSubmit={onClose} />
        ) : (
          <>
            {this.formPage === 1 && (
              <>
                <FormTitle title="My Items" icon={<I24MyItems />} />

                {submitIsLoading && (
                  <OverflowLoader>
                    <Spinner large />
                  </OverflowLoader>
                )}
                <TabsWrapper>
                  <Tabs
                    tabs={Object.values(MY_ITEMS_TABS)}
                    activeTab={this.activeTab}
                    onClick={this.onChangeTab}
                  />
                </TabsWrapper>
                {!this.isLoading ? (
                  <ScrollContentWrapper id="scrollableDiv">
                    {this.renderHeader()}
                    {this.items.length ? (
                      <List
                        items={this.items}
                        needMore={this.needMore}
                        onLoadMore={this.onLoadMore}
                        renderItem={this.renderItem}
                        scrollableTarget={"scrollableDiv"}
                        style={{ overflowY: "hidden" }}
                      />
                    ) : (
                      this.renderPlaceholder()
                    )}
                  </ScrollContentWrapper>
                ) : (
                  <Loader>
                    <Spinner large />
                  </Loader>
                )}
              </>
            )}

            {this.formPage === 2 && (
              <>
                <BackButtonWrapper>
                  <ButtonInline
                    text={"Back"}
                    iconLeft={<I24ArrowBack />}
                    onPress={this.goBack}
                  />
                </BackButtonWrapper>
                <FoodDetailsForm
                  onSubmit={this.onSubmit}
                  myFoodMode
                  submitIsLoading={submitIsLoading}
                />
              </>
            )}
          </>
        )}
      </Container>
    );
  }
}

export default MyItemsForm;
