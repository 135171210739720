import React from "react";
import { FormState } from "final-form";

import { FieldData } from "./types";

export type FormContextType = {
  registerField?: (field: FieldData) => void;
  handleSubmit?: () => void;
  reset?: () => void;
  getState?: () => FormState<any>;
};

const FormContext = React.createContext<FormContextType>({});

export default FormContext;

export const withFormContext =
  (Wrapped: React.ClassType<any, any, any>): any =>
  // eslint-disable-next-line
  (props: any) => {
    function createContextConsumer(context: FormContextType) {
      return <Wrapped {...props} {...context} />;
    }

    return (
      <>
        <FormContext.Consumer>{createContextConsumer}</FormContext.Consumer>
      </>
    );
  };
