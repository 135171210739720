import React from "react";

import styleConst from "constants/style";

import { Bar, Container } from "./styles";

type Props = {
  count: number;
  current: number;
  height?: number;
  tintColor?: string;
  backgroundColor?: string;
  width?: number;
};

const ProgressBar = ({
  count,
  current,
  height = 8,
  tintColor = styleConst.colors.primary,
  backgroundColor = styleConst.colors.black10,
  width = 100,
}: Props) => (
  <Container
    height={height}
    backgroundColor={backgroundColor}
    tintColor={tintColor}
    width={width}
  >
    {count ? (
      <Bar
        count={count}
        current={current > count ? count : current}
        height={height}
        tintColor={tintColor}
        width={width}
      />
    ) : null}
  </Container>
);

export default ProgressBar;
