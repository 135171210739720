import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I32Cross = ({ color = styleConst.colors.white }: Props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M24.4702 8.92998C24.5667 8.84125 24.6419 8.73188 24.6902 8.61C24.7406 8.48602 24.7677 8.3538 24.7702 8.21999C24.7686 8.08931 24.7415 7.9602 24.6902 7.83998C24.6446 7.71382 24.5691 7.6006 24.4702 7.51C24.284 7.32525 24.0325 7.22109 23.7702 7.21999C23.6386 7.21923 23.5081 7.24444 23.3863 7.29421C23.2645 7.34397 23.1537 7.41731 23.0602 7.51L16.0602 14.58L8.9902 7.51C8.89676 7.41731 8.78596 7.34397 8.66412 7.29421C8.54229 7.24444 8.41182 7.21923 8.28021 7.21999C8.14861 7.21923 8.01814 7.24444 7.8963 7.29421C7.77446 7.34397 7.66363 7.41731 7.57019 7.51C7.47646 7.60296 7.40209 7.71355 7.35132 7.8354C7.30055 7.95726 7.27441 8.08798 7.27441 8.21999C7.27441 8.352 7.30055 8.48271 7.35132 8.60457C7.40209 8.72643 7.47646 8.83702 7.57019 8.92998L14.6402 16L7.57019 23.07C7.38394 23.2574 7.27942 23.5108 7.27942 23.775C7.27942 24.0392 7.38394 24.2926 7.57019 24.48C7.66711 24.5713 7.77868 24.6456 7.90021 24.7C8.02043 24.7512 8.14954 24.7784 8.28021 24.78C8.41426 24.7797 8.54687 24.7525 8.6702 24.7C8.79021 24.6483 8.89894 24.5735 8.9902 24.48L16.0602 17.41L23.1302 24.48C23.3217 24.6442 23.5682 24.7299 23.8202 24.72C24.0854 24.72 24.3398 24.6146 24.5273 24.4271C24.7148 24.2396 24.8202 23.9852 24.8202 23.72C24.8301 23.468 24.7444 23.2215 24.5802 23.03L17.5102 15.96L24.4702 8.92998Z"
      fill={color}
    />
  </svg>
);

export default I32Cross;
