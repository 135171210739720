import React from "react";
import { observer } from "mobx-react";

import AddInScheduleForm from "components/Studio/AddInScheduleForm";

import { Container, Content, Title, Header, Wrapper } from "./styles";

type Props = {
  onClose: () => void;
};

@observer
class AddInSchedulePopup extends React.Component<Props> {
  render() {
    const { onClose } = this.props;
    return (
      <Container>
        <Content>
          <Header>
            <Title>Add In Schedule</Title>
          </Header>
          <Wrapper>
            <AddInScheduleForm onSubmit={onClose} />
          </Wrapper>
        </Content>
      </Container>
    );
  }
}

export default AddInSchedulePopup;
