import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { FormSpy } from "react-final-form";

import I36Star from "assets/icons/I36Star";
import I36StarFilled from "assets/icons/I36StarFilled";
import I32Plus from "assets/icons/I32Plus";

import FoodManualStore from "stores/FoodManualStore";
import FoodFavoritesStore from "stores/FoodFavoritesStore";

import FormTitle from "components/Form/FormTitle";
import FormControl, {
  DecimalNumberFieldControl,
  DropdownFieldControl,
  SubmitButtonControl,
} from "components/Form";
import FoodNutrientsRow from "components/FoodNutrientsRow";
import Spinner from "components/Spinner";
import { SnackbarTypeValues } from "components/Snackbar";
import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";

import {
  getNutrientLabel,
  getNutrientsList,
  getNutrientValue,
  getMainFields,
  getServingValue,
  getCurrentNutritionalInfo,
} from "utils/nutrients";
import { isPositiveNumber } from "utils/validation";

import styleConst from "constants/style";

import {
  Column,
  FavoriteContainer,
  Row,
  ScrollLoader,
  FavoriteIconButton,
  NutrientsInfo,
  NutrientWrapper,
  SubmitButtonWrapper,
  SubmitButton,
  InlineListItem,
  Nutrients,
  NutrientsTitle,
} from "./styles";

type Props = {
  onSubmit: (values: any) => void;
  barcodeMode?: boolean;
  myFoodMode?: boolean;
  submitIsLoading?: boolean;
} & SnackbarContextProps;

@observer
class FoodDetailsForm extends React.Component<Props> {
  @observable nutritionalInfo: any = undefined;

  get isMyFoodMode() {
    const { myFoodMode } = this.props;
    return myFoodMode;
  }

  @computed get store() {
    const { myFoodMode } = this.props;

    return myFoodMode ? FoodFavoritesStore : FoodManualStore;
  }

  @computed get isLoading() {
    return this.store.isLoading;
  }

  @computed get isUpdating() {
    return FoodFavoritesStore.isUpdating;
  }

  @computed get details() {
    const { barcodeMode } = this.props;

    return !barcodeMode
      ? this.store.details
      : FoodManualStore.barcodeItemDetails;
  }

  @computed get isFavorite() {
    return !this.isMyFoodMode
      ? this.details?.isFavorite
      : this.details?.favoriteId;
  }

  @computed get initialValues() {
    const { barcodeMode } = this.props;

    if (barcodeMode || this.details?.servings.length === 1) {
      return { serving: this.details?.servings[0], amount: 1 };
    }

    return { amount: 1 };
  }

  @computed get servingSizeOptions() {
    return this.details?.servings.map(value => ({
      label: getServingValue(value),
      value,
    }));
  }

  @computed get mainNutrients() {
    return this.nutritionalInfo
      ? getNutrientsList(getMainFields(this.nutritionalInfo))
      : undefined;
  }

  @computed get allNutrients() {
    return this.nutritionalInfo
      ? getNutrientsList(this.nutritionalInfo)
      : undefined;
  }

  @computed get isArchived() {
    return this.details?.isArchived;
  }

  onChangeFormValues = ({ values }: { values: any }) => {
    const { serving, amount } = values;

    if (serving && serving.nutritionalInfo && amount) {
      this.nutritionalInfo = getCurrentNutritionalInfo(
        serving.nutritionalInfo,
        amount,
      );
    }
  };

  onFormSubmit = (values: any) => {
    const { onSubmit } = this.props;

    const foodPayload = {
      ...this.details,
      serving: { ...values.serving, amount: values.amount },
    };

    onSubmit(foodPayload);
  };

  onFavoriteClick = () => {
    if (this.isFavorite) {
      this.removeFromFavorites(this.details);
    } else {
      this.addToFavorites(this.details);
    }
  };

  addToFavorites = async (item: any) => {
    const { setMessage } = this.props;

    try {
      await FoodFavoritesStore.addToFavorites(item);
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    }
  };

  removeFromFavorites = async (item: any) => {
    const { setMessage } = this.props;

    try {
      await FoodFavoritesStore.removeFromFavorites(item);
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    }
  };

  renderSubmitButton = ({ onClick }: { onClick: () => void }) => (
    <SubmitButtonWrapper>
      <SubmitButton
        iconLeft={<I32Plus />}
        text="Add to Food Log"
        onClick={onClick}
        loading={this.props.submitIsLoading}
      />
    </SubmitButtonWrapper>
  );

  render() {
    const { barcodeMode } = this.props;

    return (
      <>
        {!this.isLoading ? (
          <>
            <FormControl
              onSubmit={this.onFormSubmit}
              initialValues={this.initialValues}
            >
              <FormSpy
                subscription={{ values: true }}
                onChange={this.onChangeFormValues}
              />
              <FormTitle
                title={this.details?.name}
                subtitle={this.details?.brand}
              />

              <Row barcodeForm={barcodeMode} formRow>
                {!barcodeMode && (
                  <Column>
                    <DropdownFieldControl
                      name="serving"
                      options={this.servingSizeOptions}
                      label="Serving Size"
                      required
                      withoutErrorText
                    />
                  </Column>
                )}
                <Column>
                  <DecimalNumberFieldControl
                    name="amount"
                    label="Number of Servings"
                    validate={isPositiveNumber}
                    onlyPositiveNumbers
                    required
                    withoutErrorText
                  />
                </Column>
              </Row>

              {this.mainNutrients && (
                <FoodNutrientsRow
                  nutrients={this.mainNutrients}
                  control={
                    <FavoriteContainer>
                      <FavoriteIconButton
                        onClick={this.onFavoriteClick}
                        backgroundColor={
                          this.isFavorite
                            ? styleConst.colors.primary10
                            : undefined
                        }
                        icon={this.isFavorite ? <I36StarFilled /> : <I36Star />}
                        isLoading={this.isUpdating}
                      />
                      {this.isFavorite ? "Unfavorite" : "Favorite"}
                    </FavoriteContainer>
                  }
                />
              )}

              {this.allNutrients && (
                <Row formRow>
                  <Nutrients>
                    <NutrientsTitle>Nutrition Facts</NutrientsTitle>
                    <NutrientsInfo>
                      {this.allNutrients.map(({ label, value }) => (
                        <NutrientWrapper key={`${label}-${value}`}>
                          <InlineListItem
                            label={getNutrientLabel(label)}
                            value={getNutrientValue(label, value)}
                          />
                        </NutrientWrapper>
                      ))}
                    </NutrientsInfo>
                  </Nutrients>
                </Row>
              )}

              {!this.isArchived ? (
                <SubmitButtonControl render={this.renderSubmitButton} />
              ) : null}
            </FormControl>
          </>
        ) : (
          <Row>
            <ScrollLoader>
              <Spinner color={styleConst.colors.primary} large />
            </ScrollLoader>
          </Row>
        )}
      </>
    );
  }
}

export default withGlobalSnackbar(FoodDetailsForm);
