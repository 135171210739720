import React from "react";
import Lottie from "lottie-react";

import { WaterProgressContainer, WaterProgressBar } from "./styles";

type Props = {
  progress: number;
};

class WaterProgress extends React.Component<Props> {
  render() {
    const { progress } = this.props;

    return (
      <WaterProgressContainer>
        <WaterProgressBar progress={progress}>
          <Lottie
            style={{ width: 340, height: 340, alignSelf: "center" }}
            animationData={require("assets/animations/FasterWay-Nutrition-Add Water.json")}
            autoplay
            loop
          />
        </WaterProgressBar>
      </WaterProgressContainer>
    );
  }
}

export default WaterProgress;
