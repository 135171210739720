import styled, { css, FlattenSimpleInterpolation } from "styled-components";

import styleConst from "constants/style";

interface ILabel {
  error?: string;
}

interface IError {
  errorTextStyles?: FlattenSimpleInterpolation;
}

interface ISelectField {
  error?: string;
}

export const Container = styled.div`
  position: relative;

  width: 100%;
`;

export const IconContainer = styled.div`
  position: absolute;

  top: 15px;
  right: 15px;

  pointer-events: none;
`;

export const SelectField = styled.select<ISelectField>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 100%;

  padding: 15px 30px 15px 15px;

  font-size: 14px;

  background-color: ${styleConst.colors.white};
  border: 2px solid ${styleConst.colors.black10};
  box-sizing: border-box;
  border-radius: 10px;

  &:focus {
    outline: none;
    border: 2px solid ${styleConst.colors.primary};
  }

  ${({ error }) =>
    error &&
    css`
      &&& {
        border: 2px solid ${styleConst.colors.red};
      }
    `}
`;

export const Label = styled.label<ILabel>`
  position: absolute;

  top: -13px;
  left: 15px;

  padding: 0 4px;

  font-size: 18px;
  color: ${styleConst.colors.black50};

  font-size: 12px;
  line-height: 24px;

  pointer-events: none;

  background-color: ${styleConst.colors.white};

  ${SelectField}:focus ~ & {
    color: ${styleConst.colors.primary};
  }

  ${({ error }) =>
    error &&
    css`
      &&& {
        color: ${styleConst.colors.red};
      }
    `}
`;

export const Error = styled.p<IError>`
  margin-top: 6px;

  font-size: 12px;
  text-align: start;
  color: ${styleConst.colors.red};

  ${({ errorTextStyles }) => errorTextStyles};
`;
