import * as React from "react";

type Props = {
  width?: number;
  height?: number;
};

const NoInternetConnection = ({ width = 312, height = 312 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 312 312"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_2917_22860)">
        <rect
          x="131.3"
          y="257.4"
          width="105.3"
          height="2.6"
          rx="1.3"
          fill="#8D8D8D"
        />
      </g>
      <circle opacity="0.3" cx="91.0002" cy="204.101" r="33.8" fill="#84CFCB" />
      <circle
        opacity="0.3"
        cx="126.101"
        cy="78.0002"
        r="30.3"
        stroke="#D2E8E7"
        strokeWidth="7"
      />
      <circle opacity="0.3" cx="224.25" cy="144.95" r="3.25" fill="#84CFCB" />
      <path
        d="M144.301 243.751C144.301 221.135 162.635 202.801 185.251 202.801C207.867 202.801 226.201 221.135 226.201 243.751V247.001H144.301V243.751Z"
        fill="url(#paint0_radial_2917_22860)"
      />
      <rect
        x="137.801"
        y="247.001"
        width="94.9"
        height="11.7"
        rx="5.85"
        fill="url(#paint1_linear_2917_22860)"
      />
      <rect
        width="7.79997"
        height="25.9992"
        transform="matrix(0.75471 -0.656059 0.656075 0.754695 169.04 174.874)"
        fill="url(#paint2_linear_2917_22860)"
      />
      <path
        d="M192.034 76.4886C216.298 104.401 209.613 149.938 177.101 178.2C144.59 206.462 98.564 206.745 74.2994 178.833C50.0348 150.921 82.9119 144.504 115.423 116.242C147.935 87.9804 167.769 48.5766 192.034 76.4886Z"
        fill="url(#paint3_radial_2917_22860)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.956 145.825C181.468 117.563 200.377 86.0874 191.192 75.5216C190.762 75.0268 190.278 74.5866 189.742 74.1999L189.914 74.0508L185.65 69.1451L182.3 72.0565C167.76 71.65 141.326 85.2815 115.694 107.563C90.0621 129.845 72.8846 154.124 71.2638 168.579L67.9149 171.49L72.1793 176.396L72.3509 176.247C72.6593 176.831 73.0279 177.372 73.458 177.866C82.6431 188.432 116.445 174.087 148.956 145.825Z"
        fill="url(#paint4_linear_2917_22860)"
      />
      <ellipse
        rx="77.9997"
        ry="25.3493"
        transform="matrix(0.75471 -0.656059 0.656075 0.754695 126.357 119.827)"
        fill="url(#paint5_radial_2917_22860)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.458 74.1133C187.217 71.9426 186.486 70.1024 185.227 68.6547C176.042 58.0889 142.24 72.4344 109.729 100.696C77.2176 128.958 58.3079 160.434 67.4929 170.999C68.2425 171.862 69.156 172.558 70.2195 173.095C68.7021 159.422 86.6292 132.639 114.686 108.25C144.544 82.2945 175.491 68.0768 187.458 74.1133Z"
        fill="url(#paint6_linear_2917_22860)"
      />
      <rect
        width="2.6"
        height="70.1981"
        transform="matrix(0.754709 -0.656059 0.656077 0.754694 96.3037 86.6484)"
        fill="url(#paint7_linear_2917_22860)"
      />
      <circle
        cx="97.7843"
        cy="86.9609"
        r="7.8"
        transform="rotate(-41 97.7843 86.9609)"
        fill="url(#paint8_radial_2917_22860)"
      />
      <path
        d="M169.65 216.346C173.85 212.763 179.298 210.601 185.25 210.601C191.203 210.601 196.651 212.763 200.85 216.346"
        stroke="#367674"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M182 185.9H189.8V208C189.8 210.154 188.054 211.9 185.9 211.9C183.746 211.9 182 210.154 182 208V185.9Z"
        fill="url(#paint9_linear_2917_22860)"
      />
      <circle
        cx="185.25"
        cy="190.45"
        r="9.75"
        fill="url(#paint10_linear_2917_22860)"
      />
      <circle opacity="0.3" cx="184.601" cy="29.9006" r="7.8" fill="#84CFCB" />
      <defs>
        <filter
          id="filter0_f_2917_22860"
          x="127.3"
          y="253.4"
          width="113.3"
          height="10.5996"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_2917_22860"
          />
        </filter>
        <radialGradient
          id="paint0_radial_2917_22860"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(222.01 225.463) rotate(111.436) scale(31.0833 77.5915)"
        >
          <stop offset="0.461333" stopColor="#94D8E7" />
          <stop offset="1" stopColor="#21AAA4" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_2917_22860"
          x1="232.701"
          y1="223.601"
          x2="232.701"
          y2="298.351"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#30716F" />
          <stop offset="1" stopColor="#21AAA4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2917_22860"
          x1="3.89998"
          y1="-12.2673"
          x2="3.89998"
          y2="25.9992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94D8E7" />
          <stop offset="1" stopColor="#21AAA4" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_2917_22860"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(200.853 98.8) rotate(68.9625) scale(63.3742 149.236)"
        >
          <stop offset="0.461333" stopColor="#94D8E7" />
          <stop offset="1" stopColor="#21AAA4" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_2917_22860"
          x1="120.361"
          y1="111.257"
          x2="135.584"
          y2="148.617"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F0F0F0" />
        </linearGradient>
        <radialGradient
          id="paint5_radial_2917_22860"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(148.017 25.9938) rotate(123.104) scale(39.6177 133.004)"
        >
          <stop stopColor="#3A7A78" />
          <stop offset="1" stopColor="#21AAA4" />
        </radialGradient>
        <linearGradient
          id="paint6_linear_2917_22860"
          x1="70.6725"
          y1="161.216"
          x2="122.202"
          y2="97.6444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94D8E7" />
          <stop offset="1" stopColor="#21AAA4" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2917_22860"
          x1="7.7089"
          y1="-134.699"
          x2="-49.7857"
          y2="54.6892"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.674092" stopColor="#94D8E7" />
          <stop offset="1" stopColor="#2A8C88" />
        </linearGradient>
        <radialGradient
          id="paint8_radial_2917_22860"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(115.433 80.0958) rotate(142.592) scale(25.8778 29.5517)"
        >
          <stop offset="0.461333" stopColor="#94D8E7" />
          <stop offset="1" stopColor="#21AAA4" />
        </radialGradient>
        <linearGradient
          id="paint9_linear_2917_22860"
          x1="185.9"
          y1="164.679"
          x2="185.9"
          y2="210.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94D8E7" />
          <stop offset="1" stopColor="#21AAA4" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_2917_22860"
          x1="185.25"
          y1="180.7"
          x2="185.25"
          y2="200.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#458482" />
          <stop offset="1" stopColor="#286967" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoInternetConnection;
