import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IContainer {
  isSelected: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  border-width: 2px;
  border-color: transparent;

  height: 56px;
  width: 100%;
  margin-top: 10px;
  padding: 0 18px;

  border-radius: 6px;
  border: 2px solid ${styleConst.colors.black10};

  background-color: ${styleConst.colors.white};

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${styleConst.colors.primary};
      background-color: ${styleConst.colors.primary10};
    `}
`;

export const Meal = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

export const Recipes = styled.span`
  font-size: 14px;
  color: ${styleConst.colors.black50};
  font-weight: 500;
`;
