import styled from "styled-components";

import styleConst from "constants/style";

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 118px;

  border-radius: 6px;

  overflow: hidden;
  cursor: pointer;
`;

export const Left = styled.div`
  margin-left: 24px;
`;

export const Right = styled.div`
  display: flex;
  margin-right: 24px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.white};
`;

export const Subtitle = styled(Title)`
  font-size: 13px;
  font-family: ${styleConst.fonts.roboto};
`;
