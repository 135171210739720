import { LINKS } from "./links";

export const UPDATE_MEMBERSHIP = [
  {
    text: "Update my billing information",
    onClick: () => window.open(LINKS.UPDATE_BILLING_INFO),
  },
  {
    text: "Cancel my subscription",
    onClick: () => window.open(LINKS.CANCEL_SUBSCRIPTION),
  },
];
