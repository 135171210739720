import styled, { css } from "styled-components";
import styleConst from "constants/style";

interface ILinkLabel {
  selected?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  margin-bottom: 120px;
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 120px;

  background: linear-gradient(233.38deg, #c3e7e5 -9.83%, #7bcac7 82.48%),
    #f1f3f3;
  border-radius: 10px;
  position: relative;

  padding: 0 43px;
  margin: 18px 0 65px 0;
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BannerControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserPhoto = styled.img`
  width: 190px;
  height: 190px;
  border-radius: 100%;
  object-fit: cover;
`;

export const PhotoWrapper = styled.div`
  position: absolute;
  left: 43px;
  top: -35px;
`;

export const UserName = styled.div`
  font-size: 26px;
  line-height: 48px;
  font-weight: 600;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.white};
  padding-left: 230px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 80%;
`;

export const SideBarTitle = styled.div`
  font-size: 26px;
  line-height: 48px;
  font-weight: 600;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.dark};

  border-left: 4px solid ${styleConst.colors.primary};

  padding-left: 14px;
`;

export const SideBar = styled.div`
  display: flex;
  flex-direction: column;

  width: 199px;
`;

export const Link = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-top: 39px;

  cursor: pointer;
`;

export const InnerLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
`;

export const LinkLabel = styled.div<ILinkLabel>`
  font-size: 16px;
  font-weight: 500;
  font-family: ${styleConst.fonts.roboto};

  ${({ selected }) =>
    css`
      color: ${selected
        ? styleConst.colors.primary
        : styleConst.colors.black50};
    `}
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  padding-bottom: 42px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;
