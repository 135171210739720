import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24MyItems = ({ color = styleConst.colors.black50 }: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.021 5.24871C10.1405 4.53992 10.7572 4 11.5 4C12.2428 4 12.8595 4.53992 12.979 5.24871C14.5867 5.80795 15.7795 7.25332 15.9725 9H16C16.5523 9 17 9.44771 17 10C17 10.5523 16.5523 11 16 11V13.2727C16 14.2267 15.2267 15 14.2727 15H8.72727C7.77333 15 7 14.2267 7 13.2727L7 11C6.44771 11 6 10.5523 6 10C6 9.44771 6.44772 9 7 9H7.02746C7.22055 7.25332 8.41333 5.80795 10.021 5.24871ZM11.5 7C10.2905 7 9.28164 7.85888 9.05001 9H13.95C13.7184 7.85888 12.7095 7 11.5 7ZM14 13V11H9V13H14Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1C4.79086 1 3 2.79086 3 5V20C3 21.6569 4.34315 23 6 23H16C18.2091 23 20 21.2091 20 19V5C20 2.79086 18.2091 1 16 1H7ZM18 5C18 3.89543 17.1046 3 16 3H7C5.89543 3 5 3.89543 5 5V17.1707C5.31278 17.0602 5.64936 17 6 17H18V15.1L17.9975 15.1005C17.9992 15.0672 18 15.0337 18 15V5ZM18 19H6C5.44772 19 5 19.4477 5 20C5 20.5523 5.44772 21 6 21H16C17.1046 21 18 20.1046 18 19Z"
      fill={color}
    />
  </svg>
);

export default I24MyItems;
