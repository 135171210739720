import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24RestaurantFilled = ({ color = styleConst.colors.white }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.51814 11.6459C7.7989 10.0354 9.7786 9 11.9995 9C12.5518 9 12.9995 9.44772 12.9995 10C12.9995 10.5523 12.5518 11 11.9995 11C10.414 11 9.00115 11.7369 8.0835 12.8908C7.73974 13.323 7.11066 13.3948 6.6784 13.051C6.24613 12.7073 6.17438 12.0782 6.51814 11.6459Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.674 6.36151C14.8824 5.9529 15 5.49018 15 5C15 3.34315 13.6569 2 12 2C10.3431 2 9 3.34315 9 5C9 5.49018 9.11756 5.9529 9.32603 6.36151C5.10151 7.53095 2 11.4033 2 16V20.0667C2 21.1344 2.86558 22 3.93333 22H20.0667C21.1344 22 22 21.1344 22 20.0667V16C22 11.4033 18.8985 7.53095 14.674 6.36151ZM11 5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5C13 5.55228 12.5523 6 12 6C11.4477 6 11 5.55228 11 5ZM12 8C7.58172 8 4 11.5817 4 16H20C20 11.5817 16.4183 8 12 8ZM4 18V20H20V18H4Z"
      fill={color}
    />
  </svg>
);

export default I24RestaurantFilled;
