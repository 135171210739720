import styled from "styled-components";

import backgroundImageSmall from "assets/images/svg/dots_background_small.svg";
import backgroundImage from "assets/images/svg/dots_background.svg";

import { MEDIA_QUERIES } from "constants/layout";
import styleConst from "constants/style";

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 48px;

  background: ${styleConst.colors.dark5};
  border-radius: 10px;
  padding: 20px 45px 20px 80px;
`;

export const Title = styled.div`
  width: 56%;

  font-size: 18px;
  font-weight: 700;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.dark};
`;

export const SuggestContainer = styled.div`
  align-items: center;
  justify-content: center;
  padding: 0 40px;
`;

export const ColumnTitle = styled.div`
  text-align: center;

  width: 80px;
  padding: 0 10px;

  font-weight: 400;
  font-size: 14px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.black50};
`;

export const ColumnTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  width: 48%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  width: 75%;
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 10px 0px;
`;

export const SectionTitleAdornment = styled.div`
  margin-right: 14px;

  width: 4px;
  height: 28px;
  background-color: ${styleConst.colors.secondary};
`;

export const SectionTitle = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;
  color: ${styleConst.colors.text};
  line-height: 48px;
`;

export const Background = styled.div`
  position: absolute;
  z-index: -1;

  left: -371px;
  top: 164px;

  height: 388px;
  width: 100%;

  overflow: hidden;
  background-repeat: no-repeat;

  background-image: url(${backgroundImageSmall});

  ${MEDIA_QUERIES.DESKTOP} {
    background-image: url(${backgroundImage});
  }
`;

export const ItemWrapper = styled.div`
  position: relative;
`;
