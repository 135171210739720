import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24LegalAcceptances = ({ color = styleConst.colors.white }: Props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      fill={color}
      d="M14.293 7.293c.39-.39 1.024-.39 1.414 0 .39.39.39 1.024 0 1.414L12.414 12c-.78.781-2.047.781-2.828 0l-1.293-1.293c-.39-.39-.39-1.024 0-1.414.39-.39 1.024-.39 1.414 0L11 10.586l3.293-3.293z"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M3 5c0-2.21 1.79-4 4-4h9c2.21 0 4 1.79 4 4v10c0 2.21-1.79 4-4 4H6c-.552 0-1 .448-1 1s.448 1 1 1h13c.552 0 1 .448 1 1s-.448 1-1 1H6c-1.657 0-3-1.343-3-3V5zm15 0v10c0 1.105-.895 2-2 2H6c-.35 0-.687.06-1 .17V5c0-1.105.895-2 2-2h9c1.105 0 2 .895 2 2z"
      clipRule="evenodd"
    />
  </svg>
);

export default I24LegalAcceptances;
