export const postProcessValue = (value: string, fieldName: string) => {
  switch (fieldName) {
    case "minutesOfActivity":
      return value.replace(/[^0-9]/g, "");
    case "age":
      return value.replace(/[.,-]/g, "");
    case "weight":
      return value.replace(",", ".").replace("-", "");

    default:
      return value;
  }
};
