import styled, { css, keyframes } from "styled-components";

interface IContainer {
  large?: boolean;
}

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div<IContainer>`
  display: inline-block;

  height: 24px;
  width: 24px;

  animation: ${rotate} 0.8s linear infinite;

  ${({ large }) =>
    large &&
    css`
      height: 40px;
      width: 40px;
    `}
`;
