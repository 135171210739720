import * as React from "react";

import style from "constants/style";

type Props = {
  color?: string;
};

const I24Reset = ({ color = style.colors.white }: Props) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7727 10.3585C17.0587 7.84274 14.7445 6 12 6C11.922 6 11.8443 6.00149 11.767 6.00443C8.5613 6.12682 6 8.7643 6 12C6 15.3137 8.68629 18 12 18C12.5523 18 13 18.4477 13 19C13 19.5523 12.5523 20 12 20C7.58172 20 4 16.4183 4 12C4 7.59067 7.56723 4.01451 11.9732 4.00004C11.9821 4.00001 11.9911 4 12 4C15.9729 4 19.2694 6.89601 19.8936 10.6922L20.2929 10.2929C20.6834 9.90237 21.3166 9.90237 21.7071 10.2929C22.0976 10.6834 22.0976 11.3166 21.7071 11.7071L19.7071 13.7071C19.3166 14.0976 18.6834 14.0976 18.2929 13.7071L16.2929 11.7071C15.9024 11.3166 15.9024 10.6834 16.2929 10.2929C16.6834 9.90237 17.3166 9.90237 17.7071 10.2929L17.7727 10.3585Z"
        fill={color}
      />
    </svg>
  );
};

export default I24Reset;
