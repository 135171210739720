import React from "react";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import AuthStore from "stores/AuthStore";

import Input from "components/Input";
import { SnackbarTypeValues } from "components/Snackbar";

import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";

import { composeValidators, isEmpty, isPassword } from "utils/validation";

import ROUTES from "navigation/routes";

import {
  Container,
  Column,
  Form,
  Row,
  SubmitButtonContainer,
  SubmitButton,
  Title,
} from "./styles";

type Props = {} & RouteComponentProps & SnackbarContextProps;

@observer
class ChangePassword extends React.Component<Props> {
  @observable password: string = "";
  @observable confirmPassword: string = "";

  @observable error = {
    password: "",
    confirmPassword: "",
  };

  @computed get isError() {
    return Object.values(this.error).some(Boolean);
  }

  onNewPasswordChange = (text: string) => {
    this.password = text.trim();
    this.error.password = "";
  };

  onConfirmPasswordChange = (text: string) => {
    this.confirmPassword = text.trim();
    this.error.confirmPassword = "";
  };

  checkForm = () => {
    this.error = {
      password: "",
      confirmPassword: "",
    };

    this.error.password = composeValidators(isEmpty, isPassword)(this.password);
    this.error.confirmPassword = composeValidators(
      isEmpty,
      isPassword,
    )(this.confirmPassword);

    if (!(this.error.password && this.error.confirmPassword)) {
      if (this.password !== this.confirmPassword) {
        this.error.confirmPassword = "Passwords don't match";
      }
    }
  };

  resetForm = () => {
    this.password = "";
    this.confirmPassword = "";
    this.error = {
      password: "",
      confirmPassword: "",
    };
  };

  onSubmit = async () => {
    const { setMessage, history } = this.props;

    this.checkForm();

    if (!this.isError) {
      try {
        await AuthStore.changePassword(this.password);
        setMessage("Password is successfully changed", SnackbarTypeValues.INFO);
        history.push(ROUTES.MACROGOALS);
      } catch (error: any) {
        setMessage(error?.message, SnackbarTypeValues.ALERT);
      }
    }
  };

  render() {
    return (
      <Container>
        <Title>Change Password</Title>
        <Form>
          <Row>
            <Column>
              <Input
                label="New password"
                value={this.password}
                onChangeText={this.onNewPasswordChange}
                type="password"
                error={this.error.password}
                toggleableSecureText
              />
            </Column>
            <Column>
              <Input
                label="Confirm password"
                value={this.confirmPassword}
                onChangeText={this.onConfirmPasswordChange}
                type="password"
                error={this.error.confirmPassword}
                toggleableSecureText
              />
            </Column>
          </Row>

          <SubmitButtonContainer>
            <SubmitButton
              loading={AuthStore.isLoading}
              fontWeight="bold"
              text="Save"
              onClick={this.onSubmit}
            />
          </SubmitButtonContainer>
        </Form>
      </Container>
    );
  }
}

export default withGlobalSnackbar(withRouter(ChangePassword));
