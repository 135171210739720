import styled from "styled-components";

import styleConst from "constants/style";

export const Container = styled.div`
  display: inline-block;

  padding: 3px 8px;
  background: ${styleConst.colors.black4};
  border-radius: 84px;
`;

export const Text = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  text-align: center;

  color: ${styleConst.colors.black25};
`;
