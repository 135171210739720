import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";

import I24ArrowBack from "assets/icons/I24ArrowBack";
import I24Star from "assets/icons/I24Star";

import StudioStore from "stores/StudioStore";
import WorkoutDetailsStore from "stores/WorkoutDetailsStore";

import FormTitle from "components/Form/FormTitle";
import GridCollection from "components/GridCollection";
import ButtonInline from "components/ButtonInline";
import Spinner from "components/Spinner";
import Cards from "components/Studio/Cards";
import Workouts from "components/Studio/Workouts";
import WorkoutMovement from "components/Studio/WorkoutMovement";
import AddInScheduleForm from "components/Studio/AddInScheduleForm";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import Workout from "./WorkoutDetails";

import { NoInternetConnectionError } from "services/api";

import { capitalize } from "utils/common";

import styleConst from "constants/style";

import {
  BackButtonWrapper,
  Container,
  GridCollectionWrapper,
  Loader,
  Row,
  ButtonInlineWrapper,
  ControlPanel,
  ControlWrapper,
  NoInternetConnectionErrorContainer,
} from "./styles";

type Props = {
  onSubmit: (date: Date) => void;
  onClose: () => void;
};

export const MapAddWorkoutForm = {
  workout: "workout",
  favoriteWorkouts: "favoriteWorkouts",
  categoryDetails: "categoryDetails",
  studio: "studio",
  workouts: "workouts",
  movement: "movement",
};

@observer
class AddWorkoutForm extends React.Component<Props> {
  @observable formPage: string = MapAddWorkoutForm.studio;
  @observable history: string[] = [];
  @observable page: number = 1;

  @observable categoryId: number | null = null;
  @observable workoutGroup: string | null = null;
  @observable workoutId: number | null = null;
  @observable movementId: number | null = null;

  @observable isNoInternetConnectionError: boolean = false;

  @computed get isLoading() {
    return StudioStore.isLoading;
  }

  @computed get isFavorites() {
    return !this.categoryId;
  }

  @computed get items() {
    return StudioStore.items;
  }

  @computed get needMore() {
    const { context } = StudioStore;
    return context && this.page < context.pages;
  }

  @computed get details() {
    return StudioStore.details;
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    try {
      await StudioStore.fetch(this.page, true);
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }
  };

  goBack = () => {
    this.formPage = this.history.pop() || MapAddWorkoutForm.studio;
  };

  onCategoryNavigate = (item: any) => {
    this.categoryId = item.id;

    if (this.categoryId) {
      this.history.push(this.formPage);
      this.formPage = MapAddWorkoutForm.categoryDetails;
    }
  };

  onLoadMore = async () => {
    if (this.needMore) {
      this.page++;
      await StudioStore.fetch(this.page);
    }
  };

  onNavigateToWorkout = (workoutGroup?: string) => (workout: any) => {
    this.workoutId = workout.id;
    if (workoutGroup) {
      this.workoutGroup = workoutGroup;
    }
    this.history.push(this.formPage);
    this.formPage = MapAddWorkoutForm.workout;
  };

  onViewAllClick = (workoutGroup: string) => () => {
    this.workoutGroup = workoutGroup;
    this.history.push(this.formPage);
    this.formPage = MapAddWorkoutForm.workouts;
  };

  onNavigateToStudio = () => {
    this.history.push(this.formPage);
    this.formPage = MapAddWorkoutForm.studio;
  };

  onNavigateToMovement = (movement: any) => {
    this.movementId = movement.id;
    this.history.push(this.formPage);
    this.formPage = MapAddWorkoutForm.movement;
  };

  onGoToFavorites = () => {
    this.categoryId = null;
    this.history.push(this.formPage);
    this.formPage = MapAddWorkoutForm.favoriteWorkouts;
  };

  render() {
    const { onSubmit, onClose } = this.props;
    return (
      <Container>
        {this.isNoInternetConnectionError ? (
          <NoInternetConnectionErrorContainer>
            <NoInternetConnectionPlaceholder
              type={"popup"}
              onSubmit={onClose}
            />
          </NoInternetConnectionErrorContainer>
        ) : this.isLoading ? (
          <Loader>
            <Spinner large></Spinner>
          </Loader>
        ) : (
          <>
            {this.formPage === MapAddWorkoutForm.studio && (
              <>
                <Row>
                  <FormTitle title={"Add Workout"} />
                  <ButtonInlineWrapper>
                    <ButtonInline
                      onPress={this.onGoToFavorites}
                      text={"Go to Favorites"}
                      fontSize={16}
                      fontWeight={500}
                      color={styleConst.colors.primaryDark}
                      iconLeft={
                        <I24Star color={styleConst.colors.primaryDark} />
                      }
                    />
                  </ButtonInlineWrapper>
                </Row>

                <GridCollectionWrapper id="scrollableDiv">
                  <GridCollection
                    items={this.items}
                    onClick={this.onCategoryNavigate}
                    needMore={this.needMore}
                    onLoadMore={this.onLoadMore}
                    scrollableTarget={"scrollableDiv"}
                    withInfinityScroll
                  />
                </GridCollectionWrapper>
              </>
            )}

            {this.formPage === MapAddWorkoutForm.categoryDetails &&
              this.categoryId && (
                <>
                  <BackButtonWrapper>
                    <ButtonInline
                      text={"Back"}
                      iconLeft={<I24ArrowBack />}
                      onPress={this.goBack}
                    />
                  </BackButtonWrapper>
                  <FormTitle title={this.details?.title} />
                  <GridCollectionWrapper>
                    <Cards
                      categoryId={this.categoryId}
                      onNavigateToWorkout={this.onNavigateToWorkout}
                      onViewAllClick={this.onViewAllClick}
                      isFullAccessOnly
                      fromPopup={true}
                      onClose={onClose}
                    />
                  </GridCollectionWrapper>
                </>
              )}

            {(this.formPage === MapAddWorkoutForm.workouts ||
              this.formPage === MapAddWorkoutForm.favoriteWorkouts) && (
              <>
                <BackButtonWrapper>
                  <ButtonInline
                    text={"Back"}
                    iconLeft={<I24ArrowBack />}
                    onPress={this.goBack}
                  />
                </BackButtonWrapper>
                <FormTitle
                  title={
                    !this.categoryId
                      ? "Favorites"
                      : capitalize(this.workoutGroup || "")
                  }
                />
                <GridCollectionWrapper>
                  <Workouts
                    categoryId={this.categoryId}
                    workoutGroup={this.workoutGroup}
                    onNavigateToWorkout={this.onNavigateToWorkout}
                    onNavigateToStudio={this.onNavigateToStudio}
                    isFullAccessOnly
                    fromPopup={true}
                    onClose={onClose}
                  />
                </GridCollectionWrapper>
              </>
            )}

            {this.formPage === MapAddWorkoutForm.workout && this.workoutId && (
              <>
                <BackButtonWrapper>
                  <ButtonInline
                    text={"Back"}
                    iconLeft={<I24ArrowBack />}
                    onPress={this.goBack}
                  />
                </BackButtonWrapper>
                <FormTitle title={WorkoutDetailsStore.details?.title} />
                <GridCollectionWrapper>
                  <Workout
                    workoutId={this.workoutId}
                    categoryId={this.categoryId}
                    workoutGroup={this.workoutGroup}
                    onNavigateToMovement={this.onNavigateToMovement}
                    onClose={onClose}
                  />
                </GridCollectionWrapper>
                {WorkoutDetailsStore.details && (
                  <ControlPanel>
                    <ControlWrapper>
                      <AddInScheduleForm onSubmit={onSubmit} inline />
                    </ControlWrapper>
                  </ControlPanel>
                )}
              </>
            )}

            {this.formPage === MapAddWorkoutForm.movement && this.movementId && (
              <>
                <BackButtonWrapper>
                  <ButtonInline
                    text={"Back"}
                    iconLeft={<I24ArrowBack />}
                    onPress={this.goBack}
                  />
                </BackButtonWrapper>
                <FormTitle title={WorkoutDetailsStore.details?.title} />
                <GridCollectionWrapper>
                  <WorkoutMovement
                    workoutId={this.workoutId}
                    movementId={this.movementId}
                    categoryId={this.categoryId}
                    workoutGroup={this.workoutGroup}
                    fromPopup={true}
                    onClose={onClose}
                  />
                </GridCollectionWrapper>
              </>
            )}
          </>
        )}
      </Container>
    );
  }
}

export default AddWorkoutForm;
