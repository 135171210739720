import React from "react";

import { Placeholders } from "assets/images/placeholders";
import I24AddFilled from "assets/icons/I24AddFilled";

import { Ingredient as Actions } from "components/Actions";
import FormTitle from "components/Form/FormTitle";
import Input from "components/Input";
import List from "components/List";
import ButtonInline from "components/ButtonInline";
import Placeholder from "components/Placeholder";
import Button from "components/Button";
import ListItemWrapper from "components/ListItemWrapper";
import Recipe from "components/Recipe";

import AddIngredientControl from "./AddIngredientControl";

import styleConst from "constants/style";

import {
  NutrientsList,
  getCurrentNutritionalInfo,
  getNutrientLabel,
  getNutrientValue,
} from "utils/nutrients";

import {
  IngredientControlWrapper,
  InputWrapper,
  Header,
  Title,
  TitleWrapper,
  ColumnTitle,
  ColumnTitleWrapper,
  ButtonContainer,
  FoodNutrientsRow,
  RowNutrients,
  NutrientWrapper,
  Nutrients,
  NutrientsInfo,
  NutrientsTitle,
  InlineListItem,
  RecipeFormWrapper,
} from "../styles";

const header = ["Carbs", "Fiber", "Fat", "Protein"];

type Props = {
  title: string;
  ingredients: any[];
  buttonIsDisabled: boolean;
  nutritionFacts: NutrientsList;
  mainNutritionFacts: any;
  titleError: string | undefined;
  onChangeTitle: (value: string) => void;
  onAddManually: () => void;
  onBarcode: () => void;
  onSave: () => void;
  onEdit: (id: string, item: any) => void;
  onRemove: (id: string) => void;
};

class RecipeForm extends React.Component<Props> {
  renderItem = (item: any) => {
    const { onEdit, onRemove } = this.props;

    const ingredientItem = {
      title: item.ingredient.name,
      macronutrients: getCurrentNutritionalInfo(
        item.ingredient.servings[item.servingIndex ?? 0].nutritionalInfo,
        item.quantity,
      ),
      brand: `${item.ingredient.servings[item.servingIndex ?? 0].description}`,
    };

    return (
      <ListItemWrapper
        key={item.ingredient.id}
        controls={
          <Actions
            id={item.ingredient.id}
            item={item}
            onEdit={onEdit}
            onRemove={onRemove}
          />
        }
      >
        <Recipe
          withoutImage
          style={{
            height: 60,
            marginLeft: 20,
            marginTop: 10,
            marginBottom: 10,
          }}
          recipe={ingredientItem}
        />
      </ListItemWrapper>
    );
  };

  render() {
    const {
      title,
      ingredients,
      buttonIsDisabled,
      onChangeTitle,
      onAddManually,
      onBarcode,
      onSave,
      nutritionFacts,
      mainNutritionFacts,
      titleError,
    } = this.props;

    return (
      <RecipeFormWrapper>
        <FormTitle title="Add Recipe" />
        <InputWrapper>
          <Input
            required
            withoutErrorText
            error={titleError}
            label={"Title"}
            value={title}
            onChangeText={onChangeTitle}
          />
        </InputWrapper>
        <Header>
          <TitleWrapper>
            <Title>{"Ingredients"}</Title>
            <ColumnTitleWrapper>
              {header.map(item => (
                <ColumnTitle key={item}>{item}</ColumnTitle>
              ))}
            </ColumnTitleWrapper>
          </TitleWrapper>

          <IngredientControlWrapper>
            <AddIngredientControl
              element={
                <ButtonInline
                  text={"Add Ingredients"}
                  fontSize={14}
                  fontWeight={600}
                  iconLeft={<I24AddFilled color={styleConst.colors.primary} />}
                />
              }
              onAddManually={onAddManually}
              onBarcode={onBarcode}
            />
          </IngredientControlWrapper>
        </Header>

        {ingredients.length ? (
          <>
            <List
              items={ingredients}
              renderItem={this.renderItem}
              style={{
                overflowX: "hidden",
                paddingBottom: 60,
                paddingRight: 45,
                paddingLeft: 18,
              }}
            />

            {nutritionFacts ? (
              <FoodNutrientsRow nutrients={mainNutritionFacts} />
            ) : null}

            {nutritionFacts ? (
              <RowNutrients formRow>
                <Nutrients>
                  <NutrientsTitle>Nutrition Facts</NutrientsTitle>
                  <NutrientsInfo>
                    {nutritionFacts.map(({ label, value }) => (
                      <NutrientWrapper key={`${label}-${value}`}>
                        <InlineListItem
                          label={getNutrientLabel(label)}
                          value={getNutrientValue(label, value)}
                        />
                      </NutrientWrapper>
                    ))}
                  </NutrientsInfo>
                </Nutrients>
              </RowNutrients>
            ) : null}
          </>
        ) : (
          <Placeholder
            inline={false}
            imageUrl={Placeholders.Ingredients}
            text={"Ingredients for your recipe\nwill be displayed here"}
          />
        )}

        <ButtonContainer>
          <Button disabled={buttonIsDisabled} text="Save" onClick={onSave} />
        </ButtonContainer>
      </RecipeFormWrapper>
    );
  }
}

export default RecipeForm;
