import React from "react";

import { Container, Meal, Recipes } from "./styles";

type Props = {
  mealType: {
    name: string;
    id: number;
  };
  recipesCount: number;
  onClick: (id: number) => void;
  isSelected: boolean;
};

class MealItem extends React.Component<Props> {
  onPress = () => {
    const {
      mealType: { id },
      onClick,
    } = this.props;

    onClick(id);
  };

  render() {
    const { mealType, recipesCount, isSelected } = this.props;

    return (
      <Container onClick={this.onPress} isSelected={isSelected}>
        <Meal>{mealType.name}</Meal>
        <Recipes>{`${recipesCount} recipes added`}</Recipes>
      </Container>
    );
  }
}

export default MealItem;
