import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  width: 100%;
`;

export const Wrapper = styled.div`
  margin-left: 20px;

  height: 48px;
  width: 240px;
`;
