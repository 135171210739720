import React from "react";

import Shop from "./components/Shop";
import Blog from "./components/Blog";
import Podcast from "./components/Podcast";

import { LINKS } from "constants/links";

import { Background, Container, Content } from "./styles";

const GreenCards = () => {
  const onClick = (link: string) => () => {
    window.open(link);
  };

  return (
    <>
      <Background />
      <Container>
        <Content>
          <Shop title="Shop" onClick={onClick(LINKS.TODAY_SHOP)} />
          <Blog title="Blog" onClick={onClick(LINKS.TODAY_BLOG)} />
          <Podcast title="Podcast" onClick={onClick(LINKS.TODAY_PODCAST)} />
        </Content>
      </Container>
    </>
  );
};

export default GreenCards;
