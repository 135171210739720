import * as React from "react";

type Props = any;

const I40Facebook = (props: Props) => {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <rect width={40} height={40} rx="20" fill="#57BDB9" />
      <path
        d="M20.6575 29.1666V20.8039H23.6058L24.0472 17.5448H20.6575V15.4639C20.6575 14.5204 20.9327 13.8773 22.354 13.8773L24.1667 13.8765V10.9616C23.853 10.922 22.7771 10.8333 21.5253 10.8333C18.9119 10.8333 17.1226 12.3521 17.1226 15.1413V17.5449H14.1667V20.8039H17.1225V29.1667L20.6575 29.1666Z"
        fill="white"
      />
    </svg>
  );
};

export default I40Facebook;
