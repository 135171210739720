import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Measurements = ({ color = styleConst.colors.white }: Props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      fill={color}
      d="M8 12a1 1 0 011-1h6a1 1 0 010 2H9a1 1 0 01-1-1zm0 4a1 1 0 011-1h4a1 1 0 010 2H9a1 1 0 01-1-1z"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M7 4v1a3 3 0 00-3 3v12a3 3 0 003 3h10a3 3 0 003-3V8a3 3 0 00-3-3V4a3 3 0 00-3-3h-4a3 3 0 00-3 3zm7-1h-4a1 1 0 00-1 1v1h6V4a1 1 0 00-1-1zM7 7h10a1 1 0 011 1v12a1 1 0 01-1 1H7a1 1 0 01-1-1V8a1 1 0 011-1z"
      clipRule="evenodd"
    />
  </svg>
);

export default I24Measurements;
