import React from "react";
import { observer } from "mobx-react";

import I24Preview from "assets/icons/I24Preview";

import PromoteContentPopup from "components/PromoteContentPopup";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";

import { Container, Icon, Wrapper } from "./styles";

type Props = {
  isPermissionPreview?: boolean;
  children: React.ReactNode;
  top?: number;
  left?: number;
  right?: number;
} & GlobalPopupProps;

@observer
class Permission extends React.Component<Props> {
  openPermissionModal = () => {
    const { openPopup } = this.props;

    openPopup({
      key: "PromotePopup",
      render: ({ close }) => <PromoteContentPopup onClose={close} />,
    });
  };

  render() {
    const { children, isPermissionPreview, top, left, right } = this.props;

    return isPermissionPreview ? (
      <Container onClick={this.openPermissionModal}>
        <Wrapper>{children}</Wrapper>
        <Icon top={top} left={left} right={right}>
          <I24Preview />
        </Icon>
      </Container>
    ) : (
      children
    );
  }
}

export default withGlobalPopup(Permission);
