import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24CheckboxOff = ({ color = styleConst.colors.white }: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM19 19V5H5V19H19Z"
      fill={color}
    />
  </svg>
);

export default I24CheckboxOff;
