import styled, { css } from "styled-components";

import {
  MEDIA_QUERIES,
  PAGE_WIDTH,
  BIG_POPUP_MEDIA_QUERIES,
  SCROLLBAR_WIDTH,
  PADDING_WIDTH,
  TWO_COLUMN_WIDTH,
} from "constants/layout";

interface IContainer {
  fixedWidth?: boolean;
}

export const BackButtonWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
`;

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 53px;

  height: calc(100vh - 70px);
  min-height: 700px;
  max-width: 100vw;

  ${({ fixedWidth }) =>
    fixedWidth
      ? css`
          width: calc(${PAGE_WIDTH.SMALL_DESKTOP} + 46px);

          ${MEDIA_QUERIES.DESKTOP} {
            width: calc(${PAGE_WIDTH.SMALL_DESKTOP} + 46px);
          }
        `
      : css`
          ${MEDIA_QUERIES.DESKTOP} {
            width: calc(
              ${PAGE_WIDTH.DESKTOP} + ${PADDING_WIDTH} + ${SCROLLBAR_WIDTH}
            );
          }

          width: calc(
            ${PAGE_WIDTH.SMALL_DESKTOP} + ${PADDING_WIDTH} + ${SCROLLBAR_WIDTH}
          );

          ${BIG_POPUP_MEDIA_QUERIES.THREE_COLUMN_WITHOUT_PADDING} {
            width: calc(${PAGE_WIDTH.SMALL_DESKTOP} + ${SCROLLBAR_WIDTH});
          }

          ${BIG_POPUP_MEDIA_QUERIES.TWO_COLUMN_WITH_PADDING} {
            width: calc(
              ${TWO_COLUMN_WIDTH} + ${PADDING_WIDTH} + ${SCROLLBAR_WIDTH}
            );
          }

          ${BIG_POPUP_MEDIA_QUERIES.TWO_COLUMN_WITHOUT_PADDING} {
            width: calc(${TWO_COLUMN_WIDTH} + ${SCROLLBAR_WIDTH});
          }
        `}
`;

export const GridCollectionWrapper = styled.div`
  display: flex;

  height: calc(100vh - 200px);
  ${MEDIA_QUERIES.DESKTOP} {
    width: calc(${PAGE_WIDTH.DESKTOP} + ${PADDING_WIDTH} + ${SCROLLBAR_WIDTH});
  }

  width: calc(
    ${PAGE_WIDTH.SMALL_DESKTOP} + ${PADDING_WIDTH} + ${SCROLLBAR_WIDTH}
  );

  ${BIG_POPUP_MEDIA_QUERIES.THREE_COLUMN_WITHOUT_PADDING} {
    width: calc(${PAGE_WIDTH.SMALL_DESKTOP} + ${SCROLLBAR_WIDTH});
  }

  ${BIG_POPUP_MEDIA_QUERIES.TWO_COLUMN_WITH_PADDING} {
    width: calc(${TWO_COLUMN_WIDTH} + ${PADDING_WIDTH} + ${SCROLLBAR_WIDTH});
    padding: 0 59px;
  }

  ${BIG_POPUP_MEDIA_QUERIES.TWO_COLUMN_WITHOUT_PADDING} {
    width: calc(${TWO_COLUMN_WIDTH} + ${SCROLLBAR_WIDTH});
    padding: 0;
  }

  overflow-y: auto;

  ${BIG_POPUP_MEDIA_QUERIES.DESKTOP_PADDING} {
    padding: 0 59px;
  }
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 700px;
`;
