import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import ReactResizeDetector from "react-resize-detector";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import I24ArrowLeftSwiper from "assets/icons/I24ArrowLeftSwiper";
import I24ArrowRightSwiper from "assets/icons/I24ArrowRightSwiper";
import I24ArrowRightSmall from "assets/icons/I24ArrowRightSmall";

import IconButton from "components/IconButton";
import Spinner from "components/Spinner";
import TrimmedText from "components/TrimmedText";

import Card from "../Card";

import { replaceNonWordsWithUnderline } from "utils/collection";

import { COLLECTION_TYPE } from "constants/collection";
import styleConst from "constants/style";
import { BREAKPOINTS } from "constants/layout";

import {
  Container,
  Controls,
  ControlWrapper,
  LoaderContainer,
  LoaderWrapper,
  PlaceholderContainer,
  TitleContainer,
  Title,
  TitleAdornment,
  TitleWrapper,
  Swiper,
  SwiperContainer,
  SwiperWrapper,
  ViewAllButton,
} from "./styles";

type Props = {
  name: string;
  items: any[];
  type: string;
  isLoading?: boolean;
  isMoreLoading?: boolean;
  withTitleAdornment?: boolean;
  withViewAll?: boolean;
  placeholder?: React.ReactNode;
  onLoadMore?: () => Promise<void>;
  onCardClick?: (item: any) => void;
  onViewAllClick?: () => void;
};

SwiperCore.use([Navigation]);

@observer
class Collection extends React.Component<Props> {
  @observable slidesPerView: number = 3;

  get swiperNavigationClassnames() {
    const { name, type } = this.props;

    const prevEl = replaceNonWordsWithUnderline(`prevEl_${name}_${type}`);
    const nextEl = replaceNonWordsWithUnderline(`nextEl_${name}_${type}`);

    return {
      prevEl,
      nextEl,
      navigation: { prevEl: `.${prevEl}`, nextEl: `.${nextEl}` },
    };
  }

  get shouldShowControls() {
    const { items, isLoading } = this.props;

    return !isLoading && !!items.length;
  }

  onReachEnd = async (swiper: SwiperCore) => {
    const { onLoadMore } = this.props;

    if (onLoadMore) {
      await onLoadMore();

      swiper.update();
    }
  };

  onCardClick = (item: any) => () => {
    const { onCardClick } = this.props;

    onCardClick?.(item);
  };

  renderControls = () => {
    const { withViewAll, onViewAllClick } = this.props;

    return (
      <Controls>
        {!withViewAll ? (
          <>
            <ControlWrapper>
              <IconButton
                icon={<I24ArrowLeftSwiper />}
                className={this.swiperNavigationClassnames.prevEl}
              />
            </ControlWrapper>
            <ControlWrapper>
              <IconButton
                icon={<I24ArrowRightSwiper />}
                className={this.swiperNavigationClassnames.nextEl}
              />
            </ControlWrapper>
          </>
        ) : (
          <ControlWrapper>
            <ViewAllButton
              onClick={onViewAllClick}
              text="View All"
              iconRight={<I24ArrowRightSmall />}
              height={40}
              width={104}
              inverted
            />
          </ControlWrapper>
        )}
      </Controls>
    );
  };

  renderPlaceholder = () => {
    const { placeholder } = this.props;

    return placeholder ? (
      <PlaceholderContainer>{placeholder}</PlaceholderContainer>
    ) : null;
  };

  onResize = (width?: number) => {
    if (width) {
      const slidesPerView = width < BREAKPOINTS.SMALL_DESKTOP ? 2 : 3;

      const shouldUpdate = slidesPerView !== this.slidesPerView;

      if (shouldUpdate) {
        this.slidesPerView = slidesPerView;
      }
    }
  };

  render() {
    const {
      name,
      items,
      isLoading,
      isMoreLoading,
      type,
      withTitleAdornment,
      withViewAll,
    } = this.props;

    return (
      <ReactResizeDetector onResize={this.onResize}>
        <Container>
          <TitleContainer>
            <TitleWrapper>
              {withTitleAdornment && <TitleAdornment />}
              <TrimmedText lines={1} text={name}>
                <Title>{name}</Title>
              </TrimmedText>
            </TitleWrapper>
            {this.shouldShowControls ? this.renderControls() : null}
          </TitleContainer>
          <SwiperContainer>
            {(isLoading || isMoreLoading) && (
              <LoaderContainer>
                <LoaderWrapper>
                  <Spinner color={styleConst.colors.primary} large />
                </LoaderWrapper>
              </LoaderContainer>
            )}
            {!isLoading ? (
              items.length ? (
                <SwiperWrapper isMoreLoading={isMoreLoading}>
                  <Swiper
                    slidesPerView={this.slidesPerView}
                    onReachEnd={this.onReachEnd}
                    navigation={this.swiperNavigationClassnames.navigation}
                    allowTouchMove={!withViewAll}
                  >
                    {items.map((item: any) => (
                      <SwiperSlide key={item.id}>
                        <Card
                          title={item.label || item.title}
                          duration={item.duration}
                          imageUrl={item.imageUrl}
                          isWorkout={type === COLLECTION_TYPE.WORKOUT}
                          isPermissionPreview={item.isPermissionPreview}
                          onClick={this.onCardClick(item)}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </SwiperWrapper>
              ) : (
                this.renderPlaceholder()
              )
            ) : null}
          </SwiperContainer>
        </Container>
      </ReactResizeDetector>
    );
  }
}

export default Collection;
