import React from "react";

import cardBackground from "assets/images/svg/shop_card_background.svg";

import IShop from "assets/icons/IShop";

import {
  Card,
  CardBackground,
  CardContent,
  IconWrapper,
  Title,
} from "../../styles";

type Props = {
  title: string;
  onClick?: () => void;
};

const Shop = ({ title, onClick }: Props) => (
  <Card onClick={onClick}>
    <CardBackground imageUrl={cardBackground} />
    <CardContent>
      <IconWrapper>
        <IShop />
      </IconWrapper>
      <Title>{title}</Title>
    </CardContent>
  </Card>
);

export default Shop;
