import React from "react";

import I24Loader from "assets/icons/I24Loader";

import { Container } from "./styles";
import I40Loader from "assets/icons/I40Loader";

type Props = {
  color?: string;
  large?: boolean;
};

const Spinner = ({ color, large }: Props) => (
  <Container large={large}>
    {large ? <I40Loader color={color} /> : <I24Loader color={color} />}
  </Container>
);

export default React.memo(Spinner);
