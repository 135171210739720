import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { getHours, getMinutes, isBefore, set } from "date-fns";

import WorkoutDetailsStore from "stores/WorkoutDetailsStore";

import DatePicker from "components/DatePicker";
import TimePicker from "components/TimePicker";
import { SnackbarTypeValues } from "components/Snackbar";

import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";

import { zonedTimeToUTC } from "utils/timezones";
import { isValidDate } from "utils/date";

import styleConst from "constants/style";

import { SubmitButton, ItemWrapper, ItemsContainer } from "./styles";

type Props = {
  onSubmit: (date: Date) => void;
  inline?: boolean;
} & SnackbarContextProps;

type Field = "date" | "time";

interface Values {
  date?: any;
  time?: any;
}

const DATE_ERROR = "Date is incorrect";
const TIME_ERROR = "Time is incorrect";

@observer
class AddInScheduleForm extends React.Component<Props> {
  @observable values: Values = {
    date: "",
    time: "",
  };

  @observable error: Values = {
    time: "",
  };

  @computed get date() {
    if (!this.values.date || !this.values.time) {
      return undefined;
    }

    const date = set(this.values.date, {
      hours: getHours(this.values.time),
      minutes: getMinutes(this.values.time),
    });

    return isValidDate(date) ? date : undefined;
  }

  @computed get submitIsDisabled() {
    return this.error.time || !this.date;
  }

  onChange = (field: Field) => (text: Date | null) => {
    this.values[field] = text;

    if (field === "time") {
      this.error.time = "";
    }

    if (this.date) {
      this.error.time = isBefore(this.date, new Date()) ? TIME_ERROR : "";
    }
  };

  onAddWorkoutPress = async () => {
    const { onSubmit, setMessage } = this.props;

    if (!this.date || this.error.time) {
      return;
    }

    const formattedDate = zonedTimeToUTC(this.date).toISOString();

    if (formattedDate) {
      try {
        await WorkoutDetailsStore.schedule(formattedDate);
        onSubmit(this.date);
      } catch (error: any) {
        console.log("error :>> ", error);
        setMessage(error?.message, SnackbarTypeValues.ALERT);
      }
    }
  };

  render() {
    const { inline = false } = this.props;

    return (
      <>
        <ItemsContainer inline={inline}>
          <ItemWrapper inline={inline}>
            <DatePicker
              label="Workout Date"
              placeholder={"mm/dd/yyyy"}
              value={this.values.date}
              onChange={this.onChange("date")}
              minDate={new Date()}
              minDateMessage={DATE_ERROR}
              invalidDateMessage={DATE_ERROR}
              required
            />
          </ItemWrapper>

          <ItemWrapper inline={inline}>
            <TimePicker
              label="Workout Time"
              placeholder={"00:00 AM"}
              value={this.values.time}
              onChange={this.onChange("time")}
              invalidDateMessage={TIME_ERROR}
              error={this.error.time}
              required
            />
          </ItemWrapper>
        </ItemsContainer>
        <div>
          <SubmitButton
            text={"Add Workout"}
            onClick={this.onAddWorkoutPress}
            loading={WorkoutDetailsStore.isUpdating}
            fontSize={16}
            fontFamily={styleConst.fonts.roboto}
            fontWeight={700}
            disabled={this.submitIsDisabled}
            width={240}
            height={52}
            inline={inline}
          />
        </div>
      </>
    );
  }
}

export default withGlobalSnackbar(AddInScheduleForm);
