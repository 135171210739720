import React from "react";
import { observer } from "mobx-react";

import { Text, Wrapper } from "./styles";

type Props = { title: string; onClick: () => void; isLastItem?: boolean };

export type NavLinkItemType = {
  title: string;
  onClick: () => void;
};

@observer
class NavListItem extends React.Component<Props> {
  render() {
    const { isLastItem, title, onClick } = this.props;

    return (
      <Wrapper onClick={onClick} lastItem={isLastItem}>
        <Text>{title}</Text>
        {!isLastItem && <Text separator>/</Text>}
      </Wrapper>
    );
  }
}

export default NavListItem;
