import styled from "styled-components";

import { MEDIA_QUERIES } from "constants/layout";
import styleConst from "constants/style";

export const Container = styled.div`
  display: flex;

  flex-direction: column;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 38px 220px 30px;
`;

export const InfoContainer = styled.div`
  margin-top: 63px;
`;

export const Section = styled.div`
  margin-bottom: 100px;
`;

export const Text = styled.p`
  font-family: ${styleConst.fonts.roboto};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const TextWrapper = styled.div`
  margin: 0 11px 30px;
`;

export const TitleAdornment = styled.div`
  margin-right: 14px;

  width: 4px;
  height: 28px;
  background-color: ${styleConst.colors.secondary};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 0 11px 20px;

  ${MEDIA_QUERIES.DESKTOP} {
    margin: 0 0 20px;
  }
`;

export const Title = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;
  color: ${styleConst.colors.text};
  line-height: 48px;
`;

export const Workout = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 11px;

  ${MEDIA_QUERIES.DESKTOP} {
    padding: 0px;
  }
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 700px;
  width: 100%;
`;

export const NoInternetConnectionErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
`;
