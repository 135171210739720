import React from "react";
import { computed } from "mobx";
import { observer } from "mobx-react";

import I24CheckboxOn from "assets/icons/I24CheckboxOn";
import I24CheckboxOff from "assets/icons/I24CheckboxOff";
import I24CheckboxMinus from "assets/icons/I24CheckboxMinus";

import styleConst from "constants/style";

import {
  CheckboxLeadContainer,
  CheckboxLabel,
  Container,
  Header,
  Options,
  Row,
  Title,
} from "./styles";

type Option = {
  label: string;
  value: string;
};

type Props = {
  value: Array<Option>;
  options: Array<Option>;
  selected?: Array<Option>;
  onSelectOption: (options: Array<Option>) => void;
  title: string;
};

type CheckboxProps = {
  selected?: boolean;
  option: Option;
  onSelect: (option: Option) => void;
};

type CheckboxLeadProps = {
  allSelected: boolean;
  someSelected: boolean;
  onClick: () => void;
};

const Checkbox = ({ option, selected, onSelect }: CheckboxProps) => {
  const onClick = () => {
    onSelect?.(option);
  };

  return (
    <Row onClick={onClick}>
      {selected ? (
        <I24CheckboxOn color={styleConst.colors.white} />
      ) : (
        <I24CheckboxOff color={styleConst.colors.black10} />
      )}
      <CheckboxLabel>{option.label}</CheckboxLabel>
    </Row>
  );
};

const CheckboxLead = ({
  allSelected,
  someSelected,
  onClick,
}: CheckboxLeadProps) => {
  const Icon = allSelected
    ? I24CheckboxOn
    : someSelected
    ? I24CheckboxMinus
    : I24CheckboxOff;
  const color = allSelected
    ? styleConst.colors.white
    : someSelected
    ? styleConst.colors.white
    : styleConst.colors.black10;

  return (
    <CheckboxLeadContainer onClick={onClick}>
      <Icon color={color} />
    </CheckboxLeadContainer>
  );
};

@observer
class CheckboxGroup extends React.Component<Props> {
  get mappedOptions() {
    const { options } = this.props;
    return options.map(({ value }) => value);
  }

  get mappedValues() {
    const { value = [] } = this.props;
    return value.map(({ value }) => value);
  }

  @computed get isSomeOptionsSelected() {
    return (
      !!this.mappedOptions.length &&
      this.mappedOptions.some(option => this.mappedValues.includes(option))
    );
  }

  @computed get isEveryOptionsSelected() {
    return (
      !!this.mappedOptions.length &&
      this.mappedOptions.every(option => this.mappedValues.includes(option))
    );
  }

  isOptionSelected = (option: Option) => {
    const { value = [] } = this.props;
    return value.map(({ value }) => value).includes(option?.value);
  };

  onSelect = (option: Option) => {
    const { onSelectOption, value = [] } = this.props;

    let items = value || [];
    if (items.map(({ value }) => value).includes(option?.value)) {
      items = items.filter(item => item.value !== option.value);
    } else {
      items.push(option);
    }

    onSelectOption?.(items);
  };

  onLeadSelect = () => {
    const { onSelectOption, options } = this.props;
    const nextOptions = this.isEveryOptionsSelected ? [] : options;
    onSelectOption?.(nextOptions);
  };

  render() {
    const { options, title } = this.props;

    return (
      <Container>
        <Header>
          <CheckboxLead
            allSelected={this.isEveryOptionsSelected}
            someSelected={this.isSomeOptionsSelected}
            onClick={this.onLeadSelect}
          />
          <Title>{title}</Title>
        </Header>
        <Options>
          {options.map(option => (
            <Checkbox
              key={option.value}
              option={option}
              onSelect={this.onSelect}
              selected={this.isOptionSelected(option)}
            />
          ))}
        </Options>
      </Container>
    );
  }
}

export default CheckboxGroup;
