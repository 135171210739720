import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I32Add = ({ color = styleConst.colors.white }: Props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 5C17 4.73478 16.8946 4.48044 16.7071 4.29291C16.5196 4.10537 16.2652 4 16 4C15.7348 4 15.4804 4.10537 15.2929 4.29291C15.1053 4.48044 15 4.73478 15 5V15H5C4.73478 15 4.48041 15.1054 4.29288 15.2929C4.10534 15.4804 4 15.7348 4 16C4 16.2652 4.10534 16.5196 4.29288 16.7071C4.48041 16.8947 4.73478 17 5 17H15V27C15 27.2652 15.1053 27.5196 15.2929 27.7071C15.4804 27.8947 15.7348 28 16 28C16.2652 28 16.5196 27.8947 16.7071 27.7071C16.8946 27.5196 17 27.2652 17 27V17H27C27.2652 17 27.5196 16.8947 27.7071 16.7071C27.8946 16.5196 28 16.2652 28 16C28 15.7348 27.8946 15.4804 27.7071 15.2929C27.5196 15.1054 27.2652 15 27 15H17V5Z"
      fill={color}
    />
  </svg>
);

export default I32Add;
