export const NUTRITION_TABS = {
  FOOD_LOG: "Food Log",
  RECIPE_LIBRARY: "Recipe Library",
  MY_ITEMS: "My Items",
};

export const MY_ITEMS_TABS = {
  MY_RECIPES: "My Recipes",
  MY_FOOD: "My Food",
  FAVORITES: "Favorites",
};

export const CATEGORY_LIST_PAGE_SIZE = 50;
export const CATEGORY_CONTENT_PAGE_SIZE = 50;

export const MY_RECIPES_LIST_PAGE_SIZE = 50;

export const MY_FOOD_LIST_PAGE_SIZE = 50;

export const CONTENT_STORE_TYPE = {
  WEEK: "week",
  STANDARD: "standard",
};

export const MEAL_SCHEDULE_TYPE = {
  TODAY: "Today",
  SCHEDULE: "Schedule",
};

export const FOOD_ITEM_TYPE = {
  RECIPE: "Recipe",
  CUSTOM_RECIPE: "CustomRecipe",
  FOOD: "Food",
  RESTAURANT_MENU: "RestaurantMenuItem",
  QUICK_ITEM: "QuickItem",
};

export const FOOD_GUIDE_TYPE = {
  REGULAR: "Regular",
  VEGAN: "Vegan",
};

export const nutrientsFields: {
  [key: string]: { label: string; units: string };
} = {
  calories: {
    label: "Calories",
    units: "Kcal",
  },
  sugar: {
    label: "Sugar",
    units: "g",
  },
  fiber: {
    label: "Fiber",
    units: "g",
  },
  saturatedFat: {
    label: "Saturated Fat",
    units: "g",
  },
  monounsaturatedFat: {
    label: "Monounsaturated Fat",
    units: "g",
  },
  polyunsaturatedFat: {
    label: "Polyunsaturated Fat",
    units: "g",
  },
  transFat: {
    label: "Trans Fat",
    units: "g",
  },
  cholesterol: {
    label: "Cholesterol",
    units: "mg",
  },
  choline: {
    label: "Choline",
    units: "mg",
  },
  copper: {
    label: "Copper",
    units: "mg",
  },
  iodine: {
    label: "Iodine",
    units: "ug",
  },
  magnesium: {
    label: "Magnesium",
    units: "mg",
  },
  manganese: {
    label: "Manganese",
    units: "mg",
  },
  molybdenum: {
    label: "Molybdenum",
    units: "ug",
  },
  selenium: {
    label: "Selenium",
    units: "ug",
  },
  sodium: {
    label: "Sodium",
    units: "mg",
  },
  potassium: {
    label: "Potassium",
    units: "mg",
  },
  phosphorus: {
    label: "Phosphorus",
    units: "mg",
  },
  zinc: {
    label: "Zinc",
    units: "mg",
  },
  vitaminA: {
    label: "Vitamin A",
    units: "IU",
  },
  vitaminB1: {
    label: "Vitamin B1 (Thiamin)",
    units: "ug",
  },
  vitaminB2: {
    label: "Vitamin B2 (Riboflavin)",
    units: "mg",
  },
  vitaminB3: {
    label: "Vitamin B3 (Niacin)",
    units: "mg",
  },
  vitaminB5: {
    label: "VitaminB5 (Pantothenic acid)",
    units: "mg",
  },
  vitaminB6: {
    label: "Vitamin B6",
    units: "mg",
  },
  vitaminB7: {
    label: "Vitamin B7 (Biotin)",
    units: "ug",
  },
  vitaminB9: {
    label: "Vitamin B9 (Folic acid)",
    units: "ug",
  },
  vitaminC: {
    label: "Vitamin C",
    units: "mg",
  },
  vitaminD: {
    label: "Vitamin D",
    units: "mcg",
  },
  vitaminE: {
    label: "Vitamin E (alpha-tocopherol)",
    units: "mg",
  },
  calcium: {
    label: "Calcium",
    units: "mg",
  },
  iron: {
    label: "Iron",
    units: "mg",
  },
  netcarbs: {
    label: "Net Carbohydrates (carbs minus fiber)",
    units: "g",
  },
  protein: {
    label: "Protein",
    units: "g",
  },
  carbs: {
    label: "Carbohydrates",
    units: "g",
  },
  fat: {
    label: "Fat",
    units: "g",
  },
  dha: {
    label: "DHA",
    units: "g",
  },
  dpa: {
    label: "DPA",
    units: "g",
  },
  epa: {
    label: "EPA",
    units: "g",
  },
  ala: {
    label: "ALA",
    units: "g",
  },
  omega3: {
    label: "Omega 3 (DHA, DPA, EPA, ALA)",
    units: "g",
  },
};

export const ACTION_DAY_MEALS_TYPES = {
  COPY: "copy",
  MOVE: "move",
};

export const FOOD_LOG_SOURCE = {
  BARCODE: "Barcode",
  RECIPE: "Recipe Library",
  QUICK_ADD: "Quick Add",
  MY_RECIPE: "My Recipes",
  SUGGESTIC: "Suggestic Search",
};
