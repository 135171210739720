import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IImage {
  imageUrl?: string;
}

export const Title = styled.p`
  margin-bottom: 4px;

  font-family: ${styleConst.fonts.roboto};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: ${styleConst.colors.dark};
`;

export const SubTitle = styled.p`
  font-family: ${styleConst.fonts.roboto};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${styleConst.colors.black50};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 20px 0;

  width: 100%;

  &:not(:last-child) {
    border-bottom: 2px solid ${styleConst.colors.black4};
  }

  &:hover {
    background: linear-gradient(
      89.98deg,
      rgba(132, 207, 203, 0) 0%,
      rgba(132, 207, 203, 0.08) 12.5%,
      rgba(132, 207, 203, 0.08) 87.48%,
      rgba(132, 207, 203, 0) 99.97%
    );

    ${Title} {
      color: ${styleConst.colors.primaryDark};
    }
  }
`;

export const Image = styled.div<IImage>`
  display: flex;

  margin-right: 20px;

  width: 52px;
  height: 52px;

  border-radius: 10px;
  background-color: ${styleConst.colors.black4};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
