import React from "react";

import I24CalendarFilled from "assets/icons/I24CalendarFilled";
import I24PlusFilled from "assets/icons/I24PlusFilled";
import I24CrossFilled from "assets/icons/I24CrossFilled";

import styleConst from "constants/style";

import { Action, Actions, ActionTooltip } from "../styles";

type Props = {
  id: number;
  onToday: (id: number) => void;
  onSelectDay: (id: number) => void;
  onRemove: (id: number) => void;
};

const MyRecipes = (props: Props) => {
  const { id, onToday, onSelectDay, onRemove } = props;

  const onPress = (action: (id: number) => void) => (event: any) => {
    event.stopPropagation();
    action(id);
  };

  return (
    <Actions>
      <Action onClick={onPress(onToday)}>
        <I24CalendarFilled color={styleConst.colors.black25} />
        <ActionTooltip>{"Today"}</ActionTooltip>
      </Action>

      <Action onClick={onPress(onSelectDay)}>
        <I24PlusFilled color={styleConst.colors.black25} />
        <ActionTooltip>{"Select a day"}</ActionTooltip>
      </Action>

      <Action onClick={onPress(onRemove)}>
        <I24CrossFilled color={styleConst.colors.black25} />
        <ActionTooltip>{"Remove"}</ActionTooltip>
      </Action>
    </Actions>
  );
};

export default MyRecipes;
