import React from "react";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import I14Star from "assets/icons/I14Star";

import StudioStore from "stores/StudioStore";

import GridCollection from "components/GridCollection";
import { Page } from "components/Layout";

import NavList from "./NavList";

import { NoInternetConnectionError } from "services/api";

import ROUTES from "navigation/routes";

import styleConst from "constants/style";

import { Background, FavoriteButton, Wrapper } from "./styles";

type Props = {} & RouteComponentProps;

@observer
class Studio extends React.Component<Props> {
  @observable page: number = 1;
  @observable isNoInternetConnectionError: boolean = false;

  @computed get needMore() {
    const { context } = StudioStore;
    return context && this.page < context.pages;
  }

  componentDidMount() {
    this.fetch();
  }

  onLoadMore = () => {
    if (this.needMore) {
      this.page++;
      this.fetch();
    }
  };

  fetch = async () => {
    try {
      await StudioStore.fetch(this.page);
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }
  };

  onNavigateToFavorites = () => {
    const { history } = this.props;

    history.push(generatePath(ROUTES.STUDIO_WORKOUTS_FAVORITES));
  };

  onNavigateToCategory = (item: any) => {
    const { history } = this.props;
    const { id } = item;

    if (id) {
      history.push(
        generatePath(ROUTES.STUDIO_CATEGORY_DETAILS, {
          categoryId: id,
        }),
      );
    }
  };

  render() {
    return (
      <Page
        isLoading={StudioStore.isLoading}
        isNoInternetConnectionError={this.isNoInternetConnectionError}
        title={"Studio"}
        titleControls={
          <FavoriteButton
            iconLeft={<I14Star />}
            text="Go to Favorites"
            onClick={this.onNavigateToFavorites}
            height={48}
            fontSize={18}
            fontWeight={600}
            fontFamily={styleConst.fonts.montserrat}
          />
        }
        contentBackground={<Background />}
        topNavigation={<NavList />}
      >
        <Wrapper>
          <GridCollection
            items={StudioStore.items}
            onClick={this.onNavigateToCategory}
            needMore={this.needMore}
            onLoadMore={this.onLoadMore}
            withInfinityScroll
          />
        </Wrapper>
      </Page>
    );
  }
}

export default withRouter(Studio);
