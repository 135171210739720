import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I16Edit = ({ color = styleConst.colors.white }: Props) => (
  <svg width={16} height={16} fill="none" viewBox="0 0 16 16">
    <path
      fill={color}
      d="M12.289 6.596l1.093-1.094.053-.053c.75-.775.75-2.005 0-2.78l-.053-.053-.052-.053a2.002 2.002 0 00-2.783 0l-.052.052-1.11 1.111a7.575 7.575 0 002.904 2.87zM7.93 5.181L3.946 9.167c-.332.332-.498.498-.62.696-.123.199-.197.421-.346.867l-.828 2.488c-.138.414-.207.62-.098.73.11.11.316.04.73-.098l2.485-.829c.446-.148.67-.223.867-.345.198-.123.365-.289.697-.621l3.998-4A9.573 9.573 0 017.93 5.18z"
    />
  </svg>
);

export default I16Edit;
