import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IContainer {
  flat?: boolean;
}

interface IDetails {
  withoutImage?: boolean;
}

interface IImage {
  imageUrl?: string;
}

export const Title = styled.div`
  flex: 1;

  font-size: 16px;
  line-height: 18.75px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.dark};
  font-weight: 500;

  padding-right: 10px;
`;

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 75%;
  height: 100%;

  ${({ flat }) =>
    flat
      ? css`
          margin-bottom: 18px;
          padding: 0;
        `
      : css`
          border-top-color: ${styleConst.colors.black4};
          border-top-width: 2px;
        `};

  &:hover {
    ${Title} {
      color: ${styleConst.colors.primaryDark};
    }
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const Details = styled.div<IDetails>`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: ${({ withoutImage }) => (withoutImage ? 56 : 58)}%;
`;

export const NutrientsContainer = styled.div`
  width: 44%;
  height: 100%;
`;

export const TitleContainer = styled.div``;

export const Brand = styled.div`
  flex: 1;

  font-size: 14px;
  line-height: 18.75px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.black50};
  font-weight: 500;

  padding-right: 10px;
`;

export const Image = styled.div<IImage>`
  width: 60px;
  height: 60px;

  margin-right: 20px;

  border-radius: 10px;
  background-color: ${styleConst.colors.black4};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `}
`;
