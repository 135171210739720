import styled, { css } from "styled-components";

interface IContainer {
  borderColor?: string;
  color?: string;
  height?: number;
  width?: number;
}

interface IIconLeft {
  loading?: boolean;
}

interface ILabel {
  textColor?: string;
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: number | string;
  isIconRight?: boolean;
  isIconLeft?: boolean;
}

interface ILoaderWrapper {
  withoutLeftIcon?: boolean;
}

export const Container = styled.button<IContainer>`
  display: flex;

  align-items: center;
  justify-content: center;

  border-radius: 6px;

  cursor: pointer;

  width: 100%;

  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}

  ${({ height }) => css`
    height: ${height}px;
  `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `};

  ${({ color }) =>
    css`
      background-color: ${color};
    `};

  ${({ borderColor, color }) =>
    borderColor
      ? css`
          border: 2px solid ${borderColor};
        `
      : css`
          border: 2px solid ${color};
        `};
`;

export const IconLeft = styled.div<IIconLeft>`
  display: flex;
  margin-right: 10px;

  ${({ loading }) =>
    loading &&
    css`
      visibility: hidden;
    `}
`;

export const IconRight = styled.div`
  display: flex;
  margin-left: 10px;
`;

export const LabelContainer = styled.div`
  display: flex;
  position: relative;

  flex-direction: row;
  align-items: center;
`;

export const Label = styled.label<ILabel>`
  text-align: center;

  cursor: pointer;

  ${({ fontWeight }) =>
    fontWeight &&
    css`
      font-weight: ${fontWeight};
    `};

  ${({ textColor }) =>
    textColor &&
    css`
      color: ${textColor};
    `};

  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize}px;
    `};

  ${({ fontFamily }) =>
    fontFamily &&
    css`
      font-family: ${fontFamily};
    `};

  ${({ isIconRight, isIconLeft }) =>
    isIconRight &&
    !isIconLeft &&
    css`
      padding-left: 9px;
    `};
`;

export const LoaderWrapper = styled.div<ILoaderWrapper>`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  ${({ withoutLeftIcon }) =>
    withoutLeftIcon &&
    css`
      left: -30px;
    `}
`;
