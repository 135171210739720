import React from "react";
import { observer } from "mobx-react";
import { observable, computed } from "mobx";
import {
  withRouter,
  RouteComponentProps,
  generatePath,
} from "react-router-dom";

import AuthStore from "stores/AuthStore";

import Input from "components/Input";
import Button from "components/Button";
import AlertPopup from "components/AlertPopup";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";

import { email, password } from "utils/env";
import { isEmpty, isEmail, composeValidators } from "utils/validation";

import ROUTES from "navigation/routes";

import {
  Background,
  Container,
  LogoContainer,
  Logo,
  Title,
  ItemWrapper,
  errorTextStyles,
  Description,
} from "./styles";

type Props = RouteComponentProps & GlobalPopupProps & {};

@observer
class ForgotPassword extends React.Component<Props> {
  @observable email: string = AuthStore.email || email;
  @observable password: string = password;
  @observable error = {
    email: "",
  };

  @computed get isError() {
    return Object.values(this.error).some(Boolean);
  }

  onEmailChange = (text: string) => {
    this.email = text;
    this.error.email = "";
  };

  checkForm = () => {
    this.error = {
      email: "",
    };
    this.error.email = composeValidators(isEmpty, isEmail)(this.email);
  };

  openAlertPopup = () => {
    const { openPopup } = this.props;

    openPopup({
      key: "AlertPopup",
      onClose: () => {
        this.onCloseAlertPopup();
      },
      render: ({ close }) => (
        <AlertPopup
          onClose={close}
          title={"Email Sent"}
          description={"We have sent instructions to your email address"}
        />
      ),
    });
  };

  onSubmit = async () => {
    this.checkForm();
    if (!this.isError) {
      try {
        await AuthStore.forgotPassword({
          email: this.email,
        });
        this.openAlertPopup();
      } catch (error: any) {
        this.error.email = error?.message;
        throw new Error(error?.message);
      }
    } else {
      throw new Error();
    }
  };

  onCloseAlertPopup = () => {
    this.props.history.push(generatePath(ROUTES.SIGN_IN));
  };

  render() {
    return (
        <Background>
          <Container>
            <LogoContainer>
              <Logo alt="Logo" />
            </LogoContainer>
            <Title>{"Forgot password?"}</Title>
            <Description>
              Please enter your email address to get instructions on how to
              reset your password
            </Description>
            <ItemWrapper>
              <Input
                value={this.email}
                onChangeText={this.onEmailChange}
                placeholder="Email"
                type="email"
                error={this.error.email}
                errorTextStyles={errorTextStyles}
              />
            </ItemWrapper>
            <ItemWrapper>
              <Button
                loading={AuthStore.isValidating || AuthStore.isLoading}
                fontWeight="bold"
                text={"Submit"}
                onClick={this.onSubmit}
              />
            </ItemWrapper>
          </Container>
        </Background>
    );
  }
}

export default withRouter(withGlobalPopup(ForgotPassword));
