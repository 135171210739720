const ROUTES = {
  SIGN_IN: "/sign-in",
  FORGOT_PASSWORD: "/forgot-password",

  TODAY: "/today",
  TODAY_WORKOUT_DETAILS:
    "/today/:workoutInWorkoutCategoryId/workout/:workoutId",
  TODAY_WORKOUT_DETAILS_MOVEMENT_DETAILS:
    "/today/:workoutInWorkoutCategoryId/workout/:workoutId/movement/:movementId",
  TODAY_RECIPE_DETAILS: "/today/recipe/:recipeId",
  TODAY_QUICK_LINK_DETAILS: "/today/quick-link/:quickLinkId",

  NUTRITION: "/nutrition",
  NUTRITION_FOOD_LOG: "/nutrition/food-log",
  NUTRITION_FOOD_LOG_RECIPE_LIBRARY_RECIPE_DETAILS:
    "/nutrition/food-log/recipe/:recipeId",
  NUTRITION_FOOD_LOG_FOOD_DETAILS: "/nutrition/food-log/food/:recipeId",
  NUTRITION_SEARCH_RECIPE_DETAILS: "/nutrition/search/recipe/:recipeId",

  NUTRITION_RECIPE_LIBRARY: "/nutrition/recipe-library",
  NUTRITION_RECIPE_LIBRARY_CATEGORY_DETAILS:
    "/nutrition/recipe-library/:categoryId",
  NUTRITION_RECIPE_LIBRARY_RECIPE_DETAILS:
    "/nutrition/recipe-library/:categoryId/:recipeId",

  NUTRITION_MY_ITEMS: "/nutrition/my-items",
  NUTRITION_MY_ITEMS_MY_RECIPES: "/nutrition/my-items/my-recipes",
  NUTRITION_MY_ITEMS_MY_RECIPES_DETAILS:
    "/nutrition/my-items/my-recipes/:recipeId",
  NUTRITION_MY_ITEMS_MY_FOOD: "/nutrition/my-items/my-food",
  NUTRITION_MY_ITEMS_MY_FOOD_DETAILS: "/nutrition/my-items/my-food/:recipeId",
  NUTRITION_MY_ITEMS_FAVORITES: "/nutrition/my-items/favorites",
  NUTRITION_MY_ITEMS_FAVORITES_RECIPE:
    "/nutrition/my-items/favorites/:recipeId",

  STUDIO: "/studio/categories",
  STUDIO_WORKOUTS_FAVORITES: "/studio/categories/workouts/favorites",
  STUDIO_WORKOUT_FAVORITES_WORKOUT_DETAILS:
    "/studio/categories/workouts/favorites/:workoutId",
  STUDIO_WORKOUT_FAVORITES_WORKOUT_DETAILS_MOVEMENT_DETAILS:
    "/studio/categories/workouts/favorites/:workoutId/movement/:movementId",
  STUDIO_CATEGORY_DETAILS: "/studio/categories/:categoryId",
  STUDIO_CATEGORY_DETAILS_WORKOUTS:
    "/studio/categories/:categoryId/workouts/:workoutGroup",
  STUDIO_CATEGORY_DETAILS_WORKOUT_DETAILS:
    "/studio/categories/:categoryId/workout/:workoutId",
  STUDIO_CATEGORY_DETAILS_WORKOUT_DETAILS_MOVEMENT_DETAILS:
    "/studio/categories/:categoryId/workout/:workoutId/movements/:movementId",
  STUDIO_CATEGORY_DETAILS_WORKOUTS_WORKOUT_DETAILS:
    "/studio/categories/:categoryId/workouts/:workoutGroup/:workoutId",
  STUDIO_CATEGORY_DETAILS_WORKOUTS_WORKOUT_DETAILS_MOVEMENT_DETAILS:
    "/studio/categories/:categoryId/workouts/:workoutGroup/:workoutId/movement/:movementId",
  STUDIO_SEARCH_WORKOUT_DETAILS: "/studio/categories/search/workout/:workoutId",
  STUDIO_SEARCH_WORKOUT_DETAILS_MOVEMENT_DETAILS:
    "/studio/categories/search/workout/:workoutId/movement/:movementId",

  RESOURCES: "/resources",
  RESOURCES_CATEGORY_DETAILS: "/resources/:categoryId",
  RESOURCES_CATEGORY_DETAILS_QUICK_LINK_DETAILS:
    "/resources/:categoryId/quick-link/:quickLinkId",
  RESOURCES_SEARCH_RESOURCE_DETAILS: "/resources/search/resource/:quickLinkId",
  ONBOARDING: "/onboarding",

  PROFILE: "/profile",
  PROFILE_EDIT: "/profile/edit",
  CHANGE_PASSWORD: "/profile/change-password",
  CONTACTS: "/profile/contacts",
  MACROGOALS: "/profile/macro-goals",
  MEASUREMENTS: "/profile/measurements",
  SETTINGS: "/profile/settings",

  CALENDAR: "/calendar",
  CALENDAR_WORKOUT_SCHEDULE_DATE_DETAILS:
    "/calendar/workout/:workoutId/scheduleDate/:scheduleDate",
  CALENDAR_WORKOUT_SCHEDULE_DATE_DETAILS_MOVEMENT_DETAILS:
    "/calendar/workout/:workoutId/scheduleDate/:scheduleDate/movement/:movementId",

  SEARCH: "/search",
};

export default ROUTES;
