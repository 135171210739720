import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import WorkoutDetailsStore from "stores/WorkoutDetailsStore";

import { Page } from "components/Layout";
import Workout from "components/Studio/WorkoutDetails";
import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";

import StudioNavList from "pages/Studio/NavList";
import TodayNavList from "pages/Today/NavList";
import CalendarNavList from "pages/Calendar/NavList";

import ROUTES from "navigation/routes";

import { WEEK } from "constants/common";

import { Background } from "./styles";

type Props = {} & RouteComponentProps<{
  workoutId: string;
  workoutInWorkoutCategoryId?: string;
  categoryId?: string;
  workoutGroup?: string;
  scheduleDate?: string;
}> &
  SnackbarContextProps &
  GlobalPopupProps;

@observer
class WorkoutDetails extends React.Component<Props> {
  @observable isLoading: boolean = false;

  get categoryId() {
    const {
      match: {
        params: { categoryId },
      },
    } = this.props;

    return categoryId ? Number.parseInt(categoryId) : undefined;
  }

  get scheduleDate() {
    const {
      match: {
        params: { scheduleDate },
      },
    } = this.props;

    return scheduleDate;
  }

  get workoutId() {
    const {
      match: {
        params: { workoutId },
      },
    } = this.props;

    return Number.parseInt(workoutId);
  }

  get workoutGroup() {
    const {
      match: {
        params: { workoutGroup },
      },
    } = this.props;

    return workoutGroup;
  }

  get url() {
    const {
      match: { url },
    } = this.props;

    return url;
  }

  get workoutInWorkoutCategoryId() {
    const {
      match: {
        params: { workoutInWorkoutCategoryId },
      },
    } = this.props;

    return workoutInWorkoutCategoryId
      ? Number.parseInt(workoutInWorkoutCategoryId)
      : undefined;
  }

  get dayOfWeek() {
    const day = Object.keys(WEEK).find(key => WEEK[key] === this.workoutGroup);
    const parsedDay = day !== undefined ? Number.parseInt(day) : null;

    return typeof parsedDay === "number" ? parsedDay + 1 : null;
  }

  get isFavorites() {
    return this.url.includes(ROUTES.STUDIO_WORKOUTS_FAVORITES);
  }

  get isStudio() {
    return this.url.includes(ROUTES.STUDIO);
  }

  get isToday() {
    return this.url.includes(ROUTES.TODAY);
  }

  get isSearch() {
    return this.url.includes(ROUTES.SEARCH);
  }

  get isCalendar() {
    return this.url.includes(ROUTES.CALENDAR);
  }

  @computed
  get navList() {
    if (this.isSearch) {
      return null;
    } else if (this.isStudio) {
      return <StudioNavList />;
    } else if (this.isCalendar) {
      return <CalendarNavList />;
    } else {
      return <TodayNavList />;
    }
  }

  @computed
  get details() {
    return WorkoutDetailsStore.details;
  }

  @computed get workoutTitle() {
    return this.details?.label || this.details?.title;
  }

  onNavigateToMovement = (movement: any) => {
    const { history } = this.props;
    const { id } = movement;

    if (this.isStudio) {
      const path =
        this.categoryId && this.workoutGroup
          ? generatePath(
              ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUTS_WORKOUT_DETAILS_MOVEMENT_DETAILS,
              {
                categoryId: this.categoryId,
                workoutId: this.workoutId,
                workoutGroup: this.workoutGroup,
                movementId: id,
              },
            )
          : this.workoutGroup
          ? generatePath(
              ROUTES.STUDIO_WORKOUT_FAVORITES_WORKOUT_DETAILS_MOVEMENT_DETAILS,
              {
                workoutId: this.workoutId,
                workoutGroup: this.workoutGroup,
                movementId: id,
              },
            )
          : !this.isFavorites
          ? generatePath(
              ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUT_DETAILS_MOVEMENT_DETAILS,
              {
                categoryId: this.categoryId,
                workoutId: this.workoutId,
                movementId: id,
              },
            )
          : generatePath(
              ROUTES.STUDIO_WORKOUT_FAVORITES_WORKOUT_DETAILS_MOVEMENT_DETAILS,
              {
                workoutId: this.workoutId,
                movementId: id,
              },
            );

      history.push(path);
    }

    if (this.isToday && this.workoutInWorkoutCategoryId) {
      const route = ROUTES.TODAY_WORKOUT_DETAILS_MOVEMENT_DETAILS;

      const defaultParams = { workoutId: this.workoutId, movementId: id };
      const params = this.workoutInWorkoutCategoryId
        ? {
            ...defaultParams,
            workoutInWorkoutCategoryId: this.workoutInWorkoutCategoryId,
          }
        : { ...defaultParams };

      history.push(generatePath(route, params));
    }

    if (this.isSearch) {
      history.push(
        generatePath(ROUTES.STUDIO_SEARCH_WORKOUT_DETAILS_MOVEMENT_DETAILS, {
          workoutId: this.workoutId,
          movementId: id,
        }),
      );
    }

    if (this.isCalendar) {
      history.push(
        generatePath(
          ROUTES.CALENDAR_WORKOUT_SCHEDULE_DATE_DETAILS_MOVEMENT_DETAILS,
          {
            workoutId: this.workoutId,
            movementId: id,
            scheduleDate: this.scheduleDate,
          },
        ),
      );
    }
  };

  onChangeIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  onGoBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    return (
      <Page
        isLoading={this.isLoading}
        title={this.workoutTitle}
        topNavigation={this.navList}
        contentBackground={
          <>
            <Background />
            <Background leftDots />
          </>
        }
      >
        <Workout
          workoutId={this.workoutId}
          categoryId={this.categoryId}
          dayOfWeek={this.dayOfWeek}
          scheduleDate={this.scheduleDate}
          workoutInWorkoutCategoryId={this.workoutInWorkoutCategoryId}
          onNavigateToMovement={this.onNavigateToMovement}
          onChangeIsLoading={this.onChangeIsLoading}
          onGoBack={this.onGoBack}
        />
      </Page>
    );
  }
}

export default withGlobalSnackbar(withGlobalPopup(withRouter(WorkoutDetails)));
