import styled from "styled-components";

import styleConst from "constants/style";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  align-items: center;
`;

export const Label = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  font-family: ${styleConst.fonts.roboto};
  color: ${({ color }) => color};
`;

export const Value = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: ${styleConst.fonts.roboto};
  color: ${({ color }) => color};
`;

export const TitleContainer = styled.div`
  margin-left: 10px;
  width: 85px;
  display: flex;
  flex-direction: column;
`;

export const ProgressbarContainer = styled.div`
  position: relative;
  display: flex;

  width: 58px;
  height: 58px;

  font-family: ${styleConst.fonts.roboto};
  font-weight: 400;

  align-items: center;
`;
