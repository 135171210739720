import React from "react";
import { isSameDay, format, addWeeks } from "date-fns";

import I24ArrowLeft from "assets/icons/I24ArrowLeft";
import I24ArrowRight from "assets/icons/I24ArrowRight";

import styleConst from "constants/style";

import { formatDate } from "utils/date";

import {
  Circle,
  Container,
  Day,
  DayContainer,
  IconContainer,
  Label,
  Title,
  Week,
} from "./styles";

type Props = {
  active: Date;
  onDayChange: (day: Date) => void;
  week: Date[];
};

class DaySelect extends React.Component<Props> {
  get title() {
    const { active } = this.props;
    return formatDate(active, "LLLL yyyy");
  }

  onDayChange = (value: Date) => () => {
    const { onDayChange } = this.props;
    onDayChange?.(value);
  };

  onPrevPress = () => {
    const { active, onDayChange } = this.props;
    onDayChange?.(addWeeks(active, -1));
  };

  onNextPress = () => {
    const { active, onDayChange } = this.props;
    onDayChange?.(addWeeks(active, 1));
  };

  render() {
    const { active, week } = this.props;

    return (
      <Container>
        <Title>{this.title}</Title>
        <Week>
          <IconContainer onClick={this.onPrevPress} left>
            <I24ArrowLeft color={styleConst.colors.black50} />
          </IconContainer>
          {week.map((day: Date) => {
            const isActive = isSameDay(day, active);
            return (
              <DayContainer key={day.toISOString()}>
                <Day onClick={this.onDayChange(day)}>
                  <Label>{format(day, "E")}</Label>
                  <Circle active={isActive}>{format(day, "d")}</Circle>
                </Day>
              </DayContainer>
            );
          })}
          <IconContainer onClick={this.onNextPress} right>
            <I24ArrowRight color={styleConst.colors.black50} />
          </IconContainer>
        </Week>
      </Container>
    );
  }
}
export default DaySelect;
