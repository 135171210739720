import styled, { css } from "styled-components";

import { MEDIA_QUERIES } from "constants/layout";
import styleConst from "constants/style";

interface IImage {
  imageUrl?: string;
}

interface ISection {
  isEquipment?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

export const Equipments = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin: 11px 0;
`;

export const EquipmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0 64px 10px 0;
`;

export const EquipmentImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 10px;

  height: 50px;
  width: 50px;

  background-color: ${styleConst.colors.primary10};
  border-radius: 50%;
`;

export const EquipmentImage = styled.div<IImage>`
  height: 24px;
  width: 24px;

  background-size: contain;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `}
`;

export const EquipmentText = styled.p`
  font-family: ${styleConst.fonts.roboto};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;

  color: ${styleConst.colors.black50};
`;

export const InfoContainer = styled.div`
  margin-top: 40px;
  width: 780px;
`;

export const Section = styled.div<ISection>`
  margin-bottom: 100px;

  ${({ isEquipment }) =>
    isEquipment &&
    css`
      margin-bottom: 90px;
    `}
`;

export const Text = styled.p`
  font-family: ${styleConst.fonts.roboto};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const TextWrapper = styled.div`
  margin: 0 11px 30px;
`;

export const TitleAdornment = styled.div`
  margin-right: 14px;

  width: 4px;
  height: 28px;
  background-color: ${styleConst.colors.secondary};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 0 11px 20px;

  ${MEDIA_QUERIES.DESKTOP} {
    margin: 0 0 20px;
  }
`;

export const Title = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;
  color: ${styleConst.colors.text};
  line-height: 48px;
`;

export const Workout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 40px;
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 700px;
  width: 100%;
`;

export const NoInternetConnectionErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
`;
