import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IText {
  separator?: boolean;
}

interface IWrapper {
  lastItem?: boolean;
}

export const Text = styled.span<IText>`
  font-family: ${styleConst.fonts.roboto};
  font-size: 12px;
  font-weight: 400;

  line-height: 24px;

  ${({ separator }) =>
    separator &&
    css`
      margin: 0 2px;
    `}
`;

export const Wrapper = styled.div<IWrapper>`
  display: flex;

  color: ${styleConst.colors.black50};

  cursor: pointer;

  ${({ lastItem }) =>
    !lastItem &&
    css`
      color: ${styleConst.colors.black25};
    `}
`;
