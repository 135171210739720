import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I32ArrowRight = ({ color = styleConst.colors.white }: Props) => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none">
    <path
      d="M24.0859 17H5.5C4.94772 17 4.5 16.5523 4.5 16C4.5 15.4477 4.94772 15 5.5 15H24.0858L19.5001 10.4143C19.1096 10.0237 19.1096 9.39057 19.5001 9.00005C19.8906 8.60952 20.5238 8.60952 20.9143 9.00005L27.9143 16L20.9143 23C20.5238 23.3906 19.8906 23.3906 19.5001 23C19.1096 22.6095 19.1096 21.9764 19.5001 21.5858L24.0859 17Z"
      fill={color}
    />
  </svg>
);

export default I32ArrowRight;
