import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
  width?: number;
  height?: number;
};

const I24ChevronLeft = ({
  color = styleConst.colors.white,
  width = 24,
  height = 24,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.500456 15.7079C-0.0745601 15.2479 -0.167789 14.4088 0.292224 13.8338L4.95922 8.00005L0.292225 2.16631C-0.167788 1.59129 -0.0745589 0.752236 0.500457 0.292224C1.07547 -0.167789 1.91453 -0.0745601 2.37454 0.500456L8.37422 8.00005L2.37454 15.4996C1.91453 16.0747 1.07547 16.1679 0.500456 15.7079Z"
      fill={color}
    />
  </svg>
);

export default I24ChevronLeft;
