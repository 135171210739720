import React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import ResourcesStore from "stores/ResourcesStore";
import RecipeDetailsStore from "stores/RecipeDetailsStore";
import WorkoutDetailsStore from "stores/WorkoutDetailsStore";

import { NavListItem } from "components/Layout";
import { NavLinkItemType } from "components/Layout/NavListItem/NavListItem";

import { capitalize } from "utils/common";

import ROUTES from "navigation/routes";

type Props = { workoutLabel?: string } & RouteComponentProps<{
  workoutId?: string;
  workoutInWorkoutCategoryId?: string;
  movementId?: string;
  recipeId?: string;
  quickLinkId?: string;
}>;

@observer
class NavList extends React.Component<Props> {
  get pathname() {
    const {
      location: { pathname },
    } = this.props;

    return pathname;
  }

  get workoutId() {
    const {
      match: {
        params: { workoutId },
      },
    } = this.props;

    return workoutId ? Number.parseInt(workoutId) : undefined;
  }

  get workoutInWorkoutCategoryId() {
    const {
      match: {
        params: { workoutInWorkoutCategoryId },
      },
    } = this.props;

    return workoutInWorkoutCategoryId
      ? Number.parseInt(workoutInWorkoutCategoryId)
      : undefined;
  }

  get movementId() {
    const {
      match: {
        params: { movementId },
      },
    } = this.props;

    return movementId ? Number.parseInt(movementId) : undefined;
  }

  get recipeId() {
    const {
      match: {
        params: { recipeId },
      },
    } = this.props;

    return recipeId ? Number.parseInt(recipeId) : undefined;
  }

  get quickLinkId() {
    const {
      match: {
        params: { quickLinkId },
      },
    } = this.props;

    return quickLinkId ? Number.parseInt(quickLinkId) : undefined;
  }

  get firstItem(): NavLinkItemType {
    return {
      title: "Today",
      onClick: this.onClick(ROUTES.TODAY),
    };
  }

  @computed get workoutTitle() {
    const title =
      WorkoutDetailsStore.details?.label || WorkoutDetailsStore.details?.title;

    return title ? capitalize(title) : undefined;
  }

  @computed get movementTitle() {
    return this.movementId
      ? capitalize(
          WorkoutDetailsStore.getMovementDetails(this.movementId)?.title,
        )
      : undefined;
  }

  @computed get recipeTitle() {
    return RecipeDetailsStore.details?.title
      ? capitalize(RecipeDetailsStore.details?.title)
      : undefined;
  }

  @computed get quickLinkTitle() {
    return ResourcesStore.resourceDetails?.title
      ? capitalize(ResourcesStore.resourceDetails?.title)
      : undefined;
  }

  @computed get navListItems() {
    const firstItem = this.firstItem;

    if (this.workoutId) {
      const secondItem =
        this.workoutId && this.workoutInWorkoutCategoryId
          ? {
              title: this.workoutTitle,
              onClick: this.onClick(ROUTES.TODAY_WORKOUT_DETAILS, {
                workoutId: this.workoutId,
                workoutInWorkoutCategoryId: this.workoutInWorkoutCategoryId,
              }),
            }
          : undefined;
      const thirdItem =
        this.movementId && this.workoutInWorkoutCategoryId
          ? {
              title: this.movementTitle,
              onClick: this.onClick(
                ROUTES.TODAY_WORKOUT_DETAILS_MOVEMENT_DETAILS,
                {
                  workoutId: this.workoutId,
                  movementId: this.movementId,
                  workoutInWorkoutCategoryId: this.workoutInWorkoutCategoryId,
                },
              ),
            }
          : undefined;

      return [firstItem, secondItem, thirdItem].filter(Boolean);
    }

    if (this.recipeId) {
      const secondItem = this.recipeId
        ? {
            title: this.recipeTitle,
            onClick: this.onClick(ROUTES.TODAY_RECIPE_DETAILS, {
              recipeId: this.recipeId,
            }),
          }
        : undefined;

      return [firstItem, secondItem].filter(Boolean);
    }

    if (this.quickLinkId) {
      const secondItem = this.quickLinkId
        ? {
            title: this.quickLinkTitle,
            onClick: this.onClick(ROUTES.TODAY_QUICK_LINK_DETAILS, {
              quickLinkId: this.quickLinkId,
            }),
          }
        : undefined;

      return [firstItem, secondItem].filter(Boolean);
    }

    return [];
  }

  onClick = (route: string, routeParams?: any) => () => {
    const { history } = this.props;

    history.push(generatePath(route, routeParams));
  };

  render() {
    return (
      <>
        {this.navListItems.map((item: any, index: number) => (
          <NavListItem
            key={`${index}-${item.title}`}
            title={item.title}
            onClick={item.onClick}
            isLastItem={index === this.navListItems.length - 1}
          />
        ))}
      </>
    );
  }
}

export default withRouter(NavList);
