import React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import {
  generatePath,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";

import I20Chevron from "assets/icons/I20Chevron";
import IPhotoPlaceholder from "assets/icons/IPhotoPlaceholder";

import ProfileStore from "stores/ProfileStore";

import Icon from "components/Icon";

import ROUTES from "navigation/routes";

import { Container, IconWrapper, UserPhoto } from "./styles";

type Props = {} & RouteComponentProps;

@observer
class Profile extends React.Component<Props> {
  @computed get image() {
    return ProfileStore.image;
  }

  onClick = () => () => {
    this.props.history.push(generatePath(ROUTES.MACROGOALS));
  };

  render() {
    return (
      <Container onClick={this.onClick()}>
        {this.image ? <UserPhoto src={this.image} /> : <IPhotoPlaceholder />}

        <IconWrapper>
          <Icon icon={<I20Chevron />} />
        </IconWrapper>
      </Container>
    );
  }
}

export default withRouter(Profile);
