import React from "react";

import { Row, Controls } from "./styles";

type Props = {
  controls?: React.ReactNode;
  onClick?: () => void;
};

const ListItemWrapper: React.FC<Props> = ({ children, controls, onClick }) => {
  return (
    <Row onClick={onClick}>
      {children}
      {controls ? <Controls>{controls}</Controls> : null}
    </Row>
  );
};

export default ListItemWrapper;
