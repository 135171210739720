import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

export const getLocalTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const zonedTimeToUTC = (date: Date) => {
  const timeZone = getLocalTimezone();
  return zonedTimeToUtc(date, timeZone);
};

export const UTCToZonedTime = (date: Date) => {
  const timeZone = getLocalTimezone();
  return utcToZonedTime(date, timeZone);
};
