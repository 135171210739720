import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import {
  RouteComponentProps,
  generatePath,
  withRouter,
} from "react-router-dom";

import AuthStore from "stores/AuthStore";
import ProfileStore from "stores/ProfileStore";

import I24Edit from "assets/icons/I24Edit";
import I24Logout from "assets/icons/I24Logout";
import IPhotoPlaceholder from "assets/icons/IPhotoPlaceholder";
import I24Nutrition from "assets/icons/I24Nutrition";
import I24Measurements from "assets/icons/I24Measurements";
import I24Call from "assets/icons/I24Call";
import I24LegalAcceptances from "assets/icons/I24LegalAcceptances";
import I24Flag from "assets/icons/I24Flag";
import I24Block from "assets/icons/I24Block";

import { Page } from "components/Layout";
import ButtonInline from "components/ButtonInline";
import ConfirmPopup from "components/ConfirmPopup";
import EditProfilePopup from "components/EditProfilePopup";
import MenuControl from "components/MenuControl";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";

import MacroGoals from "./MacroGoals";
import Measurements from "./Measurements";
import ChangePassword from "./ChangePassword";
import Contacts from "./Contacts";
import NavList from "./NavList";

import { NoInternetConnectionError } from "services/api";

import ROUTES from "navigation/routes";

import { PROFILE_TABS } from "constants/profile";
import { LEGAL_ITEMS } from "constants/legal";
import { UPDATE_MEMBERSHIP } from "constants/updateMembership";

import styleConst from "constants/style";
import {
  Container,
  Banner,
  UserPhoto,
  UserName,
  PhotoWrapper,
  BannerContent,
  BannerControls,
  Content,
  SideBar,
  SideBarTitle,
  Link,
  Links,
  LinkLabel,
  IconContainer,
  InnerLinkWrapper,
} from "./styles";

type Props = {} & RouteComponentProps & GlobalPopupProps;

@observer
class Profile extends React.Component<Props> {
  @observable showMenuControlsId?: number = undefined;
  @observable isNoInternetConnectionError: boolean = false;

  @computed get image() {
    return ProfileStore.image;
  }

  get pathname() {
    const {
      location: { pathname },
    } = this.props;

    return pathname;
  }

  get route() {
    return this.pathname ?? ROUTES.MACROGOALS;
  }

  @computed get isMyMacroGoals() {
    return this.route === ROUTES.MACROGOALS;
  }

  @computed get isChangePassword() {
    return this.route === ROUTES.CHANGE_PASSWORD;
  }

  @computed get isContacts() {
    return this.route === ROUTES.CONTACTS;
  }

  @computed get isMyMeasurements() {
    return this.route === ROUTES.MEASUREMENTS;
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    try {
      await ProfileStore.fetch();
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }
  };

  onEditProfilePress = () => {
    const { openPopup } = this.props;

    openPopup({
      key: "EditProfilePopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <EditProfilePopup
          onClose={() => {
            this.fetch();
            close();
          }}
        />
      ),
    });
  };

  onMacroGoalsNavigate = () => {
    const { history } = this.props;

    history.push(generatePath(ROUTES.MACROGOALS));
  };

  onMyMeasurements = () => {
    const { history } = this.props;

    history.push(generatePath(ROUTES.MEASUREMENTS));
  };

  onMyContactsNavigate = () => {
    const { history } = this.props;

    history.push(generatePath(ROUTES.CONTACTS));
  };

  onChangePasswordNavigate = () => {
    const { history } = this.props;

    history.push(generatePath(ROUTES.CHANGE_PASSWORD));
  };

  onLogoutPress = () => {
    const { openPopup } = this.props;

    openPopup({
      key: "ConfirmPopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <ConfirmPopup
          onClose={close}
          onAccept={this.logout}
          title={`Are you sure you want\nto log out?`}
        />
      ),
    });
  };

  logout = () => {
    const { history } = this.props;

    history.push(generatePath(ROUTES.SIGN_IN));

    AuthStore.logout();
  };

  showMenuControls = (itemId: number) => () => {
    this.showMenuControlsId = itemId;
  };

  hideMenuControls = () => {
    this.showMenuControlsId = undefined;
  };

  render() {
    return (
      <Page
        title="Profile"
        topNavigation={<NavList />}
        isNoInternetConnectionError={this.isNoInternetConnectionError}
      >
        <Banner>
          <BannerContent>
            <PhotoWrapper>
              {this.image ? (
                <UserPhoto src={this.image} />
              ) : (
                <IPhotoPlaceholder width={190} height={190} />
              )}
            </PhotoWrapper>
            <UserName>{ProfileStore.name}</UserName>
          </BannerContent>
          <BannerControls>
            <ButtonInline
              fontSize={14}
              onPress={this.onEditProfilePress}
              style={{ marginLeft: 43 }}
              color={styleConst.colors.white}
              text={"Edit"}
              iconLeft={<I24Edit color={styleConst.colors.white} />}
            />
            <ButtonInline
              fontSize={14}
              onPress={this.onLogoutPress}
              style={{ marginLeft: 43 }}
              color={styleConst.colors.white}
              text={"Logout"}
              iconLeft={<I24Logout color={styleConst.colors.white} />}
            />
          </BannerControls>
        </Banner>

        <Container>
          <SideBar>
            <SideBarTitle>Options</SideBarTitle>
            <Links>
              <Link onClick={this.onMacroGoalsNavigate}>
                <IconContainer>
                  <I24Nutrition
                    color={
                      this.isMyMacroGoals
                        ? styleConst.colors.primary
                        : styleConst.colors.black50
                    }
                  />
                </IconContainer>
                <LinkLabel selected={this.isMyMacroGoals}>
                  {PROFILE_TABS.MACROGOALS}
                </LinkLabel>
              </Link>
              <Link onClick={this.onMyMeasurements}>
                <IconContainer>
                  <I24Measurements
                    color={
                      this.isMyMeasurements
                        ? styleConst.colors.primary
                        : styleConst.colors.black50
                    }
                  />
                </IconContainer>
                <LinkLabel selected={this.isMyMeasurements}>
                  {PROFILE_TABS.MEASUREMENTS}
                </LinkLabel>
              </Link>
              <Link onClick={this.onMyContactsNavigate}>
                <IconContainer>
                  <I24Call
                    color={
                      this.isContacts
                        ? styleConst.colors.primary
                        : styleConst.colors.black50
                    }
                  />
                </IconContainer>
                <LinkLabel selected={this.isContacts}>
                  {PROFILE_TABS.CONTACTS}
                </LinkLabel>
              </Link>
              <Link onClick={this.onChangePasswordNavigate}>
                <IconContainer>
                  <I24Block
                    color={
                      this.isChangePassword
                        ? styleConst.colors.primary
                        : styleConst.colors.black50
                    }
                  />
                </IconContainer>
                <LinkLabel selected={this.isChangePassword}>
                  {PROFILE_TABS.CHANGE_PASSWORD}
                </LinkLabel>
              </Link>
            </Links>
            <Links>
              <Link>
                <MenuControl
                  element={
                    <InnerLinkWrapper>
                      <IconContainer>
                        <I24LegalAcceptances
                          color={styleConst.colors.black50}
                        />
                      </IconContainer>
                      <LinkLabel>{"Legal"}</LinkLabel>
                    </InnerLinkWrapper>
                  }
                  onOpenCallback={this.showMenuControls(1)}
                  onCloseCallback={this.hideMenuControls}
                  menuItems={LEGAL_ITEMS}
                />
              </Link>
              <Link>
                <MenuControl
                  element={
                    <InnerLinkWrapper>
                      <IconContainer>
                        <I24Flag color={styleConst.colors.black50} />
                      </IconContainer>
                      <LinkLabel>{"Update Membership"}</LinkLabel>
                    </InnerLinkWrapper>
                  }
                  onOpenCallback={this.showMenuControls(2)}
                  onCloseCallback={this.hideMenuControls}
                  menuItems={UPDATE_MEMBERSHIP}
                />
              </Link>
            </Links>
          </SideBar>
          <Content>
            {this.isMyMacroGoals ? (
              <MacroGoals needUpdate={!ProfileStore.isLoading} />
            ) : null}
            {this.isMyMeasurements ? <Measurements /> : null}
            {this.isContacts ? <Contacts /> : null}
            {this.isChangePassword ? <ChangePassword /> : null}
          </Content>
        </Container>
      </Page>
    );
  }
}

export default withGlobalPopup(withRouter(Profile));
