import { applySnapshot, flow, types } from "mobx-state-tree";

import api from "services/api";

import { SimpleModel } from "models";
import { MacroNutrients as ItemMacroNutrients, Serving } from "models/Recipe";

import { FOOD_ITEM_TYPE } from "constants/nutrition";

interface QuickAddItem {
  title: string;
  calories?: string;
  carbs: string;
  protein: string;
  fat: string;
  fiber: string;
}

interface AddToFoodLogParams {
  foodMealTypeId: number;
  foodRecipeId?: number;
  foodCustomRecipeId?: number;
  amount?: number;
  quickAddItem?: QuickAddItem;
}

const GoalValue = types.model("GoalValue", {
  value: types.number,
  goal: types.number,
});

const SummaryWater = types.model("SummaryWater", {
  amount: types.number,
  goal: types.number,
  unit: types.string,
});

export const SummaryMacroNutrients = types.model("SummaryMacroNutrients", {
  carbs: GoalValue,
  fiber: GoalValue,
  fat: GoalValue,
  protein: GoalValue,
  calories: types.maybe(types.number),
  saturatedFat: types.maybe(types.number),
  cholesterol: types.maybe(types.number),
  potassium: types.maybe(types.number),
  iron: types.maybe(types.number),
  magnesium: types.maybe(types.number),
  zinc: types.maybe(types.number),
  calcium: types.maybe(types.number),
  sodium: types.maybe(types.number),
  vitaminA: types.maybe(types.number),
  vitaminC: types.maybe(types.number),
  vitaminD: types.maybe(types.number),
  sugar: types.maybe(types.number),
  transFat: types.maybe(types.number),
});

export const Item = types
  .model("Item", {
    id: types.number,
    internalId: types.maybe(types.number),
    title: types.maybe(types.string),
    imageUrl: types.maybe(types.string),
    itemType: types.enumeration(Object.values(FOOD_ITEM_TYPE)),
    amount: types.maybe(types.number),
    macronutrients: ItemMacroNutrients,
    serving: types.maybe(Serving),
  })
  .views(self => ({
    get isCustomRecipe() {
      return self.itemType === FOOD_ITEM_TYPE.CUSTOM_RECIPE;
    },
    get isFood() {
      return self.itemType === FOOD_ITEM_TYPE.FOOD;
    },
    get isQuickItem() {
      return self.itemType === FOOD_ITEM_TYPE.QUICK_ITEM;
    },
    get isRecipe() {
      return self.itemType === FOOD_ITEM_TYPE.RECIPE;
    },
  }));

export const FoodLogItem = types.model("FoodLogItem", {
  foodMealType: SimpleModel,
  items: types.array(Item),
});

export const AvailableMealSuggestionsItem = types.model(
  "AvailableMealSuggestionsItem",
  {
    isAvailable: types.boolean,
    id: types.number,
    name: types.string,
  },
);

const Summary = types
  .model("Summary", {
    water: SummaryWater,
    macronutrients: SummaryMacroNutrients,
    foodDayType: SimpleModel,
  })
  .views(self => ({
    get isLowCarbDay() {
      return self.foodDayType.id === 2;
    },
  }));

const FoodLog = types
  .model("FoodLog", {
    items: types.array(FoodLogItem),
    availableMealSuggestions: types.array(AvailableMealSuggestionsItem),
    summary: types.maybe(Summary),
    isLoading: types.optional(types.boolean, false),
    isLoaded: types.optional(types.boolean, false),
    isSuggestLoading: types.optional(types.boolean, false),
    isWaterLoading: types.optional(types.boolean, false),
    isBarcodeSearching: types.optional(types.boolean, false),
    isManagingMeals: types.optional(types.boolean, false),
    isNeedToShowWaterGoalAnimation: (types.boolean, true),
  })
  .actions(self => ({
    checkWeeklySuggest: flow(function* (startDate: string, endDate: string) {
      try {
        const payload = {
          startDate,
          endDate,
        };

        const { isNeedToShow } = yield api.get(
          `/mobile/food/suggest/${startDate}/${endDate}/need-to-show`,
          payload,
        );

        return isNeedToShow;
      } catch (error) {
        console.log("error :>> ", error);
      }
    }),

    suggest: flow(function* (
      date: string,
      foodCategoryGuideType: string,
      foodCategoryId?: number,
      foodMealTypeId?: number,
    ) {
      try {
        const payload = {
          date,
          foodCategoryGuideType,
          foodCategoryId,
          ...(foodMealTypeId ? { foodMealTypeId } : {}),
        };

        const { isLogged } = yield api.post("/mobile/food/suggest", payload);

        return isLogged as boolean;
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      }
    }),

    getAvailableSuggestCategories: flow(function* (date: string) {
      const { items } = yield api.get(
        `/mobile/food/suggest/${date}/category/availability`,
      );
      return items;
    }),

    getAvailableMealSuggestions: flow(function* (date: string) {
      const { items } = yield api.get(
        `/mobile/food/suggest/${date}/meals/availability`,
      );
      applySnapshot(self, { ...self, availableMealSuggestions: items });
    }),

    suggestInRange: flow(function* (
      startDate: string,
      endDate: string,
      foodCategoryGuideType: string,
      foodCategoryId?: number,
    ) {
      self.isSuggestLoading = true;
      try {
        const payload = {
          startDate,
          endDate,
          foodCategoryGuideType,
          foodCategoryId,
        };

        yield api.post("/mobile/food/suggest/range", payload);
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isSuggestLoading = false;
      }
    }),
  }))
  .actions(self => ({
    fetchItems: flow(function* (date: string) {
      try {
        const { items } = yield api.get(`/mobile/food/log/${date}`);
        applySnapshot(self, { ...self, items });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      }
    }),

    fetchSummary: flow(function* (date: string) {
      try {
        const summary = yield api.get(`/mobile/food/${date}/summary`);
        applySnapshot(self, { ...self, summary });
      } catch (error) {
        console.log("error :>> ", error);
      }
    }),

    addWaterAmount(amount: number) {
      if (self.summary) {
        self.summary.water.amount += amount;
      }
    },

    updateWaterAmount(amount: number) {
      if (self.summary) {
        self.summary.water.amount = amount;
      }
    },
  }))
  .actions(self => ({
    fetch: flow(function* (date: string) {
      self.isLoading = true;
      try {
        yield self.fetchItems(date);
        yield self.fetchSummary(date);
        self.isLoaded = true;
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    addWater: flow(function* (date: string, amount: number) {
      self.isWaterLoading = true;

      try {
        yield api.post(`/mobile/water/${date}`, {
          amount,
        });

        self.addWaterAmount(amount);
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isWaterLoading = false;
      }
    }),

    editWater: flow(function* (date: string, amount: number) {
      self.isWaterLoading = true;

      try {
        yield api.put(`/mobile/water/${date}`, {
          amount,
        });

        self.updateWaterAmount(amount);
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isWaterLoading = false;
      }
    }),

    resetWaterGoal: flow(function* () {
      try {
        yield api.delete("/mobile/water/goal/");
        self.isNeedToShowWaterGoalAnimation = true;
      } catch (error) {
        console.log("error :>>", error);
        throw error;
      } finally {
        self.isWaterLoading = false;
      }
    }),

    addToFoodLog: flow(function* (date: string, params: AddToFoodLogParams) {
      self.isLoading = true;
      try {
        yield api.post(`/mobile/food/log/${date}`, params);
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    removeSelectedFromFoodLog: flow(function* (ids: number[], date: string) {
      self.isLoading = true;

      try {
        yield api.delete(`/mobile/v2/food/log/${date}`, {
          ids: ids,
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    removeFromFoodLog: flow(function* (
      date: string,
      params?: { foodMealTypeId?: number; id?: string },
    ) {
      self.isLoading = true;

      try {
        yield api.delete(`/mobile/food/log/${date}`, params);
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    changeAmount: flow(function* (date: string, params: AddToFoodLogParams) {
      try {
        yield api.put(`/mobile/food/log/${date}`, params);
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      }
    }),

    copyMeals: flow(function* (ids: number[], date: string) {
      self.isManagingMeals = true;

      try {
        yield api.post(`/mobile/food/log/copy-to/${date}`, {
          logIds: ids,
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isManagingMeals = false;
      }
    }),

    moveMeals: flow(function* (ids: number[], date: string) {
      self.isManagingMeals = true;

      try {
        yield api.post(`/mobile/food/log/move-to/${date}`, {
          logIds: ids,
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isManagingMeals = false;
      }
    }),
  }));

const FoodLogStore = FoodLog.create();

export default FoodLogStore;
