import { applySnapshot, flow, getSnapshot, types } from "mobx-state-tree";
import { toJS } from "mobx";

import RecipeFavoritesStore from "stores/RecipeFavoritesStore";

import api from "services/api";

import { Context } from "models";
import { Recipe, Category } from "models/Recipe";

import { CATEGORY_LIST_PAGE_SIZE } from "constants/nutrition";

import { getWeekDay } from "utils/common";

const RecipeLibrary = types
  .model("Search", {
    context: types.maybe(Context),
    recipeContext: types.maybe(Context),
    details: types.maybe(Category),
    items: types.array(Category),
    recipes: types.array(Recipe),
    isLoading: types.optional(types.boolean, false),
    isMoreLoading: types.optional(types.boolean, false),
    isRecipesLoading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get availableRecipes() {
      return self.recipes.filter(
        ({ isPermissionPreview }) => !isPermissionPreview,
      );
    },

    get availableCategories() {
      return self.items.filter(
        ({ isPermissionPreview }) => !isPermissionPreview,
      );
    },
  }))
  .views(self => ({
    get sections() {
      if (self.details?.isWeekStructure) {
        const current = [...Array(7)].map(() => []);
        const week = self.recipes.reduce((output: any, item: any) => {
          if (item.day) {
            output[item.day - 1].push(item);
          }
          return output;
        }, current);

        return week.map((day: any, index: number) => ({
          title: getWeekDay(index),
          data: day,
        }));
      } else {
        return [];
      }
    },

    get availableSections() {
      if (self.details?.isWeekStructure) {
        const current = [...Array(7)].map(() => []);
        const week = self.availableRecipes.reduce((output: any, item: any) => {
          if (item.day) {
            output[item.day - 1].push(item);
          }
          return output;
        }, current);

        return week.map((day: any, index: number) => ({
          title: getWeekDay(index),
          data: day,
        }));
      } else {
        return [];
      }
    },

    getRecipeById(id: number) {
      return self.recipes.find(recipe => recipe.id === id);
    },
  }))
  .actions(self => ({
    updateRecipe(id: number, recipe: any) {
      const { recipes } = getSnapshot(self);
      const index = recipes.findIndex(item => item.id === id);
      self.recipes[index] = { ...recipes[index], ...recipe };
    },
  }))
  .actions(self => ({
    fillDetails(details: any) {
      if (!details) return;
      applySnapshot(self, { ...self, details: toJS(details) });
    },
  }))
  .actions(self => ({
    fetch: flow(function* (page = 1) {
      self.isLoading = true;

      try {
        const { context, items } = yield api.get("/mobile/food/category/list", {
          page: page,
          size: CATEGORY_LIST_PAGE_SIZE,
        });

        applySnapshot(self, {
          ...self,
          items: page === 1 ? items : self.items.concat(items),
          context,
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    fetchCategoryDetails: flow(function* (id: number) {
      try {
        const details = yield api.get(`/mobile/food/category/${id}`);
        return details;
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      }
    }),

    fetchRecipes: flow(function* ({
      page,
      categoryId,
    }: {
      page: number;
      categoryId?: number;
    }) {
      self.isRecipesLoading = true;

      try {
        const id = self.details?.id ?? categoryId;

        const { context: recipeContext, items: recipes } = yield api.get(
          `/mobile/food/category/${id}/recipe/list`,
          {
            page,
            size: CATEGORY_LIST_PAGE_SIZE,
          },
        );

        applySnapshot(self, {
          ...self,
          recipes: page === 1 ? recipes : self.recipes.concat(recipes),
          recipeContext,
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    addToFavorites: flow(function* (id: number) {
      try {
        yield RecipeFavoritesStore.addToFavorites(id);
        self.updateRecipe(id, { isFavorite: true });
      } catch (error) {
        console.log("error :>> ", error);
      }
    }),

    removeFromFavorites: flow(function* (id: number) {
      try {
        yield RecipeFavoritesStore.removeFromFavorites(id);
        self.updateRecipe(id, { isFavorite: false });
      } catch (error) {
        console.log("error :>> ", error);
      }
    }),

    resetDetails() {
      applySnapshot(self, { ...self, details: undefined });
    },
  }));

const RecipeLibraryStore = RecipeLibrary.create();

export default RecipeLibraryStore;
