import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import Tippy from "@tippyjs/react";

import I24Nutrition from "assets/icons/I24Nutrition";
import I24CalendarFilled from "assets/icons/I24CalendarFilled";

import OutsideClickHandler from "components/OutsideClickHandler";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";

import styleConst from "constants/style";

import { Wrapper, Menu } from "./styles";

type Props = {
  disabled?: boolean;
  element: React.ReactElement<any>;
  onToday: () => void;
  onSelectDay: () => void;
} & GlobalPopupProps;

@observer
class AddFoodControl extends React.Component<Props> {
  @observable isMenuOpened: boolean = false;

  openMenu = () => {
    const { disabled = false } = this.props;

    if (disabled) return;
    
    this.isMenuOpened = true;
  };

  hideMenu = () => {
    this.isMenuOpened = false;
  };

  get menuItems() {
    const { onToday, onSelectDay } = this.props;
    return [
      {
        text: "Add to Today's Food Log",
        icon: <I24Nutrition color={styleConst.colors.black} />,
        onClick: onToday,
      },
      {
        text: "Select a Day",
        icon: <I24CalendarFilled color={styleConst.colors.black} />,
        onClick: onSelectDay,
      },
    ];
  }

  render() {
    const { element } = this.props;

    return (
      <OutsideClickHandler onClick={this.hideMenu}>
        <Wrapper>
          <Tippy
            appendTo={document.body}
            animation="fade"
            arrow={false}
            maxWidth={"none"}
            placement="bottom-start"
            theme={"menu"}
            visible={this.isMenuOpened}
            content={<Menu items={this.menuItems} />}
            offset={[0, 20]}
            interactive
          >
            <Wrapper onClick={this.openMenu}>{element}</Wrapper>
          </Tippy>
        </Wrapper>
      </OutsideClickHandler>
    );
  }
}

export default withGlobalPopup(AddFoodControl);
