import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Quill } from "react-quill";

import "react-quill/dist/quill.bubble.css";

import ResourcesStore from "stores/ResourcesStore";

import { Page } from "components/Layout";

import QuickLinksNavList from "pages/Resources/NavList";
import TodayNavList from "pages/Today/NavList";

import { NoInternetConnectionError, NotFoundError } from "services/api";

import ROUTES from "navigation/routes";

import { redirectToRootRoute } from "utils/route";

import { CATEGORY_CONTENT_VIEW_TYPE } from "constants/resources";

import { Background, Button, Container, Image, ViewContainer } from "./styles";

type Props = {} & RouteComponentProps<{
  quickLinkId: string;
  categoryId?: string;
}>;

@observer
class ResourceDetails extends React.Component<Props> {
  @observable isLoading: boolean = false;
  @observable isLoaded: boolean = false;
  @observable isNoInternetConnectionError: boolean = false;

  get url() {
    const {
      match: { url },
    } = this.props;

    return url;
  }

  get categoryId() {
    const {
      match: {
        params: { categoryId },
      },
    } = this.props;

    return categoryId ? Number.parseInt(categoryId) : undefined;
  }

  get quickLinkId() {
    const {
      match: {
        params: { quickLinkId },
      },
    } = this.props;

    return quickLinkId ? Number.parseInt(quickLinkId) : undefined;
  }

  get isToday() {
    return this.url.includes(ROUTES.TODAY);
  }

  get isSearch() {
    return this.url.includes(ROUTES.SEARCH);
  }

  get navList() {
    return !this.isSearch ? (
      !this.isToday ? (
        <QuickLinksNavList />
      ) : (
        <TodayNavList />
      )
    ) : null;
  }

  @computed get details() {
    return ResourcesStore.resourceDetails;
  }

  @computed get quickLinkUrl() {
    return ResourcesStore.resourceDetails?.url;
  }

  async componentDidMount() {
    const { history } = this.props;

    this.isLoading = true;

    try {
      await Promise.all([
        this.categoryId
          ? ResourcesStore.fetchDetails({
              categoryId: this.categoryId,
              viewType: CATEGORY_CONTENT_VIEW_TYPE.RESOURCE_DETAILS,
            })
          : null,
        this.quickLinkId
          ? ResourcesStore.fetchResourceDetails(this.quickLinkId)
          : null,
      ]);
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
      if (error instanceof NotFoundError) {
        redirectToRootRoute(history, this.url);
      }
    } finally {
      this.isLoading = false;
      this.isLoaded = true;

      this.pasteHtml();
    }
  }

  componentWillUnmount() {
    ResourcesStore.resetResourceDetails();
  }

  pasteHtml = () => {
    const quill = new Quill("#descriptionContainer", {
      readOnly: true,
      theme: "bubble",
    });
    quill.clipboard.dangerouslyPasteHTML(
      ResourcesStore.resourceDetails?.description ?? "",
    );
  };

  openQuickLink = () => {
    if (this.quickLinkUrl) {
      window.open(this.quickLinkUrl);
    }
  };

  render() {
    return (
      <Page
        title={this.details?.title}
        isLoading={this.isLoading}
        isNoInternetConnectionError={this.isNoInternetConnectionError}
        topNavigation={this.navList}
        titleUpperContent={
          this.isLoaded && (
            <Image
              imageUrl={this.details?.imageUrl}
              withoutNavList={this.isSearch}
            />
          )
        }
        titleControls={
          this.quickLinkUrl ? (
            <Button text="Open" onClick={this.openQuickLink} height={50} />
          ) : null
        }
        contentBackground={
          <>
            <Background />
            <Background leftDots />
          </>
        }
      >
        <Container>
          <ViewContainer id="descriptionContainer" />
        </Container>
      </Page>
    );
  }
}

export default withRouter(ResourceDetails);
