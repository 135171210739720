import React from "react";
import { observer } from "mobx-react";

import Table from "components/MacroGoals/Table";

import { Container } from "./styles";

type Props = {
  plan: {
    items: any[];
  };
  onSelectType?: (data: any) => void;
  selected?: any;
};

@observer
class Edit extends React.Component<Props> {
  render() {
    const {
      plan: { items },
      onSelectType,
      selected,
    } = this.props;

    return (
      <Container>
        <Table
          items={items}
          onSelectType={onSelectType}
          selected={selected}
          editMode
        />
      </Container>
    );
  }
}

export default Edit;
