import { QUESTION_TYPE } from "constants/common";

export const ACTIVITY_LEVEL = {
  SEDENTARY: { label: "Sedentary (little or no exercise)", value: "Sedentary" },
  LIGHTLY_ACTIVE: {
    label: "Lightly active (light exercise/sports 1-3 days/week)",
    value: "LightlyActive",
  },
  MODERATELY_ACTIVE: {
    label: "Moderately active (moderate exercise/sports 3-5 days/week)",
    value: "ModeratelyActive",
  },
  VERY_ACTIVE: {
    label: "Very active (hard exercise/sports 6-7 days a week)",
    value: "VeryActive",
  },
  EXTRA_ACTIVE: {
    label: "Extra active (very hard exercise/sports & a physical job)",
    value: "ExtraActive",
  },
};

export const WEIGHT_GOAL = {
  LOSE_WEIGHT: { label: "Lose Weight", value: "LoseWeight" },
  MAINTAIN: { label: "Maintain", value: "Maintain" },
  GAIN: { label: "Gain", value: "Gain" },
};

export const DEFAULT_GROUPS = [
  {
    title: "Parameters",
    subtitle:
      "Please take a couple of minutes and update your parameters if needed",
    fields: [
      {
        description: "",
        label: "Height",
        name: "height",
        type: QUESTION_TYPE.SELECT,
        required: true,
        options: [
          { label: "4'11\"", value: "4'11\"" },
          { label: "5'0\"", value: "5'0\"" },
          { label: "5'1\"", value: "5'1\"" },
          { label: "5'2\"", value: "5'2\"" },
          { label: "5'3\"", value: "5'3\"" },
          { label: "5'4\"", value: "5'4\"" },
          { label: "5'5\"", value: "5'5\"" },
          { label: "5'6\"", value: "5'6\"" },
          { label: "5'7\"", value: "5'7\"" },
          { label: "5'8\"", value: "5'8\"" },
          { label: "5'9\"", value: "5'9\"" },
          { label: "5'10\"", value: "5'10\"" },
          { label: "5'11\"", value: "5'11\"" },
          { label: "6'0\"", value: "6'0\"" },
          { label: "6'1\"", value: "6'1\"" },
          { label: "6'2\"", value: "6'2\"" },
          { label: "6'3\"", value: "6'3\"" },
          { label: "6'4\"", value: "6'4\"" },
          { label: "6'5\"", value: "6'5\"" },
          { label: "6'6\"", value: "6'6\"" },
        ],
      },
      {
        description: "",
        label: "Weight",
        name: "weight",
        type: QUESTION_TYPE.NUMBER,
        required: true,
        dimension: "lbs.",
      },
      {
        description: "",
        label: "Age",
        name: "age",
        type: QUESTION_TYPE.NUMBER,
        required: true,
        dimension: "yr.",
      },
    ],
  },
  {
    title: "Select your activity level:",
    subtitle: "",
    fields: [
      {
        description: "",
        label: "",
        name: "activityLevel",
        type: QUESTION_TYPE.RADIO,
        options: Object.values(ACTIVITY_LEVEL),
        required: true,
      },
    ],
  },
  {
    title: "Select your goal:",
    subtitle: "",
    fields: [
      {
        description: "",
        label: "",
        name: "weightGoal",
        type: QUESTION_TYPE.RADIO,
        options: Object.values(WEIGHT_GOAL),
        required: true,
      },
    ],
  },
];
