import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";

import I24RecipeLibrary from "assets/icons/I24RecipeLibrary";
import I24ArrowBack from "assets/icons/I24ArrowBack";

import RecipeLibraryStore from "stores/RecipeLibraryStore";

import FormTitle from "components/Form/FormTitle";
import GridCollection from "components/GridCollection";
import ButtonInline from "components/ButtonInline";
import Spinner from "components/Spinner";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import { NoInternetConnectionError } from "services/api";

import RecipesListForm from "./RecipesListForm";
import { AddFoodSubmitPayloadType, ADD_RECIPE_TYPE } from "../AddFoodControl";

import {
  BackButtonWrapper,
  Container,
  GridCollectionWrapper,
  Loader,
} from "./styles";

type Props = {
  onSubmit: (payload: AddFoodSubmitPayloadType) => void;
  submitIsLoading?: boolean;
  onClose: () => void;
};

@observer
class RecipeLibraryForm extends React.Component<Props> {
  @observable formPage: number = 1;
  @observable page: number = 1;

  @observable isLoading: boolean = false;
  @observable isNoInternetConnectionError: boolean = false;

  @computed get items() {
    return RecipeLibraryStore.availableCategories;
  }

  @computed get needMore() {
    const { context } = RecipeLibraryStore;
    return context && this.page < context.pages;
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    this.isLoading = true;

    try {
      await RecipeLibraryStore.fetch(this.page);
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    } finally {
      this.isLoading = false;
    }
  };

  goBack = () => {
    this.formPage--;
  };

  goForward = () => {
    this.formPage++;
  };

  onCategoryNavigate = (details: any) => {
    RecipeLibraryStore.resetDetails();
    RecipeLibraryStore.fillDetails(details);

    if (details?.id) {
      this.goForward();
    }
  };

  onLoadMore = async () => {
    if (this.needMore) {
      this.page++;
      await RecipeLibraryStore.fetch(this.page);
    }
  };

  onSubmit = (values: any) => {
    const { onSubmit } = this.props;

    onSubmit({ values, addRecipeType: ADD_RECIPE_TYPE.RECIPE_LIBRARY });
  };

  render() {
    const { submitIsLoading, onClose } = this.props;

    return (
      <Container fixedWidth={this.formPage === 2}>
        {this.isNoInternetConnectionError ? (
          <NoInternetConnectionPlaceholder type={"popup"} onSubmit={onClose} />
        ) : (
          <>
            {this.formPage === 1 && (
              <>
                <FormTitle
                  title={"Recipe Library"}
                  icon={<I24RecipeLibrary />}
                />
                {!this.isLoading ? (
                  <GridCollectionWrapper id="scrollableDiv">
                    <GridCollection
                      items={this.items}
                      onClick={this.onCategoryNavigate}
                      needMore={this.needMore}
                      onLoadMore={this.onLoadMore}
                      scrollableTarget={"scrollableDiv"}
                      withInfinityScroll
                    />
                  </GridCollectionWrapper>
                ) : (
                  <Loader>
                    <Spinner large></Spinner>
                  </Loader>
                )}
              </>
            )}

            {this.formPage === 2 && (
              <>
                <BackButtonWrapper>
                  <ButtonInline
                    text={"Back"}
                    iconLeft={<I24ArrowBack />}
                    onPress={this.goBack}
                  />
                </BackButtonWrapper>
                <RecipesListForm
                  onSubmit={this.onSubmit}
                  submitIsLoading={submitIsLoading}
                  onClose={onClose}
                />
              </>
            )}
          </>
        )}
      </Container>
    );
  }
}

export default RecipeLibraryForm;
