import * as React from "react";

import style from "constants/style";

type Props = {
  color?: string;
};

const I14Star = ({ color = style.colors.white }: Props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M5.98275 0.670221C6.37068 -0.223407 7.62932 -0.223407 8.01725 0.67022L9.21154 3.42143C9.36865 3.78334 9.70369 4.03492 10.0929 4.08325L13.0243 4.44722C13.9499 4.56215 14.3309 5.70566 13.6614 6.35957L11.418 8.55077C11.1499 8.81268 11.0305 9.19282 11.1003 9.56239L11.6873 12.67C11.8658 13.6149 10.8548 14.3308 10.0323 13.8418L7.5644 12.3745C7.21621 12.1675 6.78379 12.1675 6.4356 12.3745L3.96772 13.8418C3.14521 14.3308 2.13415 13.6149 2.31267 12.6699L2.89972 9.56238C2.96953 9.19282 2.85014 8.81268 2.582 8.55077L0.338621 6.35957C-0.330862 5.70566 0.0500865 4.56215 0.975703 4.44722L3.90706 4.08325C4.29631 4.03492 4.63135 3.78334 4.78846 3.42143L5.98275 0.670221Z"
        fill={color}
      />
    </svg>
  );
};

export default I14Star;
