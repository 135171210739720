import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24QuickAdd = ({ color = styleConst.colors.black50 }: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      d="M21.6539 6.23912C20.7069 5.27892 19.7527 4.32347 18.7914 3.37275C18.3276 2.91257 17.6727 2.90759 17.2451 3.34217C16.8174 3.77675 16.8366 4.42328 17.2969 4.89058C17.5135 5.11178 17.7451 5.31733 17.9362 5.49799C17.5008 5.94324 17.0938 6.36003 16.6796 6.78252C15.3746 5.752 13.8119 5.10046 12.1621 4.89911V3.1295C12.53 3.1295 12.883 3.1416 13.2361 3.1295C14.0806 3.09323 14.5707 2.1814 14.0927 1.49006C13.9442 1.27668 13.6537 1.16074 13.4279 1H8.73981C8.43793 1.11593 8.15878 1.25605 8.00464 1.56901C7.64949 2.29733 8.12823 3.10461 8.9458 3.13377C9.29669 3.14515 9.64901 3.13377 10.0006 3.13377V4.89413C7.13735 5.32089 4.88638 6.70855 3.37343 9.17235C1.86047 11.6361 1.59765 14.3019 2.58427 17.0282C4.39343 22.0191 10.1846 24.4096 14.9202 22.1364C17.3495 20.97 18.9377 19.0403 19.7191 16.4762C20.6197 13.5196 20.0927 10.7933 18.2629 8.36791L19.5053 7.0969C19.7141 7.31028 19.9314 7.5329 20.1523 7.74912C20.6098 8.19437 21.2498 8.20859 21.6795 7.78824C22.1092 7.36789 22.1128 6.705 21.6539 6.23912ZM11.0171 20.8277C7.24674 20.8818 4.14837 17.6982 4.15405 13.893C4.15974 10.102 7.2574 6.93117 11.0171 6.98523C15.4735 7.04853 17.9483 10.3217 17.9554 13.9065C17.9483 17.482 15.4842 20.763 11.0171 20.8277ZM12.9853 10.4996C12.0757 11.3839 11.1797 12.2829 10.2975 13.1966C10.132 13.3688 10.0652 13.6369 10.0042 13.76C10.029 14.3382 10.2208 14.6782 10.6307 14.8695C11.0405 15.0608 11.4496 15.0175 11.7671 14.7066C12.6905 13.8097 13.6019 12.9015 14.4933 11.974C14.8875 11.5643 14.83 10.9206 14.4287 10.5294C14.0274 10.1382 13.393 10.1027 12.9853 10.4996Z"
      fill={color}
    />
  </svg>
);

export default I24QuickAdd;
