import React from "react";
import { computed } from "mobx";
import { observer } from "mobx-react";
import { SnapshotOrInstance } from "mobx-state-tree";

import RadioButtonList from "components/RadioButtonList";

import { Answer, Field } from "models/Questionnaire";

import { RadioContainer } from "../styles";

type FieldItem = SnapshotOrInstance<typeof Field>;
type AnswerItem = SnapshotOrInstance<typeof Answer> & { value: string };

type Props = {
  field: FieldItem;
  onAnswer: (answer: AnswerItem) => void;
  parentFieldName?: string;
  store: any;
};

type Option = {
  label?: string;
  value: string;
};

@observer
class Radio extends React.Component<Props> {
  @computed get answer(): AnswerItem {
    const { field, store } = this.props;
    return store.getAnswersByField(field.name);
  }

  @computed get value(): string {
    return this.answer?.value ?? "";
  }

  @computed get options() {
    const { field: { options = [] } = {} } = this.props;

    return (
      options.map(({ label, value }) => ({
        label: label ?? value,
        value,
      })) ?? []
    );
  }

  onChange = (option: Option) => {
    const { onAnswer, field, parentFieldName } = this.props;

    onAnswer({
      fieldName: field.name,
      value: option.value,
      parentFieldName,
    });
  };

  render() {
    return (
      <RadioContainer>
        <RadioButtonList
          options={this.options}
          selected={this.value}
          onSelect={this.onChange}
          numberOfLabelLines={this.options.length}
          isQuestionType={true}
        />
      </RadioContainer>
    );
  }
}

export default Radio;
