import React from "react";
import { FlattenSimpleInterpolation } from "styled-components";

import I24Shevron2 from "assets/icons/I24Shevron2";

import styleConst from "constants/style";

import { Container, SelectField, IconContainer, Label, Error } from "./styles";

type Props = {
  onSelectOption: any;
  value?: string;
  options: any;
  label?: string | React.ReactElement;
  withoutErrorText?: boolean;
  error?: string;
  errorTextStyles?: FlattenSimpleInterpolation;
};

const Select: React.FC<Props> = ({
  value,
  onSelectOption,
  options,
  label,
  error,
  withoutErrorText,
  errorTextStyles,
}) => {
  const onChange = React.useCallback(({ target }) => {
    onSelectOption(target.value);
  }, []);

  return (
    <Container>
      <SelectField error={error} value={value} onChange={onChange}>
        {options.map((option: any) => (
          <option key={option.value}>{option.label}</option>
        ))}
      </SelectField>
      {label ? <Label error={error}>{label}</Label> : null}
      <IconContainer>
        <I24Shevron2 color={styleConst.colors.black50} />
      </IconContainer>

      {!withoutErrorText && error ? (
        <Error errorTextStyles={errorTextStyles}>{error}</Error>
      ) : null}
    </Container>
  );
};

export default React.memo(Select);
