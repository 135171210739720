import React from "react";
import I36Star from "assets/icons/I36StarFilled";

import NutrientsMain from "components/NutrientsMain";

import { formatRecipeDuration } from "utils/nutrition";

import styleConst from "constants/style";

import {
  Container,
  Image,
  DetailsContainer,
  FavoriteContainer,
  IconButton,
  ImageContainer,
  Duration,
} from "./styles";

type Props = {
  imageUrl?: string;
  duration?: number;
  nutrients: any;
  isFavorite: boolean;
  onFavoriteClick: () => void;
};

const Details: React.FC<Props> = ({
  imageUrl,
  nutrients,
  isFavorite,
  onFavoriteClick,
  duration,
}) => {
  return (
    <Container>
      <ImageContainer>
        <Image imageUrl={imageUrl} />
        {duration ? (
          <Duration>{formatRecipeDuration(duration)}</Duration>
        ) : null}
      </ImageContainer>
      <DetailsContainer>
        <NutrientsMain withLabel nutrients={nutrients} />
        <FavoriteContainer>
          <IconButton
            onClick={onFavoriteClick}
            backgroundColor={
              isFavorite ? styleConst.colors.primary10 : undefined
            }
            icon={
              <I36Star
                color={
                  isFavorite
                    ? styleConst.colors.primary
                    : styleConst.colors.black25
                }
              />
            }
          />
          {isFavorite ? "Unfavorite" : "Favorite"}
        </FavoriteContainer>
      </DetailsContainer>
    </Container>
  );
};

export default React.memo(Details);
