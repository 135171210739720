import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Preview = ({ color = styleConst.colors.white }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4a5 5 0 00-5 5v1a1 1 0 00-1 1v8a1 1 0 001 1h10a1 1 0 001-1v-8a1 1 0 00-1-1V9a5 5 0 00-5-5zm3 6V9a3 3 0 10-6 0v1h6z"
      fill={color}
    />
  </svg>
);

export default I24Preview;
