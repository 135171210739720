import React from "react";
import { open, close, ContentProps, RenderConfirmation } from "services/popup";

type OpenPopupArguments = {
  key: string;
  render: (props: ContentProps) => React.ReactNode;
  canCloseByOuterClick?: boolean;
  exitButtonZIndex?: number;
  exitButtonColor?: string;
  onClose?: () => void;
  confirmation?: {
    renderConfirmation: RenderConfirmation;
    shouldShowConfirmation: () => boolean;
  };
};

export type GlobalPopupProps = {
  openPopup: (args: OpenPopupArguments) => void;
  closePopup: () => void;
};

const withGlobalPopup = <P extends GlobalPopupProps>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentClass<Omit<P, keyof GlobalPopupProps>> =>
  class Wrapper extends React.Component<Omit<P, keyof GlobalPopupProps>> {
    isOpened: boolean = false;

    componentWillUnmount(): void {
      if (this.isOpened) {
        close();
      }
    }

    openPopup = ({
      key,
      canCloseByOuterClick,
      render,
      onClose,
      confirmation,
      exitButtonZIndex,
      exitButtonColor
    }: OpenPopupArguments) => {
      open(key, {
        render,
        canCloseByOuterClick,
        onClose: this.onClosePopup(onClose),
        confirmation,
        exitButtonZIndex,
        exitButtonColor
      });
      this.isOpened = true;
    };

    onClosePopup = (onClose?: () => void) => () => {
      this.isOpened = false;
      onClose?.();
    };

    render() {
      return (
        <WrappedComponent
          {...(this.props as P)}
          openPopup={this.openPopup}
          closePopup={close}
        />
      );
    }
  };

export default withGlobalPopup;
