import React from "react";
import { observer } from "mobx-react";
import { Portal } from "react-portal";

import { Container, Text } from "./styles";

export type SnackbarType = "Alert" | "Info" | "Warning";

export enum SnackbarTypeValues {
  ALERT = "Alert",
  INFO = "Info",
  WARNING = "Warning",
}

type Props = {
  onClose: () => void;
  message?: string;
  timer?: number;
  type?: SnackbarType;
  position?: "top" | "bottom";
};

const DEFAULT_TIMER = 6000;

@observer
export default class Snackbar extends React.Component<Props> {
  lastTimerId: number | null = null;

  get shouldShow() {
    const { message } = this.props;

    return !!message;
  }

  clear = () => {
    if (this.lastTimerId) {
      clearTimeout(this.lastTimerId);
      this.lastTimerId = null;
    }
  };

  componentDidUpdate() {
    const { onClose, message, timer = DEFAULT_TIMER } = this.props;

    this.clear();

    if (message) {
      this.lastTimerId = window.setTimeout(onClose, timer);
    }
  }

  componentWillUnmount() {
    this.clear();
  }

  render() {
    const {
      position = "bottom",
      message,
      type = SnackbarTypeValues.INFO,
    } = this.props;

    if (!this.shouldShow) {
      return null;
    }

    return (
      <Portal>
        <Container position={position} type={type}>
          <Text>{message}</Text>
        </Container>
      </Portal>
    );
  }
}
