import styled, { css } from "styled-components";

import { PAGE_WIDTH } from "constants/layout";
import styleConst from "constants/style";

interface IContainer {
  isFoodDetails?: boolean;
}

export const BackButtonWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
`;

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 53px;

  height: 830px;
  width: calc(${PAGE_WIDTH.SMALL_DESKTOP} + 46px);
  max-width: 100vw;

  ${({ isFoodDetails }) =>
    isFoodDetails &&
    css`
      align-items: normal;

      min-height: 250px;
      height: auto;
      width: 700px;
    `}
`;

export const OverflowLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 130px;
  z-index: 10;

  width: 100%;
  height: 700px;

  background-color: ${styleConst.colors.white50};
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 700px;
`;

export const ScrollContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 700px;
  width: 100%;
  min-width: ${PAGE_WIDTH.SMALL_DESKTOP};

  overflow-y: auto;
`;

export const TabsWrapper = styled.div`
  margin-bottom: 30px;
`;
