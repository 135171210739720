import styled from "styled-components";

export const BackButtonWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 53px;

  min-height: 250px;
  width: 700px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
`;

export const SearchWrapper = styled.div`
  width: 100%;

  padding: 0 62px;
`;

export const ScrollLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 210px;
`;
