import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { Decorator } from "final-form";
import { Form, FormRenderProps } from "react-final-form";

import FormContext, { FormContextType } from "./formContext";

import { FieldData } from "./types";

export type FormSubmitData = {
  values: any;
};

type Props = {
  children: React.ReactNode;
  initialValues?: { [name: string]: any };
  keepDirtyOnReinitialize?: boolean;
  decorators?: Decorator<any, any>[];
  mutators?: { [key: string]: (...args: any[]) => any };
  onSubmit?: (data: FormSubmitData) => void;
};

@observer
class FormControl extends React.Component<Props> {
  @observable fields: { [name: string]: FieldData } = {};

  registerField = (data: FieldData) => {
    this.fields[data.name] = data;
  };

  onSubmit = (values: any) => {
    this.props.onSubmit?.(values);
  };

  renderFormContextControl = (props: FormRenderProps) => {
    return (
      <FormContextControl {...props} registerField={this.registerField}>
        {this.props.children}
      </FormContextControl>
    );
  };

  render() {
    const {
      decorators,
      initialValues,
      mutators,
      keepDirtyOnReinitialize,
    } = this.props;
    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize={keepDirtyOnReinitialize}
        decorators={decorators}
        mutators={mutators}
        render={this.renderFormContextControl}
      />
    );
  }
}

export default FormControl;

type FormContextControlProps = {
  children: React.ReactNode;
} & FormContextType &
  FormRenderProps;

class FormContextControl extends React.Component<FormContextControlProps> {
  get formContext() {
    const {
      registerField,
      handleSubmit,
      form: { getState },
      form,
    } = this.props;

    return {
      registerField,
      handleSubmit,
      getState,
      reset: () => {
        form.reset();
      },
    };
  }

  render() {
    const { children } = this.props;
    return (
      <FormContext.Provider value={this.formContext}>
        {children}
      </FormContext.Provider>
    );
  }
}
