export const IMAGE_FILE_TYPE = ["JPG", "JPEG", "PNG"];

export const IMAGE_TYPE = {
  IOS: "MobileIOS",
  ANDROID: "MobileAndroid",
  ORIGINAL: "Original",
  WEB: "Web",
};

export const QUESTION_TYPE = {
  TEXT: "text",
  CHECKBOX: "checkbox",
  SELECT: "select",
  NUMBER: "number",
  RADIO: "radio",
  TEXTAREA: "textarea",
  BOOLEAN_CHECKBOX: "booleancheckbox",
  PHOTO: "photo",
  PASSWORD: "password",
};

export const WEEK: { [key: string]: string } = {
  "0": "monday",
  "1": "tuesday",
  "2": "wednesday",
  "3": "thursday",
  "4": "friday",
  "5": "saturday",
  "6": "sunday",
};

export const STRUCTURE_TYPE = {
  WEEK: "Week",
  STANDARD: "Standard",
};

export const PERMISSION_TYPE = {
  PREVIEW: "Preview",
  FULL_ACCESS: "FullAccess",
};

export const PUBLISH_STATUS = {
  DRAFT: "Draft",
  PREVIEW: "Preview",
  PUBLISHED: "Published",
  SCHEDULE_FOR_REMOVE: "ScheduleForRemove",
};

export const IMAGE_SIZE_TYPE = {
  BIG: "Image_1200x600",
  MEDIUM: "Image_900x500",
  SMALL: "Image_210x210",
};

export const NETWORK_TIMEOUT = 30000;
