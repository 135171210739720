import React from "react";

import I24EditFilled2 from "assets/icons/I24Edit";
import I24CrossFilled from "assets/icons/I24CrossFilled";

import styleConst from "constants/style";

import { Action, Actions, ActionTooltip } from "../styles";

type Props = {
  id: string;
  item: any;
  onEdit: (id: string, item: any) => void;
  onRemove: (id: string) => void;
};

const Ingredient: React.FC<Props> = ({ id, item, onEdit, onRemove }: Props) => {
  const onClick = (action: (id: string, item: any) => void) => () => {
    action(id, item);
  };

  return (
    <Actions>
      <Action onClick={onClick(onEdit)}>
        <I24EditFilled2 color={styleConst.colors.primary} />
        <ActionTooltip>{"Edit"}</ActionTooltip>
      </Action>
      <Action color={styleConst.colors.red} onClick={onClick(onRemove)}>
        <I24CrossFilled color={styleConst.colors.primary} />
        <ActionTooltip>{"Remove"}</ActionTooltip>
      </Action>
    </Actions>
  );
};

export default Ingredient;
