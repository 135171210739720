import styled from "styled-components";

import styleConst from "constants/style";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  padding-left: 101px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 48px;

  background: ${styleConst.colors.dark5};
  border-radius: 10px;
  padding: 20px;
`;

export const Title = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 48px;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.dark};

  border-left: 4px solid ${styleConst.colors.primary};

  padding-left: 14px;
  margin-bottom: 20px;
`;

export const DaySelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(249, 250, 250, 1);

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-bottom: 13px;
  padding-top: 15px;
`;

export const GreyBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  width: 100%;

  background: rgba(15, 61, 63, 0.05);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 38px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: calc(100vh - 100px - 345px);
`;
