import { types } from "mobx-state-tree";

import { PERMISSION_TYPE, PUBLISH_STATUS } from "constants/common";

import { ImageSize } from "models";

export const Resource = types
  .model("Resource", {
    id: types.maybe(types.number),
    originalQuickLinkId: types.maybe(types.number),
    title: types.maybe(types.string),
    description: types.maybe(types.string),
    imageUrl: types.maybe(types.string),
    url: types.maybe(types.string),
    permission: types.optional(
      types.enumeration(Object.values(PERMISSION_TYPE)),
      PERMISSION_TYPE.FULL_ACCESS,
    ),
    publishStatus: types.enumeration(Object.values(PUBLISH_STATUS)),
    images: types.array(ImageSize),
  })
  .views(self => ({
    get isPermissionPreview() {
      return self.permission === PERMISSION_TYPE.PREVIEW;
    },
    get isPublishStatusPreview() {
      return self.publishStatus === PUBLISH_STATUS.PREVIEW;
    },
  }));
