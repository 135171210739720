import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I36Check = ({ color = styleConst.colors.black }: Props) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 18C30 24.6274 24.6274 30 18 30C11.3726 30 6 24.6274 6 18C6 11.3726 11.3726 6 18 6C24.6274 6 30 11.3726 30 18ZM33 18C33 26.2843 26.2843 33 18 33C9.71573 33 3 26.2843 3 18C3 9.71573 9.71573 3 18 3C26.2843 3 33 9.71573 33 18ZM24.3107 13.9393C24.8964 14.5251 24.8964 15.4749 24.3107 16.0607L17.3107 23.0607C16.7249 23.6464 15.7751 23.6464 15.1893 23.0607L11.6893 19.5607C11.1036 18.9749 11.1036 18.0251 11.6893 17.4393C12.2751 16.8536 13.2249 16.8536 13.8107 17.4393L16.25 19.8787L22.1893 13.9393C22.7751 13.3536 23.7249 13.3536 24.3107 13.9393Z"
      fill={color}
      fillOpacity="0.25"
    />
  </svg>
);

export default I36Check;
