import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 84px;
`;


export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: calc(100vh - 100px - 345px);
`;
