import styled, { css } from "styled-components";

import styleConst from "constants/style";
import { MEDIA_QUERIES, PAGE_CONTENT_WIDTH } from "constants/layout";

interface IContainer {
  isOverlay?: boolean;
  disabled?: boolean;
}

export const Container = styled.div<IContainer>`
  width: 100%;
  min-height: ${({ isOverlay }) => (isOverlay ? "0px" : "100px")};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `};
`;

export const FixedHeader = styled.div`
  display: flex;
  flex-direction: row;

  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100px;

  background-color: ${styleConst.colors.white};
  box-shadow: 0px 7px 20px rgba(154, 154, 154, 0.1);
  padding: 0 11px;

  z-index: 1000;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  margin: 0 auto;

  ${MEDIA_QUERIES.DESKTOP} {
    width: ${PAGE_CONTENT_WIDTH.DESKTOP};
    min-width: ${PAGE_CONTENT_WIDTH.DESKTOP};
  }

  ${MEDIA_QUERIES.MEDIUM_DESKTOP} {
    width: ${PAGE_CONTENT_WIDTH.SMALL_DESKTOP};
    min-width: ${PAGE_CONTENT_WIDTH.SMALL_DESKTOP};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
`;
