import styled from "styled-components";

import styleConst from "constants/style";

export const Container = styled.div`
  padding: 20px;

  border-top-width: 2px;
  border-top-color: ${styleConst.colors.black4};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 10px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 23px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.white};
`;

export const Value = styled.div`
  display: flex;
  font-size: 14px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.white};
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
