import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24CalendarFilled = ({ color = styleConst.colors.white }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1C7.55229 1 8 1.44772 8 2L8 4H16L16 2C16 1.44771 16.4477 1 17 1C17.5523 1 18 1.44772 18 2V4.00414C18.4979 4.01123 18.943 4.0304 19.3278 4.08214C19.9833 4.17027 20.6117 4.36902 21.1213 4.87868C21.631 5.38834 21.8297 6.01669 21.9179 6.67221C22.0001 7.28387 22.0001 8.0477 22 8.93419V16.0658C22.0001 16.9523 22.0001 17.7161 21.9179 18.3278C21.8297 18.9833 21.631 19.6117 21.1213 20.1213C20.6117 20.631 19.9833 20.8297 19.3278 20.9179C18.7161 21.0001 17.9523 21.0001 17.0658 21H6.9342C6.0477 21.0001 5.28388 21.0001 4.67221 20.9179C4.0167 20.8297 3.38835 20.631 2.87868 20.1213C2.36902 19.6117 2.17028 18.9833 2.08215 18.3278C1.99991 17.7161 1.99995 16.9523 2 16.0658V8.93417C1.99995 8.04768 1.99991 7.28387 2.08215 6.67221C2.17028 6.01669 2.36902 5.38834 2.87868 4.87868C3.38835 4.36902 4.0167 4.17027 4.67221 4.08214C5.05703 4.0304 5.50208 4.01123 6 4.00414L6 2C6 1.44771 6.44772 1 7 1ZM4 9V16C4 16.9711 4.00213 17.5988 4.06431 18.0613C4.12263 18.495 4.21677 18.631 4.2929 18.7071C4.36902 18.7832 4.50497 18.8774 4.93871 18.9357C5.40121 18.9979 6.02893 19 7 19H17C17.9711 19 18.5988 18.9979 19.0613 18.9357C19.495 18.8774 19.631 18.7832 19.7071 18.7071C19.7832 18.631 19.8774 18.495 19.9357 18.0613C19.9979 17.5988 20 16.9711 20 16V9H4Z"
      fill={color}
    />
  </svg>
);

export default I24CalendarFilled;
