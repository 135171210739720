import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IActive {
  isActive: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Tab = styled.button<IActive>`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  width: 168px;
  height: 48px;

  border: 1px solid ${styleConst.colors.black10};

  background: ${styleConst.colors.white};

  &:last-child {
    border-radius: 0px 10px 10px 0px;
  }

  &:first-child {
    border-radius: 10px 0px 0px 10px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: linear-gradient(233.38deg, #c3e7e5 -9.83%, #7bcac7 82.48%),
        linear-gradient(133.72deg, #b3edea -7.19%, #84cfcb 102.48%);
      box-shadow: 5px 10px 20px rgba(106, 192, 189, 0.25);

      border: 0;
    `}
`;

export const Title = styled.span<IActive>`
  font-size: 16px;
  line-height: 24px;

  color: ${styleConst.colors.dark};

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${styleConst.colors.white};
    `}
`;
