import styled from "styled-components";

import Button from "components/Button";

import styleConst from "constants/style";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 53px;

  min-height: 250px;
  width: 700px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 280px;

  &:not(:last-child) {
    margin-right: 22px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;

  padding: 0 62px;

  &:last-child {
    margin-bottom: 60px;
  }
`;

export const SubmitButton = styled(Button)`
  display: flex;

  width: 215px;

  background: radial-gradient(
      83.3% 179.17% at 56.29% -31.25%,
      ${styleConst.colors.primaryMedium} 0%,
      ${styleConst.colors.primary} 100%
    ),
    ${styleConst.colors.primary};
  box-shadow: 0px 20px 40px -10px ${styleConst.colors.shadow};
  border-radius: 10px;
  border: none;
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 60px;
  width: 100%;
`;
