import * as React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I20Chevron = ({ color = styleConst.colors.black }: Props) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.24408 7.74403C5.56951 7.4186 6.09715 7.4186 6.42259 7.74403L10 11.3214L13.5774 7.74403C13.9028 7.4186 14.4305 7.4186 14.7559 7.74403C15.0814 8.06947 15.0814 8.59711 14.7559 8.92254L10 13.6785L5.24408 8.92254C4.91864 8.59711 4.91864 8.06947 5.24408 7.74403Z"
        fill={color}
        fillOpacity="0.25"
      />
    </svg>
  );
};

export default I20Chevron;
