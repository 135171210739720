import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Block = ({ color = styleConst.colors.white }: Props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      fill={color}
      fillRule="evenodd"
      d="M11 2a3 3 0 00-3 3v1H7a3 3 0 00-3 3v10a3 3 0 003 3h10a3 3 0 003-3V9a3 3 0 00-3-3h-1V5a3 3 0 00-3-3h-2zm3 4V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v1h4zM8 8H7a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1V9a1 1 0 00-1-1H8zm4 4a1 1 0 100 2 1 1 0 000-2zm-3 1a3 3 0 113.986 2.834c.01.054.014.11.014.166v1a1 1 0 01-2 0v-1c0-.056.005-.112.014-.166A3 3 0 019 13z"
      clipRule="evenodd"
    />
  </svg>
);

export default I24Block;
