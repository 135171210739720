import { flow, types, applySnapshot, Instance } from "mobx-state-tree";

import api from "services/api";

import AuthStore from "./AuthStore";
import ProfileStore from "./ProfileStore";

import {
  isEmail,
  isPassword,
  isEmpty,
  isAgreeTermsAndPrivacyChecked,
  isConfirmEqualPassword,
  composeValidators,
} from "utils/validation";

import {
  DEFAULT_GROUPS,
  PASSWORD_GROUPS,
  PASSOWRD_FIELD_NAMES,
  PASSWORD_ANSWERS,
} from "constants/onboarding";

import {
  Answer,
  FieldWithDependentFields,
  Fields,
  FieldError,
} from "models/Questionnaire";

const Onboarding = types
  .model("Onboarding", {
    groups: types.array(Fields),
    answers: types.array(Answer),
    errors: types.map(FieldError),
    photoUrl: types.maybe(types.string),
    isLoading: types.optional(types.boolean, false),
    isManaging: types.optional(types.boolean, false),
  })
  .volatile(() => ({
    photo: null,
  }))
  .views(self => ({
    getQuestionByField(
      fieldName: string,
    ): Instance<typeof FieldWithDependentFields> {
      return self.groups
        .map(({ fields }) => fields)
        .reduce((acc: any, current: any) => acc.concat(current), [])
        .find(({ name }: { name: string }) => name === fieldName);
    },

    getAnswersByField(fieldName: string) {
      return self.answers.find(answer => answer.fieldName === fieldName);
    },

    getErrorByField(fieldName: string) {
      return self.errors.get(fieldName)?.message;
    },

    get isError() {
      let isError = false;

      for (const error of self.errors.values()) {
        if (error.message) {
          isError = true;
          break;
        }
      }

      return isError;
    },
  }))
  .actions(self => ({
    addAnswer(nextAnswer: any) {
      self.answers.push(nextAnswer);
    },

    updateAnswer(nextAnswer: any) {
      const answers = self.answers.map(answer =>
        answer.fieldName !== nextAnswer.fieldName ? answer : nextAnswer,
      );

      self.errors.delete(nextAnswer.fieldName);

      applySnapshot(self, {
        ...self,
        answers,
        // @ts-ignore
        errors: self.errors,
      });
    },

    calcAnswersBeforePublish() {
      const answers = self.answers
        // eslint-disable-next-line array-callback-return
        .map(answer => {
          const { fieldName, parentFieldName } = answer;
          if (parentFieldName) {
            const parentField = self.getQuestionByField(parentFieldName);

            const filteredDependedFields =
              parentField?.filteredDependedFields.map(
                ({ field: { name } }) => name,
              );

            if (filteredDependedFields?.includes(fieldName)) {
              return answer;
            }
          } else {
            return answer;
          }
        })
        .filter(
          answer =>
            answer?.fieldName !== PASSOWRD_FIELD_NAMES.NEW_PASSWORD &&
            answer?.fieldName !== PASSOWRD_FIELD_NAMES.CONFIRM_PASSWORD,
        )
        .filter(Boolean)
        .map(answer => ({
          fieldName: answer?.fieldName,
          value: Array.isArray(answer?.value)
            ? answer?.value?.join(";")
            : answer?.value,
        }));

      return answers;
    },

    checkAnswers() {
      for (const answer of self.answers) {
        if (typeof answer.value !== "string") return;

        const question = self.getQuestionByField(answer.fieldName);

        if (answer.fieldName === "email") {
          self.errors.put({
            id: answer.fieldName,
            message: isEmail(answer.value),
          });
        }

        if (question.required && answer.isNotFilled) {
          self.errors.put({
            id: answer.fieldName,
            message: isEmpty(answer.value),
          });
        }

        if (answer.fieldName === "commitment_and_terms_i_accept") {
          self.errors.put({
            id: answer.fieldName,
            message: isAgreeTermsAndPrivacyChecked(Boolean(answer.value)),
          });
        }

        if (answer.fieldName === PASSOWRD_FIELD_NAMES.NEW_PASSWORD) {
          self.errors.put({
            id: answer.fieldName,
            message: composeValidators(isEmpty, isPassword)(answer.value),
          });
        }

        if (answer.fieldName === PASSOWRD_FIELD_NAMES.CONFIRM_PASSWORD) {
          const newPassword = self.getAnswersByField(
            PASSOWRD_FIELD_NAMES.NEW_PASSWORD,
          )?.value;

          self.errors.put({
            id: answer.fieldName,
            message: composeValidators(isEmpty, isConfirmEqualPassword)(
              answer.value,
              newPassword,
            ),
          });
        }
      }
    },
  }))
  .actions(self => ({
    fetch: flow(function* () {
      try {
        self.isLoading = true;

        const questions = yield api.get("/mobile/questionnaire/after-login", {
          emailAndPhoneCombine: true,
        });
        const prefilledAnswers = yield api.get(
          "/mobile/questionnaire/after-login/answers",
        );

        const answers = prefilledAnswers.items.map(
          ({ key, value }: { key: string; value: string }) => ({
            fieldName: key,
            value,
          }),
        );

        if (AuthStore.isNeedChangePassword) {
          answers.push(...PASSWORD_ANSWERS);
        }

        const additionalGroups = AuthStore.isNeedChangePassword
          ? DEFAULT_GROUPS.concat(PASSWORD_GROUPS)
          : DEFAULT_GROUPS;
        const groups = questions.groups.concat(additionalGroups);

        applySnapshot(self, { ...self, answers, groups, errors: {} });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    pushAnswer(nextAnswer: any) {
      const answer = self.getAnswersByField(nextAnswer.fieldName);

      if (answer) {
        self.updateAnswer(nextAnswer);
      } else {
        self.addAnswer(nextAnswer);
      }
    },

    setPhoto({ path, file }: any) {
      self.photoUrl = path;
      self.photo = file;
    },

    deletePhoto() {
      self.photoUrl = undefined;
      self.photo = null;
    },

    publishAnswers: flow(function* () {
      try {
        self.isManaging = true;

        self.checkAnswers();

        if (self.isError) throw new Error();

        const answers = self.calcAnswersBeforePublish();

        yield api.post("/mobile/questionnaire/after-login/answers", {
          answers,
        });

        if (self.photo) {
          yield ProfileStore.updatePhoto(self.photo);
        }

        if (AuthStore.isNeedChangePassword) {
          const newPassword = self.getAnswersByField(
            PASSOWRD_FIELD_NAMES.NEW_PASSWORD,
          )?.value;

          yield AuthStore.changePassword(newPassword);
        }

        AuthStore.setNeedOnboarding(false);
      } catch (error: any) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isManaging = false;
      }
    }),

    reset() {
      applySnapshot(self, {});
    },
  }));

const OnboardingStore = Onboarding.create({
  answers: [],
});

export default OnboardingStore;
