import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { PauseEvent } from "@u-wave/react-vimeo";
import { RouteComponentProps, withRouter } from "react-router-dom";

import StudioStore from "stores/StudioStore";
import WorkoutDetailsStore from "stores/WorkoutDetailsStore";

import I36CalendarScheduled from "assets/icons/I36CalendarScheduled";
import I36Calendar from "assets/icons/I36Calendar";
import I36StarFilled from "assets/icons/I36StarFilled";
import I36Star from "assets/icons/I36Star";
import I36Check from "assets/icons/I36Check";
import I36CheckFilled from "assets/icons/I36CheckFilled";

import GridCollection from "components/GridCollection";
import Video from "components/Video";
import { SnackbarTypeValues } from "components/Snackbar";
import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";
import Spinner from "components/Spinner";
import AddInSchedulePopup from "components/AddInSchedulePopup";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import CompleteWorkout from "./CompleteWorkout";

import { NoInternetConnectionError, NotFoundError } from "services/api";

import { redirectToRootRoute } from "utils/route";

import styleConst from "constants/style";

import {
  Button,
  Container,
  Controls,
  ControlWrapper,
  Equipments,
  EquipmentContainer,
  EquipmentImage,
  EquipmentImageContainer,
  EquipmentText,
  InfoContainer,
  Section,
  Text,
  TextWrapper,
  Title,
  TitleContainer,
  TitleAdornment,
  Workout,
  Loader,
  HorizontalSeparator,
  Image,
} from "./styles";

const SECONDS_TO_END = 30;

type Props = {
  workoutId: number;
  categoryId?: number;
  dayOfWeek?: number;
  scheduleDate?: string;
  workoutInWorkoutCategoryId?: number;
  onNavigateToMovement: (movement: any) => void;
  onChangeIsLoading?: (value: boolean) => void;
  onGoBack?: () => void;
} & SnackbarContextProps &
  GlobalPopupProps &
  RouteComponentProps;

@observer
class WorkoutDetails extends React.Component<Props> {
  @observable isLoading: boolean = false;
  @observable isScheduled: boolean = !!this.props.scheduleDate;
  @observable isNoInternetConnectionError: boolean = false;

  get url() {
    const {
      match: { url },
    } = this.props;

    return url;
  }

  @computed
  get details() {
    return WorkoutDetailsStore.details;
  }

  @computed get workoutTitle() {
    return this.details?.label || this.details?.title;
  }

  @computed
  get equipments() {
    return WorkoutDetailsStore.details?.equipments;
  }

  @computed
  get movements() {
    return WorkoutDetailsStore.details?.movements;
  }

  @computed get videoFinishTime() {
    return this.details?.duration
      ? Math.round(this.details.duration) - SECONDS_TO_END
      : null;
  }

  @computed get isDone() {
    return this.details?.isDone;
  }

  @computed get isDoneWithScheduleDate() {
    const { scheduleDate } = this.props;

    return scheduleDate && this.isDone;
  }

  async componentDidMount() {
    const {
      categoryId,
      workoutId,
      dayOfWeek,
      workoutInWorkoutCategoryId,
      scheduleDate,
      history,
    } = this.props;

    this.isLoading = true;

    try {
      if (categoryId) {
        await StudioStore.fetchDetails(categoryId);
      }

      await WorkoutDetailsStore.fetch({
        categoryId,
        workoutId,
        scheduleDate,
        dayOfWeek,
        workoutInWorkoutCategoryId,
      });
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }

      if (error instanceof NotFoundError) {
        redirectToRootRoute(history, this.url);
      }
    } finally {
      this.isLoading = false;
    }
  }

  componentWillUnmount() {
    WorkoutDetailsStore.reset();
  }

  favoriteWorkout = async () => {
    const { setMessage } = this.props;

    try {
      await WorkoutDetailsStore.addToFavorites();
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    }
  };

  unFavoriteWorkout = async () => {
    const { setMessage } = this.props;

    try {
      await WorkoutDetailsStore.removeFromFavorites();
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    }
  };

  scheduleWorkout = () => {
    const { openPopup, scheduleDate } = this.props;

    openPopup({
      key: "AddInSchedulePopup",
      render: ({ close }) => (
        <AddInSchedulePopup
          onClose={() => {
            this.isScheduled = !!scheduleDate;
            close();
          }}
        />
      ),
      exitButtonColor: styleConst.colors.black50,
    });
  };

  unScheduleWorkout = async () => {
    const { onGoBack, scheduleDate } = this.props;
    if (scheduleDate) {
      this.isScheduled = false;
      await WorkoutDetailsStore.removeSchedule(scheduleDate);
    }
    onGoBack?.();
  };

  onCompleteWorkout = () => {
    const { openPopup, onGoBack } = this.props;

    openPopup({
      key: "CompleteWorkout",
      render: ({ close }) => (
        <CompleteWorkout
          details={this.details}
          onClose={close}
          isLoading={WorkoutDetailsStore.isUpdating}
          onSubmit={this.changeWorkout(close)}
          onAccept={this.completeWorkout}
          goBack={onGoBack}
        />
      ),
      exitButtonColor: styleConst.colors.black50,
    });
  };

  undoComplete = async () => {
    const { scheduleDate } = this.props;

    try {
      await WorkoutDetailsStore.undoComplete(scheduleDate);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  changeWorkout = (close: () => void) => async (values: any) => {
    const { setMessage, scheduleDate, onGoBack } = this.props;

    try {
      if (scheduleDate) {
        values.scheduleDate = scheduleDate;
      }
      await WorkoutDetailsStore.change(values);

      close();
      onGoBack?.();
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    }
  };

  completeWorkout = async () => {
    const { setMessage, scheduleDate } = this.props;

    try {
      const payload = { scheduleDate };

      await WorkoutDetailsStore.complete(payload);
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
      throw new Error(error);
    }
  };

  onVideoEnd = () => {
    this.onCompleteWorkout();
  };

  onVideoPause = (event: PauseEvent) => {
    const videoFinishTime =
      this.videoFinishTime ?? Math.round(event.duration) - SECONDS_TO_END;

    if (Math.round(event.seconds) >= videoFinishTime) {
      this.onCompleteWorkout();
    }
  };

  renderSchedule = () => {
    const isSchedule = this.isScheduled;

    const icon = isSchedule ? <I36CalendarScheduled /> : <I36Calendar />;
    const text = isSchedule ? "Unschedule" : "Schedule";
    const backgroundColor = isSchedule
      ? styleConst.colors.primary20
      : styleConst.colors.black10;
    const onClick = isSchedule ? this.unScheduleWorkout : this.scheduleWorkout;

    return (
      <Button
        text={text}
        height={60}
        iconLeft={icon}
        backgroundColor={backgroundColor}
        onClick={onClick}
        inverted
      />
    );
  };

  renderDone = () => {
    const text = this.isDoneWithScheduleDate ? "Undo" : "Done";
    const DoneIcon = this.isDone ? <I36CheckFilled /> : <I36Check />;
    const backgroundColor = this.isDone
      ? styleConst.colors.primary20
      : styleConst.colors.black10;
    const onClick = this.isDoneWithScheduleDate
      ? this.undoComplete
      : this.onCompleteWorkout;

    return (
      <Button
        text={text}
        height={60}
        iconLeft={DoneIcon}
        onClick={onClick}
        backgroundColor={backgroundColor}
        inverted
      />
    );
  };

  renderFavorite = () => {
    const isFavorite = this.details?.isFavorite;
    const icon = isFavorite ? <I36StarFilled /> : <I36Star />;
    const text = isFavorite ? "Unfavorite" : "Favorite";
    const backgroundColor = isFavorite
      ? styleConst.colors.primary20
      : styleConst.colors.black10;

    const onClick = isFavorite ? this.unFavoriteWorkout : this.favoriteWorkout;

    return (
      <Button
        text={text}
        height={60}
        iconLeft={icon}
        backgroundColor={backgroundColor}
        onClick={onClick}
        inverted
      />
    );
  };

  render() {
    const { onNavigateToMovement } = this.props;

    if (this.isNoInternetConnectionError) {
      return <NoInternetConnectionPlaceholder />;
    }

    if (this.isLoading) {
      return (
        <Loader>
          <Spinner large />
        </Loader>
      );
    }

    return (
      <Container>
        <Workout>
          {WorkoutDetailsStore.details?.videoUrl ? (
            <Video
              imageUrl={WorkoutDetailsStore.details.imageUrl ?? ""}
              video={WorkoutDetailsStore.details?.videoUrl}
              duration={WorkoutDetailsStore.details.duration ?? 0}
              onEnd={this.onVideoEnd}
              onPause={this.onVideoPause}
            />
          ) : (
            <Image imageUrl={WorkoutDetailsStore.details?.imageUrl} />
          )}
          <Controls>
            <ControlWrapper>{this.renderSchedule()}</ControlWrapper>
            <ControlWrapper>{this.renderFavorite()}</ControlWrapper>
            <ControlWrapper>{this.renderDone()}</ControlWrapper>
          </Controls>
        </Workout>
        <HorizontalSeparator />
        <InfoContainer>
          {this.equipments?.length ? (
            <Section isEquipment>
              <TitleContainer>
                <TitleAdornment />
                <Title>Equipment</Title>
              </TitleContainer>
              <Equipments>
                {this.equipments.map(equipment => (
                  <EquipmentContainer key={equipment.id}>
                    <EquipmentImageContainer>
                      <EquipmentImage imageUrl={equipment.imageUrl} />
                    </EquipmentImageContainer>
                    <EquipmentText>{equipment.title}</EquipmentText>
                  </EquipmentContainer>
                ))}
              </Equipments>
            </Section>
          ) : null}
          <Section>
            <TitleContainer>
              <TitleAdornment />
              <Title>Instructions</Title>
            </TitleContainer>
            <TextWrapper>
              <Text>{this.details?.instruction}</Text>
            </TextWrapper>

            {this.movements?.length ? (
              <GridCollection
                items={WorkoutDetailsStore.details?.movements ?? []}
                onClick={onNavigateToMovement}
                isWorkout
              />
            ) : null}
          </Section>
        </InfoContainer>
      </Container>
    );
  }
}

export default withGlobalSnackbar(withGlobalPopup(withRouter(WorkoutDetails)));
