import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24StarFilled = ({ color = styleConst.colors.primary }: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      d="M13.582 2.89913C13.0579 1.70029 11.3574 1.70029 10.8333 2.89913L8.99762 7.09786C8.78536 7.58338 8.33268 7.92089 7.80677 7.98572L3.31826 8.5391C2.06767 8.69328 1.55297 10.2274 2.45751 11.1046L5.87927 14.4232C6.24156 14.7745 6.40287 15.2845 6.30854 15.7803L5.41111 20.4973C5.16992 21.7651 6.53595 22.7254 7.64724 22.0694L11.4451 19.8273C11.9155 19.5496 12.4998 19.5496 12.9702 19.8273L16.7681 22.0694C17.8794 22.7254 19.2454 21.7651 19.0042 20.4973L18.1068 15.7803C18.0124 15.2845 18.1737 14.7745 18.536 14.4232L21.9578 11.1046C22.8623 10.2273 22.3476 8.69328 21.097 8.5391L16.6085 7.98572C16.0826 7.92089 15.6299 7.58338 15.4177 7.09786L13.582 2.89913Z"
      fill={color}
    />
  </svg>
);

export default I24StarFilled;
