import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IContainer {
  height?: number;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 220px;
  width: 600px;

  border-radius: 10px;

  padding: 50px 60px;

  background-color: ${styleConst.colors.white};

  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
`;

export const Title = styled.h2`
  font-family: ${styleConst.fonts.montserrat};
  font-weight: 600;
  font-size: 26px;
  line-height: 48px;

  text-align: center;

  color: ${styleConst.colors.dark};
`;

export const MealContainer = styled.div`
  margin: 20px 0;
`;

export const SelectedDate = styled.p`
  margin-bottom: 6px;

  font-size: 14px;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const CalendarContainer = styled.div`
  margin: 20px 0;

  height: 550px;
`;

export const InputContainer = styled.div`
  margin: 20px 0;
`;
