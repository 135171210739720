import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";

import I24AddFilled from "assets/icons/I24AddFilled";

import ButtonInline from "components/ButtonInline";
import Input from "components/Input";
import Recipe from "components/Recipe";

import styleConst from "constants/style";

import {
  Container,
  Content,
  Controls,
  InputWrapper,
  RecipeWrapper,
} from "./styles";

type Props = {
  recipe: any;
  categoryId?: number;
  withoutImage?: boolean;
  withoutControls?: boolean;
  onRecipeClick?: () => void;
  onSubmit?: (values: any) => void;
};

@observer
class FormRecipeItem extends React.Component<Props> {
  @observable serving: string | undefined = "1";

  @computed get isDisabled() {
    const serving = this.serving ? Number.parseFloat(this.serving) : undefined;
    return !serving || serving === 0;
  }

  onChangeServing = (value?: string) => {
    this.serving = value;
  };

  addToFoodLog = () => {
    const { recipe, onSubmit } = this.props;

    if (this.serving) {
      onSubmit?.({ ...recipe, amount: this.serving });
    }
  };

  render() {
    const { onRecipeClick, recipe, withoutImage, withoutControls } = this.props;

    return (
      <Container>
        <Content>
          <RecipeWrapper>
            <Recipe
              recipe={recipe}
              onClick={onRecipeClick}
              withoutImage={withoutImage}
              style={{ cursor: "pointer" }}
            />
            {!withoutControls ? (
              <Controls>
                <InputWrapper>
                  <Input
                    value={this.serving}
                    onChangeText={this.onChangeServing}
                    inputStyles={{ height: 50 }}
                    decimalNumbersOnly
                    onlyPositiveDecimalNumbers
                  />
                </InputWrapper>
                <ButtonInline
                  iconLeft={<I24AddFilled color={styleConst.colors.primary} />}
                  fontSize={14}
                  text="Add to Food Log"
                  disabled={this.isDisabled}
                  onPress={this.addToFoodLog}
                />
              </Controls>
            ) : null}
          </RecipeWrapper>
        </Content>
      </Container>
    );
  }
}

export default FormRecipeItem;
