import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { SnapshotOrInstance } from "mobx-state-tree";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import I24AddFilled from "assets/icons/I24AddFilled";

import FoodLogStore, { FoodLogItem } from "stores/FoodLogStore";

import Recipe from "components/Recipe";
import Button from "components/Button";
import ButtonInline from "components/ButtonInline";
import Checkbox from "components/Checkbox";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";

import { Common as Actions } from "components/Actions";

import AddFoodControl from "pages/Nutrition/AddFoodControl";
import QuickAddForm from "pages/Nutrition/AddFoodControl/QuickAddForm";

import ROUTES from "navigation/routes";

import styleConst from "constants/style";

import {
  ButtonContainer,
  Container,
  Header,
  SuggestContainer,
  Title,
  Row,
  Controls,
  ColumnTitle,
  ColumnTitleWrapper,
  TitleWrapper,
  CheckboxWrapper,
} from "./styles";

type MealItem = SnapshotOrInstance<typeof FoodLogItem>;

interface IMealAction {
  foodMealTypeId: number;
  id: string;
}

type Props = {
  activeDay: string;
  meal: MealItem;
  onSuggestPress: (foodMealTypeId: number) => void;
  onChange: (params: IMealAction) => void;
  onRemove: (params: IMealAction) => void;
  onServings: (params: IMealAction) => void;
  onAddFoodPress: () => void;
  onChangeCheckedMealType: (value: boolean, foodMealTypeId: number) => void;
  onChangeCheckedMeal: (
    value: boolean,
    id: number,
    foodMealTypeId: number,
  ) => void;
  checkedMeals: number[];
} & RouteComponentProps &
  GlobalPopupProps;

@observer
class MealDetails extends React.Component<Props> {
  @observable disableRepeat: boolean = false;
  @observable isCheckedFoodMealType: boolean = false;

  @observable showControlsRowId?: number = undefined;

  rows: any = {};

  get needSuggest() {
    const {
      meal: {
        items = [],
        foodMealType: { name },
      },
    } = this.props;

    return (
      !items.length &&
      FoodLogStore.availableMealSuggestions.some(
        item => item.name === name && item.isAvailable,
      )
    );
  }

  onChange = (id: string) => {
    const {
      onChange,
      meal: { foodMealType },
    } = this.props;

    onChange({ foodMealTypeId: foodMealType.id, id });
    this.closeRow(id);
  };

  onRemove = (id: string) => {
    const {
      onRemove,
      meal: { foodMealType },
    } = this.props;

    onRemove({ foodMealTypeId: foodMealType.id, id });
    this.closeRow(id);
  };

  onServings = (id: string) => {
    const {
      onServings,
      meal: { foodMealType },
    } = this.props;

    onServings({ foodMealTypeId: foodMealType.id, id });
    this.closeRow(id);
  };

  closeRow = (id: string) => {
    const row = this.rows?.[id];
    row?.closeRow();
  };

  onRecipeDetailsNavigate = (recipe: any) => () => {
    const { history, openPopup } = this.props;

    const {
      isCustomRecipe,
      isFood,
      isQuickItem,
      isRecipe,
      internalId,
      serving,
      title,
      macronutrients,
    } = recipe;

    if (isRecipe) {
      history.push(
        generatePath(ROUTES.NUTRITION_FOOD_LOG_RECIPE_LIBRARY_RECIPE_DETAILS, {
          recipeId: internalId,
        }),
      );
    }

    if (isCustomRecipe) {
      history.push(
        generatePath(ROUTES.NUTRITION_MY_ITEMS_MY_RECIPES_DETAILS, {
          recipeId: internalId,
        }),
      );
    }

    if (isFood) {
      history.push({
        pathname: generatePath(ROUTES.NUTRITION_FOOD_LOG_FOOD_DETAILS, {
          recipeId: internalId,
        }),
        state: {
          selectedServing: serving,
          numberOfServingsValue: serving?.amount,
        },
      });
    }

    if (isQuickItem) {
      openPopup({
        key: "QuickAddItemDetails",
        exitButtonColor: styleConst.colors.black25,
        render: () => (
          <QuickAddForm initialValues={{ title, ...macronutrients }} />
        ),
      });
    }
  };

  onSuggestClick = async () => {
    const {
      onSuggestPress,
      meal: { foodMealType },
    } = this.props;
    await onSuggestPress(foodMealType.id);
  };

  toggleIsCheckedFoodMealType = (value: boolean) => {
    const {
      meal: { foodMealType },
      onChangeCheckedMealType,
    } = this.props;

    onChangeCheckedMealType?.(value, foodMealType.id);
  };

  toggleIsCheckedFoodMeal = (value: boolean, id: number) => {
    const {
      meal: { foodMealType },
      onChangeCheckedMeal,
    } = this.props;

    onChangeCheckedMeal?.(value, id, foodMealType.id);
  };

  showRowControls = (itemId: number) => () => {
    this.showControlsRowId = itemId;
  };

  hideRowControls = () => {
    this.showControlsRowId = undefined;
  };

  render() {
    const {
      meal: { items = [], foodMealType },
      checkedMeals,
      activeDay,
    } = this.props;

    return (
      <Container>
        <Header>
          <CheckboxWrapper>
            {items.length ? (
              <Checkbox
                checked={checkedMeals.length === items.length}
                toggleChecked={this.toggleIsCheckedFoodMealType}
              />
            ) : null}
          </CheckboxWrapper>
          <TitleWrapper>
            <Title>{foodMealType.name}</Title>
            <ColumnTitleWrapper>
              <ColumnTitle>Carbs</ColumnTitle>
              <ColumnTitle>Fiber</ColumnTitle>
              <ColumnTitle>Fat</ColumnTitle>
              <ColumnTitle>Protein</ColumnTitle>
            </ColumnTitleWrapper>
          </TitleWrapper>
          <Controls>
            {this.needSuggest ? (
              <SuggestContainer>
                <ButtonContainer>
                  <Button
                    text={"Suggest"}
                    onClick={this.onSuggestClick}
                    fontSize={14}
                    fontFamily={styleConst.fonts.roboto}
                    fontWeight={600}
                    height={26}
                  />
                </ButtonContainer>
              </SuggestContainer>
            ) : null}
            <AddFoodControl
              activeDay={activeDay}
              foodMealTypeId={foodMealType.id}
              element={
                <ButtonInline
                  text={"Add Food"}
                  fontSize={14}
                  fontWeight={600}
                  iconLeft={<I24AddFilled color={styleConst.colors.primary} />}
                />
              }
            />
          </Controls>
        </Header>

        {items.length
          ? items.map((item: any) => (
              <Row
                key={item.id}
                showControls={this.showControlsRowId === item.id}
              >
                <CheckboxWrapper>
                  <Checkbox
                    checked={checkedMeals.includes(item.id)}
                    toggleChecked={value =>
                      this.toggleIsCheckedFoodMeal(value, item.id)
                    }
                  />
                </CheckboxWrapper>
                <Recipe
                  withoutImage
                  recipe={item}
                  onClick={this.onRecipeDetailsNavigate(item)}
                />
                <Controls>
                  <Actions
                    id={item.id}
                    withoutServings={item.isQuickItem}
                    onChange={this.onChange}
                    onRemove={this.onRemove}
                    onServings={this.onServings}
                    activeDay={activeDay}
                    foodMealTypeId={foodMealType.id}
                    onOpenCallback={this.showRowControls(item.id)}
                    onCloseCallback={this.hideRowControls}
                  />
                </Controls>
              </Row>
            ))
          : null}
      </Container>
    );
  }
}

export default withGlobalPopup(withRouter(MealDetails));
