import { flow, types, applySnapshot, getSnapshot } from "mobx-state-tree";

import api from "services/api";

import { TYPE } from "constants/macroPlan";

import { getMacroPlanTitle } from "utils/macroPlane";

export const Item = types.model("Item", {
  foodDayType: types.model({
    name: types.string,
    id: types.number,
  }),
  unit: types.maybeNull(types.string),
  carbs: types.maybeNull(types.number),
  fat: types.maybeNull(types.number),
  protein: types.maybeNull(types.number),
});

export const CalculatorItem = types.model("CalculatorItem", {
  macroPlan: types.maybeNull(types.string),
  unit: types.maybeNull(types.string),
  carbs: types.maybeNull(types.number),
  fat: types.maybeNull(types.number),
  protein: types.maybeNull(types.number),
  lowCarbDayNetCarbIntake: types.maybeNull(types.number),
});

const MacroPlan = types
  .model("MacroPlanStore", {
    selectedMacroPlan: types.maybe(types.enumeration(Object.values(TYPE))),
    items: types.array(Item),
    calculationItems: types.array(CalculatorItem),
    isLoading: types.optional(types.boolean, false),
    isManaging: types.optional(types.boolean, false),
  })
  .views(self => ({
    get defaultMacroPlan() {
      return self.selectedMacroPlan !== TYPE.UNKNOWN
        ? self.selectedMacroPlan
        : TYPE.PLAN_45;
    },
    get macroPlanLabel() {
      return self.selectedMacroPlan !== TYPE.UNKNOWN
        ? getMacroPlanTitle(self.selectedMacroPlan)
        : "";
    },
    get mappedItems() {
      const { items } = getSnapshot(self);

      return items.map(({ foodDayType, ...rest }) => ({
        title: foodDayType.name,
        ...rest,
      }));
    },
    get mappedCalculationItems() {
      const { calculationItems } = getSnapshot(self);
      return calculationItems.map(({ macroPlan, ...rest }) => ({
        title: macroPlan,
        label: getMacroPlanTitle(macroPlan),
        ...rest,
      }));
    },
  }))
  .views(self => ({
    getCalculationItemByName(name: string) {
      return self.mappedCalculationItems.find(({ title }) => title === name);
    },
  }))
  .actions(self => ({
    fetch: flow(function* () {
      try {
        self.isLoading = true;

        const { selectedMacroPlan, items } = yield api.get(
          "/mobile/macro-plan",
        );

        applySnapshot(self, { ...self, selectedMacroPlan, items });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    fetchCycleCalculations: flow(function* () {
      try {
        self.isLoading = true;

        const { selectedMacroPlan, items: calculationItems } = yield api.get(
          "/mobile/macro-plan/edit",
        );

        applySnapshot(self, { ...self, selectedMacroPlan, calculationItems });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    updateCycleCalculations: flow(function* (payload: any) {
      try {
        self.isManaging = true;

        yield api.put("/mobile/macro-plan/edit", payload);
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        self.isManaging = false;
      }
    }),

    reset() {
      applySnapshot(self, {});
    },
  }));

const MacroPlanStore = MacroPlan.create();

export default MacroPlanStore;
