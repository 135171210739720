import { types, getParent } from "mobx-state-tree";

import { QUESTION_TYPE } from "constants/common";
import { OPERATOR_TYPE } from "constants/onboarding";

export const Option = types.model("Option", {
  label: types.string,
  value: types.string,
  displayOrder: types.maybe(types.number),
});

export const Photo = types.model("Photo", {
  path: types.string,
  mime: types.string,
});

export const Answer = types
  .model("Answer", {
    fieldName: types.string,
    value: types.union(types.string, types.array(types.string)),
    parentFieldName: types.maybe(types.string),
  })
  .views(self => ({
    get isNotFilled() {
      return Array.isArray(self.value) ? !self.value.length : !self.value;
    },
  }));

export const Field = types.model("Field", {
  name: types.string,
  label: types.string,
  description: types.string,
  type: types.enumeration(Object.values(QUESTION_TYPE)),
  required: types.boolean,
  dimension: types.maybe(types.string),
  options: types.array(Option),
});

export const FieldError = types.model("FieldError", {
  id: types.identifier,
  message: types.string,
});

export const Filter = types.model("Filter", {
  operator: types.enumeration(Object.values(OPERATOR_TYPE)),
  strValues: types.array(types.string),
});

export const DependentField = types.model("DependentField", {
  field: Field,
  filters: types.array(Filter),
});

export const DependentFields = types.model("DependentFields", {
  dependentFields: types.array(DependentField),
});

export const FieldWithDependentFields = types
  .compose(Field, DependentFields)
  .views(self => ({
    get filteredDependedFields() {
      if (self.dependentFields.length) {
        const store: any = getParent(self, 4);
        const value = store.getAnswersByField(self.name)?.value;

        const checkFilter = ({ operator, strValues }: any) =>
          (operator === OPERATOR_TYPE.IS_NOT_EMPTY && value) ||
          (operator === OPERATOR_TYPE.SET_ANY && strValues.includes(value));

        return self.dependentFields.filter((dependedField: any) =>
          dependedField.filters.some(checkFilter),
        );
      } else {
        return [];
      }
    },
  }));

export const Fields = types.model("Fields", {
  title: types.maybe(types.string),
  subtitle: types.maybe(types.string),
  fields: types.array(FieldWithDependentFields),
});
