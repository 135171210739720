import React from "react";

const I24Dropdown = () => (
  <svg width={24} height={25} viewBox="0 0 24 25" fill="none">
    <path
      d="M19.7782 9.83433L12 17.6125L4.22182 9.83433H19.7782Z"
      fill="#84CFCB"
    />
  </svg>
);

export default I24Dropdown;
