import styled, { css } from "styled-components";

import backgroundImageSmall from "assets/images/svg/dots_background_small.svg";

import styleConst from "constants/style";

interface IGroupContainer {
  isLast?: boolean;
}

interface IBackground {
  leftDots?: boolean;
}

export const Title = styled.h1`
  margin: 40px 0px 7px 0px;

  text-align: center;

  font-family: ${styleConst.fonts.montserrat};
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 48px;

  color: ${styleConst.colors.text};
`;

export const GroupContainer = styled.div<IGroupContainer>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 580px;

  margin: 0 auto;

  justify-content: space-between;

  padding: 50px 0px 30px 0px;

  background-color: ${styleConst.colors.white};

  ${({ isLast }) =>
    !isLast &&
    css`
      border-bottom: 1px solid ${styleConst.colors.black10};
    `}
`;

export const ButtonContainer = styled.div`
  display: flex;

  justify-content: center;

  margin-bottom: 100px;
`

export const Background = styled.div<IBackground>`
  z-index: -1;

  position: absolute;
  top: 497px;

  height: 388px;
  width: 100%;

  overflow: hidden;
  background-repeat: no-repeat;
  background-position: -242px;

  background-image: url(${backgroundImageSmall});

  ${({ leftDots }) =>
    leftDots &&
    css`
      top: 959px;

      transform: rotate(180deg);
    `}
`;
