import styled from "styled-components";

import styleConst from "constants/style";

export const CheckboxLeadContainer = styled.div`
  display: flex;
`;

export const CheckboxLabel = styled.p`
  flex-shrink: 1;

  margin-left: 13px;
  margin-right: auto;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${styleConst.colors.dark};
`;

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 16px;
  padding: 12px 16px;

  background-color: ${styleConst.colors.dark5};
  border-radius: 10px;
`;

export const Options = styled.div`
  margin-left: 16px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 12px 0;

  svg {
    flex-shrink: 0;
  }
`;

export const Title = styled.p`
  margin-left: 13px;

  font-family: ${styleConst.fonts.montserrat};
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${styleConst.colors.dark};
`;
