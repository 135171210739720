import React from "react";

import { capitalize } from "utils/common";
import { getNutrientValue, NutrientsList } from "utils/nutrients";

import { Container, Control, Info, Item, ItemText, ItemTitle } from "./styles";

type Props = {
  nutrients: NutrientsList;
  control?: React.ReactNode;
  className?: string;
};

const FoodNutrientsRow = ({ nutrients, control, className }: Props) => (
  <Container className={className}>
    <Info>
      {nutrients.map(({ label, value }) => (
        <Item key={`${label}-${value}`}>
          <ItemTitle>{capitalize(label)}</ItemTitle>
          <ItemText>{getNutrientValue(label, value)}</ItemText>
        </Item>
      ))}
    </Info>
    {control && <Control>{control}</Control>}
  </Container>
);

export default FoodNutrientsRow;
