import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { debounce } from "debounce";
import InfiniteScroll from "react-infinite-scroll-component";

import I24Add from "assets/icons/I24Add";
import I24ArrowBack from "assets/icons/I24ArrowBack";
import I24Barcode from "assets/icons/I24Barcode";
import { Placeholders } from "assets/images/placeholders";

import FoodFavoritesStore from "stores/FoodFavoritesStore";
import FoodManualStore from "stores/FoodManualStore";
import AddRecipeStore from "stores/AddRecipeStore";

import FormTitle from "components/Form/FormTitle";
import Placeholder from "components/Placeholder";
import Search from "components/Search";
import Spinner from "components/Spinner";
import { SnackbarTypeValues } from "components/Snackbar";
import ButtonInline from "components/ButtonInline/ButtonInline";
import SearchItem from "./SearchItem";

import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";

import styleConst from "constants/style";

import {
  BackButtonWrapper,
  Row,
  ScrollLoader,
  SearchWrapper,
  ScrollWrapper,
  ScrollLoaderWrapper,
} from "../styles";

type Props = {
  onBack: () => void;
  onSelectIngredient: (id: number) => void;
  barcodeMode?: boolean;
} & SnackbarContextProps;

@observer
class SearchIngredients extends React.Component<Props> {
  @observable page: number = 1;

  @observable isSearched: boolean = false;

  get barcodeMode() {
    const { barcodeMode } = this.props;

    return barcodeMode;
  }

  get icon() {
    return !this.barcodeMode ? <I24Add /> : <I24Barcode />;
  }

  @computed get store() {
    return !this.barcodeMode ? AddRecipeStore : FoodManualStore;
  }

  @computed get isLoading() {
    return this.store.isLoading;
  }

  @computed get isUpdating() {
    return FoodFavoritesStore.isUpdating;
  }

  @computed get needMore() {
    return AddRecipeStore.needMore;
  }

  @computed get searchString() {
    return this.store.searchString;
  }

  @computed get searchResult() {
    return AddRecipeStore.ingredients;
  }

  @computed get searchResultIsEmpty() {
    return AddRecipeStore.ingredients.length === 0;
  }

  componentDidMount() {
    if (!this.searchResultIsEmpty) {
      this.isSearched = true;
    }
  }

  goBack = () => {
    const { onBack } = this.props;
    onBack();
  };

  onSearchChange = (value: string) => {
    this.store.setSearchString(value);

    if (this.barcodeMode) {
      return;
    }

    if (this.searchString.length > 2) {
      this.debouncedSearch();
    } else {
      this.isSearched = false;
    }
  };

  onSearchClear = () => {
    this.isSearched = false;
    this.store.resetResult();
  };

  onSearchApply = async () => {
    const { setMessage } = this.props;

    try {
      if (this.barcodeMode) {
        if (this.searchString) {
          await FoodManualStore.barcodeSearch();
          this.selectFood(FoodManualStore.barcodeItemDetails)();
        }

        return;
      } else {
        if (this.searchString.length < 3) {
          setMessage(
            "Please specify minimum 3 character",
            SnackbarTypeValues.INFO,
          );
          return;
        }

        this.page = 1;
        await AddRecipeStore.searchIngredients();
      }
    } catch (error: any) {
    } finally {
      this.isSearched = true;
    }
  };

  debouncedSearch = debounce(this.onSearchApply, 1500);

  onLoadMore = () => {
    this.page++;
    AddRecipeStore.searchIngredients(this.page);
  };

  selectFood = (item: any) => () => {
    const { onSelectIngredient } = this.props;
    onSelectIngredient(item.id);
  };

  onFavoriteClick = (item: any) => () => {
    const { isFavorite } = item;

    if (isFavorite) {
      this.removeFromFavorites(item);
    } else {
      this.addToFavorites(item);
    }
  };

  addToFavorites = async (item: any) => {
    const { setMessage } = this.props;

    try {
      const { favoriteId } = await FoodFavoritesStore.addToFavorites(item);

      AddRecipeStore.updateItem(item.id, {
        isFavorite: true,
        favoriteId,
      });
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    }
  };

  removeFromFavorites = async (item: any) => {
    const { setMessage } = this.props;

    try {
      await FoodFavoritesStore.removeFromFavorites(item);

      AddRecipeStore.updateItem(item.id, {
        isFavorite: false,
      });
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    }
  };

  renderPlaceholder = () =>
    this.isSearched ? (
      <Row>
        <Placeholder
          imageUrl={Placeholders.NotFound}
          text={"No matches found. Please\ntry another search"}
        />
      </Row>
    ) : (
      <Row>
        <Placeholder
          imageUrl={Placeholders.Search}
          text="Your search results will be displayed here"
        />
      </Row>
    );

  render() {
    return (
      <>
        <BackButtonWrapper>
          <ButtonInline
            text={"Back"}
            iconLeft={<I24ArrowBack />}
            onPress={this.goBack}
          />
        </BackButtonWrapper>
        <FormTitle title={"Search for Ingredients"} icon={this.icon} />
        <Row>
          <SearchWrapper>
            <Search
              initValue={this.searchString}
              onChange={this.onSearchChange}
              onClear={this.onSearchClear}
              onApply={this.onSearchApply}
              onlyNumber={this.barcodeMode}
            />
          </SearchWrapper>
        </Row>

        {!this.barcodeMode && this.isSearched && this.searchResult.length ? (
          <ScrollWrapper id="scrollableDiv">
            <InfiniteScroll
              scrollableTarget={"scrollableDiv"}
              dataLength={this.searchResult.length}
              hasMore={Boolean(this.needMore)}
              next={this.onLoadMore}
              loader={
                <ScrollLoaderWrapper>
                  <Spinner color={styleConst.colors.primary} large />
                </ScrollLoaderWrapper>
              }
            >
              {this.searchResult.map(item => (
                <SearchItem
                  key={`${item.id}-${item.name}`}
                  title={item.name}
                  subtitle={item.brand}
                  isFavorite={item.isFavorite}
                  isLoading={FoodFavoritesStore.isUpdating}
                  onItemClick={this.selectFood(item)}
                  onControlClick={this.onFavoriteClick(item)}
                />
              ))}
            </InfiniteScroll>
          </ScrollWrapper>
        ) : !this.isLoading ? (
          this.renderPlaceholder()
        ) : (
          <Row>
            <ScrollLoader>
              <Spinner color={styleConst.colors.primary} large />
            </ScrollLoader>
          </Row>
        )}
      </>
    );
  }
}

export default withGlobalSnackbar(SearchIngredients);
