import React from "react";

import I120Calculator from "assets/icons/I120Calculator";
import I24Edit from "assets/icons/I24Edit";
import I24Calculator from "assets/icons/I24Calculator";

import ButtonInline from "components/ButtonInline";
import Table from "components/MacroGoals/Table";
import EditMacroPlanPopup from "components/EditMacroPlanPopup";
import MacroCalculatorPopup from "components/MacroCalculatorPopup";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";

import styleConst from "constants/style";

import {
  Buttons,
  Container,
  Header,
  Placeholder,
  PlaceholderIcon,
  PlaceholderTitle,
  Title,
  TitleContainer,
  ButtonInlineWrapper,
} from "./styles";

type Props = {
  plan: {
    name: string;
    items: any[];
  };
  onClosePopup: () => void;
} & GlobalPopupProps;

const TablePlaceholder = ({ onPress }: { onPress: () => void }) => (
  <Placeholder>
    <PlaceholderIcon>
      <ButtonInlineWrapper>
        <ButtonInline
          onPress={onPress}
          fontSize={14}
          fontWeight={400}
          color={styleConst.colors.black50}
          iconLeft={<I120Calculator />}
        />
      </ButtonInlineWrapper>
    </PlaceholderIcon>
    <PlaceholderTitle primary>{"Tap the Calculator button"}</PlaceholderTitle>
    <PlaceholderTitle>{"to calculate your Macro Goals"}</PlaceholderTitle>
  </Placeholder>
);

class View extends React.Component<Props> {
  onMacroCalculator = () => {
    const { openPopup, onClosePopup } = this.props;

    openPopup({
      key: "MacroCalculatorPopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <MacroCalculatorPopup
          onClose={() => {
            close();
            onClosePopup();
            this.onEditMacroPlan();
          }}
        />
      ),
    });
  };

  onEditMacroPlan = () => {
    const { openPopup, onClosePopup } = this.props;

    openPopup({
      key: "EditMacroPlanPopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <EditMacroPlanPopup
          onClose={() => {
            onClosePopup();
            close();
          }}
        />
      ),
    });
  };

  render() {
    const {
      plan: { name, items },
    } = this.props;

    return (
      <>
        <Container>
          <Header>
            <TitleContainer>
              <Title>{"My Macro Plan"}</Title>
              {name ? <Title>{name}</Title> : null}
            </TitleContainer>
            <Buttons>
              <ButtonInlineWrapper>
                <ButtonInline
                  onPress={this.onEditMacroPlan}
                  text={"Edit"}
                  fontSize={14}
                  fontWeight={400}
                  color={styleConst.colors.black50}
                  iconLeft={<I24Edit color={styleConst.colors.black50} />}
                />
              </ButtonInlineWrapper>
              <ButtonInlineWrapper>
                <ButtonInline
                  onPress={this.onMacroCalculator}
                  text={"Calculator"}
                  fontSize={14}
                  fontWeight={400}
                  color={styleConst.colors.black50}
                  iconLeft={<I24Calculator color={styleConst.colors.black50} />}
                />
              </ButtonInlineWrapper>
            </Buttons>
          </Header>
          {items.length ? <Table items={items} /> : null}
        </Container>
        {!items.length ? (
          <TablePlaceholder onPress={this.onMacroCalculator} />
        ) : null}
      </>
    );
  }
}

export default withGlobalPopup(View);
