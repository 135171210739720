import styled, { css } from "styled-components";

import InlineListItemDefault from "components/InlineListItem";
import FoodNutrientsRowDefault from "components/FoodNutrientsRow";

import styleConst from "constants/style";

interface IRow {
  formRow?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 53px 0;

  min-height: 250px;
  max-width: 100vw;
  width: 920px;
`;

export const IngredientControlWrapper = styled.div`
  display: flex;
`;

export const RecipeFormWrapper = styled.div`
  width: 920px;
`;

export const IngredientDetailsFormWrapper = styled.div`
  width: 920px;

  @media screen and (min-width: 920px) {
    overflow-x: hidden;
  }
`;

export const InputWrapper = styled.div`
  width: 240px;

  padding-top: 15px;

  margin: 0 auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 48px;

  background: ${styleConst.colors.dark5};

  padding: 0 65px 0 38px;
  margin-top: 40px;
`;

export const Title = styled.div`
  width: 56%;

  font-size: 18px;
  font-weight: 700;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.dark};
`;

export const ColumnTitle = styled.div`
  text-align: center;

  width: 80px;
  padding: 0 10px;

  font-weight: 400;
  font-size: 14px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.black50};
`;

export const ColumnTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  width: 44%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  width: 75%;
`;

export const ButtonContainer = styled.div`
  width: 216px;

  padding-top: 54px;

  margin: 0 auto;
`;

export const ScrollLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 210px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
`;

export const SearchWrapper = styled.div`
  width: 100%;

  padding: 0 62px;
`;

export const ScrollWrapper = styled.div`
  height: calc(100vh - 400px);
  min-height: calc(100vh - 400px);

  overflow-y: auto;
`;

export const ScrollLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding: 10px;
`;

export const BackButtonWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
`;

export const RowNutrients = styled.div<IRow>`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;

  ${({ formRow }) =>
    formRow &&
    css`
      padding: 0 171px;
    `}
`;

export const Nutrients = styled.div`
  display: flex;
  flex-direction: column;
`;
export const NutrientsTitle = styled.p`
  margin-top: 30px;

  font-family: ${styleConst.fonts.montserrat};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: ${styleConst.colors.dark};
`;

export const NutrientsInfo = styled.div`
  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;
`;

export const NutrientWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  width: 243px;

  border-bottom: 1px solid ${styleConst.colors.black10};
`;

export const InlineListItem = styled(InlineListItemDefault)`
  width: 100%;
`;

export const FoodNutrientsRow = styled(FoodNutrientsRowDefault)`
  justify-content: center;

  padding: 0 171px;
`;
