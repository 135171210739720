import styled from "styled-components";

import styleConst from "constants/style";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  padding: 0 62px;

  background-color: rgba(15, 61, 63, 0.05);
`;

export const Control = styled.div`
  display: flex;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: 40px 20px 40px 0;

  min-width: 77px;
`;

export const ItemTitle = styled.p`
  margin-bottom: 2px;

  font-family: ${styleConst.fonts.roboto};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: ${styleConst.colors.dark};
`;

export const ItemText = styled.p`
  font-family: ${styleConst.fonts.roboto};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: ${styleConst.colors.dark};
`;
