import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24CheckboxMinus = ({ color = styleConst.colors.white }: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24">
    <path
      d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3Z"
      fill="#84CFCB"
    />
    <rect x="7" y="10.957" width="10" height="2" fill={color} />
  </svg>
);

export default I24CheckboxMinus;
