import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";

import I24Reset from "assets/icons/I24Reset";

import SearchStore from "stores/SearchStore";

import ButtonInline from "components/ButtonInline";
import CheckboxGroup from "components/CheckboxGroup";
import FormTitle from "components/Form/FormTitle";

import styleConst from "constants/style";

import {
  Buttons,
  ButtonWrapper,
  Container,
  Column,
  SubmitButton,
} from "./styles";

type Props = {
  onApply: () => void;
};

type Field = "workouts" | "recipes" | "resources";

interface Values {
  workouts: any[];
  recipes: any[];
  resources: any[];
}

@observer
class FiltersForm extends React.Component<Props> {
  @observable values: Values = {
    workouts: [],
    recipes: [],
    resources: [],
  };

  @computed get workouts() {
    return SearchStore.workoutCategoryList.map(({ id, title }) => ({
      label: title,
      value: id.toString(),
    }));
  }

  @computed get recipes() {
    return SearchStore.foodCategoryList.map(({ id, title }) => ({
      label: title,
      value: id.toString(),
    }));
  }

  @computed get resources() {
    return SearchStore.resourceCategoryList.map(({ id, title }) => ({
      label: title,
      value: id.toString(),
    }));
  }

  @computed get fields() {
    return [
      {
        title: "Workouts",
        field: "workouts",
        options: this.workouts,
        value: this.values.workouts,
      },
      {
        title: "Recipes",
        field: "recipes",
        options: this.recipes,
        value: this.values.recipes,
      },
      {
        title: "Resources",
        field: "resources",
        options: this.resources,
        value: this.values.resources,
      },
    ];
  }

  componentDidMount() {
    this.values = SearchStore.currentFilters;
  }

  onSelectOption = (field: Field) => (items: any[]) => {
    this.values[field] = items;
  };

  onResetFilters = () => {
    const { onApply } = this.props;

    this.values = {
      workouts: [],
      recipes: [],
      resources: [],
    };

    SearchStore.setFilters(this.values);
    onApply();
  };

  onShowResults = () => {
    const { onApply } = this.props;

    SearchStore.setFilters(this.values);

    onApply();
  };

  render() {
    return (
      <>
        <FormTitle title={"Filter"} />
        <Container>
          {this.fields
            .filter(({ options }) => options.length)
            .map((item: any) => (
              <Column key={item.field}>
                <CheckboxGroup
                  title={item.title}
                  value={item.value}
                  onSelectOption={this.onSelectOption(item.field)}
                  options={item.options}
                />
              </Column>
            ))}
        </Container>
        <Buttons>
          <ButtonWrapper>
            <ButtonInline
              text="Reset All"
              onPress={this.onResetFilters}
              fontSize={16}
              fontWeight={500}
              iconLeft={<I24Reset color={styleConst.colors.primary} />}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <SubmitButton
              height={50}
              text="Show Results"
              onClick={this.onShowResults}
            />
          </ButtonWrapper>
        </Buttons>
      </>
    );
  }
}

export default FiltersForm;
