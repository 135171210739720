import React from "react";
import { observer } from "mobx-react";

import I24Star from "assets/icons/I24Star";
import I24StarFilled from "assets/icons/I24StarFilled";

import IconButton from "components/IconButton";

import styleConst from "constants/style";

import { Container, Controls, Info, Title, SubTitle } from "./styles";

type Props = {
  title?: string;
  subtitle?: string;
  isFavorite?: boolean;
  isLoading?: boolean;
  onItemClick: () => void;
  onControlClick: () => void;
};

const SearchItem = ({
  title,
  subtitle,
  isFavorite,
  isLoading,
  onItemClick,
  onControlClick,
}: Props) => (
  <Container onClick={onItemClick}>
    <Info>
      {title && <Title>{title}</Title>}
      {subtitle && <SubTitle>{subtitle}</SubTitle>}
    </Info>
    <Controls>
      <IconButton
        icon={
          !isFavorite ? (
            <I24Star color={styleConst.colors.primary} />
          ) : (
            <I24StarFilled color={styleConst.colors.primary} />
          )
        }
        backgroundColor="transparent"
        onClick={onControlClick}
        isLoading={isLoading}
        shouldStopPropagation
      ></IconButton>
    </Controls>
  </Container>
);

export default observer(SearchItem);
