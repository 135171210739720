import styled from "styled-components";
import styleConst from "constants/style";

interface IContainerWithNumberOfButtons {
  numberOfButtons: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DaySelectContainer = styled.div``;

export const GreenBarsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-bottom: 27px;
`;

export const GreenBarMacroGoals = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  width: 100%;

  background: linear-gradient(233.38deg, #c3e7e5 -9.83%, #7bcac7 82.48%),
    #f1f3f3;
  border-radius: 10px;
  padding: 0 38px;
  margin-right: 10px;
`;

export const GreenBarWaterWrapper = styled.div`
  width: 330px;

  background: linear-gradient(242.01deg, #c3e7e5 -9.39%, #7bcac7 129.64%),
    #f1f3f3;
  border-radius: 10px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.white};
`;

export const Subtitle = styled(Title)`
  font-size: 14px;
  font-family: ${styleConst.fonts.roboto};
`;

export const ButtonInlineWrapper = styled.div`
  padding-left: 21.3px;
`;

export const SuggestMealsButtonContainer = styled.div`
  width: 178px;
  height: 26px;

  font-size: 14px;
  font-family: ${styleConst.fonts.roboto};
`;

export const MealOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 15px;
  padding-left: 20px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: calc(100vh - 100px - 345px);
`;

export const GuideTypeContainer = styled.div<IContainerWithNumberOfButtons>`
  height: ${({ numberOfButtons }) => numberOfButtons * 75}px;
  max-height: 350px;
  margin: 20px 0 0;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  width: 72%;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;

  width: 25%;
`;

export const Label = styled.span`
  align-items: center;
  justify-content: center;
  margin-left: 11px;

  font-size: 14px;
  line-height: 24px;

  color: ${styleConst.colors.black50};
`;
