import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import Tippy from "@tippyjs/react";

import I24Add from "assets/icons/I24Add";
import I24Barcode from "assets/icons/I24Barcode";

import OutsideClickHandler from "components/OutsideClickHandler";
import Menu from "components/Menu";

import { Wrapper } from "./styles";

type Props = {
  element: React.ReactElement<any>;
  onAddManually: () => void;
  onBarcode: () => void;
};

@observer
class AddIngredientControl extends React.Component<Props> {
  @observable isMenuOpened: boolean = false;

  get menuItems() {
    const { onAddManually, onBarcode } = this.props;

    return [
      {
        text: "Add Manually",
        icon: <I24Add />,
        onClick: onAddManually,
      },
      {
        text: "Barcode",
        icon: <I24Barcode />,
        onClick: onBarcode,
      },
    ];
  }

  openMenu = () => {
    this.isMenuOpened = true;
  };

  hideMenu = () => {
    this.isMenuOpened = false;
  };

  render() {
    const { element } = this.props;

    return (
      <OutsideClickHandler onClick={this.hideMenu}>
        <Wrapper>
          <Tippy
            appendTo={document.body}
            animation="fade"
            arrow={false}
            maxWidth={"none"}
            placement="bottom-start"
            theme={"menu"}
            visible={this.isMenuOpened}
            content={<Menu items={this.menuItems} />}
            interactive
          >
            <Wrapper onClick={this.openMenu}>{element}</Wrapper>
          </Tippy>
        </Wrapper>
      </OutsideClickHandler>
    );
  }
}

export default AddIngredientControl;
