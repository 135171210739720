import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";

import I24CalendarDatePicker from "assets/icons/I24CalendarDatePicker";

import { roundTime } from "utils/date";

type Props = {
  label?: string;
  required?: boolean;
  dateWithTimeMode?: boolean;
  minutesStep?: number;
  value?: Date;
  minDate?: Date;
  minDateMessage?: string;
  maxDate?: Date;
  maxDateMessage?: string;
  invalidDateMessage?: string;
  placeholder?: string;
  onChange: (date: Date | null) => void;
};

class DatePicker extends React.Component<Props> {
  onChangeDateTime = (date: Date | null) => {
    const { minutesStep, onChange } = this.props;

    if (date) {
      const roundedDate = minutesStep ? roundTime(date, minutesStep) : date;

      onChange(roundedDate);
    }
  };

  render() {
    const { dateWithTimeMode, value, onChange, ...props } = this.props;

    return (
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={{
          ...enLocale,
          options: { ...enLocale.options, weekStartsOn: "1" },
        }}
      >
        {dateWithTimeMode ? (
          <KeyboardDateTimePicker
            {...props}
            value={value ? new Date(value) : null}
            onAccept={this.onChangeDateTime}
            format="MM/dd/yyyy hh:mm a"
            inputVariant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={this.onChangeDateTime}
            fullWidth
          />
        ) : (
          <KeyboardDatePicker
            {...props}
            value={value ? new Date(value) : null}
            onAccept={onChange}
            format="MM/dd/yyyy"
            inputVariant="outlined"
            InputLabelProps={{ shrink: true }}
            keyboardIcon={<I24CalendarDatePicker />}
            onChange={onChange}
            fullWidth
          />
        )}
      </MuiPickersUtilsProvider>
    );
  }
}

export default DatePicker;
