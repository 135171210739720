import styled from "styled-components";

import styleConst from "constants/style";

interface IStyledCheckbox {
  checked: boolean;
}

export const Container = styled.div`
  display: flex;

  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const Label = styled.span`
  align-items: center;
  justify-content: center;
  margin-left: 11px;

  font-size: 14px;
  line-height: 24px;

  color: ${styleConst.colors.black50};
`;

export const Wrapper = styled.label`
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;

  width: 18px;
  height: 18px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;

  height: 1px;
  width: 1px;

  border: 0;

  clip: rect(0 0 0 0);
  clippath: inset(50%);

  margin: -1px;
  padding: 0;

  overflow: hidden;
  white-space: nowrap;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: ${styleConst.colors.white};
  stroke-width: 2px;
`;

export const StyledCheckbox = styled.div<IStyledCheckbox>`
  display: inline-block;

  width: 18px;
  height: 18px;

  background: ${({ checked }) =>
    checked ? styleConst.colors.primary : styleConst.colors.white};

  border-radius: 3px;
  border: 1px solid ${styleConst.colors.primary};

  transition: all 150ms;

  cursor: pointer;

  ${Icon} {
    visibility: ${({ checked }) => (checked ? "visible" : "hidden")};
  }
`;
