import React from "react";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";
import { SnapshotOrInstance } from "mobx-state-tree";
import Dropzone, { DropzoneRef } from "react-dropzone";

import OnboardingStore from "stores/OnboardingStore";

import I36Camera from "assets/icons/I36Camera";
import I24Exit from "assets/icons/I24Exit";

import Button from "components/Button";

import styleConst from "constants/style";

import { Answer, Field } from "models/Questionnaire";

import { validateFile, IMAGE_TYPES } from "utils/validation";

import {
  PhotoContainer,
  PhotoCircle,
  PhotoImage,
  PhotoSubTitle,
  PhotoTitle,
  PhotoWrapper,
  PhotoBorder,
  Container,
  PlaceholderContainer,
  PlaceholderBorder,
  IconButton,
} from "../styles";

type FieldItem = SnapshotOrInstance<typeof Field>;
type AnswerItem = SnapshotOrInstance<typeof Answer> & { value: string[] };

type Props = {
  field: FieldItem;
  onAnswer: (answer: AnswerItem) => void;
  parentFieldName?: string;
  store: any;
};

@observer
class Photo extends React.Component<Props> {
  dropzoneRef = React.createRef<DropzoneRef>();

  @observable isDragging: boolean = false;

  get dropzone() {
    return this.dropzoneRef.current;
  }

  @computed get photo() {
    return OnboardingStore.photoUrl;
  }

  handleDrop = (files: File[]) => {
    if (files && files.length > 0 && validateFile(files[0])) {
      this.onFileUpload({ target: { files: files } });
    }
  };

  onBrowseFileClick = () => {
    this.dropzone?.open();
  };

  onFileUpload = ({ target }: any) => {
    if (target.files && target.files[0]) {
      const file = target.files[0];

      const path = URL.createObjectURL(file);
      OnboardingStore.setPhoto({ path, file });
    }
  };

  onRemoveFile = () => {
    OnboardingStore.deletePhoto();
  };

  render() {
    const uri = this.photo ?? null;

    return (
      <Dropzone
        ref={this.dropzoneRef}
        onDrop={this.handleDrop}
        maxFiles={1}
        accept={IMAGE_TYPES}
        noClick
        noKeyboard
      >
        {({ isDragActive, getRootProps, getInputProps }) => (
          <PhotoContainer {...getRootProps()}>
            {isDragActive ? (
              <PlaceholderContainer>
                <PlaceholderBorder>
                  <I36Camera color={styleConst.colors.black} />
                </PlaceholderBorder>
              </PlaceholderContainer>
            ) : (
              <>
                <Container>
                  <PhotoTitle>{"You’re almost done!"}</PhotoTitle>
                  <PhotoSubTitle>
                    {"Drag&drop to upload your photo\nor"}
                  </PhotoSubTitle>
                  <Button
                    height={26}
                    width={130}
                    fontSize={14}
                    text={"Browse file"}
                    onClick={this.onBrowseFileClick}
                  />
                </Container>
                <PhotoWrapper>
                  {uri ? (
                    <>
                      <IconButton
                        backgroundColor={styleConst.colors.transparent}
                        onClick={this.onRemoveFile}
                        icon={<I24Exit color={styleConst.colors.red} />}
                      />
                      <PhotoImage alt="avatar" src={uri} />
                    </>
                  ) : (
                    <PhotoCircle>
                      <PhotoBorder>
                        <I36Camera color={styleConst.colors.black} />
                      </PhotoBorder>
                    </PhotoCircle>
                  )}
                </PhotoWrapper>
                <input
                  {...getInputProps()}
                  hidden
                  type="file"
                  accept={"image/*"}
                  onChange={this.onFileUpload}
                />
              </>
            )}
          </PhotoContainer>
        )}
      </Dropzone>
    );
  }
}

export default Photo;
