import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IContent {
  top?: boolean;
  bottom?: boolean;
}

interface IIConWrapper {
  contentIcon?: boolean;
}

export const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding-bottom: 59px;
  padding-top: 50px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 702px;
  height: 600px;

  overflow-y: hidden;

  border-radius: 10px;
`;

export const Content = styled.div<IContent>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  height: 100%;

  padding: 0 60px;
`;

export const IconWrapper = styled.div<IIConWrapper>`
  margin-right: 20px;

  ${({ contentIcon }) =>
    contentIcon &&
    css`
      display: flex;

      margin-right: 6px;
    `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 53px 0 10px 0;
`;

export const Title = styled.div`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;

  color: ${styleConst.colors.dark};
  padding-left: 12px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: 50%;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
`;

export const ItemWrapper = styled.div`
  display: flex;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  margin-top: 20px;
`;

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  padding-top: 10px;
`;

export const UserPhoto = styled.img`
  width: 168px;
  height: 168px;

  object-fit: cover;
  border-radius: 100%;

  margin-bottom: 13px;
`;
