import styled from "styled-components";

import styleConst from "constants/style";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  margin-bottom: 20px;
`;

export const Title = styled.div`
  margin-bottom: 18px;

  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  font-family: ${styleConst.fonts.montserrat};
  background: rgba(15, 61, 63, 0.05);
  border-radius: 10px;

  padding: 12px 20px;
`;
