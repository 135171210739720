import styled from "styled-components";

import backgroundImage from "assets/images/svg/dots_background_placeholder.svg";

import styleConst from "constants/style";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: relative;

  min-height: 100vh;
  height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 100px 0;

  max-width: 283px;

  @media (orientation: landscape) {
    min-height: 558px;
  }
`;

export const Footer = styled.div`
  min-height: 100px;
  width: 100%;

  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  background-image: url(${backgroundImage});

  @media (orientation: portrait) {
    position: absolute;
    bottom: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  padding: 40px 0;

  background-color: ${styleConst.colors.white};
  box-shadow: 0px 7px 20px ${styleConst.colors.shadow};
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Text = styled.p`
  display: flex;

  margin-bottom: 32px;

  font-family: ${styleConst.fonts.roboto};
  font-size: 14px;
  line-height: 20px;

  text-align: center;

  color: ${styleConst.colors.black50};
`;

export const Title = styled.p`
  display: flex;

  margin-bottom: 16px;

  font-family: ${styleConst.fonts.montserrat};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${styleConst.colors.primaryDark};
`;
