import { applySnapshot, flow, types } from "mobx-state-tree";
import { parseISO, format } from "date-fns";

import api, { NoInternetConnectionError, NotFoundError } from "services/api";

import {
  CATEGORY_CONTENT_PAGE_SIZE,
  CONTENT_STORE_TYPE,
  CONTENT_STORE_TYPE_LABEL,
} from "constants/studio";

import { Context } from "models";
import { Workout } from "models/Workout";

const defaultParams = { size: CATEGORY_CONTENT_PAGE_SIZE };

const StudioContent = types
  .model("StudioContent", {
    type: types.maybe(types.enumeration(Object.values(CONTENT_STORE_TYPE))),
    categoryId: types.maybe(types.number),
    items: types.array(types.late(() => Workout)),
    context: types.maybe(Context),
    dayOfWeek: types.maybe(types.number),
    date: types.maybe(types.string),
    isLoading: types.optional(types.boolean, false),
    isMoreLoading: types.optional(types.boolean, false),
    isNoInternetConnectionError: types.optional(types.boolean, false),
    isNotFoundError: types.optional(types.boolean, false),
  })
  .views(self => ({
    get totalRows() {
      return self.context?.totalRows ?? 0;
    },
    get totalPages() {
      return self.context?.pages ?? 0;
    },
    get page() {
      return self.context?.page ?? 0;
    },
    get needMore() {
      if (self.context) {
        return self.context.page < self.context.pages;
      }
    },
  }))
  .views(self => ({
    get formattedDate() {
      return self.date ? format(parseISO(self.date), "EEEE") : "";
    },
  }))
  .views(self => ({
    get title() {
      switch (self.type) {
        case CONTENT_STORE_TYPE.WEEK:
          return self.formattedDate;
        case CONTENT_STORE_TYPE.STANDARD_FAVORITES:
          return CONTENT_STORE_TYPE_LABEL.STANDARD_FAVORITES;
        case CONTENT_STORE_TYPE.STANDARD_ALL:
          return CONTENT_STORE_TYPE_LABEL.STANDARD_ALL;
        case CONTENT_STORE_TYPE.STANDARD_NEWEST:
          return CONTENT_STORE_TYPE_LABEL.STANDARD_NEWEST;
        default:
          return ``;
      }
    },
    get fetchUrl() {
      switch (self.type) {
        case CONTENT_STORE_TYPE.WEEK:
          return `/mobile/workout/category/${self.categoryId}/workout/list`;
        case CONTENT_STORE_TYPE.STANDARD_FAVORITES:
          return `/mobile/workout/favorite/list`;
        case CONTENT_STORE_TYPE.STANDARD_NEWEST:
          return `/mobile/workout/category/${self.categoryId}/workout/newest/list`;
        case CONTENT_STORE_TYPE.STANDARD_ALL:
          return `/mobile/workout/category/${self.categoryId}/workout/list`;
        default:
          return ``;
      }
    },
    get fetchParams() {
      switch (self.type) {
        case CONTENT_STORE_TYPE.WEEK:
          return {
            DayOfWeek: self.dayOfWeek,
          };
        default:
          return {};
      }
    },
  }))
  .actions(self => ({
    getFetchParams(page: number, isFullAccessOnly: boolean = false) {
      switch (self.type) {
        case CONTENT_STORE_TYPE.WEEK:
          return {
            DayOfWeek: self.dayOfWeek,
            page,
            isFullAccessOnly,
            ...defaultParams,
          };
        case CONTENT_STORE_TYPE.STANDARD_FAVORITES:
          return {
            WorkoutCategoryId: self.categoryId,
            isFullAccessOnly,
          };
        case CONTENT_STORE_TYPE.STANDARD_NEWEST:
        case CONTENT_STORE_TYPE.STANDARD_ALL:
          return { page, isFullAccessOnly, ...defaultParams };
        default:
          return {};
      }
    },
  }))
  .actions(self => ({
    fetch: flow(function* (
      page: number = 1,
      isFullAccessOnly: boolean = false,
    ) {
      try {
        if (page === 1) {
          self.isLoading = true;
        } else {
          self.isMoreLoading = true;
        }

        const params = self.getFetchParams(page, isFullAccessOnly);

        const { items, context } = yield api.get(self.fetchUrl, params);

        applySnapshot(self, {
          ...self,
          context,
          items: page === 1 ? items : self.items.concat(items),
        });
      } catch (error) {
        if (error instanceof NoInternetConnectionError) {
          self.isNoInternetConnectionError = true;
        }
        if (error instanceof NotFoundError) {
          self.isNotFoundError = true;
        }
        throw error;
      } finally {
        if (page === 1) {
          self.isLoading = false;
        } else {
          self.isMoreLoading = false;
        }
      }
    }),
    setPage(page: number) {
      if (self.context) {
        self.context.page = page;
      }
    },
    resetPage() {
      if (self.context) {
        self.context.page = 1;
      }
    },
    reset() {
      applySnapshot(self, {});
    },
  }))
  .actions(self => ({
    afterCreate() {
      self.fetch();
    },
  }));

export default StudioContent;
