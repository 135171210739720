import React from "react";

import Button from "components/Button";

import { ButtonWrapper, Container, Content, Text, Controls } from "./styles";

type Props = {
  title: string;
  onClose: () => void;
  onAccept: () => void;
};

class ConfirmPopup extends React.Component<Props> {
  render() {
    const { title, onAccept, onClose } = this.props;
    return (
      <Container>
        <Content>
          <Text>{title}</Text>
          <Controls>
            <ButtonWrapper>
              <Button text={"Cancel"} onClick={onClose} height={50} />
            </ButtonWrapper>
            <ButtonWrapper>
              <Button text={"OK"} onClick={onAccept} height={50} />
            </ButtonWrapper>
          </Controls>
        </Content>
      </Container>
    );
  }
}

export default ConfirmPopup;
