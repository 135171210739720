export default {
  colors: {
    primary: "rgba(132, 207, 203, 1)",
    primary10: "rgba(132, 207, 203, 0.1)",
    primary20: "rgba(132, 207, 203, 0.2)",
    primary50: "rgba(132, 207, 203, 0.5)",
    primary70: "rgba(132, 207, 203, 0.7)",
    primaryLight: "rgba(220, 245, 244, 1)",
    primaryMedium: "rgba(180, 233, 230, 1)",
    primaryDark: "rgba(117, 191, 187, 1)",
    primaryAdditional1: "rgba(195, 231, 229, 1)",
    primaryAdditional2: "rgba(123, 202, 199, 1)",
    primaryAdditional3: "rgba(132, 207, 203, 1)",
    secondary: "rgba(87, 189, 185, 1)",
    secondary80: "rgba(87, 189, 185, 0.8)",
    red: "rgba(255, 124, 111, 1)",
    primaryRed1: "rgba(255, 99, 118, 1)",
    redLight: "rgba(255, 124, 111, 0.3)",
    orange: "rgba(227, 171, 87, 1)",
    background: "rgba(243, 243, 243, 1)",
    backgroundGreen: "rgba(26, 54, 53, 0.2)",
    white: "rgba(255, 255, 255, 1)",
    white20: "rgba(255, 255, 255, 0.2)",
    white50: "rgba(255, 255, 255, 0.5)",
    white60: "rgba(255, 255, 255, 0.6)",
    white70: "rgba(255, 255, 255, 0.7)",
    white80: "rgba(255, 255, 255, 0.8)",
    black: "rgba(0, 0, 0, 1)",
    black2: "rgba(0, 0, 0, 0.02)",
    black4: "rgba(0, 0, 0, 0.04)",
    black10: "rgba(0, 0, 0, 0.1)",
    black25: "rgba(0, 0, 0, 0.25)",
    black30: "rgba(0, 0, 0, 0.3)",
    black45: "rgba(0, 0, 0, 0.45)",
    black50: "rgba(0, 0, 0, 0.5)",
    black70: "rgba(0, 0, 0, 0.7)",
    dark: "rgba(26, 54, 53, 1)",
    dark5: "rgba(15, 61, 63, 0.05)",
    dark50: "rgba(26, 54, 53, 0.5)",
    text: "rgba(26, 54, 53, 1)",
    text40: "rgba(26, 54, 53, 0.4)",
    transparent: "rgba(0, 0, 0, 0)",
    stroke: "rgba(92, 60, 60, 0.26)",

    shadow: "rgba(62, 87, 101, 0.25)",
  },
  gradient: {
    primary: { from: "rgba(195, 231, 229, 1)", to: "rgba(123, 202, 199, 1)" },
    dark: { from: "rgba(3, 3, 3, 0.4)", to: "rgba(3, 3, 3, 0.7)" },
    category: {
      from: "rgba(0, 0, 0, 1)",
      center: "rgba(3, 3, 3, 1)",
      to: "rgba(3, 3, 3, 0)",
    },
  },
  fonts: {
    montserrat: "Montserrat",
    roboto: "Roboto",
    sfPro: "Source Sans Pro",
    outfit: "Outfit",
  },
};
