import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IICons {
  left?: boolean;
}
interface IIconWrapper {
  storeIcon?: boolean;
}

interface IText {
  small?: boolean;
}

interface ISideContainer {
  left?: boolean;
  right?: boolean;
}

export const BottomContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 55px;
`;

export const BottomContent = styled.div`
  display: flex;

  &:nth-child(1) {
    width: 316px;
  }

  &:nth-child(2) {
    width: 305px;
  }

  &:nth-child(3) {
    width: 280px;
  }
`;

export const BottomTextWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 30px;
  }
`;

export const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 110px;
  }
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: row;

  max-width: 380px;
`;

export const Gap = styled.div`
  height: 55px;
  width: 55px;
`;

export const Icons = styled.div<IICons>`
  display: flex;
  flex-direction: row;

  margin-top: 30px;

  ${({ left }) =>
    left &&
    css`
      margin-top: 9px;
    `}
`;

export const IconWrapper = styled.div<IIconWrapper>`
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 20px;
  }

  ${({ storeIcon }) =>
    storeIcon &&
    css`
      &:not(:last-child) {
        margin-right: 10px;
      }
    `}
`;

export const Logo = styled.div`
  margin-bottom: 7px;
`;

export const Menu = styled.div`
  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;

export const Link = styled.a<IText>`
  font-family: ${styleConst.fonts.roboto};
  font-size: 14px;
  color: ${styleConst.colors.black50};
  line-height: 14px;
  text-decoration: none;

  ${({ small }) =>
    small &&
    css`
      font-size: 12px;
      line-height: 12px;
    `}

  cursor: pointer;
`;

export const Text = styled.p<IText>`
  font-family: ${styleConst.fonts.roboto};
  font-size: 14px;
  color: ${styleConst.colors.black50};

  ${({ small }) =>
    small &&
    css`
      font-size: 12px;
    `}
`;

export const Title = styled.p`
  margin-bottom: 22px;

  font-family: ${styleConst.fonts.montserrat};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: ${styleConst.colors.primary};
`;

export const SideContainer = styled.div<ISideContainer>`
  display: flex;
  flex-direction: column;

  ${({ left }) =>
    left &&
    css`
      max-width: 400px;
    `}

  ${({ right }) =>
    right &&
    css`
      max-width: 350px;
      padding-left: 70px;
    `}
`;
