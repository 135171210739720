import styled from "styled-components";

import {
  PAGE_WIDTH,
  HEADER_HEIGHT,
  FOOTER_HEIGHT,
  MEDIA_QUERIES,
} from "constants/layout";

export const ContentWrapper = styled.div`
  position: relative;

  flex-grow: 1;

  overflow-y: auto;
  overflow-x: hidden;

  ${MEDIA_QUERIES.SMALL_DESKTOP} {
    overflow-x: auto;
  }
`;

export const ContentContainer = styled.div`
  display: flex;

  position: relative;
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  z-index: -1;

  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 auto;

  min-height: calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT});

  box-sizing: border-box;

  ${MEDIA_QUERIES.DESKTOP} {
    width: ${PAGE_WIDTH.DESKTOP};
    min-width: ${PAGE_WIDTH.DESKTOP};
  }

  ${MEDIA_QUERIES.MEDIUM_DESKTOP} {
    width: ${PAGE_WIDTH.SMALL_DESKTOP};
    min-width: ${PAGE_WIDTH.SMALL_DESKTOP};
  }
`;

export const WrappedContainer = styled.div`
  display: flex;

  width: 100%;
  height: 100%;
  min-height: 100%;

  padding-top: ${HEADER_HEIGHT};

  box-sizing: border-box;
`;
