import styled, { css } from "styled-components";

import DefaultIconButton from "components/IconButton";

import styleConst from "constants/style";

interface IImage {
  imageUrl?: string;
}

export const Container = styled.div`
  display: flex;

  height: 120px;
  width: 100%;

  margin-bottom: 50px;

  background: ${styleConst.colors.dark5};
  border-radius: 10px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  margin-left: 50px;
  margin-right: 37px;
`;

export const FavoriteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 140px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: ${styleConst.colors.dark};
`;

export const IconButton = styled(DefaultIconButton)`
  width: 60px;
  height: 60px;
`;

export const Image = styled.div<IImage>`
  width: 180px;
  height: 100%;

  border-radius: 10px 0px 0px 10px;
  background-color: ${styleConst.colors.black4};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `}
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const Duration = styled.div`
  display: inline-block;
  position: absolute;

  bottom: 5px;
  left: 5px;

  padding: 4px 8px;

  border-radius: 6px;

  background-color: ${styleConst.colors.black70};

  z-index: 2;

  color: ${styleConst.colors.white};

  font-size: 12px;
`;
