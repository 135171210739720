import React from "react";

import { Container, Image, Text } from "./styles";

type Props = {
  text: string;
  className?: any;
  imageUrl?: string;
  inline?: boolean;
};

const Placeholder = ({ imageUrl, text, className, inline = true }: Props) => (
  <Container className={className} inline={inline}>
    {imageUrl && <Image imageUrl={imageUrl} />}
    <Text>{text}</Text>
  </Container>
);

export default Placeholder;
