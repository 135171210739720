import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IPlaceholderTitle {
  primary?: boolean;
}

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 2px;
`;

export const Container = styled.div`
  margin-top: 59.94px;

  background-color: ${styleConst.colors.white};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0px 22px 30px 22px;
`;

export const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 40px;
`;

export const PlaceholderIcon = styled.div`
  margin: 9px 0 13px;
`;

export const PlaceholderTitle = styled.div<IPlaceholderTitle>`
  font-size: 15px;
  line-height: 20px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.text};

  ${({ primary }) =>
    primary &&
    css`
      margin-bottom: 4px;

      font-size: 24px;
      line-height: 24px;
      font-family: ${styleConst.fonts.roboto};
    `};
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.dark};
  font-weight: 700;

  padding-right: 15px;
`;

export const TitleContainer = styled.div`
  display: flex;
`;

export const ButtonInlineWrapper = styled.div`
  cursor: pointer;
  padding-left: 21.3px;
`;
