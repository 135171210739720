import React from "react";
import DefaultCalendar, { CalendarProps } from "react-calendar";
import { addMonths, format } from "date-fns";
import { observer } from "mobx-react";
import { observable } from "mobx";

import Header from "./Header";

import { CalendarContainer } from "./styles";

interface Props extends CalendarProps {
  markedDates?: any;
  headerFontSize?: number;
  onChangeMonth?: (date: Date) => void;
}

@observer
class Calendar extends React.Component<Props> {
  @observable activeStartDate = new Date();

  onNext = () => {
    const { onChangeMonth } = this.props;
    this.activeStartDate = addMonths(this.activeStartDate, 1);
    onChangeMonth?.(this.activeStartDate);
  };

  onPrev = () => {
    const { onChangeMonth } = this.props;
    this.activeStartDate = addMonths(this.activeStartDate, -1);
    onChangeMonth?.(this.activeStartDate);
  };

  setClass = (date: Date) => {
    const { markedDates = {} } = this.props;

    let className = "";

    for (const markedDate in markedDates) {
      const currentDate = format(date, "yyyy-MM-dd");
      if (currentDate === markedDate) {
        className = markedDates[markedDate].completed
          ? "completed"
          : "scheduled";

        className += markedDates[markedDate].startingDay ? " startingDay" : "";
        className += markedDates[markedDate].endingDay ? " endingDay" : "";
      }
    }

    return className;
  };

  render() {
    const { value = new Date(), headerFontSize = 18, ...props } = this.props;

    return (
      <CalendarContainer>
        <Header
          date={this.activeStartDate}
          onNextClick={this.onNext}
          onPrevClick={this.onPrev}
          fontSize={headerFontSize}
        />
        <DefaultCalendar
          activeStartDate={this.activeStartDate}
          showNavigation={false}
          showNeighboringMonth={false}
          value={value}
          locale={"en"}
          tileClassName={({ date }) => this.setClass(date)}
          tileContent={() => (
            <>
              <div className={"calendarCircle"}></div>
              <div className={"today"}></div>
              <div className={"hoverCalendarCircle"}></div>
            </>
          )}
          {...props}
        />
      </CalendarContainer>
    );
  }
}

export default Calendar;
