import { flow, types, applySnapshot, Instance } from "mobx-state-tree";

import api from "services/api";

import { DEFAULT_GROUPS } from "constants/macroCalculator";

import {
  Answer,
  FieldWithDependentFields,
  Fields,
  FieldError,
} from "models/Questionnaire";

const MacroCalculator = types
  .model("MacroCalculator", {
    groups: types.array(Fields),
    answers: types.array(Answer),
    errors: types.map(FieldError),
    current: types.optional(types.number, 1),
    isLoading: types.optional(types.boolean, false),
    isManaging: types.optional(types.boolean, false),
  })
  .views(self => ({
    get count() {
      return self.groups.length;
    },
  }))
  .views(self => ({
    get isFirstStep() {
      return self.current === 1;
    },

    get isLastStep() {
      return self.count && self.current === self.count;
    },
  }))
  .views(self => ({
    get currentGroup() {
      return self.groups.find((group, index) => index + 1 === self.current);
    },

    getQuestionByField(
      fieldName: string,
    ): Instance<typeof FieldWithDependentFields> {
      return self.groups
        .map(({ fields }) => fields)
        .reduce((acc: any, current: any) => acc.concat(current), [])
        .find(({ name }: { name: string }) => name === fieldName);
    },

    getAnswersByField(fieldName: string): any {
      return self.answers.find(answer => answer.fieldName === fieldName);
    },
    getErrorByField(fieldName: string) {
      return self.errors.get(fieldName)?.message;
    },
  }))
  .actions(self => ({
    addAnswer(nextAnswer: any) {
      self.answers.push(nextAnswer);
    },

    updateAnswer(nextAnswer: any) {
      const answers = self.answers.map(answer =>
        answer.fieldName !== nextAnswer.fieldName ? answer : nextAnswer,
      );

      self.errors.delete(nextAnswer.fieldName);

      applySnapshot(self, {
        ...self,
        answers,
        // @ts-ignore
        errors: self.errors,
      });
    },

    calcAnswersBeforePublish() {
      const answers: any = {};

      self.answers.forEach(({ fieldName, value }) => {
        answers[fieldName] = value;
      });

      return answers;
    },
  }))
  .actions(self => ({
    fetch: flow(function* () {
      try {
        self.isLoading = true;

        const prefilledAnswers = yield api.get("/mobile/macro-calculation");

        const answers = Object.entries(prefilledAnswers).map(
          ([key, value]: any) => ({
            fieldName: key,
            value: value.toString(),
          }),
        );
        const groups = DEFAULT_GROUPS;

        applySnapshot(self, { ...self, answers, groups, errors: {} });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    stepForward() {
      self.current++;
    },

    stepBackward() {
      self.current--;
    },

    pushAnswer(nextAnswer: any) {
      const answer = self.getAnswersByField(nextAnswer.fieldName);

      if (answer) {
        self.updateAnswer(nextAnswer);
      } else {
        self.addAnswer(nextAnswer);
      }
    },

    publishAnswers: flow(function* () {
      try {
        self.isManaging = true;

        const answers = self.calcAnswersBeforePublish();

        yield api.put("/mobile/macro-calculation", answers);
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isManaging = false;
      }
    }),

    reset() {
      applySnapshot(self, { groups: self.groups });
    },
  }));

const MacroCalculatorStore = MacroCalculator.create({
  answers: [],
});

export default MacroCalculatorStore;
