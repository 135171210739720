import React from "react";
import {
  Switch as SwitchContext,
  Redirect,
  Route,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import { observer } from "mobx-react";

import AuthStore from "stores/AuthStore";
import ProfileStore from "stores/ProfileStore";

import SignIn from "pages/SignIn";
import ForgotPassword from "pages/ForgotPassword";
import Today from "pages/Today";
import FoodLog from "pages/Nutrition/FoodLog";
import RecipeLibrary from "pages/Nutrition/RecipeLibrary";
import CategoryDetails from "pages/Nutrition/CategoryDetails";
import MyRecipeDetails from "pages/Nutrition/MyRecipeDetails";
import MyFoodDetails from "pages/Nutrition/MyFoodDetails";
import MyItems from "pages/Nutrition/MyItems";
import Studio from "pages/Studio";
import WorkoutCategoryDetails from "pages/Studio/WorkoutCategoryDetails";
import Workouts from "pages/Studio/Workouts";
import WorkoutDetails from "pages/Workout/WorkoutDetails";
import WorkoutMovement from "pages/Workout/WorkoutMovement";
import Resources from "pages/Resources";
import ResourcesCategoryDetails from "pages/Resources/ResourcesCategoryDetails";
import ResourceDetails from "pages/Resources/ResourcesCategoryDetails/ResourceDetails";
import Onboarding from "pages/Onboarding";
import RecipeDetails from "pages/RecipeDetails";
import Profile from "pages/Profile";
import Calendar from "pages/Calendar";

import GlobalNavigationContext from "./context";
import ROUTES from "./routes";

type Props = {
  component: React.ComponentClass<any>;
  allowAccess: boolean;
  redirectTo: string;
  path: string | string[];
  exact?: boolean;
} & RouteComponentProps;

export const PrivateRoute = (props: Props) => {
  const {
    component: Component,
    allowAccess,
    redirectTo,
    path,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={props =>
        allowAccess || path === ROUTES.SIGN_IN ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: allowAccess ? ROUTES.SIGN_IN : redirectTo,
              state: { from: props.location.pathname },
            }}
          />
        )
      }
    />
  );
};

@observer
class Switch extends React.Component<RouteComponentProps> {
  redirectIfNotAccess = AuthStore.isAuthorized ? ROUTES.TODAY : ROUTES.SIGN_IN;

  get navigationContext() {
    return {
      navigateToLogin: this.navigateToLogin,
    };
  }

  componentDidMount() {
    AuthStore.init();

    if (AuthStore.isAuthorized) {
      ProfileStore.fetch();
    }

    if (AuthStore.isNeedChangePassword) {
      this.props.history.push(ROUTES.ONBOARDING);
    }
  }

  navigateToLogin = () => {
    this.props.history.push(ROUTES.SIGN_IN);
  };

  renderRedirectIfUserIsLoggedIn = () => (
    <Redirect to={this.redirectIfNotAccess} />
  );

  render() {
    return (
      <GlobalNavigationContext.Provider value={this.navigationContext}>
        <SwitchContext>
          <Route exact path="/" render={this.renderRedirectIfUserIsLoggedIn} />
          <Route exact path={ROUTES.SIGN_IN} component={SignIn} />
          <Route
            exact
            path={ROUTES.FORGOT_PASSWORD}
            component={ForgotPassword}
          />

          <PrivateRoute
            path={ROUTES.TODAY}
            component={Today}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.SIGN_IN}
            exact
            {...this.props}
          />
          <PrivateRoute
            path={ROUTES.NUTRITION_FOOD_LOG}
            component={FoodLog}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.SIGN_IN}
            exact
            {...this.props}
          />
          <PrivateRoute
            path={ROUTES.NUTRITION_RECIPE_LIBRARY}
            component={RecipeLibrary}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.SIGN_IN}
            exact
            {...this.props}
          />
          <PrivateRoute
            path={ROUTES.NUTRITION_RECIPE_LIBRARY_CATEGORY_DETAILS}
            component={CategoryDetails}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.NUTRITION_RECIPE_LIBRARY}
            exact
            {...this.props}
          />
          <PrivateRoute
            path={[
              ROUTES.TODAY_RECIPE_DETAILS,
              ROUTES.NUTRITION_FOOD_LOG_RECIPE_LIBRARY_RECIPE_DETAILS,
              ROUTES.NUTRITION_RECIPE_LIBRARY_RECIPE_DETAILS,
              ROUTES.NUTRITION_MY_ITEMS_FAVORITES_RECIPE,
              ROUTES.NUTRITION_SEARCH_RECIPE_DETAILS,
            ]}
            component={RecipeDetails}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.NUTRITION_RECIPE_LIBRARY}
            exact
            {...this.props}
          />
          <PrivateRoute
            path={[ROUTES.NUTRITION_MY_ITEMS_MY_RECIPES_DETAILS]}
            component={MyRecipeDetails}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.NUTRITION_MY_ITEMS_MY_RECIPES}
            exact
            {...this.props}
          />
          <PrivateRoute
            path={[
              ROUTES.NUTRITION_FOOD_LOG_FOOD_DETAILS,
              ROUTES.NUTRITION_MY_ITEMS_MY_FOOD_DETAILS,
            ]}
            component={MyFoodDetails}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.NUTRITION_MY_ITEMS_MY_FOOD}
            exact
            {...this.props}
          />
          <PrivateRoute
            path={[
              ROUTES.NUTRITION_MY_ITEMS_MY_RECIPES,
              ROUTES.NUTRITION_MY_ITEMS_MY_FOOD,
              ROUTES.NUTRITION_MY_ITEMS_FAVORITES,
            ]}
            component={MyItems}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.SIGN_IN}
            exact
            {...this.props}
          />

          <PrivateRoute
            path={ROUTES.STUDIO}
            component={Studio}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.SIGN_IN}
            exact
            {...this.props}
          />
          <PrivateRoute
            path={ROUTES.STUDIO_CATEGORY_DETAILS}
            component={WorkoutCategoryDetails}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.STUDIO}
            exact
            {...this.props}
          />
          <PrivateRoute
            path={[
              ROUTES.STUDIO_WORKOUTS_FAVORITES,
              ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUTS,
            ]}
            component={Workouts}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.STUDIO}
            exact
            {...this.props}
          />
          <PrivateRoute
            path={[
              ROUTES.TODAY_WORKOUT_DETAILS,
              ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUT_DETAILS,
              ROUTES.STUDIO_WORKOUT_FAVORITES_WORKOUT_DETAILS,
              ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUTS_WORKOUT_DETAILS,
              ROUTES.STUDIO_SEARCH_WORKOUT_DETAILS,
              ROUTES.CALENDAR_WORKOUT_SCHEDULE_DATE_DETAILS,
            ]}
            component={WorkoutDetails}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.STUDIO}
            exact
            {...this.props}
          />
          <PrivateRoute
            path={[
              ROUTES.TODAY_WORKOUT_DETAILS_MOVEMENT_DETAILS,
              ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUT_DETAILS_MOVEMENT_DETAILS,
              ROUTES.STUDIO_WORKOUT_FAVORITES_WORKOUT_DETAILS_MOVEMENT_DETAILS,
              ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUTS_WORKOUT_DETAILS_MOVEMENT_DETAILS,
              ROUTES.STUDIO_SEARCH_WORKOUT_DETAILS_MOVEMENT_DETAILS,
              ROUTES.CALENDAR_WORKOUT_SCHEDULE_DATE_DETAILS_MOVEMENT_DETAILS,
            ]}
            component={WorkoutMovement}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.STUDIO}
            exact
            {...this.props}
          />

          <PrivateRoute
            path={ROUTES.RESOURCES}
            component={Resources}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.SIGN_IN}
            exact
            {...this.props}
          />
          <PrivateRoute
            path={ROUTES.RESOURCES_CATEGORY_DETAILS}
            component={ResourcesCategoryDetails}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.RESOURCES}
            exact
            {...this.props}
          />
          <PrivateRoute
            path={[
              ROUTES.TODAY_QUICK_LINK_DETAILS,
              ROUTES.RESOURCES_CATEGORY_DETAILS_QUICK_LINK_DETAILS,
              ROUTES.RESOURCES_SEARCH_RESOURCE_DETAILS,
            ]}
            component={ResourceDetails}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.RESOURCES}
            exact
            {...this.props}
          />

          <PrivateRoute
            path={ROUTES.ONBOARDING}
            component={Onboarding}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.ONBOARDING}
            exact
            {...this.props}
          />
          <PrivateRoute
            path={[
              ROUTES.PROFILE,
              ROUTES.MACROGOALS,
              ROUTES.MEASUREMENTS,
              ROUTES.CHANGE_PASSWORD,
              ROUTES.CONTACTS,
            ]}
            component={Profile}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.SIGN_IN}
            exact
            {...this.props}
          />

          <PrivateRoute
            path={ROUTES.CALENDAR}
            component={Calendar}
            allowAccess={AuthStore.isAuthorized}
            redirectTo={ROUTES.SIGN_IN}
            exact
            {...this.props}
          />

          <Redirect to={this.redirectIfNotAccess} />
        </SwitchContext>
      </GlobalNavigationContext.Provider>
    );
  }
}

export default withRouter(Switch);
