import styled, { css } from "styled-components";

import topBackgroundImage from "assets/images/svg/promote_popup_background.svg";

import styleConst from "constants/style";

interface IContent {
  top?: boolean;
  bottom?: boolean;
}

interface IIConWrapper {
  contentIcon?: boolean;
}

export const ButtonWrapper = styled.div`
  width: 306px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 400px;
  min-height: 280px;

  border-radius: 10px;
`;

export const Content = styled.div<IContent>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ top }) =>
    top &&
    css`
      overflow: hidden;
      background-image: url(${topBackgroundImage});
      background-repeat: no-repeat;
      background-position: 50% 50%;
      justify-content: center;
      height: 110px;
    `}

  ${({ bottom }) =>
    bottom &&
    css`
      justify-content: space-between;
      padding: 43px 46px 40px;
      min-height: 100px;
    `}
`;

export const IconWrapper = styled.div<IIConWrapper>`
  margin-right: 20px;

  ${({ contentIcon }) =>
    contentIcon &&
    css`
      display: flex;

      margin-right: 6px;
    `}
`;

export const Text = styled.div`
  margin-left: 11px;

  padding-bottom: 30px;

  font-size: 14px;
  line-height: 24px;
  text-align: center;

  color: ${styleConst.colors.black50};
`;

export const Title = styled.div`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 18px;
  font-weight: 500;

  color: ${styleConst.colors.white};
`;
