import styled from "styled-components";

import { Title as RecipeTitle } from "components/Recipe/styles";

import styleConst from "constants/style";

export const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;

  width: 25%;
  visibility: hidden;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  line-height: 18.75px;

  padding-right: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background: linear-gradient(
      89.98deg,
      rgba(132, 207, 203, 0) 0%,
      rgba(132, 207, 203, 0.08) 12.5%,
      rgba(132, 207, 203, 0.08) 87.48%,
      rgba(132, 207, 203, 0) 99.97%
    );

    ${Controls} {
      visibility: visible;
    }

    ${RecipeTitle} {
      color: ${styleConst.colors.primaryDark};
    }
  }
`;
