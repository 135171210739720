import * as React from "react";

type Props = any;

const IAppStore = (props: Props) => {
  return (
    <svg width={135} height={40} viewBox="0 0 135 40" fill="none" {...props}>
      <path
        d="M5 0.5H130C132.474 0.5 134.5 2.52614 134.5 5V35C134.5 37.4739 132.474 39.5 130 39.5H5C2.52614 39.5 0.5 37.4739 0.5 35V5C0.5 2.52614 2.52614 0.5 5 0.5Z"
        stroke="#84CFCB"
      />
      <path
        d="M31.7973 19.8637C31.8081 19.029 32.0298 18.2106 32.4418 17.4847C32.8538 16.7587 33.4428 16.1488 34.1539 15.7116C33.7021 15.0665 33.1062 14.5355 32.4134 14.161C31.7205 13.7864 30.9499 13.5786 30.1627 13.5539C28.4835 13.3777 26.8555 14.5588 25.9998 14.5588C25.1275 14.5588 23.81 13.5714 22.3913 13.6006C21.4736 13.6303 20.5793 13.8971 19.7954 14.3752C19.0115 14.8532 18.3649 15.5262 17.9184 16.3285C15.9844 19.6769 17.427 24.598 19.2796 27.3046C20.2065 28.6299 21.2898 30.1104 22.7072 30.0579C24.0943 30.0004 24.6123 29.1734 26.2866 29.1734C27.9454 29.1734 28.4314 30.0579 29.8776 30.0245C31.366 30.0003 32.3038 28.6933 33.1981 27.3554C33.8641 26.411 34.3766 25.3673 34.7166 24.2628C33.8518 23.8971 33.1139 23.2849 32.5948 22.5026C32.0757 21.7204 31.7983 20.8025 31.7973 19.8637Z"
        fill="#84CFCB"
      />
      <path
        d="M29.0657 11.7739C29.8772 10.7997 30.277 9.5475 30.1802 8.28327C28.9404 8.41349 27.7951 9.00605 26.9726 9.94288C26.5705 10.4005 26.2625 10.933 26.0662 11.5098C25.87 12.0865 25.7893 12.6963 25.8289 13.3043C26.4491 13.3107 27.0626 13.1762 27.6232 12.9112C28.1839 12.6461 28.6771 12.2572 29.0657 11.7739Z"
        fill="#84CFCB"
      />
      <path
        d="M52.3307 26.7027H47.5973L46.4606 30.0591H44.4557L48.9391 17.6411H51.0221L55.5055 30.0591H53.4664L52.3307 26.7027ZM48.0875 25.1538H51.8395L49.9899 19.7066H49.9382L48.0875 25.1538Z"
        fill="#84CFCB"
      />
      <path
        d="M65.1881 25.5327C65.1881 28.3462 63.6823 30.1538 61.4098 30.1538C60.8342 30.1839 60.2617 30.0513 59.7579 29.7712C59.2541 29.4911 58.8393 29.0747 58.5612 28.5698H58.5182V33.0542H56.6598V21.0054H58.4586V22.5112H58.4928C58.7838 22.0088 59.2055 21.5946 59.7131 21.3127C60.2208 21.0309 60.7953 20.8919 61.3756 20.9107C63.6734 20.9107 65.1881 22.7271 65.1881 25.5327ZM63.278 25.5327C63.278 23.6997 62.3307 22.4946 60.8854 22.4946C59.4655 22.4946 58.5104 23.7251 58.5104 25.5327C58.5104 27.357 59.4655 28.5786 60.8854 28.5786C62.3307 28.5786 63.278 27.3823 63.278 25.5327Z"
        fill="#84CFCB"
      />
      <path
        d="M75.153 25.5327C75.153 28.3462 73.6471 30.1538 71.3746 30.1538C70.799 30.1839 70.2265 30.0513 69.7227 29.7712C69.2189 29.4911 68.8042 29.0747 68.526 28.5698H68.483V33.0542H66.6246V21.0054H68.4234V22.5112H68.4576C68.7486 22.0088 69.1703 21.5946 69.6779 21.3127C70.1856 21.0309 70.7601 20.8919 71.3404 20.9107C73.6383 20.9107 75.153 22.7271 75.153 25.5327ZM73.2428 25.5327C73.2428 23.6997 72.2955 22.4946 70.8502 22.4946C69.4303 22.4946 68.4752 23.7251 68.4752 25.5327C68.4752 27.357 69.4303 28.5786 70.8502 28.5786C72.2955 28.5786 73.2428 27.3823 73.2428 25.5327Z"
        fill="#84CFCB"
      />
      <path
        d="M81.7389 26.5991C81.8766 27.8306 83.0729 28.6391 84.7077 28.6391C86.2741 28.6391 87.401 27.8305 87.401 26.7202C87.401 25.7563 86.7213 25.1792 85.112 24.7837L83.5026 24.396C81.2223 23.8452 80.1637 22.7788 80.1637 21.0483C80.1637 18.9057 82.0309 17.434 84.6823 17.434C87.3063 17.434 89.1051 18.9057 89.1657 21.0483H87.2897C87.1774 19.809 86.1529 19.061 84.6559 19.061C83.1588 19.061 82.1344 19.8178 82.1344 20.9194C82.1344 21.7973 82.7887 22.3139 84.3893 22.7094L85.7574 23.0453C88.3053 23.6479 89.3639 24.6713 89.3639 26.4877C89.3639 28.811 87.5133 30.266 84.5699 30.266C81.816 30.266 79.9567 28.8451 79.8365 26.599L81.7389 26.5991Z"
        fill="#84CFCB"
      />
      <path
        d="M93.3746 18.8628V21.0054H95.0963V22.4771H93.3746V27.4683C93.3746 28.2437 93.7194 28.605 94.4762 28.605C94.6806 28.6014 94.8847 28.5871 95.0875 28.562V30.0249C94.7473 30.0885 94.4014 30.1173 94.0553 30.1108C92.2223 30.1108 91.5075 29.4223 91.5075 27.6665V22.4771H90.1911V21.0054H91.5074V18.8628H93.3746Z"
        fill="#84CFCB"
      />
      <path
        d="M96.0934 25.5327C96.0934 22.6841 97.7712 20.8941 100.387 20.8941C103.012 20.8941 104.682 22.6841 104.682 25.5327C104.682 28.3892 103.021 30.1714 100.387 30.1714C97.7545 30.1714 96.0934 28.3892 96.0934 25.5327ZM102.789 25.5327C102.789 23.5786 101.893 22.4253 100.387 22.4253C98.8815 22.4253 97.987 23.5874 97.987 25.5327C97.987 27.4946 98.8815 28.6392 100.387 28.6392C101.893 28.6392 102.789 27.4946 102.789 25.5327Z"
        fill="#84CFCB"
      />
      <path
        d="M106.214 21.0054H107.987V22.5464H108.03C108.15 22.0651 108.432 21.6398 108.828 21.3419C109.225 21.044 109.712 20.8917 110.208 20.9106C110.422 20.9099 110.635 20.9332 110.844 20.98V22.7183C110.574 22.6356 110.292 22.5977 110.009 22.606C109.739 22.595 109.47 22.6426 109.22 22.7455C108.971 22.8484 108.746 23.0041 108.562 23.202C108.378 23.3999 108.239 23.6353 108.155 23.8919C108.07 24.1486 108.042 24.4205 108.073 24.689V30.0591H106.214L106.214 21.0054Z"
        fill="#84CFCB"
      />
      <path
        d="M119.413 27.3999C119.163 29.0435 117.562 30.1714 115.514 30.1714C112.881 30.1714 111.246 28.4067 111.246 25.5757C111.246 22.7358 112.889 20.8941 115.436 20.8941C117.941 20.8941 119.516 22.6147 119.516 25.3599V25.9966H113.122V26.1089C113.092 26.4421 113.134 26.7778 113.244 27.0936C113.354 27.4094 113.53 27.6982 113.761 27.9408C113.991 28.1833 114.27 28.3741 114.58 28.5004C114.89 28.6267 115.223 28.6856 115.557 28.6733C115.996 28.7145 116.437 28.6128 116.814 28.3833C117.191 28.1539 117.483 27.809 117.648 27.3999L119.413 27.3999ZM113.131 24.6978H117.657C117.674 24.3982 117.628 24.0984 117.523 23.8171C117.419 23.5359 117.257 23.2794 117.049 23.0636C116.84 22.8479 116.589 22.6775 116.312 22.5633C116.034 22.4491 115.736 22.3935 115.436 22.3999C115.134 22.3981 114.834 22.4562 114.554 22.571C114.273 22.6857 114.019 22.8548 113.805 23.0684C113.59 23.2821 113.42 23.536 113.305 23.8157C113.189 24.0953 113.13 24.3951 113.131 24.6978Z"
        fill="#84CFCB"
      />
      <path
        d="M47.8546 8.294C48.2442 8.26604 48.6352 8.3249 48.9993 8.46633C49.3634 8.60777 49.6916 8.82825 49.9602 9.11187C50.2288 9.3955 50.4311 9.73519 50.5525 10.1065C50.6739 10.4777 50.7114 10.8713 50.6622 11.2588C50.6622 13.1651 49.632 14.2608 47.8546 14.2608H45.6994V8.294H47.8546ZM46.6261 13.417H47.7511C48.0295 13.4336 48.3081 13.388 48.5667 13.2834C48.8252 13.1788 49.0572 13.0178 49.2457 12.8122C49.4341 12.6067 49.5744 12.3617 49.6563 12.0951C49.7382 11.8284 49.7595 11.5469 49.7189 11.271C49.7566 10.9962 49.733 10.7164 49.6499 10.4517C49.5668 10.1871 49.4262 9.94406 49.2381 9.74013C49.05 9.5362 48.8192 9.37639 48.5621 9.27216C48.305 9.16792 48.0281 9.12184 47.7511 9.13721H46.6261V13.417Z"
        fill="#84CFCB"
      />
      <path
        d="M51.7091 12.0073C51.6808 11.7114 51.7147 11.4129 51.8085 11.1308C51.9023 10.8487 52.0541 10.5894 52.254 10.3694C52.454 10.1494 52.6977 9.97364 52.9695 9.85337C53.2414 9.73309 53.5354 9.67096 53.8326 9.67096C54.1299 9.67096 54.4239 9.73309 54.6958 9.85337C54.9676 9.97364 55.2113 10.1494 55.4113 10.3694C55.6112 10.5894 55.763 10.8487 55.8568 11.1308C55.9506 11.4129 55.9845 11.7114 55.9562 12.0073C55.985 12.3036 55.9516 12.6025 55.858 12.8851C55.7644 13.1676 55.6127 13.4274 55.4127 13.6478C55.2127 13.8682 54.9689 14.0444 54.6967 14.1649C54.4246 14.2854 54.1303 14.3477 53.8326 14.3477C53.535 14.3477 53.2407 14.2854 52.9686 14.1649C52.6964 14.0444 52.4526 13.8682 52.2526 13.6478C52.0526 13.4274 51.9009 13.1676 51.8073 12.8851C51.7137 12.6025 51.6803 12.3036 51.7091 12.0073ZM55.0421 12.0073C55.0421 11.0313 54.6036 10.4605 53.8341 10.4605C53.0617 10.4605 52.6271 11.0313 52.6271 12.0073C52.6271 12.9912 53.0617 13.5576 53.8341 13.5576C54.6037 13.5576 55.0421 12.9873 55.0421 12.0073Z"
        fill="#84CFCB"
      />
      <path
        d="M61.6017 14.2607H60.6798L59.7492 10.9443H59.6789L58.7521 14.2607H57.839L56.5978 9.75782H57.4992L58.3058 13.1938H58.3722L59.298 9.75782H60.1505L61.0763 13.1938H61.1466L61.9494 9.75782H62.838L61.6017 14.2607Z"
        fill="#84CFCB"
      />
      <path
        d="M63.882 9.75782H64.7374V10.4731H64.8038C64.9165 10.2162 65.1065 10.0009 65.3474 9.85705C65.5882 9.71324 65.868 9.64817 66.1476 9.6709C66.3667 9.65443 66.5867 9.68746 66.7913 9.76756C66.9959 9.84765 67.1798 9.97276 67.3295 10.1336C67.4792 10.2944 67.5908 10.4869 67.656 10.6967C67.7212 10.9065 67.7383 11.1283 67.7062 11.3457V14.2607H66.8175V11.5689C66.8175 10.8452 66.5031 10.4854 65.8458 10.4854C65.6971 10.4784 65.5486 10.5037 65.4105 10.5596C65.2725 10.6154 65.1481 10.7004 65.046 10.8088C64.9439 10.9172 64.8664 11.0464 64.8189 11.1875C64.7713 11.3286 64.7549 11.4784 64.7706 11.6265V14.2607H63.882L63.882 9.75782Z"
        fill="#84CFCB"
      />
      <path d="M69.1222 8H70.0109V14.2607H69.1222V8Z" fill="#84CFCB" />
      <path
        d="M71.2462 12.0073C71.2179 11.7114 71.2518 11.4128 71.3457 11.1307C71.4395 10.8487 71.5913 10.5893 71.7913 10.3693C71.9912 10.1493 72.235 9.97356 72.5068 9.85329C72.7787 9.73301 73.0727 9.67088 73.37 9.67088C73.6673 9.67088 73.9613 9.73301 74.2332 9.85329C74.505 9.97356 74.7488 10.1493 74.9487 10.3693C75.1487 10.5893 75.3005 10.8487 75.3943 11.1307C75.4882 11.4128 75.5221 11.7114 75.4938 12.0073C75.5226 12.3036 75.4891 12.6026 75.3955 12.8851C75.3019 13.1676 75.1502 13.4274 74.9502 13.6478C74.7501 13.8682 74.5062 14.0444 74.2341 14.1649C73.962 14.2854 73.6676 14.3477 73.37 14.3477C73.0724 14.3477 72.778 14.2854 72.5059 14.1649C72.2338 14.0444 71.9898 13.8682 71.7898 13.6478C71.5898 13.4274 71.4381 13.1676 71.3445 12.8851C71.2509 12.6026 71.2174 12.3036 71.2462 12.0073ZM74.5792 12.0073C74.5792 11.0313 74.1407 10.4605 73.3712 10.4605C72.5988 10.4605 72.1642 11.0313 72.1642 12.0073C72.1642 12.9912 72.5988 13.5576 73.3712 13.5576C74.1408 13.5576 74.5792 12.9873 74.5792 12.0073Z"
        fill="#84CFCB"
      />
      <path
        d="M76.4293 12.9873C76.4293 12.1768 77.0329 11.7095 78.1041 11.6431L79.3239 11.5728V11.1841C79.3239 10.7085 79.0094 10.44 78.402 10.44C77.9059 10.44 77.5622 10.6221 77.4635 10.9404H76.6032C76.694 10.167 77.4215 9.67092 78.443 9.67092C79.5719 9.67092 80.2086 10.2329 80.2086 11.1841V14.2608H79.3532V13.6279H79.2829C79.1401 13.8549 78.9397 14.04 78.7021 14.1642C78.4645 14.2884 78.1982 14.3473 77.9303 14.3349C77.7413 14.3546 77.5502 14.3344 77.3695 14.2757C77.1887 14.217 77.0222 14.1211 76.8808 13.9942C76.7394 13.8672 76.6262 13.712 76.5484 13.5386C76.4706 13.3652 76.4301 13.1774 76.4293 12.9873ZM79.3239 12.6025V12.2261L78.2243 12.2964C77.6041 12.3379 77.3229 12.5488 77.3229 12.9458C77.3229 13.3511 77.6744 13.5869 78.1579 13.5869C78.2995 13.6013 78.4426 13.587 78.5786 13.5449C78.7146 13.5028 78.8408 13.4338 78.9496 13.3419C79.0583 13.2501 79.1475 13.1373 79.2118 13.0103C79.2761 12.8832 79.3142 12.7446 79.3239 12.6025Z"
        fill="#84CFCB"
      />
      <path
        d="M81.3766 12.0073C81.3766 10.5845 82.108 9.68312 83.2457 9.68312C83.5272 9.67015 83.8064 9.73756 84.051 9.87745C84.2955 10.0173 84.4951 10.224 84.6266 10.4731H84.693V8H85.5817V14.2607H84.7301V13.5493H84.6598C84.5182 13.7968 84.3116 14.0008 84.0623 14.1393C83.8131 14.2778 83.5307 14.3455 83.2457 14.3349C82.1002 14.335 81.3766 13.4336 81.3766 12.0073ZM82.2946 12.0073C82.2946 12.9624 82.7448 13.5371 83.4977 13.5371C84.2467 13.5371 84.7096 12.9541 84.7096 12.0112C84.7096 11.0728 84.2419 10.4815 83.4977 10.4815C82.7496 10.4815 82.2946 11.0601 82.2946 12.0073Z"
        fill="#84CFCB"
      />
      <path
        d="M89.2584 12.0073C89.2301 11.7114 89.264 11.4129 89.3578 11.1308C89.4517 10.8487 89.6034 10.5894 89.8034 10.3694C90.0033 10.1494 90.247 9.97364 90.5189 9.85337C90.7907 9.73309 91.0847 9.67096 91.382 9.67096C91.6792 9.67096 91.9732 9.73309 92.2451 9.85337C92.5169 9.97364 92.7606 10.1494 92.9606 10.3694C93.1605 10.5894 93.3123 10.8487 93.4061 11.1308C93.5 11.4129 93.5338 11.7114 93.5055 12.0073C93.5344 12.3036 93.5009 12.6025 93.4073 12.8851C93.3137 13.1676 93.162 13.4274 92.9621 13.6478C92.7621 13.8682 92.5182 14.0444 92.246 14.1649C91.9739 14.2854 91.6796 14.3477 91.382 14.3477C91.0844 14.3477 90.79 14.2854 90.5179 14.1649C90.2458 14.0444 90.0019 13.8682 89.8019 13.6478C89.6019 13.4274 89.4502 13.1676 89.3566 12.8851C89.263 12.6025 89.2296 12.3036 89.2584 12.0073ZM92.5914 12.0073C92.5914 11.0313 92.153 10.4605 91.3834 10.4605C90.611 10.4605 90.1764 11.0313 90.1764 12.0073C90.1764 12.9912 90.611 13.5576 91.3834 13.5576C92.153 13.5576 92.5914 12.9873 92.5914 12.0073Z"
        fill="#84CFCB"
      />
      <path
        d="M94.6979 9.75782H95.5534V10.4731H95.6198C95.7324 10.2162 95.9224 10.0009 96.1633 9.85705C96.4042 9.71324 96.6839 9.64817 96.9635 9.6709C97.1826 9.65443 97.4026 9.68746 97.6072 9.76756C97.8118 9.84765 97.9958 9.97276 98.1454 10.1336C98.2951 10.2944 98.4067 10.4869 98.4719 10.6967C98.5371 10.9065 98.5543 11.1283 98.5221 11.3457V14.2607H97.6334V11.5689C97.6334 10.8452 97.319 10.4854 96.6618 10.4854C96.513 10.4784 96.3645 10.5037 96.2264 10.5596C96.0884 10.6154 95.964 10.7004 95.8619 10.8088C95.7598 10.9172 95.6823 11.0464 95.6348 11.1875C95.5872 11.3286 95.5708 11.4784 95.5866 11.6265V14.2607H94.6979V9.75782Z"
        fill="#84CFCB"
      />
      <path
        d="M103.544 8.63673V9.77833H104.519V10.5269H103.544V12.8423C103.544 13.314 103.738 13.5205 104.18 13.5205C104.294 13.5202 104.407 13.5133 104.519 13.5V14.2402C104.36 14.2688 104.198 14.284 104.036 14.2856C103.048 14.2856 102.654 13.938 102.654 13.0698V10.5268H101.939V9.77829H102.654V8.63673H103.544Z"
        fill="#84CFCB"
      />
      <path
        d="M105.733 8H106.614V10.4815H106.684C106.802 10.2221 106.998 10.0055 107.243 9.86119C107.489 9.71684 107.773 9.65176 108.057 9.67481C108.275 9.66295 108.493 9.69934 108.695 9.78138C108.898 9.86342 109.079 9.9891 109.227 10.1494C109.376 10.3098 109.486 10.5008 109.552 10.709C109.618 10.9171 109.637 11.1372 109.608 11.3535V14.2607H108.718V11.5728C108.718 10.8535 108.383 10.4893 107.756 10.4893C107.603 10.4767 107.449 10.4977 107.305 10.5508C107.162 10.6038 107.031 10.6877 106.923 10.7964C106.815 10.9051 106.732 11.0362 106.68 11.1803C106.628 11.3244 106.608 11.4782 106.622 11.6308V14.2607H105.733L105.733 8Z"
        fill="#84CFCB"
      />
      <path
        d="M114.79 13.0449C114.669 13.4565 114.407 13.8125 114.051 14.0506C113.694 14.2888 113.265 14.3939 112.839 14.3477C112.542 14.3555 112.247 14.2987 111.975 14.1811C111.702 14.0636 111.458 13.8882 111.26 13.6671C111.063 13.4459 110.915 13.1844 110.828 12.9005C110.742 12.6167 110.718 12.3174 110.758 12.0234C110.719 11.7286 110.743 11.4286 110.83 11.144C110.916 10.8593 111.063 10.5965 111.26 10.3734C111.457 10.1504 111.699 9.9722 111.971 9.85099C112.243 9.72979 112.537 9.66837 112.835 9.67091C114.088 9.67091 114.843 10.5269 114.843 11.9409V12.251H111.664V12.3008C111.65 12.466 111.671 12.6324 111.725 12.7891C111.779 12.9459 111.865 13.0896 111.978 13.211C112.091 13.3325 112.228 13.429 112.38 13.4944C112.533 13.5598 112.697 13.5926 112.863 13.5908C113.076 13.6163 113.291 13.578 113.482 13.4808C113.672 13.3836 113.83 13.2319 113.934 13.0449L114.79 13.0449ZM111.664 11.5938H113.938C113.949 11.4426 113.929 11.2908 113.878 11.1481C113.827 11.0054 113.747 10.8749 113.642 10.7651C113.538 10.6552 113.412 10.5684 113.272 10.5102C113.132 10.452 112.981 10.4238 112.83 10.4273C112.676 10.4253 112.523 10.4542 112.381 10.5121C112.239 10.5701 112.109 10.6559 112.001 10.7646C111.892 10.8734 111.806 11.0027 111.748 11.1451C111.69 11.2875 111.662 11.4401 111.664 11.5938Z"
        fill="#84CFCB"
      />
    </svg>
  );
};

export default IAppStore;
