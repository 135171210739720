import { IMAGE_TYPE } from "constants/common";

export const openDownloadedBlob = (blob: Blob, filename: string) => {
  // @ts-ignore
  if (window.navigator?.msSaveOrOpenBlob) {
    // @ts-ignore
    window.navigator?.msSaveOrOpenBlob(blob, filename);
  } else {
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

function download(blob: Blob, name: string) {
  // @ts-ignore
  if (window.navigator.msSaveBlob) {
    // @ts-ignore
    window.navigator?.msSaveOrOpenBlob(blob, name);
  } else {
    const uri = window.URL.createObjectURL(blob);
    downloadURI({ uri, name });
  }
}

type DownloadURIProps = { uri: string; name: string; target?: string };

export function downloadURI({ uri, name, target }: DownloadURIProps) {
  const anchorElement = document.createElement("a");
  anchorElement.download = name;
  anchorElement.href = uri;
  anchorElement.target = target ?? "";
  document.body.appendChild(anchorElement);
  anchorElement.click();
  anchorElement.remove();
}

function getFilename(contentDisposition: string) {
  const result = contentDisposition.split("filename=")[1];
  return result ?? "download";
}

type DownloadBlobProperties = {
  blob: Blob;
  contentDisposition: string;
};

export function downloadBlob({
  blob,
  contentDisposition,
}: DownloadBlobProperties) {
  const name = getFilename(contentDisposition);
  download(blob, name);
}

function base64toBlob(base64Data: string, contentType: string = "") {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

type DownloadBase64Properties = {
  base64: string;
  name: string;
  contentType?: string;
};

export function downloadBase64({
  base64,
  name,
  contentType,
}: DownloadBase64Properties) {
  const blob = base64toBlob(base64, contentType);
  download(blob, name);
}

export const getImageType = () => {
  return IMAGE_TYPE.ORIGINAL;
};
