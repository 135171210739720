import React from "react";

import I32ArrowRight from "assets/icons/I32ArrowRight";

import { Container, Left, Right, Subtitle, Title } from "./styles";

type Props = {
  subtitle?: string;
  title?: string;
  onClick?: () => void;
};

const Water = ({
  title = "Water",
  subtitle = "Calculate your daily water goal",
  onClick = () => {},
}: Props) => {
  return (
    <Container onClick={onClick}>
      <Left>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Left>
      <Right>
        <I32ArrowRight />
      </Right>
    </Container>
  );
};

export default Water;
