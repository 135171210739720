import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I32Arrow = ({ color = styleConst.colors.white }: Props) => (
  <svg width={32} height={32} fill="none" viewBox="0 0 32 32">
    <path
      fill={color}
      d="M23.586 17H5a1 1 0 010-2h18.586L19 10.414A1 1 0 0120.414 9l7 7-7 7A1 1 0 0119 21.586L23.586 17z"
    />
  </svg>
);

export default I32Arrow;
