import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24ArrowBack = ({ color = styleConst.colors.primary }: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      d="M7.4336 13.3912H19.1031C19.5984 13.3912 20 12.9922 20 12.4999C20 12.0077 19.5984 11.6087 19.1031 11.6087H7.43374L11.5466 7.52151C11.8969 7.17344 11.8969 6.60912 11.5466 6.26105C11.1964 5.91298 10.6285 5.91298 10.2782 6.26105L4 12.5L10.2782 18.739C10.6285 19.087 11.1964 19.087 11.5466 18.739C11.8969 18.3909 11.8969 17.8266 11.5466 17.4785L7.4336 13.3912Z"
      fill={color}
    />
  </svg>
);

export default I24ArrowBack;
