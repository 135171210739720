import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
`;

export const CollectionWrapper = styled.div`
  margin-bottom: 56px;
  display: flex;

  min-height: 250px;

  &:last-child {
    margin-bottom: 100px;
  }
`;

export const NoInternetConnectionErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
`;
