import * as React from "react";

type Props = any;

const I40Play = (props: Props) => {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM15.9228 28.1971C15.3846 27.9169 15.3846 27.0744 15.3846 25.3895V14.6105C15.3846 12.9256 15.3846 12.0831 15.9228 11.8029C16.461 11.5227 17.1511 12.0058 18.5315 12.9721L26.2307 18.3615L26.2308 18.3615C27.2953 19.1067 27.8276 19.4793 27.8276 20C27.8276 20.5207 27.2953 20.8933 26.2307 21.6385L18.5315 27.0279C17.1511 27.9942 16.461 28.4773 15.9228 28.1971Z"
        fill="white"
      />
    </svg>
  );
};

export default I40Play;
