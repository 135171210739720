import { applySnapshot, flow, types } from "mobx-state-tree";

import api from "services/api";

import { GENDER_TYPE } from "constants/profile";

import { getImageType } from "utils/files";
import { ignoreTimezone } from "utils/date";

const Contacts = types.model({
  email: types.optional(types.string, ""),
  phone: types.optional(types.string, ""),
  intagramHandle: types.optional(types.string, ""),
});

const Profile = types
  .model({
    accessProgram: types.maybe(types.string),
    name: types.maybe(types.string),
    gender: types.maybe(types.enumeration(Object.values(GENDER_TYPE))),
    dateOfBirth: types.maybe(types.string),
    location: types.maybe(types.string),
    image: types.maybe(types.string),
    contacts: types.optional(Contacts, {}),
    isLoading: types.optional(types.boolean, false),
    isUpdating: types.optional(types.boolean, false),
    isContactsLoading: types.optional(types.boolean, false),
    isPhotoLoading: types.optional(types.boolean, false),
    isPhotoManaging: types.optional(types.boolean, false),
  })
  .views(self => ({
    get email() {
      return self.contacts.email;
    },
  }))
  .actions(self => ({
    update: flow(function* (data: any) {
      try {
        self.isUpdating = true;
        const profile = yield api.put("/mobile/profile", data);
        applySnapshot(self, {
          ...self,
          ...profile,
          dateOfBirth: ignoreTimezone(profile.dateOfBirth),
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isUpdating = false;
      }
    }),

    fetchContacts: flow(function* () {
      try {
        self.isContactsLoading = true;
        const contacts = yield api.get("/mobile/profile/contact");
        applySnapshot(self, { ...self, contacts });
        return contacts;
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isContactsLoading = false;
      }
    }),

    updateContacts: flow(function* (data) {
      try {
        self.isContactsLoading = true;
        const contacts = yield api.put("/mobile/profile/contact", data);
        applySnapshot(self, { ...self, contacts });
        return contacts;
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isContactsLoading = false;
      }
    }),

    fetchPhoto: flow(function* () {
      try {
        self.isPhotoLoading = true;
        const { url } = yield api.get("/mobile/profile/photo", {
          imageType: getImageType(),
        });

        applySnapshot(self, { ...self, image: url });

        return url;
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        self.isPhotoLoading = false;
      }
    }),

    updatePhoto: flow(function* (file) {
      self.isPhotoManaging = true;
      try {
        const formData = new FormData();
        formData.append("file", file);

        const { url } = yield api.uploadFile(
          `/mobile/profile/photo/upload`,
          formData,
        );

        applySnapshot(self, { ...self, image: url });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isPhotoManaging = false;
      }
    }),

    deletePhoto: flow(function* () {
      self.isPhotoManaging = true;
      try {
        yield api.delete(`/mobile/profile/photo`);

        applySnapshot(self, { ...self, image: undefined });
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        self.isPhotoManaging = false;
      }
    }),
  }))
  .actions(self => ({
    fetch: flow(function* () {
      try {
        self.isLoading = true;

        const profile = yield api.get("/mobile/profile");
        const { name: accessProgram } = yield api.get(
          "/mobile/profile/active-program",
        );

        const image = yield self.fetchPhoto();
        applySnapshot(self, {
          ...self,
          ...profile,
          dateOfBirth: ignoreTimezone(profile.dateOfBirth),
          accessProgram,
          image,
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
  }));

const ProfileStore = Profile.create();

export default ProfileStore;
