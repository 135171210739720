import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface ICell {
  firstItem?: boolean;
  lastItem?: boolean;
  head?: boolean;
  autoWidth?: boolean;
}

interface IRow {
  head?: boolean;
  editMode?: boolean;
}

export const Cell = styled.div<ICell>`
  width: 120px;
  text-align: center;

  margin-left: 10px;

  ${({ head }) =>
    head
      ? css`
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          font-family: ${styleConst.fonts.roboto};
          color: ${styleConst.colors.black50};
        `
      : css`
          font-size: 16px;
          font-weight: 500;
          line-height: 18.75px;
          font-family: ${styleConst.fonts.roboto};
          color: ${styleConst.colors.dark};
        `};

  ${({ firstItem }) =>
    firstItem &&
    css`
      width: auto;
      flex: 3;
      text-align: left;
    `}

  ${({ lastItem }) =>
    lastItem &&
    css`
      width: 24px;
      margin-right: 24px;
    `}

  ${({ firstItem, head }) =>
    !head &&
    firstItem &&
    css`
      font-family: ${styleConst.fonts.roboto};
      color: ${styleConst.colors.text};
    `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const RadioContainer = styled.div.attrs({
  activeOpacity: 0.4,
})``;

export const Row = styled.div<IRow>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${({ head }) =>
    head
      ? css`
          background: rgba(15, 61, 63, 0.05);

          padding: 15px 22px;
        `
      : css`
          &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }

          padding: 31px 22px;
        `}

  ${({ editMode }) =>
    editMode &&
    css`
      padding-right: 64px;
      padding-left: 51px;
    `}

  ${({ editMode, head }) =>
    !editMode &&
    head &&
    css`
      border-radius: 10px;
    `}
`;
