import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { debounce } from "debounce";
import InfiniteScroll from "react-infinite-scroll-component";

import I24Add from "assets/icons/I24Add";
import I24ArrowBack from "assets/icons/I24ArrowBack";
import { Placeholders } from "assets/images/placeholders";

import FoodManualStore from "stores/FoodManualStore";
import FoodFavoritesStore from "stores/FoodFavoritesStore";

import FormTitle from "components/Form/FormTitle";
import ButtonInline from "components/ButtonInline";
import Placeholder from "components/Placeholder";
import Search from "components/Search";
import Spinner from "components/Spinner";
import { SnackbarTypeValues } from "components/Snackbar";
import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";

import SearchItem from "./SearchItem";
import FoodDetailsForm from "../FoodDetailsForm";
import { AddFoodSubmitPayloadType, ADD_RECIPE_TYPE } from "../AddFoodControl";

import styleConst from "constants/style";

import {
  BackButtonWrapper,
  Container,
  Row,
  ScrollLoader,
  SearchWrapper,
  ScrollWrapper,
  ScrollLoaderWrapper,
} from "./styles";

type Props = {
  onSubmit: (payload: AddFoodSubmitPayloadType) => void;
  submitIsLoading?: boolean;
} & SnackbarContextProps;

@observer
class AddManuallyForm extends React.Component<Props> {
  @observable formPage: number = 1;
  @observable page: number = 1;

  @observable isSearched: boolean = false;

  @computed get isLoading() {
    return FoodManualStore.isLoading;
  }

  @computed get isUpdating() {
    return FoodFavoritesStore.isUpdating;
  }

  @computed get needMore() {
    return FoodManualStore.needMore;
  }

  @computed get searchString() {
    return FoodManualStore.searchString;
  }

  @computed get searchResult() {
    return FoodManualStore.items;
  }

  componentWillUnmount() {
    this.onSearchClear();
  }

  goBack = () => {
    this.formPage--;
    FoodManualStore.resetDetails();
  };

  onSearchChange = (value: string) => {
    FoodManualStore.setSearchString(value);

    if (this.searchString.length > 2) {
      this.debouncedSearch();
    } else {
      this.isSearched = false;
    }
  };

  onSearchClear = () => {
    this.isSearched = false;
    FoodManualStore.resetResult();
  };

  onSearchApply = async () => {
    const { setMessage } = this.props;

    if (this.searchString.length < 3) {
      setMessage("Please specify minimum 3 character", SnackbarTypeValues.INFO);

      return;
    }

    this.page = 1;
    await FoodManualStore.search();
    this.isSearched = true;
  };

  debouncedSearch = debounce(this.onSearchApply, 1500);

  onLoadMore = () => {
    this.page++;
    FoodManualStore.search(this.page);
  };

  selectFood = (item: any) => () => {
    const { id } = item;

    this.formPage++;
    FoodManualStore.fetchDetails(id);
  };

  onSubmit = (values: any) => {
    const { onSubmit } = this.props;

    onSubmit({ values, addRecipeType: ADD_RECIPE_TYPE.DEFAULT });
  };

  onFavoriteClick = (item: any) => () => {
    const { isFavorite } = item;

    if (isFavorite) {
      this.removeFromFavorites(item);
    } else {
      this.addToFavorites(item);
    }
  };

  addToFavorites = async (item: any) => {
    const { setMessage } = this.props;

    try {
      await FoodFavoritesStore.addToFavorites(item);
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    }
  };

  removeFromFavorites = async (item: any) => {
    const { setMessage } = this.props;

    try {
      await FoodFavoritesStore.removeFromFavorites(item);
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    }
  };

  renderPlaceholder = () =>
    this.isSearched ? (
      <Row>
        <Placeholder
          imageUrl={Placeholders.NotFound}
          text={"No matches found. Please\ntry another search"}
        />
      </Row>
    ) : (
      <Row>
        <Placeholder
          imageUrl={Placeholders.Search}
          text="Your search results will be displayed here"
        />
      </Row>
    );

  render() {
    const { submitIsLoading } = this.props;

    return (
      <Container>
        {this.formPage === 1 && (
          <>
            <FormTitle title={"Add Manually"} icon={<I24Add />} />
            <Row>
              <SearchWrapper>
                <Search
                  initValue={this.searchString}
                  onChange={this.onSearchChange}
                  onClear={this.onSearchClear}
                  onApply={this.onSearchApply}
                />
              </SearchWrapper>
            </Row>

            {this.isSearched && this.searchResult.length ? (
              <ScrollWrapper id="scrollableDiv">
                <InfiniteScroll
                  scrollableTarget={"scrollableDiv"}
                  dataLength={this.searchResult.length}
                  hasMore={!!this.needMore}
                  next={this.onLoadMore}
                  loader={
                    <ScrollLoaderWrapper>
                      <Spinner color={styleConst.colors.primary} large />
                    </ScrollLoaderWrapper>
                  }
                >
                  {this.searchResult.map(item => (
                    <SearchItem
                      key={`${item.id}-${item.name}`}
                      title={item.name}
                      subtitle={item.brand}
                      isFavorite={item.isFavorite}
                      isLoading={FoodFavoritesStore.isUpdating}
                      onItemClick={this.selectFood(item)}
                      onControlClick={this.onFavoriteClick(item)}
                    />
                  ))}
                </InfiniteScroll>
              </ScrollWrapper>
            ) : !this.isLoading ? (
              this.renderPlaceholder()
            ) : (
              <Row>
                <ScrollLoader>
                  <Spinner color={styleConst.colors.primary} large />
                </ScrollLoader>
              </Row>
            )}
          </>
        )}

        {this.formPage === 2 && (
          <>
            <BackButtonWrapper>
              <ButtonInline
                text={"Back"}
                iconLeft={<I24ArrowBack />}
                onPress={this.goBack}
              />
            </BackButtonWrapper>
            <FoodDetailsForm
              onSubmit={this.onSubmit}
              submitIsLoading={submitIsLoading}
            />
          </>
        )}
      </Container>
    );
  }
}

export default withGlobalSnackbar(AddManuallyForm);
