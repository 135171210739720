import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";

import I24RecipeLibrary from "assets/icons/I24RecipeLibrary";
import I24MyItems from "assets/icons/I24MyItems";
import I24Add from "assets/icons/I24Add";
import I24Barcode from "assets/icons/I24Barcode";
import I24QuickAdd from "assets/icons/I24QuickAdd";

import FoodLogStore from "stores/FoodLogStore";

import { SnackbarTypeValues } from "components/Snackbar";
import MenuControl from "components/MenuControl";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";
import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";

import AddManuallyForm from "./AddManuallyForm";
import BarcodeForm from "./BarcodeForm";
import QuickAddForm from "./QuickAddForm";
import RecipeLibraryForm from "./RecipeLibraryForm";
import MyItemsForm from "./MyItemsForm";

import styleConst from "constants/style";

type Props = {
  activeDay: string;
  foodMealTypeId: number;
  element: React.ReactElement<any>;
  itemId?: string;
  onOpenCallback?: () => void;
  onCloseCallback?: () => void;
} & GlobalPopupProps &
  SnackbarContextProps;

export type AddFoodSubmitPayloadType = { values: any; addRecipeType: string };

export const ADD_RECIPE_TYPE = {
  DEFAULT: "default",
  RECIPE_LIBRARY: "recipeLibrary",
  MY_ITEMS_MY_RECIPES: "myItemsMyRecipes",
  QUICK_ADD: "quickAdd",
};

@observer
class AddFoodControl extends React.Component<Props> {
  @observable isMenuOpened: boolean = false;

  get isEditMode() {
    const { itemId } = this.props;

    return itemId;
  }

  hideMenu = () => {
    const { onCloseCallback } = this.props;

    this.isMenuOpened = false;
    onCloseCallback?.();
  };

  get menuItems() {
    return [
      {
        text: "Recipe Library",
        icon: <I24RecipeLibrary />,
        onClick: this.onRecipeLibraryAdd,
      },
      { text: "My Items", icon: <I24MyItems />, onClick: this.onMyItemsAdd },
      {
        text: "Add Manually",
        icon: <I24Add />,
        onClick: this.onAddManually,
      },
      {
        text: "Barcode",
        icon: <I24Barcode />,
        onClick: this.onBarcodeAdd,
      },
      { text: "Quick Add", icon: <I24QuickAdd />, onClick: this.onQuickAdd },
    ];
  }

  onRecipeLibraryAdd = () => {
    const { openPopup } = this.props;

    this.hideMenu();

    openPopup({
      key: "RecipeLibraryForm",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <RecipeLibraryForm
          onSubmit={this.addFood(close)}
          submitIsLoading={FoodLogStore.isLoading}
          onClose={close}
        />
      ),
    });
  };

  onMyItemsAdd = () => {
    const { openPopup } = this.props;

    this.hideMenu();

    openPopup({
      key: "MyItemsForm",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <MyItemsForm
          onSubmit={this.addFood(close)}
          submitIsLoading={FoodLogStore.isLoading}
          onClose={close}
        />
      ),
    });
  };

  onAddManually = () => {
    const { openPopup } = this.props;

    this.hideMenu();

    openPopup({
      key: "AddManuallyForm",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <AddManuallyForm
          onSubmit={this.addFood(close)}
          submitIsLoading={FoodLogStore.isLoading}
        />
      ),
    });
  };

  onBarcodeAdd = () => {
    const { openPopup } = this.props;

    this.hideMenu();

    openPopup({
      key: "BarcodeForm",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <BarcodeForm
          onSubmit={this.addFood(close)}
          submitIsLoading={FoodLogStore.isLoading}
        />
      ),
    });
  };

  onQuickAdd = () => {
    const { openPopup } = this.props;

    this.hideMenu();

    openPopup({
      key: "QuickAddForm",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <QuickAddForm
          onSubmit={this.addFood(close)}
          submitIsLoading={FoodLogStore.isLoading}
        />
      ),
    });
  };

  calculatePayload = (foodPayload: any, addRecipeType: string) => {
    const { foodMealTypeId } = this.props;

    switch (addRecipeType) {
      case ADD_RECIPE_TYPE.DEFAULT:
        return { foodMealTypeId, food: { ...foodPayload } };
      case ADD_RECIPE_TYPE.QUICK_ADD:
        return { foodMealTypeId, quickAddItem: { ...foodPayload } };
      case ADD_RECIPE_TYPE.RECIPE_LIBRARY:
        return {
          foodMealTypeId,
          foodRecipeId: foodPayload.id,
          amount: foodPayload.amount,
        };
      case ADD_RECIPE_TYPE.MY_ITEMS_MY_RECIPES:
        return {
          foodMealTypeId,
          foodCustomRecipeId: foodPayload.id,
          amount: foodPayload.amount,
        };
    }
  };

  addFood =
    (close: () => void) =>
    async ({ values, addRecipeType }: AddFoodSubmitPayloadType) => {
      const { activeDay, foodMealTypeId, itemId, setMessage } = this.props;

      const payload = this.calculatePayload(values, addRecipeType);

      if (!payload) {
        return;
      }

      try {
        await FoodLogStore.addToFoodLog(activeDay, payload);
        close();

        if (this.isEditMode) {
          await FoodLogStore.removeFromFoodLog(activeDay, {
            foodMealTypeId,
            id: itemId,
          });
        }

        FoodLogStore.fetch(activeDay);
      } catch (error: any) {
        setMessage(error?.message, SnackbarTypeValues.ALERT);
      }
    };

  render() {
    const { element, itemId, onOpenCallback, onCloseCallback } = this.props;

    return (
      <MenuControl
        element={element}
        onOpenCallback={onOpenCallback}
        onCloseCallback={onCloseCallback}
        menuItems={this.menuItems}
        itemId={itemId}
      />
    );
  }
}

export default withGlobalSnackbar(withGlobalPopup(AddFoodControl));
