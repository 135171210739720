import { applySnapshot, flow, types } from "mobx-state-tree";

import api from "services/api";

import { startOfWeek, parseISO, formatISO, addDays } from "date-fns";

import StudioContentStore from "stores/StudioContentStore";

import { Context } from "models";
import { Category } from "models/Studio";

import { STRUCTURE_TYPE } from "constants/common";
import { CATEGORY_LIST_PAGE_SIZE, CONTENT_STORE_TYPE } from "constants/studio";

import { getWeekDay } from "utils/common";

const Studio = types
  .model("Studio", {
    context: types.maybe(Context),
    items: types.array(Category),
    details: types.maybe(Category),
    isLoading: types.optional(types.boolean, false),
    isMoreLoading: types.optional(types.boolean, false),
    isDetailsLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    fillDetails() {
      const { details } = self;

      if (!details) {
        return;
      }

      if (details.structureType === STRUCTURE_TYPE.WEEK) {
        if (details.startDate) {
          const week: any = {};
          const start = startOfWeek(parseISO(details.startDate));

          [...Array(7).keys()].forEach(async count => {
            const date = addDays(start, count + 1);
            const key = getWeekDay(count);

            week[key] = StudioContentStore.create({
              type: CONTENT_STORE_TYPE.WEEK,
              dayOfWeek: count + 1,
              categoryId: details.id,
              date: formatISO(date),
            });
          });
          applySnapshot(self, {
            ...self,
            details: { ...details, week },
          });
        }
      } else {
        const standard = {
          favorites: StudioContentStore.create({
            type: CONTENT_STORE_TYPE.STANDARD_FAVORITES,
            categoryId: details.id,
          }),
          newest: StudioContentStore.create({
            type: CONTENT_STORE_TYPE.STANDARD_NEWEST,
            categoryId: details.id,
          }),
          all: StudioContentStore.create({
            type: CONTENT_STORE_TYPE.STANDARD_ALL,
            categoryId: details.id,
          }),
        };

        applySnapshot(self, {
          ...self,
          details: { ...details, standard },
        });
      }
    },
  }))
  .actions(self => ({
    fetch: flow(function* (
      page: number = 1,
      isFullAccessOnly: boolean = false,
    ) {
      try {
        if (page === 1) {
          self.isLoading = true;
        } else {
          self.isMoreLoading = true;
        }

        const { context, items } = yield api.get(
          "/mobile/workout/category/list",
          {
            size: CATEGORY_LIST_PAGE_SIZE,
            page,
            isFullAccessOnly,
          },
        );

        applySnapshot(self, {
          ...self,
          context,
          items: page === 1 ? items : self.items.concat(items),
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        if (page === 1) {
          self.isLoading = false;
        } else {
          self.isMoreLoading = false;
        }
      }
    }),

    fetchDetails: flow(function* (
      categoryId: number,
      isFullAccessOnly: boolean = false,
    ) {
      self.isDetailsLoading = true;

      try {
        const details = yield api.get(
          `/mobile/workout/category/${categoryId}`,
          {
            isFullAccessOnly,
          },
        );

        applySnapshot(self, { ...self, details });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isDetailsLoading = false;
      }
    }),

    reset() {
      applySnapshot(self, {});
    },

    resetDetails() {
      applySnapshot(self, { ...self, details: undefined });
    },
  }));

const StudioStore = Studio.create();

export default StudioStore;
