import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
  width?: number;
  height?: number;
};

const I24Chevron = ({
  color = styleConst.colors.white,
  width = 24,
  height = 24,
}: Props) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 24 24">
    <path
      fill={color}
      fillRule="evenodd"
      d="M8.879 18.707c-.39-.39-.39-1.024 0-1.414L14.172 12 8.879 6.707c-.39-.39-.39-1.024 0-1.414.39-.39 1.023-.39 1.414 0L17 12l-6.707 6.707c-.39.39-1.024.39-1.414 0z"
      clipRule="evenodd"
    />
  </svg>
);

export default I24Chevron;
