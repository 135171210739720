import styled from "styled-components";

import Button from "components/Button";

import styleConst from "constants/style";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 11px;
  margin-bottom: 120px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  gap: 115px;
`;

export const SubmitButton = styled(Button)`
  width: 200px;
  height: 50px;

  margin-left: 20px;

  border-radius: 10px;
  border: none;
  background: radial-gradient(
      83.3% 179.17% at 56.29% -31.25%,
      ${styleConst.colors.primaryMedium} 0%,
      ${styleConst.colors.primary} 100%
    ),
    ${styleConst.colors.primary};
  box-shadow: 0px 20px 40px -10px ${styleConst.colors.shadow};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  &:first-child {
    width: 480px;
  }
  width: 582px;
`;

export const Title = styled.div`
  font-size: 44px;
  font-family: ${styleConst.fonts.montserrat};
  font-weight: 600;
  line-height: 48px;
  color: ${styleConst.colors.dark};

  padding-bottom: 41px;
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${styleConst.colors.white60};

  z-index: 20;
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 100px;
`;

export const SelectedDayTitle = styled.div`
  font-size: 26px;
  font-weight: 600;
  font-family: ${styleConst.fonts.montserrat};
  line-height: 48px;
  color: ${styleConst.colors.dark};

  padding: 0px 10px 0 14px;
  margin-bottom: 29px;

  border-left: 4px solid ${styleConst.colors.primary};
`;

export const PlaceholderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 627px;

  background: ${styleConst.colors.dark5};
  border-radius: 10px;

  margin-top: -9px;
`;
