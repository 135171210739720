import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  addDays,
  format,
  isSameMonth,
  isSameWeek,
  startOfDay,
  startOfWeek,
} from "date-fns";

import I24Copy from "assets/icons/I24Copy";
import I24Move from "assets/icons/I24Move";
import I24Remove from "assets/icons/I24Remove";

import FoodLogStore from "stores/FoodLogStore";

import Button from "components/Button";
import { Page } from "components/Layout";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";
import SuggestPopup from "components/SuggestPopup";
import SelectDatePopup from "components/SelectDatePopup";
import WaterCalculatorPopup from "components/WaterCalculatorPopup";
import RadioButtonList from "components/RadioButtonList";
import AlertPopup from "components/AlertPopup";
import ConfirmPopup from "components/ConfirmPopup";
import AddWaterPopup from "components/AddWaterPopup";
import ServingsPopup from "components/ServingsPopup";
import Checkbox from "components/Checkbox";
import ButtonInline from "components/ButtonInline";
import GreenBarWater from "components/GreenBars/Water";
import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";
import { SnackbarTypeValues } from "components/Snackbar";
import MacroGoals from "components/GreenBars/MacroGoals";
import DaySelect from "components/DaySelect";

import Water from "./Water";
import MealDetails from "./MealDetails";

import PageSelector from "../PageSelector";

import { NoInternetConnectionError } from "services/api";

import { SERVER_DATE_FORMAT } from "utils/date";

import { ACTION_DAY_MEALS_TYPES } from "constants/nutrition";

import styleConst from "constants/style";

import {
  Container,
  DaySelectContainer,
  GreenBarsContainer,
  GreenBarWaterWrapper,
  GreenBarMacroGoals,
  GuideTypeContainer,
  SuggestMealsButtonContainer,
  MealOptionsWrapper,
  CheckboxWrapper,
  TitleWrapper,
  Controls,
  Label,
  ButtonInlineWrapper,
} from "./styles";

const TOKEN = { SUGGEST: "foodLogLastWeeklySuggest" };

type Props = {} & RouteComponentProps & GlobalPopupProps & SnackbarContextProps;

@observer
class FoodLog extends React.Component<Props> {
  @observable isLoading: boolean = false;
  @observable activeDay: Date = startOfDay(new Date());
  @observable checkedMeals: any = {};

  @observable selectedWeekGuideType?: string;
  @observable selectedWeekGuideId?: number;

  @observable selectedDayGuideType?: string;
  @observable selectedDayGuideId?: number;

  @observable selectedGuideType?: string;
  @observable selectedGuideId?: number;

  @observable isNoInternetConnectionError: boolean = false;

  @computed get isSelectAllChecked() {
    return this.checkedIds.length === this.countMeals;
  }

  @computed get checkedIds() {
    let ids: number[] = [];
    for (const key in this.checkedMeals) {
      ids = [...ids, ...this.checkedMeals[key]];
    }
    return ids;
  }

  @computed get week() {
    const start = startOfWeek(this.activeDay, { weekStartsOn: 1 });
    return [...Array(7).keys()].map(count => addDays(start, count));
  }

  @computed get formattedActiveDay() {
    return format(startOfDay(this.activeDay), SERVER_DATE_FORMAT);
  }

  @computed get nutritionGoal() {
    return this.summary?.macronutrients;
  }

  @computed get summary() {
    return FoodLogStore.summary;
  }

  @computed get foodDayType() {
    return this.summary?.foodDayType.name;
  }

  @computed get water() {
    return this.summary?.water;
  }

  @computed get meals() {
    return FoodLogStore.items;
  }

  @computed get countMeals() {
    return this.meals.reduce((result, meal) => result + meal.items.length, 0);
  }

  @computed get startOfWeek() {
    return this.week[0];
  }

  @computed get endOfWeek() {
    return this.week[this.week.length - 1];
  }

  async componentDidMount() {
    this.isLoading = true;
    await this.fetch();
    this.isLoading = false;
    if (FoodLogStore.isLoaded) {
      this.checkWeeklySuggest();
    }
  }

  checkWeeklySuggest = async () => {
    const now = new Date();

    const formattedStart = format(this.startOfWeek, SERVER_DATE_FORMAT);
    const formattedEnd = format(this.endOfWeek, SERVER_DATE_FORMAT);

    const lastWeeklySuggest: number = Number(
      window.localStorage.getItem(TOKEN.SUGGEST),
    );

    if (!lastWeeklySuggest || !isSameWeek(new Date(lastWeeklySuggest), now)) {
      const isNeedToShow = await FoodLogStore.checkWeeklySuggest(
        formattedStart,
        formattedEnd,
      );
      if (isNeedToShow) {
        this.showWeeklySuggestAlert();
      }
    }
  };

  onDeclineSuggestInRangePress = async () => {
    window.localStorage.setItem(
      TOKEN.SUGGEST,
      JSON.stringify(new Date().getTime()),
    );
  };

  showWeeklySuggestAlert = async () => {
    try {
      const formattedStart = format(this.startOfWeek, "MMMM dd");
      const formattedEnd = isSameMonth(this.startOfWeek, this.endOfWeek)
        ? format(this.endOfWeek, "dd")
        : format(this.endOfWeek, "MMMM dd");

      const range = `${formattedStart}\u00A0-\u00A0${formattedEnd}`;

      const guides = await FoodLogStore.getAvailableSuggestCategories(
        this.formattedActiveDay,
      );

      if (!guides.length) return;

      this.onSelectWeekSuggestType({
        type: guides[0].foodCategoryGuideType,
        value: guides[0].id,
      });

      const { openPopup, setMessage } = this.props;

      openPopup({
        key: "SuggestPopup",
        render: ({ close }) => (
          <SuggestPopup
            title={`Would you like to prefill the Food Log for ${range} with suggestions from the FASTer Way meal guide?`}
            onAccept={async () => {
              try {
                await this.onSuggestInRangePress();
                close();
              } catch (error: any) {
                setMessage(error?.message, SnackbarTypeValues.ALERT);
              }
            }}
            onClose={() => {
              this.onDeclineSuggestInRangePress();
              close();
            }}
          >
            <GuideTypeContainer numberOfButtons={guides.length}>
              <RadioButtonList
                options={guides.map((item: any) => ({
                  label: item.name,
                  value: item.id,
                  type: item.foodCategoryGuideType,
                }))}
                selected={this.selectedWeekGuideId?.toString()}
                onSelect={this.onSelectWeekSuggestType}
                numberOfLabelLines={3}
                showIndicator
                modal
              />
            </GuideTypeContainer>
          </SuggestPopup>
        ),
      });
    } catch (error) {
      this.showNoMealAvailableAlert();
    }
  };

  onSuggestInRangePress = async () => {
    if (!this.selectedWeekGuideType) return;

    const formattedStart = format(this.startOfWeek, SERVER_DATE_FORMAT);
    const formattedEnd = format(this.endOfWeek, SERVER_DATE_FORMAT);

    await FoodLogStore.suggestInRange(
      formattedStart,
      formattedEnd,
      this.selectedWeekGuideType,
      this.selectedWeekGuideId,
    );
    await FoodLogStore.fetch(this.formattedActiveDay);
    window.localStorage.setItem(
      TOKEN.SUGGEST,
      JSON.stringify(new Date().getTime()),
    );
  };

  onSelectWeekSuggestType = ({ value, type }: any) => {
    this.selectedWeekGuideType = type;
    this.selectedWeekGuideId = value;
  };

  onDayChange = async (day: Date) => {
    this.checkedMeals = {};
    this.activeDay = day;
    await this.fetch();
  };

  fetch = async () => {
    try {
      this.checkedMeals = {};
      await FoodLogStore.fetch(this.formattedActiveDay);
      await FoodLogStore.getAvailableMealSuggestions(this.formattedActiveDay);
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }
  };

  resetWaterGoal = async () => {
    const { setMessage } = this.props;
    try {
      await FoodLogStore.resetWaterGoal();
      await FoodLogStore.fetch(this.formattedActiveDay);
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    }
  };

  onWaterResetPress = () => {
    const { openPopup } = this.props;

    openPopup({
      key: "ConfirmPopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <ConfirmPopup
          onClose={close}
          onAccept={async () => {
            await this.resetWaterGoal();
            close();
          }}
          title={`Are you sure you want to recalculate your water goal?`}
        />
      ),
    });
  };

  onManageWaterPress = (editMode?: boolean) => () => {
    const { openPopup } = this.props;

    openPopup({
      key: "AddWaterPopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <AddWaterPopup
          activeDay={this.formattedActiveDay}
          editMode={!!editMode}
          onClose={close}
        />
      ),
    });
  };

  onAddFoodPress = () => () => {};

  onServings = (amount: number = 0, params: any) => {
    const { openPopup } = this.props;

    openPopup({
      key: "ServingsPopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <ServingsPopup
          activeDay={this.formattedActiveDay}
          onClose={close}
          numberOfServingsValue={amount}
          params={params}
        />
      ),
    });
  };

  onMealActionPress =
    (meal: any) =>
    (action: "remove" | "change" | "servings") =>
    async (params: { foodMealTypeId: number; id: string }) => {
      const { setMessage, openPopup } = this.props;

      if (action === "remove") {
        openPopup({
          key: "ConfirmPopup",
          exitButtonColor: styleConst.colors.black25,
          render: ({ close }) => (
            <ConfirmPopup
              onClose={close}
              onAccept={async () => {
                try {
                  await FoodLogStore.removeFromFoodLog(
                    this.formattedActiveDay,
                    params,
                  );
                  await this.fetch();
                  close();
                } catch (error: any) {
                  setMessage(error?.message, SnackbarTypeValues.ALERT);
                }
              }}
              title={"Are you sure you want to remove selected meal?"}
            />
          ),
        });
      }
      if (action === "change") {
        this.onAddFoodPress()();
      }
      if (action === "servings") {
        const item = meal.items.find((item: any) => item.id === params.id);
        const amount = item?.isFood ? item?.serving?.amount : item?.amount;

        this.onServings(amount, params);
      }
    };

  onSelectSuggestType = ({ value, type }: any) => {
    this.selectedGuideType = type;
    this.selectedGuideId = value;
  };

  onSuggestPress = async (foodMealTypeId: number) => {
    if (!this.selectedGuideType) return;

    const isLogged = await FoodLogStore.suggest(
      this.formattedActiveDay,
      this.selectedGuideType,
      this.selectedGuideId,
      foodMealTypeId,
    );

    if (!isLogged) {
      const { openPopup } = this.props;

      openPopup({
        key: "AlertPopup",
        render: ({ close }) => (
          <AlertPopup
            onClose={close}
            title={"Sorry"}
            description={"No meal available"}
          />
        ),
      });
    } else {
      await FoodLogStore.fetch(this.formattedActiveDay);
    }
  };

  showSuggestPopup = async (foodMealTypeId: number) => {
    try {
      const guides = await FoodLogStore.getAvailableSuggestCategories(
        this.formattedActiveDay,
      );

      this.onSelectSuggestType({
        type: guides[0].foodCategoryGuideType,
        value: guides[0].id,
      });

      const { openPopup, setMessage } = this.props;

      openPopup({
        key: "SuggestPopup",
        render: ({ close }) => (
          <SuggestPopup
            onClose={close}
            title={`Select a meal guide`}
            onAccept={async () => {
              try {
                await this.onSuggestPress(foodMealTypeId);
                close();
              } catch (error: any) {
                setMessage(error?.message, SnackbarTypeValues.ALERT);
              }
            }}
          >
            <GuideTypeContainer numberOfButtons={guides.length}>
              <RadioButtonList
                options={guides.map((item: any) => ({
                  label: item.name,
                  value: item.id,
                  type: item.foodCategoryGuideType,
                }))}
                selected={this.selectedGuideId?.toString()}
                onSelect={this.onSelectSuggestType}
                numberOfLabelLines={3}
                showIndicator
                modal
              />
            </GuideTypeContainer>
          </SuggestPopup>
        ),
      });
    } catch (error) {
      this.showNoMealAvailableAlert();
    }
  };

  onSelectDaySuggestType = ({ value, type }: any) => {
    this.selectedDayGuideType = type;
    this.selectedDayGuideId = value;
  };

  showNoMealAvailableAlert = () => {
    const { setMessage } = this.props;
    setMessage("Sorry, no meal guides available", SnackbarTypeValues.ALERT);
  };

  onSuggestDayPress = async () => {
    if (!this.selectedDayGuideType) return;

    await FoodLogStore.suggest(
      this.formattedActiveDay,
      this.selectedDayGuideType,
      this.selectedDayGuideId,
    );
    await FoodLogStore.fetch(this.formattedActiveDay);
  };

  showDaySuggestPopup = async () => {
    try {
      const formattedDay = format(this.activeDay, "MMMM, dd");
      const guides = await FoodLogStore.getAvailableSuggestCategories(
        this.formattedActiveDay,
      );

      this.onSelectDaySuggestType({
        type: guides[0].foodCategoryGuideType,
        value: guides[0].id,
      });

      const { openPopup, setMessage } = this.props;

      openPopup({
        key: "SuggestPopup",
        render: ({ close }) => (
          <SuggestPopup
            onClose={close}
            title={`Would you like to prefill the Food Log for ${formattedDay} with suggestions from any of the FASTer Way meal guides?`}
            onAccept={async () => {
              try {
                await this.onSuggestDayPress();
                close();
              } catch (error: any) {
                setMessage(error?.message, SnackbarTypeValues.ALERT);
              }
            }}
          >
            <GuideTypeContainer numberOfButtons={guides.length}>
              <RadioButtonList
                options={guides.map((item: any) => ({
                  label: item.name,
                  value: item.id,
                  type: item.foodCategoryGuideType,
                }))}
                selected={this.selectedDayGuideId?.toString()}
                onSelect={this.onSelectDaySuggestType}
                numberOfLabelLines={3}
                showIndicator
                modal
              />
            </GuideTypeContainer>
          </SuggestPopup>
        ),
      });
    } catch (error) {
      this.showNoMealAvailableAlert();
    }
  };

  onChangeSelectAll = (value: boolean) => {
    this.checkedMeals = {};
    if (value) {
      this.meals.forEach(meal => {
        const ids: number[] = [];
        meal.items.forEach(item => {
          ids.push(item.id);
        });
        this.checkedMeals[meal.foodMealType.id] = ids;
      });
    }
  };

  onChangeCheckedMealType = (value: boolean, foodMealTypeId: number) => {
    const checkedMeals: number[] = [];
    if (value) {
      const meal = this.meals.find(
        meal => meal.foodMealType.id === foodMealTypeId,
      );
      if (meal) {
        meal.items.forEach(item => {
          checkedMeals.push(item.id);
        });
      }
    }
    this.checkedMeals[foodMealTypeId] = checkedMeals;
  };

  onChangeCheckedMeal = (
    value: boolean,
    id: number,
    foodMealTypeId: number,
  ) => {
    const checkedMeals: number[] = this.checkedMeals[foodMealTypeId] || [];
    if (value) {
      checkedMeals.push(id);
    } else {
      const index = checkedMeals.indexOf(id);
      checkedMeals.splice(index, 1);
    }
    this.checkedMeals[foodMealTypeId] = checkedMeals;
  };

  showSelectDatePopup = (actionType: string) => {
    const { openPopup } = this.props;

    openPopup({
      key: "SelectDatePopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <SelectDatePopup
          onClose={close}
          ids={this.checkedIds}
          activeDay={this.activeDay}
          fetch={this.fetch}
          actionType={actionType}
        />
      ),
    });
  };

  onResetMealsSubmit = async () => {
    const { setMessage } = this.props;
    try {
      await FoodLogStore.removeSelectedFromFoodLog(
        this.checkedIds,
        this.formattedActiveDay,
      );
      await this.fetch();
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    }
  };

  onRemovePress = () => {
    const { openPopup } = this.props;
    const date = format(startOfDay(this.activeDay), "MMMM d");

    openPopup({
      key: "ConfirmPopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <ConfirmPopup
          onClose={close}
          onAccept={async () => {
            await this.onResetMealsSubmit();
            close();
          }}
          title={`Are you sure you want to remove selected meals for ${date}?`}
        />
      ),
    });
  };

  onWaterCalculatorPress = () => {
    const { openPopup } = this.props;

    openPopup({
      key: "WaterCalculatorPopup",
      exitButtonColor: styleConst.colors.black25,
      onClose: async () => {
        await FoodLogStore.fetch(this.formattedActiveDay);
      },
      render: ({ close }) => <WaterCalculatorPopup onClose={close} />,
    });
  };

  render() {
    return (
      <Page
        isNoInternetConnectionError={this.isNoInternetConnectionError}
        title={"Nutrition"}
        titleControls={<PageSelector />}
        isLoading={this.isLoading}
        titleControlsFullWidth
      >
        <Container>
          <DaySelectContainer>
            <DaySelect
              active={this.activeDay}
              week={this.week}
              onDayChange={this.onDayChange}
            />
          </DaySelectContainer>
          <GreenBarsContainer>
            <GreenBarMacroGoals>
              <MacroGoals
                foodDayType={this.foodDayType}
                nutritionGoal={this.nutritionGoal}
              ></MacroGoals>
            </GreenBarMacroGoals>
            {this.water?.goal ? (
              <GreenBarWaterWrapper>
                <Water
                  amount={this.water.amount}
                  goal={this.water.goal}
                  unit={this.water.unit}
                  onReset={this.onWaterResetPress}
                  onEdit={this.onManageWaterPress(true)}
                  onAdd={this.onManageWaterPress()}
                />
              </GreenBarWaterWrapper>
            ) : (
              <GreenBarWaterWrapper>
                <GreenBarWater onClick={this.onWaterCalculatorPress} />
              </GreenBarWaterWrapper>
            )}
          </GreenBarsContainer>
          <MealOptionsWrapper>
            <CheckboxWrapper>
              {this.countMeals > 0 ? (
                <Checkbox
                  checked={this.isSelectAllChecked}
                  toggleChecked={this.onChangeSelectAll}
                />
              ) : null}
            </CheckboxWrapper>
            <TitleWrapper>
              {this.countMeals > 0 ? <Label>Select All</Label> : null}
            </TitleWrapper>
            <Controls>
              {this.countMeals > 0 ? (
                <>
                  <ButtonInlineWrapper>
                    <ButtonInline
                      onPress={() =>
                        this.showSelectDatePopup(ACTION_DAY_MEALS_TYPES.COPY)
                      }
                      text={"Copy"}
                      fontSize={14}
                      fontWeight={400}
                      color={styleConst.colors.black50}
                      iconLeft={<I24Copy color={styleConst.colors.black50} />}
                      disabled={!this.checkedIds.length}
                    />
                  </ButtonInlineWrapper>
                  <ButtonInlineWrapper>
                    <ButtonInline
                      onPress={() =>
                        this.showSelectDatePopup(ACTION_DAY_MEALS_TYPES.MOVE)
                      }
                      text={"Move"}
                      fontSize={14}
                      fontWeight={400}
                      color={styleConst.colors.black50}
                      iconLeft={<I24Move color={styleConst.colors.black50} />}
                      disabled={!this.checkedIds.length}
                    />
                  </ButtonInlineWrapper>
                  <ButtonInlineWrapper>
                    <ButtonInline
                      onPress={this.onRemovePress}
                      text={"Remove"}
                      fontSize={14}
                      fontWeight={400}
                      color={styleConst.colors.black50}
                      iconLeft={<I24Remove color={styleConst.colors.black50} />}
                      disabled={!this.checkedIds.length}
                    />
                  </ButtonInlineWrapper>
                </>
              ) : (
                <SuggestMealsButtonContainer>
                  <Button
                    text={"Suggest Meals"}
                    onClick={this.showDaySuggestPopup}
                    loading={this.isLoading}
                    fontSize={14}
                    fontFamily={styleConst.fonts.roboto}
                    fontWeight={600}
                    height={26}
                  />
                </SuggestMealsButtonContainer>
              )}
            </Controls>
          </MealOptionsWrapper>

          {this.meals.map((meal, index) => (
            <MealDetails
              key={`${meal.foodMealType.name}_${index}`}
              activeDay={this.formattedActiveDay}
              meal={meal}
              onChange={this.onMealActionPress(meal)("change")}
              onRemove={this.onMealActionPress(meal)("remove")}
              onServings={this.onMealActionPress(meal)("servings")}
              onSuggestPress={this.showSuggestPopup}
              onAddFoodPress={this.onAddFoodPress()}
              onChangeCheckedMealType={this.onChangeCheckedMealType}
              onChangeCheckedMeal={this.onChangeCheckedMeal}
              checkedMeals={this.checkedMeals[meal.foodMealType.id] || []}
            />
          ))}
        </Container>
      </Page>
    );
  }
}

export default withGlobalSnackbar(withGlobalPopup(withRouter(FoodLog)));
