import React from "react";

import styleConst from "constants/style";

type Props = {
    color?: string;
}

const I24Check = ({color = styleConst.colors.white}: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 12C20 13.5823 19.5308 15.129 18.6517 16.4446C17.7727 17.7602 16.5233 18.7856 15.0615 19.3911C13.5997 19.9966 11.9911 20.155 10.4393 19.8463C8.88743 19.5376 7.46197 18.7757 6.34315 17.6569C5.22433 16.538 4.46241 15.1126 4.15373 13.5607C3.84505 12.0089 4.00346 10.4003 4.60896 8.93854C5.21446 7.47673 6.23984 6.22729 7.55543 5.34824C8.87103 4.46918 10.4178 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34314C19.1572 7.84343 20 9.87827 20 12ZM22 12C22 13.9778 21.4135 15.9112 20.3147 17.5557C19.2159 19.2002 17.6541 20.4819 15.8268 21.2388C13.9996 21.9957 11.9889 22.1937 10.0491 21.8079C8.10931 21.422 6.32746 20.4696 4.92894 19.0711C3.53041 17.6726 2.578 15.8907 2.19215 13.9509C1.8063 12.0111 2.00434 10.0004 2.76121 8.17316C3.51809 6.3459 4.7998 4.78412 6.44429 3.6853C8.08878 2.58649 10.0222 2 12 2C14.6522 2 17.1957 3.05356 19.0711 4.92892C20.9464 6.80429 22 9.34784 22 12ZM16.21 9.29001C16.3963 9.47737 16.5008 9.73081 16.5008 9.995C16.5008 10.2592 16.3963 10.5126 16.21 10.7L11.54 15.37C11.3526 15.5562 11.0992 15.6608 10.835 15.6608C10.5708 15.6608 10.3174 15.5562 10.13 15.37L7.8 13.04C7.70627 12.947 7.63187 12.8364 7.5811 12.7146C7.53033 12.5927 7.50419 12.462 7.50419 12.33C7.50419 12.198 7.53033 12.0673 7.5811 11.9454C7.63187 11.8236 7.70627 11.713 7.8 11.62C7.98736 11.4337 8.24083 11.3292 8.50502 11.3292C8.7692 11.3292 9.02264 11.4337 9.21 11.62L10.84 13.25L14.84 9.25C15.0305 9.08094 15.2783 8.99095 15.5329 8.99838C15.7875 9.00582 16.0297 9.11013 16.21 9.29001Z"
      fill={color}
    />
  </svg>
);

export default I24Check;
