import React from "react";

import { Container } from "./styles";

type Props = {
  icon: React.ReactNode;
  className?: any;
  color?: string;
  hoverColor?: string;
};

const Icon = ({ icon, color, hoverColor, className }: Props) => (
  <Container className={className} color={color} hoverColor={hoverColor}>
    {icon}
  </Container>
);

export default Icon;
