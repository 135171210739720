import React from "react";
import {
  components,
  Options,
  OptionProps,
  ControlProps,
  MenuPosition,
  GroupBase,
  SingleValueProps,
  Props as DefaultSelectProps,
  DropdownIndicatorProps,
} from "react-select";
import { observer } from "mobx-react";
import { computed } from "mobx";
import { FieldInputProps } from "react-final-form";

import I24Dropdown from "assets/icons/I24Dropdown";

import TrimmedText from "components/TrimmedText";

import { isObject } from "utils/common";

import {
  Select,
  Container,
  ErrorText,
  Wrapper,
  Label,
  pageSelectorStyles,
  defaultSelectstyles,
} from "./styles";

export type DictionaryItem = {
  value: any;
  label: string;
};

export type FilterOptionsType = { data: any; label: string; value: any };

export type DropdownExternalProps = {
  options?: Options<DictionaryItem>;
  disabled?: boolean;
  searchable?: boolean;
  loading?: boolean;
  placeholder?: string;
  value: DictionaryItem;
  label?: string;
  hideIndicator?: boolean;
  filterOption?: (options: FilterOptionsType, search: string) => boolean;
  hideSelectedOptions?: boolean;
  menuPosition?: MenuPosition;
  isClearable?: boolean;
  maxMenuHeight?: number;
  required?: boolean;
  withoutErrorText?: boolean;
  pageSelector?: boolean;
};

type Props = {
  onChange: (item: DictionaryItem) => void;
  error?: string | Object;
  input?: FieldInputProps<string | any>;
} & DropdownExternalProps;

const OptionDefaultComp = (
  props: OptionProps<DictionaryItem, false, GroupBase<DictionaryItem>>,
) => {
  const { Option } = components;

  const label = props?.data?.label ?? "";

  return (
    <Option {...props}>
      <TrimmedText text={label} lines={1} duration={50}>
        {label}
      </TrimmedText>
    </Option>
  );
};

const Control =
  (hasError?: boolean, label?: string) =>
  // eslint-disable-next-line
  (props: ControlProps<DictionaryItem, false, GroupBase<DictionaryItem>>) => {
    const { Control } = components;

    return (
      <>
        <Label
          isFloating={props.isFocused || props.hasValue}
          hasValue={props.hasValue && !props.isFocused}
          hasError={hasError}
          disabled={props.isDisabled}
        >
          {label}
        </Label>
        <Control {...props} />
      </>
    );
  };

const SingleValue = (
  props: SingleValueProps<DictionaryItem, false, GroupBase<DictionaryItem>>,
) => {
  const { SingleValue } = components;
  const {
    data: { label },
  } = props;

  return (
    <SingleValue {...props}>
      <TrimmedText text={label} lines={1}>
        {label}
      </TrimmedText>
    </SingleValue>
  );
};

const DropdownIndicatorCustom = (
  props: DropdownIndicatorProps<
    DictionaryItem,
    false,
    GroupBase<DictionaryItem>
  >,
) => {
  const { DropdownIndicator } = components;

  return (
    <DropdownIndicator {...props}>
      <I24Dropdown />
    </DropdownIndicator>
  );
};

@observer
class Dropdown extends React.Component<
  Props & DefaultSelectProps<DictionaryItem, false, GroupBase<DictionaryItem>>
> {
  @computed
  get value() {
    const { options, input, value } = this.props;
    return (
      options?.find(item =>
        isObject(item.value)
          ? item.value.id === input?.value?.id
          : item.value === input?.value,
      ) ||
      input?.value ||
      value
    );
  }

  get optionComponent() {
    return OptionDefaultComp;
  }

  get controlComponent() {
    const { label, required } = this.props;

    const labelText = required ? `${label} *` : label;

    return Control(!!this.error, labelText);
  }

  @computed
  get components() {
    const { hideIndicator, pageSelector } = this.props;
    const { DropdownIndicator } = components;

    const selectComponents = {
      Option: OptionDefaultComp,
      Control: this.controlComponent,
      SingleValue,
      DropdownIndicator: !pageSelector
        ? DropdownIndicator
        : DropdownIndicatorCustom,
    };
    const withoutIndicator = {
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
    };
    return hideIndicator
      ? { ...selectComponents, ...withoutIndicator }
      : selectComponents;
  }

  get error() {
    const { error } = this.props;

    if (!error) {
      return undefined;
    }

    return isObject(error) ? Object.values(error).join("") : error;
  }

  onChange = (newValue: any) => {
    this.props.onChange(newValue);
  };

  getOptionValue = (option: any) => {
    return option?.value && isObject(option.value)
      ? option?.value?.id
      : option.value;
  };

  render() {
    const {
      disabled,
      searchable,
      loading,
      placeholder,
      input,
      options,
      filterOption,
      hideSelectedOptions,
      maxMenuHeight,
      menuPosition,
      isClearable = false,
      withoutErrorText,
      pageSelector,
    } = this.props;

    return (
      <>
        <Container>
          <Wrapper>
            <Select
              {...input}
              key={this.value}
              value={this.value}
              options={options}
              onChange={this.onChange}
              isDisabled={loading || disabled}
              isSearchable={searchable}
              isLoading={loading}
              placeholder={placeholder}
              menuPosition={menuPosition || "fixed"}
              filterOption={filterOption}
              hideSelectedOptions={hideSelectedOptions}
              isClearable={isClearable}
              getOptionValue={this.getOptionValue}
              maxMenuHeight={maxMenuHeight}
              styles={!pageSelector ? defaultSelectstyles : pageSelectorStyles}
              blurInputOnSelect
              /* @ts-ignore */
              components={this.components}
              error={this.error}
            />
          </Wrapper>
          {!withoutErrorText && this.error && (
            <ErrorText>{this.error}</ErrorText>
          )}
        </Container>
      </>
    );
  }
}

export default Dropdown;
