import styled from "styled-components";

export const Container = styled.div`
  &:last-child {
    margin-bottom: 20px;
  }
`;

export const ScrollLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding-bottom: 20px;
`;
