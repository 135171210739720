import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IButton {
  backgroundColor?: string;
}

export const Button = styled.button<IButton>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  border-radius: 50%;

  min-height: 40px;
  min-width: 40px;
  padding: 0;

  background: ${styleConst.colors.dark5};
  border: none;
  outline: none;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:not(:disabled):hover {
    background: ${styleConst.colors.dark5};
  }

  &:not(:disabled):active {
    background: ${styleConst.colors.dark5};
  }

  &:disabled {
    path,
    use {
      fill: ${styleConst.colors.black25};
    }
  }

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background: ${backgroundColor};

      &:not(:disabled):hover {
        background: ${backgroundColor};
      }

      &:not(:disabled):active {
        background: ${backgroundColor};
      }
    `}
`;
