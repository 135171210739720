import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24RecipeLibrary = ({ color = styleConst.colors.black50 }: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      d="M14.2929 7.29289C14.6834 6.90237 15.3166 6.90237 15.7071 7.29289C16.0976 7.68342 16.0976 8.31658 15.7071 8.70711L12.4142 12C11.6332 12.781 10.3668 12.781 9.58579 12L8.29289 10.7071C7.90237 10.3166 7.90237 9.68342 8.29289 9.29289C8.68342 8.90237 9.31658 8.90237 9.70711 9.29289L11 10.5858L14.2929 7.29289Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 5C3 2.79086 4.79086 1 7 1H16C18.2091 1 20 2.79086 20 5V15C20 17.2091 18.2091 19 16 19H6C5.44772 19 5 19.4477 5 20C5 20.5523 5.44772 21 6 21H19C19.5523 21 20 21.4477 20 22C20 22.5523 19.5523 23 19 23H6C4.34315 23 3 21.6569 3 20V5ZM18 5V15C18 16.1046 17.1046 17 16 17H6C5.64936 17 5.31278 17.0602 5 17.1707V5C5 3.89543 5.89543 3 7 3H16C17.1046 3 18 3.89543 18 5Z"
      fill={color}
    />
  </svg>
);

export default I24RecipeLibrary;
