import styled, { css } from "styled-components";

import { MEDIA_QUERIES } from "constants/layout";

interface ITitleContainer {
  withTopNavigation?: boolean;
}

interface IControls {
  titleControlsFullWidth?: boolean;
}

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 auto;

  width: 100%;
  height: 100%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const PageNavigationContainer = styled.div`
  display: flex;
  align-items: center;

  height: 24px;

  margin: 14px 0 20px;
  padding: 0 11px;

  ${MEDIA_QUERIES.DESKTOP} {
    padding: 0px;
  }
`;

export const TitleContainer = styled.div<ITitleContainer>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 11px;
  margin-bottom: 40px;

  ${MEDIA_QUERIES.DESKTOP} {
    padding: 0px;
  }

  ${({ withTopNavigation }) =>
    !withTopNavigation &&
    css`
      margin-top: 58px;
    `}
`;

export const Title = styled.p`
  font-family: Montserrat;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
`;

export const Controls = styled.div<IControls>`
  ${({ titleControlsFullWidth }) =>
    titleControlsFullWidth &&
    css`
      width: 100%;
    `}
`;
