import { flow, types, applySnapshot } from "mobx-state-tree";

import api from "services/api";

import { Context } from "models";
import { Serving, UserRecipe } from "models/Recipe";

import { MY_RECIPES_LIST_PAGE_SIZE } from "constants/nutrition";

const Ingredient = types.model("Ingredient", {
  id: types.string,
  name: types.maybe(types.string),
  quantity: types.maybe(types.number),
  servingIndex: types.maybe(types.number),
  grams: types.maybe(types.number),
});

const RecipeDetails = types.model("RecipeDetails", {
  id: types.number,
  name: types.string,
  ingredients: types.array(Ingredient),
  servings: types.array(Serving),
  isArchived: types.maybe(types.boolean),
});

const MyRecipes = types
  .model("MyRecipes", {
    context: types.maybe(Context),
    items: types.array(UserRecipe),
    isLoading: types.optional(types.boolean, false),
    details: types.maybeNull(RecipeDetails),
    isDetailsLoading: types.optional(types.boolean, false),
    searchValue: types.optional(types.string, ""),
    searchResult: types.array(UserRecipe),
    isSearching: types.optional(types.boolean, false),
    isUpdating: types.optional(types.boolean, false),
  })
  .views(self => ({
    get needMore() {
      if (self.context) {
        return self.context.page < self.context.pages;
      }
    },
  }))
  .actions(self => ({
    fetch: flow(function* (page: number = 1) {
      try {
        self.isLoading = true;

        const { items, context } = yield api.get(
          `/mobile/food/custom-recipe/list`,
          {
            page,
            size: MY_RECIPES_LIST_PAGE_SIZE,
          },
        );

        applySnapshot(self, {
          ...self,
          context,
          items: page === 1 ? items : self.items.concat(items),
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    removeRecipe: flow(function* (id: number) {
      try {
        yield api.delete(`/mobile/food/custom-recipe/${id}`);
      } catch (error) {
        console.log("error :>> ", error);
      }
    }),
  }))
  .actions(self => ({
    fetchDetails: flow(function* (id: string) {
      try {
        self.isDetailsLoading = true;

        const details = yield api.get(`/mobile/food/custom-recipe/${id}`);

        applySnapshot(self, {
          ...self,
          details,
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isDetailsLoading = false;
      }
    }),
    updateRecipe: flow(function* (id: string, name: string) {
      try {
        self.isUpdating = true;

        yield api.put(`/mobile/food/custom-recipe/${id}`, { name });
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        self.isUpdating = false;
      }
    }),
    resetDetails() {
      applySnapshot(self, { ...self, details: null });
    },
  }))
  .actions(self => ({
    setRecipesSearchValue(value: string) {
      self.searchValue = value;
    },
    searchRecipes: flow(function* () {
      try {
        self.isSearching = true;

        const payload = { Search: self.searchValue };
        const { items } = yield api.get(
          `/mobile/food/custom-recipe/list`,
          payload,
        );

        applySnapshot(self, { ...self, searchResult: items });
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        self.isSearching = false;
      }
    }),
    resetSearchResult() {
      applySnapshot(self, {
        ...self,
        searchResult: [],
        searchValue: "",
      });
    },
  }));

const MyRecipesStore = MyRecipes.create();

export default MyRecipesStore;
