import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IContent {
  top?: boolean;
  bottom?: boolean;
}

interface IIConWrapper {
  contentIcon?: boolean;
}

export const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding-bottom: 67px;
  padding-top: 40px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 702px;
  height: 830px;

  border-radius: 10px;
`;

export const Content = styled.div<IContent>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  height: 100%;
`;

export const IconWrapper = styled.div<IIConWrapper>`
  margin-right: 20px;

  ${({ contentIcon }) =>
    contentIcon &&
    css`
      display: flex;

      margin-right: 6px;
    `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 53px 0 10px 0;
`;

export const Title = styled.div`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;

  color: ${styleConst.colors.dark};
  padding-left: 12px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 280px;
  height: 50px;
  margin-top: 22px;
`;

export const Form = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  padding: 0 61px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: calc(100vh - 100px - 345px);
`;
