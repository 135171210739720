import styled from "styled-components";

import styleConst from "constants/style";

interface IContent {
  top?: boolean;
  bottom?: boolean;
}

export const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;

  padding-bottom: 59px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 702px;
  height: 780px;

  border-radius: 10px;
`;

export const Content = styled.div<IContent>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  padding: 53px 0 10px 0;
`;

export const Title = styled.div`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;

  color: ${styleConst.colors.dark};
  padding-left: 12px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 280px;
  height: 50px;
  margin-top: 22px;
`;

export const PageInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.primary};
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;

  padding: 40px 61px 0 61px;
`;

export const QuestionTitle = styled.div`
  margin-bottom: 10px;

  font-size: 18px;
  font-family: ${styleConst.fonts.montserrat};
  font-weight: 700;
`;

export const QuestionSubtitle = styled.div`
  margin-bottom: 40px;

  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.black50};
  text-align: center;
`;

export const BackButtonWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
`;

export const Alert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 480px;
  height: 80px;

  background-color: ${styleConst.colors.primary10};
  color: ${styleConst.colors.primaryDark};
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  font-family: ${styleConst.fonts.roboto};
  border-radius: 20px;
  padding: 40px 30px;
  text-align: center;
`;

export const AlertWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding-bottom: 20px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: calc(100vh - 100px - 345px);
`;
