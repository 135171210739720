import * as React from "react";

const I100Play = () => {
  return (
    <svg width={100} height={100} viewBox="0 0 100 100" fill="none">
      <circle cx="50" cy="50" r="50" fill="#84CFCB" />
      <circle cx="50" cy="50" r="50" fill="url(#paint0_radial_321_6996)" />
      <path
        d="M40.7402 38.6319V61.3688C40.7402 62.9015 40.7402 63.6679 41.2383 63.9569C41.7364 64.2459 42.4018 63.8657 43.7325 63.1053L63.6273 51.7368C64.9762 50.9661 65.6506 50.5807 65.6506 50.0004C65.6506 49.42 64.9762 49.0346 63.6273 48.2639L43.7325 36.8954C42.4018 36.135 41.7364 35.7548 41.2383 36.0438C40.7402 36.3329 40.7402 37.0992 40.7402 38.6319Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_321_6996"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(56.294 -31.25) rotate(90) scale(179.167 83.2955)"
        >
          <stop stopColor="#B4E9E6" />
          <stop offset="1" stopColor="#84CFCB" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default I100Play;
