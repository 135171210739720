import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Filter = ({ color = styleConst.colors.white }: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8293 4C11.4175 2.83481 10.3062 2 9 2C7.69378 2 6.58254 2.83481 6.17071 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6H6.17071C6.58254 7.16519 7.69378 8 9 8C10.3062 8 11.4175 7.16519 11.8293 6H20C20.5523 6 21 5.55228 21 5C21 4.44772 20.5523 4 20 4H11.8293ZM10 5C10 5.55228 9.55228 6 9 6C8.44772 6 8 5.55228 8 5C8 4.44772 8.44772 4 9 4C9.55228 4 10 4.44772 10 5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1707 11H4C3.44772 11 3 11.4477 3 12C3 12.5523 3.44771 13 4 13H12.1707C12.5825 14.1652 13.6938 15 15 15C16.3062 15 17.4175 14.1652 17.8293 13H20C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11H17.8293C17.4175 9.83481 16.3062 9 15 9C13.6938 9 12.5825 9.83481 12.1707 11ZM16 12C16 11.4477 15.5523 11 15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13C15.5523 13 16 12.5523 16 12Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 18C3.44772 18 3 18.4477 3 19C3 19.5523 3.44772 20 4 20H6.17071C6.58254 21.1652 7.69378 22 9 22C10.3062 22 11.4175 21.1652 11.8293 20H20C20.5523 20 21 19.5523 21 19C21 18.4477 20.5523 18 20 18H11.8293C11.4175 16.8348 10.3062 16 9 16C7.69378 16 6.58254 16.8348 6.17071 18H4ZM9 18C9.55228 18 10 18.4477 10 19C10 19.5523 9.55228 20 9 20C8.44772 20 8 19.5523 8 19C8 18.4477 8.44772 18 9 18Z"
      fill={color}
    />
  </svg>
);

export default I24Filter;
