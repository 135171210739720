import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Flag = ({ color = styleConst.colors.white }: Props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      stroke={color}
      strokeLinecap="round"
      strokeWidth={2}
      d="M5 15.429V4.5c0-.236 0-.354.073-.427C5.146 4 5.264 4 5.5 4h12.275c.52 0 .78 0 .844.156.064.155-.122.337-.494.701L13.458 9.43c-.137.134-.206.201-.206.285 0 .084.07.152.206.286l4.667 4.571c.372.364.558.546.494.702-.064.156-.324.156-.844.156H5zm0 0V20"
    />
  </svg>
);

export default I24Flag;
