import React from "react";
import { observer } from "mobx-react";
import { computed, observable, toJS } from "mobx";
import { SnapshotOut } from "mobx-state-tree";
import { FormSpy } from "react-final-form";

import I36Star from "assets/icons/I36Star";
import I36StarFilled from "assets/icons/I36StarFilled";
import I32Plus from "assets/icons/I32Plus";
import I24ArrowBack from "assets/icons/I24ArrowBack";

import AddRecipeStore from "stores/AddRecipeStore";
import FoodFavoritesStore from "stores/FoodFavoritesStore";
import FoodManualStore from "stores/FoodManualStore";

import FormTitle from "components/Form/FormTitle";
import FormControl, {
  DecimalNumberFieldControl,
  DropdownFieldControl,
  SubmitButtonControl,
} from "components/Form";
import Spinner from "components/Spinner";
import ButtonInline from "components/ButtonInline/ButtonInline";
import { SnackbarTypeValues } from "components/Snackbar";
import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";

import { Serving } from "models/Recipe";

import {
  getNutrientLabel,
  getNutrientsList,
  getNutrientValue,
  getMainFields,
  getServingValue,
  getCurrentNutritionalInfo,
} from "utils/nutrients";
import { isNumber } from "utils/common";

import styleConst from "constants/style";

import {
  Column,
  FavoriteContainer,
  Row,
  ScrollLoader,
  FavoriteIconButton,
  NutrientsInfo,
  NutrientWrapper,
  SubmitButtonWrapper,
  SubmitButton,
  InlineListItem,
  Nutrients,
  NutrientsTitle,
  FoodNutrientsRow,
  BackButtonWrapper,
} from "./styles";

type Props = {
  ingredientInfo: any;
  onBackToSearch: () => void;
  onBackToRecipe: () => void;
  onSubmit: () => void;
  barcodeMode?: boolean;
} & SnackbarContextProps;

@observer
class IngredientDetailsForm extends React.Component<Props> {
  @observable selectedServing?: SnapshotOut<typeof Serving>;
  @observable numberOfServingsValue?: number = 1;
  @observable initialValues?: any = undefined;

  @observable isFavorite: boolean = false;
  @observable isLoading: boolean = false;

  get ingredientInfo() {
    const { ingredientInfo } = this.props;
    return ingredientInfo;
  }

  get ingredient() {
    return { ...this.ingredientInfo?.item, ...FoodManualStore.details };
  }

  get servings() {
    return this.ingredient?.servings;
  }

  get quantity() {
    return this.ingredientInfo?.quantity ?? 0;
  }

  get servingIndex() {
    return this.ingredientInfo?.servingIndex ?? 0;
  }

  get isEditMode() {
    const { servingIndex, quantity } = this.ingredientInfo;
    return typeof quantity === "number" && typeof servingIndex === "number";
  }

  get isOneServing() {
    return this.ingredient?.servings.length === 1;
  }

  get title() {
    return this.ingredient?.name ?? "";
  }

  get brand() {
    return this.ingredient?.brand ?? "";
  }

  get buttonTitle() {
    return this.isEditMode ? "Save" : "Add Ingredient";
  }

  @computed get ingredientOptions() {
    if (this.ingredient) {
      return this.ingredient.servings?.map((serving: any) => ({
        value: getServingValue(serving),
        label: getServingValue(serving),
      }));
    }
  }

  @computed get servingSizeIsDisabled() {
    return this.ingredientOptions?.length === 1;
  }

  @computed get currentNutritionalInfo() {
    if (this.selectedServing?.nutritionalInfo)
      return getCurrentNutritionalInfo(
        this.selectedServing.nutritionalInfo,
        this.numberOfServingsValue ? this.numberOfServingsValue : 0,
      );

    return {};
  }

  @computed get buttonIsDisabled() {
    return this.selectedServing === undefined || !this.numberOfServingsValue;
  }

  @computed get mainNutrients() {
    return this.selectedServing?.nutritionalInfo
      ? getNutrientsList(getMainFields(this.currentNutritionalInfo))
      : undefined;
  }

  @computed get allNutrients() {
    return this.selectedServing?.nutritionalInfo
      ? getNutrientsList(this.currentNutritionalInfo)
      : undefined;
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    try {
      this.isLoading = true;

      FoodManualStore.reset();

      await FoodManualStore.fetchDetails(this.ingredient?.id);
      this.setIsFavorite();

      if (this.isEditMode || this.isOneServing) {
        const index = this.isEditMode ? this.servingIndex : 0;
        const value = getServingValue(this.servings?.[index]);

        if (value) {
          if (this.isEditMode) {
            this.numberOfServingsValue = this.quantity;
          }

          this.initialValues = {
            serving: value,
            amount: this.quantity ? this.quantity : 1,
          };

          this.onSelectServingSize({
            values: {
              serving: value,
              amount: this.numberOfServingsValue?.toString(),
            },
          });
        }
      }
    } finally {
      this.isLoading = false;
    }
  };

  setIsFavorite = () => {
    this.isFavorite = this.ingredient?.isFavorite;
  };

  onSelectServingSize = ({
    values,
  }: {
    values: { serving?: any; amount?: string };
  }) => {
    const { serving, amount } = values;

    const servingIndex = this.servings?.findIndex(
      (servingValue: any) => getServingValue(servingValue) === serving,
    );

    if (isNumber(servingIndex) && servingIndex >= 0) {
      this.selectedServing = {
        ...this.servings[servingIndex],
        serving: servingIndex,
      };
    }

    this.onChangeNumberOfServings(amount);
  };

  onChangeNumberOfServings = (value?: string) => {
    this.numberOfServingsValue = Number(value);
  };

  onSubmit = () => {
    const { onSubmit } = this.props;

    const payload = {
      quantity: this.numberOfServingsValue,
      servingIndex: this.selectedServing?.serving,
      ingredient: toJS(this.ingredient, { recurseEverything: true }),
    };

    AddRecipeStore.addIngredient(payload);

    onSubmit();
  };

  goBack = () => {
    const { onBackToRecipe, onBackToSearch } = this.props;

    !this.isEditMode ? onBackToSearch() : onBackToRecipe();
  };

  checkValues = () => {
    const { setMessage } = this.props;

    if (this.numberOfServingsValue && this.numberOfServingsValue > 1000) {
      setMessage(
        "Number of servings must be less than or equal to '1000'",
        SnackbarTypeValues.WARNING,
      );
    } else {
      this.onSubmit();
    }
  };

  onFavoriteClick = async () => {
    if (this.isFavorite) {
      await FoodFavoritesStore.removeFromFavorites(this.ingredient);

      AddRecipeStore.updateItem(this.ingredient.id, {
        isFavorite: false,
      });

      this.isFavorite = false;
    } else {
      const { favoriteId } = await FoodFavoritesStore.addToFavorites(
        this.ingredient,
      );

      AddRecipeStore.updateItem(this.ingredient.id, {
        isFavorite: true,
        favoriteId,
      });

      this.isFavorite = true;
    }
  };

  renderSubmitButton = ({ onClick }: { onClick: () => void }) => (
    <SubmitButtonWrapper>
      <SubmitButton
        iconLeft={<I32Plus />}
        text={this.buttonTitle}
        onClick={onClick}
        disabled={this.buttonIsDisabled}
      />
    </SubmitButtonWrapper>
  );

  render() {
    const { barcodeMode } = this.props;

    return (
      <>
        {!this.isLoading ? (
          <>
            <BackButtonWrapper>
              <ButtonInline
                text={"Back"}
                iconLeft={<I24ArrowBack />}
                onPress={this.goBack}
              />
            </BackButtonWrapper>
            <FormControl
              onSubmit={this.checkValues}
              initialValues={this.initialValues}
            >
              <FormSpy
                subscription={{ values: true }}
                onChange={this.onSelectServingSize}
              />
              <FormTitle title={this.title} subtitle={this.brand} />

              <Row barcodeForm={barcodeMode} formRow>
                {!barcodeMode && (
                  <Column>
                    <DropdownFieldControl
                      name="serving"
                      options={this.ingredientOptions}
                      label="Serving Size"
                      disabled={this.servingSizeIsDisabled}
                      required
                      withoutErrorText
                    />
                  </Column>
                )}
                <Column>
                  <DecimalNumberFieldControl
                    name="amount"
                    label="Number of Servings"
                    onlyPositiveNumbers
                    required
                    withoutErrorText
                  />
                </Column>
              </Row>

              {this.mainNutrients ? (
                <FoodNutrientsRow
                  nutrients={this.mainNutrients}
                  control={
                    <FavoriteContainer>
                      <FavoriteIconButton
                        onClick={this.onFavoriteClick}
                        backgroundColor={
                          this.isFavorite
                            ? styleConst.colors.primary10
                            : undefined
                        }
                        icon={this.isFavorite ? <I36StarFilled /> : <I36Star />}
                      />
                      {this.isFavorite ? "Unfavorite" : "Favorite"}
                    </FavoriteContainer>
                  }
                />
              ) : null}

              {this.allNutrients ? (
                <Row formRow>
                  <Nutrients>
                    <NutrientsTitle>Nutrition Facts</NutrientsTitle>
                    <NutrientsInfo>
                      {this.allNutrients.map(({ label, value }) => (
                        <NutrientWrapper key={`${label}-${value}`}>
                          <InlineListItem
                            label={getNutrientLabel(label)}
                            value={getNutrientValue(label, value)}
                          />
                        </NutrientWrapper>
                      ))}
                    </NutrientsInfo>
                  </Nutrients>
                </Row>
              ) : null}

              <SubmitButtonControl render={this.renderSubmitButton} />
            </FormControl>
          </>
        ) : (
          <Row>
            <ScrollLoader>
              <Spinner color={styleConst.colors.primary} large />
            </ScrollLoader>
          </Row>
        )}
      </>
    );
  }
}

export default withGlobalSnackbar(IngredientDetailsForm);
