import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";

import I24ArrowBack from "assets/icons/I24ArrowBack";
import I24Barcode from "assets/icons/I24Barcode";
import { Placeholders } from "assets/images/placeholders";

import FoodManualStore from "stores/FoodManualStore";

import FormTitle from "components/Form/FormTitle";
import ButtonInline from "components/ButtonInline";
import Placeholder from "components/Placeholder";
import Search from "components/Search";
import Spinner from "components/Spinner";
import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";

import FoodDetailsForm from "../FoodDetailsForm";
import { AddFoodSubmitPayloadType, ADD_RECIPE_TYPE } from "../AddFoodControl";

import styleConst from "constants/style";

import {
  BackButtonWrapper,
  Container,
  Row,
  ScrollLoader,
  SearchWrapper,
} from "./styles";

type Props = {
  onSubmit: (payload: AddFoodSubmitPayloadType) => void;
  submitIsLoading?: boolean;
} & SnackbarContextProps;

@observer
class BarcodeForm extends React.Component<Props> {
  @observable formPage: number = 1;

  @observable isSearched: boolean = false;

  @computed get isLoading() {
    return FoodManualStore.isLoading;
  }

  @computed get searchString() {
    return FoodManualStore.searchString;
  }

  componentWillUnmount() {
    this.onSearchClear();
  }

  goBack = () => {
    this.formPage--;
    FoodManualStore.resetDetails();
    FoodManualStore.resetResult();
    this.isSearched = false;
  };

  goForward = () => {
    this.formPage++;
  };

  onSearchChange = (value: string) => {
    FoodManualStore.setSearchString(value);
  };

  onSearchClear = () => {
    this.isSearched = false;
    FoodManualStore.resetResult();
  };

  onSearchApply = async () => {
    try {
      if (this.searchString) {
        await FoodManualStore.barcodeSearch();
        this.goForward();
      }
    } catch (error: any) {
    } finally {
      this.isSearched = true;
    }
  };

  onSubmit = (values: any) => {
    const { onSubmit } = this.props;

    onSubmit({ values, addRecipeType: ADD_RECIPE_TYPE.DEFAULT });
  };

  renderPlaceholder = () =>
    this.isSearched && (
      <Row>
        <Placeholder
          imageUrl={Placeholders.NotFound}
          text={"No matches found. Please\ntry another search"}
        />
      </Row>
    );

  render() {
    const { submitIsLoading } = this.props;

    return (
      <Container>
        {this.formPage === 1 && (
          <>
            <FormTitle title={"Barcode"} icon={<I24Barcode />} />
            <Row>
              <SearchWrapper>
                <Search
                  initValue={this.searchString}
                  onChange={this.onSearchChange}
                  onClear={this.onSearchClear}
                  onApply={this.onSearchApply}
                  onlyNumber
                />
              </SearchWrapper>
            </Row>

            {!this.isLoading ? (
              this.renderPlaceholder()
            ) : (
              <Row>
                <ScrollLoader>
                  <Spinner color={styleConst.colors.primary} large />
                </ScrollLoader>
              </Row>
            )}
          </>
        )}

        {this.formPage === 2 && (
          <>
            <BackButtonWrapper>
              <ButtonInline
                text={"Back"}
                iconLeft={<I24ArrowBack />}
                onPress={this.goBack}
              />
            </BackButtonWrapper>
            <FoodDetailsForm
              onSubmit={this.onSubmit}
              submitIsLoading={submitIsLoading}
              barcodeMode
            />
          </>
        )}
      </Container>
    );
  }
}

export default withGlobalSnackbar(BarcodeForm);
