import React from "react";
import { observer } from "mobx-react";

import MenuItem, { MenuItemType } from "./MenuItem";

import { MenuItems } from "./styles";

type Props = { items: MenuItemType[], className?: any; };

@observer
class Menu extends React.Component<Props> {
  render() {
    const { items, className } = this.props;
    return (
      <MenuItems className={className} >
        {items.map(({ text, icon, onClick }) => (
          <MenuItem key={text} text={text} icon={icon} onClick={onClick} />
        ))}
      </MenuItems>
    );
  }
}

export default Menu;
