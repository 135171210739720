import { applySnapshot, flow, types, getSnapshot } from "mobx-state-tree";
import { toJS } from "mobx";

import api from "services/api";

const CategoryItem = types.model("CategoryItem", {
  id: types.number,
  title: types.string,
});

const ResultItem = types.model("ResultItem", {
  id: types.number,
  title: types.string,
  label: types.maybe(types.string),
  imageUrl: types.maybe(types.string),
  duration: types.maybe(types.number),
  description: types.maybe(types.string),
  url: types.maybe(types.string),
  macronutrients: types.maybe(
    types.model({
      carbs: types.number,
      fiber: types.number,
      fat: types.number,
      protein: types.number,
    }),
  ),
});

const FilterItem = types.model("FilterItem", {
  label: types.string,
  value: types.string,
});

export const Filters = types.model("Filters", {
  workouts: types.array(FilterItem),
  recipes: types.array(FilterItem),
  resources: types.array(FilterItem),
});

const Results = types.model("Results", {
  workouts: types.array(ResultItem),
  recipes: types.array(ResultItem),
  resources: types.array(ResultItem),
});

const Search = types
  .model("Search", {
    foodCategoryList: types.array(CategoryItem),
    workoutCategoryList: types.array(CategoryItem),
    resourceCategoryList: types.array(CategoryItem),
    searchString: types.optional(types.string, ""),
    results: types.optional(Results, {}),
    filters: types.optional(Filters, {}),
    isSearching: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get currentFilters() {
      const { filters } = getSnapshot(self);
      return filters;
    },

    get isFilters() {
      const { filters } = getSnapshot(self);
      return Object.values(filters).some(filter => filter.length);
    },

    get workoutIds() {
      return self.filters.workouts.map(({ value }) => Number(value));
    },

    get foodIds() {
      return self.filters.recipes.map(({ value }) => Number(value));
    },

    get resourceIds() {
      return self.filters.resources.map(({ value }) => Number(value));
    },

    get canSearch() {
      return self.searchString.length >= 3;
    },

    get isResults() {
      return Object.values(self.results).some(({ length }) => !!length);
    },
  }))
  .actions(self => ({
    fetchCategoryList: flow(function* () {
      self.isLoading = true;
      try {
        const { items: foodCategoryList } = yield api.get(
          `/mobile/food/category/search-list`,
        );

        const { items: workoutCategoryList } = yield api.get(
          `/mobile/workout/category/search-list`,
        );

        const { items: resourceCategoryList } = yield api.get(
          `/mobile/quick-resource/category/search-list`,
        );

        applySnapshot(self, {
          ...self,
          foodCategoryList,
          workoutCategoryList,
          resourceCategoryList,
        });
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        self.isLoading = false;
      }
    }),

    search: flow(function* () {
      self.isSearching = true;
      try {
        const params = {
          Search: self.searchString,
          WorkoutCategoryIds: self.workoutIds,
          FoodCategoryIds: self.foodIds,
          QuickResourceCategoryIds: self.resourceIds,
        };

        const results = yield api.get(`/mobile/search`, params);

        applySnapshot(self, { ...self, results });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isSearching = false;
      }
    }),

    setSearchString(value: string) {
      self.searchString = value;
    },

    setFilters(values: any) {
      self.filters = toJS(values);
    },

    reset() {
      applySnapshot(self, {});
    },

    resetResults() {
      applySnapshot(self, {
        ...self,
        results: {
          workouts: [],
          recipes: [],
          resources: [],
        },
      });
    },
  }));

const SearchStore = Search.create();

export default SearchStore;
