import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";

import MeasurementsStore from "stores/MeasurementsStore";

import Spinner from "components/Spinner";
import Input from "components/Input";
import ButtonInline from "components/ButtonInline";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import { NoInternetConnectionError } from "services/api";

import styleConst from "constants/style";

import { LINKS } from "constants/links";

import {
  Title,
  LoaderContainer,
  Container,
  Form,
  ItemWrapper,
  SubmitButton,
  Controls,
  ButtonInlineWrapper,
  Item,
  ItemDescription,
} from "./styles";

type Props = {};

type Field = "bust" | "waist" | "hips" | "butt" | "thigh" | "arm";

interface Values {
  bust?: string;
  waist?: string;
  hips?: string;
  butt?: string;
  thigh?: string;
  arm?: string;
}

@observer
class Measurements extends React.Component<Props> {
  @observable isLoading: boolean = false;
  @observable isNoInternetConnectionError: boolean = false;

  @observable values: Values = {
    bust: "",
    waist: "",
    hips: "",
    butt: "",
    thigh: "",
    arm: "",
  };

  @observable error: Values = {
    bust: "",
    waist: "",
    hips: "",
    butt: "",
    thigh: "",
    arm: "",
  };

  async componentDidMount() {
    this.isLoading = true;
    try {
      this.values = await MeasurementsStore.fetch();
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    } finally {
      this.isLoading = false;
    }
  }

  onChange =
    (field: Field) =>
    (text: string = "") => {
      this.values[field] = text;
      this.error[field] = "";
    };

  onDonePress = async () => {
    this.isLoading = true;
    const values = Object.fromEntries(
      Object.entries(this.values).map(([key, value]) => {
        const parsed = parseFloat(String(value).replace(",", "."));
        value = isNaN(parsed) ? 0 : parsed;
        return [key, value];
      }),
    );

    this.values = await MeasurementsStore.update(values);
    this.isLoading = false;
  };

  onNeedHelpPress = async () => {
    window.open(LINKS.HELP);
  };

  render() {
    if (this.isNoInternetConnectionError) {
      return <NoInternetConnectionPlaceholder type={"element"} />;
    }

    if (this.isLoading) {
      return (
        <LoaderContainer>
          <Spinner color={styleConst.colors.primary} large />
        </LoaderContainer>
      );
    }

    return (
      <React.Fragment>
        <Container>
          <Title>My Measurements</Title>

          <Form>
            <ItemWrapper>
              <Item>
                <Input
                  label="Bust (in)"
                  value={this.values.bust}
                  onChangeText={this.onChange("bust")}
                  error={this.error.bust || ""}
                  numbersOnly
                  onlyPositiveNumbers
                />
              </Item>
              <ItemDescription>Under armpits</ItemDescription>
            </ItemWrapper>
            <ItemWrapper>
              <Item>
                <Input
                  label="Butt (in)"
                  value={this.values.butt}
                  onChangeText={this.onChange("butt")}
                  error={this.error.butt || ""}
                  numbersOnly
                  onlyPositiveNumbers
                />
              </Item>
              <ItemDescription>
                Across the midline of your glutes
              </ItemDescription>
            </ItemWrapper>
            <ItemWrapper>
              <Item>
                <Input
                  label="Waist (in)"
                  value={this.values.waist}
                  onChangeText={this.onChange("waist")}
                  error={this.error.waist || ""}
                  numbersOnly
                  onlyPositiveNumbers
                />
              </Item>
              <ItemDescription>At belly button</ItemDescription>
            </ItemWrapper>
            <ItemWrapper>
              <Item>
                <Input
                  label="Thigh (in)"
                  value={this.values.thigh}
                  onChangeText={this.onChange("thigh")}
                  error={this.error.thigh || ""}
                  numbersOnly
                  onlyPositiveNumbers
                />
              </Item>
              <ItemDescription>
                Fingertip right above knee at the bottom of palm
              </ItemDescription>
            </ItemWrapper>
            <ItemWrapper>
              <Item>
                <Input
                  label="Hips (in)"
                  value={this.values.hips}
                  onChangeText={this.onChange("hips")}
                  error={this.error.hips || ""}
                  numbersOnly
                  onlyPositiveNumbers
                />
              </Item>
              <ItemDescription>Top of hip bone</ItemDescription>
            </ItemWrapper>
            <ItemWrapper>
              <Item>
                <Input
                  label="Arm (in)"
                  value={this.values.arm}
                  onChangeText={this.onChange("arm")}
                  error={this.error.arm || ""}
                  numbersOnly
                  onlyPositiveNumbers
                />
              </Item>
              <ItemDescription>Hand width from elbow</ItemDescription>
            </ItemWrapper>
          </Form>
          <Controls>
            <ButtonInlineWrapper>
              <ButtonInline
                onPress={this.onNeedHelpPress}
                text={"Need help?"}
                fontSize={14}
                fontWeight={500}
                color={styleConst.colors.primary}
              />
            </ButtonInlineWrapper>

            <SubmitButton
              text={"Done"}
              onClick={this.onDonePress}
              height={50}
              width={180}
              fontSize={16}
              fontWeight={700}
              loading={MeasurementsStore.isUpdating}
            />
          </Controls>
        </Container>
      </React.Fragment>
    );
  }
}

export default Measurements;
