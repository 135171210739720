import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IContent {
  top?: boolean;
  bottom?: boolean;
}

interface IIConWrapper {
  contentIcon?: boolean;
}

export const ButtonWrapper = styled.div`
  width: 306px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 702px;
  height: 589px;

  border-radius: 10px;
`;

export const Content = styled.div<IContent>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const IconWrapper = styled.div<IIConWrapper>`
  margin-right: 20px;

  ${({ contentIcon }) =>
    contentIcon &&
    css`
      display: flex;

      margin-right: 6px;
    `}
`;

export const Text = styled.div`
  margin-left: 11px;

  font-size: 14px;
  line-height: 24px;
  text-align: center;

  color: ${styleConst.colors.black50};
`;

export const Title = styled.div`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;

  color: ${styleConst.colors.dark};
  padding: 53px 0 20px 0;
`;

export const NutrientsProgressWrapper = styled.div`
  width: 702px;
  padding: 0 60px;
  height: 414px;
  position: relative;
  overflow-y: scroll;
`;

export const NutrientsProgressTitle = styled.div`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 18px;
  font-weight: 700;

  color: ${styleConst.colors.dark};
`;

export const Blur = styled.div`
  background: linear-gradient(0, #ffffff, rgba(255, 254, 254, 0));
  position: absolute;
  width: 650px;
  height: 40px;
  bottom: 0;
`;
