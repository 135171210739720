import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Star = ({ color = styleConst.colors.white }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 3.99282L10.6224 7.14365C10.1271 8.27653 9.07086 9.06403 7.84373 9.21532L4.3921 9.64087L7.06391 12.2321C7.90923 13.0519 8.28563 14.2419 8.06554 15.3987L7.37473 19.0297L10.2206 17.3497C11.3183 16.7016 12.6815 16.7016 13.7792 17.3497L16.6251 19.0297L15.9342 15.3987C15.7141 14.2419 16.0905 13.0519 16.9359 12.2321L19.6077 9.64087L16.156 9.21532C14.9289 9.06403 13.8727 8.27653 13.3774 7.14365L11.9999 3.99282ZM6.42273 19.5917C6.42247 19.5919 6.42299 19.5916 6.42273 19.5917V19.5917ZM13.3743 2.14376C12.8502 0.944918 11.1496 0.944919 10.6255 2.14376L8.78986 6.34249C8.5776 6.82801 8.12492 7.16551 7.59901 7.23035L3.1105 7.78373C1.85991 7.93791 1.34521 9.47198 2.24975 10.3492L5.67151 13.6678C6.03379 14.0192 6.1951 14.5291 6.10078 15.0249L5.20335 19.7419C4.96215 21.0097 6.32819 21.9701 7.43948 21.314L11.2373 19.0719C11.7078 18.7942 12.292 18.7942 12.7625 19.0719L16.5603 21.314C17.6716 21.9701 19.0376 21.0097 18.7964 19.7419L17.899 15.0249C17.8047 14.5291 17.966 14.0192 18.3283 13.6678L21.75 10.3492C22.6546 9.47198 22.1399 7.93791 20.8893 7.78373L16.4008 7.23035C15.8749 7.16551 15.4222 6.82801 15.2099 6.34249L13.3743 2.14376Z"
      fill={color}
    />
  </svg>
);

export default I24Star;
