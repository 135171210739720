import styled from "styled-components";

import {
  MEDIA_QUERIES,
  PAGE_WIDTH,
  BIG_POPUP_MEDIA_QUERIES,
  SCROLLBAR_WIDTH,
  PADDING_WIDTH,
  TWO_COLUMN_WIDTH,
} from "constants/layout";

interface IContainer {
  fixedWidth?: boolean;
}

export const BackButtonWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
`;

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;

  padding-top: 53px;

  height: calc(100vh - 70px);
  min-height: 700px;
  max-width: 100vw;

  ${MEDIA_QUERIES.DESKTOP} {
    width: calc(${PAGE_WIDTH.DESKTOP} + ${PADDING_WIDTH} + ${SCROLLBAR_WIDTH});
  }

  width: calc(
    ${PAGE_WIDTH.SMALL_DESKTOP} + ${PADDING_WIDTH} + ${SCROLLBAR_WIDTH}
  );

  ${BIG_POPUP_MEDIA_QUERIES.THREE_COLUMN_WITHOUT_PADDING} {
    width: calc(${PAGE_WIDTH.SMALL_DESKTOP} + ${SCROLLBAR_WIDTH});
  }

  ${BIG_POPUP_MEDIA_QUERIES.TWO_COLUMN_WITH_PADDING} {
    width: calc(${TWO_COLUMN_WIDTH} + ${PADDING_WIDTH} + ${SCROLLBAR_WIDTH});
  }

  ${BIG_POPUP_MEDIA_QUERIES.TWO_COLUMN_WITHOUT_PADDING} {
    width: calc(${TWO_COLUMN_WIDTH} + ${SCROLLBAR_WIDTH});
  }

  overflow-y: hidden;
  position: relative;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;

  position: relative;
`;

export const ButtonInlineWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  line-height: 50px;

  cursor: pointer;

  position: absolute;
  right: 0;
  top: 0;

  padding-right: 60px;
  margin-right: 27px;
`;

export const GridCollectionWrapper = styled.div`
  display: flex;

  position: relative;

  height: 100%;

  ${MEDIA_QUERIES.DESKTOP} {
    width: calc(${PAGE_WIDTH.DESKTOP} + ${PADDING_WIDTH} + ${SCROLLBAR_WIDTH});
  }

  width: calc(
    ${PAGE_WIDTH.SMALL_DESKTOP} + ${PADDING_WIDTH} + ${SCROLLBAR_WIDTH}
  );

  ${BIG_POPUP_MEDIA_QUERIES.THREE_COLUMN_WITHOUT_PADDING} {
    width: calc(${PAGE_WIDTH.SMALL_DESKTOP} + ${SCROLLBAR_WIDTH});
  }

  ${BIG_POPUP_MEDIA_QUERIES.TWO_COLUMN_WITH_PADDING} {
    width: calc(${TWO_COLUMN_WIDTH} + ${PADDING_WIDTH} + ${SCROLLBAR_WIDTH});
    padding: 0 59px;
  }

  ${BIG_POPUP_MEDIA_QUERIES.TWO_COLUMN_WITHOUT_PADDING} {
    width: calc(${TWO_COLUMN_WIDTH} + ${SCROLLBAR_WIDTH});
    padding: 0;
  }

  overflow-y: auto;

  ${BIG_POPUP_MEDIA_QUERIES.DESKTOP_PADDING} {
    padding: 0 59px;
  }
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 700px;
`;

export const ControlPanel = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  height: 147px;

  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  width: 780px;

  padding: 42px 0 55px;
`;

export const NoInternetConnectionErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
`;
