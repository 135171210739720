import styled from "styled-components";

export const Version = styled.div`
  position: fixed;
  right: 6px;
  bottom: 4px;

  font-family: "Roboto";
  font-size: 12px;
  color: gray;
`;
