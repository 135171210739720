import styled, { css } from "styled-components";

import styleConst from "constants/style";

import { isNumber } from "utils/common";

interface IIcon {
  top?: number;
  left?: number;
  right?: number;
}

export const Container = styled.div``;

export const Icon = styled.div<IIcon>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  background-color: ${styleConst.colors.black45};
  border-radius: 2px;

  z-index: 1;

  height: 32px;
  width: 32px;

  ${({ top }) =>
    isNumber(top) &&
    css`
      top: ${top}px;
    `};
  ${({ left }) =>
    isNumber(left) &&
    css`
      left: ${left}px;
    `};
  ${({ right }) =>
    isNumber(right) &&
    css`
      right: ${right}px;
    `};
`;

export const Wrapper = styled.div`
  position: relative;

  pointer-events: none;
`;
