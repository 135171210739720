import * as React from "react";

import style from "constants/style";

type Props = {
  color?: string;
};

const I20Search = ({ color = style.colors.black }: Props) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2547 14.4332C12.1258 15.3108 10.7073 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667C15.8333 10.7073 15.3108 12.1258 14.4332 13.2547L17.2559 16.0774C17.5814 16.4028 17.5814 16.9305 17.2559 17.2559C16.9305 17.5814 16.4028 17.5814 16.0774 17.2559L13.2547 14.4332ZM14.1667 9.16667C14.1667 11.9281 11.9281 14.1667 9.16667 14.1667C6.40524 14.1667 4.16667 11.9281 4.16667 9.16667C4.16667 6.40524 6.40524 4.16667 9.16667 4.16667C11.9281 4.16667 14.1667 6.40524 14.1667 9.16667Z"
        fill={color}
        fillOpacity="0.25"
      />
    </svg>
  );
};

export default I20Search;
