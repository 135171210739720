import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Loader = ({ color = styleConst.colors.white }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 11.4477 21.4477 11 22 11C22.5523 11 23 11.4477 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C12.5523 1 13 1.44772 13 2C13 2.55228 12.5523 3 12 3Z"
      fill={color}
    />
  </svg>
);

export default I24Loader;
