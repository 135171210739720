import React from "react";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import { Placeholders } from "assets/images/placeholders";

import RecipeFavoritesStore from "stores/RecipeFavoritesStore";

import List from "components/List";
import ListItemWrapper from "components/ListItemWrapper";
import Recipe from "components/Recipe";
import { MyRecipes as Actions } from "components/Actions";
import Spinner from "components/Spinner";
import Placeholder from "components/Placeholder/Placeholder";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";
import MealSchedulePopUp from "components/MealSchedulePopup";
import ConfirmPopup from "components/ConfirmPopup";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import { NoInternetConnectionError } from "services/api";

import ROUTES from "navigation/routes";

import { MEAL_SCHEDULE_TYPE } from "constants/nutrition";
import styleConst from "constants/style";

import {
  Header,
  Title,
  TitleWrapper,
  ColumnTitle,
  ColumnTitleWrapper,
  LoaderContainer,
} from "./styles";

type Props = {} & RouteComponentProps & GlobalPopupProps;

const header = ["Carbs", "Fiber", "Fat", "Protein"];

@observer
class Favorites extends React.Component<Props> {
  @observable isNoInternetConnectionError: boolean = false;

  @computed get items() {
    return RecipeFavoritesStore.items;
  }

  async componentDidMount() {
    try {
      await RecipeFavoritesStore.fetch();
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }
  }

  getRecipeById = (id: number) => {
    return this.items.find(item => item.id === id);
  };

  onRecipeDetailsNavigate = (id: any) => () => {
    const { history } = this.props;

    if (id) {
      history.push(
        generatePath(ROUTES.NUTRITION_MY_ITEMS_FAVORITES_RECIPE, {
          recipeId: id,
        }),
      );
    }
  };

  openMealSchedule = (mode: string, id: number) => {
    const { openPopup } = this.props;

    openPopup({
      key: "MealSchedulePopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <MealSchedulePopUp
          mode={mode}
          recipe={{ id, isRecipe: true }}
          onClose={close}
        />
      ),
    });
  };

  onToday = (id: number) => {
    this.openMealSchedule(MEAL_SCHEDULE_TYPE.TODAY, id);
  };

  onSelectDay = (id: number) => {
    this.openMealSchedule(MEAL_SCHEDULE_TYPE.SCHEDULE, id);
  };

  onRemove = async (id: number) => {
    const { openPopup } = this.props;

    openPopup({
      key: "ConfirmPopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <ConfirmPopup
          onClose={close}
          onAccept={async () => {
            await RecipeFavoritesStore.removeFromFavorites(id);
            await RecipeFavoritesStore.fetch();
            close();
          }}
          title={"Are you sure you want to remove selected recipe?"}
        />
      ),
    });
  };

  renderItem = (item: any) => {
    return (
      <ListItemWrapper
        key={item.id}
        onClick={this.onRecipeDetailsNavigate(item.id)}
        controls={
          <Actions
            id={item.id}
            onToday={this.onToday}
            onSelectDay={this.onSelectDay}
            onRemove={this.onRemove}
          />
        }
      >
        <Recipe
          withoutImage
          style={{
            height: 60,
            marginLeft: 20,
            marginTop: 10,
            marginBottom: 10,
          }}
          recipe={item}
          onClick={this.onRecipeDetailsNavigate(item.id)}
        />
      </ListItemWrapper>
    );
  };

  render() {
    if (this.isNoInternetConnectionError) {
      return <NoInternetConnectionPlaceholder type={"element"} />;
    }

    if (RecipeFavoritesStore.isLoading) {
      return (
        <LoaderContainer>
          <Spinner color={styleConst.colors.primary} large />
        </LoaderContainer>
      );
    }

    return (
      <React.Fragment>
        <Header>
          <TitleWrapper>
            <Title>{"Recipes"}</Title>
            <ColumnTitleWrapper>
              {header.map(item => (
                <ColumnTitle key={item}>{item}</ColumnTitle>
              ))}
            </ColumnTitleWrapper>
          </TitleWrapper>
        </Header>

        {this.items.length ? (
          <List
            items={this.items}
            renderItem={this.renderItem}
            style={{ overflow: "visible" }}
          />
        ) : (
          <Placeholder
            inline={false}
            imageUrl={Placeholders.Recipes}
            text="No recipes yet"
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(withGlobalPopup(Favorites));
