import React from "react";

import I20Search from "assets/icons/I20Search";

import Icon from "components/Icon";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";

import SearchForm from "./SearchForm";

import styleConst from "constants/style";

import { Wrapper } from "./styles";

type Props = {} & GlobalPopupProps;

class SearchControl extends React.Component<Props> {
  openSearchForm = () => {
    const { openPopup } = this.props;

    openPopup({
      key: "SearchForm",
      exitButtonColor: styleConst.colors.black25,
      render: () => <SearchForm />,
    });
  };

  render() {
    return (
      <Wrapper onClick={this.openSearchForm}>
        <Icon icon={<I20Search />} />
      </Wrapper>
    );
  }
}

export default withGlobalPopup(SearchControl);
