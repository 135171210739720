import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I16Replay = ({ color = styleConst.colors.white }: Props) => (
  <svg width={16} height={16} fill="none" viewBox="0 0 16 16">
    <path
      fill={color}
      fillRule="evenodd"
      d="M5.001 12.666a1 1 0 001 1h3.667a4.667 4.667 0 100-9.333H5.082L6.71 2.707a1 1 0 00-1.415-1.414l-4.04 4.04 4.04 4.04A1 1 0 106.71 7.959L5.082 6.333h4.586a2.667 2.667 0 110 5.333H6.001a1 1 0 00-1 1z"
      clipRule="evenodd"
    />
  </svg>
);

export default I16Replay;
