import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";

import AddRecipeStore from "stores/AddRecipeStore";
import MyRecipesStore from "stores/MyRecipesStore";
import FoodManualStore from "stores/FoodManualStore";

import { SnackbarTypeValues } from "components/Snackbar";

import RecipeForm from "./RecipeForm";
import SearchIngredients from "./SearchIngredients";
import IngredientDetailsForm from "./IngredientDetailsForm";

import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";

import {
  getNutrientsList,
  getSumNutritionalInformation,
  getMainFields,
} from "utils/nutrients";
import { emptyValidator } from "utils/validation";

import { Container, IngredientDetailsFormWrapper } from "./styles";

enum Pages {
  Recipe = "Recipe",
  SearchIngredientsManually = "SearchIngredientsManually",
  SearchIngredientsBarcode = "SearchIngredientsBarcode",
  IngredientDetails = "IngredientDetails",
  IngredientDetailsBarcode = "IngredientDetailsBarcode",
}

type Props = {
  onSave: () => void;
} & SnackbarContextProps;

@observer
class AddRecipeForm extends React.Component<Props> {
  @observable formPage: Pages = Pages.Recipe;

  @observable ingredientInfo = {};

  @observable titleError?: string;

  @computed get sumNutritionalInformation() {
    return this.customRecipe.ingredients.length
      ? getSumNutritionalInformation(this.customRecipe.ingredients)
      : null;
  }

  @computed get nutritionFacts() {
    return getNutrientsList(this.sumNutritionalInformation ?? {});
  }

  @computed get mainNutritionFacts() {
    return getNutrientsList(
      getMainFields(this.sumNutritionalInformation ?? {}),
    );
  }

  @computed get customRecipe() {
    return AddRecipeStore.customRecipe;
  }

  @computed get recipeName() {
    return AddRecipeStore.customRecipe.name ?? "";
  }

  @computed get buttonIsDisabled() {
    return (
      !this.customRecipe.name || this.customRecipe.ingredients.length === 0
    );
  }

  @computed get ingredientsIsDisabled() {
    return this.customRecipe.name || this.customRecipe.ingredients.length > 0;
  }

  componentWillUnmount() {
    AddRecipeStore.resetCustomRecipe();
    AddRecipeStore.resetResult();
    FoodManualStore.resetResult();
  }

  onChangeTitle = (value: string) => {
    AddRecipeStore.setRecipeName(value);

    this.titleError = emptyValidator(value);
  };

  onSave = async () => {
    const { onSave, setMessage } = this.props;
    try {
      await AddRecipeStore.createCustomRecipe();
      MyRecipesStore.fetch();
      onSave();
    } catch (error: any) {
      setMessage(error.message, SnackbarTypeValues.ALERT);
    }
  };

  onSearchIngredientsNavigate =
    ({ isBarcodeMode }: { isBarcodeMode?: boolean }) =>
    () => {
      this.formPage = !isBarcodeMode
        ? Pages.SearchIngredientsManually
        : Pages.SearchIngredientsBarcode;
    };

  onBackToRecipeForm = () => {
    AddRecipeStore.resetResult();
    FoodManualStore.resetResult();

    this.formPage = Pages.Recipe;
  };

  onIngredientDetailsNavigate =
    ({ isBarcodeMode }: { isBarcodeMode?: boolean }) =>
    (id: number) => {
      this.ingredientInfo = { item: { id } };
      this.formPage = !isBarcodeMode
        ? Pages.IngredientDetails
        : Pages.IngredientDetailsBarcode;
    };

  onEdit = (id: string, item: any) => {
    this.ingredientInfo = {
      item: item.ingredient,
      quantity: item.quantity,
      quantityContent: item.quantityContent,
      servingIndex: item.servingIndex,
    };
    this.formPage = Pages.IngredientDetails;
  };

  onRemove = (id: string) => {
    AddRecipeStore.removeIngredient(id);
  };

  render() {
    return (
      <Container>
        {this.formPage === Pages.Recipe ? (
          <RecipeForm
            title={this.recipeName}
            titleError={this.titleError}
            ingredients={this.customRecipe.ingredients}
            buttonIsDisabled={this.buttonIsDisabled}
            onChangeTitle={this.onChangeTitle}
            onAddManually={this.onSearchIngredientsNavigate({})}
            onBarcode={this.onSearchIngredientsNavigate({
              isBarcodeMode: true,
            })}
            nutritionFacts={this.nutritionFacts}
            mainNutritionFacts={this.mainNutritionFacts}
            onSave={this.onSave}
            onEdit={this.onEdit}
            onRemove={this.onRemove}
          />
        ) : null}

        {this.formPage === Pages.SearchIngredientsManually ? (
          <SearchIngredients
            onBack={this.onBackToRecipeForm}
            onSelectIngredient={this.onIngredientDetailsNavigate({})}
          />
        ) : null}

        {this.formPage === Pages.SearchIngredientsBarcode ? (
          <SearchIngredients
            onBack={this.onBackToRecipeForm}
            onSelectIngredient={this.onIngredientDetailsNavigate({
              isBarcodeMode: true,
            })}
            barcodeMode
          />
        ) : null}

        {this.formPage === Pages.IngredientDetails ? (
          <IngredientDetailsFormWrapper>
            <IngredientDetailsForm
              ingredientInfo={this.ingredientInfo}
              onSubmit={this.onBackToRecipeForm}
              onBackToSearch={this.onSearchIngredientsNavigate({})}
              onBackToRecipe={this.onBackToRecipeForm}
            />
          </IngredientDetailsFormWrapper>
        ) : null}

        {this.formPage === Pages.IngredientDetailsBarcode ? (
          <IngredientDetailsFormWrapper>
            <IngredientDetailsForm
              ingredientInfo={this.ingredientInfo}
              onSubmit={this.onBackToRecipeForm}
              onBackToSearch={this.onSearchIngredientsNavigate({
                isBarcodeMode: true,
              })}
              onBackToRecipe={this.onBackToRecipeForm}
              barcodeMode
            />
          </IngredientDetailsFormWrapper>
        ) : null}
      </Container>
    );
  }
}

export default withGlobalSnackbar(AddRecipeForm);
