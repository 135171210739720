import React from "react";
import { observer } from "mobx-react";

import TrimmedText from "components/TrimmedText";

import { Container, Image, TextContainer, Title, SubTitle } from "./styles";

type Props = {
  title?: string;
  subtitle?: string;
  imageUrl?: string;
};

const ResultItem = ({ imageUrl, title, subtitle }: Props) => (
  <Container>
    {imageUrl && <Image imageUrl={imageUrl} />}
    <TextContainer>
      {title && (
        <TrimmedText lines={1} text={title}>
          <Title>{title}</Title>
        </TrimmedText>
      )}
      {subtitle && <SubTitle>{subtitle}</SubTitle>}
    </TextContainer>
  </Container>
);

export default observer(ResultItem);
