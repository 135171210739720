import styled, { css } from "styled-components";

import backgroundImage from "assets/images/svg/banner_background.svg";

import styleConst from "constants/style";
import { MEDIA_QUERIES, PAGE_WIDTH } from "constants/layout";

interface IImage {
  imageUrl: string;
}

const BANNER_HEIGHT = "468px";

export const Background = styled.div`
  position: absolute;

  height: ${BANNER_HEIGHT};
  width: 100%;
  min-width: ${PAGE_WIDTH.SMALL_DESKTOP};

  overflow: hidden;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-position: 41% 50%;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;

  margin: auto;

  position: relative;

  ${MEDIA_QUERIES.DESKTOP} {
    width: ${PAGE_WIDTH.DESKTOP};
  }
  ${MEDIA_QUERIES.MEDIUM_DESKTOP} {
    width: ${PAGE_WIDTH.SMALL_DESKTOP};
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 0 11px;

  ${MEDIA_QUERIES.SMALL_DESKTOP} {
    position: relative;
  }
  z-index: 1;

  width: 100%;
  min-height: ${BANNER_HEIGHT};

  overflow: hidden;
`;

export const Image = styled.div<IImage>`
  position: absolute;

  ${MEDIA_QUERIES.DESKTOP} {
    right: 39px;
  }
  ${MEDIA_QUERIES.MEDIUM_DESKTOP} {
    right: -136px;
  }

  height: ${BANNER_HEIGHT};
  width: 534px;
  z-index: 100;

  background-size: contain;
  background-repeat: no-repeat;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 154px;
`;

export const Text = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 44px;
  font-weight: 600;
  line-height: 48px;
  color: ${styleConst.colors.white};

  margin-bottom: 14px;
`;

export const Title = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 72px;
  font-weight: 700;
  line-height: 72px;
  color: ${styleConst.colors.white};
`;
