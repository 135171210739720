import React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import { RouteComponentProps, withRouter } from "react-router-dom";

import WorkoutDetailsStore from "stores/WorkoutDetailsStore";

import { Page } from "components/Layout";
import Movement from "components/Studio/WorkoutMovement";

import StudioNavList from "pages/Studio/NavList";
import TodayNavList from "pages/Today/NavList";
import SearchNavList from "pages/Search/NavList";
import CalendarNavList from "pages/Calendar/NavList";

import ROUTES from "navigation/routes";

type Props = {} & RouteComponentProps<{
  workoutId: string;
  movementId: string;
  categoryId?: string;
  workoutGroup?: string;
  workoutInWorkoutCategoryId?: string;
  scheduleDate?: string;
}>;

@observer
class WorkoutMovement extends React.Component<Props> {
  get categoryId() {
    const {
      match: {
        params: { categoryId },
      },
    } = this.props;

    return categoryId ? Number.parseInt(categoryId) : undefined;
  }

  get workoutId() {
    const {
      match: {
        params: { workoutId },
      },
    } = this.props;

    return Number.parseInt(workoutId);
  }

  get movementId() {
    const {
      match: {
        params: { movementId },
      },
    } = this.props;

    return Number.parseInt(movementId);
  }

  get url() {
    const {
      match: { url },
    } = this.props;

    return url;
  }

  get workoutInWorkoutCategoryId() {
    const {
      match: {
        params: { workoutInWorkoutCategoryId },
      },
    } = this.props;

    return workoutInWorkoutCategoryId
      ? Number.parseInt(workoutInWorkoutCategoryId)
      : undefined;
  }

  get isStudio() {
    return this.url.includes(ROUTES.STUDIO);
  }

  get isSearch() {
    return this.url.includes(ROUTES.SEARCH);
  }

  get isCalendar() {
    return this.url.includes(ROUTES.CALENDAR);
  }

  get navList() {
    if (this.isSearch) {
      return <SearchNavList />;
    } else if (this.isStudio) {
      return <StudioNavList />;
    } else if (this.isCalendar) {
      return <CalendarNavList />;
    } else {
      return <TodayNavList />;
    }
  }

  get workoutGroup() {
    const {
      match: {
        params: { workoutGroup },
      },
    } = this.props;

    return workoutGroup;
  }

  @computed
  get details() {
    return this.movementId
      ? WorkoutDetailsStore.getMovementDetails(this.movementId)
      : undefined;
  }

  render() {
    const { history } = this.props;
    return (
      <Page title={this.details?.title} topNavigation={this.navList}>
        <Movement
          workoutId={this.workoutId}
          movementId={this.movementId}
          categoryId={this.categoryId}
          workoutGroup={this.workoutGroup}
          workoutInWorkoutCategoryId={this.workoutInWorkoutCategoryId}
          url={this.url}
          history={history}
        ></Movement>
      </Page>
    );
  }
}

export default withRouter(WorkoutMovement);
