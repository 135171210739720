import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Shevron2 = ({ color = styleConst.colors.white }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.29289 9.29274C6.68342 8.90222 7.31658 8.90222 7.70711 9.29274L12 13.5856L16.2929 9.29274C16.6834 8.90222 17.3166 8.90222 17.7071 9.29274C18.0976 9.68327 18.0976 10.3164 17.7071 10.707L12 16.4141L6.29289 10.707C5.90237 10.3164 5.90237 9.68327 6.29289 9.29274Z"
      fill={color}
    />
  </svg>
);

export default I24Shevron2;
