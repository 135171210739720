import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import {
  generatePath,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";

import OnboardingStore from "stores/OnboardingStore";

import { Page } from "components/Layout";
import { QuestionGroup } from "components/Questionnaire";
import Button from "components/Button";
import { SnackbarTypeValues } from "components/Snackbar";

import { NoInternetConnectionError } from "services/api";
import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";

import ROUTES from "navigation/routes";

import { Title, GroupContainer, ButtonContainer, Background } from "./styles";

type Props = RouteComponentProps & SnackbarContextProps;

@observer
class Onboarding extends React.Component<Props> {
  @observable isNoInternetConnectionError: boolean = false;

  @computed get groups() {
    return OnboardingStore.groups;
  }

  @computed get isLoading() {
    return OnboardingStore.isLoading;
  }

  async componentDidMount() {
    try {
      await OnboardingStore.fetch();
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }
  }

  onAnswer = (answer: any) => {
    OnboardingStore.pushAnswer(answer);
  };

  onSave = async () => {
    const { setMessage } = this.props;

    try {
      await OnboardingStore.publishAnswers();
      this.props.history.push(generatePath(ROUTES.TODAY));
    } catch (error: any) {
      console.log("error :>> ", error);
      setMessage(
        error?.message || "Something went wrong",
        SnackbarTypeValues.ALERT,
      );
    }
  };

  render() {
    return (
      <Page
        isNoInternetConnectionError={this.isNoInternetConnectionError}
        isLoading={this.isLoading}
        contentBackground={
          <>
            <Background />
            <Background leftDots />
          </>
        }
        disabled={true}
      >
        <Title>{"Answer the Questions"}</Title>
        {this.groups.map((group, index) => (
          <GroupContainer key={index} isLast={index === this.groups.length - 1}>
            <QuestionGroup
              group={group}
              onAnswer={this.onAnswer}
              store={OnboardingStore}
            />
          </GroupContainer>
        ))}
        <ButtonContainer>
          <Button
            loading={OnboardingStore.isManaging}
            width={320}
            text={"Save"}
            onClick={this.onSave}
          />
        </ButtonContainer>
      </Page>
    );
  }
}

export default withGlobalSnackbar(withRouter(Onboarding));
