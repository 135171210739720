import styled, { css } from "styled-components";

import backgroundImage from "assets/images/svg/cyan_dots_background.svg";

import {
  CARD_WIDTH,
  MEDIA_QUERIES,
  PAGE_CONTENT_WIDTH,
  PAGE_WIDTH,
} from "constants/layout";
import styleConst from "constants/style";

const CONTAINER_HEIGHT = "370px";

interface ICardBackground {
  imageUrl: string;
}

export const Background = styled.div`
  position: absolute;

  height: ${CONTAINER_HEIGHT};
  width: 100%;
  min-width: ${PAGE_WIDTH.SMALL_DESKTOP};

  overflow: hidden;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

export const Card = styled.div`
  position: relative;

  justify-content: flex-end;

  height: 160px;
  width: ${CARD_WIDTH.SMALL_DESKTOP};

  ${MEDIA_QUERIES.DESKTOP} {
    width: ${CARD_WIDTH.DESKTOP};
  }

  border-radius: 10px;

  overflow: hidden;

  cursor: pointer;
`;

export const CardBackground = styled.div<ICardBackground>`
  position: absolute;
  height: 100%;
  width: 100%;

  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `}
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;

  margin-left: 45px;

  position: absolute;
  z-index: 1;

  height: 160px;
  width: ${CARD_WIDTH.SMALL_DESKTOP};

  ${MEDIA_QUERIES.DESKTOP} {
    margin-left: 93px;

    width: ${CARD_WIDTH.DESKTOP};
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;

  min-height: ${CONTAINER_HEIGHT};

  min-width: ${PAGE_WIDTH.SMALL_DESKTOP};
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 0 auto;

  position: relative;
  z-index: 1;

  ${MEDIA_QUERIES.DESKTOP} {
    width: ${PAGE_CONTENT_WIDTH.DESKTOP};
    min-width: ${PAGE_CONTENT_WIDTH.DESKTOP};
  }

  ${MEDIA_QUERIES.MEDIUM_DESKTOP} {
    width: ${PAGE_CONTENT_WIDTH.SMALL_DESKTOP};
    min-width: ${PAGE_CONTENT_WIDTH.SMALL_DESKTOP};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 18px;

  width: 116px;
  height: 116px;
`;

export const Title = styled.p`
  font-size: 24px;
  font-family: ${styleConst.fonts.montserrat};
  font-weight: 600;
  color: ${styleConst.colors.white};
  text-transform: uppercase;
`;
