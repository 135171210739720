import React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import { RouteComponentProps, generatePath } from "react-router-dom";

import { Page } from "components/Layout";
import Tabs from "components/Tabs";

import PageSelector from "../PageSelector";
import MyRecipes from "../MyRecipes";
import MyFood from "../MyFood";
import Favorites from "../Favorites";

import ROUTES from "navigation/routes";

import { MY_ITEMS_TABS } from "constants/nutrition";

import { Container, PageControls } from "./styles";

type Props = {} & RouteComponentProps;

@observer
class MyItems extends React.Component<Props> {
  get pathname() {
    const {
      location: { pathname },
    } = this.props;

    return pathname;
  }

  get route() {
    return this.pathname ?? ROUTES.NUTRITION_MY_ITEMS_MY_RECIPES;
  }

  get activeTabName() {
    switch (this.route) {
      case ROUTES.NUTRITION_MY_ITEMS_MY_FOOD:
        return MY_ITEMS_TABS.MY_FOOD;
      case ROUTES.NUTRITION_MY_ITEMS_FAVORITES:
        return MY_ITEMS_TABS.FAVORITES;
      default:
        return MY_ITEMS_TABS.MY_RECIPES;
    }
  }

  @computed get isMyRecipesTab() {
    return this.route === ROUTES.NUTRITION_MY_ITEMS_MY_RECIPES;
  }

  @computed get isMyFoodTab() {
    return this.route === ROUTES.NUTRITION_MY_ITEMS_MY_FOOD;
  }

  @computed get isFavoritesTab() {
    return this.route === ROUTES.NUTRITION_MY_ITEMS_FAVORITES;
  }

  setActiveTab = (tab: string) => {
    const { history } = this.props;

    switch (tab) {
      case MY_ITEMS_TABS.MY_FOOD:
        history.push(generatePath(ROUTES.NUTRITION_MY_ITEMS_MY_FOOD));
        break;
      case MY_ITEMS_TABS.FAVORITES:
        history.push(generatePath(ROUTES.NUTRITION_MY_ITEMS_FAVORITES));
        break;
      default:
        history.push(generatePath(ROUTES.NUTRITION_MY_ITEMS_MY_RECIPES));
        break;
    }
  };

  render() {
    return (
      <Page
        title="Nutrition"
        titleControls={
          <PageControls>
            <PageSelector />
            <Tabs
              tabs={Object.values(MY_ITEMS_TABS)}
              activeTab={this.activeTabName}
              onClick={this.setActiveTab}
            />
          </PageControls>
        }
        titleControlsFullWidth
      >
        <Container>
          {this.isMyRecipesTab ? <MyRecipes /> : null}

          {this.isMyFoodTab ? <MyFood /> : null}

          {this.isFavoritesTab ? <Favorites /> : null}
        </Container>
      </Page>
    );
  }
}

export default MyItems;
