import React from "react";
import format from "date-fns/format";

import I24ChevronRight from "assets/icons/I24ChevronRight";
import I24ChevronLeft from "assets/icons/I24ChevronLeft";

import styleConst from "constants/style";

import { Arrows, Container, Title, ArrowContainer } from "./styles";

type Props = {
  date: Date;
  onNextClick: () => void;
  onPrevClick: () => void;
  fontSize?: number;
};

const Header: React.FC<Props> = ({
  date,
  onNextClick,
  onPrevClick,
  fontSize = 18,
}) => {
  return (
    <Container>
      <Title fontSize={fontSize}>{format(date, "MMMM yyyy")}</Title>
      <Arrows>
        <ArrowContainer onClick={onPrevClick}>
          <I24ChevronLeft
            color={styleConst.colors.black}
            width={16}
            height={16}
          />
        </ArrowContainer>
        <ArrowContainer onClick={onNextClick}>
          <I24ChevronRight
            color={styleConst.colors.black}
            width={16}
            height={16}
          />
        </ArrowContainer>
      </Arrows>
    </Container>
  );
};

export default React.memo(Header);
