import styled from "styled-components";
import "react-calendar/dist/Calendar.css";

import styleConst from "constants/style";

export const CalendarContainer = styled.div`
  .react-calendar {
    width: 100%;

    border: 0;
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: capitalize;

    font-size: 16px;
  }

  .react-calendar__tile--now {
    background: none;
    .today {
      visibility: visible;
    }

    abbr {
      color: ${styleConst.colors.black50};
    }
  }

  .react-calendar__tile {
    color: ${styleConst.colors.black50};

    position: relative;
    z-index: 5;
  }

  .react-calendar__month-view__days {
    .react-calendar__tile {
      height: 68.56px;

      font-family: ${styleConst.fonts.outfit};
      font-size: 18px;
      font-weight: 600;
      line-height: 22.68px;
      color: ${styleConst.colors.black50};
    }
  }

  .react-calendar__tile:hover,
  .react-calendar__tile:focus {
    background: none;
    .hoverCalendarCircle {
      visibility: visible;
    }
    &:not(.completed) abbr {
      color: ${styleConst.colors.primary};
    }
  }

  .react-calendar__tile--active {
    background: none;

    &:not(.completed) .hoverCalendarCircle {
      visibility: visible;
      border-color: ${styleConst.colors.dark} !important;
    }

    abbr {
      color: ${styleConst.colors.dark} !important;
    }
  }

  abbr {
    text-decoration: none;
    z-index: 10;
    position: relative;
  }

  .completed {
    abbr {
      color: white;
    }

    .calendarCircle {
      background: ${styleConst.colors.primary};
      border-radius: 0;
    }

    &.startingDay:not(.endingDay) {
      .calendarCircle {
        border-bottom-left-radius: 100px;
        border-top-left-radius: 100px;
        width: 65px;
        right: -2px;
      }
      .hoverCalendarCircle {
        visibility: hidden;
      }
      &:hover {
        .calendarCircle {
          background: ${styleConst.colors.primaryLight};
        }
        abbr {
          color: ${styleConst.colors.primary};
        }
      }
    }
    &.endingDay:not(.startingDay) {
      .calendarCircle {
        border-bottom-right-radius: 100px;
        border-top-right-radius: 100px;
        width: 65px;
        right: 5px;
      }
      .hoverCalendarCircle {
        visibility: hidden;
      }
      &:hover {
        .calendarCircle {
          background: ${styleConst.colors.primaryLight};
        }
        abbr {
          color: ${styleConst.colors.primary};
        }
      }
    }
    &.startingDay.endingDay {
      .calendarCircle {
        border-radius: 100px;
      }
      &.react-calendar__tile--active .hoverCalendarCircle {
        visibility: visible;
        border-color: ${styleConst.colors.dark} !important;
      }
    }
    &:not(.startingDay, .endingDay) {
      .calendarCircle {
        width: 69px;
        right: 0px;
      }
      .hoverCalendarCircle {
        visibility: hidden;
      }
      &:hover {
        .calendarCircle {
          background: ${styleConst.colors.primaryLight};
        }
        abbr {
          color: ${styleConst.colors.primary};
        }
      }
    }
  }

  .scheduled {
    .calendarCircle {
      background: ${styleConst.colors.primaryLight};
      border-radius: 100px;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    font-size: 18px;
    font-weight: 400;
    font-family: ${styleConst.fonts.roboto};
    line-height: 21.09px;
    color: ${styleConst.colors.black};
  }

  .calendarCircle {
    width: 46px;
    height: 46px;

    position: absolute;
    top: 11px;
    right: 11px;
    z-index: 1;

    border-radius: 100px;
  }

  .hoverCalendarCircle {
    width: 54px;
    height: 54px;

    position: absolute;
    top: 7px;
    right: 7px;

    border: 1px solid ${styleConst.colors.primary};
    border-radius: 100px;
    visibility: hidden;
  }

  .today {
    width: 20px;
    height: 4px;

    position: absolute;
    right: 24px;
    z-index: 2;

    background: ${styleConst.colors.dark};
    border-radius: 50px;
    visibility: hidden;
  }
`;
