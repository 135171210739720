import styled, { css } from "styled-components";

import backgroundImageSmall from "assets/images/svg/dots_background_small.svg";
import backgroundImage from "assets/images/svg/dots_background.svg";

import DefaultButton from "components/Button";
import DefaultFoodNutrientsRow from "components/FoodNutrientsRow";
import DefaultIconButton from "components/IconButton";
import DefaultInput from "components/Input";
import DefaultInlineListItem from "components/InlineListItem";

import styleConst from "constants/style";
import { MEDIA_QUERIES } from "constants/layout";

interface IBackground {
  leftDots?: boolean;
}

interface ISection {
  extended?: boolean;
}

interface IInlineListItem {
  width?: number;
}

export const Button = styled(DefaultButton)`
  width: 216px;

  background: radial-gradient(
      83.3% 179.17% at 56.29% -31.25%,
      ${styleConst.colors.primaryMedium} 0%,
      ${styleConst.colors.primary} 100%
    ),
    ${styleConst.colors.primary};
  box-shadow: 0px 20px 40px -10px ${styleConst.colors.shadow};
  border-radius: 10px;
  border: none;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-bottom: 100px;
`;

export const Section = styled.div<ISection>`
  width: 30%;

  ${({ extended }) =>
    extended &&
    css`
      width: 60%;
    `};
`;

export const NutritionFactsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-left: 20px;
`;

export const InlineListItem = styled(DefaultInlineListItem)<IInlineListItem>`
  width: ${({ width = 100 }) => width}%;
`;

export const TitleAdornment = styled.div`
  margin-right: 14px;

  width: 4px;
  height: 28px;
  background-color: ${styleConst.colors.secondary};
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 20px 0px;

  ${MEDIA_QUERIES.DESKTOP} {
    margin: 0 0 20px;
  }
`;

export const Title = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;
  color: ${styleConst.colors.text};
  line-height: 48px;
`;

export const Background = styled.div<IBackground>`
  position: absolute;
  top: 164px;

  z-index: -1;

  background-position: 60% 50%;

  height: 388px;
  width: 100%;

  overflow: hidden;
  background-repeat: no-repeat;

  background-image: url(${backgroundImageSmall});

  ${MEDIA_QUERIES.DESKTOP} {
    background-image: url(${backgroundImage});
  }

  ${({ leftDots }) =>
    leftDots &&
    css`
      transform: rotate(180deg);

      top: 967px;
    `}
`;

export const FoodNutrientsRow = styled(DefaultFoodNutrientsRow)`
  justify-content: space-between;

  padding: 0 56px;
  margin-bottom: 40px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 150px;
`;

export const IconButton = styled(DefaultIconButton)`
  width: 40px;
  height: 40px;

  margin-left: 10px;
`;

export const Input = styled(DefaultInput)`
  height: 58px;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;

  margin-left: 10px;
`;
