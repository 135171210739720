import styled, { css } from "styled-components";

import backgroundImageSmall from "assets/images/svg/dots_background_small.svg";
import backgroundImage from "assets/images/svg/dots_background.svg";

import { MEDIA_QUERIES } from "constants/layout";

interface IBackground {
  leftDots?: boolean;
}

export const Cards = styled.div`
  margin: 100px 0 60px;
`;

export const CollectionWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 60px;
  }
`;

export const Background = styled.div<IBackground>`
  position: absolute;
  top: 178px;
  z-index: -1;

  height: 388px;
  width: 100%;

  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  ${MEDIA_QUERIES.MEDIUM_DESKTOP} {
    background-image: url(${backgroundImageSmall});
  }

  ${MEDIA_QUERIES.DESKTOP} {
    background-image: url(${backgroundImage});
  }

  ${({ leftDots }) =>
    leftDots &&
    css`
      top: 607px;

      transform: rotate(180deg);
    `}
`;

export const GreenCardsWrapper = styled.div`
  position: relative;
  padding: 105px 11px;
`;
