import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 120px;
`;

export const PageControls = styled.div`
  display: flex;
  justify-content: space-between;
  
  width: 100%;`