import React from "react";
import { observer } from "mobx-react";

import { MenuItemContainer, MenuItemIcon, Text } from "./styles";

export type MenuItemType = {
  text: string;
  icon?: React.ReactNode;
  onClick?: () => void;
};

type Props = MenuItemType;

const MenuItem = ({ text, icon, onClick }: Props) => (
  <MenuItemContainer onClick={onClick}>
    {icon && <MenuItemIcon>{icon}</MenuItemIcon>}
    <Text>{text}</Text>
  </MenuItemContainer>
);

export default observer(MenuItem);
