import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import ResourcesStore from "stores/ResourcesStore";

import Page from "components/Layout/Page";
import { Collection } from "components/Collection";
import Spinner from "components/Spinner";
import Placeholder from "components/Placeholder";

import NavList from "./NavList";

import { NoInternetConnectionError } from "services/api";

import ROUTES from "navigation/routes";

import { COLLECTION_TYPE } from "constants/collection";
import { CONTAINER_SCROLLABLE_DIV_ID } from "constants/layout";

import {
  Background,
  Cards,
  CollectionWrapper,
  ScrollLoaderWrapper,
} from "./styles";

type Props = {} & RouteComponentProps;

@observer
class Resources extends React.Component<Props> {
  @observable page: number = 1;
  @observable isNoInternetConnectionError: boolean = false;

  @computed get items() {
    return ResourcesStore.items.filter(({ items }) => items.length !== 0);
  }

  @computed get needMore() {
    const { context } = ResourcesStore;
    return context && this.page < context.pages;
  }

  @computed get isLoading() {
    return (
      ResourcesStore.isLoading ||
      ResourcesStore.items.some(({ isLoading }) => isLoading)
    );
  }

  async componentDidMount() {
    try {
      await ResourcesStore.fetch(this.page);
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }
  }

  onLoadMore = async () => {
    if (this.needMore) {
      this.page++;
      await ResourcesStore.fetch(this.page);
    }
  };

  onViewAllClick = (categoryId?: number) => () => {
    const { history } = this.props;

    if (categoryId) {
      history.push(
        generatePath(ROUTES.RESOURCES_CATEGORY_DETAILS, {
          categoryId,
        }),
      );
    }
  };

  onNavigateToResource = (categoryId?: number) => (quickLink: any) => {
    const { history } = this.props;
    const { originalQuickLinkId: quickLinkId } = quickLink;

    if (categoryId) {
      history.push(
        generatePath(ROUTES.RESOURCES_CATEGORY_DETAILS_QUICK_LINK_DETAILS, {
          categoryId,
          quickLinkId,
        }),
      );
    }
  };

  render() {
    return (
      <Page
        title="Resources"
        isLoading={this.isLoading}
        isNoInternetConnectionError={this.isNoInternetConnectionError}
        topNavigation={<NavList />}
        contentBackground={
          <>
            <>
              <Background />
              <Background leftDots />
            </>
          </>
        }
      >
        <Cards>
          {this.items.length ? (
            <InfiniteScroll
              dataLength={this.items.length}
              hasMore={!!this.needMore}
              next={this.onLoadMore}
              scrollableTarget={CONTAINER_SCROLLABLE_DIV_ID}
              style={{ overflowY: "hidden" }}
              loader={
                <ScrollLoaderWrapper>
                  <Spinner large />
                </ScrollLoaderWrapper>
              }
            >
              {this.items.map(store => (
                <CollectionWrapper key={`${store.categoryId}-${store.title}`}>
                  <Collection
                    name={store.title ?? ""}
                    items={store.items}
                    type={COLLECTION_TYPE.RESOURCE}
                    isLoading={store.isLoading}
                    isMoreLoading={store.isMoreLoading}
                    onCardClick={this.onNavigateToResource(store.categoryId)}
                    onViewAllClick={this.onViewAllClick(store.categoryId)}
                    withTitleAdornment
                    withViewAll
                  />
                </CollectionWrapper>
              ))}
            </InfiniteScroll>
          ) : (
            <Placeholder text="No resources yet." />
          )}
        </Cards>
      </Page>
    );
  }
}

export default withRouter(Resources);
