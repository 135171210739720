import React from "react";
import { observable, computed } from "mobx";
import { observer } from "mobx-react";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import { Placeholders } from "assets/images/placeholders";

import FoodFavoritesStore from "stores/FoodFavoritesStore";

import List from "components/List";
import ListItemWrapper from "components/ListItemWrapper";
import Recipe from "components/Recipe";
import { MyFood as Actions } from "components/Actions";
import Spinner from "components/Spinner";
import Placeholder from "components/Placeholder/Placeholder";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";
import ConfirmPopup from "components/ConfirmPopup";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import { NoInternetConnectionError } from "services/api";

import ROUTES from "navigation/routes";

import styleConst from "constants/style";

import { Header, Title, TitleWrapper, LoaderContainer } from "./styles";

type Props = {} & RouteComponentProps & GlobalPopupProps;

@observer
class MyFood extends React.Component<Props> {
  @observable page: number = 1;
  @observable isNoInternetConnectionError: boolean = false;

  @computed get items() {
    return FoodFavoritesStore.items;
  }

  @computed get isLoading() {
    return FoodFavoritesStore.isLoading;
  }

  async componentDidMount() {
    try {
      await FoodFavoritesStore.fetch();
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }
  }

  getRecipeById = (id: number) => {
    return this.items.find(item => item.id === id);
  };

  onLoadMore = () => {
    if (FoodFavoritesStore.needMore) {
      this.page++;
      FoodFavoritesStore.fetch(this.page);
    }
  };

  onFoodDetailsNavigate = (item: any) => () => {
    const { history } = this.props;

    if (item.id) {
      history.push(
        generatePath(ROUTES.NUTRITION_MY_ITEMS_MY_FOOD_DETAILS, {
          recipeId: item.id,
        }),
      );
    }
  };

  onMark = () => async (id: number) => {
    const { openPopup } = this.props;

    openPopup({
      key: "ConfirmPopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <ConfirmPopup
          onClose={close}
          onAccept={async () => {
            await FoodFavoritesStore.removeFromFavorites(id);
            await FoodFavoritesStore.fetch();
            close();
          }}
          title={"Are you sure you want to remove selected food?"}
        />
      ),
    });
  };

  renderItem = (item: any) => (
    <ListItemWrapper
      key={item.id}
      onClick={this.onFoodDetailsNavigate(item)}
      controls={<Actions id={item.id} onRemove={this.onMark()} />}
    >
      <Recipe
        withoutImage
        style={{ height: 60, marginLeft: 20, marginTop: 10, marginBottom: 10 }}
        recipe={{ ...item, title: item.name }}
        onClick={this.onFoodDetailsNavigate(item)}
      />
    </ListItemWrapper>
  );

  render() {
    if (this.isNoInternetConnectionError) {
      return <NoInternetConnectionPlaceholder type={"element"} />;
    }

    if (this.isLoading) {
      return (
        <LoaderContainer>
          <Spinner color={styleConst.colors.primary} large />
        </LoaderContainer>
      );
    }

    return (
      <React.Fragment>
        <Header>
          <TitleWrapper>
            <Title>{"Food"}</Title>
          </TitleWrapper>
        </Header>

        {this.items.length ? (
          <List
            items={this.items}
            renderItem={this.renderItem}
            onLoadMore={this.onLoadMore}
            needMore={FoodFavoritesStore.needMore}
            style={{ overflow: "visible" }}
          />
        ) : (
          <Placeholder
            inline={false}
            text={"Food will be displayed here"}
            imageUrl={Placeholders.Favorites}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(withGlobalPopup(MyFood));
