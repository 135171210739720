import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IIconContainer {
  isOpened?: boolean;
}

export const Container = styled.div`
  margin-top: 10px;
  padding: 20px;

  background-color: ${styleConst.colors.white};
`;

export const Content = styled.div``;

export const IconContainer = styled.div<IIconContainer>`
  display: flex;

  cursor: pointer;

  ${({ isOpened }) =>
    isOpened
      ? css`
          transform: rotate(0deg);
        `
      : css`
          transform: rotate(90deg);
        `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 12px 61px;

  background-color: ${styleConst.colors.dark5};
`;

export const Title = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${styleConst.colors.dark};
`;
