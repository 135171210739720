import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IValue {
  overconsumption?: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const Nutrient = styled.div`
  display: flex;
  justify-content: space-between;
  width: 43%;

  &:not(:nth-last-child(2), :last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  padding: 20px 0 21px 0;
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 26px;
  font-family: ${styleConst.fonts.roboto};
  text-align: center;
  color: ${styleConst.colors.dark};
  font-weight: 400;
`;

export const Value = styled.div<IValue>`
  font-size: 16px;
  line-height: 26px;
  font-family: ${styleConst.fonts.roboto};
  text-align: center;
  font-weight: 600;

  ${({ overconsumption }) =>
    css`
      color: ${styleConst.colors[overconsumption ? "red" : "dark"]};
    `};
`;
