import React from "react";
import { computed } from "mobx";
import { observer } from "mobx-react";
import { SnapshotOrInstance } from "mobx-state-tree";

import SelectField from "components/Select";

import { Answer, Field } from "models/Questionnaire";

import { QuestionContainer, RequiredLabel } from "../styles";

type FieldItem = SnapshotOrInstance<typeof Field>;
type AnswerItem = SnapshotOrInstance<typeof Answer> & { value: string };

type Props = {
  field: FieldItem;
  onAnswer: (answer: AnswerItem) => void;
  parentFieldName?: string;
  store: any;
};

@observer
class Select extends React.Component<Props> {
  @computed get answer(): AnswerItem {
    const { field, store } = this.props;
    return store.getAnswersByField(field.name);
  }

  @computed get value() {
    if (Array.isArray(this.answer?.value)) {
      return this.answer.value.map((value: string) => ({
        value,
        label: value,
      }));
    } else {
      return [];
    }
  }

  @computed get options() {
    const { field: { options = [] } = {} } = this.props;

    return (
      options.map(({ value }) => ({
        label: value,
        value,
      })) ?? []
    );
  }

  get label() {
    const { field } = this.props;
    if (field.required) {
      return (
        <>
          {field.label}
          <RequiredLabel> *</RequiredLabel>
        </>
      );
    }

    return field.label;
  }

  onChange = (value: string) => {
    const { onAnswer, field, parentFieldName } = this.props;

    onAnswer({
      fieldName: field.name,
      value,
      parentFieldName,
    });
  };

  render() {
    const { field, store } = this.props;

    return (
      <QuestionContainer>
        <SelectField
          value={this.answer?.value ?? ""}
          onSelectOption={this.onChange}
          options={this.options}
          label={this.label ?? ""}
          error={store.getErrorByField(field.name)}
        />
      </QuestionContainer>
    );
  }
}

export default Select;
