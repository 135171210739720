import React from "react";

import I40Play from "assets/icons/I40Play";

import { PermissionPreview } from "components/Preview";
import TrimmedText from "components/TrimmedText";

import { formatDuration } from "utils/common";

import {
  Container,
  Content,
  Icon,
  Image,
  Info,
  Title,
  Subtitle,
} from "./styles";

type Props = {
  imageUrl: string;
  title: string;
  label?: string;
  duration?: number;
  subTitle?: string;
  isWorkout?: boolean;
  isPermissionPreview?: boolean;
  onClick?: () => void;
};

class Card extends React.Component<Props> {
  render() {
    const {
      subTitle,
      duration,
      imageUrl,
      label,
      title,
      isWorkout,
      isPermissionPreview,
      onClick,
    } = this.props;

    return (
      <Container>
        <PermissionPreview
          isPermissionPreview={isPermissionPreview}
          top={25}
          left={23}
        >
          <Content onClick={onClick}>
            <Image imageUrl={imageUrl} />
            {isWorkout && (
              <Icon>
                <I40Play />
              </Icon>
            )}
            <Info>
              <TrimmedText lines={1} text={label ?? title}>
                <Title>{label ?? title}</Title>
              </TrimmedText>
              {duration ? (
                <Subtitle>{formatDuration(duration)}</Subtitle>
              ) : null}
              {subTitle ? (
                <TrimmedText lines={1} text={subTitle}>
                  <Subtitle>{subTitle}</Subtitle>
                </TrimmedText>
              ) : null}
            </Info>
          </Content>
        </PermissionPreview>
      </Container>
    );
  }
}

export default Card;
