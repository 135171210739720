import { applySnapshot, flow, types } from "mobx-state-tree";

import api from "services/api";

const Data = types.model({
  bust: types.maybe(types.number),
  waist: types.maybe(types.number),
  hips: types.maybe(types.number),
  butt: types.maybe(types.number),
  thigh: types.maybe(types.number),
  arm: types.maybe(types.number),
});

const Measurements = types
  .model({
    measurements: types.optional(Data, {}),
    isLoading: types.optional(types.boolean, false),
    isUpdating: types.optional(types.boolean, false),
  })
  .actions(self => ({
    fetch: flow(function* () {
      try {
        self.isLoading = true;
        const measurements = yield api.get("/mobile/measurements");
        applySnapshot(self, { ...self, measurements });
        return measurements;
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    update: flow(function* (data) {
      try {
        self.isUpdating = true;
        const measurements = yield api.put("/mobile/measurements", data);
        applySnapshot(self, { ...self, measurements });
        return measurements;
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isUpdating = false;
      }
    }),
  }));

const MeasurementsStore = Measurements.create();

export default MeasurementsStore;
