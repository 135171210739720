import styled, { css } from "styled-components";

import Button from "components/Button";
import DefaultInput from "components/Input";

import styleConst from "constants/style";

interface IInput {
  withIcon?: boolean;
}

export const ButtonContainer = styled.div`
  position: absolute;
  right: 1px;
`;

export const Container = styled.div`
  display: flex;

  position: relative;
  width: 100%;
`;

export const IconContainer = styled.div`
  position: absolute;
  z-index: 3;
  right: 114px;
  top: 13px;

  cursor: pointer;
`;

export const Input = styled(DefaultInput)<IInput>`
  padding: 13px 97px 13px 21px;

  height: 50px;

  background-color: ${styleConst.colors.black2};
  border-radius: 10px;

  ${({ withIcon }) =>
    withIcon &&
    css`
      padding-right: 140px;
    `}
`;

export const SearchButton = styled(Button)`
  padding: 0 24px;
  height: 50px;

  background: radial-gradient(
      83.3% 179.17% at 56.29% -31.25%,
      ${styleConst.colors.primaryMedium} 0%,
      ${styleConst.colors.primary} 100%
    ),
    ${styleConst.colors.primary};

  box-shadow: 0px 20px 40px -10px rgba(97, 198, 194, 0.25);
  border-radius: 0px 10px 10px 0px;
  border: none;
`;
