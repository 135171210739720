import styled, { css } from "styled-components";

import { MEDIA_QUERIES } from "constants/layout";

interface IResponsiveWrapper {
  hasInfinityScroll?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  position: relative;
`;

export const ScrollLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ResponsiveWrapper = styled.div<IResponsiveWrapper>`
  ${({ hasInfinityScroll }) =>
    !hasInfinityScroll &&
    css`
      ${Container} {
        ${MEDIA_QUERIES.DESKTOP} {
          margin: 0 -12px;
        }
      }
    `}

  .responsive_infinity_scroll {
    ${MEDIA_QUERIES.DESKTOP} {
      margin: 0 -12px;
    }
  }
`;
