import React from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";

import I176TextLogo from "assets/icons/I176TextLogo";
import I20Calendar from "assets/icons/I20Calendar";

import { Header, NavMenu } from "components/Layout";
import Icon from "components/Icon";

import Profile from "./Profile";
import SearchControl from "./SearchControl";

import ROUTES from "navigation/routes";

import { ControlsContainer, ControlGroup, IconWrapper } from "./styles";

const NAVIGATION_MENU_ITEMS = [
  {
    title: "TODAY",
    route: ROUTES.TODAY,
    rootRoute: ROUTES.TODAY,
  },
  {
    title: "NUTRITION",
    route: ROUTES.NUTRITION_FOOD_LOG,
    rootRoute: ROUTES.NUTRITION,
  },
  {
    title: "STUDIO",
    route: ROUTES.STUDIO,
    rootRoute: ROUTES.STUDIO,
  },
  {
    title: "RESOURCES",
    route: ROUTES.RESOURCES,
    rootRoute: ROUTES.RESOURCES,
  },
];

type Props = {
  disabled?: boolean;
} & RouteComponentProps;

class AppHeader extends React.Component<Props> {
  onNavigateToSearch = () => {
    this.props.history.push(ROUTES.SEARCH);
  };

  onNavigateToCalendar = () => {
    this.props.history.push(ROUTES.CALENDAR);
  };

  render() {
    const { disabled } = this.props;
    return (
      <Header
        leftContent={
          <Link to={ROUTES.TODAY}>
            <I176TextLogo />
          </Link>
        }
        centerContent={<NavMenu items={NAVIGATION_MENU_ITEMS} />}
        rightContent={
          <ControlsContainer>
            <ControlGroup>
              <IconWrapper>
                <SearchControl />
              </IconWrapper>
              <IconWrapper onClick={this.onNavigateToCalendar}>
                <Icon icon={<I20Calendar />} />
              </IconWrapper>
            </ControlGroup>

            <ControlGroup>
              <Profile />
            </ControlGroup>
          </ControlsContainer>
        }
        isOverlay
        disabled={disabled}
      />
    );
  }
}

export default withRouter(AppHeader);
