import styled, { css } from "styled-components";

interface IBar {
  count: number;
  current: number;
  tintColor: string;
  height: number;
  width: number;
}

interface IContainer {
  backgroundColor: string;
  tintColor: string;
  height: number;
  width: number;
}

export const Bar = styled.div<IBar>`
  ${({ count, current, width }) =>
    count &&
    css`
      width: ${(width / count) * current}px;
    `};

  ${({ height }) =>
    css`
      height: ${height}px;
      border-radius: ${height}px;
    `};

  ${({ tintColor }) =>
    css`
      background-color: ${tintColor};
    `};
`;

export const Container = styled.div<IContainer>`
  ${({ height }) =>
    css`
      height: ${height}px;
    `};

  ${({ backgroundColor }) =>
    css`
      background-color: ${backgroundColor};
    `};

  ${({ width }) =>
    css`
      width: ${width}px;
    `};
`;
