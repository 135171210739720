import React from "react";

import I24Tick from "assets/icons/I24Tick";
import I32ArrowRight from "assets/icons/I32ArrowRight";
import I64FLogo from "assets/icons/I64FLogo";
import I196TextLogo from "assets/icons/I196TextLogo";

import Button from "components/Button";

import { LINKS } from "constants/links";
import styleConst from "constants/style";

import {
  ButtonWrapper,
  Container,
  Content,
  IconWrapper,
  Logo,
  TextLogo,
  Text,
  Possibilities,
  Possibility,
  PossibilityTitle,
} from "./styles";

type Props = { onClose: () => void };

class PromoteContentPopup extends React.Component<Props> {
  get possibilities() {
    return ["Recipes", "Workouts", "Resources"];
  }

  onClick = () => {
    const { onClose } = this.props;

    onClose();
    window.open(LINKS.MAIN_PAGE);
  };

  render() {
    return (
      <Container>
        <Content top>
          <Logo>
            <IconWrapper>
              <I64FLogo />
            </IconWrapper>
            <TextLogo>
              <I196TextLogo color={styleConst.colors.white} />
            </TextLogo>
          </Logo>
          <Text>Get unlimited access to FASTer exclusive</Text>
        </Content>
        <Content bottom>
          <Possibilities>
            {this.possibilities.map(title => (
              <Possibility key={title}>
                <IconWrapper contentIcon>
                  <I24Tick />
                </IconWrapper>
                <PossibilityTitle>{title}</PossibilityTitle>
              </Possibility>
            ))}
          </Possibilities>
          <ButtonWrapper>
            <Button
              text={"Learn more"}
              onClick={this.onClick}
              iconRight={<I32ArrowRight />}
              height={50}
            />
          </ButtonWrapper>
        </Content>
      </Container>
    );
  }
}

export default PromoteContentPopup;
