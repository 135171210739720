import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import {
  withRouter,
  RouteComponentProps,
  generatePath,
} from "react-router-dom";

/* @ts-ignore */
import todayImage from "assets/images/today.png";

import TodayStore from "stores/TodayStore";

import { Page } from "components/Layout";

import { Collection } from "components/Collection";

import Banner from "./Banner";
import GreenCards from "./GreenCards";

import { NoInternetConnectionError } from "services/api";

import ROUTES from "navigation/routes";

import { COLLECTION_TYPE } from "constants/collection";

import { Cards, CollectionWrapper, Background } from "./styles";

type Props = {} & RouteComponentProps;

@observer
class Today extends React.Component<Props> {
  @observable quickLinksPage: number = 1;
  @observable isNoInternetConnectionError: boolean = false;

  @computed get quickLinksNeedMore() {
    return TodayStore.needMoreQuickLinks;
  }

  async componentDidMount() {
    try {
      await Promise.all([TodayStore.fetch(), TodayStore.fetchQuickLinks()]);
    } catch (error: any) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }
  }

  @computed get imageUrl() {
    return TodayStore.bannerImageUrl ?? todayImage;
  }

  onQuickLinksLoadMore = async () => {
    if (this.quickLinksNeedMore) {
      this.quickLinksPage++;
      await TodayStore.fetchQuickLinks(this.quickLinksPage);
    }
  };

  onWorkoutNavigate = (workout: any) => {
    const { history } = this.props;
    const { id: workoutId, workoutInWorkoutCategoryId } = workout;

    history.push(
      generatePath(ROUTES.TODAY_WORKOUT_DETAILS, {
        workoutId,
        workoutInWorkoutCategoryId,
      }),
    );
  };

  onRecipeNavigate = (recipe: any) => {
    const { history } = this.props;
    const { id: recipeId } = recipe;

    history.push(generatePath(ROUTES.TODAY_RECIPE_DETAILS, { recipeId }));
  };

  onQuickLinkNavigate = (quickLink: any) => {
    const { history } = this.props;
    const { originalQuickLinkId: quickLinkId } = quickLink;

    history.push(
      generatePath(ROUTES.TODAY_QUICK_LINK_DETAILS, { quickLinkId }),
    );
  };

  render() {
    return (
      <Page
        isNoInternetConnectionError={this.isNoInternetConnectionError}
        isLoading={TodayStore.isLoading || TodayStore.quickLinks.isLoading}
        topBanner={
          <Banner
            name="Today"
            title={TodayStore.foodDayType?.name}
            imageUrl={this.imageUrl}
          />
        }
        bottomBanner={<GreenCards />}
        contentBackground={
          <>
            <Background />
            <Background leftDots />
          </>
        }
      >
        <Cards>
          {TodayStore.workouts.length ? (
            <CollectionWrapper>
              <Collection
                name={"Workouts"}
                items={TodayStore.workouts}
                onCardClick={this.onWorkoutNavigate}
                type={COLLECTION_TYPE.WORKOUT}
                withTitleAdornment
              />
            </CollectionWrapper>
          ) : null}
          {TodayStore.recipes.length ? (
            <CollectionWrapper>
              <Collection
                name={"Nutrition"}
                items={TodayStore.recipes}
                onCardClick={this.onRecipeNavigate}
                type={COLLECTION_TYPE.RECIPE}
                withTitleAdornment
              />
            </CollectionWrapper>
          ) : null}
          {TodayStore.quickLinks.items.length ? (
            <CollectionWrapper>
              <Collection
                name={"Quick Links"}
                items={TodayStore.quickLinks.items}
                onLoadMore={this.onQuickLinksLoadMore}
                onCardClick={this.onQuickLinkNavigate}
                isMoreLoading={TodayStore.quickLinks.isMoreLoading}
                type={COLLECTION_TYPE.RESOURCE}
                withTitleAdornment
              />
            </CollectionWrapper>
          ) : null}
        </Cards>
      </Page>
    );
  }
}

export default withRouter(Today);
