import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
  rotated?: boolean;
};

const I24ThumbsUp = ({
  color = styleConst.colors.white,
  rotated = false,
}: Props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 11.999c0-1.683-1.13-3-3-3h-3.073l.028-.076.182-.481V8.44l.16-.423c.19-.51.329-.922.441-1.329.17-.615.262-1.17.262-1.69 0-1.472-.908-2.386-2.252-2.777-.606-.177-1.133-.223-1.748-.223h-.48l-.3.374c-.339.422-1.005 1.183-1.773 2.061C9.03 6.053 7.267 8.068 6.57 9.082A2 2 0 006 9H4a2 2 0 00-2 2v9a2 2 0 002 2h2c.687 0 1.293-.347 1.653-.874.304.196.642.36 1.01.491a6.74 6.74 0 002.371.381L18 22c2.755 0 4-4.056 4-10zM9.337 19.734C8.452 19.417 8 18.901 8 18v-7c0-.24.044-.453.142-.68.174-.408 1.76-2.217 3.263-3.931.775-.885 1.529-1.745 2.055-2.374a3.5 3.5 0 01.73.128c.572.167.81.406.81.857 0 .317-.064.702-.19 1.159-.094.34-.216.701-.387 1.162l-.15.394v.002l-.194.514a8.11 8.11 0 00-.274.845c-.25 1 0 1.924 1.195 1.924h4c.691 0 1 .36 1 1 0 4.79-.985 8-2 8h-7.037a4.809 4.809 0 01-1.626-.265zM4 20v-9h2v9H4z"
      fill={color}
      transform={rotated ? "rotate(180 12 12)" : ""}
    />
  </svg>
);

export default I24ThumbsUp;
