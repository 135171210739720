import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { FlattenSimpleInterpolation } from "styled-components";
import { FieldInputProps } from "react-final-form";

import I24Eye from "assets/icons/I24Eye";
import I24EyeClose from "assets/icons/I24EyeClose";

import styleConst from "constants/style";

import {
  Container,
  IconContainer,
  TextInput,
  TextInputMask,
  Label,
  Error,
} from "./styles";

type FinalFormFieldProps = {
  input?: FieldInputProps<string>;
};

export type InputProps = {
  value?: any;
  label?: string | React.ReactElement;
  icon?: React.ReactElement | null;
  toggleableSecureText?: boolean;
  withoutErrorText?: boolean;
  error?: string;
  errorTextStyles?: FlattenSimpleInterpolation;
  inputStyles?: any;
  mask?: string;
  onChangeText?: (value: any) => void;
  required?: boolean;
  decimalNumbersOnly?: boolean;
  onlyPositiveDecimalNumbers?: boolean;
  numbersOnly?: boolean;
  onlyPositiveNumbers?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement> &
  FinalFormFieldProps;

const DECIMAL_NUMBER_PATTERN_DEFAULT = /^[-]?([0-9]+([.][0-9]*)?|[.][0-9]+)?$/;
const DECIMAL_POSITIVE_NUMBER_PATTERN = /^([0-9]+([.][0-9]*)?|[.][0-9]+)?$/;

const NUMBER_PATTERN_DEFAULT = /^[-]?([\d]+([.][\d]*)?)?$/;
const POSITIVE_NUMBER_PATTERN = /^([\d]+([.][\d]*)?)?$/;

@observer
class Input extends React.Component<InputProps> {
  @observable isSecuredText: boolean = true;
  @observable type = this.props.type ?? "text";

  @computed get hasValue() {
    return typeof this.value === "number" || this.value;
  }

  @computed get value() {
    const { value } = this.props;
    return value;
  }

  @computed get withIcon() {
    const { icon, toggleableSecureText } = this.props;
    return Boolean(icon) || toggleableSecureText;
  }

  onChange = ({ target }: any) => {
    const {
      onChangeText,
      decimalNumbersOnly,
      onlyPositiveDecimalNumbers,
      numbersOnly,
      onlyPositiveNumbers,
    } = this.props;

    if (!decimalNumbersOnly && !numbersOnly) {
      onChangeText?.(target.value);
      return;
    }

    let pattern;

    if (numbersOnly) {
      pattern = !onlyPositiveNumbers
        ? NUMBER_PATTERN_DEFAULT
        : POSITIVE_NUMBER_PATTERN;
    }

    if (decimalNumbersOnly) {
      pattern = !onlyPositiveDecimalNumbers
        ? DECIMAL_NUMBER_PATTERN_DEFAULT
        : DECIMAL_POSITIVE_NUMBER_PATTERN;
    }

    if (pattern && pattern.test(target.value)) {
      onChangeText?.(target.value);
      return;
    }

    onChangeText?.("");
  };

  onToggleSecuredText = () => {
    this.isSecuredText = !this.isSecuredText;
    this.type = this.isSecuredText ? "password" : "text";
  };

  render() {
    const {
      label,
      icon,
      toggleableSecureText,
      error,
      withoutErrorText,
      errorTextStyles,
      mask,
      input,
      required,
      placeholder,
      disabled,
      ...props
    } = this.props;

    const InputComponent: React.ElementType = mask ? TextInputMask : TextInput;

    return (
      <Container>
        <InputComponent
          {...props}
          {...input}
          withIcon={this.withIcon}
          type={this.type}
          value={this.value}
          onChange={this.onChange}
          error={error}
          mask={mask}
          placeholder={placeholder}
          disabled={disabled}
        />
        {label ? (
          <Label
            hasValue={this.hasValue}
            placeholder={placeholder}
            error={error}
            disabled={disabled}
          >
            {label}
            {required && " *"}
          </Label>
        ) : null}
        {icon ? <IconContainer>{icon}</IconContainer> : null}
        {toggleableSecureText ? (
          <IconContainer onClick={this.onToggleSecuredText}>
            {this.isSecuredText ? (
              <I24Eye color={styleConst.colors.black10} />
            ) : (
              <I24EyeClose color={styleConst.colors.black10} />
            )}
          </IconContainer>
        ) : null}
        {!withoutErrorText && error ? (
          <Error errorTextStyles={errorTextStyles}>{error}</Error>
        ) : null}
      </Container>
    );
  }
}

export default Input;
