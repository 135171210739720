import React from "react";
import {
  RouteComponentProps,
  generatePath,
  withRouter,
} from "react-router-dom";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";

import { Placeholders } from "assets/images/placeholders";

import RecipeLibraryStore from "stores/RecipeLibraryStore";

import { Page } from "components/Layout";
import List from "components/List";
import ListItemWrapper from "components/ListItemWrapper";
import Recipe from "components/Recipe";
import { Favorites as Actions } from "components/Actions";
import Placeholder from "components/Placeholder/Placeholder";
import { PermissionPreview, PublishStatusPreview } from "components/Preview";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";
import MealSchedulePopUp from "components/MealSchedulePopup";

import RecipeLibraryNavList from "../RecipeLibraryNavList";

import { NoInternetConnectionError, NotFoundError } from "services/api";

import { MEAL_SCHEDULE_TYPE } from "constants/nutrition";
import styleConst from "constants/style";

import { capitalize } from "utils/common";
import { redirectToRootRoute } from "utils/route";

import ROUTES from "navigation/routes";

import {
  Header,
  Title,
  TitleWrapper,
  ColumnTitle,
  ColumnTitleWrapper,
  SectionTitle,
  SectionTitleAdornment,
  SectionTitleContainer,
  Background,
  ItemWrapper,
} from "./styles";

type Params = {
  categoryId: string;
};

interface Props extends RouteComponentProps<Params>, GlobalPopupProps {}

const header = ["Carbs", "Fiber", "Fat", "Protein"];

@observer
class CategoryDetails extends React.Component<Props> {
  @observable page: number = 1;
  @observable isLoading: boolean = false;
  @observable isNoInternetConnectionError: boolean = false;

  get url() {
    const {
      match: { url },
    } = this.props;

    return url;
  }

  get categoryId() {
    const {
      match: {
        params: { categoryId },
      },
    } = this.props;

    return Number.parseInt(categoryId);
  }

  @computed get details() {
    return RecipeLibraryStore.details;
  }

  @computed get isWeekStructure() {
    return RecipeLibraryStore.details?.isWeekStructure;
  }

  @computed get items() {
    return RecipeLibraryStore.recipes;
  }

  @computed get sections() {
    return RecipeLibraryStore.sections;
  }

  @computed get needMore() {
    const { recipeContext } = RecipeLibraryStore;
    return recipeContext && this.page < recipeContext.pages;
  }

  async componentDidMount() {
    await this.fetch();
  }

  fetch = async () => {
    const { history } = this.props;

    this.isLoading = true;

    try {
      await RecipeLibraryStore.fetchRecipes({
        page: this.page,
        categoryId: this.categoryId,
      });
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
      if (error instanceof NotFoundError) {
        redirectToRootRoute(history, this.url);
      }
    } finally {
      this.isLoading = false;
    }
  };

  onLoadMore = async () => {
    if (this.needMore) {
      this.page++;
      await RecipeLibraryStore.fetchRecipes({
        page: this.page,
        categoryId: this.categoryId,
      });
    }
  };

  openMealSchedule = (mode: string, id: number) => {
    const { openPopup } = this.props;

    openPopup({
      key: "MealSchedulePopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <MealSchedulePopUp
          mode={mode}
          recipe={{ id, isRecipe: true }}
          onClose={close}
        />
      ),
    });
  };

  onToday = () => (id: number) => {
    this.openMealSchedule(MEAL_SCHEDULE_TYPE.TODAY, id);
  };

  onSelectDay = () => (id: number) => {
    this.openMealSchedule(MEAL_SCHEDULE_TYPE.SCHEDULE, id);
  };

  onMark = (isFavorite: boolean) => async (id: number) => {
    if (isFavorite) {
      RecipeLibraryStore.removeFromFavorites(id);
    } else {
      RecipeLibraryStore.addToFavorites(id);
    }
  };

  onRecipeDetailsNavigate = (recipe: any) => () => {
    const { history } = this.props;

    if (recipe?.id) {
      history.push(
        generatePath(ROUTES.NUTRITION_RECIPE_LIBRARY_RECIPE_DETAILS, {
          recipeId: recipe.id,
          categoryId: this.categoryId,
        }),
      );
    }
  };

  renderItem = (item: any) => (
    <ItemWrapper>
      <PermissionPreview
        top={25}
        left={34}
        isPermissionPreview={item.isPermissionPreview}
      >
        <PublishStatusPreview
          top={25}
          left={34}
          isPublishStatusPreview={item.isPublishStatusPreview}
        >
          <ListItemWrapper
            key={item.id}
            onClick={this.onRecipeDetailsNavigate(item)}
            controls={
              <Actions
                id={item.id}
                isFavorite={item.isFavorite}
                onToday={this.onToday()}
                onSelectDay={this.onSelectDay()}
                onMark={this.onMark(item.isFavorite)}
              />
            }
          >
            <Recipe
              style={{
                height: 60,
                marginLeft: 20,
                marginTop: 10,
                marginBottom: 10,
              }}
              recipe={item}
              onClick={this.onRecipeDetailsNavigate(item)}
            />
          </ListItemWrapper>
        </PublishStatusPreview>
      </PermissionPreview>
    </ItemWrapper>
  );

  renderHeader = () => (
    <Header>
      <TitleWrapper>
        <Title>{"Recipes"}</Title>
        <ColumnTitleWrapper>
          {header.map(item => (
            <ColumnTitle key={item}>{item}</ColumnTitle>
          ))}
        </ColumnTitleWrapper>
      </TitleWrapper>
    </Header>
  );

  renderPlaceholder = () => (
    <Placeholder imageUrl={Placeholders.Recipes} text="No recipes yet" />
  );

  render() {
    return (
      <Page
        isNoInternetConnectionError={this.isNoInternetConnectionError}
        isLoading={this.isLoading}
        title={this.details?.title}
        topNavigation={<RecipeLibraryNavList />}
        contentBackground={<Background />}
      >
        {this.isWeekStructure
          ? this.sections.map((section: any, index: number) => (
              <>
                <SectionTitleContainer>
                  <SectionTitleAdornment />
                  <SectionTitle>{capitalize(section.title)}</SectionTitle>
                </SectionTitleContainer>
                {section.data.length ? (
                  <>
                    {this.renderHeader()}
                    <List
                      key={index}
                      items={section.data}
                      renderItem={this.renderItem}
                      style={{ overflow: "visible" }}
                    />
                  </>
                ) : (
                  this.renderPlaceholder()
                )}
              </>
            ))
          : null}

        {!this.isWeekStructure ? (
          this.items.length ? (
            <>
              {this.renderHeader()}
              <List
                items={this.items}
                renderItem={this.renderItem}
                onLoadMore={this.onLoadMore}
                needMore={this.needMore}
                style={{ overflow: "visible" }}
              />
            </>
          ) : (
            this.renderPlaceholder()
          )
        ) : null}
      </Page>
    );
  }
}

export default withGlobalPopup(withRouter(CategoryDetails));
