import React from "react";

import NutrientsCircular from "components/NutrientsCircular";
import MacroGoalsPopup from "components/MacroGoalsPopup";
import Button from "components/Button";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";

import I24Chevron from "assets/icons/I24Chevron";

import {
  Container,
  Subtitle,
  Title,
  ButtonContainer,
  MacroGoalsWrapper,
} from "./styles";

import styleConst from "constants/style";

type Props = {
  foodDayType?: string;
  nutritionGoal: any;
  greyStyle?: boolean;
} & GlobalPopupProps;

class MacroGoals extends React.Component<Props> {
  onMacroGoalsNavigate = () => {
    const { openPopup } = this.props;

    openPopup({
      key: "MacroGoalsPopup",
      exitButtonColor: styleConst.colors.black25,
      exitButtonZIndex: 101,
      render: ({ close }) => <MacroGoalsPopup onClose={close} />,
    });
  };

  render() {
    const { foodDayType, nutritionGoal, greyStyle } = this.props;

    return (
      <Container>
        <MacroGoalsWrapper>
          <Title
            color={greyStyle ? styleConst.colors.dark : styleConst.colors.white}
          >
            Macro goals:
          </Title>
          <Subtitle
            color={greyStyle ? styleConst.colors.dark : styleConst.colors.white}
          >
            {foodDayType}
          </Subtitle>
          <ButtonContainer>
            <Button
              color={
                greyStyle ? styleConst.colors.primary : styleConst.colors.white
              }
              textColor={
                greyStyle ? styleConst.colors.white : styleConst.colors.primary
              }
              fontSize={14}
              fontWeight={600}
              text="More"
              height={26}
              width={90}
              onClick={this.onMacroGoalsNavigate}
              iconRight={
                <I24Chevron
                  color={
                    greyStyle
                      ? styleConst.colors.white
                      : styleConst.colors.primary
                  }
                />
              }
            />
          </ButtonContainer>
        </MacroGoalsWrapper>
        {nutritionGoal ? (
          <NutrientsCircular
            greyStyle={greyStyle}
            carbs={nutritionGoal.carbs}
            fiber={nutritionGoal.fiber}
            fat={nutritionGoal.fat}
            protein={nutritionGoal.protein}
          />
        ) : null}
      </Container>
    );
  }
}

export default withGlobalPopup(MacroGoals);
