import * as React from "react";

import style from "constants/style";

type Props = {
  color?: string;
};

const I24ArrowLeft = ({ color = style.colors.text }: Props) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 17 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7929 0.792893C15.1834 0.402369 15.8166 0.402369 16.2071 0.792893C16.5976 1.18342 16.5976 1.81658 16.2071 2.20711L14.7929 0.792893ZM1.5 15.5L0.792893 16.2071C0.402369 15.8166 0.402369 15.1834 0.792893 14.7929L1.5 15.5ZM16.2071 28.7929C16.5976 29.1834 16.5976 29.8166 16.2071 30.2071C15.8166 30.5976 15.1834 30.5976 14.7929 30.2071L16.2071 28.7929ZM16.2071 2.20711L2.20711 16.2071L0.792893 14.7929L14.7929 0.792893L16.2071 2.20711ZM2.20711 14.7929L16.2071 28.7929L14.7929 30.2071L0.792893 16.2071L2.20711 14.7929Z"
        fill={color}
      />
    </svg>
  );
};

export default I24ArrowLeft;
