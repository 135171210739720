export const TYPE = {
  UNKNOWN: "Unknown",
  PLAN_50: "Plan50",
  PLAN_45: "Plan45",
  PLAN_40: "Plan40",
  CUSTOM: "Custom",
};

export const TYPE_OPTIONS = {
  UNKNOWN: { label: "Unknown", value: TYPE.UNKNOWN },
  PLAN_50: { label: "Plan50", value: TYPE.PLAN_50 },
  PLAN_45: { label: "Plan45", value: TYPE.PLAN_45 },
  PLAN_40: { label: "Plan40", value: TYPE.PLAN_40 },
  CUSTOM: { label: "Custom", value: TYPE.CUSTOM },
};

export const TYPE_LABELS = {
  [TYPE.UNKNOWN]: "Unknown",
  [TYPE.PLAN_50]: "50/20/30",
  [TYPE.PLAN_45]: "45/25/30",
  [TYPE.PLAN_40]: "40/30/30",
  [TYPE.CUSTOM]: "Custom plan",
};
