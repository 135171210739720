import { applySnapshot, flow, types } from "mobx-state-tree";

import api from "services/api";

import { STRUCTURE_TYPE } from "constants/common";

import { getVimeoId, getVimeoIdHash } from "utils/links";

const Equipment = types.model("Equipment", {
  id: types.number,
  imageUrl: types.maybe(types.string),
  title: types.string,
});

const Category = types.model("Category", {
  id: types.number,
  imageUrl: types.maybe(types.string),
  title: types.string,
  subTitle: types.maybe(types.string),
  isPublished: types.optional(types.boolean, false),
  structureType: types.optional(
    types.enumeration(Object.values(STRUCTURE_TYPE)),
    STRUCTURE_TYPE.STANDARD,
  ),
  startDate: types.maybe(types.string),
});

const Movement = types
  .model("Movement", {
    id: types.number,
    title: types.string,
    imageUrl: types.maybe(types.string),
    videoUrl: types.maybe(types.string),
    videoFileUrl: types.maybe(types.string),
    duration: types.number,
    description: types.maybe(types.string),
  })
  .views(self => ({
    get vimeoId() {
      return getVimeoId(self.videoUrl);
    },
  }));

const Details = types
  .model("Details", {
    id: types.number,
    title: types.string,
    label: types.maybe(types.string),
    videoUrl: types.maybe(types.string),
    duration: types.maybe(types.number),
    instruction: types.maybe(types.string),
    imageUrl: types.maybe(types.string),
    equipments: types.array(Equipment),
    categories: types.array(Category),
    movements: types.array(Movement),
    downloadedVideoPath: types.maybe(types.string),
    videoFileUrl: types.maybe(types.string),
    isFavorite: types.optional(types.boolean, false),
    isDone: types.optional(types.boolean, false),
    isSchedule: types.optional(types.boolean, false),
    isDownloaded: types.optional(types.boolean, false),
    downloadProgress: types.optional(types.number, 0),
    isDownloading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get vimeoId() {
      return getVimeoId(self.videoUrl);
    },
    get vimeoIdHash() {
      return getVimeoIdHash(self.videoUrl);
    },
  }));

const WorkoutDetails = types
  .model("WorkoutDetails", {
    details: types.maybe(Details),
    isLoading: types.optional(types.boolean, false),
    isUpdating: types.optional(types.boolean, false),
  })
  .views(self => ({
    getMovementDetails(movementId: number) {
      if (self.details) {
        return self.details.movements.find(({ id }) => id === movementId);
      }
    },
  }))
  .actions(self => ({
    fetchWorkoutTitle: flow(function* ({
      categoryId,
      workoutId,
      dayOfWeek,
      workoutInWorkoutCategoryId,
    }: {
      workoutId: number;
      categoryId?: number;
      dayOfWeek?: number;
      workoutInWorkoutCategoryId?: number;
    }) {
      try {
        const url = dayOfWeek
          ? `/mobile/workout/category/${categoryId}/workout/${workoutId}`
          : `/mobile/workout/in/category/${workoutInWorkoutCategoryId}`;
        const payload = dayOfWeek ? { DayOfWeek: dayOfWeek } : undefined;

        const { label } = yield api.get(url, payload);

        if (self.details) {
          self.details.label = label;
        }
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      }
    }),
  }))
  .actions(self => ({
    fetch: flow(function* ({
      categoryId,
      workoutId,
      scheduleDate,
      dayOfWeek,
      workoutInWorkoutCategoryId,
    }: {
      categoryId?: number;
      workoutId: number;
      dayOfWeek?: number;
      scheduleDate?: string;
      workoutInWorkoutCategoryId?: number;
    }) {
      try {
        self.isLoading = true;
        const details = yield api.get(`/mobile/workout/${workoutId}`, {
          scheduleDate,
        });
        applySnapshot(self, { ...self, details });

        if ((categoryId && dayOfWeek) || workoutInWorkoutCategoryId) {
          yield self.fetchWorkoutTitle({
            categoryId,
            workoutId,
            dayOfWeek,
            workoutInWorkoutCategoryId,
          });
        }
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    change: flow(function* (data) {
      if (self.details) {
        try {
          self.isUpdating = true;
          yield api.put(`/mobile/v2/workout/${self.details.id}/detail`, data);
        } catch (error) {
          console.log("error :>> ", error);
          throw error;
        } finally {
          self.isUpdating = false;
        }
      }
    }),

    complete: flow(function* (data) {
      if (self.details) {
        try {
          self.isUpdating = true;
          yield api.post(`/mobile/v2/workout/${self.details.id}/done`, data);
          self.details.isDone = true;
        } catch (error) {
          console.log("error :>> ", error);
          throw error;
        } finally {
          self.isUpdating = false;
        }
      }
    }),

    undoComplete: flow(function* (scheduleDate?: string) {
      if (self.details) {
        try {
          self.isUpdating = true;
          yield api.post(`/mobile/workout/${self.details.id}/undo`, {
            scheduleDate,
          });
          self.details.isDone = false;
        } catch (error) {
          console.log("error :>> ", error);
          throw error;
        } finally {
          self.isUpdating = false;
        }
      }
    }),

    addToFavorites: flow(function* () {
      if (self.details) {
        try {
          self.isUpdating = true;
          yield api.post(`/mobile/workout/${self.details.id}/favorite`, {});
          self.details.isFavorite = true;
        } catch (error) {
          console.log("error :>> ", error);
          throw error;
        } finally {
          self.isUpdating = false;
        }
      }
    }),

    removeFromFavorites: flow(function* () {
      if (self.details) {
        try {
          self.isUpdating = true;
          yield api.delete(`/mobile/workout/${self.details.id}/favorite`);
          self.details.isFavorite = false;
        } catch (error) {
          console.log("error :>> ", error);
          throw error;
        } finally {
          self.isUpdating = false;
        }
      }
    }),

    schedule: flow(function* (scheduleDate: string) {
      if (self.details) {
        try {
          self.isUpdating = true;
          yield api.post(`/mobile/workout/${self.details.id}/schedule`, {
            scheduleDate,
          });
        } catch (error) {
          console.log("error :>> ", error);
          throw error;
        } finally {
          self.isUpdating = false;
        }
      }
    }),

    removeSchedule: flow(function* (scheduleDate: string) {
      if (self.details) {
        try {
          self.isUpdating = true;
          yield api.delete(`/mobile/workout/${self.details.id}/schedule`, {
            scheduleDate,
          });
        } catch (error) {
          console.log("error :>> ", error);
          throw error;
        } finally {
          self.isUpdating = false;
        }
      }
    }),

    reset() {
      applySnapshot(self, {});
    },
  }));

const WorkoutDetailsStore = WorkoutDetails.create();

export default WorkoutDetailsStore;
