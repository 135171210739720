import { flow, types, applySnapshot, getSnapshot } from "mobx-state-tree";

import api from "services/api";

import { Ingredient } from "models/Recipe";
import { Context } from "models/index";

import { convertSearchString, uniqArray } from "utils/common";

const FoodManual = types
  .model("FoodManual", {
    searchString: types.optional(types.string, ""),
    context: types.maybe(Context),
    items: types.array(Ingredient),
    details: types.maybe(Ingredient),
    barcodeItemDetails: types.maybe(Ingredient),
    isLoading: types.optional(types.boolean, false),
    isMoreLoading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get needMore() {
      if (self.context) {
        return self.context.page < self.context.pages;
      }
    },
  }))
  .actions(self => ({
    updateDetails(details: any) {
      applySnapshot(self, { ...self, details });
    },

    updateBarcodeItemDetails(barcodeItemDetails: any) {
      applySnapshot(self, { ...self, barcodeItemDetails });
    },
  }))
  .actions(self => ({
    search: flow(function* (page: number = 1) {
      try {
        if (page > 1) {
          self.isMoreLoading = true;
        } else {
          self.isLoading = true;
        }

        const payload = {
          Search: convertSearchString(self.searchString),
          page,
        };

        const { items, context } = yield api.get(
          `/mobile/v3/food/search`,
          payload,
        );

        const nextItems = page > 1 ? self.items.concat(items) : items;
        const uniquesNextItems = uniqArray(nextItems, "id");

        applySnapshot(self, {
          ...self,
          context,
          items: uniquesNextItems,
        });
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        if (page > 1) {
          self.isMoreLoading = false;
        } else {
          self.isLoading = false;
        }
      }
    }),

    barcodeSearch: flow(function* () {
      self.isLoading = true;

      try {
        if (self.searchString) {
          const details = yield api.get(`/mobile/v3/food/search-barcode`, {
            Barcode: self.searchString,
          });

          self.updateBarcodeItemDetails(details);
        }
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    fetchDetails: flow(function* (id: number) {
      self.isLoading = true;

      try {
        const details = yield api.get(`/mobile/v3/food/${id}`);

        self.updateDetails(details);
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    setSearchString(searchString: string) {
      applySnapshot(self, { ...self, searchString });
    },

    clear() {
      applySnapshot(self, { ...self, searchString: "", items: [] });
    },

    updateItem(id: string, food: any) {
      const { items } = getSnapshot(self);

      const index = items.findIndex(item => item.id === id);

      self.items[index] = {
        ...items[index],
        ...food,
      };

      if (self.details && self.details.id === id) {
        self.details = { ...self.details, ...food };
      }

      if (self.barcodeItemDetails && self.barcodeItemDetails.id === id) {
        self.barcodeItemDetails = { ...self.barcodeItemDetails, ...food };
      }
    },

    resetDetails() {
      applySnapshot(self, {
        ...self,
        details: undefined,
        barcodeItemDetails: undefined,
      });
    },

    resetResult() {
      applySnapshot(self, {
        ...self,
        items: undefined,
        searchString: undefined,
      });
    },

    reset() {
      applySnapshot(self, { ...self, items: undefined, details: undefined });
    },
  }));

const FoodManualStore = FoodManual.create();

export default FoodManualStore;
