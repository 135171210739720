import styled, { css } from "styled-components";

import IconButton from "components/IconButton";

import styleConst from "constants/style";

interface IExitButton {
  exitButtonZIndex?: number;
}

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;

  width: 100%;
  height: 100%;

  margin: auto;
  background-color: ${styleConst.colors.black50};

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;
`;

export const ExitButton = styled(IconButton)<IExitButton>`
  position: absolute;
  right: 20px;
  top: 20px;

  background-color: transparent;
  min-height: 32px;
  min-width: 32px;

  ${({ exitButtonZIndex }) =>
    exitButtonZIndex !== undefined &&
    css`
      z-index: ${exitButtonZIndex};
    `}
`;

export const Content = styled.div`
  max-height: 100%;
  overflow-y: auto;
  overflow-y: overlay;

  align-self: center;

  position: relative;

  background: #ffffff;
  box-shadow: 5px 20px 30px ${styleConst.colors.shadow};
  border-radius: 10px;
`;
