import React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";

import FoodLogStore from "stores/FoodLogStore";

import CircularProgress from "./components/CircularProgress";

import { round } from "utils/common";

import { Container } from "./styles";

type Param = {
  value: number;
  goal: number;
};

type Props = {
  carbs: Param;
  fiber: Param;
  fat: Param;
  protein: Param;
  greyStyle?: boolean;
};

@observer
class NutrientsCircular extends React.Component<Props> {
  @computed get isLowCarbDay() {
    return FoodLogStore.summary?.isLowCarbDay;
  }

  getValue = (value: any) => {
    value = round(value) ?? 0;
    return value < 0 ? 0 : value;
  };

  render() {
    const { carbs, fiber, fat, protein, greyStyle } = this.props;

    return (
      <Container>
        <CircularProgress
          label={this.isLowCarbDay ? "Net Carbs" : "Total Carbs"}
          current={this.getValue(carbs.value)}
          goal={carbs.goal}
          greyStyle={greyStyle}
        />
        <CircularProgress
          label="Fiber"
          current={this.getValue(fiber.value)}
          goal={fiber.goal}
          withoutOverconsumption
          greyStyle={greyStyle}
        />
        <CircularProgress
          label="Fat"
          current={this.getValue(fat.value)}
          goal={fat.goal}
          greyStyle={greyStyle}
        />
        <CircularProgress
          label="Protein"
          current={this.getValue(protein.value)}
          goal={protein.goal}
          greyStyle={greyStyle}
        />
      </Container>
    );
  }
}
export default NutrientsCircular;
