import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { format, startOfDay } from "date-fns";

import FoodLogStore from "stores/FoodLogStore";

import Button from "components/Button";
import Spinner from "components/Spinner";
import Calendar from "components/Calendar";
import Input from "components/Input";
import MealItem from "./components/MealItem";
import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";
import { SnackbarTypeValues } from "components/Snackbar";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import { NoInternetConnectionError } from "services/api";

import { FOOD_LOG_SOURCE, MEAL_SCHEDULE_TYPE } from "constants/nutrition";
import styleConst from "constants/style";

import { SERVER_DATE_FORMAT } from "utils/date";

import {
  Container,
  Title,
  MealContainer,
  SelectedDate,
  LoaderContainer,
  CalendarContainer,
  InputContainer,
} from "./styles";

interface Props extends SnackbarContextProps {
  mode: "Today" | "Schedule";
  recipe: any;
  onClose: () => void;
}

@observer
class MealSchedulePopUp extends React.Component<Props> {
  @observable selectedDate: Date | undefined = undefined;
  @observable preSelectedDate: Date | undefined = undefined;
  @observable selectedMeal: number | undefined = undefined;

  @observable numberOfServingsValue: string = "1";

  @observable showNumberOfServings: boolean = false;

  @observable isLoading: boolean = false;
  @observable isNoInternetConnectionError: boolean = false;

  get mode() {
    const { mode } = this.props;
    return mode;
  }

  @computed get meals() {
    return FoodLogStore.items;
  }

  @computed
  get markedDay() {
    if (this.preSelectedDate) {
      const date: { [date: string]: any } = {};
      const formattedDay = format(this.preSelectedDate, SERVER_DATE_FORMAT);

      date[formattedDay] = {
        completed: true,
        startingDay: true,
        endingDay: true,
      };

      return date;
    }

    return {};
  }

  @computed get formattedPreSelectedDay() {
    return format(
      startOfDay(this.preSelectedDate ?? new Date()),
      SERVER_DATE_FORMAT,
    );
  }

  @computed get formattedSelectedDay() {
    return format(
      startOfDay(this.selectedDate ?? new Date()),
      SERVER_DATE_FORMAT,
    );
  }

  get isTodayMode() {
    return this.mode === MEAL_SCHEDULE_TYPE.TODAY;
  }

  get isScheduleMode() {
    return this.mode === MEAL_SCHEDULE_TYPE.SCHEDULE;
  }

  get recipe() {
    const { recipe } = this.props;
    return recipe;
  }

  get title() {
    return this.selectedDate ? "Select a meal" : "Select a day";
  }

  @computed get source() {
    if (this.recipe.isRecipe) {
      return FOOD_LOG_SOURCE.RECIPE;
    }
    if (this.recipe.isCustomRecipe) {
      return FOOD_LOG_SOURCE.MY_RECIPE;
    }
    if (this.recipe.isFood) {
      return FOOD_LOG_SOURCE.SUGGESTIC;
    }
    return "";
  }

  componentDidMount() {
    if (this.isTodayMode) {
      this.fetchMeals();
    }
  }

  fetchMeals = async () => {
    this.selectedDate = new Date();
    this.preSelectedDate = this.selectedDate;

    try {
      this.isLoading = true;
      await FoodLogStore.fetchItems(this.formattedSelectedDay);
    } catch (error) {
      console.log("error ->", error);
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    } finally {
      this.isLoading = false;
    }
  };

  onMealClick = (mealId: number) => {
    this.selectedMeal = mealId;
  };

  onSubmit = async () => {
    const { onClose, setMessage } = this.props;

    if (this.selectedDate && this.selectedMeal) {
      try {
        let payload: any = {
          amount: this.numberOfServingsValue,
          foodMealTypeId: this.selectedMeal,
          foodRecipeId: this.recipe?.internalId ?? this.recipe?.id,
        };

        if (this.recipe.isRecipe) {
          payload = { ...payload, foodRecipeId: this.recipe.id };
        }

        if (this.recipe.isCustomRecipe) {
          payload = {
            ...payload,
            foodCustomRecipeId: this.recipe.id,
            foodRecipeId: null,
          };
        }

        if (this.recipe.isFood) {
          payload = {
            ...payload,
            food: { ...this.recipe },
            foodRecipeId: null,
          };
        }
        if (this.recipe.isRestaurantMenuItem) {
          payload = { ...payload, restaurantMenuItem: this.recipe };
        }

        await FoodLogStore.addToFoodLog(this.formattedSelectedDay, payload);

        onClose();
      } catch (error: any) {
        setMessage(error?.message, SnackbarTypeValues.ALERT);
      }
    }
  };

  onNextClick = async () => {
    if (!this.selectedDate && this.preSelectedDate) {
      this.selectedDate = this.preSelectedDate;
      try {
        await FoodLogStore.fetchItems(this.formattedPreSelectedDay);
      } catch (error) {
        if (error instanceof NoInternetConnectionError) {
          this.isNoInternetConnectionError = true;
        }
      }
    }
  };

  onDayClick = (date: Date) => {
    this.preSelectedDate = date;
  };

  onChangeNumberOfServings = (value: string) => {
    this.numberOfServingsValue = value;
  };

  onConfirmClick = () => {
    if (this.recipe.isFood) {
      this.onSubmit();
      return;
    }

    this.showNumberOfServings = true;
  };

  render() {
    const { onClose } = this.props;

    if (this.isNoInternetConnectionError) {
      return (
        <Container height={700}>
          <NoInternetConnectionPlaceholder type={"popup"} onSubmit={onClose} />
        </Container>
      );
    }

    if (this.showNumberOfServings) {
      return (
        <Container>
          <Title>{"Select the number\nof servings"}</Title>
          <InputContainer>
            <Input
              decimalNumbersOnly
              onlyPositiveDecimalNumbers
              label={"Servings"}
              value={this.numberOfServingsValue}
              onChangeText={this.onChangeNumberOfServings}
            />
          </InputContainer>
          <Button
            loading={FoodLogStore.isLoading}
            text="Save"
            disabled={!Number(this.numberOfServingsValue)}
            onClick={this.onSubmit}
          />
        </Container>
      );
    }

    return (
      <Container>
        <Title>{this.title}</Title>
        {this.selectedDate ? (
          <MealContainer>
            <SelectedDate>
              {`${format(this.selectedDate, `EEEE`)}\n${format(
                this.selectedDate,
                `MMMM dd, yyyy`,
              )}`}
            </SelectedDate>
            {this.isLoading ? (
              <LoaderContainer>
                <Spinner color={styleConst.colors.primary} />
              </LoaderContainer>
            ) : (
              this.meals.map(meal => (
                <MealItem
                  key={`${meal.foodMealType.id}-${meal.foodMealType.name}`}
                  onClick={this.onMealClick}
                  isSelected={this.selectedMeal === meal.foodMealType.id}
                  mealType={meal.foodMealType}
                  recipesCount={meal.items.length}
                />
              ))
            )}
          </MealContainer>
        ) : (
          <CalendarContainer>
            <Calendar
              value={this.preSelectedDate}
              onClickDay={this.onDayClick}
            />
          </CalendarContainer>
        )}
        <Button
          text={this.selectedDate ? "Confirm" : "Next"}
          loading={FoodLogStore.isLoading}
          onClick={this.selectedDate ? this.onConfirmClick : this.onNextClick}
          disabled={
            !this.preSelectedDate || (this.selectedDate && !this.selectedMeal)
          }
        />
      </Container>
    );
  }
}

export default withGlobalSnackbar(MealSchedulePopUp);
