import React from "react";

import Button from "components/Button";

import {
  ButtonWrapper,
  Container,
  Content,
  Text,
  ContentWrapper,
  Controls,
} from "./styles";

type Props = {
  title: string;
  children: React.ReactNode;
  onClose: () => void;
  onAccept: () => void;
};

class SuggestPopup extends React.Component<Props> {
  render() {
    const { onAccept, onClose, title, children } = this.props;
    return (
      <Container>
        <Content top>
          <Text>{title}</Text>
        </Content>
        <Content bottom>
          <ContentWrapper>{children}</ContentWrapper>
          <Controls>
            <ButtonWrapper>
              <Button text={"Cancel"} onClick={onClose} height={50} />
            </ButtonWrapper>
            <ButtonWrapper>
              <Button text={"OK"} onClick={onAccept} height={50} />
            </ButtonWrapper>
          </Controls>
        </Content>
      </Container>
    );
  }
}

export default SuggestPopup;
