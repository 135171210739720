export const OPERATOR_TYPE = {
  IS_NOT_EMPTY: "IS_NOT_EMPTY",
  SET_ANY: "SET_ANY",
};

export const PHOTO_FIELD_NAME = "default_photo_field";

export const PASSOWRD_FIELD_NAMES = {
  NEW_PASSWORD: "new_password",
  CONFIRM_PASSWORD: "confirm_password",
};

export const DEFAULT_GROUPS = [
  {
    fields: [
      {
        description: "",
        label: "",
        name: PHOTO_FIELD_NAME,
        type: "photo",
        required: false,
      },
    ],
  },
];

export const PASSWORD_GROUPS = [
  {
    fields: [
      {
        description: "",
        label: "New password",
        name: PASSOWRD_FIELD_NAMES.NEW_PASSWORD,
        type: "password",
        required: false,
      },
      {
        description: "",
        label: "Confirm password",
        name: PASSOWRD_FIELD_NAMES.CONFIRM_PASSWORD,
        type: "password",
        required: false,
      },
    ],
  },
];

export const PASSWORD_ANSWERS = [
  { fieldName: PASSOWRD_FIELD_NAMES.NEW_PASSWORD, value: "" },
  { fieldName: PASSOWRD_FIELD_NAMES.CONFIRM_PASSWORD, value: "" },
];
