import styled, { css } from "styled-components";

import DefaultButton from "components/Button";
import { IconLeft, Label } from "components/Button/styles";

import { MEDIA_QUERIES, PAGE_WIDTH } from "constants/layout";
import styleConst from "constants/style";

interface IButton {
  backgroundColor?: string;
}

interface IImage {
  imageUrl?: string;
}

interface ISection {
  isEquipment?: boolean;
}

export const Button = styled(DefaultButton)<IButton>`
  color: ${styleConst.colors.black50};

  ${IconLeft} {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 15px;

    width: 60px;
    height: 60px;
    border-radius: 50%;

    ${({ backgroundColor }) =>
      backgroundColor &&
      css`
        background-color: ${backgroundColor};
      `}
  }

  ${Label} {
    color: ${styleConst.colors.dark};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  margin: 84px 100px 84px;
`;

export const ControlWrapper = styled.div``;

export const Equipments = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin: 11px 0;
`;

export const EquipmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0 64px 10px 0;
`;

export const EquipmentImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 10px;

  height: 50px;
  width: 50px;

  background-color: ${styleConst.colors.primary10};
  border-radius: 50%;
`;

export const EquipmentImage = styled.div<IImage>`
  height: 24px;
  width: 24px;

  background-size: contain;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `}
`;

export const EquipmentText = styled.p`
  font-family: ${styleConst.fonts.roboto};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;

  color: ${styleConst.colors.black50};
`;

export const InfoContainer = styled.div`
  margin-top: 63px;
`;

export const HorizontalSeparator = styled.div`
  position: absolute;
  top: 642px;
  left: 0;

  width: 100%;
  height: 1px;
  min-width: ${PAGE_WIDTH.SMALL_DESKTOP};

  background-color: ${styleConst.colors.black10};

  ${MEDIA_QUERIES.DESKTOP} {
    min-width: ${PAGE_WIDTH.DESKTOP};
  }
`;

export const Section = styled.div<ISection>`
  margin-bottom: 100px;

  ${({ isEquipment }) =>
    isEquipment &&
    css`
      margin-bottom: 90px;
    `}
`;

export const Text = styled.p`
  font-family: ${styleConst.fonts.roboto};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const TextWrapper = styled.div`
  margin: 0 11px 30px;
`;

export const TitleAdornment = styled.div`
  margin-right: 14px;

  width: 4px;
  height: 28px;
  background-color: ${styleConst.colors.secondary};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 0 11px 20px;

  ${MEDIA_QUERIES.DESKTOP} {
    margin: 0 0 20px;
  }
`;

export const Title = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;
  color: ${styleConst.colors.text};
  line-height: 48px;
`;

export const Workout = styled.div`
  display: flex;
  flex-direction: row;

  min-height: 495.89px;

  padding-bottom: 59.73px;
  padding-left: 11px;
  padding-right: 11px;
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 700px;
`;

export const Image = styled.div<IImage>`
  width: 780px;
  height: 436px;

  border-radius: 10px;
  background-color: ${styleConst.colors.black4};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `}
`;
