import React from "react";
import { Portal } from "react-portal";

import I32Cross from "assets/icons/I32Cross";

import OutsideClickHandler from "components/OutsideClickHandler";

import { Container, Content, ExitButton } from "./styles";

type Props = {
  children: React.ReactNode;
  onClose: () => void;
  canCloseByOuterClick?: boolean;
  withoutExitButton?: boolean;
  exitButtonZIndex?: number;
  exitButtonColor?: string;
};

class Popup extends React.Component<Props> {
  onClickOutside = () => {
    const { canCloseByOuterClick, onClose } = this.props;
    canCloseByOuterClick && onClose();
  };

  render() {
    const {
      children,
      onClose,
      exitButtonZIndex,
      exitButtonColor,
      withoutExitButton,
    } = this.props;

    return (
      <Portal>
        <Container>
          <OutsideClickHandler onClick={this.onClickOutside}>
            <Content>
              {!withoutExitButton && (
                <ExitButton
                  icon={<I32Cross color={exitButtonColor} />}
                  onClick={onClose}
                  exitButtonZIndex={exitButtonZIndex}
                />
              )}
              {children}
            </Content>
          </OutsideClickHandler>
        </Container>
      </Portal>
    );
  }
}

export default Popup;
