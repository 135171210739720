import styled from "styled-components";

import DefaultIconButton from "components/IconButton";

import styleConst from "constants/style";

export const CheckboxContainer = styled.div`
  margin-bottom: 20px;
`;

export const CheckboxWrapper = styled.p`
  margin-top: 9px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

export const PhotoContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  margin-bottom: 20px;

  align-items: center;
  justify-content: space-around;
`;

export const PhotoWrapper = styled.div`
  position: relative;

  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PhotoImage = styled.img`
  width: 168px;
  height: 168px;

  object-fit: cover;
  border-radius: 500px;
`;

export const PhotoCircle = styled.div`
  width: 168px;
  height: 168px;

  padding: 17px;

  border-radius: 500px;

  background-color: ${styleConst.colors.dark5};
`;

export const PhotoBorder = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  width: 134px;
  height: 134px;

  border-radius: 500px;
  border: 1px dashed ${styleConst.colors.black25};
`;

export const PhotoSubTitle = styled.p`
  margin-top: 7px;

  font-size: 14px;
  line-height: 20px;

  text-align: center;
  letter-spacing: 0.01em;

  white-space: pre-wrap;

  color: ${styleConst.colors.black50};
`;

export const PhotoTitle = styled.p`
  font-size: 16px;
  line-height: 20px;

  text-align: center;
  letter-spacing: 0.01em;

  color: ${styleConst.colors.text};
`;

export const AgreeDescription = styled.p`
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.01em;

  color: ${styleConst.colors.text};
`;

export const QuestionContainer = styled.div`
  width: 280px;

  margin-bottom: 20px;
`;

export const RequiredLabel = styled.span`
  color: ${styleConst.colors.red};
`;

export const Error = styled.p`
  margin-top: 6px;

  font-size: 12px;
  text-align: start;
  color: ${styleConst.colors.red};
`;

export const RadioContainer = styled.div`
  width: 100%;
`;

export const PlaceholderContainer = styled.div`
  height: 168px;
  width: 100%;

  padding: 20px;

  background: ${styleConst.colors.dark5};
  border-radius: 10px;
`;

export const PlaceholderBorder = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  border: 1px dashed ${styleConst.colors.black25};
  border-radius: 10px;
`;

export const IconButton = styled(DefaultIconButton)`
  position: absolute;

  right: -10px;
  top: -10px;
`;
