import React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import RecipeLibraryStore from "stores/RecipeLibraryStore";
import RecipeDetailsStore from "stores/RecipeDetailsStore";

import { NavListItem } from "components/Layout";
import { NavLinkItemType } from "components/Layout/NavListItem/NavListItem";

import { NotFoundError } from "services/api";

import { capitalize } from "utils/common";
import { redirectToRootRoute } from "utils/route";

import ROUTES from "navigation/routes";

type Props = {} & RouteComponentProps<{
  categoryId?: string;
  recipeId?: string;
}>;

@observer
class RecipeLibraryNavList extends React.Component<Props> {
  get pathname() {
    const {
      location: { pathname },
    } = this.props;

    return pathname;
  }

  get categoryId() {
    const {
      match: {
        params: { categoryId },
      },
    } = this.props;

    return categoryId ? Number.parseInt(categoryId) : undefined;
  }

  get recipeId() {
    const {
      match: {
        params: { recipeId },
      },
    } = this.props;

    return recipeId ? Number.parseInt(recipeId) : undefined;
  }

  get url() {
    const {
      match: { url },
    } = this.props;

    return url;
  }

  get isFavorites() {
    return this.url.includes(ROUTES.NUTRITION_MY_ITEMS_FAVORITES);
  }

  @computed get categoryTitle() {
    return RecipeLibraryStore.details?.title
      ? capitalize(RecipeLibraryStore.details?.title)
      : undefined;
  }

  @computed get recipeTitle() {
    return RecipeDetailsStore.details?.title
      ? capitalize(RecipeDetailsStore.details?.title)
      : undefined;
  }

  get firstItem(): NavLinkItemType {
    return !this.isFavorites
      ? {
          title: "Nutrition",
          onClick: this.onClick(ROUTES.NUTRITION_RECIPE_LIBRARY),
        }
      : {
          title: "Favorites",
          onClick: this.onClick(ROUTES.NUTRITION_MY_ITEMS_FAVORITES),
        };
  }

  @computed get navListItems() {
    if (this.categoryId) {
      const secondItem = {
        title: this.categoryTitle,
        onClick: this.onClick(
          ROUTES.NUTRITION_RECIPE_LIBRARY_CATEGORY_DETAILS,
          {
            categoryId: this.categoryId,
          },
        ),
      };

      const thirdItem = this.recipeId
        ? {
            title: this.recipeTitle,
            onClick: this.onClick(
              ROUTES.NUTRITION_RECIPE_LIBRARY_RECIPE_DETAILS,
              {
                categoryId: this.categoryId,
                recipeId: this.recipeId,
              },
            ),
          }
        : undefined;

      return [this.firstItem, secondItem, thirdItem].filter(Boolean);
    }

    if (this.recipeId) {
      const secondItem = this.recipeId
        ? {
            title: this.recipeTitle,
            onClick: this.onClick(ROUTES.NUTRITION_MY_ITEMS_FAVORITES_RECIPE, {
              categoryId: this.categoryId,
              recipeId: this.recipeId,
            }),
          }
        : undefined;

      return [this.firstItem, secondItem].filter(Boolean);
    }

    return [this.firstItem];
  }

  componentDidMount() {
    if (this.categoryId && !RecipeLibraryStore.details) {
      this.fetchCategoryDetails(this.categoryId);
    }
  }

  fetchCategoryDetails = async (id: number) => {
    const { history } = this.props;

    try {
      const details = await RecipeLibraryStore.fetchCategoryDetails(id);

      if (details) {
        RecipeLibraryStore.fillDetails(details);
      }
    } catch (error) {
      if (error instanceof NotFoundError) {
        redirectToRootRoute(history, this.url);
      }
    }
  };

  onClick = (route: string, routeParams?: any) => () => {
    const { history } = this.props;

    history.push(generatePath(route, routeParams));
  };

  render() {
    return (
      <>
        {this.navListItems.map((item: any, index: number) => (
          <NavListItem
            key={`${index}-${item.title}`}
            title={item.title}
            onClick={item.onClick}
            isLastItem={index === this.navListItems.length - 1}
          />
        ))}
      </>
    );
  }
}

export default withRouter(RecipeLibraryNavList);
