import styled, { css } from "styled-components";

import backgroundImageSmall from "assets/images/svg/dots_background_small.svg";
import backgroundImage from "assets/images/svg/dots_background.svg";

import Button from "components/Button";
import IconButton from "components/IconButton";
import InlineListItemDefault from "components/InlineListItem";
import DefaultFoodNutrientsRow from "components/FoodNutrientsRow";

import styleConst from "constants/style";
import { MEDIA_QUERIES } from "constants/layout";

interface IBackground {
  leftDots?: boolean;
}

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 280px;

  &:not(:last-child) {
    margin-right: 22px;
  }
`;

export const FavoriteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 140px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: ${styleConst.colors.dark};
`;

export const FavoriteIconButton = styled(IconButton)`
  width: 60px;
  height: 60px;
`;

export const InlineListItem = styled(InlineListItemDefault)`
  width: 100%;

  border-bottom: 1px solid ${styleConst.colors.black10};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 60px;
`;

export const Nutrients = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const NutrientsInfo = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const NutrientWrapper = styled.div`
  width: 25%;

  padding: 0 20px;
`;

export const SubmitButton = styled(Button)`
  display: flex;

  width: 215px;

  background: radial-gradient(
      83.3% 179.17% at 56.29% -31.25%,
      ${styleConst.colors.primaryMedium} 0%,
      ${styleConst.colors.primary} 100%
    ),
    ${styleConst.colors.primary};
  box-shadow: 0px 20px 40px -10px ${styleConst.colors.shadow};
  border-radius: 10px;
  border: none;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubTitle = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: ${styleConst.colors.dark50};
`;

export const TitleAdornment = styled.div`
  margin-right: 14px;

  width: 4px;
  height: 28px;
  background-color: ${styleConst.colors.secondary};
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 20px 0px;
`;

export const SectionTitle = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;
  color: ${styleConst.colors.text};
  line-height: 48px;
`;

export const Background = styled.div<IBackground>`
  position: absolute;
  top: 164px;

  z-index: -1;

  background-position: 60% 50%;

  height: 388px;
  width: 100%;

  overflow: hidden;
  background-repeat: no-repeat;

  background-image: url(${backgroundImageSmall});

  ${MEDIA_QUERIES.DESKTOP} {
    background-image: url(${backgroundImage});
  }

  ${({ leftDots }) =>
    leftDots &&
    css`
      transform: rotate(180deg);

      top: 967px;
    `}
`;

export const FoodNutrientsRow = styled(DefaultFoodNutrientsRow)`
  padding: 0 56px;
`;

export const LabelContainer = styled.div`
  display: inline-block;
`;
