import React from "react";
import { SnapshotOrInstance } from "mobx-state-tree";

import { SummaryMacroNutrients } from "stores/FoodLogStore";

import { getNutrientLabel, getNutrientValue } from "utils/nutrients";

import { Container, Nutrient, Title, Value } from "./styles";

type Props = {
  nutrients: SnapshotOrInstance<typeof SummaryMacroNutrients>;
};

const NutrientsProgress = ({ nutrients }: Props) => {
  return (
    <Container>
      {Object.entries(nutrients).map(([label, nutrient]) => {
        if (typeof nutrient === "number") {
          return (
            <Nutrient key={label}>
              <Title>{getNutrientLabel(label)}</Title>
              <Value>{getNutrientValue(label, nutrient)}</Value>
            </Nutrient>
          );
        }
        return null;
      })}
    </Container>
  );
};

export default NutrientsProgress;
