import React from "react";

import { Circle, Container, Count, Item, Label } from "./styles";

type Props = {
  scheduled?: number;
  completed?: number;
  streak?: number;
};

const Summary = ({ scheduled = 0, completed = 0, streak = 0 }: Props) => (
  <Container>
    <Item>
      <Count>{scheduled}</Count>
      <Circle scheduled />
      <Label>{"Workouts\nScheduled"}</Label>
    </Item>

    <Item>
      <Count>{completed}</Count>
      <Circle completed />
      <Label>{"Workouts\nCompleted"}</Label>
    </Item>

    <Item>
      <Count>{streak}</Count>
      <Circle streak />
      <Label>{"Daily\nStreak"}</Label>
    </Item>
  </Container>
);

export default Summary;
