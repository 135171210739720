import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Tick = ({ color = styleConst.colors.primary }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.545 6.16155C19.008 6.46254 19.1394 7.08192 18.8384 7.54498L12.3384 17.545C12.1741 17.7979 11.9043 17.9631 11.6043 17.9945C11.3043 18.026 11.0062 17.9204 10.7929 17.7071L5.79289 12.7071C5.40237 12.3166 5.40237 11.6834 5.79289 11.2929C6.18342 10.9024 6.81658 10.9024 7.20711 11.2929L11.3343 15.4201L17.1616 6.45501C17.4625 5.99195 18.0819 5.86056 18.545 6.16155Z"
      fill={color}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default I24Tick;
