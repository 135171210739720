import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

import I24Clock from "assets/icons/I24Clock";

type Props = {
  label?: string;
  required?: boolean;
  value?: Date;
  invalidDateMessage?: string;
  placeholder?: string;
  error?: string;
  onChange: (date: Date | null) => void;
};

class TimePicker extends React.Component<Props> {
  get error() {
    const { error } = this.props;

    return Boolean(error);
  }

  get helperText() {
    const { error } = this.props;

    return this.error ? error : undefined;
  }

  get convertedProps() {
    const { label, required, invalidDateMessage, placeholder, onChange } =
      this.props;

    const defaultProps = {
      label,
      required,
      invalidDateMessage,
      placeholder,
      onChange,
    };

    return !this.error && !this.helperText
      ? defaultProps
      : {
          ...defaultProps,
          onChange,
          helperText: this.helperText,
          error: this.error,
        };
  }

  render() {
    const { value } = this.props;

    return (
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={{
          ...enLocale,
          options: { ...enLocale.options, weekStartsOn: "1" },
        }}
      >
        <KeyboardTimePicker
          value={value ? new Date(value) : null}
          format="hh:mm a"
          inputVariant="outlined"
          InputLabelProps={{ shrink: true }}
          keyboardIcon={<I24Clock />}
          fullWidth
          {...this.convertedProps}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

export default TimePicker;
