import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";

import MacroPlanStore from "stores/MacroPlanStore";

import I24Edit from "assets/icons/I24Edit";

import Button from "components/Button";
import Input from "components/Input";
import MacroPlan from "components/MacroGoals/Edit";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";
import Spinner from "components/Spinner";

import { NoInternetConnectionError } from "services/api";

import { TYPE } from "constants/macroPlan";
import styleConst from "constants/style";

import { round } from "utils/common";

import {
  ButtonWrapper,
  Container,
  Content,
  Title,
  Controls,
  ItemWrapper,
  Header,
  Form,
  LoaderContainer,
} from "./styles";

type Props = {
  onClose: () => void;
};

type Field = "carbs" | "fat" | "protein" | "lowCarbDayNetCarbIntake";

interface Values {
  carbs: string;
  fat: string;
  protein: string;
  lowCarbDayNetCarbIntake: string;
}

@observer
class EditMacroPlanPopup extends React.Component<Props> {
  @observable selectedMacroPlan: any = undefined;
  @observable isNoInternetConnectionError: boolean = false;

  @observable values: Values = {
    carbs: "",
    fat: "",
    protein: "",
    lowCarbDayNetCarbIntake: "",
  };

  @observable error: Values = {
    carbs: "",
    fat: "",
    protein: "",
    lowCarbDayNetCarbIntake: "",
  };

  @computed get isError() {
    return Object.values(this.error).some(Boolean);
  }

  async componentDidMount() {
    try {
      await MacroPlanStore.fetchCycleCalculations();

      this.selectedMacroPlan = MacroPlanStore.defaultMacroPlan;

      const data = MacroPlanStore.getCalculationItemByName(
        this.selectedMacroPlan,
      );

      this.onSelectType(data);
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }
  }

  onSubmit = async () => {
    const { onClose } = this.props;
    this.checkFields();

    if (!this.isError) {
      try {
        const data = {
          selectedMacroPlan: this.selectedMacroPlan,
          ...this.values,
        };
        await MacroPlanStore.updateCycleCalculations(data);
        onClose();
      } catch (error) {
        console.log("error :>> ", error);
      }
    }
  };

  checkFields = () => {
    const { lowCarbDayNetCarbIntake } = this.values;

    if (lowCarbDayNetCarbIntake.length === 0) {
      this.error.lowCarbDayNetCarbIntake = "This field is required";
      return;
    }

    if (Number(lowCarbDayNetCarbIntake) === 0) {
      this.error.lowCarbDayNetCarbIntake = "The value should be more than 0";
    }
  };

  onSelectType = (data: any) => {
    const { carbs, fat, protein, lowCarbDayNetCarbIntake, title } = data;

    this.selectedMacroPlan = title;

    this.values = {
      carbs: carbs?.toString() ?? "",
      fat: fat?.toString() ?? "",
      protein: protein?.toString() ?? "",
      lowCarbDayNetCarbIntake: lowCarbDayNetCarbIntake?.toString() ?? "",
    };
  };

  onChange =
    (field: Field) =>
    (text: string = "") => {
      this.values[field] = text;
      this.error[field] = "";
    };

  isFieldDisabled = (field: Field) => {
    if (this.selectedMacroPlan !== TYPE.CUSTOM) {
      return field !== "lowCarbDayNetCarbIntake";
    }
    return false;
  };

  render() {
    const { onClose } = this.props;
    return (
      <Container>
        <Content>
          <Header>
            <I24Edit color={"#DADADA"} />
            <Title>Edit Macro Plan</Title>
          </Header>
          {this.isNoInternetConnectionError ? (
            <NoInternetConnectionPlaceholder
              type={"popup"}
              onSubmit={onClose}
            />
          ) : MacroPlanStore.isLoading ? (
            <LoaderContainer>
              <Spinner color={styleConst.colors.primary} large />
            </LoaderContainer>
          ) : (
            <>
              <MacroPlan
                plan={{ items: MacroPlanStore.mappedCalculationItems }}
                onSelectType={this.onSelectType}
                selected={this.selectedMacroPlan}
              />

              <Form>
                <ItemWrapper>
                  <Input
                    label="Carbs (g)"
                    value={round(this.values.carbs)}
                    onChangeText={this.onChange("carbs")}
                    placeholder="Please specify"
                    error={this.error.carbs}
                    disabled={this.isFieldDisabled("carbs")}
                    numbersOnly
                    onlyPositiveNumbers
                  />
                </ItemWrapper>
                <ItemWrapper>
                  <Input
                    label="Protein (g)"
                    value={round(this.values.protein)}
                    onChangeText={this.onChange("protein")}
                    placeholder="Please specify"
                    error={this.error.protein}
                    disabled={this.isFieldDisabled("protein")}
                    numbersOnly
                    onlyPositiveNumbers
                  />
                </ItemWrapper>
                <ItemWrapper>
                  <Input
                    label="Fat (g)"
                    value={round(this.values.fat)}
                    onChangeText={this.onChange("fat")}
                    placeholder="Please specify"
                    error={this.error.fat}
                    disabled={this.isFieldDisabled("fat")}
                    numbersOnly
                    onlyPositiveNumbers
                  />
                </ItemWrapper>
                <ItemWrapper>
                  <Input
                    label="Low Carb Day Net Carb Intake (g)"
                    value={this.values.lowCarbDayNetCarbIntake}
                    onChangeText={this.onChange("lowCarbDayNetCarbIntake")}
                    placeholder="Please specify"
                    error={this.error.lowCarbDayNetCarbIntake}
                    disabled={this.isFieldDisabled("lowCarbDayNetCarbIntake")}
                    numbersOnly
                    onlyPositiveNumbers
                  />
                </ItemWrapper>
              </Form>

              <Controls>
                <ButtonWrapper>
                  <Button
                    text={"Done"}
                    onClick={this.onSubmit}
                    height={50}
                    width={280}
                  />
                </ButtonWrapper>
              </Controls>
            </>
          )}
        </Content>
      </Container>
    );
  }
}

export default EditMacroPlanPopup;
