import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import InputMask from "react-input-mask";

import styleConst from "constants/style";

interface ILabel {
  error?: string;
  hasValue?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

interface IInputText {
  error?: string;
  withIcon?: boolean;
  inputStyles?: string;
}

interface IError {
  errorTextStyles?: FlattenSimpleInterpolation;
}

const defaultInputStyles = css<IInputText>`
  width: 100%;

  padding: 15px;

  font-size: 14px;

  border: 2px solid ${styleConst.colors.black10};
  box-sizing: border-box;
  border-radius: 10px;

  ::placeholder {
    color: ${styleConst.colors.black25};
  }

  ${({ withIcon }) =>
    withIcon &&
    css`
      padding-right: 40px;
    `}

  &:focus {
    outline: none;
    border: 2px solid ${styleConst.colors.primary};
  }

  &:disabled {
    background-color: ${styleConst.colors.white};
    opacity: 0.6;
  }

  ${({ error }) =>
    error &&
    css`
      &&& {
        border: 2px solid ${styleConst.colors.red};
      }
    `}
`;

const defaultLabelFocusStyles = css`
  top: -13px;

  padding: 0 4px;

  font-size: 12px;
  color: ${styleConst.colors.primary};

  background-color: ${styleConst.colors.white};
`;

export const Container = styled.div`
  position: relative;

  width: 100%;
`;

export const IconContainer = styled.div`
  position: absolute;

  top: 15px;
  right: 15px;
`;

export const TextInput = styled.input<IInputText>`
  ${defaultInputStyles};

  ${({ inputStyles }) => inputStyles};
`;

export const TextInputMask = styled(InputMask)<IInputText>`
  ${defaultInputStyles};

  ${({ inputStyles }) => inputStyles};
`;

export const Label = styled.label<ILabel>`
  position: absolute;

  top: 15px;
  left: 15px;

  color: ${styleConst.colors.black50};

  font-size: 14px;
  line-height: 24px;

  pointer-events: none;

  transition: 0.2s ease all;

  ${({ hasValue, placeholder }) =>
    (hasValue || placeholder) &&
    css`
      top: -13px;

      padding: 0 4px;

      font-size: 12px;

      background-color: ${styleConst.colors.white};
    `}

  ${TextInput}:focus ~ & {
    ${defaultLabelFocusStyles}
  }

  ${TextInputMask}:focus ~ & {
    ${defaultLabelFocusStyles}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${styleConst.colors.black30};
    `}

  ${({ error }) =>
    error &&
    css`
      &&& {
        color: ${styleConst.colors.red};
      }
    `}
`;

export const Error = styled.p<IError>`
  margin-top: 6px;

  font-size: 12px;
  text-align: start;
  color: ${styleConst.colors.red};

  ${({ errorTextStyles }) => errorTextStyles};
`;
