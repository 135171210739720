import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Nutrition = ({ color = styleConst.colors.white }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 5C13 3.89543 13.8954 3 15 3H16C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1H15C12.7909 1 11 2.79086 11 5V6.08318C9.77522 5.26071 8.37311 4.86506 6.93807 5.04127C3.10089 5.51241 0.481308 9.89375 1.08707 14.8273C1.69283 19.7608 5.29454 23.3782 9.13171 22.9071C9.64946 22.8435 10.3452 22.5519 10.9188 22.2718C11.582 21.948 12.3672 21.948 13.0305 22.2718C13.6041 22.5519 14.2998 22.8435 14.8175 22.9071C18.6547 23.3782 22.2564 19.7608 22.8622 14.8273C23.4679 9.89375 20.8483 5.51241 17.0112 5.04127C15.5959 4.86749 14.2127 5.24991 13 6.04941V5ZM10.0412 20.4746C9.79015 20.5973 9.53508 20.711 9.30483 20.797C9.19084 20.8395 9.09404 20.8711 9.01558 20.8928C8.97702 20.9034 8.94642 20.9107 8.92343 20.9156C8.91212 20.9179 8.90343 20.9195 8.89725 20.9206L8.8903 20.9217L8.88801 20.922C6.58254 21.2051 3.60386 18.9139 3.07216 14.5835C2.54046 10.2532 4.87634 7.30943 7.1818 7.02636C8.31531 6.88718 9.52553 7.3057 10.6051 8.32006C11.3749 9.0433 12.5743 9.0433 13.3441 8.32006C14.4237 7.3057 15.6339 6.88718 16.7674 7.02636C19.0729 7.30943 21.4088 10.2532 20.8771 14.5835C20.3454 18.9139 17.3667 21.2051 15.0613 20.922L15.0589 20.9217L15.052 20.9206C15.0458 20.9195 15.0371 20.9179 15.0258 20.9156C15.0028 20.9107 14.9722 20.9034 14.9337 20.8928C14.8552 20.8711 14.7584 20.8395 14.6444 20.797C14.4142 20.711 14.1591 20.5973 13.908 20.4746C12.691 19.8804 11.2583 19.8804 10.0412 20.4746Z"
      fill={color}
    />
  </svg>
);

export default I24Nutrition;
