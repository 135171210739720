import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
  width?: number;
  height?: number;
};

const I24ChevronLeft = ({
  color = styleConst.colors.white,
  width = 24,
  height = 24,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.49954 15.7079C9.07456 15.2479 9.16779 14.4088 8.70778 13.8338L4.04078 8.00005L8.70778 2.16631C9.16779 1.59129 9.07456 0.752236 8.49954 0.292224C7.92453 -0.167789 7.08547 -0.0745601 6.62546 0.500456L0.625784 8.00005L6.62546 15.4996C7.08547 16.0747 7.92453 16.1679 8.49954 15.7079Z"
      fill={color}
    />
  </svg>
);

export default I24ChevronLeft;
