import * as React from "react";

import style from "constants/style";

type Props = {
  color?: string;
};

const I24Remove = ({ color = style.colors.black50 }: Props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.30962 4.36685C5.04927 4.1065 4.62716 4.1065 4.36681 4.36685C4.10646 4.6272 4.10646 5.04931 4.36681 5.30966L6.05717 7.00002L4.36681 8.69037C4.10646 8.95072 4.10646 9.37283 4.36681 9.63318C4.62716 9.89353 5.04927 9.89353 5.30962 9.63318L6.99998 7.94283L8.69033 9.63318C8.95068 9.89353 9.37279 9.89353 9.63314 9.63318C9.89349 9.37283 9.89349 8.95072 9.63314 8.69037L7.94279 7.00002L9.63314 5.30966C9.89349 5.04931 9.89349 4.6272 9.63314 4.36685C9.37279 4.1065 8.95068 4.1065 8.69033 4.36685L6.99998 6.05721L5.30962 4.36685Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99967 13.6667C10.6816 13.6667 13.6663 10.6819 13.6663 7.00004C13.6663 3.31814 10.6816 0.333374 6.99967 0.333374C3.31778 0.333374 0.333008 3.31814 0.333008 7.00004C0.333008 10.6819 3.31778 13.6667 6.99967 13.6667ZM6.99967 12.3334C9.94519 12.3334 12.333 9.94556 12.333 7.00004C12.333 4.05452 9.94519 1.66671 6.99967 1.66671C4.05416 1.66671 1.66634 4.05452 1.66634 7.00004C1.66634 9.94556 4.05416 12.3334 6.99967 12.3334Z"
        fill={color}
      />
    </svg>
  );
};

export default I24Remove;
