import React from "react";
import { observer } from "mobx-react";

import I24RadioOn from "assets/icons/I24RadioButtonOn";
import I24RadioOff from "assets/icons/I24RadioButtonOff";

import TrimmedText from "components/TrimmedText";

import styleConst from "constants/style";

import { round } from "utils/common";

import { Cell, Container, RadioContainer, Row } from "./styles";

type Props = {
  items: any[];
  onSelectType?: (data: any) => void;
  selected?: string;
  editMode?: boolean;
  style?: any;
};

@observer
class Table extends React.Component<Props> {
  get header() {
    return {
      title: "Type",
      carbs: "Carbs",
      fat: "Fat",
      protein: "Protein",
    };
  }

  isSelected = (item?: any) => {
    const { selected } = this.props;
    return selected === item?.title;
  };

  onPlanSelect = (item?: any) => () => {
    const { onSelectType } = this.props;
    onSelectType?.(item);
  };

  render() {
    const { items, editMode, style } = this.props;

    return editMode ? (
      <Container style={style}>
        <Row head editMode>
          <Cell lastItem />
          <Cell head firstItem>
            {this.header.title}
          </Cell>
          <Cell head>{this.header.carbs}</Cell>
          <Cell head>{this.header.protein}</Cell>
          <Cell head>{this.header.fat}</Cell>
        </Row>
        {items.map((item, index) => (
          <Row key={item.title + index} editMode>
            <Cell lastItem>
              <RadioContainer onClick={this.onPlanSelect(item)}>
                {this.isSelected(item) ? (
                  <I24RadioOn color={styleConst.colors.primary} />
                ) : (
                  <I24RadioOff color={styleConst.colors.black25} />
                )}
              </RadioContainer>
            </Cell>
            <Cell firstItem>{item.label ?? item.title}</Cell>
            <Cell>
              <TrimmedText lines={1} text={`${round(item.carbs)} g`}>
                {round(item.carbs)} g
              </TrimmedText>
            </Cell>
            <Cell>
              <TrimmedText lines={1} text={`${round(item.protein)} g`}>
                {round(item.protein)} g
              </TrimmedText>
            </Cell>
            <Cell>
              <TrimmedText lines={1} text={`${round(item.fat)} g`}>
                {round(item.fat)} g
              </TrimmedText>
            </Cell>
          </Row>
        ))}
      </Container>
    ) : (
      <Container style={style}>
        <Row head>
          <Cell head firstItem>
            {this.header.title}
          </Cell>
          <Cell head>{this.header.carbs}</Cell>
          <Cell head>{this.header.protein}</Cell>
          <Cell head>{this.header.fat}</Cell>
        </Row>
        {items.map((item, index) => (
          <Row key={item.title + index}>
            <Cell firstItem>{item.title}</Cell>
            <Cell>
              <TrimmedText lines={1} text={`${round(item.carbs)} g`}>
                {round(item.carbs)} g
              </TrimmedText>
            </Cell>
            <Cell>
              <TrimmedText lines={1} text={`${round(item.protein)} g`}>
                {round(item.protein)} g
              </TrimmedText>
            </Cell>
            <Cell>
              <TrimmedText lines={1} text={`${round(item.fat)} g`}>
                {round(item.fat)} g
              </TrimmedText>
            </Cell>
          </Row>
        ))}
      </Container>
    );
  }
}

export default Table;
