import React from "react";

export type NavigationContextProps = {
  navigateToLogin: () => void;
};

const GlobalNavigationContext = React.createContext<NavigationContextProps>({
  navigateToLogin: () => {},
});

export default GlobalNavigationContext;

export function withGlobalNavigation(Wrapped: React.ClassType<any, any, any>) {
  return function ContextWrapper(props: any) {
    function createContextConsumer(context: NavigationContextProps) {
      return <Wrapped {...props} {...context} />;
    }

    return (
      <GlobalNavigationContext.Consumer>
        {createContextConsumer}
      </GlobalNavigationContext.Consumer>
    );
  };
}
