import { flow, types, applySnapshot, getSnapshot } from "mobx-state-tree";

import api from "services/api";

import { Ingredient } from "models/Recipe";
import { Context } from "models/index";

import { convertSearchString, uniqArray } from "utils/common";

const CustomIngredient = types.model("CustomIngredient", {
  quantity: types.number,
  servingIndex: types.maybe(types.number),
  ingredient: Ingredient,
});

const CustomRecipe = types.model("CustomRecipe", {
  name: types.maybeNull(types.string),
  ingredients: types.array(CustomIngredient),
});

const AddRecipe = types
  .model("AddRecipe", {
    searchString: types.optional(types.string, ""),
    ingredients: types.array(Ingredient),
    context: types.maybe(Context),
    customRecipe: types.optional(CustomRecipe, {}),
    isLoading: types.optional(types.boolean, false),
    isMoreLoading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false),
  })
  .views(self => ({
    get needMore() {
      if (self.context) {
        return self.context.page < self.context.pages;
      }
    },
  }))
  .actions(self => ({
    updateItem(id: string, food: any) {
      const { ingredients } = getSnapshot(self);

      const index = ingredients.findIndex(item => item.id === id);

      self.ingredients[index] = {
        ...ingredients[index],
        ...food,
      };
    },
    resetResult() {
      applySnapshot(self, {
        ...self,
        ingredients: [],
        searchString: "",
      });
    },
    resetCustomRecipe() {
      applySnapshot(self, { ...self, customRecipe: {} });
    },
  }))
  .actions(self => ({
    setSearchString(value: string) {
      self.searchString = value;
    },
    searchIngredients: flow(function* (page: number = 1) {
      try {
        if (self.searchString.length < 3) return;

        if (page > 1) {
          self.isMoreLoading = true;
        } else {
          self.isLoading = true;
        }

        const payload = {
          Search: convertSearchString(self.searchString),
          page,
        };

        const { context, items: ingredients } = yield api.get(
          `/mobile/v3/food/search`,
          payload,
        );

        const nextIngredients =
          page > 1 ? self.ingredients.concat(ingredients) : ingredients;
        const uniquesNextItems = uniqArray(nextIngredients, "id");

        applySnapshot(self, {
          ...self,
          context,
          ingredients: uniquesNextItems,
        });
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        if (page > 1) {
          self.isMoreLoading = false;
        } else {
          self.isLoading = false;
        }
      }
    }),
    setRecipeName(name: string) {
      applySnapshot(self, {
        ...self,
        customRecipe: { ...self.customRecipe, name },
      });
    },
    addIngredient(payload: any) {
      const ingredientIndex = self.customRecipe.ingredients.findIndex(
        item => item.ingredient.id === payload.ingredient.id,
      );

      if (ingredientIndex !== -1) {
        self.customRecipe.ingredients[ingredientIndex] = payload;
      } else {
        self.customRecipe.ingredients.push(payload);
      }
    },
    createCustomRecipe: flow(function* () {
      try {
        self.isCreating = true;

        const payload = {
          name: self.customRecipe.name?.trim(),
          ingredients: self.customRecipe.ingredients.map(
            ({ ingredient, servingIndex = 0, quantity }) => ({
              id: ingredient.id,
              servingId: ingredient.servings[servingIndex].id,
              amount: quantity,
            }),
          ),
        };

        yield api.post(`/mobile/v2/food/custom-recipe`, payload);

        self.resetCustomRecipe();
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isCreating = false;
      }
    }),
    removeIngredient(id: string) {
      applySnapshot(self, {
        ...self,
        customRecipe: {
          ...self.customRecipe,
          ingredients: self.customRecipe.ingredients.filter(
            ({ ingredient }) => ingredient.id !== id,
          ),
        },
      });
    },
  }));

const AddRecipeStore = AddRecipe.create();

export default AddRecipeStore;
