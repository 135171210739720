import React from "react";
import { observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroll-component";

import Spinner from "components/Spinner";

import Card from "./Card";

import styleConst from "constants/style";
import { CONTAINER_SCROLLABLE_DIV_ID } from "constants/layout";

import { Container, ScrollLoaderWrapper, ResponsiveWrapper } from "./styles";

type Props = {
  items: any;
  onClick: (item: any) => void;
  isWorkout?: boolean;
  withInfinityScroll?: boolean;
  needMore?: boolean;
  onLoadMore?: () => void;
  scrollableTarget?: React.ReactNode;
};

@observer
class GridCollection extends React.Component<Props> {
  onClick = (item: any) => () => {
    const { onClick } = this.props;

    onClick(item);
  };

  renderContent = () => {
    const { items, isWorkout } = this.props;

    return (
      <Container>
        {items.map((item: any) => (
          <Card
            key={item.id}
            title={item.label || item.title}
            duration={item.duration}
            subTitle={item.subTitle}
            imageUrl={item.imageUrl}
            isPermissionPreview={item.isPermissionPreview}
            onClick={this.onClick(item)}
            isWorkout={isWorkout}
          />
        ))}
      </Container>
    );
  };

  render() {
    const {
      items,
      onLoadMore,
      needMore,
      withInfinityScroll,
      scrollableTarget,
    } = this.props;

    const hasInfinityScroll =
      withInfinityScroll && needMore !== undefined && !!onLoadMore;

    return (
      <ResponsiveWrapper hasInfinityScroll={hasInfinityScroll}>
        {hasInfinityScroll ? (
          <InfiniteScroll
            className="responsive_infinity_scroll"
            dataLength={items.length}
            hasMore={needMore}
            next={onLoadMore}
            loader={
              <ScrollLoaderWrapper>
                <Spinner color={styleConst.colors.primary} large />
              </ScrollLoaderWrapper>
            }
            scrollableTarget={scrollableTarget || CONTAINER_SCROLLABLE_DIV_ID}
            style={{ overflowY: "hidden" }}
          >
            {this.renderContent()}
          </InfiniteScroll>
        ) : (
          this.renderContent()
        )}
      </ResponsiveWrapper>
    );
  }
}

export default GridCollection;
