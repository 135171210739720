import styled, { css } from "styled-components";

import topBackgroundImage from "assets/images/svg/promote_popup_background.svg";

import styleConst from "constants/style";

interface IContent {
  top?: boolean;
  bottom?: boolean;
}

interface IIConWrapper {
  contentIcon?: boolean;
}

export const ButtonWrapper = styled.div`
  width: 150px;
  margin: 0 10px;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 570px;
  height: 488px;

  border-radius: 10px;
`;

export const Content = styled.div<IContent>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ top }) =>
    top &&
    css`
      height: 184px;
      overflow: hidden;
      background-image: url(${topBackgroundImage});
      background-repeat: no-repeat;
      background-position: 50% 50%;
    `}

  ${({ bottom }) =>
    bottom &&
    css`
      height: 304px;
      padding: 43px 66px 40px;
    `}
`;

export const IconWrapper = styled.div<IIConWrapper>`
  margin-right: 20px;

  ${({ contentIcon }) =>
    contentIcon &&
    css`
      display: flex;

      margin-right: 6px;
    `}
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 34px;
`;

export const Text = styled.div`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 18px;
  font-weight: 500;

  color: ${styleConst.colors.white};
  padding: 0 40px;
`;

export const ContentWrapper = styled.div`
  padding-bottom: 20px;
`;
