import styled, { css } from "styled-components";

import styleConst from "constants/style";
interface IText {
  isSelected?: boolean;
}

export const Container = styled.div`
  position: relative;

  cursor: pointer;
`;

export const SelectedIndicator = styled.div`
  position: absolute;
  top: 23px;

  width: 100%;
  height: 4px;

  background-color: ${styleConst.colors.secondary80};
`;

export const Text = styled.p<IText>`
  font-size: 14px;
  font-family: ${styleConst.fonts.roboto};
  font-weight: 700;
  color: ${styleConst.colors.text};
  letter-spacing: 1px;

  ${({ isSelected }) =>
    !isSelected &&
    css`
      font-family: ${styleConst.fonts.roboto};
      font-weight: 400;
    `}
`;
