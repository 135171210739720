import React from "react";

import {
  Container,
  Label,
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
  Wrapper,
} from "./styles";

type Props = {
  label?: string;
  checked: boolean;
  toggleChecked: (value: boolean) => void;
};

const CheckboxInput = ({ checked, ...props }: any) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

const Checkbox: React.FC<Props> = ({ label, checked, toggleChecked }) => {
  const onToggle = React.useCallback(({ target }) => {
    toggleChecked(target.checked);
  }, []);

  return (
    <Container>
      <Wrapper>
        <CheckboxInput checked={checked} onChange={onToggle} />
        {label ? <Label>{label}</Label> : null}
      </Wrapper>
    </Container>
  );
};

export default React.memo(Checkbox);
