import React from "react";

import { Container } from "components/Layout";
import AppHeader from "components/Layout/Header/AppHeader";
import AppFooter from "components/Layout/Footer/AppFooter";

import Spinner from "components/Spinner";

import styleConst from "constants/style";

import {
  Content,
  Controls,
  LoaderContainer,
  PageNavigationContainer,
  Title,
  TitleContainer,
} from "./styles";

type Props = {
  children: React.ReactNode;
  topBanner?: React.ReactNode;
  bottomBanner?: React.ReactNode;
  contentBackground?: React.ReactNode;
  isLoading?: boolean;
  title?: string;
  titleControls?: React.ReactNode;
  titleUpperContent?: React.ReactNode;
  topNavigation?: React.ReactNode;
  titleControlsFullWidth?: boolean;
  disabled?: boolean;
  isNoInternetConnectionError?: boolean;
};

class Page extends React.Component<Props> {
  render() {
    const {
      children,
      title,
      titleControls,
      topNavigation,
      topBanner,
      titleUpperContent,
      bottomBanner,
      contentBackground,
      isLoading,
      titleControlsFullWidth,
      disabled,
      isNoInternetConnectionError,
    } = this.props;

    return (
        <Container
          header={<AppHeader disabled={disabled} />}
          footer={<AppFooter disabled={disabled} />}
          topBanner={!isLoading ? topBanner : undefined}
          bottomBanner={!isLoading ? bottomBanner : undefined}
          contentBackground={contentBackground}
          isNoInternetConnectionError={isNoInternetConnectionError}
        >
          <Content>
            {topNavigation && (
              <PageNavigationContainer>{topNavigation}</PageNavigationContainer>
            )}
            {titleUpperContent}
            {title && (
              <TitleContainer withTopNavigation={!!topNavigation}>
                <Title>{title}</Title>
                {titleControls && (
                  <Controls titleControlsFullWidth={titleControlsFullWidth}>
                    {titleControls}
                  </Controls>
                )}
              </TitleContainer>
            )}
            {!isLoading ? (
              children
            ) : (
              <LoaderContainer>
                <Spinner color={styleConst.colors.primary} large />
              </LoaderContainer>
            )}
          </Content>
        </Container>
    );
  }
}

export default Page;
