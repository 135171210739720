import styled, { css } from "styled-components";

import Button from "components/Button";
import IconButton from "components/IconButton";
import InlineListItemDefault from "components/InlineListItem";
import FoodNutrientsRowDefault from "components/FoodNutrientsRow";

import styleConst from "constants/style";

interface IRow {
  formRow?: boolean;
  barcodeForm?: boolean;
}

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 280px;

  &:not(:last-child) {
    margin-right: 22px;
  }
`;

export const FavoriteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 140px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: ${styleConst.colors.dark};
`;

export const FavoriteIconButton = styled(IconButton)`
  width: 60px;
  height: 60px;
`;

export const InlineListItem = styled(InlineListItemDefault)`
  width: 100%;
`;

export const ScrollLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 210px;
`;

export const Row = styled.div<IRow>`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;

  ${({ formRow }) =>
    formRow &&
    css`
      padding: 0 171px;
    `}

  ${({ barcodeForm }) =>
    barcodeForm &&
    css`
      justify-content: center;
    `}
`;

export const Nutrients = styled.div`
  display: flex;
  flex-direction: column;
`;
export const NutrientsTitle = styled.p`
  margin-top: 30px;

  font-family: ${styleConst.fonts.montserrat};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: ${styleConst.colors.dark};
`;

export const NutrientsInfo = styled.div`
  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;
`;

export const NutrientWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  width: 243px;

  border-bottom: 1px solid ${styleConst.colors.black10};
`;

export const SubmitButton = styled(Button)`
  display: flex;

  width: 215px;

  background: radial-gradient(
      83.3% 179.17% at 56.29% -31.25%,
      ${styleConst.colors.primaryMedium} 0%,
      ${styleConst.colors.primary} 100%
    ),
    ${styleConst.colors.primary};
  box-shadow: 0px 20px 40px -10px ${styleConst.colors.shadow};
  border-radius: 10px;
  border: none;
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 60px;
  width: 100%;
`;

export const SearchWrapper = styled.div`
  width: 100%;

  padding: 0 100px;
`;

export const ScrollWrapper = styled.div`
  height: calc(100vh - 400px);
  min-height: calc(100vh - 400px);

  overflow-y: auto;
`;

export const ScrollLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding: 10px;
`;

export const FoodNutrientsRow = styled(FoodNutrientsRowDefault)`
  padding: 0 171px;
`;

export const BackButtonWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
`;
