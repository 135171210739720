import React from "react";
import { observer } from "mobx-react";

import {
  Header,
  HeaderContent,
  TitleWrapper,
  Title,
  ColumnTitleWrapper,
  ColumnTitle,
  ExtraItems,
} from "./styles";

type Props = { title: string; items?: string[]; extraItems?: string[] };

const GroupHeader = ({ title, items = [], extraItems }: Props) => {
  return (
    <Header>
      <HeaderContent>
        <TitleWrapper>
          <Title>{title}</Title>
          <ColumnTitleWrapper>
            {items.map(item => (
              <ColumnTitle key={item}>{item}</ColumnTitle>
            ))}
          </ColumnTitleWrapper>
        </TitleWrapper>
        <ExtraItems>
          {extraItems?.map(item => (
            <ColumnTitle key={item}>{item}</ColumnTitle>
          ))}
        </ExtraItems>
      </HeaderContent>
    </Header>
  );
};

export default observer(GroupHeader);
