import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";

import I32Arrow from "assets/icons/I32Arrow";
import I24ArrowBack from "assets/icons/I24ArrowBack";

import MacroCalculatorStore from "stores/MacroCalculatorStore";

import Button from "components/Button";
import ProgressBar from "components/ProgressBar";
import { QuestionGroup } from "components/Questionnaire";
import ButtonInline from "components/ButtonInline";
import { SnackbarTypeValues } from "components/Snackbar";
import Spinner from "components/Spinner";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";
import { NoInternetConnectionError } from "services/api";

import { WEIGHT_GOAL } from "constants/macroCalculator";
import styleConst from "constants/style";

import {
  ButtonWrapper,
  Container,
  Content,
  Title,
  Controls,
  Header,
  PageInfo,
  GroupContainer,
  QuestionTitle,
  QuestionSubtitle,
  BackButtonWrapper,
  Alert,
  AlertWrapper,
  LoaderContainer,
} from "./styles";

type Props = {
  onClose: () => void;
} & SnackbarContextProps;

const { value: weightGoalGainValue } = WEIGHT_GOAL.GAIN;
const weightGoalField = "weightGoal";

@observer
class MacroCalculatorPopup extends React.Component<Props> {
  @observable showContactCoachAlert: boolean = false;
  @observable isNoInternetConnectionError: boolean = false;

  @computed get count() {
    return MacroCalculatorStore.count;
  }

  @computed get current() {
    return MacroCalculatorStore.current;
  }

  @computed get group() {
    return MacroCalculatorStore.currentGroup;
  }

  @computed get isNextButtonDisabled() {
    if (this.group && MacroCalculatorStore.isLastStep !== 0) {
      const { fields } = this.group;

      return fields.some(field => {
        const answer = MacroCalculatorStore.getAnswersByField(field.name);
        return field.required && (!answer || answer.isNotFilled);
      });
    } else {
      return true;
    }
  }

  @computed get nextButtonTitle() {
    return !MacroCalculatorStore.isLastStep ? "Next" : "Done";
  }

  async componentDidMount() {
    try {
      await MacroCalculatorStore.fetch();
    } catch (error: any) {
      const { setMessage } = this.props;

      setMessage(error.message, SnackbarTypeValues.ALERT);

      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }
  }

  onAnswer = (answer: any) => {
    MacroCalculatorStore.pushAnswer(answer);

    this.showContactCoachAlert =
      answer.fieldName === weightGoalField &&
      answer.value === weightGoalGainValue;
  };

  onNextPress = () => {
    if (!MacroCalculatorStore.isLastStep) {
      MacroCalculatorStore.stepForward();
    } else {
      this.onResultsNavigate();
    }
  };

  onResultsNavigate = async () => {
    const { setMessage, onClose } = this.props;
    try {
      await MacroCalculatorStore.publishAnswers();
      MacroCalculatorStore.reset();
      onClose();
    } catch (error: any) {
      setMessage(error.message, SnackbarTypeValues.ALERT);
    }
  };

  onPrevPress = () => {
    this.showContactCoachAlert = false;
    MacroCalculatorStore.stepBackward();
  };

  render() {
    const { onClose } = this.props;

    return (
      <Container>
        {this.isNoInternetConnectionError ? (
          <NoInternetConnectionPlaceholder type={"popup"} onSubmit={onClose} />
        ) : MacroCalculatorStore.isLoading ? (
          <LoaderContainer>
            <Spinner color={styleConst.colors.primary} large />
          </LoaderContainer>
        ) : (
          <>
            <Content>
              {!MacroCalculatorStore.isFirstStep && (
                <BackButtonWrapper>
                  <ButtonInline
                    text={"Back"}
                    iconLeft={<I24ArrowBack />}
                    onPress={this.onPrevPress}
                  />
                </BackButtonWrapper>
              )}

              <Header>
                <Title>{"Macro Calculator"}</Title>
                <PageInfo>
                  {`${MacroCalculatorStore.current} of ${MacroCalculatorStore.count}`}
                </PageInfo>
              </Header>
              {this.group ? (
                <ProgressBar
                  current={this.current}
                  count={this.count}
                  width={702}
                />
              ) : null}

              {this.group ? (
                <GroupContainer>
                  {this.group?.title ? (
                    <QuestionTitle>{this.group?.title}</QuestionTitle>
                  ) : null}
                  {this.group?.subtitle ? (
                    <QuestionSubtitle>{this.group?.subtitle}</QuestionSubtitle>
                  ) : null}
                  <QuestionGroup
                    group={this.group}
                    onAnswer={this.onAnswer}
                    store={MacroCalculatorStore}
                  />
                </GroupContainer>
              ) : null}
            </Content>
            {this.showContactCoachAlert && (
              <AlertWrapper>
                <Alert>
                  Please contact your coach to get your custom Macro plan.
                </Alert>
              </AlertWrapper>
            )}
            <Controls>
              <ButtonWrapper>
                <Button
                  text={this.nextButtonTitle}
                  iconRight={
                    !MacroCalculatorStore.isLastStep ? <I32Arrow /> : null
                  }
                  onClick={this.onNextPress}
                  disabled={this.isNextButtonDisabled}
                  loading={MacroCalculatorStore.isManaging}
                  height={50}
                  width={280}
                />
              </ButtonWrapper>
            </Controls>
          </>
        )}
      </Container>
    );
  }
}

export default withGlobalSnackbar(MacroCalculatorPopup);
