import styled, { css } from "styled-components";

interface IWaterProgressBar {
  progress?: number;
}

export const WaterProgressContainer = styled.div`
  z-index: 1;
`;

export const WaterProgressBar = styled.div<IWaterProgressBar>`
  display: flex;

  position: relative;

  ${({ progress }) =>
    progress &&
    css`
      transform: translateY(${(100 - progress) * 0.9}%);
    `}

  z-index: 1;
`;
