import * as React from "react";

const I283AppStore = () => {
  return (
    <svg width={283} height={83} viewBox="0 0 283 83" fill="none">
      <path
        d="M10.4815 0.5H272.519C278.012 0.5 282.5 4.94969 282.5 10.375V72.625C282.5 78.0503 278.012 82.5 272.519 82.5H10.4815C4.98798 82.5 0.5 78.0503 0.5 72.625V10.375C0.5 4.94969 4.98798 0.5 10.4815 0.5Z"
        stroke="#7CCBC7"
      />
      <path
        d="M66.6563 41.2177C66.6788 39.4858 67.1436 37.7877 68.0073 36.2813C68.871 34.775 70.1057 33.5093 71.5963 32.6022C70.6493 31.2635 69.4001 30.1618 67.9477 29.3846C66.4953 28.6074 64.8799 28.1761 63.2297 28.125C59.7095 27.7593 56.2968 30.21 54.503 30.21C52.6745 30.21 49.9126 28.1613 46.9385 28.2219C45.0148 28.2834 43.14 28.8371 41.4967 29.8291C39.8535 30.8211 38.4979 32.2175 37.5619 33.8822C33.5077 40.8302 36.5318 51.0414 40.4154 56.6576C42.3585 59.4077 44.6294 62.4796 47.6007 62.3707C50.5084 62.2513 51.5944 60.5354 55.1042 60.5354C58.5815 60.5354 59.6003 62.3707 62.632 62.3014C65.7521 62.2513 67.7179 59.5391 69.5928 56.763C70.9889 54.8034 72.0632 52.6377 72.7759 50.346C70.9632 49.5871 69.4163 48.3168 68.328 46.6936C67.2398 45.0703 66.6584 43.1659 66.6563 41.2177Z"
        fill="#7CCBC7"
      />
      <path
        d="M60.93 24.4314C62.6312 22.4099 63.4693 19.8117 63.2663 17.1884C60.6673 17.4586 58.2665 18.6881 56.5423 20.6321C55.6993 21.5817 55.0536 22.6865 54.6422 23.8833C54.2309 25.0801 54.0618 26.3454 54.1447 27.6069C55.4447 27.6202 56.7308 27.3413 57.9061 26.7912C59.0814 26.2412 60.1154 25.4343 60.93 24.4314Z"
        fill="#7CCBC7"
      />
      <path
        d="M109.7 55.4086H99.7777L97.3948 62.3732H93.192L102.591 36.6059H106.957L116.356 62.3732H112.081L109.7 55.4086ZM100.805 52.1948H108.671L104.793 40.8917H104.685L100.805 52.1948Z"
        fill="#7CCBC7"
      />
      <path
        d="M136.653 52.981C136.653 58.819 133.497 62.5698 128.733 62.5698C127.526 62.6322 126.326 62.3571 125.27 61.7758C124.214 61.1945 123.344 60.3306 122.761 59.283H122.671V68.5881H118.775V43.5868H122.546V46.7114H122.618C123.228 45.6688 124.112 44.8093 125.176 44.2245C126.24 43.6397 127.445 43.3514 128.661 43.3902C133.478 43.3902 136.653 47.1592 136.653 52.981ZM132.649 52.981C132.649 49.1775 130.663 46.677 127.633 46.677C124.657 46.677 122.655 49.2302 122.655 52.981C122.655 56.7663 124.657 59.3012 127.633 59.3012C130.663 59.3012 132.649 56.8189 132.649 52.981Z"
        fill="#7CCBC7"
      />
      <path
        d="M157.543 52.981C157.543 58.819 154.386 62.5698 149.622 62.5698C148.415 62.6322 147.215 62.3571 146.159 61.7758C145.103 61.1945 144.234 60.3306 143.651 59.283H143.56V68.5881H139.665V43.5868H143.435V46.7114H143.507C144.117 45.6688 145.001 44.8094 146.065 44.2245C147.129 43.6397 148.334 43.3514 149.55 43.3902C154.367 43.3902 157.543 47.1592 157.543 52.981ZM153.538 52.981C153.538 49.1775 151.553 46.677 148.523 46.677C145.546 46.677 143.544 49.2302 143.544 52.981C143.544 56.7663 145.546 59.3012 148.523 59.3012C151.553 59.3012 153.538 56.8189 153.538 52.981Z"
        fill="#7CCBC7"
      />
      <path
        d="M171.349 55.1938C171.637 57.749 174.145 59.4268 177.572 59.4268C180.856 59.4268 183.218 57.749 183.218 55.445C183.218 53.4449 181.793 52.2474 178.42 51.4267L175.046 50.6222C170.266 49.4793 168.047 47.2665 168.047 43.6758C168.047 39.23 171.961 36.1762 177.519 36.1762C183.019 36.1762 186.79 39.23 186.917 43.6758H182.985C182.749 41.1043 180.602 39.5522 177.463 39.5522C174.325 39.5522 172.178 41.1226 172.178 43.4083C172.178 45.23 173.549 46.302 176.905 47.1226L179.773 47.8197C185.114 49.0699 187.333 51.1936 187.333 54.9626C187.333 59.7833 183.453 62.8026 177.283 62.8026C171.51 62.8026 167.613 59.8543 167.361 55.1936L171.349 55.1938Z"
        fill="#7CCBC7"
      />
      <path
        d="M195.741 39.1409V43.5868H199.35V46.6405H195.741V56.9972C195.741 58.6062 196.463 59.3559 198.05 59.3559C198.478 59.3485 198.906 59.3188 199.331 59.2667V62.3022C198.618 62.4341 197.893 62.4939 197.167 62.4805C193.325 62.4805 191.826 61.0519 191.826 57.4085V46.6405H189.067V43.5868H191.826V39.1409H195.741Z"
        fill="#7CCBC7"
      />
      <path
        d="M201.44 52.981C201.44 47.0701 204.957 43.3558 210.441 43.3558C215.944 43.3558 219.445 47.07 219.445 52.981C219.445 58.9081 215.963 62.6062 210.441 62.6062C204.922 62.6062 201.44 58.9081 201.44 52.981ZM215.475 52.981C215.475 48.9262 213.598 46.5331 210.441 46.5331C207.285 46.5331 205.409 48.9445 205.409 52.981C205.409 57.052 207.285 59.4269 210.441 59.4269C213.598 59.4269 215.475 57.052 215.475 52.981Z"
        fill="#7CCBC7"
      />
      <path
        d="M222.657 43.5868H226.372V46.7843H226.462C226.714 45.7857 227.305 44.9031 228.136 44.285C228.968 43.6669 229.989 43.3509 231.028 43.3902C231.477 43.3886 231.924 43.4369 232.362 43.534V47.141C231.796 46.9695 231.204 46.8908 230.612 46.908C230.046 46.8852 229.482 46.984 228.958 47.1975C228.434 47.411 227.963 47.7341 227.578 48.1447C227.192 48.5554 226.901 49.0437 226.724 49.5763C226.547 50.1089 226.488 50.6731 226.552 51.2302V62.3732H222.657L222.657 43.5868Z"
        fill="#7CCBC7"
      />
      <path
        d="M250.324 56.8554C249.8 60.2658 246.445 62.6062 242.152 62.6062C236.631 62.6062 233.204 58.9446 233.204 53.0701C233.204 47.1775 236.649 43.3557 241.988 43.3557C247.239 43.3557 250.541 46.9262 250.541 52.6223V53.9435H237.136V54.1765C237.074 54.8679 237.162 55.5644 237.393 56.2198C237.624 56.8752 237.993 57.4744 238.476 57.9777C238.959 58.481 239.544 58.8768 240.194 59.1389C240.843 59.401 241.541 59.5233 242.242 59.4978C243.163 59.5831 244.086 59.3721 244.876 58.896C245.666 58.4199 246.279 57.7042 246.625 56.8554L250.324 56.8554ZM237.155 51.2484H246.643C246.678 50.6268 246.583 50.0047 246.364 49.4212C246.144 48.8377 245.805 48.3053 245.368 47.8576C244.931 47.4099 244.405 47.0565 243.824 46.8195C243.242 46.5825 242.617 46.467 241.988 46.4804C241.354 46.4767 240.725 46.5973 240.138 46.8354C239.551 47.0735 239.017 47.4243 238.568 47.8676C238.118 48.3109 237.762 48.8379 237.52 49.4181C237.277 49.9984 237.153 50.6205 237.155 51.2484Z"
        fill="#7CCBC7"
      />
      <path
        d="M100.317 17.2106C101.134 17.1526 101.953 17.2748 102.717 17.5682C103.48 17.8617 104.168 18.3192 104.731 18.9077C105.294 19.4962 105.718 20.2011 105.973 20.9715C106.227 21.7419 106.306 22.5586 106.203 23.3627C106.203 27.3181 104.043 29.5918 100.317 29.5918H95.7991V17.2106H100.317ZM97.7418 27.8409H100.1C100.684 27.8754 101.268 27.7807 101.81 27.5636C102.352 27.3465 102.838 27.0126 103.233 26.586C103.628 26.1595 103.922 25.6511 104.094 25.0978C104.266 24.5446 104.31 23.9604 104.225 23.3879C104.304 22.8176 104.255 22.2371 104.081 21.6879C103.906 21.1387 103.612 20.6345 103.217 20.2114C102.823 19.7882 102.339 19.4566 101.8 19.2403C101.261 19.024 100.681 18.9284 100.1 18.9603H97.7418V27.8409Z"
        fill="#7CCBC7"
      />
      <path
        d="M108.397 24.9158C108.338 24.3018 108.409 23.6823 108.606 23.097C108.802 22.5117 109.12 21.9735 109.54 21.5171C109.959 21.0606 110.47 20.6959 111.04 20.4463C111.609 20.1968 112.226 20.0678 112.849 20.0678C113.472 20.0678 114.088 20.1968 114.658 20.4463C115.228 20.6959 115.739 21.0606 116.158 21.5171C116.577 21.9735 116.895 22.5117 117.092 23.097C117.289 23.6823 117.36 24.3018 117.3 24.9158C117.361 25.5305 117.291 26.1509 117.095 26.7371C116.898 27.3233 116.58 27.8625 116.161 28.3198C115.742 28.7772 115.231 29.1427 114.66 29.3928C114.09 29.6429 113.473 29.7721 112.849 29.7721C112.225 29.7721 111.608 29.6429 111.037 29.3928C110.467 29.1427 109.956 28.7772 109.537 28.3198C109.117 27.8625 108.799 27.3233 108.603 26.7371C108.407 26.1509 108.337 25.5305 108.397 24.9158ZM115.384 24.9158C115.384 22.8905 114.465 21.7061 112.852 21.7061C111.233 21.7061 110.322 22.8905 110.322 24.9158C110.322 26.9574 111.233 28.1327 112.852 28.1327C114.465 28.1327 115.384 26.9493 115.384 24.9158Z"
        fill="#7CCBC7"
      />
      <path
        d="M129.135 29.5916H127.203L125.252 22.7101H125.104L123.161 29.5916H121.247L118.645 20.2481H120.535L122.226 27.3778H122.365L124.306 20.2481H126.093L128.034 27.3778H128.181L129.864 20.2481H131.727L129.135 29.5916Z"
        fill="#7CCBC7"
      />
      <path
        d="M133.915 20.2481H135.709V21.7324H135.848C136.084 21.1993 136.482 20.7524 136.987 20.454C137.492 20.1556 138.078 20.0205 138.665 20.0677C139.124 20.0335 139.585 20.1021 140.014 20.2683C140.443 20.4345 140.829 20.6941 141.142 21.0278C141.456 21.3616 141.69 21.7609 141.827 22.1963C141.963 22.6317 141.999 23.0919 141.932 23.5429V29.5915H140.069V24.006C140.069 22.5044 139.41 21.7577 138.032 21.7577C137.72 21.7433 137.409 21.7959 137.12 21.9117C136.83 22.0276 136.569 22.204 136.355 22.4289C136.141 22.6538 135.979 22.9218 135.879 23.2147C135.78 23.5075 135.745 23.8182 135.778 24.1255V29.5916H133.915L133.915 20.2481Z"
        fill="#7CCBC7"
      />
      <path d="M144.9 16.6006H146.763V29.5916H144.9V16.6006Z" fill="#7CCBC7" />
      <path
        d="M149.353 24.9158C149.294 24.3017 149.365 23.6822 149.561 23.0969C149.758 22.5115 150.076 21.9734 150.495 21.5169C150.915 21.0604 151.426 20.6957 151.995 20.4462C152.565 20.1966 153.182 20.0677 153.805 20.0677C154.428 20.0677 155.044 20.1966 155.614 20.4462C156.184 20.6957 156.695 21.0604 157.114 21.5169C157.534 21.9734 157.852 22.5115 158.049 23.0969C158.245 23.6822 158.316 24.3017 158.257 24.9158C158.317 25.5305 158.247 26.1509 158.051 26.7371C157.855 27.3234 157.537 27.8625 157.117 28.3198C156.698 28.7772 156.187 29.1426 155.616 29.3927C155.046 29.6428 154.429 29.772 153.805 29.772C153.181 29.772 152.564 29.6428 151.994 29.3927C151.423 29.1426 150.912 28.7772 150.492 28.3198C150.073 27.8625 149.755 27.3234 149.559 26.7371C149.363 26.1509 149.292 25.5305 149.353 24.9158ZM156.34 24.9158C156.34 22.8905 155.421 21.7061 153.807 21.7061C152.188 21.7061 151.277 22.8905 151.277 24.9158C151.277 26.9574 152.188 28.1327 153.807 28.1327C155.421 28.1327 156.34 26.9493 156.34 24.9158Z"
        fill="#7CCBC7"
      />
      <path
        d="M160.218 26.9493C160.218 25.2674 161.483 24.2978 163.729 24.16L166.286 24.0141V23.2076C166.286 22.2207 165.627 21.6635 164.353 21.6635C163.314 21.6635 162.593 22.0414 162.386 22.702H160.583C160.773 21.0971 162.298 20.0677 164.439 20.0677C166.806 20.0677 168.141 21.2339 168.141 23.2076V29.5917H166.347V28.2786H166.2C165.901 28.7496 165.481 29.1336 164.983 29.3913C164.484 29.649 163.926 29.7713 163.365 29.7456C162.968 29.7864 162.568 29.7446 162.189 29.6228C161.81 29.501 161.461 29.3019 161.165 29.0385C160.868 28.775 160.631 28.453 160.468 28.0931C160.305 27.7333 160.22 27.3436 160.218 26.9493ZM166.286 26.1509V25.3697L163.981 25.5156C162.681 25.6017 162.091 26.0394 162.091 26.8631C162.091 27.7041 162.828 28.1934 163.842 28.1934C164.139 28.2232 164.439 28.1935 164.724 28.1062C165.009 28.0188 165.273 27.8756 165.501 27.6851C165.729 27.4945 165.916 27.2605 166.051 26.9969C166.186 26.7333 166.266 26.4456 166.286 26.1509Z"
        fill="#7CCBC7"
      />
      <path
        d="M170.589 24.9158C170.589 21.9634 172.122 20.0931 174.507 20.0931C175.097 20.0662 175.683 20.206 176.195 20.4963C176.708 20.7866 177.127 21.2153 177.402 21.7323H177.541V16.6006H179.404V29.5916H177.619V28.1154H177.472C177.175 28.6289 176.742 29.0523 176.219 29.3397C175.697 29.627 175.105 29.7674 174.507 29.7456C172.106 29.7457 170.589 27.8753 170.589 24.9158ZM172.514 24.9158C172.514 26.8976 173.457 28.0901 175.036 28.0901C176.606 28.0901 177.576 26.8804 177.576 24.9239C177.576 22.9766 176.596 21.7496 175.036 21.7496C173.467 21.7496 172.514 22.9502 172.514 24.9158Z"
        fill="#7CCBC7"
      />
      <path
        d="M187.112 24.9158C187.052 24.3018 187.123 23.6823 187.32 23.097C187.517 22.5117 187.835 21.9735 188.254 21.5171C188.673 21.0606 189.184 20.6959 189.754 20.4463C190.324 20.1968 190.94 20.0678 191.563 20.0678C192.187 20.0678 192.803 20.1968 193.373 20.4463C193.943 20.6959 194.453 21.0606 194.873 21.5171C195.292 21.9735 195.61 22.5117 195.807 23.097C196.003 23.6823 196.074 24.3018 196.015 24.9158C196.075 25.5305 196.005 26.1509 195.809 26.7371C195.613 27.3233 195.295 27.8625 194.876 28.3198C194.456 28.7772 193.945 29.1427 193.375 29.3928C192.804 29.6429 192.187 29.7721 191.563 29.7721C190.939 29.7721 190.322 29.6429 189.752 29.3928C189.182 29.1427 188.67 28.7772 188.251 28.3198C187.832 27.8625 187.514 27.3233 187.318 26.7371C187.121 26.1509 187.051 25.5305 187.112 24.9158ZM194.099 24.9158C194.099 22.8905 193.18 21.7061 191.566 21.7061C189.947 21.7061 189.036 22.8905 189.036 24.9158C189.036 26.9574 189.947 28.1327 191.566 28.1327C193.18 28.1327 194.099 26.9493 194.099 24.9158Z"
        fill="#7CCBC7"
      />
      <path
        d="M198.515 20.2481H200.308V21.7324H200.447C200.683 21.1993 201.081 20.7524 201.586 20.454C202.091 20.1556 202.678 20.0205 203.264 20.0677C203.723 20.0335 204.184 20.1021 204.613 20.2683C205.042 20.4345 205.428 20.6941 205.742 21.0278C206.055 21.3616 206.289 21.7609 206.426 22.1963C206.563 22.6317 206.599 23.0919 206.531 23.5429V29.5915H204.668V24.006C204.668 22.5044 204.009 21.7577 202.631 21.7577C202.32 21.7433 202.008 21.7959 201.719 21.9117C201.429 22.0276 201.169 22.204 200.955 22.4289C200.741 22.6538 200.578 22.9218 200.479 23.2147C200.379 23.5075 200.344 23.8182 200.377 24.1255V29.5916H198.515V20.2481Z"
        fill="#7CCBC7"
      />
      <path
        d="M217.058 17.9218V20.2906H219.103V21.8438H217.058V26.6484C217.058 27.6271 217.465 28.0557 218.392 28.0557C218.63 28.0549 218.867 28.0407 219.103 28.0131V29.5491C218.768 29.6083 218.429 29.6398 218.09 29.6433C216.018 29.6433 215.193 28.9219 215.193 27.1205V21.8438H213.694V20.2905H215.193V17.9218H217.058Z"
        fill="#7CCBC7"
      />
      <path
        d="M221.647 16.6006H223.494V21.7496H223.641C223.889 21.2115 224.298 20.7621 224.813 20.4626C225.328 20.163 225.924 20.028 226.52 20.0758C226.977 20.0512 227.433 20.1267 227.857 20.297C228.281 20.4672 228.662 20.728 228.973 21.0607C229.283 21.3934 229.516 21.7898 229.654 22.2217C229.792 22.6536 229.831 23.1102 229.771 23.5591V29.5916H227.906V24.014C227.906 22.5216 227.203 21.7658 225.887 21.7658C225.567 21.7398 225.245 21.7834 224.944 21.8934C224.642 22.0035 224.369 22.1775 224.142 22.4031C223.916 22.6287 223.742 22.9006 223.633 23.1997C223.524 23.4988 223.482 23.8179 223.51 24.1346V29.5916H221.648L221.647 16.6006Z"
        fill="#7CCBC7"
      />
      <path
        d="M240.633 27.0688C240.38 27.9228 239.831 28.6614 239.084 29.1556C238.336 29.6497 237.436 29.8679 236.543 29.772C235.921 29.7882 235.303 29.6703 234.731 29.4265C234.16 29.1826 233.649 28.8186 233.235 28.3597C232.82 27.9009 232.511 27.3581 232.329 26.7692C232.147 26.1803 232.097 25.5593 232.182 24.9492C232.099 24.3374 232.15 23.715 232.332 23.1243C232.513 22.5336 232.82 21.9884 233.233 21.5255C233.646 21.0626 234.154 20.6929 234.724 20.4414C235.293 20.1899 235.911 20.0625 236.534 20.0677C239.161 20.0677 240.745 21.8439 240.745 24.778V25.4214H234.08V25.5247C234.051 25.8676 234.094 26.2128 234.208 26.538C234.321 26.8633 234.502 27.1615 234.739 27.4135C234.975 27.6655 235.263 27.8658 235.582 28.0015C235.902 28.1372 236.246 28.2053 236.594 28.2015C237.039 28.2544 237.491 28.175 237.891 27.9733C238.291 27.7716 238.621 27.4567 238.84 27.0688L240.633 27.0688ZM234.08 24.0576H238.848C238.871 23.744 238.828 23.4291 238.721 23.1329C238.614 22.8368 238.446 22.566 238.227 22.3381C238.009 22.1101 237.744 21.93 237.451 21.8093C237.157 21.6886 236.842 21.6299 236.524 21.6371C236.202 21.6331 235.882 21.693 235.584 21.8133C235.285 21.9335 235.014 22.1116 234.786 22.3372C234.559 22.5628 234.379 22.8312 234.257 23.1267C234.136 23.4222 234.076 23.7387 234.08 24.0576Z"
        fill="#7CCBC7"
      />
    </svg>
  );
};

export default I283AppStore;
