import React from "react";

import I24CalendarFilled from "assets/icons/I24CalendarFilled";
import I24PlusFilled from "assets/icons/I24PlusFilled";
import I24CrossFilled from "assets/icons/I24CrossFilled";
import I24Star from "assets/icons/I24Star";

import styleConst from "constants/style";

import { Action, Actions, ActionTooltip } from "../styles";

type Props = {
  id: number;
  onToday: (id: number) => void;
  onSelectDay: (id: number) => void;
  onMark: (id: number) => void;
  isFavorite?: boolean;
};

const Favorites = (props: Props) => {
  const { id, onToday, onSelectDay, onMark, isFavorite } = props;

  const onPress = (action: (id: number) => void) => (event: any) => {
    event.stopPropagation();
    action(id);
  };

  return (
    <Actions>
      <Action onClick={onPress(onToday)}>
        <I24CalendarFilled color={styleConst.colors.black25} />
        <ActionTooltip>{"Today"}</ActionTooltip>
      </Action>

      <Action onClick={onPress(onSelectDay)}>
        <I24PlusFilled color={styleConst.colors.black25} />
        <ActionTooltip>{"Select a day"}</ActionTooltip>
      </Action>
      {isFavorite ? (
        <Action onClick={onPress(onMark)}>
          <I24CrossFilled color={styleConst.colors.black25} />
          <ActionTooltip>{"Remove"}</ActionTooltip>
        </Action>
      ) : (
        <Action onClick={onPress(onMark)}>
          <I24Star color={styleConst.colors.black25} />
          <ActionTooltip>{"Favorite"}</ActionTooltip>
        </Action>
      )}
    </Actions>
  );
};

export default Favorites;
