import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Chevron2 = ({ color = styleConst.colors.white }: Props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      fill={color}
      fillRule="evenodd"
      d="M6.293 9.293a1 1 0 011.414 0L12 13.586l4.293-4.293a1 1 0 011.414 1.414L12 16.414l-5.707-5.707a1 1 0 010-1.414z"
      clipRule="evenodd"
    />
  </svg>
);

export default I24Chevron2;
