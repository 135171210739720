import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { addDays, isSameDay, startOfDay, startOfWeek } from "date-fns";

import FoodLogStore from "stores/FoodLogStore";

import DaySelect from "components/DaySelect";
import { SnackbarTypeValues } from "components/Snackbar";

import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";

import { ACTION_DAY_MEALS_TYPES } from "constants/nutrition";

import { formatDate, SERVER_DATE_FORMAT } from "utils/date";
import { capitalize } from "utils/common";
import {
  Container,
  Content,
  Text,
  Controls,
  SubmitButton,
  ResultText,
} from "./styles";

type Props = {
  ids: number[];
  activeDay: Date;
  fetch: Function;
  actionType: string;
  onClose: () => void;
} & SnackbarContextProps;

@observer
class SelectDatePopup extends React.Component<Props> {
  @observable activeDay: Date = startOfDay(new Date());
  @observable showResult: boolean = false;

  @computed get week() {
    const start = startOfWeek(this.activeDay, { weekStartsOn: 1 });
    return [...Array(7).keys()].map(count => addDays(start, count));
  }

  get isCopyAction() {
    const { actionType } = this.props;
    return actionType === ACTION_DAY_MEALS_TYPES.COPY;
  }

  get isMoveAction() {
    const { actionType } = this.props;
    return actionType === ACTION_DAY_MEALS_TYPES.MOVE;
  }

  get action() {
    return this.isCopyAction ? FoodLogStore.copyMeals : FoodLogStore.moveMeals;
  }

  onDayChange = async (day: Date) => {
    this.activeDay = day;
  };

  onActionToDate = async () => {
    const { ids, activeDay, fetch, setMessage, onClose } = this.props;

    try {
      const date = formatDate(this.activeDay, SERVER_DATE_FORMAT);
      await this.action(ids, date);

      if (isSameDay(this.activeDay, activeDay) || this.isMoveAction) {
        await fetch();
      }
      this.showResult = true;
    } catch (error: any) {
      console.log(`error`, error);
      setMessage(error?.message, SnackbarTypeValues.ALERT);
      onClose();
    }
  };

  render() {
    const { actionType, onClose } = this.props;
    const formattedDate = formatDate(this.activeDay, "eeee, LLLL do");

    return (
      <Container>
        <Content>
          {this.showResult ? (
            <>
              <ResultText>
                Selected meals {this.isCopyAction ? "copied" : "moved"} to{" "}
                {formattedDate}
              </ResultText>
              <Controls>
                <SubmitButton text={"Ok"} onClick={onClose} height={50} />
              </Controls>
            </>
          ) : (
            <>
              <Text>Select Date</Text>
              <DaySelect
                active={this.activeDay}
                week={this.week}
                onDayChange={this.onDayChange}
              />
              <Controls>
                <SubmitButton
                  text={`${capitalize(actionType)} to Date`}
                  onClick={this.onActionToDate}
                  height={50}
                />
              </Controls>
            </>
          )}
        </Content>
      </Container>
    );
  }
}

export default withGlobalSnackbar(SelectDatePopup);
