import React from "react";

import Icon from "components/Icon";
import Spinner from "components/Spinner";

import styleConst from "constants/style";

import { Button } from "./styles";

type Props = {
  icon?: React.ReactNode;
  onClick?: () => void;
  shouldStopPropagation?: boolean;
  className?: any;
  color?: string;
  hoverColor?: string;
  backgroundColor?: string;
  disabled?: boolean;
  isLoading?: boolean;
};

const IconButton = ({
  icon,
  onClick,
  shouldStopPropagation,
  className,
  color,
  hoverColor,
  backgroundColor = styleConst.colors.dark5,
  disabled,
  isLoading,
}: Props) => {
  const onClickHandle = (e: { [key: string]: any }) => {
    if (shouldStopPropagation) {
      e.stopPropagation();
    }
    onClick?.();
  };

  return (
    <Button
      className={className}
      onClick={onClickHandle}
      disabled={isLoading || disabled}
      backgroundColor={backgroundColor}
    >
      {!isLoading ? (
        <Icon color={color} hoverColor={hoverColor} icon={icon} />
      ) : (
        <Spinner color={styleConst.colors.primary} />
      )}
    </Button>
  );
};

export default IconButton;
