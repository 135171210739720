import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I36Star = ({ color = styleConst.colors.black }: Props) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 5.98899L15.9337 10.7152C15.1908 12.4145 13.6064 13.5958 11.7657 13.8227L6.58827 14.4611L10.596 18.3479C11.864 19.5777 12.4286 21.3626 12.0984 23.0978L11.0622 28.5443L15.331 26.0242C16.9775 25.0522 19.0224 25.0522 20.6689 26.0242L24.9377 28.5443L23.9015 23.0978C23.5713 21.3626 24.1359 19.5776 25.4039 18.3479L29.4116 14.4611L24.2342 13.8227C22.3935 13.5958 20.8091 12.4145 20.0662 10.7152L18 5.98899ZM9.63422 29.3873C9.63383 29.3876 9.6346 29.3871 9.63422 29.3873V29.3873ZM20.0615 3.2154C19.2754 1.41713 16.7245 1.41713 15.9384 3.2154L13.1849 9.51349C12.8665 10.2418 12.1875 10.748 11.3986 10.8453L4.66587 11.6754C2.78998 11.9066 2.01794 14.2077 3.37474 15.5236L8.50739 20.5014C9.05081 21.0285 9.29278 21.7935 9.15129 22.5371L7.80514 29.6127C7.44335 31.5143 9.49241 32.9548 11.1593 31.9708L16.8561 28.6077C17.5618 28.1911 18.4381 28.1911 19.1438 28.6077L24.8406 31.9708C26.5075 32.9548 28.5566 31.5143 28.1948 29.6127L26.8486 22.5371C26.7071 21.7935 26.9491 21.0285 27.4925 20.5014L32.6252 15.5236C33.982 14.2077 33.2099 11.9066 31.334 11.6754L24.6013 10.8453C23.8124 10.748 23.1334 10.2418 22.815 9.51349L20.0615 3.2154Z"
      fill={color}
      fillOpacity="0.25"
    />
  </svg>
);

export default I36Star;
