import React from "react";

import { Container } from "./styles";

type Props = {
  children: React.ReactNode;
  className?: any;
};

function Row({ children, className }: Props) {
  return <Container className={className}>{children}</Container>;
}

export default Row;
