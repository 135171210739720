export const LINKS = {
  TERMS_AND_CONDITIONS: "https://www.fasterwaytofatloss.com/appprivacypolicy",
  PRIVACY_POLICY: "https://www.fasterwaytofatloss.com/privacy-policy",
  SMS_POLICY: "https://www.fasterwaytofatloss.com/sms-policy",

  TODAY_SHOP: "https://fasterwayshop.com/products",
  TODAY_BLOG: "https://www.fasterwaytofatloss.com/blogs",
  TODAY_PODCAST: "https://www.fasterwaytofatloss.com/podcast",

  UPDATE_BILLING_INFO: "https://share.hsforms.com/1WsyEsB22RZiOzM0860xa9Q4cm6v",
  CANCEL_SUBSCRIPTION: "https://www.fasterwaytofatloss.com/manage-subscription",
  UPDATE_EMAIL: "https://www.fasterwaytofatloss.com/emailchangerequest",

  MAIN_PAGE: "https://www.fasterwaytofatloss.com",

  HELP: "https://community.fasterwaytofatloss.com/en/help/faster-way-app",

  SUBMIT_FOOD: "https://fasterwaytofatloss.typeform.com/to/h2zg2LsC",

  GOOGLE_PLAY_LINK:
    "https://play.google.com/store/apps/details?id=com.fasterwaytofatloss.FasterWayToFatLoss",
  APP_STORE_LINK:
    "https://apps.apple.com/us/app/faster-way-to-fat-loss/id1489886595",

  FACEBOOK: "https://www.facebook.com/FASTerWay/",
  INSTAGRAM: "https://www.instagram.com/fasterwaytofatloss/",
};
