import styled, { css } from "styled-components";

import topBackgroundImage from "assets/images/svg/promote_popup_background.svg";
import WaterEmptyImage from "assets/icons/WaterEmpty";

import Button from "components/Button";

import styleConst from "constants/style";

interface IContent {
  top?: boolean;
  bottom?: boolean;
}

interface IIConWrapper {
  contentIcon?: boolean;
}

interface IControlButton {
  leftControl?: boolean;
  rightControl?: boolean;
  disabled?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 404px;
  height: 744px;

  border-radius: 10px;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Content = styled.div<IContent>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ top }) =>
    top &&
    css`
      height: 184px;
      overflow: hidden;
      background-image: url(${topBackgroundImage});
      background-repeat: no-repeat;
      background-position: 50% 50%;
    `}

  ${({ bottom }) =>
    bottom &&
    css`
      height: 304px;
      padding: 43px 66px 40px;
    `}
`;

export const IconWrapper = styled.div<IIConWrapper>`
  margin-right: 20px;

  ${({ contentIcon }) =>
    contentIcon &&
    css`
      display: flex;

      margin-right: 6px;
    `}
`;

export const Text = styled.div`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 18px;
  font-weight: 500;

  color: ${styleConst.colors.white};
  padding: 0 40px;
`;

export const Amount = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  font-family: ${styleConst.fonts.montserrat};

  text-align: center;
  color: ${styleConst.colors.dark};
`;

export const Control = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 20px;
`;

export const ControlButton = styled.button<IControlButton>`
  height: 56px;
  width: 56px;

  justify-content: center;
  align-items: center;
  display: flex;

  background-color: ${styleConst.colors.white};
  border: 2px solid ${styleConst.colors.black10};
  cursor: pointer;

  font-size: 22px;
  font-weight: 400;
  font-family: ${styleConst.fonts.roboto};

  text-align: center;
  color: ${styleConst.colors.black50};

  ${({ leftControl }) =>
    leftControl &&
    css`
      border-right: none;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    `};

  ${({ rightControl }) =>
    rightControl &&
    css`
      border-left: none;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `};
`;

export const ControlInputWrapper = styled.div`
  flex: 1;
  height: 56px;
  width: 120px;
`;

export const Goal = styled.div`
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: ${styleConst.fonts.roboto};
  text-align: center;
  color: ${styleConst.colors.dark};
`;

export const ImageMeasurement = styled.div`
  position: relative;
  z-index: 1;

  height: 324px;
  width: 324px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 200px;
  border: 10px solid #f9fafa;

  overflow: hidden;

  background-color: rgba(237, 250, 249, 0.4);
`;

export const NumberMeasurement = styled.div`
  padding: 0 0 20px 0;
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Placeholder = styled(WaterEmptyImage)``;

export const PlaceholderText = styled.div`
  margin-top: 4px;

  text-align: center;
  font-size: 16px;
  line-height: 18px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.black50};
`;

export const inputStyles = css`
  height: 56px;

  border-top: 2px solid ${styleConst.colors.black10};
  border-bottom: 2px solid ${styleConst.colors.black10};
  border-radius: 0;

  font-size: 18px;
  text-align: center;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.black};

  background-color: ${styleConst.colors.white};

  ${({ disabled }: any) =>
    disabled &&
    css`
      color: ${styleConst.colors.text40};
    `};
`;

export const SubmitButton = styled(Button)`
  width: 150px;
  height: 50px;

  margin: 40px 10px 60px 10px;

  background: radial-gradient(
      83.3% 179.17% at 56.29% -31.25%,
      ${styleConst.colors.primaryMedium} 0%,
      ${styleConst.colors.primary} 100%
    ),
    ${styleConst.colors.primary};
  box-shadow: 0px 20px 40px -10px ${styleConst.colors.shadow};
  border-radius: 10px;
  border: none;
`;

export const Title = styled.div`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;
  line-height: 48px;

  color: ${styleConst.colors.dark};
  padding: 53px 0 20px 0;
`;
