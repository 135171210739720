import styled, { css } from "styled-components";

import backgroundImageSmall from "assets/images/svg/dots_background_small.svg";
import backgroundImage from "assets/images/svg/dots_background.svg";

import { MEDIA_QUERIES } from "constants/layout";

interface IBackground {
  leftDots?: boolean;
}

export const Background = styled.div<IBackground>`
  position: absolute;
  top: 159px;
  z-index: -1;

  height: 388px;
  width: 100%;

  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  background-image: url(${backgroundImageSmall});

  ${MEDIA_QUERIES.DESKTOP} {
    background-image: url(${backgroundImage});
  }

  ${({ leftDots }) =>
    leftDots &&
    css`
      top: 826px;

      transform: rotate(180deg);
    `}
`;
