import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IContent {
  top?: boolean;
  bottom?: boolean;
}

interface IIConWrapper {
  contentIcon?: boolean;
}

export const ButtonWrapper = styled.div`
  width: 150px;
  margin: 0 10px;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-top: 50px;
  padding-bottom: 60px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 380px;
  height: 335px;

  border-radius: 10px;
`;

export const Content = styled.div<IContent>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 60px;
`;

export const IconWrapper = styled.div<IIConWrapper>`
  margin-right: 20px;

  ${({ contentIcon }) =>
    contentIcon &&
    css`
      display: flex;

      margin-right: 6px;
    `}
`;

export const Text = styled.div`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 18px;
  font-weight: 500;

  color: ${styleConst.colors.dark};
  padding: 53px 0 30px 0;
`;

export const ItemWrapper = styled.div`
  width: 278px;

  text-align: center;
`;

export const errorTextStyles = css`
  color: ${styleConst.colors.primaryRed1};
`;
