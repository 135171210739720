import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";

import { Placeholders } from "assets/images/placeholders";

import StudioStore from "stores/StudioStore";

import { Collection } from "components/Collection";
import Placeholder from "components/Placeholder";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import { NoInternetConnectionError, NotFoundError } from "services/api";

import { redirectToRootRoute } from "utils/route";

import { COLLECTION_TYPE } from "constants/collection";
import { CONTENT_STORE_TYPE } from "constants/studio";

import {
  Container,
  CollectionWrapper,
  NoInternetConnectionErrorContainer,
} from "./styles";

type Props = {
  categoryId: number;
  onViewAllClick: (workoutGroup: string) => () => void;
  onNavigateToWorkout: (workoutGroup?: string) => (workout: any) => void;
  isFullAccessOnly?: boolean;
  fromPopup?: boolean;
  onClose?: () => void;
  url?: string;
  history?: any;
};

@observer
class Cards extends React.Component<Props> {
  @observable isNoInternetConnectionError: boolean = false;

  get placeholder() {
    return (
      <Placeholder
        imageUrl={Placeholders.Workout}
        text="No workouts added yet"
      />
    );
  }

  @computed get details() {
    return StudioStore.details;
  }

  @computed get week() {
    return this.details?.week;
  }

  @computed get standard() {
    return this.details?.standard;
  }

  @computed get standardIsLoading() {
    return this.standard
      ? !!Object.values(this.standard).find(({ isLoading }) => isLoading)
      : false;
  }

  @computed get standardIsEmpty() {
    return this.standard && !this.standardIsLoading
      ? !Object.values(this.standard)
          .map(({ items }) => {
            return items.length !== 0;
          })
          .filter(Boolean).length
      : false;
  }

  async componentDidMount() {
    const { categoryId, isFullAccessOnly = false, history, url } = this.props;
    try {
      if (categoryId) {
        await StudioStore.fetchDetails(categoryId, isFullAccessOnly);
        StudioStore.fillDetails();
      }
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
      if (error instanceof NotFoundError && url && history) {
        redirectToRootRoute(history, url);
      }
    }
  }

  componentWillUnmount() {
    StudioStore.resetDetails();
  }

  render() {
    const {
      onViewAllClick,
      onNavigateToWorkout,
      fromPopup = false,
      onClose,
    } = this.props;

    if (this.isNoInternetConnectionError) {
      return (
        <NoInternetConnectionErrorContainer>
          <NoInternetConnectionPlaceholder
            type={fromPopup ? "popup" : "element"}
            onSubmit={onClose}
          />
        </NoInternetConnectionErrorContainer>
      );
    }

    return (
      <Container>
        {this.week
          ? Object.entries(this.week).map(([key, store]) => (
              <CollectionWrapper key={key}>
                <Collection
                  name={store.title}
                  items={store.items}
                  type={COLLECTION_TYPE.WORKOUT}
                  isLoading={store.isLoading}
                  isMoreLoading={store.isMoreLoading}
                  placeholder={this.placeholder}
                  onCardClick={onNavigateToWorkout(key)}
                  onViewAllClick={onViewAllClick(key)}
                  withTitleAdornment
                  withViewAll
                />
              </CollectionWrapper>
            ))
          : null}
        {this.standard ? (
          !this.standardIsEmpty ? (
            <>
              {this.standard.favorites.items.length ? (
                <CollectionWrapper>
                  <Collection
                    name={"Favorites"}
                    items={this.standard.favorites.items}
                    type={COLLECTION_TYPE.WORKOUT}
                    onCardClick={onNavigateToWorkout()}
                    withTitleAdornment
                  />
                </CollectionWrapper>
              ) : null}
              {Object.entries(this.standard)
                .filter(
                  ([key, store]) =>
                    key !== CONTENT_STORE_TYPE.STANDARD_FAVORITES &&
                    store.items.length,
                )
                .map(([key, store]) => (
                  <CollectionWrapper key={key}>
                    <Collection
                      name={store.title}
                      items={store.items}
                      type={COLLECTION_TYPE.WORKOUT}
                      isLoading={store.isLoading}
                      isMoreLoading={store.isMoreLoading}
                      onCardClick={onNavigateToWorkout(key)}
                      onViewAllClick={onViewAllClick(key)}
                      withTitleAdornment
                      withViewAll
                    />
                  </CollectionWrapper>
                ))}
            </>
          ) : (
            this.placeholder
          )
        ) : null}
      </Container>
    );
  }
}

export default Cards;
