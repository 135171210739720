import React from "react";

import cardBackground from "assets/images/svg/blog_card_background.svg";

import IBlog from "assets/icons/IBlog";

import {
  Card,
  CardBackground,
  CardContent,
  IconWrapper,
  Title,
} from "../../styles";

type Props = {
  title: string;
  onClick?: () => void;
};

const Blog = ({ title, onClick }: Props) => (
  <Card onClick={onClick}>
    <CardBackground imageUrl={cardBackground} />
    <CardContent>
      <IconWrapper>
        <IBlog />
      </IconWrapper>
      <Title>{title}</Title>
    </CardContent>
  </Card>
);

export default Blog;
