import * as React from "react";

const IShop = () => {
  return (
    <svg width="57" height="56" viewBox="0 0 57 56" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.7791 2.5787C55.7611 1.40989 54.8179 0.466734 53.6491 0.448752L32.1274 0.11765C30.3717 0.0906372 28.6798 0.776212 27.4382 2.01789L2.28516 27.1709C-0.249521 29.7056 -0.24952 33.8151 2.28516 36.3498L19.878 53.9426C22.4127 56.4773 26.5222 56.4773 29.0569 53.9427L54.2099 28.7896C55.4516 27.548 56.1372 25.8562 56.1102 24.1004L55.7791 2.5787ZM53.6158 2.61198L53.9469 24.1336C53.9649 25.3042 53.5079 26.432 52.6801 27.2598L27.5271 52.4128C25.8373 54.1026 23.0976 54.1026 21.4078 52.4128L20.0606 51.0656C22.7852 50.0703 25.3414 48.4796 27.5276 46.2934C35.5541 38.2669 35.5541 25.2534 27.5276 17.2269C25.3415 15.0408 22.7854 13.4501 20.0609 12.4548L28.968 3.54771C29.7958 2.71992 30.9236 2.26287 32.0942 2.28088L53.6158 2.61198ZM15.588 16.9276L3.81497 28.7007C2.68238 29.8333 2.30892 31.4376 2.69458 32.8812L14.5241 21.0517L18.3487 24.8762L18.3477 24.8772L24.4659 30.9954L23.0453 32.4159L16.9271 26.2977L10.6987 32.5262L15.6148 37.4423L14.1943 38.8629L9.27814 33.9467L6.10993 37.1149L17.7391 48.7441C19.3379 47.9363 20.8376 46.8646 22.1733 45.5289C28.9324 38.7697 28.9324 27.811 22.1733 21.0519C20.253 19.1315 17.9937 17.7568 15.588 16.9276ZM43.9729 12.2549C42.0719 10.3539 38.9897 10.3539 37.0887 12.2549C35.1877 14.156 35.1877 17.2381 37.0887 19.1391C38.9897 21.0401 42.0719 21.0401 43.9729 19.1391C45.8739 17.2381 45.8739 14.156 43.9729 12.2549ZM35.5589 10.7251C38.3048 7.97922 42.7568 7.97922 45.5027 10.7251C48.2486 13.471 48.2486 17.923 45.5027 20.6689C42.7568 23.4148 38.3048 23.4148 35.5589 20.6689C32.813 17.923 32.813 13.471 35.5589 10.7251Z"
        fill="white"
      />
    </svg>
  );
};

export default IShop;
