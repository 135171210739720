import { nutrientsFields } from "constants/nutrition";
import { round } from "./common";

type Nutrients = { [key: string]: string | number } | {};
export type NutrientsList = { label: string; value: string | number }[];

export const mainList = ["carbs", "fiber", "fat", "protein"];

export const emptyMainFieldValue = "N/A";

export const getMainFields = (nutrients: Nutrients) => {
  const mainNutrients: { [key: string]: string | number } = {};
  mainList.forEach(label => {
    /* @ts-ignore */
    const value = nutrients[label];
    mainNutrients[label] =
      typeof value === "number" ? value : emptyMainFieldValue;
  });
  return mainNutrients;
};

export const getNutrientsList = (nutrients: Nutrients): NutrientsList => {
  const items = Object.entries(nutrients)
    .map(([label, value]) => {
      if (typeof value === "number" && !Number.isNaN(value)) {
        return { label, value };
      } else {
        if (mainList.includes(label)) {
          return { label, value: emptyMainFieldValue };
        } else {
          return { label: "", value: "" };
        }
      }
    })
    .filter(({ label }) => !!label);

  return items;
};

export const getUnits = (field: string) => {
  return nutrientsFields[field]?.units ?? "";
};

export const getNutrientValue = (field: any, value: any) => {
  return value !== emptyMainFieldValue
    ? `${round(value)} ${getUnits(field)}`
    : emptyMainFieldValue;
};

export const getNutrientLabel = (field: string) => {
  return nutrientsFields[field]?.label ?? "";
};

export const getServingValue = (serving: any) =>
  serving
    ? `${serving.description} (${serving.equivalent} ${serving.unit})`
    : "";

export const getCurrentNutritionalInfo = (
  nutritionalInfo: any,
  quantity: number,
) => {
  return Object.keys(nutritionalInfo).reduce<any>((result, key) => {
    result[key] = Number(nutritionalInfo[key]) * quantity;
    return result;
  }, {});
};

export const getSumNutritionalInformation = (ingredients: any) => {
  return ingredients.reduce((result: any, item: any) => {
    const { ingredient, quantity, servingIndex } = item;
    const { nutritionalInfo } = ingredient.servings[servingIndex];

    const currentNutritionalInfo = getCurrentNutritionalInfo(
      nutritionalInfo,
      quantity,
    );

    return Object.keys(currentNutritionalInfo).reduce((prevResult, key) => {
      const prevValue = prevResult[key] || 0;
      const currentValue = currentNutritionalInfo[key] || 0;
      prevResult[key] = prevValue + currentValue;
      return prevResult;
    }, result);
  }, {});
};
