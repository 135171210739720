import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface IImage {
  imageUrl?: string;
}

interface IContainer {
  inline?: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  min-height: 210px;
  min-width: 210px;

  ${({ inline }) =>
    !inline &&
    css`
      flex-direction: column;
    `};
`;

export const Image = styled.div<IImage>`
  height: 210px;
  width: 210px;

  background-size: cover;
  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `};
`;

export const Text = styled.div`
  display: flex;
  align-items: center;

  font-family: ${styleConst.fonts.roboto};
  font-size: 14px;
  color: ${styleConst.colors.black50};
`;
