import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";

import StudioStore from "stores/StudioStore";
import WorkoutDetailsStore from "stores/WorkoutDetailsStore";

import Spinner from "components/Spinner";
import Video from "components/Video";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import { NoInternetConnectionError, NotFoundError } from "services/api";

import { redirectToRootRoute } from "utils/route";

import { WEEK } from "constants/common";

import {
  Container,
  InfoContainer,
  Section,
  Text,
  TextWrapper,
  Title,
  TitleContainer,
  TitleAdornment,
  Workout,
  Loader,
  NoInternetConnectionErrorContainer,
} from "./styles";

type Props = {
  workoutId: number | null;
  movementId: number | null;
  categoryId?: number | null;
  workoutGroup?: string | null;
  workoutInWorkoutCategoryId?: number;
  fromPopup?: boolean;
  onClose?: () => void;
  url?: string;
  history?: any;
};

@observer
class WorkoutMovement extends React.Component<Props> {
  @observable isLoading: boolean = false;
  @observable isNoInternetConnectionError: boolean = false;

  get dayOfWeek() {
    const { workoutGroup } = this.props;
    if (!workoutGroup) {
      return undefined;
    }

    const day = Object.keys(WEEK).find(key => WEEK[key] === workoutGroup);
    const parsedDay = day !== undefined ? Number.parseInt(day) : undefined;

    return typeof parsedDay === "number" ? parsedDay + 1 : undefined;
  }

  @computed
  get details() {
    const { movementId } = this.props;

    return movementId
      ? WorkoutDetailsStore.getMovementDetails(movementId)
      : undefined;
  }

  async componentDidMount() {
    const {
      categoryId = undefined,
      workoutId = undefined,
      workoutInWorkoutCategoryId,
      url,
      history,
    } = this.props;

    this.isLoading = true;

    try {
      categoryId && (await StudioStore.fetchDetails(categoryId));
      const params: any = {
        categoryId: categoryId,
        workoutId: workoutId,
        dayOfWeek: this.dayOfWeek,
        workoutInWorkoutCategoryId: workoutInWorkoutCategoryId,
      };

      await WorkoutDetailsStore.fetch(params);
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
      if (error instanceof NotFoundError && url && history) {
        redirectToRootRoute(history, url);
      }
    } finally {
      this.isLoading = false;
    }
  }

  render() {
    const { fromPopup = false, onClose } = this.props;

    if (this.isNoInternetConnectionError) {
      return (
        <NoInternetConnectionErrorContainer>
          <NoInternetConnectionPlaceholder
            type={fromPopup ? "popup" : "element"}
            onSubmit={onClose}
          />
        </NoInternetConnectionErrorContainer>
      );
    }

    if (this.isLoading) {
      return (
        <Loader>
          <Spinner large></Spinner>
        </Loader>
      );
    }
    return (
      <Container>
        <Workout>
          {this.details?.videoUrl && (
            <Video
              imageUrl={this.details.imageUrl ?? ""}
              video={this.details.videoUrl}
              duration={this.details.duration}
            />
          )}
        </Workout>

        <InfoContainer>
          <Section>
            <TitleContainer>
              <TitleAdornment />
              <Title>Instructions</Title>
            </TitleContainer>
            <TextWrapper>
              <Text>{this.details?.description}</Text>
            </TextWrapper>
          </Section>
        </InfoContainer>
      </Container>
    );
  }
}

export default WorkoutMovement;
