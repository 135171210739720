import React from "react";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";
import {
  RouteComponentProps,
  withRouter,
  generatePath,
} from "react-router-dom";

import RecipeLibraryStore from "stores/RecipeLibraryStore";

import GridCollection from "components/GridCollection";
import { Page } from "components/Layout";

import PageSelector from "../PageSelector";

import { NoInternetConnectionError } from "services/api";

import ROUTES from "navigation/routes";

import { Container } from "./styles";

type Props = {} & RouteComponentProps;

@observer
class RecipeLibrary extends React.Component<Props> {
  @observable page: number = 1;
  @observable isLoading: boolean = false;
  @observable isNoInternetConnectionError: boolean = false;

  @computed get items() {
    return RecipeLibraryStore.items;
  }

  @computed get needMore() {
    const { context } = RecipeLibraryStore;
    return context && this.page < context.pages;
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    this.isLoading = true;
    try {
      await RecipeLibraryStore.fetch(this.page);
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    } finally {
      this.isLoading = false;
    }
  };

  onCategoryNavigate = (details: any) => {
    const { history } = this.props;

    RecipeLibraryStore.resetDetails();
    RecipeLibraryStore.fillDetails(details);

    if (details?.id) {
      history.push(
        generatePath(ROUTES.NUTRITION_RECIPE_LIBRARY_CATEGORY_DETAILS, {
          categoryId: details?.id,
        }),
      );
    }
  };

  onLoadMore = async () => {
    if (this.needMore) {
      this.page++;
      await RecipeLibraryStore.fetch(this.page);
    }
  };

  render() {
    return (
      <Page
        title="Nutrition"
        titleControls={<PageSelector />}
        isLoading={this.isLoading}
        titleControlsFullWidth
        isNoInternetConnectionError={this.isNoInternetConnectionError}
      >
        <Container>
          <GridCollection
            items={this.items}
            onClick={this.onCategoryNavigate}
            needMore={this.needMore}
            onLoadMore={this.onLoadMore}
            withInfinityScroll
          />
        </Container>
      </Page>
    );
  }
}
export default withRouter(RecipeLibrary);
