import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";

import StudioStore from "stores/StudioStore";
import WorkoutDetailsStore from "stores/WorkoutDetailsStore";

import GridCollection from "components/GridCollection";
import Video from "components/Video";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";
import Spinner from "components/Spinner";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import { NoInternetConnectionError } from "services/api";

import {
  Container,
  Equipments,
  EquipmentContainer,
  EquipmentImage,
  EquipmentImageContainer,
  EquipmentText,
  InfoContainer,
  Section,
  Text,
  TextWrapper,
  Title,
  TitleContainer,
  TitleAdornment,
  Workout,
  Loader,
  NoInternetConnectionErrorContainer,
} from "./styles";

type Props = {
  workoutId: number;
  categoryId?: number | null;
  workoutGroup?: string | null;
  onNavigateToMovement: (movement: any) => void;
  onChangeIsLoading?: (value: boolean) => void;
  onClose?: () => void;
} & GlobalPopupProps;

@observer
class WorkoutDetails extends React.Component<Props> {
  @observable isLoading: boolean = false;
  @observable isNoInternetConnectionError: boolean = false;

  @computed
  get details() {
    return WorkoutDetailsStore.details;
  }

  @computed get workoutTitle() {
    return this.details?.label || this.details?.title;
  }

  @computed
  get equipments() {
    return WorkoutDetailsStore.details?.equipments;
  }

  @computed
  get movements() {
    return WorkoutDetailsStore.details?.movements;
  }

  async componentDidMount() {
    const { categoryId, workoutId } = this.props;

    this.isLoading = true;

    try {
      if (categoryId) {
        await StudioStore.fetchDetails(categoryId);
      }
      await WorkoutDetailsStore.fetch({ workoutId });
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    } finally {
      this.isLoading = false;
    }
  }

  componentWillUnmount() {
    WorkoutDetailsStore.reset();
  }

  render() {
    const { onNavigateToMovement, onClose } = this.props;

    if (this.isNoInternetConnectionError) {
      return (
        <NoInternetConnectionErrorContainer>
          <NoInternetConnectionPlaceholder type={"popup"} onSubmit={onClose} />
        </NoInternetConnectionErrorContainer>
      );
    }

    if (this.isLoading) {
      return (
        <Loader>
          <Spinner large></Spinner>
        </Loader>
      );
    }
    return (
      <Container>
        <Workout>
          {WorkoutDetailsStore.details?.videoUrl && (
            <Video
              imageUrl={WorkoutDetailsStore.details.imageUrl ?? ""}
              video={WorkoutDetailsStore.details?.videoUrl}
              duration={WorkoutDetailsStore.details.duration ?? 0}
            />
          )}
        </Workout>

        <InfoContainer>
          {this.equipments?.length ? (
            <Section isEquipment>
              <TitleContainer>
                <TitleAdornment />
                <Title>Equipment</Title>
              </TitleContainer>
              <Equipments>
                {this.equipments.map(equipment => (
                  <EquipmentContainer key={equipment.id}>
                    <EquipmentImageContainer>
                      <EquipmentImage imageUrl={equipment.imageUrl} />
                    </EquipmentImageContainer>
                    <EquipmentText>{equipment.title}</EquipmentText>
                  </EquipmentContainer>
                ))}
              </Equipments>
            </Section>
          ) : null}
          <Section>
            <TitleContainer>
              <TitleAdornment />
              <Title>Instructions</Title>
            </TitleContainer>
            <TextWrapper>
              <Text>{this.details?.instruction}</Text>
            </TextWrapper>

            {this.movements?.length ? (
              <GridCollection
                items={WorkoutDetailsStore.details?.movements ?? []}
                onClick={onNavigateToMovement}
                isWorkout
              />
            ) : null}
          </Section>
        </InfoContainer>
      </Container>
    );
  }
}

export default withGlobalPopup(WorkoutDetails);
