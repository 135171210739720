import styled, { css } from "styled-components";
import styleConst from "constants/style";

interface ICircle {
  active?: boolean;
}

interface IIconContainer {
  right?: boolean;
  left?: boolean;
}

export const Circle = styled.div<ICircle>`
  width: 57px;
  height: 57px;

  padding: 16px 0;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  font-family: ${styleConst.fonts.roboto};
  text-align: center;
  overflow: hidden;

  ${({ active }) =>
    active
      ? css`
          color: ${styleConst.colors.white};
          background: radial-gradient(
              83.3% 179.17% at 56.29% -31.25%,
              ${styleConst.colors.primaryMedium} 0%,
              ${styleConst.colors.primary} 100%
            ),
            linear-gradient(
              233.38deg,
              ${styleConst.colors.primaryAdditional1} -9.83%,
              ${styleConst.colors.primaryAdditional2} 82.48%
            ),
            ${styleConst.colors.primary};
        `
      : css`
          color: ${styleConst.colors.black25};
          background-color: ${styleConst.colors.black4};
        `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Day = styled.div`
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const DayContainer = styled.div``;

export const IconContainer = styled.button<IIconContainer>`
  cursor: pointer;
  display: flex;
  border: none;
  background: none;
  width: 24px;
  height: 24px;

  margin-top: 21px;

  ${({ right }) =>
    right &&
    css`
      margin-left: 21px;
    `}

  ${({ left }) =>
    left &&
    css`
      margin-right: 21px;
    `}
`;

export const Label = styled.p`
  margin-bottom: 3px;

  text-align: center;
  font-size: 14px;
  font-family: ${styleConst.fonts.roboto};
  line-height: 16.41px;
  font-weight: 600;
  color: ${styleConst.colors.dark};
`;

export const Title = styled.p`
  font-size: 26px;
  line-height: 48px;
  text-align: center;
  font-family: ${styleConst.fonts.montserrat};
  font-weight: 600;
  color: ${styleConst.colors.dark};
`;

export const Week = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 711.28px;

  padding: 16px 10px 21px;
`;
