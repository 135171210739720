const PASSWORD_REGEXP =
  /^(?=.*[0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;
const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_NUMBER_REGEXP = /^[+][0-9][(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]{9}$/;

export const ERROR = {
  EMPTY: "This field is required.",
  PASSWORD:
    "Password must be minimum 8 characters long and include at least 1 number or special character. Spaces are not allowed.",
  PASSWORD_CONFIRM: "Password and confirmation password do not match.",
  AGREE_TERMS_PRIVACY:
    "Please review our Terms & Conditions and Privacy Policy and accept them to continue.",
  PHONE_NUMBER: "Must be a phone number.",
  EMAIL: "A valid email address is required.",
  INVALID_VALUE: "Invalid value",
};

export const IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
];

type Validator = (value: any) => string | Promise<string>;

export const isEmpty = (value: string) => {
  return !value ? ERROR.EMPTY : "";
};

export const isPassword = (value: string) => {
  return !PASSWORD_REGEXP.test(value) ? ERROR.PASSWORD : "";
};

export const isConfirmEqualPassword = (password: string, confirm: string) => {
  return password !== confirm ? ERROR.PASSWORD_CONFIRM : "";
};

export const isAgreeTermsAndPrivacyChecked = (value: boolean) => {
  return !value ? ERROR.AGREE_TERMS_PRIVACY : "";
};

export const isPhoneNumber = (value: string) => {
  if (!value) {
    return value;
  }

  return !PHONE_NUMBER_REGEXP.test(String(value).trim())
    ? ERROR.PHONE_NUMBER
    : "";
};

export const isEmail = (value: string) => {
  if (!value) {
    return value;
  }

  return !EMAIL_REGEXP.test(String(value).toLowerCase()) ? ERROR.EMAIL : "";
};

export const composeValidators =
  (...validators: any) =>
  (...value: any) =>
    validators.reduce(
      (error: any, validator: any) => error || validator(...value),
      null,
    );

export const emptyValidator = (value: any) => {
  return Number.isFinite(value) ||
    Boolean(typeof value === "string" ? value.trim() : value)
    ? ""
    : ERROR.EMPTY;
};

export const emptyArrayValidator =
  (validator: Validator) =>
  (values: Array<any>): string => {
    return (Array.isArray(values) && values.length > 0) ||
      (!Array.isArray(values) && validator(values) === "")
      ? ""
      : ERROR.EMPTY;
  };

export const isPositiveNumber = (value: string = "") => {
  const parsedValue = Number.parseFloat(value);

  return !Number.isNaN(parsedValue) && parsedValue > 0
    ? ""
    : ERROR.INVALID_VALUE;
};

export const validateFile = (file: any) => {
  if (IMAGE_TYPES.indexOf(file.type) === -1) {
    return false;
  }
  return true;
};
