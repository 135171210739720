import React from "react";
import { SnapshotOrInstance } from "mobx-state-tree";

import Text from "./types/Text";
import Select from "./types/Select";
import Agree from "./types/Agree";
import Photo from "./types/Photo";
import Radio from "./types/Radio";

import { QUESTION_TYPE } from "constants/common";

import { Answer, Field } from "models/Questionnaire";

type FieldItem = SnapshotOrInstance<typeof Field>;
type AnswerItem = SnapshotOrInstance<typeof Answer>;

type Props = {
  field: FieldItem;
  onAnswer: (answer: AnswerItem) => void;
  parentFieldName?: string;
  store: any;
};

const QuestionType = (props: Props) => {
  switch (props.field.type) {
    case QUESTION_TYPE.TEXT:
      return <Text {...props} />;
    case QUESTION_TYPE.SELECT:
      return <Select {...props} />;
    case QUESTION_TYPE.NUMBER:
      return <Text {...props} number />;
    case QUESTION_TYPE.BOOLEAN_CHECKBOX:
      return <Agree {...props} />;
    case QUESTION_TYPE.PHOTO:
      return <Photo {...props} />;
    case QUESTION_TYPE.PASSWORD:
      return <Text {...props} password />;
    case QUESTION_TYPE.RADIO:
      return <Radio {...props} />;
    default:
      return null;
  }
};

export default QuestionType;
