import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Eye = ({ color = styleConst.colors.white }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2031 8C9.99399 8 8.20312 9.79086 8.20312 12C8.20312 14.2091 9.99399 16 12.2031 16C14.4123 16 16.2031 14.2091 16.2031 12C16.2031 9.79086 14.4123 8 12.2031 8ZM10.2031 12C10.2031 10.8954 11.0986 10 12.2031 10C13.3077 10 14.2031 10.8954 14.2031 12C14.2031 13.1046 13.3077 14 12.2031 14C11.0986 14 10.2031 13.1046 10.2031 12Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0344 11.442C22.0346 11.4422 22.0347 11.4425 21.2046 12C22.0347 12.5575 22.0346 12.5578 22.0344 12.558L22.0328 12.5605L22.0293 12.5656L22.0179 12.5823C22.0084 12.5962 21.9949 12.6157 21.9777 12.6404C21.9432 12.6897 21.8934 12.7599 21.8291 12.8478C21.7007 13.0236 21.5138 13.2712 21.2745 13.5668C20.7972 14.1563 20.1051 14.9444 19.246 15.7356C17.5636 17.2851 15.0751 19 12.2046 19C9.33408 19 6.84558 17.2851 5.16317 15.7356C4.3041 14.9444 3.61195 14.1563 3.13464 13.5668C2.89537 13.2712 2.70849 13.0236 2.58003 12.8478C2.51576 12.7599 2.466 12.6897 2.4315 12.6404C2.41425 12.6157 2.4008 12.5962 2.39126 12.5823L2.37988 12.5656L2.3764 12.5605L2.37522 12.5587C2.37503 12.5584 2.37441 12.5575 3.20458 12C2.37441 11.4425 2.37458 11.4422 2.37477 11.442L2.3764 11.4395L2.37988 11.4344L2.39126 11.4177C2.4008 11.4038 2.41425 11.3843 2.4315 11.3596C2.466 11.3103 2.51576 11.2401 2.58003 11.1522C2.70849 10.9764 2.89537 10.7288 3.13464 10.4332C3.61195 9.84372 4.3041 9.05563 5.16317 8.26443C6.84558 6.71494 9.33408 5 12.2046 5C15.0751 5 17.5636 6.71494 19.246 8.26443C20.1051 9.05563 20.7972 9.84372 21.2745 10.4332C21.5138 10.7288 21.7007 10.9764 21.8291 11.1522C21.8934 11.2401 21.9432 11.3103 21.9777 11.3596C21.9949 11.3843 22.0084 11.4038 22.0179 11.4177L22.0293 11.4344L22.0328 11.4395L22.0344 11.442ZM4.68904 12.3082C4.59896 12.197 4.51746 12.0937 4.44497 12C4.51746 11.9063 4.59896 11.803 4.68904 11.6918C5.12259 11.1563 5.74846 10.4444 6.51807 9.73557C8.09302 8.28506 10.1045 7 12.2046 7C14.3046 7 16.3161 8.28506 17.8911 9.73557C18.6607 10.4444 19.2866 11.1563 19.7201 11.6918C19.8102 11.803 19.8917 11.9063 19.9642 12C19.8917 12.0937 19.8102 12.197 19.7201 12.3082C19.2866 12.8437 18.6607 13.5556 17.8911 14.2644C16.3161 15.7149 14.3046 17 12.2046 17C10.1045 17 8.09302 15.7149 6.51807 14.2644C5.74846 13.5556 5.12259 12.8437 4.68904 12.3082Z"
      fill={color}
    />
    <path
      d="M21.2046 12L22.0344 11.442L22.4092 12L22.0344 12.558L21.2046 12Z"
      fill={color}
    />
    <path
      d="M2.37477 11.442L3.20458 12L2.37441 12.5575L2 12L2.37477 11.442Z"
      fill={color}
    />
  </svg>
);

export default I24Eye;
