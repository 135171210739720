import styled from "styled-components";

import styleConst from "constants/style";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  font-family: ${styleConst.fonts.montserrat};
  color: ${({ color }) => color};
`;

export const Subtitle = styled(Title)`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  font-family: ${styleConst.fonts.roboto};
`;

export const ButtonContainer = styled.div`
  width: 90px;
  height: 26px;

  font-size: 14px;
  font-family: ${styleConst.fonts.roboto};
  margin-top: 9px;
`;

export const MacroGoalsWrapper = styled.div`
  flex-direction: column;
  margin: 20px 0px;
`;
