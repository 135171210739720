import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";

import I24CrossFilled from "assets/icons/I24CrossFilled";

import styleConst from "constants/style";

import {
  ButtonContainer,
  Container,
  IconContainer,
  Input,
  SearchButton,
} from "./styles";

type Props = {
  initValue?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onApply?: (value: string) => void;
  onClear?: () => void;
  className?: any;
  disabled?: boolean;
  customIcon?: React.ReactNode;
  onCustomIconClick?: () => void;
  withoutClearIcon?: boolean;
  onlyNumber?: boolean;
};

@observer
class Search extends React.Component<Props> {
  @observable value: string = "";
  @observable appliedValue: string = "";
  @observable isSelected: boolean = false;

  inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  @computed
  get isEmpty() {
    return !this.value;
  }

  get input() {
    return this.inputRef && this.inputRef.current;
  }

  componentDidMount() {
    this.value = this.props.initValue ?? "";
    this.isSelected = !this.isEmpty;
  }

  onChange = (value: string) => {
    const { onChange } = this.props;

    this.value = value;
    onChange?.(this.value);
  };

  apply = () => {
    const { onApply } = this.props;

    this.isSelected = !this.isEmpty;
    this.appliedValue = this.value;
    onApply?.(this.value);
    this.blurInput();
  };

  clear = () => {
    const { onClear } = this.props;

    this.isSelected = false;
    this.value = "";
    this.appliedValue = "";
    onClear?.();
    this.blurInput();
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.apply();
    }
  };

  blurInput = () => {
    this.input && this.input.blur();
  };

  render() {
    const {
      placeholder,
      className,
      disabled,
      withoutClearIcon,
      customIcon,
      onApply,
      onCustomIconClick,
      onlyNumber,
    } = this.props;

    return (
      <Container className={className}>
        <Input
          value={this.value}
          onChangeText={this.onChange}
          placeholder={placeholder ?? "Search"}
          onKeyDown={this.onKeyDown}
          // ref={this.inputRef}
          disabled={disabled}
          withIcon={!!this.value}
          numbersOnly={onlyNumber}
          onlyPositiveNumbers={onlyNumber}
        />
        {!!this.value && !withoutClearIcon && (
          <IconContainer onClick={this.clear}>
            <I24CrossFilled color={styleConst.colors.black25} />
          </IconContainer>
        )}
        {customIcon && (
          <IconContainer onClick={onCustomIconClick}>
            {customIcon}
          </IconContainer>
        )}
        <ButtonContainer>
          <SearchButton text={"Search"} height={50} onClick={onApply} />
        </ButtonContainer>
      </Container>
    );
  }
}

export default Search;
