import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import MyRecipesStore from "stores/MyRecipesStore";

import { Placeholders } from "assets/images/placeholders";
import I24AddFilled from "assets/icons/I24AddFilled";

import List from "components/List";
import ListItemWrapper from "components/ListItemWrapper";
import Recipe from "components/Recipe";
import {
  MyRecipes as MyRecipesActions,
  MyFood as MyFoodActions,
} from "components/Actions";
import ButtonInline from "components/ButtonInline";
import Spinner from "components/Spinner";
import Placeholder from "components/Placeholder/Placeholder";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";
import MealSchedulePopUp from "components/MealSchedulePopup";
import ConfirmPopup from "components/ConfirmPopup";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import AddRecipeForm from "./AddRecipeForm";

import { NoInternetConnectionError } from "services/api";

import ROUTES from "navigation/routes";

import { MEAL_SCHEDULE_TYPE } from "constants/nutrition";
import styleConst from "constants/style";

import {
  Header,
  Title,
  TitleWrapper,
  ColumnTitle,
  ColumnTitleWrapper,
  LoaderContainer,
} from "./styles";

type Props = {} & RouteComponentProps & GlobalPopupProps;

const header = ["Carbs", "Fiber", "Fat", "Protein"];

@observer
class MyRecipes extends React.Component<Props> {
  @observable page: number = 1;
  @observable isNoInternetConnectionError: boolean = false;

  @computed get recipes() {
    return MyRecipesStore.items;
  }

  @computed get isLoading() {
    return MyRecipesStore.isLoading;
  }

  async componentDidMount() {
    try {
      await MyRecipesStore.fetch();
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }
  }

  onLoadMore = () => {
    if (MyRecipesStore.needMore) {
      this.page++;
      MyRecipesStore.fetch(this.page);
    }
  };

  onRecipeDetailsNavigate = (id: number) => () => {
    const { history } = this.props;

    if (id) {
      history.push(
        generatePath(ROUTES.NUTRITION_MY_ITEMS_MY_RECIPES_DETAILS, {
          recipeId: id,
        }),
      );
    }
  };

  openMealSchedule = (mode: string, id: number) => {
    const { openPopup } = this.props;

    openPopup({
      key: "MealSchedulePopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <MealSchedulePopUp
          mode={mode}
          recipe={{ id, isCustomRecipe: true }}
          onClose={close}
        />
      ),
    });
  };

  onToday = (id: number) => {
    this.openMealSchedule(MEAL_SCHEDULE_TYPE.TODAY, id);
  };

  onSelectDay = (id: number) => {
    this.openMealSchedule(MEAL_SCHEDULE_TYPE.SCHEDULE, id);
  };

  onRemove = async (id: number) => {
    const { openPopup } = this.props;

    openPopup({
      key: "ConfirmPopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <ConfirmPopup
          onClose={close}
          onAccept={async () => {
            await MyRecipesStore.removeRecipe(id);
            await MyRecipesStore.fetch();
            close();
          }}
          title={"Are you sure you want to remove selected recipe?"}
        />
      ),
    });
  };

  onAddMyRecipePress = () => {
    const { openPopup } = this.props;

    openPopup({
      key: "AddRecipeForm",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => <AddRecipeForm onSave={close} />,
    });
  };

  renderItem = (item: any) => {
    return (
      <ListItemWrapper
        key={item.id}
        onClick={this.onRecipeDetailsNavigate(item.id)}
        controls={
          item.isArchived ? (
            <MyFoodActions id={item.id} onRemove={this.onRemove} />
          ) : (
            <MyRecipesActions
              id={item.id}
              onToday={this.onToday}
              onSelectDay={this.onSelectDay}
              onRemove={this.onRemove}
            />
          )
        }
      >
        <Recipe
          withoutImage
          style={{
            height: 60,
            marginLeft: 20,
            marginTop: 10,
            marginBottom: 10,
          }}
          recipe={{ ...item, title: item.name }}
          onClick={this.onRecipeDetailsNavigate(item.id)}
        />
      </ListItemWrapper>
    );
  };

  render() {
    if (this.isNoInternetConnectionError) {
      return <NoInternetConnectionPlaceholder type={"element"} />;
    }

    if (this.isLoading) {
      return (
        <LoaderContainer>
          <Spinner color={styleConst.colors.primary} large />
        </LoaderContainer>
      );
    }

    return (
      <React.Fragment>
        <Header>
          <TitleWrapper>
            <Title>{"Recipes"}</Title>
            <ColumnTitleWrapper>
              {header.map(item => (
                <ColumnTitle key={item}>{item}</ColumnTitle>
              ))}
            </ColumnTitleWrapper>
          </TitleWrapper>

          <ButtonInline
            onPress={this.onAddMyRecipePress}
            text={"Add Recipe"}
            fontSize={14}
            fontWeight={600}
            iconLeft={<I24AddFilled color={styleConst.colors.primary} />}
          />
        </Header>

        {this.recipes.length ? (
          <List
            items={this.recipes}
            renderItem={this.renderItem}
            onLoadMore={this.onLoadMore}
            needMore={MyRecipesStore.needMore}
            style={{ overflow: "visible" }}
          />
        ) : (
          <Placeholder
            inline={false}
            imageUrl={Placeholders.Recipes}
            text="No recipes yet"
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(withGlobalPopup(MyRecipes));
