import React from "react";

import NoInternetConnection from "assets/icons/NoInternetConnection";

import { Container, InnerWrapper, Text, Title, SubmitButton } from "./styles";

type Props = {
  type?: "page" | "element" | "popup";
  onSubmit?: () => void;
};

const NoInternetConnectionPlaceholder: React.FC<Props> = ({
  type = "page",
  onSubmit,
}: Props) => {
  return (
    <Container type={type}>
      <InnerWrapper>
        {type === "popup" && <Title>Oops</Title>}
        <NoInternetConnection
          width={type === "popup" ? 123.53 : 312}
          height={type === "popup" ? 123.53 : 312}
        />
        {type !== "popup" && <Title>Oops</Title>}
        <Text>
          No Internet connection.
          <br /> Please check and try again.
        </Text>
      </InnerWrapper>

      {type === "popup" && onSubmit && (
        <SubmitButton height={50} width={200} text="OK" onClick={onSubmit} />
      )}
    </Container>
  );
};

export default NoInternetConnectionPlaceholder;
