import React from "react";
import { observer } from "mobx-react";
import { observable, computed } from "mobx";
import { SnapshotOrInstance } from "mobx-state-tree";
import {
  withRouter,
  RouteComponentProps,
  generatePath,
} from "react-router-dom";
import { endOfMonth, format, startOfMonth } from "date-fns";

import CalendarStore from "stores/CalendarStore";

import I32Union from "assets/icons/I32Union";
import { Placeholders } from "assets/images/placeholders";

import { Page } from "components/Layout";
import DefaultCalendar from "components/Calendar";
import Spinner from "components/Spinner";
import { List } from "components/Collection";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";
import Placeholder from "components/Placeholder";

import Summary from "./Summary";
import AddWorkoutForm from "./AddWorkoutForm";
import NavList from "./NavList";

import { NoInternetConnectionError } from "services/api";

import { getStreak, getMarkedDates } from "./utils";
import { SERVER_DATE_FORMAT } from "utils/date";

import ROUTES from "navigation/routes";

import { Workout } from "models/Workout";

import styleConst from "constants/style";
import {
  Container,
  Row,
  SubmitButton,
  Title,
  Column,
  CalendarContainer,
  LoaderContainer,
  SummaryContainer,
  SelectedDayTitle,
  PlaceholderWrapper,
} from "./styles";

type Props = {} & RouteComponentProps & GlobalPopupProps;

type Collection = {
  title: string;
  items: SnapshotOrInstance<typeof Workout>[];
};

@observer
class Calendar extends React.Component<Props> {
  @observable isLoading: boolean = false;
  @observable date: Date = new Date();
  @observable selectedDay: Date = new Date();
  @observable collections: Collection[] = [];
  @observable isNoInternetConnectionError: boolean = false;

  get placeholder() {
    return (
      <PlaceholderWrapper>
        <Placeholder
          imageUrl={Placeholders.Workout}
          text="No workouts added yet"
          inline={false}
        />
      </PlaceholderWrapper>
    );
  }

  @computed get streak(): number {
    return getStreak(this.completed);
  }

  @computed get scheduled() {
    return CalendarStore.mappedScheduled;
  }

  @computed get completed() {
    return CalendarStore.mappedCompleted;
  }

  @computed get selectedDayTitle() {
    return this.selectedDay ? format(this.selectedDay, "EEEE, LLLL d") : "";
  }

  async componentDidMount() {
    this.isLoading = true;
    await this.fetch();
    this.onDayPress(this.selectedDay);
    this.isLoading = false;
  }

  fetch = async () => {
    try {
      const startDate = format(startOfMonth(this.date), SERVER_DATE_FORMAT);
      const endDate = format(endOfMonth(this.date), SERVER_DATE_FORMAT);

      await CalendarStore.fetch({
        startDate,
        endDate,
      });
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }
  };

  onDayPress = (day: Date) => {
    this.selectedDay = day;
    this.collections = CalendarStore.getCollectionsByDay(this.selectedDay);
  };

  onChangeMonth = (date: Date) => {
    this.date = date;
    this.fetch();
  };

  onAddWorkoutPress = () => {
    const { openPopup } = this.props;

    openPopup({
      key: "AddWorkoutForm",
      exitButtonColor: styleConst.colors.black25,
      exitButtonZIndex: 10000,
      render: ({ close }) => (
        <AddWorkoutForm
          onSubmit={async date => {
            await this.fetch();
            this.onDayPress(date);
            close();
          }}
          onClose={close}
        />
      ),
    });
  };

  onWorkoutNavigate = (workout: any) => {
    const { history } = this.props;
    const { id: workoutId, date: scheduleDate } = workout;

    history.push(
      generatePath(ROUTES.CALENDAR_WORKOUT_SCHEDULE_DATE_DETAILS, {
        workoutId,
        scheduleDate,
      }),
    );
  };

  render() {
    return (
      <Page
        isNoInternetConnectionError={this.isNoInternetConnectionError}
        isLoading={this.isLoading}
        topNavigation={<NavList />}
      >
        <Container>
          <Row>
            <Title>Calendar</Title>
            <SubmitButton
              text={"Add Workout"}
              onClick={this.onAddWorkoutPress}
              loading={this.isLoading}
              fontSize={16}
              fontFamily={styleConst.fonts.roboto}
              fontWeight={700}
              iconLeft={<I32Union />}
            />
          </Row>
          <Row>
            <Column>
              <CalendarContainer>
                <DefaultCalendar
                  markedDates={getMarkedDates({
                    scheduled: this.scheduled,
                    completed: this.completed,
                  })}
                  value={this.selectedDay}
                  onClickDay={this.onDayPress}
                  headerFontSize={26}
                  onChangeMonth={this.onChangeMonth}
                />
                {CalendarStore.isLoading ? (
                  <LoaderContainer>
                    <Spinner color={styleConst.colors.primary} large />
                  </LoaderContainer>
                ) : null}
              </CalendarContainer>
              {!CalendarStore.isLoading && (
                <SummaryContainer>
                  <Summary
                    scheduled={this.scheduled.length}
                    completed={this.completed.length}
                    streak={this.streak}
                  />
                </SummaryContainer>
              )}
            </Column>
            <Column>
              <SelectedDayTitle>{this.selectedDayTitle}</SelectedDayTitle>
              {this.collections.length
                ? this.collections.map(({ title, items }) => (
                    <List
                      key={title}
                      title={title}
                      items={items}
                      onCardClick={this.onWorkoutNavigate}
                    />
                  ))
                : this.placeholder}
            </Column>
          </Row>
        </Container>
      </Page>
    );
  }
}

export default withRouter(withGlobalPopup(Calendar));
