import React from "react";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";
import { debounce } from "debounce";
import { generatePath } from "react-router-dom";

import I20Search from "assets/icons/I20Search";
import I24Filter from "assets/icons/I24Filter";
import { Placeholders } from "assets/images/placeholders";

import SearchStore from "stores/SearchStore";

import FormTitle from "components/Form/FormTitle";
import Placeholder from "components/Placeholder";
import Search from "components/Search";
import Spinner from "components/Spinner";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import FiltersForm from "./FiltersForm";
import ResultItem from "./ResultItem";

import { NoInternetConnectionError } from "services/api";

import {
  capitalize,
  formatDuration,
  generateAppSectionLink,
} from "utils/common";
import { getMainFields } from "utils/nutrients";

import ROUTES from "navigation/routes";
import styleConst from "constants/style";

import {
  Accordion,
  Container,
  Content,
  ItemContainer,
  Row,
  ScrollLoader,
  Results,
  SearchWrapper,
} from "./styles";

type Props = {};

@observer
class SearchForm extends React.Component<Props> {
  @observable filtersAreOpen: boolean = false;
  @observable isSearched: boolean = false;
  @observable isNoInternetConnectionError: boolean = false;

  @computed get canSearch() {
    return SearchStore.canSearch;
  }

  @computed get isLoading() {
    return SearchStore.isLoading || SearchStore.isSearching;
  }

  @computed get searchString() {
    return SearchStore.searchString;
  }

  @computed get workouts(): any[] {
    return SearchStore.results.workouts;
  }

  @computed get recipes(): any[] {
    return SearchStore.results.recipes;
  }

  @computed get resources(): any[] {
    return SearchStore.results.resources;
  }

  @computed get showResults() {
    return !this.isLoading && this.isSearched && SearchStore.isResults;
  }

  componentDidMount() {
    SearchStore.fetchCategoryList();
  }

  componentWillUnmount() {
    SearchStore.reset();
  }

  getRecipeMainNutrients = (recipe: any) => {
    const { macronutrients } = recipe;

    const mainNutrients = getMainFields(macronutrients);

    return Object.keys(mainNutrients)
      .map(key => `${mainNutrients[key]} ${capitalize(key)}`)
      .join("  ∙  ");
  };

  onSearchChange = (value: string) => {
    SearchStore.setSearchString(value);

    if (this.searchString.length > 2) {
      this.debouncedSearch();
    } else {
      this.isSearched = false;
    }
  };

  onSearchClear = () => {
    this.isSearched = false;
    SearchStore.resetResults();
  };

  onSearchApply = async () => {
    if (!SearchStore.canSearch) {
      return;
    }
    try {
      await SearchStore.search();
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
    }

    this.isSearched = true;
  };

  debouncedSearch = debounce(this.onSearchApply, 1500);

  openFilters = () => {
    this.filtersAreOpen = true;
  };

  closeFilters = () => {
    this.filtersAreOpen = false;
  };

  onFiltersApply = () => {
    this.closeFilters();
    this.onSearchApply();
  };

  onWorkoutNavigate = (workout: any) => () => {
    const { id: workoutId } = workout;

    if (workoutId) {
      const link = generateAppSectionLink(
        generatePath(ROUTES.STUDIO_SEARCH_WORKOUT_DETAILS, { workoutId }),
      );

      window.open(link);
    }
  };

  onRecipeNavigate = (recipe: any) => () => {
    const { id: recipeId } = recipe;

    if (recipeId) {
      const link = generateAppSectionLink(
        generatePath(ROUTES.NUTRITION_SEARCH_RECIPE_DETAILS, { recipeId }),
      );

      window.open(link);
    }
  };

  onResourceNavigate = (quickLink: any) => () => {
    const { id: quickLinkId } = quickLink;

    if (quickLinkId) {
      const link = generateAppSectionLink(
        generatePath(ROUTES.RESOURCES_SEARCH_RESOURCE_DETAILS, {
          quickLinkId,
        }),
      );

      window.open(link);
    }
  };

  renderPlaceholder = () =>
    this.isSearched ? (
      <Row>
        <Placeholder
          imageUrl={Placeholders.NotFound}
          text={"No matches found. Please\ntry another search"}
        />
      </Row>
    ) : (
      <Row>
        <Placeholder
          imageUrl={Placeholders.Search}
          text="Your search results will be displayed here"
        />
      </Row>
    );

  render() {
    return (
      <Container filtersForm={this.filtersAreOpen}>
        {!this.filtersAreOpen && (
          <>
            <FormTitle title={"Search"} icon={<I20Search />} />
            <Row>
              <SearchWrapper>
                <Search
                  initValue={this.searchString}
                  onChange={this.onSearchChange}
                  onApply={this.onSearchApply}
                  customIcon={
                    <I24Filter
                      color={
                        SearchStore.isFilters
                          ? styleConst.colors.primary
                          : styleConst.colors.black25
                      }
                    />
                  }
                  onCustomIconClick={this.openFilters}
                  withoutClearIcon
                />
              </SearchWrapper>
            </Row>

            {this.isNoInternetConnectionError ? (
              <Row>
                <NoInternetConnectionPlaceholder type={"popup"} />
              </Row>
            ) : this.showResults ? (
              <Results>
                {this.workouts.length ? (
                  <Accordion title={"Workouts"}>
                    <Content>
                      {this.workouts.map((item, index) => (
                        <ItemContainer
                          key={`${item.id}-${index}`}
                          onClick={this.onWorkoutNavigate(item)}
                        >
                          <ResultItem
                            title={item.title}
                            subtitle={formatDuration(item.duration)}
                            imageUrl={item.imageUrl}
                          />
                        </ItemContainer>
                      ))}
                    </Content>
                  </Accordion>
                ) : null}
                {this.recipes.length ? (
                  <Accordion title={"Recipes"}>
                    <Content>
                      {this.recipes.map((item, index) => (
                        <ItemContainer
                          key={`${item.id}-${index}`}
                          onClick={this.onRecipeNavigate(item)}
                        >
                          <ResultItem
                            title={item.title}
                            subtitle={this.getRecipeMainNutrients(item)}
                            imageUrl={item.imageUrl}
                          />
                        </ItemContainer>
                      ))}
                    </Content>
                  </Accordion>
                ) : null}
                {this.resources.length ? (
                  <Accordion title={"Resources"}>
                    <Content>
                      {this.resources.map((item, index) => (
                        <ItemContainer
                          key={`${item.id}-${index}`}
                          onClick={this.onResourceNavigate(item)}
                        >
                          <ResultItem
                            title={item.title}
                            subtitle={item.duration}
                            imageUrl={item.imageUrl}
                          />
                        </ItemContainer>
                      ))}
                    </Content>
                  </Accordion>
                ) : null}
              </Results>
            ) : !this.isLoading ? (
              this.renderPlaceholder()
            ) : (
              <Row>
                <ScrollLoader>
                  <Spinner large />
                </ScrollLoader>
              </Row>
            )}
          </>
        )}

        {this.filtersAreOpen && (
          <>
            <FiltersForm onApply={this.onFiltersApply} />
          </>
        )}
      </Container>
    );
  }
}

export default SearchForm;
