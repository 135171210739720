import styled from "styled-components";

import styleConst from "constants/style";

export const Controls = styled.div`
  display: flex;

  padding-right: 52px;

  visibility: hidden;
`;

export const Title = styled.p`
  font-family: ${styleConst.fonts.roboto};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: ${styleConst.colors.dark};
`;

export const SubTitle = styled.p`
  font-family: ${styleConst.fonts.roboto};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${styleConst.colors.black50};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 22px 0;

  width: 100%;

  &:not(:last-child) {
    border-bottom: 2px solid ${styleConst.colors.black4};
  }

  &:hover {
    background: linear-gradient(
      89.98deg,
      rgba(132, 207, 203, 0) 0%,
      rgba(132, 207, 203, 0.08) 12.5%,
      rgba(132, 207, 203, 0.08) 87.48%,
      rgba(132, 207, 203, 0) 99.97%
    );

    ${Controls} {
      visibility: visible;
    }

    ${Title} {
      color: ${styleConst.colors.primaryDark};
    }
  }
`;

export const Info = styled.div`
  padding-left: 52px;
`;
