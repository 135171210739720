import React from "react";
import { format } from "date-fns";

import I40Play from "assets/icons/I40Play";

import TrimmedText from "components/TrimmedText";
import { PermissionPreview } from "components/Preview";

import { formatDuration } from "utils/common";

import {
  Container,
  Content,
  Icon,
  Image,
  Info,
  Title,
  Subtitle,
  SubtitleWrapper,
  Dot,
} from "./styles";

type Props = {
  imageUrl: string;
  title: string;
  label?: string;
  duration?: number;
  isWorkout?: boolean;
  isPermissionPreview?: boolean;
  onClick?: (item?: any) => void;
  height?: number;
  width?: number;
  date?: string;
};

class Card extends React.Component<Props> {
  render() {
    const {
      duration,
      imageUrl,
      label,
      title,
      isWorkout,
      isPermissionPreview,
      onClick,
      height = 210,
      width,
      date,
    } = this.props;
    return (
      <Container height={height} width={width}>
        <PermissionPreview
          isPermissionPreview={isPermissionPreview}
          top={25}
          left={23}
        >
          <Content onClick={onClick} height={height} width={width}>
            <Image imageUrl={imageUrl} height={height} width={width} />
            {isWorkout && (
              <Icon>
                <I40Play />
              </Icon>
            )}
            <Info>
              <TrimmedText lines={1} text={label ?? title}>
                <Title>{label ?? title}</Title>
              </TrimmedText>
              <SubtitleWrapper>
                {date ? (
                  <>
                    <Subtitle>{format(new Date(date), "hh:mm a")}</Subtitle>
                    <Dot>•</Dot>
                  </>
                ) : null}
                {duration ? (
                  <Subtitle>{formatDuration(duration)}</Subtitle>
                ) : null}
              </SubtitleWrapper>
            </Info>
          </Content>
        </PermissionPreview>
      </Container>
    );
  }
}

export default Card;
