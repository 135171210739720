import React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import { generatePath, RouteComponentProps } from "react-router-dom";

import StudioStore from "stores/StudioStore";

import { Page } from "components/Layout";
import Cards from "components/Studio/Cards";

import NavList from "../NavList";

import ROUTES from "navigation/routes";

import { Background } from "./styles";

type Props = {} & RouteComponentProps<{ categoryId: string }>;

@observer
class WorkoutCategoryDetails extends React.Component<Props> {
  get categoryId() {
    const {
      match: {
        params: { categoryId },
      },
    } = this.props;

    return Number.parseInt(categoryId);
  }

  get url() {
    const {
      match: { url },
    } = this.props;

    return url;
  }

  @computed get details() {
    return StudioStore.details;
  }

  onNavigateToWorkout = (workoutGroup?: string) => (workout: any) => {
    const { history } = this.props;
    const { id: workoutId } = workout;

    const path = workoutGroup
      ? generatePath(ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUTS_WORKOUT_DETAILS, {
          categoryId: this.categoryId,
          workoutId,
          workoutGroup,
        })
      : generatePath(ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUT_DETAILS, {
          categoryId: this.categoryId,
          workoutId,
        });

    history.push(path);
  };

  onViewAllClick = (workoutGroup: string) => () => {
    const { history } = this.props;

    history.push(
      generatePath(ROUTES.STUDIO_CATEGORY_DETAILS_WORKOUTS, {
        categoryId: this.categoryId,
        workoutGroup,
      }),
    );
  };

  render() {
    const { history } = this.props;

    return (
      <Page
        title={this.details?.title}
        contentBackground={
          <>
            <Background />
            <Background leftDots />
          </>
        }
        topNavigation={<NavList />}
      >
        <Cards
          categoryId={this.categoryId}
          onNavigateToWorkout={this.onNavigateToWorkout}
          onViewAllClick={this.onViewAllClick}
          url={this.url}
          history={history}
        />
      </Page>
    );
  }
}

export default WorkoutCategoryDetails;
