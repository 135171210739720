import React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import { NavListItem } from "components/Layout";
import { NavLinkItemType } from "components/Layout/NavListItem/NavListItem";

import ROUTES from "navigation/routes";
import { PROFILE_TABS } from "constants/profile";

type Props = {} & RouteComponentProps<{
  workoutId?: string;
  movementId?: string;
}>;

@observer
class NavList extends React.Component<Props> {
  get pathname() {
    const {
      location: { pathname },
    } = this.props;

    return pathname;
  }

  get firstItem(): NavLinkItemType {
    return {
      title: "Profile",
      onClick: this.onClick(ROUTES.PROFILE),
    };
  }

  @computed get navListItems() {
    const firstItem = this.firstItem;
    let secondItem;
    switch (this.pathname) {
      case ROUTES.MACROGOALS:
        secondItem = {
          title: PROFILE_TABS.MACROGOALS,
          onClick: this.onClick(ROUTES.MACROGOALS),
        };
        break;
      case ROUTES.MEASUREMENTS:
        secondItem = {
          title: PROFILE_TABS.MEASUREMENTS,
          onClick: this.onClick(ROUTES.MEASUREMENTS),
        };
        break;

      case ROUTES.CONTACTS:
        secondItem = {
          title: PROFILE_TABS.CONTACTS,
          onClick: this.onClick(ROUTES.CONTACTS),
        };
        break;

      case ROUTES.CHANGE_PASSWORD:
        secondItem = {
          title: PROFILE_TABS.CHANGE_PASSWORD,
          onClick: this.onClick(ROUTES.CHANGE_PASSWORD),
        };
        break;
    }

    if (secondItem) {
      return [firstItem, secondItem].filter(Boolean);
    }

    return [];
  }

  onClick = (route: string, routeParams?: any) => () => {
    const { history } = this.props;

    history.push(generatePath(route, routeParams));
  };

  render() {
    return (
      <>
        {this.navListItems.map((item: any, index: number) => (
          <NavListItem
            key={`${index}-${item.title}`}
            title={item.title}
            onClick={item.onClick}
            isLastItem={index === this.navListItems.length - 1}
          />
        ))}
      </>
    );
  }
}

export default withRouter(NavList);
