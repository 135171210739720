import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { Instance } from "mobx-state-tree";

import StudioStore from "stores/StudioStore";
import StudioContentStore from "stores/StudioContentStore";

import { Placeholders } from "assets/images/placeholders";

import GridCollection from "components/GridCollection";
import Placeholder from "components/Placeholder";
import Spinner from "components/Spinner";
import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import { NoInternetConnectionError, NotFoundError } from "services/api";

import { redirectToRootRoute } from "utils/route";

import { WEEK } from "constants/common";
import { CONTENT_STORE_TYPE } from "constants/studio";

import {
  Wrapper,
  PlaceholderWrapper,
  LoaderContainer,
  NoInternetConnectionErrorContainer,
} from "./styles";

type Props = {
  categoryId: number | null;
  workoutGroup: string | null;
  onNavigateToWorkout: (workoutGroup?: string) => (workout: any) => void;
  onNavigateToStudio: () => void;
  isFullAccessOnly?: boolean;
  fromPopup?: boolean;
  onClose?: () => void;
  url?: string;
  history?: any;
};

@observer
class Workouts extends React.Component<Props> {
  @observable store: Instance<typeof StudioContentStore> | null = null;
  @observable isNoInternetConnectionError: boolean = false;

  get placeholder() {
    return (
      <PlaceholderWrapper>
        <Placeholder
          imageUrl={Placeholders.Workout}
          text="Favorite workouts will be displayed here"
          inline={false}
        />
      </PlaceholderWrapper>
    );
  }

  get isFavorites() {
    const { categoryId } = this.props;
    return !categoryId;
  }

  get isWeekCategory() {
    const { workoutGroup } = this.props;
    return workoutGroup && Object.values(WEEK).includes(workoutGroup);
  }

  get dayOfWeek() {
    const { workoutGroup } = this.props;
    return Object.keys(WEEK).find(key => WEEK[key] === workoutGroup);
  }

  get workoutGroupIsNewOrAll() {
    const { workoutGroup } = this.props;
    return (
      workoutGroup &&
      [
        CONTENT_STORE_TYPE.STANDARD_NEWEST,
        CONTENT_STORE_TYPE.STANDARD_ALL,
      ].includes(workoutGroup)
    );
  }

  @computed get workouts() {
    return this.store?.items ?? [];
  }

  async componentDidMount() {
    const {
      categoryId,
      workoutGroup,
      onNavigateToStudio,
      isFullAccessOnly = false,
      history,
      url,
    } = this.props;

    if (categoryId) {
      try {
        await StudioStore.fetchDetails(categoryId, isFullAccessOnly);
      } catch (error) {
        if (error instanceof NoInternetConnectionError) {
          this.isNoInternetConnectionError = true;
        }
        if (error instanceof NotFoundError && url && history) {
          redirectToRootRoute(history, url);
        }
      }
    }

    if (this.isFavorites) {
      this.store = StudioContentStore.create({
        type: CONTENT_STORE_TYPE.STANDARD_FAVORITES,
      });
    } else if (this.isWeekCategory && this.dayOfWeek !== undefined) {
      this.store = StudioContentStore.create({
        type: CONTENT_STORE_TYPE.WEEK,
        dayOfWeek: Number.parseInt(this.dayOfWeek) + 1,
        categoryId: categoryId || undefined,
      });
    } else {
      if (!this.workoutGroupIsNewOrAll) {
        onNavigateToStudio();
        return;
      }

      this.store = StudioContentStore.create({
        type: workoutGroup || undefined,
        categoryId: categoryId || undefined,
      });
    }
  }

  onLoadMore = async () => {
    if (!this.store) {
      return;
    }
    const { isFullAccessOnly = false } = this.props;

    if (this.store.needMore && this.store.context) {
      this.store.setPage(this.store.context.page + 1);
      await this.store.fetch(this.store.context.page, isFullAccessOnly);
    }
  };

  render() {
    const {
      onNavigateToWorkout,
      fromPopup = false,
      onClose,
      url,
      history,
    } = this.props;

    if (
      this.store?.isNoInternetConnectionError ||
      this.isNoInternetConnectionError
    ) {
      return (
        <NoInternetConnectionErrorContainer>
          <NoInternetConnectionPlaceholder
            type={fromPopup ? "popup" : "element"}
            onSubmit={onClose}
          />
        </NoInternetConnectionErrorContainer>
      );
    }

    if (this.store?.isNotFoundError && url && history) {
      redirectToRootRoute(history, url);
    }

    if (this.store?.isLoading) {
      return (
        <LoaderContainer>
          <Spinner large />
        </LoaderContainer>
      );
    }

    return (
      <Wrapper>
        {this.isFavorites && !this.workouts.length ? (
          this.placeholder
        ) : (
          <GridCollection
            items={this.workouts}
            onClick={onNavigateToWorkout()}
            needMore={this.store?.needMore}
            onLoadMore={this.onLoadMore}
            isWorkout
            withInfinityScroll
          />
        )}
      </Wrapper>
    );
  }
}

export default Workouts;
