import React from "react";

import { Container, SelectedIndicator, Text } from "./styles";

type Props = {
  title: string;
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
};

class NavMenuItem extends React.Component<Props> {
  render() {
    const { title, selected, onClick } = this.props;
    return (
      <Container onClick={onClick}>
        <Text isSelected={selected}>{title}</Text>
        {selected && <SelectedIndicator />}
      </Container>
    );
  }
}

export default NavMenuItem;
