import styled from "styled-components";

export const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ControlGroup = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-right: 40px;
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 24px;
  }
`;
