import styled, { css } from "styled-components";

import backgroundImageSmall from "assets/images/svg/dots_background_small.svg";
import backgroundImage from "assets/images/svg/dots_background.svg";

import Button from "components/Button";

import styleConst from "constants/style";
import { MEDIA_QUERIES } from "constants/layout";

interface IBackground {
  leftDots?: boolean;
}

interface ISection {
  width?: number;
}

export const FoodLogButton = styled(Button)`
  width: 216px;

  background: radial-gradient(
      83.3% 179.17% at 56.29% -31.25%,
      ${styleConst.colors.primaryMedium} 0%,
      ${styleConst.colors.primary} 100%
    ),
    ${styleConst.colors.primary};
  box-shadow: 0px 20px 40px -10px ${styleConst.colors.shadow};
  border-radius: 10px;
  border: none;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-bottom: 100px;
`;

export const Section = styled.div<ISection>`
  width: ${({ width = 300 }) => width}px;
`;

export const TitleAdornment = styled.div`
  margin-right: 14px;

  width: 4px;
  height: 28px;
  background-color: ${styleConst.colors.secondary};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 0 11px 20px;

  ${MEDIA_QUERIES.DESKTOP} {
    margin: 0 0 20px;
  }
`;

export const Title = styled.p`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;
  color: ${styleConst.colors.text};
  line-height: 48px;
`;

export const Text = styled.p`
  padding-top: 20px;

  font-size: 14px;
  line-height: 18px;

  letter-spacing: 0.01em;
  text-transform: capitalize;

  white-space: pre-line;

  color: ${styleConst.colors.black50};
`;

export const Background = styled.div<IBackground>`
  position: absolute;
  top: 164px;

  z-index: -1;

  background-position: 60% 50%;

  height: 388px;
  width: 100%;

  overflow: hidden;
  background-repeat: no-repeat;

  background-image: url(${backgroundImageSmall});

  ${MEDIA_QUERIES.DESKTOP} {
    background-image: url(${backgroundImage});
  }

  ${({ leftDots }) =>
    leftDots &&
    css`
      transform: rotate(180deg);

      top: 967px;
    `}
`;
