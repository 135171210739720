import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";

import FoodLogStore from "stores/FoodLogStore";

import Button from "components/Button";
import Input from "components/Input";
import { SnackbarTypeValues } from "components/Snackbar";

import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";

import {
  ButtonWrapper,
  Container,
  Content,
  Text,
  Controls,
  errorTextStyles,
  ItemWrapper,
} from "./styles";

type Props = {
  activeDay: string;
  numberOfServingsValue: number;
  onClose: () => void;
  params: any;
} & SnackbarContextProps;

@observer
class ServingsPopup extends React.Component<Props> {
  @observable numberOfServings: number = this.props.numberOfServingsValue || 1;

  @observable error = {
    numberOfServings: "",
  };

  onServingsSubmit = async () => {
    const { activeDay, onClose, params, setMessage } = this.props;
    try {
      const options = { ...params, amount: this.numberOfServings };

      await FoodLogStore.changeAmount(activeDay, options);
      await FoodLogStore.fetch(activeDay);
      onClose();
    } catch (error: any) {
      console.log("error :>> ", error);
      setMessage(error.message, SnackbarTypeValues.ALERT);
    }
  };

  changeNumberOfServings = (value: number) => {
    this.numberOfServings = value;
    this.error.numberOfServings = "";
  };

  render() {
    return (
      <Container>
        <Content>
          <Text>Servings</Text>
          <ItemWrapper>
            <Input
              label={"Servings"}
              value={this.numberOfServings}
              onChangeText={this.changeNumberOfServings}
              type="number"
              error={this.error.numberOfServings}
              errorTextStyles={errorTextStyles}
              decimalNumbersOnly
              onlyPositiveDecimalNumbers
            />
          </ItemWrapper>
          <Controls>
            <ButtonWrapper>
              <Button
                disabled={!Number(this.numberOfServings)}
                text={"Save"}
                onClick={this.onServingsSubmit}
                height={50}
              />
            </ButtonWrapper>
          </Controls>
        </Content>
      </Container>
    );
  }
}

export default withGlobalSnackbar(ServingsPopup);
