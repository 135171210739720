import styled, { css } from "styled-components";
import DefaultSelect from "react-select";

import styleConst from "constants/style";

interface ILabel {
  isFloating?: boolean;
  hasError?: boolean;
  hasValue?: boolean;
  disabled?: boolean;
}

export const defaultSelectstyles = {
  control: (provided: any, props: any) => ({
    ...provided,
    height: "100%",
    borderWidth: props.isFocused ? "2px" : "2px",
    borderRadius: "10px",
    borderColor: props.selectProps.error
      ? styleConst.colors.red
      : props.isFocused
      ? styleConst.colors.primary
      : styleConst.colors.black10,
    backgroundColor: styleConst.colors.white,
    boxShadow: 0,
    fontSize: "14px",
    maxHeight: "55px",
    opacity: props.isDisabled && 0.6,
    "&:hover": {
      borderColor: props.selectProps.error
        ? styleConst.colors.red
        : props.isFocused
        ? styleConst.colors.primary
        : styleConst.colors.black10,
    },
  }),
  placeholder: (provided: any, props: any) => ({
    ...provided,
    color: styleConst.colors.black50,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    display: props.selectProps.name
      ? props.isFocused
        ? "block"
        : "none"
      : "block",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    alignItems: "flex-start",
    padding: "8px 0",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: "inherit",
    padding: "8px",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
  }),
  input: (provided: any) => ({
    ...provided,
    fontFamily: styleConst.fonts.roboto,
    fontSize: "14px",
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 5 }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: 1,
    borderRadius: "0 0 4px 4px",
    border: "none",
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingTop: 10,
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: styleConst.colors.black10,
    color: styleConst.colors.dark,
  }),
  multiValueLabel: (provided: any, props: any) => {
    const hideRemoveIconStyles = props.data?.isFixed
      ? { paddingRight: "6px" }
      : {};

    return { ...provided, ...hideRemoveIconStyles };
  },
  multiValueRemove: (provided: any, props: any) => {
    const hideRemoveIconStyles = props.data?.isFixed ? { display: "none" } : {};

    return { ...provided, ...hideRemoveIconStyles };
  },
  option: (provided: any, props: any) => {
    const { isFocused, isSelected, isDisabled } = props;
    const backgroundColor =
      (isFocused && isSelected) || isSelected
        ? styleConst.colors.primaryDark
        : isFocused
        ? styleConst.colors.black4
        : styleConst.colors.white;
    const color =
      (isFocused && isSelected) || isSelected
        ? styleConst.colors.white
        : isFocused
        ? styleConst.colors.primaryDark
        : isDisabled
        ? styleConst.colors.black50
        : styleConst.colors.dark;
    return {
      ...provided,
      padding: "8px 16px",
      backgroundColor,
      color,
      fontSize: "14px",
      "&:active": { backgroundColor: styleConst.colors.primary20 },
    };
  },
};

const selectStyles = css`
  width: 100%;

  & input::placeholder {
    color: ${styleConst.colors.black50};
  }
`;

export const pageSelectorStyles = {
  control: (provided: any, props: any) => ({
    ...provided,
    height: "100%",
    borderWidth: props.isFocused ? "0px" : "0px",
    borderRadius: "10px",
    borderColor: props.selectProps.error
      ? styleConst.colors.red
      : props.isFocused
      ? styleConst.colors.primary
      : styleConst.colors.black10,
    backgroundColor: styleConst.colors.dark5,
    boxShadow: 0,
    fontSize: "18px",
    maxHeight: "48px",
    "&:hover": {
      borderColor: props.selectProps.error
        ? styleConst.colors.red
        : props.isFocused
        ? styleConst.colors.primary
        : styleConst.colors.black10,
    },
  }),
  placeholder: (provided: any, props: any) => ({
    ...provided,
    color: styleConst.colors.black50,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    display: props.selectProps.name
      ? props.isFocused
        ? "block"
        : "none"
      : "block",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    alignItems: "flex-start",
    padding: "3px 10px 3px 0",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: "48px",
    padding: "12px 22px",
    fontFamily: styleConst.fonts.montserrat,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: styleConst.fonts.montserrat,
    fontWeight: 600,
    color: styleConst.colors.primary,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
  }),
  input: (provided: any) => ({
    ...provided,
    fontFamily: styleConst.fonts.montserrat,
    fontSize: "18px",
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 5 }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: 1,
    borderRadius: "10px",
    border: "none",
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingTop: 10,
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: styleConst.colors.black10,
    color: styleConst.colors.dark,
  }),
  multiValueLabel: (provided: any, props: any) => {
    const hideRemoveIconStyles = props.data?.isFixed
      ? { paddingRight: "6px" }
      : {};

    return { ...provided, ...hideRemoveIconStyles };
  },
  multiValueRemove: (provided: any, props: any) => {
    const hideRemoveIconStyles = props.data?.isFixed ? { display: "none" } : {};

    return { ...provided, ...hideRemoveIconStyles };
  },
  option: (provided: any, props: any) => {
    const { isFocused, isSelected, isDisabled } = props;
    const backgroundColor =
      (isFocused && isSelected) || isSelected
        ? styleConst.colors.primaryDark
        : isFocused
        ? styleConst.colors.black4
        : styleConst.colors.white;
    const color =
      (isFocused && isSelected) || isSelected
        ? styleConst.colors.white
        : isFocused
        ? styleConst.colors.primaryDark
        : isDisabled
        ? styleConst.colors.black50
        : styleConst.colors.dark;
    return {
      ...provided,
      padding: "8px 16px",
      backgroundColor,
      color,
      fontSize: "16px",
      fontFamily: styleConst.fonts.montserrat,
      fontWeight: 500,
      "&:active": { backgroundColor: styleConst.colors.primary20 },
    };
  },
};

export const Select = styled(DefaultSelect)`
  ${selectStyles};
`;

export const Container = styled.div`
  width: 100%;

  position: relative;
`;

export const ErrorText = styled.p`
  margin-top: 6px;

  font-size: 12px;
  text-align: start;
  color: ${styleConst.colors.red};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span<ILabel>`
  top: 15px;
  left: 15px;
  pointer-events: none;
  position: absolute;
  z-index: 1;

  font-size: 14px;
  line-height: 24px;
  color: ${styleConst.colors.black50};

  transition: 0.2s ease all;

  ${({ isFloating }) =>
    isFloating &&
    css`
      top: -13px;
      z-index: 1;
      padding: 0 5px;

      font-size: 12px;

      color: ${styleConst.colors.primary};
      background-color: ${styleConst.colors.white};
    `}

  ${({ hasValue, hasError }) =>
    hasError
      ? css`
          color: ${styleConst.colors.red};
        `
      : hasValue
      ? css`
          color: ${styleConst.colors.black50};
        `
      : css``}

      ${({ disabled }) =>
    disabled &&
    css`
      color: ${styleConst.colors.black30};
    `}
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  width: 100%;
`;
