import styled from "styled-components";

import styleConst from "constants/style";

export const MenuItemIcon = styled.div`
  display: flex;
  margin: 0 7px 0 0;

  padding: 10px 0;
`;

export const Text = styled.p`
  font-family: ${styleConst.fonts.roboto};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;

  color: ${styleConst.colors.black};
`;

export const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 45px;

  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${styleConst.colors.black10};
  }

  &:hover {
    ${MenuItemIcon} {
      path,
      use {
        fill: ${styleConst.colors.primary};
      }
    }

    ${Text} {
      color: ${styleConst.colors.primary};
    }
  }

  padding: 0 15px;
`;
