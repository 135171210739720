import * as React from "react";

import style from "constants/style";

type Props = {
  color?: string;
};

const I20Calendar = ({ color = style.colors.black }: Props) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 0.833374C6.29356 0.833374 6.66666 1.20647 6.66666 1.66671L6.66666 3.33337H13.3333V1.66671C13.3333 1.20647 13.7064 0.833374 14.1667 0.833374C14.6269 0.833374 15 1.20647 15 1.66671V3.33683C15.4149 3.34273 15.7858 3.35871 16.1065 3.40182C16.6527 3.47527 17.1764 3.64089 17.6011 4.06561C18.0258 4.49033 18.1914 5.01395 18.2649 5.56021C18.3334 6.06994 18.3334 6.70645 18.3333 7.4452V13.3882C18.3334 14.127 18.3334 14.7635 18.2649 15.2732C18.1914 15.8195 18.0258 16.3431 17.6011 16.7678C17.1764 17.1925 16.6527 17.3581 16.1065 17.4316C15.5968 17.5001 14.9602 17.5001 14.2215 17.5H5.77849C5.03974 17.5001 4.40322 17.5001 3.8935 17.4316C3.34724 17.3581 2.82361 17.1925 2.39889 16.7678C1.97418 16.3431 1.80855 15.8195 1.73511 15.2732C1.66658 14.7635 1.66662 14.127 1.66666 13.3882V7.44518C1.66662 6.70644 1.66658 6.06993 1.73511 5.56021C1.80855 5.01395 1.97418 4.49033 2.39889 4.06561C2.82361 3.64089 3.34724 3.47527 3.8935 3.40182C4.21418 3.35871 4.58505 3.34273 4.99999 3.33683L4.99999 1.66671C4.99999 1.20647 5.37309 0.833374 5.83333 0.833374ZM3.33333 7.50004V13.3334C3.33333 14.1426 3.3351 14.6657 3.38692 15.0511C3.43551 15.4126 3.51397 15.5259 3.5774 15.5893C3.64084 15.6527 3.75413 15.7312 4.11558 15.7798C4.501 15.8316 5.02409 15.8334 5.83333 15.8334H14.1667C14.9759 15.8334 15.499 15.8316 15.8844 15.7798C16.2459 15.7312 16.3591 15.6527 16.4226 15.5893C16.486 15.5259 16.5645 15.4126 16.6131 15.0511C16.6649 14.6657 16.6667 14.1426 16.6667 13.3334V7.50004H3.33333Z"
        fill={color}
        fillOpacity="0.25"
      />
    </svg>
  );
};

export default I20Calendar;
