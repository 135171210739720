import React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import WorkoutDetailsStore from "stores/WorkoutDetailsStore";

import { NavListItem } from "components/Layout";

import { capitalize } from "utils/common";

import ROUTES from "navigation/routes";

type Props = { workoutLabel?: string } & RouteComponentProps<{
  workoutId?: string;
  movementId?: string;
}>;

@observer
class NavList extends React.Component<Props> {
  get pathname() {
    const {
      location: { pathname },
    } = this.props;

    return pathname;
  }

  get workoutId() {
    const {
      match: {
        params: { workoutId },
      },
    } = this.props;

    return workoutId ? Number.parseInt(workoutId) : undefined;
  }

  get movementId() {
    const {
      match: {
        params: { movementId },
      },
    } = this.props;

    return movementId ? Number.parseInt(movementId) : undefined;
  }

  @computed get workoutTitle() {
    const title =
      WorkoutDetailsStore.details?.label || WorkoutDetailsStore.details?.title;
      
    return title ? capitalize(title) : undefined;
  }

  @computed get movementTitle() {
    return this.movementId
      ? capitalize(
          WorkoutDetailsStore.getMovementDetails(this.movementId)?.title,
        )
      : undefined;
  }

  @computed get navListItems() {
    if (this.workoutId) {
      const firstItem = this.workoutId
        ? {
            title: this.workoutTitle,
            onClick: this.onClick(ROUTES.STUDIO_SEARCH_WORKOUT_DETAILS, {
              workoutId: this.workoutId,
            }),
          }
        : undefined;
      const secondItem = this.movementId
        ? {
            title: this.movementTitle,
            onClick: this.onClick(
              ROUTES.STUDIO_SEARCH_WORKOUT_DETAILS_MOVEMENT_DETAILS,
              {
                workoutId: this.workoutId,
                movementId: this.movementId,
              },
            ),
          }
        : undefined;

      return [firstItem, secondItem].filter(Boolean);
    }

    return [];
  }

  onClick = (route: string, routeParams?: any) => () => {
    const { history } = this.props;

    history.push(generatePath(route, routeParams));
  };

  render() {
    return (
      <>
        {this.navListItems.map((item: any, index: number) => (
          <NavListItem
            key={`${index}-${item.title}`}
            title={item.title}
            onClick={item.onClick}
            isLastItem={index === this.navListItems.length - 1}
          />
        ))}
      </>
    );
  }
}

export default withRouter(NavList);
