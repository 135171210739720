import styled, { css } from "styled-components";

import backgroundImageSmall from "assets/images/svg/dots_background_small.svg";
import backgroundImage from "assets/images/svg/dots_background.svg";

import DefaultButton from "components/Button";

import { MEDIA_QUERIES, PAGE_CONTENT_WIDTH } from "constants/layout";
import styleConst from "constants/style";

interface IBackground {
  leftDots?: boolean;
}

interface IImage {
  imageUrl?: string;
  withoutNavList?: boolean;
}

export const Background = styled.div<IBackground>`
  position: absolute;
  top: 151px;
  z-index: -1;

  height: 388px;
  width: 100%;

  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  background-image: url(${backgroundImageSmall});

  ${MEDIA_QUERIES.DESKTOP} {
    background-image: url(${backgroundImage});
  }

  ${({ leftDots }) =>
    leftDots &&
    css`
      top: 439px;

      transform: rotate(180deg);
    `}
`;

export const Button = styled(DefaultButton)`
  width: 200px;

  padding: 1px 42px 1px 30px;

  background: radial-gradient(
      83.3% 179.17% at 56.29% -31.25%,
      ${styleConst.colors.primaryMedium} 0%,
      ${styleConst.colors.primary} 100%
    ),
    ${styleConst.colors.primary};
  box-shadow: 0px 20px 40px -10px ${styleConst.colors.shadow};
  border-radius: 10px;
  border: none;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 100px;
  padding: 0 11px;

  ${MEDIA_QUERIES.DESKTOP} {
    padding: 0px;
  }
`;

export const Image = styled.div<IImage>`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto 40px;

  height: 500px;
  width: ${PAGE_CONTENT_WIDTH.SMALL_DESKTOP};

  border-radius: 10px;
  background-color: ${styleConst.colors.black4};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `}

  ${({ withoutNavList }) =>
    withoutNavList &&
    css`
      margin-top: 58px;
    `}

  ${MEDIA_QUERIES.DESKTOP} {
    height: 620px;
    width: ${PAGE_CONTENT_WIDTH.DESKTOP};
  }
`;

export const ViewContainer = styled.div`
  && .ql-editor {
    padding: 0;
  }

  && .ql-editor blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }

  && .ql-tooltip {
    display: none;
  }
`;
