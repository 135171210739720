import React from "react";
import { observer } from "mobx-react";

import I24QuickAdd from "assets/icons/I24QuickAdd";
import I32Plus from "assets/icons/I32Plus";

import FormTitle from "components/Form/FormTitle";
import FormControl, {
  DecimalNumberFieldControl,
  SubmitButtonControl,
  TextFieldControl,
} from "components/Form";

import { AddFoodSubmitPayloadType, ADD_RECIPE_TYPE } from "../AddFoodControl";

import {
  Container,
  Column,
  Row,
  SubmitButtonWrapper,
  SubmitButton,
} from "./styles";

type Props = {
  onSubmit?: (payload: AddFoodSubmitPayloadType) => void;
  initialValues?: any;
  submitIsLoading?: boolean;
};

@observer
class QuickAddForm extends React.Component<Props> {
  get isReadOnlyMode() {
    const { initialValues } = this.props;

    return !!initialValues;
  }

  renderSubmitButton = ({ onClick }: { onClick: () => void }) => (
    <SubmitButtonWrapper>
      <SubmitButton
        iconLeft={<I32Plus />}
        text="Add to Food Log"
        onClick={onClick}
        loading={this.props.submitIsLoading}
        disabled={this.isReadOnlyMode}
      />
    </SubmitButtonWrapper>
  );

  onSubmit = (values: any) => {
    const { onSubmit } = this.props;

    onSubmit?.({ values, addRecipeType: ADD_RECIPE_TYPE.QUICK_ADD });
  };

  render() {
    const { initialValues } = this.props;

    return (
      <Container>
        <FormControl onSubmit={this.onSubmit} initialValues={initialValues}>
          <FormTitle title={"Quick Add"} icon={<I24QuickAdd />} />

          <Row>
            <Column>
              <TextFieldControl
                name="title"
                label="Title"
                placeholder="Specify your food name"
                disabled={this.isReadOnlyMode}
                required
                withoutErrorText
              />
            </Column>
            <Column>
              <DecimalNumberFieldControl
                name="calories"
                label="Calories"
                placeholder="Specify calorie amount"
                disabled={this.isReadOnlyMode}
                onlyPositiveNumbers
                withoutErrorText
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <DecimalNumberFieldControl
                name="carbs"
                label="Carbs (g)"
                placeholder="Specify carbs amount"
                disabled={this.isReadOnlyMode}
                onlyPositiveNumbers
                required
                withoutErrorText
              />
            </Column>
            <Column>
              <DecimalNumberFieldControl
                name="fiber"
                label="Fiber (g)"
                placeholder="Specify fiber amount"
                disabled={this.isReadOnlyMode}
                onlyPositiveNumbers
                required
                withoutErrorText
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <DecimalNumberFieldControl
                name="fat"
                label="Fat (g)"
                placeholder="Specify fat amount"
                disabled={this.isReadOnlyMode}
                onlyPositiveNumbers
                required
                withoutErrorText
              />
            </Column>
            <Column>
              <DecimalNumberFieldControl
                name="protein"
                label="Protein (g)"
                placeholder="Specify protein amount"
                disabled={this.isReadOnlyMode}
                onlyPositiveNumbers
                required
                withoutErrorText
              />
            </Column>
          </Row>

          <SubmitButtonControl render={this.renderSubmitButton} />
        </FormControl>
      </Container>
    );
  }
}

export default QuickAddForm;
