import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;

  width: 100%;

  margin-bottom: 84px;
`;

export const PlaceholderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  width: 100%;
  height: 100%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 231px;
  width: 100%;
`;

export const NoInternetConnectionErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
`;
