import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I40Loader = ({ color = styleConst.colors.primary }: Props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 3.63636C10.9626 3.63636 3.63636 10.9626 3.63636 20C3.63636 29.0374 10.9626 36.3636 20 36.3636C29.0374 36.3636 36.3636 29.0374 36.3636 20C36.3636 18.9958 37.1777 18.1818 38.1818 18.1818C39.186 18.1818 40 18.9958 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C21.0042 0 21.8182 0.814028 21.8182 1.81818C21.8182 2.82234 21.0042 3.63636 20 3.63636Z"
      fill={color}
    />
  </svg>
);

export default I40Loader;
