import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { RouteComponentProps, withRouter } from "react-router-dom";

import RecipeDetailsStore from "stores/RecipeDetailsStore";

import I32Add from "assets/icons/I32Add";

import { Page } from "components/Layout";
import InlineListItem from "components/InlineListItem";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";
import MealSchedulePopUp from "components/MealSchedulePopup";
import FoodLogControl from "components/FoodLogControl";
import Details from "./components/Details";

import TodayNavList from "pages/Today/NavList";
import RecipeLibraryNavList from "pages/Nutrition/RecipeLibraryNavList";

import { NoInternetConnectionError, NotFoundError } from "services/api";

import {
  getNutrientsList,
  getNutrientLabel,
  getNutrientValue,
} from "utils/nutrients";
import { redirectToRootRoute } from "utils/route";

import ROUTES from "navigation/routes";

import { MEAL_SCHEDULE_TYPE } from "constants/nutrition";
import styleConst from "constants/style";

import {
  FoodLogButton,
  InfoContainer,
  Section,
  TitleAdornment,
  TitleContainer,
  Title,
  Text,
  Background,
} from "./styles";

type Params = {
  categoryId: string;
  recipeId: string;
};

interface Props extends RouteComponentProps<Params>, GlobalPopupProps {}

@observer
class RecipeDetails extends React.Component<Props> {
  @observable isLoading: boolean = false;
  @observable isNoInternetConnectionError: boolean = false;

  get recipeId() {
    const {
      match: {
        params: { recipeId },
      },
    } = this.props;

    return Number.parseInt(recipeId);
  }

  get categoryId() {
    const {
      match: {
        params: { categoryId },
      },
    } = this.props;

    return Number.parseInt(categoryId);
  }

  get url() {
    const {
      match: { url },
    } = this.props;

    return url;
  }

  get isToday() {
    return this.url.includes(ROUTES.TODAY);
  }

  get isSearch() {
    return this.url.includes(ROUTES.SEARCH);
  }

  @computed get recipeDetails() {
    return RecipeDetailsStore.details;
  }

  @computed get macronutrients() {
    return this.recipeDetails?.macronutrients ?? {};
  }

  @computed get isFavorite() {
    return Boolean(this.recipeDetails?.isFavorite);
  }

  @computed get ingredients() {
    return this.recipeDetails?.ingredients ?? [];
  }

  @computed get instructions() {
    return this.recipeDetails?.instruction;
  }

  @computed
  get navList() {
    return !this.isSearch ? (
      this.isToday ? (
        <TodayNavList />
      ) : (
        <RecipeLibraryNavList />
      )
    ) : null;
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const { history } = this.props;
    this.isLoading = true;
    try {
      await RecipeDetailsStore.fetch(this.recipeId);
    } catch (error) {
      console.log("error :>> ", error);
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
      if (error instanceof NotFoundError) {
        redirectToRootRoute(history, this.url);
      }
    } finally {
      this.isLoading = false;
    }
  };

  onFavoriteClick = async () => {
    await this.addRecipeToFavorite();
  };

  addRecipeToFavorite = async () => {
    if (!this.isFavorite) {
      await RecipeDetailsStore.addToFavorites();
    } else {
      await RecipeDetailsStore.removeFromFavorites();
    }
  };

  openMealSchedule = (mode: string) => {
    const { openPopup } = this.props;

    openPopup({
      key: "MealSchedulePopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <MealSchedulePopUp
          mode={mode}
          recipe={this.recipeDetails}
          onClose={close}
        />
      ),
    });
  };

  onToday = () => {
    this.openMealSchedule(MEAL_SCHEDULE_TYPE.TODAY);
  };

  onSelectDay = () => {
    this.openMealSchedule(MEAL_SCHEDULE_TYPE.SCHEDULE);
  };

  render() {
    return (
      <Page
        isNoInternetConnectionError={this.isNoInternetConnectionError}
        isLoading={this.isLoading}
        title={this.recipeDetails?.title}
        topNavigation={this.navList}
        titleControls={
          <FoodLogControl
            onToday={this.onToday}
            onSelectDay={this.onSelectDay}
            element={
              <FoodLogButton
                iconLeft={<I32Add />}
                text="Add to Food Log"
                height={50}
                fontSize={16}
                fontWeight={500}
              />
            }
          />
        }
        contentBackground={
          <>
            <Background />
            <Background leftDots />
          </>
        }
      >
        <Details
          isFavorite={this.isFavorite}
          imageUrl={this.recipeDetails?.imageUrl}
          nutrients={this.macronutrients}
          duration={this.recipeDetails?.duration}
          onFavoriteClick={this.onFavoriteClick}
        />

        <InfoContainer>
          <Section>
            <TitleContainer>
              <TitleAdornment />
              <Title>Ingredients</Title>
            </TitleContainer>
            {this.ingredients.map((ingredient, index) => (
              <InlineListItem
                key={`${ingredient.title}-${index}`}
                label={ingredient.title}
                value={ingredient.unit}
              />
            ))}
          </Section>

          <Section width={380}>
            <TitleContainer>
              <TitleAdornment />
              <Title>Instructions</Title>
            </TitleContainer>
            <Text>{this.instructions}</Text>
          </Section>

          <Section>
            <TitleContainer>
              <TitleAdornment />
              <Title>Nutrition Facts</Title>
            </TitleContainer>
            {getNutrientsList(this.macronutrients).map(({ label, value }) => (
              <InlineListItem
                key={`${label}-${value}`}
                label={getNutrientLabel(label)}
                value={getNutrientValue(label, value)}
              />
            ))}
          </Section>
        </InfoContainer>
      </Page>
    );
  }
}

export default withGlobalPopup(withRouter(RecipeDetails));
