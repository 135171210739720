import { flow, types, onSnapshot, applySnapshot } from "mobx-state-tree";

import { AuthModel } from "models/Auth";

import api, { assignAuth, assignOnRefresh } from "services/api";

export const TOKEN = {
  AUTH: "authToken",
  NEED_CHANGE_PASSWORD: "changePasswordToken",
  ONBOARDING: "onboardingToken",
  PREVIEW_ACCOUNT: "previewAccount",
  EMAIL_HASH: "emailHash",
};

// @ts-ignore
const AuthStore = types
  .model({
    tokenData: types.maybe(AuthModel),
    isPreviewAccount: types.maybeNull(types.boolean),
    isNeedChangePassword: types.maybeNull(types.boolean),
    isNeedOnboarding: types.maybeNull(types.boolean),
    isNeedRememberTokenData: types.optional(types.boolean, true),
    isLoading: types.maybe(types.boolean),
    isValidating: types.optional(types.boolean, false),
    email: types.maybe(types.string),
  })
  .views(self => ({
    get isAuthorized() {
      return !!self.tokenData?.accessToken;
    },
  }))
  .actions(self => ({
    setNeedOnboarding: flow(function* (status: boolean) {
      const isNeedOnboarding = status;
      window.localStorage.setItem(
        TOKEN.ONBOARDING,
        JSON.stringify(isNeedOnboarding),
      );
      applySnapshot(self, { ...self, isNeedOnboarding });
    }),
    setEmail: (email: string) => {
      self.email = email;
    },
  }))
  .actions(self => ({
    logout: () => {
      window.localStorage.removeItem(TOKEN.AUTH);
      self.tokenData = undefined;
    },
  }))
  .actions(self => ({
    refreshToken: flow(function* () {
      try {
        self.isLoading = true;
        const result: any = yield api.post("/mobile/account/refresh", {
          token: Auth.tokenData?.refreshToken,
        });

        if (result.accessToken) {
          self.tokenData = result;
        }
        return result;
      } catch (error) {
        console.log("error :>> ", error);
        self.logout();
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
  }))
  .actions(self => ({
    init() {
      const isNeedChangePassword = window.localStorage.getItem(
        TOKEN.NEED_CHANGE_PASSWORD,
      );
      const isNeedOnboarding = window.localStorage.getItem(TOKEN.ONBOARDING);

      applySnapshot(self, {
        ...self,
        isNeedChangePassword:
          isNeedChangePassword != null
            ? JSON.parse(isNeedChangePassword)
            : null,
        isNeedOnboarding:
          isNeedOnboarding != null ? JSON.parse(isNeedOnboarding) : null,
      });
    },

    login: flow(function* (payload) {
      try {
        self.isLoading = true;

        const { isNeedChangePassword, tokenData, isPreviewAccount } =
          yield api.post("/mobile/account/login", payload);

        window.localStorage.setItem(
          TOKEN.NEED_CHANGE_PASSWORD,
          isNeedChangePassword,
        );

        applySnapshot(self, {
          ...self,
          isNeedChangePassword,
          tokenData,
          isPreviewAccount,
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    changePassword: flow(function* (newPassword) {
      self.isLoading = true;
      try {
        const { success } = yield api.post(
          "/mobile/account/change-password/validate",
          {
            newPassword,
          },
        );

        if (success) {
          const tokenData = yield api.post("/mobile/account/change-password", {
            newPassword,
          });

          window.localStorage.setItem(TOKEN.AUTH, tokenData);
          window.localStorage.setItem(
            TOKEN.NEED_CHANGE_PASSWORD,
            JSON.stringify(false),
          );

          applySnapshot(self, {
            ...self,
            isNeedChangePassword: false,
            tokenData,
          });
        }
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    forgotPassword: flow(function* (payload) {
      self.isLoading = true;
      try {
        yield api.post("/mobile/account/forgot-password", payload);
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    validateEmail: flow(function* (email: string) {
      self.isValidating = true;
      try {
        const { isValid } = yield api.get(`/mobile/account/login/validate`, {
          email,
        });

        if (isValid) {
          self.setNeedOnboarding(false);
        }

        return isValid;
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isValidating = false;
      }
    }),

    setIsNeedRememberTokenData(isNeedRememberTokenData: boolean) {
      applySnapshot(self, { ...self, isNeedRememberTokenData });
    },

    afterCreate() {
      const tokenData = window.localStorage.getItem(TOKEN.AUTH);
      assignOnRefresh(self.refreshToken);
      if (tokenData) {
        const authObject = JSON.parse(tokenData);
        self.tokenData = authObject;
        assignAuth(authObject);
      }

      onSnapshot(self, ({ tokenData }) => {
        assignAuth(tokenData);
        assignOnRefresh(self.refreshToken);
        if (tokenData && self.isNeedRememberTokenData) {
          window.localStorage.setItem(TOKEN.AUTH, JSON.stringify(tokenData));
        }
      });
    },
  }));

// @ts-ignore
const Auth = AuthStore.create();

export default Auth;
