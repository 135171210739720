const formatValue = (value: number) => `${value < 10 ? `0` : ``}${value}`;

export const formatRecipeDuration = (count: number) => {
  const hoursValue = Math.floor(count / 60 / 60);
  const minutesValue = formatValue(Math.floor((count % 3600) / 60));

  const hours = hoursValue ? `${hoursValue} h` : "";
  const minutes = minutesValue ? `${minutesValue} min` : "";

  return [hours, minutes].join(` `);
};
