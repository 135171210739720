import React from "react";

import NutrientsMain from "components/NutrientsMain";
import Label from "components/Label";

import {
  Container,
  DetailsContainer,
  Details,
  NutrientsContainer,
  Title,
  TitleContainer,
  Brand,
  Image,
} from "./styles";

type RecipeItem = {
  title: string;
  brand?: string;
  macronutrients?: any;
  isArchived?: boolean;
  imageUrl?: string;
};

type Props = {
  recipe: RecipeItem;
  onClick?: (recipe: RecipeItem) => void;
  flat?: boolean;
  withoutImage?: boolean;
  style?: React.CSSProperties;
};

class Recipe extends React.Component<Props> {
  onClick = () => {
    const { recipe, onClick } = this.props;
    onClick?.(recipe);
  };

  render() {
    const {
      recipe: { title, macronutrients, isArchived, brand, imageUrl },
      flat,
      withoutImage,
      style,
    } = this.props;

    return (
      <Container flat={flat} style={style} onClick={this.onClick}>
        <DetailsContainer>
          <Details withoutImage={withoutImage}>
            {!withoutImage ? <Image imageUrl={imageUrl} /> : null}
            <TitleContainer>
              <Title>{title}</Title>
              {brand ? <Brand>{brand}</Brand> : null}
            </TitleContainer>
            {isArchived ? <Label text={"Archived"} /> : null}
          </Details>
          {macronutrients ? (
            <NutrientsContainer>
              <NutrientsMain nutrients={macronutrients} indent={false} />
            </NutrientsContainer>
          ) : null}
        </DetailsContainer>
      </Container>
    );
  }
}

export default Recipe;
