const {
  REACT_APP_DEFAULT_EMAIL = "",
  REACT_APP_DEFAULT_PASSWORD = "",
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_FIREBASE_DYNAMIC_LINK_URL,
} = process.env;

const apiUrl = REACT_APP_API_URL;
const environmentName = REACT_APP_ENV;

export const isDevelopment = REACT_APP_ENV === "development";

export const isProduction = REACT_APP_ENV === "production";

export const email = isDevelopment ? REACT_APP_DEFAULT_EMAIL : "";

export const password = isDevelopment ? REACT_APP_DEFAULT_PASSWORD : "";

export const firebaseDynamicLinkUrl = REACT_APP_FIREBASE_DYNAMIC_LINK_URL;

export { apiUrl, environmentName };
