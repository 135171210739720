import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I36StarFilled = ({ color = styleConst.colors.primary }: Props) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
    <path
      d="M20.0625 3.2154C19.2763 1.41713 16.7255 1.41713 15.9393 3.2154L13.1859 9.51349C12.8675 10.2418 12.1885 10.748 11.3996 10.8453L4.66685 11.6754C2.79096 11.9066 2.01891 14.2077 3.37572 15.5236L8.50836 20.5014C9.05179 21.0285 9.29375 21.7935 9.15227 22.5371L7.80612 29.6127C7.44433 31.5143 9.49338 32.9548 11.1603 31.9708L16.8571 28.6077C17.5628 28.1911 18.4391 28.1911 19.1448 28.6077L24.8416 31.9708C26.5085 32.9548 28.5575 31.5143 28.1957 29.6127L26.8496 22.5371C26.7081 21.7935 26.9501 21.0285 27.4935 20.5014L32.6261 15.5236C33.983 14.2077 33.2109 11.9066 31.335 11.6754L24.6023 10.8453C23.8134 10.748 23.1344 10.2418 22.816 9.51349L20.0625 3.2154Z"
      fill={color}
    />
  </svg>
);

export default I36StarFilled;


