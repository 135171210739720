import React from "react";

import { SnackbarType } from "components/Snackbar/Snackbar";

export type SnackbarContextProps = {
  setMessage: (message: string, type?: SnackbarType) => void;
};

const GlobalSnackbarContext = React.createContext<SnackbarContextProps>({
  setMessage: () => {},
});

export default GlobalSnackbarContext;

export const withGlobalSnackbar =
  (Wrapped: React.ClassType<any, any, any>): any =>
  // eslint-disable-next-line
  (props: any) => {
    function createContextConsumer(context: SnackbarContextProps) {
      return <Wrapped {...props} {...context} />;
    }

    return (
      <>
        <GlobalSnackbarContext.Consumer>
          {createContextConsumer}
        </GlobalSnackbarContext.Consumer>
      </>
    );
  };
