import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import ResourcesStore from "stores/ResourcesStore";

import { Page } from "components/Layout";
import GridCollection from "components/GridCollection";

import NavList from "pages/Resources/NavList";

import { NoInternetConnectionError, NotFoundError } from "services/api";

import ROUTES from "navigation/routes";

import { redirectToRootRoute } from "utils/route";

import { CATEGORY_CONTENT_VIEW_TYPE } from "constants/resources";

import { Background, Wrapper } from "./styles";

type Props = {} & RouteComponentProps<{
  categoryId: string;
}>;

@observer
class ResourcesCategoryDetails extends React.Component<Props> {
  @observable isNoInternetConnectionError: boolean = false;

  get categoryId() {
    const {
      match: {
        params: { categoryId },
      },
    } = this.props;

    return Number.parseInt(categoryId);
  }

  get url() {
    const {
      match: { url },
    } = this.props;

    return url;
  }

  @computed get store() {
    return ResourcesStore.categoryDetails;
  }

  @computed
  get title() {
    return this.store?.title;
  }

  @computed get quickLinks() {
    return this.store?.items ?? [];
  }

  async componentDidMount() {
    const { history } = this.props;
    if (this.categoryId) {
      try {
        await ResourcesStore.fetchDetails({
          categoryId: this.categoryId,
          viewType: CATEGORY_CONTENT_VIEW_TYPE.CATEGORY_DETAILS,
        });
      } catch (error) {
        if (error instanceof NoInternetConnectionError) {
          this.isNoInternetConnectionError = true;
        }
        if (error instanceof NotFoundError) {
          redirectToRootRoute(history, this.url);
        }
      }
    }
  }

  onLoadMore = async () => {
    if (this.store && this.store.needMore && this.store.context) {
      this.store.setPage(this.store.context.page + 1);
      await this.store.fetch(this.store.context.page);
    }
  };

  onNavigateToQuickLink = (quickLink: any) => {
    const { history } = this.props;
    const { originalQuickLinkId: quickLinkId } = quickLink;

    history.push(
      generatePath(ROUTES.RESOURCES_CATEGORY_DETAILS_QUICK_LINK_DETAILS, {
        categoryId: this.categoryId,
        quickLinkId,
      }),
    );
  };

  render() {
    return (
      <Page
        isLoading={this.store?.isLoading}
        isNoInternetConnectionError={this.isNoInternetConnectionError}
        title={this.title}
        contentBackground={
          <>
            <Background />
            <Background leftDots />
          </>
        }
        topNavigation={<NavList />}
      >
        <Wrapper>
          <GridCollection
            items={this.quickLinks}
            onClick={this.onNavigateToQuickLink}
            needMore={this.store?.needMore}
            onLoadMore={this.onLoadMore}
            withInfinityScroll
          />
        </Wrapper>
      </Page>
    );
  }
}

export default withRouter(ResourcesCategoryDetails);
