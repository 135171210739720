// @ts-nocheck
import Ingredients from "./ingridients.png";
import Recipes from "./recipes.png";
import Search from "./search.png";
import NotFound from "./not_found.png";
import Workout from "./workout.png";
import Favorites from "./favorites.png";
import Camera from "./camera.png";
import Error from "./error.png";

export const Placeholders = {
  Ingredients,
  Recipes,
  Search,
  NotFound,
  Workout,
  Favorites,
  Camera,
  Error,
};
