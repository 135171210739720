import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { format, isAfter, startOfDay } from "date-fns";

import ProfileStore from "stores/ProfileStore";

import IPhotoPlaceholder from "assets/icons/IPhotoPlaceholder";
import I24Edit from "assets/icons/I24Edit";

import Button from "components/Button";
import DatePicker from "components/DatePicker";
import Input from "components/Input";
import RadioButtonList from "components/RadioButtonList";
import { SnackbarTypeValues } from "components/Snackbar";
import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";

import { isEmpty } from "utils/validation";
import { formatDate, SERVER_DATE_FORMAT, isValidDate } from "utils/date";

import {
  ButtonWrapper,
  Container,
  Content,
  Title,
  Controls,
  ItemWrapper,
  Column,
  ColumnWrapper,
  ImageContainer,
  UserPhoto,
  Header,
} from "./styles";

type Props = {
  onClose: () => void;
} & SnackbarContextProps;

type Field = "name" | "dateOfBirth" | "gender" | "location";

interface Values {
  name?: any;
  dateOfBirth?: any;
  gender?: any;
  location?: any;
}

const DATE_ERROR = "Date is incorrect";

@observer
class EditProfilePopup extends React.Component<Props> {
  inputRef = React.createRef<any>();
  @observable isSubmitting: boolean = false;

  get input() {
    return this.inputRef.current;
  }

  @observable values: Values = {
    name: "",
    dateOfBirth: "",
    gender: "",
    location: "",
  };

  @observable error: Values = {
    name: "",
    dateOfBirth: "",
    gender: "",
    location: "",
  };

  @computed get isError() {
    return Object.values(this.error).some(Boolean);
  }

  @computed get isFilled() {
    return (
      !!this.values.name &&
      !!this.values.dateOfBirth &&
      isValidDate(this.values.dateOfBirth) &&
      !this.error.dateOfBirth
    );
  }

  @computed get image() {
    return ProfileStore.image;
  }

  componentDidMount() {
    const { name, dateOfBirth, gender, location } = ProfileStore;

    let formatedDateOfBirth: any;
    if (dateOfBirth) {
      formatedDateOfBirth = new Date(this.formatDateOfBirth(dateOfBirth));
    }

    this.values = { name, dateOfBirth: formatedDateOfBirth, gender, location };
  }

  onChange =
    (field: Field) =>
    (text: string | Date | null = "") => {
      this.values[field] = text;
      this.error[field] = "";

      if (field === "dateOfBirth") {
        this.error.dateOfBirth = "";

        this.error.dateOfBirth = isAfter(this.values.dateOfBirth, new Date())
          ? DATE_ERROR
          : "";
      }
    };

  onSelect = (field: Field) => (option: any) => {
    this.values[field] = option.value;
    this.error[field] = "";
  };

  checkForm = () => {
    this.error = {
      name: "",
    };

    this.error.name = isEmpty(this.values.name);
  };

  onSubmit = async () => {
    const { onClose, setMessage } = this.props;
    this.checkForm();
    if (!this.isError) {
      try {
        this.isSubmitting = true;

        const date = this.values.dateOfBirth;

        const dateOfBirth = date
          ? formatDate(startOfDay(date), SERVER_DATE_FORMAT)
          : undefined;

        await ProfileStore.update({ ...this.values, dateOfBirth });
        onClose();
      } catch (error: any) {
        console.log("error :>> ", error);
        setMessage(
          error?.message || "Something went wrong",
          SnackbarTypeValues.ALERT,
        );
      } finally {
        this.isSubmitting = false;
      }
    }
  };

  formatDateOfBirth = (value: string) => {
    return `${format(new Date(value), "MM/dd/yyyy")}`;
  };

  onBrowseFileClick = () => {
    this.input.value = null;
    this.input?.click();
  };

  onFileUpload = async ({ target }: any) => {
    if (target.files && target.files[0]) {
      const file = target.files[0];
      if (file) {
        try {
          await ProfileStore.updatePhoto(file);
        } catch (error: any) {
          const { setMessage } = this.props;
          setMessage(
            error?.message || "Something went wrong",
            SnackbarTypeValues.ALERT,
          );
        }
      }
    }
  };

  render() {
    return (
      <Container>
        <Content>
          <Header>
            <I24Edit color={"#DADADA"} />
            <Title>Edit</Title>
          </Header>

          <ColumnWrapper>
            <Column>
              <ItemWrapper>
                <Input
                  label="Name"
                  value={this.values.name}
                  onChangeText={this.onChange("name")}
                  placeholder="Type your name"
                  error={this.error.name}
                  maxLength={200}
                />
              </ItemWrapper>
              <ItemWrapper>
                <DatePicker
                  label="Date of Birth"
                  placeholder={"mm/dd/yyyy"}
                  value={this.values.dateOfBirth}
                  onChange={this.onChange("dateOfBirth")}
                  maxDateMessage={DATE_ERROR}
                  invalidDateMessage={DATE_ERROR}
                  maxDate={new Date()}
                />
              </ItemWrapper>
              <ItemWrapper>
                <RadioButtonList
                  label="Gender"
                  options={[
                    { label: "Male", value: "Male" },
                    { label: "Female", value: "Female" },
                  ]}
                  selected={this.values.gender}
                  onSelect={this.onSelect("gender")}
                  error={this.error.gender}
                  showInLine={true}
                />
              </ItemWrapper>
              <ItemWrapper>
                <Input
                  label="Location"
                  value={this.values.location}
                  onChangeText={this.onChange("location")}
                  placeholder="Please specify"
                  error={this.error.location}
                  maxLength={200}
                />
              </ItemWrapper>
            </Column>
            <Column>
              <ImageContainer>
                {this.image ? (
                  <UserPhoto src={this.image} />
                ) : (
                  <IPhotoPlaceholder width={190} height={190} />
                )}
                <input
                  hidden
                  type="file"
                  accept={"image/*"}
                  ref={this.inputRef}
                  onChange={this.onFileUpload}
                />

                <ButtonWrapper>
                  <Button
                    text={this.image ? "Change photo" : "Upload photo"}
                    onClick={this.onBrowseFileClick}
                    fontSize={14}
                    fontWeight={400}
                    height={26}
                    width={130}
                  />
                </ButtonWrapper>
              </ImageContainer>
            </Column>
          </ColumnWrapper>

          <Controls>
            <ButtonWrapper>
              <Button
                text={"Done"}
                onClick={this.onSubmit}
                height={50}
                width={280}
                disabled={!this.isFilled}
                loading={this.isSubmitting}
              />
            </ButtonWrapper>
          </Controls>
        </Content>
      </Container>
    );
  }
}

export default withGlobalSnackbar(EditProfilePopup);
