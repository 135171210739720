import React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import WorkoutDetailsStore from "stores/WorkoutDetailsStore";

import { NavListItem } from "components/Layout";
import { NavLinkItemType } from "components/Layout/NavListItem/NavListItem";

import { capitalize } from "utils/common";

import ROUTES from "navigation/routes";

type Props = { workoutLabel?: string } & RouteComponentProps<{
  workoutId?: string;
  movementId?: string;
  scheduleDate?: string;
}>;

@observer
class NavList extends React.Component<Props> {
  get pathname() {
    const {
      location: { pathname },
    } = this.props;

    return pathname;
  }

  get workoutId() {
    const {
      match: {
        params: { workoutId },
      },
    } = this.props;

    return workoutId ? Number.parseInt(workoutId) : undefined;
  }

  get scheduleDate() {
    const {
      match: {
        params: { scheduleDate },
      },
    } = this.props;

    return scheduleDate;
  }

  get movementId() {
    const {
      match: {
        params: { movementId },
      },
    } = this.props;

    return movementId ? Number.parseInt(movementId) : undefined;
  }

  get firstItem(): NavLinkItemType {
    return {
      title: "Calendar",
      onClick: this.onClick(ROUTES.CALENDAR),
    };
  }

  @computed get workoutTitle() {
    return WorkoutDetailsStore.details?.title
      ? capitalize(WorkoutDetailsStore.details?.title)
      : undefined;
  }

  @computed get movementTitle() {
    return this.movementId
      ? capitalize(
          WorkoutDetailsStore.getMovementDetails(this.movementId)?.title,
        )
      : undefined;
  }

  @computed get navListItems() {
    const firstItem = this.firstItem;

    if (this.workoutId) {
      const secondItem = this.workoutId
        ? {
            title: this.workoutTitle,
            onClick: this.onClick(
              ROUTES.CALENDAR_WORKOUT_SCHEDULE_DATE_DETAILS,
              {
                workoutId: this.workoutId,
                scheduleDate: this.scheduleDate,
              },
            ),
          }
        : undefined;

      const thirdItem = this.movementId
        ? {
            title: this.movementTitle,
            onClick: this.onClick(
              ROUTES.CALENDAR_WORKOUT_SCHEDULE_DATE_DETAILS_MOVEMENT_DETAILS,
              {
                workoutId: this.workoutId,
                movementId: this.movementId,
                scheduleDate: this.scheduleDate,
              },
            ),
          }
        : undefined;

      return [firstItem, secondItem, thirdItem].filter(Boolean);
    }

    return [];
  }

  onClick = (route: string, routeParams?: any) => () => {
    const { history } = this.props;

    history.push(generatePath(route, routeParams));
  };

  render() {
    return (
      <>
        {this.navListItems.map((item: any, index: number) => (
          <NavListItem
            key={`${index}-${item.title}`}
            title={item.title}
            onClick={item.onClick}
            isLastItem={index === this.navListItems.length - 1}
          />
        ))}
      </>
    );
  }
}

export default withRouter(NavList);
