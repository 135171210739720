import styled, { css } from "styled-components";

import styleConst from "constants/style";
import {
  FOOTER_HEIGHT,
  MEDIA_QUERIES,
  PAGE_CONTENT_WIDTH,
  PAGE_WIDTH,
} from "constants/layout";

interface IFooterContent {
  isBottom?: boolean;
  isTop?: boolean;
}

interface IWrapper {
  isTop?: boolean;
}

interface IContainer {
  disabled?: boolean;
}

export const BottomBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  background-color: ${styleConst.colors.background};
`;

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;

  min-height: ${FOOTER_HEIGHT};

  ${MEDIA_QUERIES.DESKTOP} {
    min-width: ${PAGE_WIDTH.DESKTOP};
  }

  ${MEDIA_QUERIES.MEDIUM_DESKTOP} {
    min-width: ${PAGE_WIDTH.SMALL_DESKTOP};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `};
`;

export const FooterContent = styled.div<IFooterContent>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  margin: 0 auto;

  ${MEDIA_QUERIES.DESKTOP} {
    width: ${PAGE_CONTENT_WIDTH.DESKTOP};
    min-width: ${PAGE_CONTENT_WIDTH.DESKTOP};
  }

  ${MEDIA_QUERIES.MEDIUM_DESKTOP} {
    width: ${PAGE_CONTENT_WIDTH.SMALL_DESKTOP};
    min-width: ${PAGE_CONTENT_WIDTH.SMALL_DESKTOP};
  }

  ${({ isBottom }) =>
    isBottom &&
    css`
      position: relative;
      z-index: 2;
    `}

  ${({ isTop }) =>
    isTop &&
    css`
      align-items: flex-start;

      padding: 64px 0 44px 0;
      min-height: 290px;
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
`;

export const Wrapper = styled.div<IWrapper>`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: row;

  background-color: ${styleConst.colors.white};

  ${({ isTop }) =>
    isTop &&
    css`
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    `}
`;
