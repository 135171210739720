import * as React from "react";

import style from "constants/style";

type Props = {
  color?: string;
};

const I24ArrowRight = ({ color = style.colors.text }: Props) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 17 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.20711 0.792893C1.81658 0.402369 1.18342 0.402369 0.792893 0.792893C0.402369 1.18342 0.402369 1.81658 0.792893 2.20711L2.20711 0.792893ZM15.5 15.5L16.2071 16.2071C16.5976 15.8166 16.5976 15.1834 16.2071 14.7929L15.5 15.5ZM0.792893 28.7929C0.402369 29.1834 0.402369 29.8166 0.792893 30.2071C1.18342 30.5976 1.81658 30.5976 2.20711 30.2071L0.792893 28.7929ZM0.792893 2.20711L14.7929 16.2071L16.2071 14.7929L2.20711 0.792893L0.792893 2.20711ZM14.7929 14.7929L0.792893 28.7929L2.20711 30.2071L16.2071 16.2071L14.7929 14.7929Z"
        fill={color}
      />
    </svg>
  );
};

export default I24ArrowRight;
