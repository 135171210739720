import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface ITitle {
  fontSize?: number;
}

export const Arrows = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 70px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0px 10px 0 14px;
  margin-bottom: 29px;

  border-left: 4px solid ${styleConst.colors.primary};
`;

export const Title = styled.p<ITitle>`
  font-size: 18px;
  font-weight: 600;
  font-family: ${styleConst.fonts.montserrat};
  line-height: 48px;
  color: ${styleConst.colors.dark};

  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize}px;
    `};
`;

export const ArrowContainer = styled.div`
  cursor: pointer;
`;
