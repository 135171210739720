import styled, { css } from "styled-components";

import DefaultAccordion from "components/Accordion";

import styleConst from "constants/style";

interface IContainer {
  filtersForm?: boolean;
}

export const Accordion = styled(DefaultAccordion)`
  margin: 0px;
  padding: 0px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;

  padding: 53px 0 30px 0;

  min-height: 250px;
  width: 700px;

  ${({ filtersForm }) =>
    filtersForm &&
    css`
      padding-bottom: 0px;

      width: 980px;
    `}
`;

export const Content = styled.div``;

export const ItemContainer = styled.div`
  padding: 0 61px;

  border-bottom: 1px solid ${styleConst.colors.black10};
  
  cursor: pointer;
`;

export const Results = styled.div`
  display: flex;
  flex-direction: column;

  max-height: calc(100vh - 300px);
  overflow-y: auto;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
`;

export const SearchWrapper = styled.div`
  width: 100%;

  padding: 0 62px;
`;

export const ScrollLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 210px;
`;
