import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";

import Popup from "components/Popup";

export type ContentProps = {
  close: () => void;
};

export type ConfirmationProps = {
  deny: () => void;
  allow: () => void;
};

export type RenderConfirmation = (props: ConfirmationProps) => React.ReactNode;

export type RenderContent = (props: ContentProps) => React.ReactNode;

type Options = {
  render: RenderContent;
  onClose?: () => void;
  canCloseByOuterClick?: boolean;
  confirmation?: {
    renderConfirmation: RenderConfirmation;
    shouldShowConfirmation: () => boolean;
  };
  exitButtonZIndex?: number;
  exitButtonColor?: string;
};

@observer
class PopupService extends React.Component {
  @observable static isVisible: boolean = false;
  @observable static key: string = "";
  @observable static isConfirmationVisible: boolean = false;
  @observable static keyConfirmation: string = "";
  static options: Options | null = null;

  static open(key: string, options: Options) {
    if (PopupService.isVisible) {
      if (key === PopupService.key) return;
      throw new Error(
        `Another popup is opened now with key: ${PopupService.key}`,
      );
    }

    PopupService.key = key;
    PopupService.options = options;
    PopupService.isVisible = true;
  }

  static close(key: string = PopupService.key) {
    if (!PopupService.isVisible) {
      throw new Error("There are no visible popups");
    }
    if (key !== PopupService.key) {
      throw new Error(`Current popup has different key: ${PopupService.key}`);
    }

    PopupService.isVisible = false;
  }

  static openConfirmation(
    keyConfirmation: string = `${PopupService.key}ConfirmationDialog`,
  ) {
    if (PopupService.isConfirmationVisible) {
      if (keyConfirmation === PopupService.keyConfirmation) return;
      throw new Error(
        `Another popup confirmation is opened now with key: ${PopupService.keyConfirmation}`,
      );
    }

    PopupService.isConfirmationVisible = true;
    PopupService.keyConfirmation = keyConfirmation;
  }

  static closeConfirmation(
    keyConfirmation: string = PopupService.keyConfirmation,
  ) {
    if (!PopupService.isConfirmationVisible) {
      throw new Error("There are no visible confirmation popups");
    }
    if (keyConfirmation !== PopupService.keyConfirmation) {
      throw new Error(
        `Current confirmation popup has different key: ${PopupService.keyConfirmation}`,
      );
    }

    PopupService.isConfirmationVisible = false;
  }
}

@observer
class GlobalPopup extends React.Component<any, any> {
  onClosePopup = () => {
    PopupService.close();
    if (!PopupService.options) return;
    const {
      options: { onClose },
    } = PopupService;
    onClose && onClose();
  };

  onCloseConfirmation = () => {
    PopupService.closeConfirmation();
  };

  onConfirmationAllow = () => {
    this.onCloseConfirmation();
    this.onClosePopup();
  };

  onClose = () => {
    if (
      !PopupService.options ||
      !PopupService.options?.confirmation ||
      !PopupService.options.confirmation.shouldShowConfirmation()
    ) {
      this.onClosePopup();
      return;
    }

    PopupService.openConfirmation(PopupService.keyConfirmation);
  };

  render() {
    const {
      isVisible,
      key,
      options,
      isConfirmationVisible,
      keyConfirmation,
      closeConfirmation,
    } = PopupService;
    if (!options) return null;
    const { render, canCloseByOuterClick, confirmation } = options;

    return (
      isVisible && (
        <>
          <Popup
            key={key}
            onClose={this.onClose}
            canCloseByOuterClick={canCloseByOuterClick || false}
            exitButtonZIndex={options.exitButtonZIndex}
            exitButtonColor={options.exitButtonColor}
          >
            {render({ close: this.onClose })}
          </Popup>

          {confirmation && isConfirmationVisible && (
            <Popup
              key={keyConfirmation}
              onClose={closeConfirmation}
              canCloseByOuterClick={canCloseByOuterClick || false}
            >
              {confirmation.renderConfirmation({
                allow: this.onConfirmationAllow,
                deny: this.onCloseConfirmation,
              })}
            </Popup>
          )}
        </>
      )
    );
  }
}

export default GlobalPopup;

const { open, close } = PopupService;
export { open, close };
