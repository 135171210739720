import { flow, types } from "mobx-state-tree";

import api from "services/api";

const WaterCalculator = types
  .model("WaterCalculator", {
    prefilledWeight: types.optional(types.string, ""),
    prefilledMinutesOfActivity: types.optional(types.string, ""),
    isLoading: types.optional(types.boolean, false),
    isManaging: types.optional(types.boolean, false),
  })

  .actions(self => ({
    fetch: flow(function* () {
      try {
        self.isLoading = true;

        const prefilledAnswers = yield api.get("/mobile/water/goal/settings");

        if (prefilledAnswers.weight) {
          self.prefilledWeight = prefilledAnswers.weight.toString();
        }

        if (prefilledAnswers.minutesOfActivity) {
          self.prefilledMinutesOfActivity =
            prefilledAnswers.minutesOfActivity.toString();
        }
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    publishAnswers: flow(function* (answers) {
      try {
        self.isManaging = true;

        yield api.put("/mobile/water/goal/settings", answers);
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isManaging = false;
      }
    }),
  }));

const WaterCalculatorStore = WaterCalculator.create();

export default WaterCalculatorStore;
