import React from "react";
import { computed } from "mobx";
import { observer } from "mobx-react";

import FoodLogStore from "stores/FoodLogStore";

import NutrientsProgress from "components/NutrientsProgress";

import {
  Container,
  Content,
  Title,
  NutrientsProgressWrapper,
  NutrientsProgressTitle,
  Blur,
} from "./styles";

type Props = {
  onClose: () => void;
};

@observer
class MacroGoalsPopup extends React.Component<Props> {
  @computed get macronutrients() {
    return FoodLogStore.summary?.macronutrients;
  }

  onClick = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    return (
      <Container>
        <Content>
          <Title>Macro Goals</Title>

          <NutrientsProgressWrapper>
            <NutrientsProgressTitle>Consumed</NutrientsProgressTitle>
            <>
              {this.macronutrients ? (
                <NutrientsProgress nutrients={this.macronutrients} />
              ) : null}
            </>
          </NutrientsProgressWrapper>
          <Blur />
        </Content>
      </Container>
    );
  }
}

export default MacroGoalsPopup;
