import { applySnapshot, flow, types } from "mobx-state-tree";

import api from "services/api";

import { Context, SimpleModel } from "models";
import { Recipe } from "models/Recipe";
import { Resource } from "models/Resource";
import { Workout } from "models/Workout";

const defaultParams = { excludeDescription: true };

const Resources = types.model("Resources", {
  items: types.array(Resource),
  context: types.maybe(Context),
  isLoading: types.optional(types.boolean, false),
  isMoreLoading: types.optional(types.boolean, false),
});

const Today = types
  .model("Today", {
    foodDayType: types.maybe(SimpleModel),
    workouts: types.array(types.late(() => Workout)),
    recipes: types.array(types.late(() => Recipe)),
    quickLinks: types.optional(Resources, {}),
    bannerImageUrl: types.maybe(types.string),
    isLoading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get foodDay() {
      return self.foodDayType?.name;
    },

    get needMoreQuickLinks() {
      if (self.quickLinks.context) {
        return self.quickLinks.context.page < self.quickLinks.context.pages;
      }
    },
  }))
  .actions(self => ({
    fetch: flow(function* () {
      try {
        self.isLoading = true;
        const response = yield api.get("/mobile/today");
        applySnapshot(self, { ...self, ...response });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    fetchQuickLinks: flow(function* (page = 1) {
      try {
        if (page === 1) {
          self.quickLinks.isLoading = true;
        } else {
          self.quickLinks.isMoreLoading = true;
        }

        const { items, context } = yield api.get(
          `/mobile/quick-resource/today/list`,
          { page, ...defaultParams },
        );

        applySnapshot(self.quickLinks, {
          ...self,
          items: page === 1 ? items : self.quickLinks.items.concat(items),
          context,
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        if (page === 1) {
          self.quickLinks.isLoading = false;
        } else {
          self.quickLinks.isMoreLoading = false;
        }
      }
    }),
  }));

const TodayStore = Today.create();

export default TodayStore;
