import React from "react";

import {
  getMainFields,
  getNutrientsList,
  getNutrientValue,
} from "utils/nutrients";

import { Container, Nutrient, Value, Label } from "./styles";

type Props = {
  nutrients:
    | { carbs: number; fiber: number; fat: number; protein: number }
    | {};
  indent?: boolean;
  valueColor?: string;
  labelColor?: string;
  withLabel?: boolean;
};

const NutrientsMain = ({
  nutrients,
  indent = true,
  valueColor,
  labelColor,
  withLabel = false,
}: Props) => (
  <Container>
    {getNutrientsList(getMainFields(nutrients)).map(({ label, value }) => (
      <Nutrient key={`${label}_${value}`}>
        {withLabel ? <Label color={labelColor}>{label}</Label> : null}
        <Value indent={indent} color={valueColor}>
          {getNutrientValue(label, value)}
        </Value>
      </Nutrient>
    ))}
  </Container>
);

export default NutrientsMain;
