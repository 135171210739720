import React from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

import NoInternetConnectionPlaceholder from "components/NoInternetConnectionPlaceholder";

import { CONTAINER_SCROLLABLE_DIV_ID } from "constants/layout";

import {
  BackgroundContainer,
  ContentWrapper,
  Content,
  ContentContainer,
  WrappedContainer,
} from "./styles";

type Props = RouteComponentProps & {
  children?: React.ReactNode;
  header?: React.ReactNode;
  topBanner?: React.ReactNode;
  bottomBanner?: React.ReactNode;
  footer?: React.ReactNode;
  contentBackground?: React.ReactNode;
  isNoInternetConnectionError?: boolean;
};

@observer
class Container extends React.Component<Props> {
  render() {
    const {
      children,
      header,
      topBanner,
      bottomBanner,
      footer,
      contentBackground,
      isNoInternetConnectionError,
    } = this.props;

    return (
      <WrappedContainer>
        <ContentWrapper id={CONTAINER_SCROLLABLE_DIV_ID}>
          {header}
          {isNoInternetConnectionError ? (
            <NoInternetConnectionPlaceholder />
          ) : (
            <>
              {topBanner}
              <ContentContainer>
                <BackgroundContainer>{contentBackground}</BackgroundContainer>
                <Content>{children}</Content>
              </ContentContainer>
              {bottomBanner}
            </>
          )}

          {footer}
        </ContentWrapper>
      </WrappedContainer>
    );
  }
}

export default withRouter(Container);
