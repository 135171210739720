import React from "react";
import { FieldRenderProps } from "react-final-form";

import withFieldControl from "../FieldControl";
import Dropdown, { DropdownExternalProps } from "components/Dropdown/Dropdown";

type Props = {
  onChangeCallback?: (
    newValue?: any | any[],
    previousValue?: any | any[],
  ) => void;
};

class DropdownFieldControl extends React.Component<
  Props & FieldRenderProps<any> & DropdownExternalProps
> {
  onChange = (item: any) => {
    const {
      input: { onChange, value: previousValue },
      onChangeCallback,
    } = this.props;

    const event: any = { target: { value: item?.value } };
    onChange(event);
    onChangeCallback?.(item.value, previousValue);
  };

  render() {
    const { input, meta, ...props } = this.props;
    return (
      <Dropdown
        input={input}
        onChange={this.onChange}
        error={meta.touched && meta.error}
        {...props}
      />
    );
  }
}

export default withFieldControl(DropdownFieldControl);
