import React from "react";

import {
  Background,
  Container,
  Content,
  Image,
  TextContainer,
  Text,
  Title,
} from "./styles";

type Props = {
  name: string;
  title?: string;
  imageUrl?: string;
};

class Banner extends React.Component<Props> {
  render() {
    const { imageUrl, name, title } = this.props;

    return (
      <>
        <Background />
        <Container>
          <Content>
            <TextContainer>
              <Text>{name}</Text>
              {title && <Title>{title}</Title>}
            </TextContainer>
            {imageUrl && <Image imageUrl={imageUrl} />}
          </Content>
        </Container>
      </>
    );
  }
}

export default Banner;
