import styled from "styled-components";

import styleConst from "constants/style";

export const ActionTooltip = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.black};
  letter-spacing: 0.01em;

  visibility: hidden;

  background-color: ${styleConst.colors.white};
  color: ${styleConst.colors.black};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  padding: 2px 10px;

  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;

  white-space: nowrap;
  opacity: 0.5;
`;

export const Action = styled.div`
  align-items: center;
  justify-content: flex-start;
  position: relative;

  height: 100%;
  cursor: pointer;

  padding: 0 0 0 24px;

  &:hover {
    svg {
      path {
        fill: ${styleConst.colors.primaryDark};
      }
    }

    ${ActionTooltip} {
      visibility: visible;
    }
  }
`;

export const Actions = styled.div`
  display: flex;

  flex-direction: row;
  justify-content: flex-end;

  height: 100%;

  border-bottom-color: ${styleConst.colors.black4};
  border-bottom-width: 2px;
`;
