import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import ReactResizeDetector from "react-resize-detector";

import I176TextLogo from "assets/icons/I176TextLogo";
import I283AppStore from "assets/icons/I283AppStore";
import I283GoogleStore from "assets/icons/I283GoogleStore";

import { isIOS, isMacOS } from "utils/window";
import { firebaseDynamicLinkUrl } from "utils/env";

import { MOBILE_DEVICE_BREAKPOINTS } from "constants/layout";

import {
  Container,
  Content,
  Footer,
  Header,
  Icons,
  IconWrapper,
  Logo,
  Row,
  Title,
  Text,
} from "./styles";

type Props = {};

@observer
class WithMobilePlaceholder extends React.Component<Props> {
  @observable isMobile: boolean = false;

  get showAppStore() {
    return isIOS || isMacOS;
  }

  onResize = (width?: number, height?: number) => {
    if (width && height) {
      const isMobile =
        (width <= MOBILE_DEVICE_BREAKPOINTS.WIDTH &&
          height <= MOBILE_DEVICE_BREAKPOINTS.HEIGHT) ||
        (width <= MOBILE_DEVICE_BREAKPOINTS.HEIGHT &&
          height <= MOBILE_DEVICE_BREAKPOINTS.WIDTH);

      const shouldUpdate = isMobile !== this.isMobile;

      if (shouldUpdate) {
        this.isMobile = isMobile;
      }
    }
  };

  openExternalLink = () => {
    window.open(firebaseDynamicLinkUrl, "_self");
  };

  render() {
    const { children } = this.props;

    return (
      <ReactResizeDetector onResize={this.onResize}>
        {this.isMobile ? (
          <Container>
            <Header>
              <Logo>
                <I176TextLogo />
              </Logo>
            </Header>
            <Content>
              <Row>
                <Title>Download App</Title>
              </Row>
              <Row>
                <Text>
                  Download our comprehensive client app to unlock exclusive
                  features!
                </Text>
              </Row>
              <Icons>
                {this.showAppStore ? (
                  <IconWrapper onClick={this.openExternalLink}>
                    <I283AppStore />
                  </IconWrapper>
                ) : (
                  <IconWrapper onClick={this.openExternalLink}>
                    <I283GoogleStore />
                  </IconWrapper>
                )}
              </Icons>
            </Content>
            <Footer />
          </Container>
        ) : (
          children
        )}
      </ReactResizeDetector>
    );
  }
}

export default WithMobilePlaceholder;
