import React from "react";

import I16Replay from "assets/icons/I16Replay";
import I24AddFilled from "assets/icons/I24AddFilled";
import I16Edit from "assets/icons/I16Edit";

import ButtonInline from "components/ButtonInline";
import ProgressBar from "components/ProgressBar";

import styleConst from "constants/style";

import {
  Container,
  Footer,
  Header,
  Title,
  Value,
  ButtonsContainer,
} from "./styles";

type Props = {
  amount: number;
  goal: number;
  unit: string;
  onAdd?: () => void;
  onReset?: () => void;
  onEdit?: () => void;
};

const Water = ({ amount, goal, unit, onAdd, onReset, onEdit }: Props) => (
  <Container>
    <Header>
      <Title>{"Water"}</Title>
      <ButtonInline
        color={styleConst.colors.white}
        onPress={onAdd}
        text={"Add Water"}
        iconLeft={<I24AddFilled color={styleConst.colors.white} />}
      />
    </Header>
    <ProgressBar
      height={6}
      current={amount}
      count={goal}
      width={286}
      tintColor={styleConst.colors.white}
      backgroundColor={styleConst.colors.white20}
    />
    <Footer>
      <ButtonsContainer>
        {onEdit ? (
          <ButtonInline
            fontSize={14}
            onPress={onEdit}
            style={{ marginRight: 16 }}
            color={styleConst.colors.white}
            text={"Edit"}
            iconLeft={<I16Edit color={styleConst.colors.white} />}
          />
        ) : null}

        {onReset ? (
          <ButtonInline
            fontSize={14}
            onPress={onReset}
            color={styleConst.colors.white}
            text={"Reset"}
            iconLeft={<I16Replay color={styleConst.colors.white} />}
          />
        ) : null}
      </ButtonsContainer>

      <Value>{`${amount} / ${goal} ${unit}`}</Value>
    </Footer>
  </Container>
);

export default Water;
