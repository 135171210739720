import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import {
  generatePath,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import Dropdown from "components/Dropdown";

import ROUTES from "navigation/routes";

import { Container, Wrapper } from "./styles";

type Props = {} & RouteComponentProps;

const MENU_ITEMS = [
  {
    text: "Food Log",
    route: ROUTES.NUTRITION_FOOD_LOG,
    rootRoute: ROUTES.NUTRITION_FOOD_LOG,
  },
  {
    text: "Recipe Library",
    route: ROUTES.NUTRITION_RECIPE_LIBRARY,
    rootRoute: ROUTES.NUTRITION_RECIPE_LIBRARY,
  },
  {
    text: "My Items",
    route: ROUTES.NUTRITION_MY_ITEMS_MY_RECIPES,
    rootRoute: ROUTES.NUTRITION_MY_ITEMS,
  },
];

@observer
class PageSelector extends React.Component<Props> {
  @observable isMenuOpened: boolean = false;

  get url() {
    const {
      location: { pathname },
    } = this.props;

    return pathname;
  }

  get selectedValue() {
    return (
      this.options.find(({ value }) => this.url.includes(value.rootRoute)) ?? {
        label: "",
        value: "",
      }
    );
  }

  get options() {
    return MENU_ITEMS.map(value => ({
      label: value.text,
      value: { ...value, id: value.text },
    }));
  }

  openMenu = () => {
    this.isMenuOpened = true;
  };

  hideMenu = () => {
    this.isMenuOpened = false;
  };

  onChange = (value: any) => {
    const { history } = this.props;
    const {
      label,
      value: { route },
    } = value;

    if (route && this.selectedValue.label !== label) {
      history.push(generatePath(route));
    }
  };

  render() {
    return (
      <Container>
        <Wrapper>
          <Dropdown
            value={this.selectedValue}
            options={this.options}
            onChange={this.onChange}
            searchable={false}
            pageSelector
          />
        </Wrapper>
      </Container>
    );
  }
}

export default withRouter(PageSelector);
