import styled from "styled-components";

import styleConst from "constants/style";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${styleConst.colors.black10};
  }
`;

export const Label = styled.span`
  font-size: 16px;
  line-height: 26px;

  letter-spacing: 0.02em;

  color: ${styleConst.colors.text};
`;

export const Value = styled.span`
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 16px;
  line-height: 26px;

  letter-spacing: 0.02em;

  color: ${styleConst.colors.dark};

  white-space: nowrap;
`;
