import React from "react";

import styleConst from "constants/style";

const I120Calculator = (props: any) => (
  <svg width={120} height={120} viewBox="0 0 120 120" fill="none" {...props}>
    <g>
      <rect x={25} y={26} width={74} height={74} rx={6} fill="#8C8C8C" />
    </g>
    <rect
      x={25}
      y={25}
      width={74}
      height={74}
      rx={6}
      fill="url(#prefix__paint0_linear)"
    />
    <rect
      x={23}
      y={23}
      width={74}
      height={74}
      rx={6}
      fill="url(#prefix__paint1_linear)"
    />
    <rect
      x={33}
      y={33}
      width={54}
      height={11}
      rx={3}
      fill="url(#prefix__paint2_linear)"
    />
    <rect
      x={31}
      y={31}
      width={58}
      height={18}
      rx={5}
      fill="url(#prefix__paint3_linear)"
    />
    <g>
      <rect
        x={33}
        y={33}
        width={54}
        height={14}
        rx={3}
        fill="url(#prefix__paint4_linear)"
      />
    </g>
    <rect x={33.5} y={33.5} width={53} height={13} rx={2.5} stroke="#666" />
    <g>
      <rect
        x={33}
        y={56}
        width={23}
        height={14}
        rx={3}
        fill="url(#prefix__paint5_linear)"
      />
    </g>
    <rect
      x={33}
      y={55}
      width={23}
      height={14}
      rx={3}
      fill="url(#prefix__paint6_linear)"
    />
    <rect
      x={33}
      y={56}
      width={23}
      height={12}
      rx={3}
      fill="url(#prefix__paint7_linear)"
    />
    <g>
      <rect
        x={33}
        y={74}
        width={23}
        height={14}
        rx={3}
        fill="url(#prefix__paint8_linear)"
      />
    </g>
    <rect
      x={33}
      y={73}
      width={23}
      height={14}
      rx={3}
      fill="url(#prefix__paint9_linear)"
    />
    <rect
      x={33}
      y={74}
      width={23}
      height={12}
      rx={3}
      fill="url(#prefix__paint10_linear)"
    />
    <g>
      <rect
        x={64}
        y={56}
        width={23}
        height={14}
        rx={3}
        fill="url(#prefix__paint11_linear)"
      />
    </g>
    <rect
      x={64}
      y={55}
      width={23}
      height={14}
      rx={3}
      fill="url(#prefix__paint12_linear)"
    />
    <rect
      x={64}
      y={56}
      width={23}
      height={12}
      rx={3}
      fill="url(#prefix__paint13_linear)"
    />
    <g>
      <rect
        x={64}
        y={74}
        width={23}
        height={14}
        rx={3}
        fill="url(#prefix__paint14_linear)"
      />
    </g>
    <rect
      x={64}
      y={73}
      width={23}
      height={14}
      rx={3}
      fill="url(#prefix__paint15_linear)"
    />
    <rect
      x={64}
      y={74}
      width={23}
      height={12}
      rx={3}
      fill="url(#prefix__paint16_linear)"
    />
    <path
      opacity={0.3}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42 35h-4l-1 1v8l1 1h4l1-1v-8l-1-1zm1 1l-1 1v2l1 1-1 1v2l1 1h-6l1-1v-2l-1-1 1-1v-2l-1-1h6zM50 35h-4l-1 1v8l1 1h4l1-1v-8l-1-1zm1 1l-1 1v2l1 1-1 1v2l1 1h-6l1-1v-2l-1-1 1-1v-2l-1-1h6zM58 35h-4l-1 1v8l1 1h4l1-1v-8l-1-1zm1 1l-1 1v2l1 1-1 1v2l1 1h-6l1-1v-2l-1-1 1-1v-2l-1-1h6z"
      fill={styleConst.colors.white}
    />
    <path
      opacity={0.7}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66 35h-4l-1 1v8l1 1h4l1-1v-8l-1-1zm1 1l-1 1v2l1 1-1 1v2l1 1h-6l1-1v-2l-1-1 1-1v-2l-1-1h6zm-1 3.5h-4l-.5.5.5.5h4l.5-.5-.5-.5zm12 0h4l.5.5-.5.5h-4l-.5-.5.5-.5zM74 35h-4l-1 1v8l1 1h4l1-1v-8l-1-1zm1 1l-1 1v2l1 1-1 1v2l1 1h-6l1-1v-2l-1-1 1-1v-2l-1-1h6z"
      fill={styleConst.colors.white}
    />
    <path
      opacity={0.3}
      d="M78 35h4l1 1h-6l1-1zM78 45h4l1-1h-6l1 1z"
      fill={styleConst.colors.white}
    />
    <path
      opacity={0.7}
      d="M82 37v2l1 1v-4l-1 1zM82 41v2l1 1v-4l-1 1zM78 37v2l-1 1v-4l1 1z"
      fill={styleConst.colors.white}
    />
    <path
      opacity={0.3}
      d="M78 41v2l-1 1v-4l1 1z"
      fill={styleConst.colors.white}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46 59a1 1 0 10-2 0v2h-2a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2v-2zM43.586 76.829a1 1 0 00-1.414 1.414l1.414 1.414-1.414 1.414a1 1 0 001.414 1.415l1.415-1.415 1.414 1.415a1 1 0 001.414-1.415l-1.414-1.414 1.414-1.414a1 1 0 10-1.414-1.414L45 78.243l-1.415-1.414z"
      fill="#4ABBBC"
    />
    <path
      d="M72 62a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1zM72 78a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1zM72 82a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
      fill="#4ABBBC"
    />
    <defs>
      <linearGradient
        id="prefix__paint0_linear"
        x1={99}
        y1={-123}
        x2={99}
        y2={349.778}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30716F" />
        <stop offset={1} stopColor="#21AAA4" />
      </linearGradient>
      <linearGradient
        id="prefix__paint1_linear"
        x1={103.478}
        y1={12.501}
        x2={54.271}
        y2={91.523}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#94D8E7" />
        <stop offset={1} stopColor="#21AAA4" />
      </linearGradient>
      <linearGradient
        id="prefix__paint2_linear"
        x1={60.38}
        y1={34.269}
        x2={58.49}
        y2={43.113}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={styleConst.colors.white} />
        <stop offset={1} stopColor="#F0F0F0" />
      </linearGradient>
      <linearGradient
        id="prefix__paint3_linear"
        x1={89}
        y1={-5}
        x2={82.548}
        y2={48.766}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.613} stopColor="#30716F" />
        <stop offset={1} stopColor="#7FD0DB" />
      </linearGradient>
      <linearGradient
        id="prefix__paint4_linear"
        x1={37}
        y1={33}
        x2={60.435}
        y2={45.465}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#777" />
        <stop offset={1} stopColor="#858585" />
      </linearGradient>
      <linearGradient
        id="prefix__paint5_linear"
        x1={44}
        y1={73}
        x2={45.154}
        y2={61.72}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.613} stopColor="#30716F" />
      </linearGradient>
      <linearGradient
        id="prefix__paint6_linear"
        x1={43.5}
        y1={59}
        x2={43.5}
        y2={65}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={styleConst.colors.white} />
        <stop offset={1} stopColor="#A9A9A9" />
      </linearGradient>
      <linearGradient
        id="prefix__paint7_linear"
        x1={44.662}
        y1={57.385}
        x2={40.412}
        y2={65.147}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFEDED" />
        <stop offset={1} stopColor="#F0F0F0" />
      </linearGradient>
      <linearGradient
        id="prefix__paint8_linear"
        x1={44}
        y1={91}
        x2={45.154}
        y2={79.72}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.613} stopColor="#30716F" />
      </linearGradient>
      <linearGradient
        id="prefix__paint9_linear"
        x1={43.5}
        y1={77}
        x2={43.5}
        y2={83}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={styleConst.colors.white} />
        <stop offset={1} stopColor="#A9A9A9" />
      </linearGradient>
      <linearGradient
        id="prefix__paint10_linear"
        x1={44.662}
        y1={75.385}
        x2={40.412}
        y2={83.147}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFEDED" />
        <stop offset={1} stopColor="#F0F0F0" />
      </linearGradient>
      <linearGradient
        id="prefix__paint11_linear"
        x1={75}
        y1={73}
        x2={76.154}
        y2={61.72}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.613} stopColor="#30716F" />
      </linearGradient>
      <linearGradient
        id="prefix__paint12_linear"
        x1={74.5}
        y1={59}
        x2={74.5}
        y2={65}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={styleConst.colors.white} />
        <stop offset={1} stopColor="#A9A9A9" />
      </linearGradient>
      <linearGradient
        id="prefix__paint13_linear"
        x1={75.662}
        y1={57.385}
        x2={71.412}
        y2={65.147}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFEDED" />
        <stop offset={1} stopColor="#F0F0F0" />
      </linearGradient>
      <linearGradient
        id="prefix__paint14_linear"
        x1={75}
        y1={91}
        x2={76.154}
        y2={79.72}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.613} stopColor="#30716F" />
      </linearGradient>
      <linearGradient
        id="prefix__paint15_linear"
        x1={74.5}
        y1={77}
        x2={74.5}
        y2={83}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={styleConst.colors.white} />
        <stop offset={1} stopColor="#A9A9A9" />
      </linearGradient>
      <linearGradient
        id="prefix__paint16_linear"
        x1={75.662}
        y1={75.385}
        x2={71.412}
        y2={83.147}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFEDED" />
        <stop offset={1} stopColor="#F0F0F0" />
      </linearGradient>
    </defs>
  </svg>
);

export default I120Calculator;
