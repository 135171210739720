import React from "react";
import { Instance, SnapshotOrInstance } from "mobx-state-tree";
import { observer } from "mobx-react";

import {
  Answer,
  Fields,
  FieldWithDependentFields,
  DependentField,
} from "models/Questionnaire";

import QuestionType from "../QuestionType";

type GroupItem = Instance<typeof Fields>;
type FieldItem = Instance<typeof FieldWithDependentFields>;
type DependentFieldItem = Instance<typeof DependentField>;
type AnswerItem = SnapshotOrInstance<typeof Answer>;

type Props = {
  group: GroupItem;
  onAnswer: (answer: AnswerItem) => void;
  store: any;
};

const Group = ({ group, store, onAnswer }: Props) => (
  <>
    {group.fields.map((field: FieldItem) => (
      <React.Fragment key={field.name}>
        <QuestionType field={field} onAnswer={onAnswer} store={store} />

        {field.filteredDependedFields.map(
          (dependentField: DependentFieldItem) => (
            <React.Fragment key={dependentField.field.name}>
              <QuestionType
                field={dependentField.field}
                parentFieldName={field.name}
                onAnswer={onAnswer}
                store={store}
              />
            </React.Fragment>
          ),
        )}
      </React.Fragment>
    ))}
  </>
);

export default observer(Group);
