import { applySnapshot, flow, types } from "mobx-state-tree";

import api from "services/api";

import {
  parseISO,
  isSameDay,
  isAfter,
  isBefore,
  endOfDay,
  isEqual,
} from "date-fns";

import { Workout } from "models/Workout";

import { UTCToZonedTime } from "utils/timezones";

const Calendar = types
  .model("Calendar", {
    scheduled: types.array(types.late(() => Workout)),
    completed: types.array(types.late(() => Workout)),
    isLoading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get mappedScheduled() {
      return self.scheduled.map(({ date }) => date ?? "");
    },
    get mappedCompleted() {
      return self.completed.map(({ date }) => date ?? "");
    },
    getCollectionsByDay(day: Date) {
      const scheduledItems = self.scheduled.filter(({ date = "" }) => {
        const zonedDate = UTCToZonedTime(parseISO(date));
        return (
          isSameDay(day, zonedDate) &&
          (isAfter(endOfDay(zonedDate), endOfDay(new Date())) ||
            isEqual(endOfDay(zonedDate), endOfDay(new Date())))
        );
      });
      const completedItems = self.completed.filter(({ date = "" }) => {
        const zonedDate = UTCToZonedTime(parseISO(date));
        return isSameDay(day, zonedDate);
      });
      const missedItems = self.scheduled.filter(({ date = "" }) => {
        const zonedDate = UTCToZonedTime(parseISO(date));
        return (
          isSameDay(day, zonedDate) &&
          isBefore(endOfDay(zonedDate), endOfDay(new Date()))
        );
      });

      const scheduled = { title: "Workout Scheduled", items: scheduledItems };
      const completed = { title: "Workout Completed", items: completedItems };
      const missed = { title: "Workout Missed", items: missedItems };

      return [scheduled, completed, missed].filter(({ items }) => items.length);
    },
  }))
  .actions(self => ({
    fetch: flow(function* ({ startDate, endDate }) {
      try {
        self.isLoading = true;

        const { items: scheduledItems } = yield api.get(
          `/mobile/workout/${startDate}/${endDate}/scheduled/list`,
        );
        const { items: completedItems } = yield api.get(
          `/mobile/workout/${startDate}/${endDate}/done/list`,
        );

        const scheduled = scheduledItems.map((item: any) => ({
          ...item,
          title: item.name,
        }));
        const completed = completedItems.map((item: any) => ({
          ...item,
          title: item.name,
        }));

        applySnapshot(self, { ...self, scheduled, completed });
        return { scheduled, completed };
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    reset() {
      applySnapshot(self, {});
    },
  }));

const CalendarStore = Calendar.create();

export default CalendarStore;
