import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Logout = ({ color = styleConst.colors.primary }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.71 7.78996C17.5191 7.68135 17.2977 7.63864 17.0801 7.66841C16.8624 7.69818 16.6607 7.79877 16.5059 7.95466C16.3512 8.11055 16.2521 8.31308 16.2239 8.53093C16.1957 8.74877 16.24 8.96982 16.35 9.15995L18.14 10.9599H9.05005C8.78483 10.9599 8.53046 11.0653 8.34293 11.2528C8.15539 11.4404 8.05005 11.6947 8.05005 11.9599C8.05005 12.2252 8.15539 12.4795 8.34293 12.6671C8.53046 12.8546 8.78483 12.9599 9.05005 12.9599H18.14L16.35 14.79C16.2454 14.8796 16.1603 14.9899 16.1003 15.114C16.0403 15.2381 16.0066 15.3732 16.0013 15.5109C15.9959 15.6486 16.0191 15.786 16.0694 15.9143C16.1197 16.0426 16.1959 16.1591 16.2934 16.2566C16.3909 16.3541 16.5074 16.4303 16.6357 16.4806C16.764 16.5309 16.9014 16.5541 17.0391 16.5487C17.1768 16.5434 17.3119 16.5097 17.436 16.4497C17.5601 16.3897 17.6704 16.3046 17.76 16.2L20.55 13.41C20.736 13.2242 20.8835 13.0036 20.9842 12.7608C21.0848 12.518 21.1366 12.2578 21.1366 11.9949C21.1366 11.7321 21.0848 11.4719 20.9842 11.2291C20.8835 10.9863 20.736 10.7657 20.55 10.58L17.71 7.78996Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 5C3 4.20435 3.31608 3.4413 3.87869 2.87869C4.4413 2.31608 5.20435 2 6 2H12C12.7956 2 13.5587 2.31608 14.1213 2.87869C14.6839 3.4413 15 4.20435 15 5V6C15 6.26522 14.8947 6.51959 14.7071 6.70712C14.5196 6.89466 14.2652 7 14 7C13.7348 7 13.4804 6.89466 13.2929 6.70712C13.1054 6.51959 13 6.26522 13 6V5C13 4.73478 12.8947 4.48044 12.7071 4.29291C12.5196 4.10537 12.2652 4 12 4H6C5.73478 4 5.48044 4.10537 5.29291 4.29291C5.10537 4.48044 5 4.73478 5 5V19C5 19.2652 5.10537 19.5196 5.29291 19.7071C5.48044 19.8947 5.73478 20 6 20H12C12.2652 20 12.5196 19.8947 12.7071 19.7071C12.8947 19.5196 13 19.2652 13 19V18C13 17.7348 13.1054 17.4804 13.2929 17.2929C13.4804 17.1054 13.7348 17 14 17C14.2652 17 14.5196 17.1054 14.7071 17.2929C14.8947 17.4804 15 17.7348 15 18V19C15 19.7956 14.6839 20.5587 14.1213 21.1213C13.5587 21.6839 12.7956 22 12 22H6C5.20435 22 4.4413 21.6839 3.87869 21.1213C3.31608 20.5587 3 19.7956 3 19V5Z"
      fill={color}
    />
  </svg>
);

export default I24Logout;
