import { applySnapshot, flow, types } from "mobx-state-tree";

import api from "services/api";

import RecipeFavoritesStore from "stores/RecipeFavoritesStore";

import { Recipe } from "models/Recipe";

const RecipeDetails = types
  .model("RecipeDetails", {
    details: types.maybe(Recipe),
    isLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    fetch: flow(function* (id: number) {
      self.isLoading = true;

      try {
        const details = yield api.get(`/mobile/food/recipe/${id}`);

        applySnapshot(self, { ...self, details });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    addToFavorites: flow(function* () {
      try {
        if (self.details) {
          yield RecipeFavoritesStore.addToFavorites(self.details.id);
          self.details.isFavorite = true;
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    }),

    removeFromFavorites: flow(function* () {
      try {
        if (self.details) {
          yield RecipeFavoritesStore.removeFromFavorites(self.details.id);
          self.details.isFavorite = false;
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    }),
  }));

const RecipeDetailsStore = RecipeDetails.create();

export default RecipeDetailsStore;
