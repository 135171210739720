import React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";

import AuthStore from "stores/AuthStore";

import { Badge, PreviewBadgeContainer, Wrapper } from "./styles";

type Props = {
  isPublishStatusPreview?: boolean;
  top?: number;
  left?: number;
  children: React.ReactNode;
};

export const PreviewBadge = ({ text }: { text: string }) => (
  <Badge>{text}</Badge>
);

@observer
class PublishStatus extends React.Component<Props> {
  @computed get isPreviewAccount() {
    return AuthStore.isPreviewAccount;
  }

  render() {
    const { children, isPublishStatusPreview, top, left } = this.props;

    return this.isPreviewAccount && isPublishStatusPreview ? (
      <Wrapper>
        {children}
        <PreviewBadgeContainer top={top} left={left}>
          <PreviewBadge text="Preview" />
        </PreviewBadgeContainer>
      </Wrapper>
    ) : (
      children
    );
  }
}

export default PublishStatus;
