import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { FormSpy } from "react-final-form";
import {
  RouteComponentProps,
  generatePath,
  withRouter,
} from "react-router-dom";

import I36Star from "assets/icons/I36Star";
import I36StarFilled from "assets/icons/I36StarFilled";
import I32Plus from "assets/icons/I32Plus";

import FoodFavoritesStore from "stores/FoodFavoritesStore";
import FoodManualStore from "stores/FoodManualStore";

import { Page, NavListItem } from "components/Layout";
import FormControl, {
  DecimalNumberFieldControl,
  DropdownFieldControl,
} from "components/Form";
import { SnackbarTypeValues } from "components/Snackbar";
import withGlobalPopup, {
  GlobalPopupProps,
} from "components/Popup/withGlobalPopup";
import MealSchedulePopUp from "components/MealSchedulePopup";
import FoodLogControl from "components/FoodLogControl";
import Label from "components/Label";

import {
  SnackbarContextProps,
  withGlobalSnackbar,
} from "services/snackbar/snackbarContext";
import { NoInternetConnectionError, NotFoundError } from "services/api";

import {
  getNutrientLabel,
  getNutrientsList,
  getNutrientValue,
  getMainFields,
  getServingValue,
  getCurrentNutritionalInfo,
} from "utils/nutrients";
import { capitalize } from "utils/common";
import { redirectToRootRoute } from "utils/route";

import ROUTES from "navigation/routes";

import { MEAL_SCHEDULE_TYPE } from "constants/nutrition";
import styleConst from "constants/style";

import {
  Column,
  FavoriteContainer,
  Row,
  FavoriteIconButton,
  NutrientsInfo,
  NutrientWrapper,
  SubmitButton,
  InlineListItem,
  Nutrients,
  TitleContainer,
  SubTitle,
  TitleAdornment,
  SectionTitle,
  SectionTitleContainer,
  Background,
  FoodNutrientsRow,
  LabelContainer,
} from "./styles";

type Params = {
  recipeId: string;
};

type LocationState = { selectedServing: any; numberOfServingsValue: number };

type Props = {} & SnackbarContextProps &
  RouteComponentProps<Params, {}, LocationState> &
  GlobalPopupProps;

@observer
class MyFoodDetails extends React.Component<Props> {
  @observable nutritionalInfo?: any;
  @observable selectedServing?: any;
  @observable numberOfServingsValue?: number;
  @observable isNoInternetConnectionError: boolean = false;

  get recipeId() {
    const {
      match: {
        params: { recipeId },
      },
    } = this.props;

    return Number.parseInt(recipeId);
  }

  get locationState() {
    const {
      location: { state },
    } = this.props;

    return state;
  }

  get url() {
    const {
      match: { url },
    } = this.props;

    return url;
  }

  get isFoodLog() {
    return this.url.includes(ROUTES.NUTRITION_FOOD_LOG);
  }

  get store() {
    return !this.isFoodLog ? FoodFavoritesStore : FoodManualStore;
  }

  get initialValues() {
    if (this.locationState) {
      return {
        serving: this.locationState.selectedServing,
        amount: this.locationState.numberOfServingsValue,
      };
    }

    if (this.details?.servings.length === 1) {
      return {
        serving: this.details.servings[0],
        amount: 1,
      };
    }

    return { amount: 1 };
  }

  get isReadOnlyMode() {
    return !!this.locationState;
  }

  @computed get isLoading() {
    return this.store.isLoading;
  }

  @computed get isUpdating() {
    return FoodFavoritesStore.isUpdating;
  }

  @computed get details() {
    return this.store.details;
  }

  @computed get isFavorite() {
    return !this.isFoodLog
      ? this.details?.isFavorite || Boolean(this.details?.favoriteId)
      : this.details?.isFavorite;
  }

  @computed get servingSizeOptions() {
    return !this.locationState
      ? this.details?.servings.map(value => ({
          label: getServingValue(value),
          value,
        }))
      : [
          {
            label: getServingValue(this.locationState.selectedServing),
            value: this.locationState.selectedServing,
          },
        ];
  }

  @computed get mainNutrients() {
    return this.nutritionalInfo
      ? getNutrientsList(getMainFields(this.nutritionalInfo))
      : undefined;
  }

  @computed get allNutrients() {
    return this.nutritionalInfo
      ? getNutrientsList(this.nutritionalInfo)
      : undefined;
  }

  @computed get submitIsDisabled() {
    return (
      this.selectedServing === undefined ||
      this.numberOfServingsValue === undefined
    );
  }

  @computed get navigationItems() {
    const { history } = this.props;

    if (this.isFoodLog) {
      return [];
    }

    return [
      {
        title: "My Food",
        onClick: () =>
          history.push(generatePath(ROUTES.NUTRITION_MY_ITEMS_MY_FOOD)),
      },
      {
        title: capitalize(this.details?.name),
        onClick: () =>
          history.push(
            generatePath(ROUTES.NUTRITION_MY_ITEMS_MY_FOOD_DETAILS, {
              recipeId: this.recipeId,
            }),
          ),
      },
    ];
  }

  @computed get isArchived() {
    return this.details?.isArchived;
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    this.store.reset();
  }

  init = async () => {
    const { history } = this.props;
    try {
      this.store.reset();

      await this.store.fetchDetails(this.recipeId);
    } catch (error) {
      if (error instanceof NoInternetConnectionError) {
        this.isNoInternetConnectionError = true;
      }
      if (error instanceof NotFoundError) {
        redirectToRootRoute(history, this.url);
      }
    }
  };

  onChangeFormValues = ({ values }: { values: any }) => {
    const { serving, amount } = values;

    if (this.locationState) {
      this.nutritionalInfo = this.locationState.selectedServing.nutritionalInfo;
      return;
    }

    if (serving) {
      this.selectedServing = serving;
    }

    if (amount) {
      this.numberOfServingsValue = amount;
    }

    if (serving && serving.nutritionalInfo && amount) {
      this.nutritionalInfo = getCurrentNutritionalInfo(
        serving.nutritionalInfo,
        amount,
      );
    }
  };

  onFavoriteClick = () => {
    if (this.isFavorite) {
      this.removeFromFavorites(this.details);
    } else {
      this.addToFavorites(this.details);
    }
  };

  addToFavorites = async (item: any) => {
    const { setMessage } = this.props;

    try {
      await FoodFavoritesStore.addToFavorites(item);
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    }
  };

  removeFromFavorites = async (item: any) => {
    const { setMessage } = this.props;

    try {
      await FoodFavoritesStore.removeFromFavorites(item);
    } catch (error: any) {
      setMessage(error?.message, SnackbarTypeValues.ALERT);
    }
  };

  openMealSchedule = (mode: string) => {
    const { openPopup } = this.props;

    openPopup({
      key: "MealSchedulePopup",
      exitButtonColor: styleConst.colors.black25,
      render: ({ close }) => (
        <MealSchedulePopUp
          mode={mode}
          recipe={{
            ...this.details,
            serving: {
              ...this.selectedServing,
              amount: this.numberOfServingsValue,
            },
            isFood: true,
          }}
          onClose={close}
        />
      ),
    });
  };

  onToday = () => {
    this.openMealSchedule(MEAL_SCHEDULE_TYPE.TODAY);
  };

  onSelectDay = () => {
    this.openMealSchedule(MEAL_SCHEDULE_TYPE.SCHEDULE);
  };

  renderTitle = (): any => (
    <TitleContainer>
      {this.isArchived ? (
        <LabelContainer>
          <Label text={"Archived"} />
        </LabelContainer>
      ) : null}
      {this.details?.name}
      <SubTitle> {this.details?.brand}</SubTitle>
    </TitleContainer>
  );

  renderNavigationList = () =>
    this.navigationItems.map(({ title, onClick }, index) => (
      <NavListItem
        key={title}
        title={title}
        onClick={onClick}
        isLastItem={index === this.navigationItems.length - 1}
      />
    ));

  render() {
    return (
      <Page
        isNoInternetConnectionError={this.isNoInternetConnectionError}
        isLoading={this.isLoading}
        title={this.renderTitle()}
        topNavigation={this.renderNavigationList()}
        titleControls={
          !this.isArchived ? (
            <FoodLogControl
              disabled={this.submitIsDisabled || this.isReadOnlyMode}
              onToday={this.onToday}
              onSelectDay={this.onSelectDay}
              element={
                <SubmitButton
                  disabled={this.submitIsDisabled || this.isReadOnlyMode}
                  iconLeft={<I32Plus />}
                  text="Add to Food Log"
                  height={50}
                  fontSize={16}
                  fontWeight={500}
                />
              }
            />
          ) : null
        }
        contentBackground={
          <>
            <Background />
            <Background leftDots />
          </>
        }
      >
        <FormControl initialValues={this.initialValues}>
          <FormSpy
            subscription={{ values: true }}
            onChange={this.onChangeFormValues}
          />

          <Row>
            <Column>
              <DropdownFieldControl
                name="serving"
                options={this.servingSizeOptions}
                label="Serving Size"
                disabled={this.isReadOnlyMode}
                required
                withoutErrorText
              />
            </Column>
            <Column>
              <DecimalNumberFieldControl
                name="amount"
                label="Number of Servings"
                disabled={this.isReadOnlyMode}
                onlyPositiveNumbers
                required
                withoutErrorText
              />
            </Column>
          </Row>

          {this.mainNutrients && (
            <FoodNutrientsRow
              nutrients={this.mainNutrients}
              control={
                !this.isArchived || this.isFavorite ? (
                  <FavoriteContainer>
                    <FavoriteIconButton
                      onClick={this.onFavoriteClick}
                      backgroundColor={
                        this.isFavorite
                          ? styleConst.colors.primary10
                          : undefined
                      }
                      icon={this.isFavorite ? <I36StarFilled /> : <I36Star />}
                      isLoading={this.isUpdating}
                    />
                    {this.isFavorite
                      ? this.isArchived
                        ? "Remove"
                        : "Unfavorite"
                      : "Favorite"}
                  </FavoriteContainer>
                ) : null
              }
            />
          )}

          {this.allNutrients && (
            <Row>
              <Nutrients>
                <SectionTitleContainer>
                  <TitleAdornment />
                  <SectionTitle>Nutrition Facts</SectionTitle>
                </SectionTitleContainer>
                <NutrientsInfo>
                  {this.allNutrients.map(({ label, value }) => (
                    <NutrientWrapper key={`${label}-${value}`}>
                      <InlineListItem
                        label={getNutrientLabel(label)}
                        value={getNutrientValue(label, value)}
                      />
                    </NutrientWrapper>
                  ))}
                </NutrientsInfo>
              </Nutrients>
            </Row>
          )}
        </FormControl>
      </Page>
    );
  }
}

export default withGlobalPopup(withGlobalSnackbar(withRouter(MyFoodDetails)));
