import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import Tippy from "@tippyjs/react";

import OutsideClickHandler from "components/OutsideClickHandler";
import Menu from "components/Menu";

import { Wrapper } from "./styles";

type Props = {
  element: React.ReactElement<any>;
  itemId?: string;
  menuItems: any[];
  onOpenCallback?: () => void;
  onCloseCallback?: () => void;
};

@observer
class MenuControl extends React.Component<Props> {
  @observable isMenuOpened: boolean = false;

  get isEditMode() {
    const { itemId } = this.props;

    return itemId;
  }

  get offset(): [number, number] {
    return !this.isEditMode ? [0, 20] : [0, 0];
  }

  openMenu = () => {
    const { onOpenCallback } = this.props;

    this.isMenuOpened = true;
    onOpenCallback?.();
  };

  hideMenu = () => {
    const { onCloseCallback } = this.props;

    this.isMenuOpened = false;
    onCloseCallback?.();
  };

  render() {
    const { element, menuItems } = this.props;

    return (
      <OutsideClickHandler onClick={this.hideMenu}>
        <Wrapper>
          <Tippy
            appendTo={document.body}
            animation="fade"
            arrow={false}
            maxWidth={"none"}
            placement="bottom-start"
            theme={"menu"}
            visible={this.isMenuOpened}
            content={<Menu items={menuItems} />}
            offset={this.offset}
            interactive
          >
            <Wrapper onClick={this.openMenu}>{element}</Wrapper>
          </Tippy>
        </Wrapper>
      </OutsideClickHandler>
    );
  }
}

export default MenuControl;
