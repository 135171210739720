import { WEEK } from "constants/common";

export const formatDuration = (count: number) => {
  const min = Math.trunc(count / 60);
  const sec = count % 60;

  const minutes = min ? `${min} min` : ``;
  const seconds = sec ? `${sec} sec` : ``;

  return [minutes, seconds].filter(Boolean).join(` `);
};

export const formatDurationOnlyNumbers = (count: number) => {
  const allMins = Math.trunc(count / 60);
  const sec = count % 60;

  if (allMins < 60) {
    const minutes = allMins ? `${allMins}` : `00`;
    const seconds = sec ? `${sec}` : `00`;

    return [minutes, seconds]
      .filter(Boolean)
      .map(item => (item.length === 2 ? item : `0${item}`))
      .join(`:`);
  } else {
    const min = allMins % 60;
    const hours = `${(allMins - min) / 60}`;

    const minutes = min ? `${min}` : `00`;
    const seconds = sec ? `${sec}` : `00`;

    return [hours, minutes, seconds]
      .filter(Boolean)
      .map(item => (item.length === 2 ? item : `0${item}`))
      .join(":");
  }
};

export const isNumber = (value: any) =>
  typeof value === "number" && isFinite(value);

export const getWeekDay = (count: number) => {
  return WEEK[`${count}`];
};

export const capitalize = (text: string = "") =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const round = (value: any, decimal: number = 0) => {
  if (typeof value === "number") {
    return Math.round(value * 10 ** decimal) / 10 ** decimal;
  } else {
    return value ? Math.round(Number.parseFloat(value)) : value;
  }
};

export const uniqArray = (list: any[], param?: string) =>
  list.filter(
    (item, index, array) =>
      array.findIndex(_item =>
        param ? item[param] === _item[param] : _item === item,
      ) === index,
  );

export const convertSearchString = (search: string) =>
  search.replace(/[‘’]/g, "'");

export const isObject = (value: any) =>
  value != null && value.constructor.name === "Object";

export const getLocationOrigin = () => {
  return window.location.origin;
};

export const generateAppSectionLink = (internalPath: string) => {
  return `${getLocationOrigin()}/#${internalPath}`;
};
