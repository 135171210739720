import { types } from "mobx-state-tree";

import { PERMISSION_TYPE, PUBLISH_STATUS } from "constants/common";

import { ImageSize } from "models";

export const Workout = types
  .model("Workout", {
    id: types.maybe(types.number),
    workoutInWorkoutCategoryId: types.maybe(types.number),
    date: types.maybe(types.string),
    label: types.maybe(types.string),
    title: types.maybe(types.string),
    duration: types.maybe(types.number),
    imageUrl: types.maybe(types.string),
    publishStatus: types.optional(
      types.enumeration(Object.values(PUBLISH_STATUS)),
      PUBLISH_STATUS.PREVIEW,
    ),
    permission: types.optional(
      types.enumeration(Object.values(PERMISSION_TYPE)),
      PERMISSION_TYPE.FULL_ACCESS,
    ),
    images: types.array(ImageSize),
  })
  .views(self => ({
    get isPermissionPreview() {
      return self.permission === PERMISSION_TYPE.PREVIEW;
    },
    get isPublishStatusPreview() {
      return self.publishStatus === PUBLISH_STATUS.PREVIEW;
    },
  }));
