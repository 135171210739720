import styled from "styled-components";

import Button from "components/Button";

import styleConst from "constants/style";

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  width: 180px;

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export const SubmitButton = styled(Button)`
  background: radial-gradient(
      83.3% 179.17% at 56.29% -31.25%,
      ${styleConst.colors.primaryMedium} 0%,
      ${styleConst.colors.primary} 100%
    ),
    ${styleConst.colors.primary};

  box-shadow: 0px 20px 40px -10px rgba(97, 198, 194, 0.25);
  border-radius: 10px;
  border: none;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  padding-left: 101px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 280px;

  &:not(:last-child) {
    margin-right: 22px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 28px;

  max-width: 580px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: calc(100vh - 100px - 345px);
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
`;

export const Title = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 48px;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.dark};

  border-left: 4px solid ${styleConst.colors.primary};

  padding-left: 14px;
  margin-bottom: 20px;
`;
