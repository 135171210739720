import React from "react";

import {
  Container,
  ContentWrapper,
  FixedHeader,
  HeaderContent,
} from "./styles";

type Props = {
  leftContent?: React.ReactNode;
  centerContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  isOverlay?: boolean;
  disabled?: boolean;
};

class Header extends React.Component<Props> {
  render() {
    const { isOverlay, leftContent, centerContent, rightContent, disabled } =
      this.props;

    return (
      <Container isOverlay={isOverlay} disabled={disabled}>
        <FixedHeader>
          <HeaderContent>
            <ContentWrapper>{leftContent}</ContentWrapper>
            <ContentWrapper>{centerContent}</ContentWrapper>
            <ContentWrapper>{rightContent}</ContentWrapper>
          </HeaderContent>
        </FixedHeader>
      </Container>
    );
  }
}

export default Header;
