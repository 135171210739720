import styled, { css } from "styled-components";

export const Container = styled.div``;

export const Wrapper = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;

  ${({ lines }: { lines?: number }) =>
    !!lines &&
    css`
      @supports (-webkit-line-clamp: ${lines}) {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: ${lines};
        -webkit-box-orient: vertical;
      }
    `}
`;
