import React from "react";

import Spinner from "components/Spinner";

import styleConst from "constants/style";

import {
  Container,
  IconLeft,
  IconRight,
  Label,
  LabelContainer,
  LoaderWrapper,
} from "./styles";

type Props = {
  borderColor?: string;
  className?: any;
  color?: string;
  disabled?: boolean;
  iconLeft?: React.ReactElement | null;
  iconRight?: React.ReactElement | null;
  inverted?: boolean;
  loading?: boolean;
  onClick?: (event: any) => void;
  text?: string;
  textColor?: string;
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: string | number;
  height?: number;
  width?: number;
};

const Button = ({
  borderColor,
  className,
  color = styleConst.colors.primary,
  disabled,
  iconLeft,
  iconRight,
  inverted,
  loading,
  onClick: onButtonClick,
  text,
  textColor = styleConst.colors.white,
  fontFamily,
  fontSize = 16,
  fontWeight = "normal",
  height = 56,
  width,
}: Props) => {
  const onPress = (event: any) =>
    !disabled && !loading ? onButtonClick?.(event) : undefined;

  return (
    <Container
      className={className}
      borderColor={borderColor}
      color={inverted ? textColor : color}
      disabled={disabled}
      height={height}
      width={width}
      onClick={onPress}
    >
      <LabelContainer>
        {loading ? (
          <LoaderWrapper withoutLeftIcon={!iconLeft}>
            <Spinner color={inverted ? color : textColor} />
          </LoaderWrapper>
        ) : null}

        {iconLeft ? <IconLeft loading={loading}>{iconLeft}</IconLeft> : null}

        {text ? (
          <Label
            fontSize={fontSize}
            fontFamily={fontFamily}
            fontWeight={fontWeight}
            textColor={inverted ? color : textColor}
            isIconRight={!!iconRight}
            isIconLeft={!!iconLeft}
          >
            {text}
          </Label>
        ) : null}

        {iconRight ? <IconRight>{iconRight}</IconRight> : null}
      </LabelContainer>
    </Container>
  );
};

export default Button;
