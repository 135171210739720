import styled, { css } from "styled-components";

import { CARD_WIDTH, MEDIA_QUERIES } from "constants/layout";
import styleConst from "constants/style";

interface IImage {
  imageUrl: string;
}

export const Icon = styled.div`
  display: none;

  position: absolute;

  top: 85px;
  right: 170px;

  z-index: 4;

  ${MEDIA_QUERIES.DESKTOP} {
    right: 218px;
  }
`;

export const Info = styled.div`
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;

  z-index: 3;
`;

export const Image = styled.div<IImage>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 210px;

  z-index: 1;

  border-radius: 10px;
  background-color: ${styleConst.colors.black4};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `}

  width: ${CARD_WIDTH.SMALL_DESKTOP};

  ${MEDIA_QUERIES.DESKTOP} {
    width: ${CARD_WIDTH.DESKTOP};
  }
`;

export const Container = styled.div`
  position: relative;

  padding: 4px 0 20px;

  height: 230px;

  margin: 0 10px;

  width: ${CARD_WIDTH.SMALL_DESKTOP};

  ${MEDIA_QUERIES.DESKTOP} {
    margin: 0 12.5px;

    height: 235px;
    width: ${CARD_WIDTH.DESKTOP};
  }
`;

export const Content = styled.div`
  position: relative;

  height: 210px;

  cursor: pointer;

  &:hover {
    top: -4px;

    ${Image} {
      top: 0;

      box-shadow: -1px 20px 30px -11px #3e576540;
    }

    ${Icon} {
      display: block;
    }
  }

  width: ${CARD_WIDTH.SMALL_DESKTOP};

  ${MEDIA_QUERIES.DESKTOP} {
    width: ${CARD_WIDTH.DESKTOP};
  }
`;

export const Subtitle = styled.p`
  font-size: 14px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.white70};
`;

export const Title = styled.p`
  font-size: 16px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.white};
`;
