import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { ThemeProvider } from "@material-ui/core/styles";

import Router from "navigation";

import GlobalPopup from "services/popup";
import GlobalSnackbarContext from "services/snackbar/snackbarContext";

import Snackbar, {
  SnackbarType,
  SnackbarTypeValues,
} from "components/Snackbar/Snackbar";

import project from "../../package.json";
import { environmentName } from "utils/env";

import { DEFAULT_THEME } from "constants/muiTheme";

import { Version } from "./styles";

@observer
export default class App extends React.Component {
  @observable snackbarMessage: string = "";
  @observable snackbarType: SnackbarType = SnackbarTypeValues.INFO;

  setMessage = (
    message: string,
    type: SnackbarType = SnackbarTypeValues.INFO,
  ) => {
    this.snackbarMessage = message;
    this.snackbarType = type;
  };

  onSnackbarClose = () => {
    this.snackbarMessage = "";
    this.snackbarType = SnackbarTypeValues.INFO;
  };

  render(): React.ReactNode {
    return (
      <>
        <ThemeProvider theme={DEFAULT_THEME}>
          <GlobalSnackbarContext.Provider
            value={{ setMessage: this.setMessage }}
          >
            <Router />
            <GlobalPopup />
            <Version>{project.version + "\n" + environmentName}</Version>
          </GlobalSnackbarContext.Provider>
          <Snackbar
            message={this.snackbarMessage}
            onClose={this.onSnackbarClose}
            type={this.snackbarType}
          />
        </ThemeProvider>
      </>
    );
  }
}
