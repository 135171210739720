import React from "react";

import I24CheckArrow from "assets/icons/I24CheckArrow";
import I24CrossFilled from "assets/icons/I24CrossFilled";
import I24RestaurantFilled from "assets/icons/I24RestaurantFilled";

import AddFoodControl from "pages/Nutrition/AddFoodControl";

import styleConst from "constants/style";

import { Action, Actions, ActionTooltip } from "../styles";

type Props = {
  id: string;
  activeDay: string;
  foodMealTypeId: number;
  withoutServings?: boolean;
  onRemove: (id: string) => void;
  onChange: (id: string) => void;
  onServings: (id: string) => void;
  onOpenCallback?: () => void;
  onCloseCallback?: () => void;
};

const Common = (props: Props) => {
  const {
    id,
    onChange,
    onRemove,
    onServings,
    onOpenCallback,
    onCloseCallback,
    withoutServings,
    activeDay,
    foodMealTypeId,
  } = props;
  const onPress = (action: (id: string) => void) => () => {
    action(id);
  };

  return (
    <Actions>
      {!withoutServings ? (
        <Action onClick={onPress(onServings)}>
          <I24RestaurantFilled color={styleConst.colors.black25} />
          <ActionTooltip>{"Servings"}</ActionTooltip>
        </Action>
      ) : null}
      <AddFoodControl
        activeDay={activeDay}
        foodMealTypeId={foodMealTypeId}
        onOpenCallback={onOpenCallback}
        onCloseCallback={onCloseCallback}
        element={
          <Action onClick={onPress(onChange)}>
            <I24CheckArrow color={styleConst.colors.black25} />
            <ActionTooltip>{"Change"}</ActionTooltip>
          </Action>
        }
        itemId={id}
      />
      <Action onClick={onPress(onRemove)}>
        <I24CrossFilled color={styleConst.colors.black25} />
        <ActionTooltip>{"Remove"}</ActionTooltip>
      </Action>
    </Actions>
  );
};

export default Common;
