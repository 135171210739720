import React from "react";
import { observer } from "mobx-react";
import { observable, computed } from "mobx";
import {
  generatePath,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";

import I24Check from "assets/icons/I24Check";

import AuthStore from "stores/AuthStore";
import ProfileStore from "stores/ProfileStore";

import Input from "components/Input";
import Checkbox from "components/Checkbox";
import Button from "components/Button";

import { email, password } from "utils/env";
import { isEmpty, isEmail, composeValidators } from "utils/validation";

import ROUTES from "navigation/routes";

import styleConst from "constants/style";

import {
  Background,
  Container,
  LogoContainer,
  Logo,
  Title,
  ItemWrapper,
  Link,
  errorTextStyles,
} from "./styles";

type Props = RouteComponentProps & {};

@observer
class SignIn extends React.Component<Props> {
  @observable email: string = email;
  @observable password: string = password;
  @observable error = {
    email: "",
    password: "",
  };

  @observable isExistingUser: boolean = false;

  @computed get isError() {
    return Object.values(this.error).some(Boolean);
  }

  onEmailChange = (text: string) => {
    this.email = text;
    this.error.email = "";
    this.error.password = "";
    this.isExistingUser = false;
  };

  onPasswordChange = (text: string) => {
    this.password = text.trim();
    this.error.password = "";
  };

  checkEmail = () => {
    this.error.email = composeValidators(isEmpty, isEmail)(this.email);
  };

  checkForm = () => {
    this.error = {
      email: "",
      password: "",
    };

    this.checkEmail();
    this.error.password = composeValidators(isEmpty)(this.password);
  };

  onContinue = async () => {
    this.checkEmail();

    if (!this.isError) {
      try {
        this.isExistingUser = await AuthStore.validateEmail(this.email);

        if (!this.isExistingUser) {
          await AuthStore.login({ email: this.email });
          this.props.history.push(generatePath(ROUTES.ONBOARDING));
        }

        this.password = password;
      } catch (error: any) {
        console.log("error :>> ", error);
        this.error.email = error?.message;
      }
    }
  };

  onLogin = async () => {
    this.checkForm();

    if (!this.isError) {
      try {
        await AuthStore.login({
          email: this.email,
          password: this.password,
        });

        if (
          AuthStore.isNeedOnboarding ||
          AuthStore.isNeedOnboarding === null ||
          AuthStore.isNeedChangePassword
        ) {
          this.props.history.push(generatePath(ROUTES.ONBOARDING));
          return;
        }

        ProfileStore.fetch();

        this.props.history.push(generatePath(ROUTES.TODAY));
      } catch (error: any) {
        console.log("error :>> ", error);
        this.error.password = error?.message;
      }
    }
  };

  onForgotPassword = async () => {
    AuthStore.setEmail(this.email);
    this.props.history.push(generatePath(ROUTES.FORGOT_PASSWORD));
  };

  toggleIsNeedRemember = (value: boolean) => {
    AuthStore.setIsNeedRememberTokenData(value);
  };

  render() {
    return (
        <Background>
          <Container>
            <LogoContainer>
              <Logo alt="Logo" />
            </LogoContainer>
            <Title>{"Login"}</Title>
            <ItemWrapper>
              <Input
                value={this.email}
                onChangeText={this.onEmailChange}
                placeholder="Email"
                type="email"
                icon={
                  this.isExistingUser ? (
                    <I24Check color={styleConst.colors.primary} />
                  ) : null
                }
                error={this.error.email}
                errorTextStyles={errorTextStyles}
              />
            </ItemWrapper>
            {this.isExistingUser ? (
              <>
                <ItemWrapper>
                  <Input
                    label="Password"
                    value={this.password}
                    onChangeText={this.onPasswordChange}
                    type="password"
                    error={this.error.password}
                    errorTextStyles={errorTextStyles}
                    toggleableSecureText
                  />
                </ItemWrapper>
                <ItemWrapper>
                  <Checkbox
                    label="Remember me"
                    checked={AuthStore.isNeedRememberTokenData}
                    toggleChecked={this.toggleIsNeedRemember}
                  />
                </ItemWrapper>
              </>
            ) : null}
            <ItemWrapper>
              <Button
                loading={AuthStore.isValidating || AuthStore.isLoading}
                fontWeight="bold"
                text={this.isExistingUser ? "Enter" : "Continue"}
                onClick={this.isExistingUser ? this.onLogin : this.onContinue}
              />
            </ItemWrapper>
            {this.isExistingUser ? (
              <ItemWrapper>
                <Link onClick={this.onForgotPassword}>
                  {"Forgot password?"}
                </Link>
              </ItemWrapper>
            ) : null}
          </Container>
        </Background>
    );
  }
}

export default withRouter(SignIn);
