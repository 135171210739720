import { LINKS } from "./links";

export const LEGAL_ITEMS = [
  {
    text: "Terms and Conditions",
    onClick: () => window.open(LINKS.TERMS_AND_CONDITIONS),
  },
  {
    text: "Privacy Policy",
    onClick: () => window.open(LINKS.PRIVACY_POLICY),
  },
  {
    text: "SMS Policy",
    onClick: () => window.open(LINKS.SMS_POLICY),
  },
];
