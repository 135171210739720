import styled from "styled-components";

import styleConst from "constants/style";

export const Container = styled.div`
  display: flex;

  width: 100%;

  margin-top: 10px;

  background-color: ${styleConst.colors.white};
`;

export const Header = styled.div`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: ${styleConst.fonts.roboto};
  color: ${styleConst.colors.text};
`;
