import styled from "styled-components";

import styleConst from "constants/style";
import { PAGE_CONTENT_WIDTH } from "constants/layout";

export const Container = styled.div`
  display: flex;
  justify-content: center;

  &:not(:last-child) {
    border-bottom: 1px solid ${styleConst.colors.black10};
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${PAGE_CONTENT_WIDTH.SMALL_DESKTOP};
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
`;

export const InputWrapper = styled.div`
  margin-right: 32px;

  width: 82px;
  height: 50px;
`;

export const RecipeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 9px 0 11px 0;

  width: 100%;
  min-height: 70px;
`;
