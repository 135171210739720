import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24Exit = ({ color = styleConst.colors.black }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM8.04936 8.05025C8.43988 7.65973 9.07305 7.65973 9.46357 8.05025L11.9991 10.5858L14.5346 8.05025C14.9252 7.65973 15.5583 7.65973 15.9489 8.05025C16.3394 8.44078 16.3394 9.07394 15.9489 9.46447L13.4133 12L15.9489 14.5355C16.3394 14.9261 16.3394 15.5592 15.9489 15.9497C15.5583 16.3403 14.9252 16.3403 14.5346 15.9497L11.9991 13.4142L9.46357 15.9497C9.07305 16.3403 8.43988 16.3403 8.04936 15.9497C7.65883 15.5592 7.65883 14.9261 8.04936 14.5355L10.5849 12L8.04936 9.46447C7.65883 9.07394 7.65883 8.44078 8.04936 8.05025Z"
      fill={color}
    />
  </svg>
);

export default I24Exit;
