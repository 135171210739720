import React from "react";

import styleConst from "constants/style";

const WaterEmpty = () => (
  <svg width={97} height={121} viewBox="0 0 97 121" fill="none">
    <path
      d="M44.898 5.222h0l-.009.009c-.322.313-.79.77-1.383 1.358a270.475 270.475 0 00-4.897 4.99c-4.04 4.22-9.44 10.108-14.85 16.765-5.398 6.64-10.886 14.14-15.046 21.574C4.62 57.235 1.5 65.03 1.5 72.125c0 26.127 21.005 47.376 47 47.376s47-21.249 47-47.376c0-7.095-3.12-14.89-7.213-22.207-4.16-7.434-9.648-14.933-15.045-21.574-5.411-6.657-10.81-12.546-14.85-16.766a270.8 270.8 0 00-4.898-4.989l-.015-.015-3.938-3.795a1.5 1.5 0 00-2.076-.005l-2.567 2.448z"
      stroke={styleConst.colors.primaryAdditional3}
      strokeWidth={3}
      strokeLinejoin="round"
    />
    <mask id="prefix__a" x={6} y={8} width={97} height={121}>
      <path
        d="M91 72.124C91 95.804 71.972 115 48.5 115S6 95.804 6 72.124C6 48.444 48.5 8 48.5 8S91 48.444 91 72.124z"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        d="M-104.738 6.054C-154.217-43.496-234.23-28.628-257-19.999v278.262h636.295c0-22.147-20.417-203.348-61.033-244.025C267.491-36.607 172.877 1.871 87.319 46.055c-85.557 44.183-159.42-7.316-192.057-40z"
        fill={styleConst.colors.primaryAdditional3}
      />
      <path
        opacity={0.5}
        d="M-22.22 73.065C-62.366 59.775-52.845 52.106-62 56.005V155h264.601c6.803-28.821-12.557-80.617-28.886-98.995-20.412-22.973-58.929-39.584-97.29-10.44-38.36 29.143-58.496 40.791-98.644 27.5z"
        fill="#DCF5F4"
      />
      <circle opacity={0.5} cx={23.5} cy={71.5} r={2.5} fill="#DCF5F4" />
      <circle opacity={0.5} cx={62.5} cy={83.5} r={2.5} fill="#DCF5F4" />
      <circle opacity={0.5} cx={52.5} cy={66.5} r={1.5} fill="#DCF5F4" />
      <circle opacity={0.5} cx={55.5} cy={100.5} r={3.5} fill="#DCF5F4" />
    </g>
  </svg>
);

export default WaterEmpty;
