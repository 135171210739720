import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import TrimmedText from "components/TrimmedText";

import styleConst from "constants/style";

import { round } from "utils/common";

import {
  Container,
  Label,
  Value,
  TitleContainer,
  ProgressbarContainer,
} from "./styles";

type Props = {
  current: number;
  dimension?: string;
  label: string;
  goal: number;
  withoutOverconsumption?: boolean;
  greyStyle?: boolean;
};

class CircularProgress extends React.Component<Props> {
  get withoutOverconsumption() {
    const { withoutOverconsumption } = this.props;
    return withoutOverconsumption;
  }

  get overconsumption() {
    const { current, goal } = this.props;
    return current > goal;
  }

  get current() {
    const { current, goal } = this.props;
    return this.overconsumption ? current - goal : current;
  }

  get color() {
    return this.overconsumption && !this.withoutOverconsumption
      ? styleConst.colors.red
      : styleConst.colors.text;
  }

  get tintColor() {
    const { greyStyle } = this.props;

    const overconsumption =
      this.overconsumption && !this.withoutOverconsumption;

    return greyStyle && overconsumption
      ? styleConst.colors.primary
      : !greyStyle && !overconsumption
      ? styleConst.colors.white20
      : styleConst.colors.white;
  }

  get pathColor() {
    const { greyStyle } = this.props;

    return this.overconsumption && !this.withoutOverconsumption
      ? styleConst.colors.red
      : greyStyle
      ? styleConst.colors.primary
      : styleConst.colors.white;
  }

  get textColor() {
    const { greyStyle } = this.props;

    return this.overconsumption && !this.withoutOverconsumption
      ? styleConst.colors.red
      : greyStyle
      ? styleConst.colors.dark
      : styleConst.colors.white;
  }

  get backgroundColor() {
    return this.overconsumption
      ? styleConst.colors.primary
      : styleConst.colors.black4;
  }

  render() {
    const { current, dimension = "G", label, goal, greyStyle } = this.props;

    return (
      <Container>
        <ProgressbarContainer>
          <CircularProgressbar
            value={(this.current / goal) * 100}
            text={`${current} ${dimension.toLocaleLowerCase()}`}
            styles={buildStyles({
              rotation: 0,
              strokeLinecap: "round",
              pathTransitionDuration: 0.5,
              pathColor: this.pathColor,
              textColor: this.textColor,
              trailColor: this.tintColor,
              backgroundColor: this.backgroundColor,
            })}
          />
        </ProgressbarContainer>
        <TitleContainer>
          <Label
            color={greyStyle ? styleConst.colors.dark : styleConst.colors.white}
          >
            {label}
          </Label>
          <Value
            color={greyStyle ? styleConst.colors.dark : styleConst.colors.white}
          >
            <TrimmedText
              lines={1}
              text={`${round(goal)} ${dimension.toLocaleLowerCase()}`}
            >
              {`${round(goal)} ${dimension.toLocaleLowerCase()}`}
            </TrimmedText>
          </Value>
        </TitleContainer>
      </Container>
    );
  }
}
export default CircularProgress;
