import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I36Camera = ({ color = styleConst.colors.white }: Props) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 19.5C24 22.8137 21.3137 25.5 18 25.5C14.6863 25.5 12 22.8137 12 19.5C12 16.1863 14.6863 13.5 18 13.5C21.3137 13.5 24 16.1863 24 19.5ZM21 19.5C21 21.1569 19.6569 22.5 18 22.5C16.3431 22.5 15 21.1569 15 19.5C15 17.8431 16.3431 16.5 18 16.5C19.6569 16.5 21 17.8431 21 19.5Z"
      fill={color}
      fillOpacity="0.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.172 4.50002C13.8666 4.49988 13.5997 4.49976 13.342 4.52927C12.0105 4.68178 10.8162 5.41988 10.0843 6.54263C9.94271 6.75989 9.82346 6.99866 9.687 7.2719L8.42705 9.79183C8.21004 10.2259 7.76642 10.5 7.28116 10.5C4.91674 10.5 3 12.4168 3 14.7812V22.3599C2.99997 23.8004 2.99995 24.9891 3.09323 25.9508C3.19033 26.9521 3.39952 27.8644 3.91966 28.6922C4.39922 29.4555 5.04459 30.1008 5.80781 30.5804C6.63561 31.1005 7.54795 31.3097 8.54924 31.4068C9.51098 31.5001 10.6996 31.5001 12.1401 31.5H23.8599C25.3004 31.5001 26.489 31.5001 27.4508 31.4068C28.452 31.3097 29.3644 31.1005 30.1922 30.5804C30.9554 30.1008 31.6008 29.4555 32.0803 28.6922C32.6005 27.8644 32.8097 26.9521 32.9068 25.9508C33.0001 24.9891 33 23.8004 33 22.36V14.7812C33 12.4168 31.0833 10.5 28.7188 10.5C28.2336 10.5 27.79 10.2259 27.5729 9.79183L26.313 7.27188C26.1765 6.99867 26.0573 6.75987 25.9157 6.54263C25.1838 5.41988 23.9895 4.68178 22.658 4.52927C22.4003 4.49976 22.1334 4.49988 21.828 4.50002H14.172ZM13.6834 7.50978C13.7482 7.50237 13.8309 7.50004 14.2361 7.50004H21.7639C22.1691 7.50004 22.2518 7.50237 22.3166 7.50978C22.7604 7.56062 23.1585 7.80665 23.4025 8.1809C23.4381 8.23551 23.4772 8.30846 23.6584 8.67086L24.8897 11.1335C25.6149 12.5839 27.0973 13.5 28.7188 13.5C29.4264 13.5 30 14.0736 30 14.7812V22.2858C30 23.819 29.9984 24.8614 29.9208 25.6612C29.8455 26.4379 29.7098 26.8262 29.5402 27.0961C29.3004 27.4777 28.9777 27.8004 28.5961 28.0402C28.3262 28.2098 27.9379 28.3455 27.1612 28.4208C26.3614 28.4984 25.319 28.5 23.7857 28.5H12.2143C10.6811 28.5 9.63862 28.4984 8.83883 28.4208C8.06213 28.3455 7.67382 28.2098 7.40391 28.0402C7.02229 27.8004 6.69961 27.4777 6.45983 27.0961C6.29023 26.8262 6.15454 26.4379 6.07922 25.6612C6.00165 24.8614 6 23.819 6 22.2858V14.7812C6 14.0736 6.57359 13.5 7.28116 13.5C8.90274 13.5 10.3851 12.5839 11.1103 11.1335L12.3416 8.67086C12.5228 8.30846 12.5619 8.23551 12.5975 8.1809C12.8415 7.80665 13.2396 7.56062 13.6834 7.50978Z"
      fill={color}
      fillOpacity="0.25"
    />
  </svg>
);

export default I36Camera;
