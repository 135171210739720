import styled, { css } from "styled-components";
import DefaultVimeo from "@u-wave/react-vimeo";

import styleConst from "constants/style";

interface IImage {
  imageUrl?: string;
}

export const Background = styled.div`
  position: absolute;
  z-index: 1;

  height: 436px;
  width: 780px;

  background-color: ${styleConst.colors.white};
`;

export const Container = styled.div`
  position: relative;
  height: 436px;
  width: 780px;
`;

export const Control = styled.div`
  position: absolute;

  top: 168px;
  right: 340px;
  z-index: 3;
`;

export const Duration = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 18px;
  right: 20px;

  border-radius: 10px;
  background-color: ${styleConst.colors.white20};
`;

export const DurationText = styled.p`
  padding: 5px 10px;

  font-family: ${styleConst.fonts.roboto};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: ${styleConst.colors.white};
`;

export const Image = styled.div<IImage>`
  position: absolute;
  z-index: 2;

  height: 436px;
  width: 780px;

  background-size: cover;
  border-radius: 10px;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `}
`;

export const Vimeo = styled(DefaultVimeo)`
  iframe {
    height: 436px;
    width: 780px;
  }
`;
