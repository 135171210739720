export const HEADER_HEIGHT = "100px";
export const FOOTER_HEIGHT = "345px";

export const BREAKPOINTS = { DESKTOP: 1920, SMALL_DESKTOP: 1200, MOBILE: 540 };

export const MEDIA_QUERIES = {
  DESKTOP: `@media screen and (min-width: ${BREAKPOINTS.DESKTOP}px)`,
  MEDIUM_DESKTOP: `@media screen and (max-width: ${BREAKPOINTS.DESKTOP - 1}px)`,
  SMALL_DESKTOP: `@media screen and (max-width: ${
    BREAKPOINTS.SMALL_DESKTOP - 1
  }px)`,
  MOBILE: `@media screen and (max-width: ${BREAKPOINTS.MOBILE}px)`,
};

export const MOBILE_DEVICE_BREAKPOINTS = {
  WIDTH: BREAKPOINTS.MOBILE,
  HEIGHT: 959,
};

export const PAGE_WIDTH = { DESKTOP: "1480px", SMALL_DESKTOP: "1200px" };
export const PAGE_CONTENT_WIDTH = {
  DESKTOP: "1480px",
  SMALL_DESKTOP: "1178px",
};

export const CARD_WIDTH = { DESKTOP: "476px", SMALL_DESKTOP: "380px" };

export const CONTAINER_SCROLLABLE_DIV_ID = "containerScrollableDivId";

export const SCROLLBAR_WIDTH = "20px";
export const PADDING_WIDTH = "118px";
export const TWO_COLUMN_WIDTH = "800px";

export const BIG_POPUP_MEDIA_QUERIES = {
  THREE_COLUMN_WITHOUT_PADDING: `@media screen and (max-width: calc(${BREAKPOINTS.SMALL_DESKTOP}px + ${SCROLLBAR_WIDTH} + ${PADDING_WIDTH}))`,
  TWO_COLUMN_WITH_PADDING: `@media screen and (max-width: calc(${
    BREAKPOINTS.SMALL_DESKTOP - 1
  }px + ${SCROLLBAR_WIDTH}))`,
  TWO_COLUMN_WITHOUT_PADDING: `@media screen and (max-width: calc(${TWO_COLUMN_WIDTH} -
    1px + ${SCROLLBAR_WIDTH} + ${PADDING_WIDTH}))`,
  DESKTOP_PADDING: `@media screen and (min-width: calc(${
    BREAKPOINTS.SMALL_DESKTOP + 1
  }px + ${SCROLLBAR_WIDTH} + ${PADDING_WIDTH}))`,
};
