import styled, { css } from "styled-components";

import styleConst from "constants/style";

interface ICircle {
  scheduled?: boolean;
  completed?: boolean;
  streak?: boolean;
}

export const Circle = styled.div<ICircle>`
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  border-radius: 100px;

  margin-top: 4px;
  margin-bottom: 12px;

  overflow: hidden;

  ${({ scheduled }) =>
    scheduled &&
    css`
      background-color: ${styleConst.colors.primary20};
    `}

  ${({ completed }) =>
    completed &&
    css`
      background-color: ${styleConst.colors.primary};
    `}

  ${({ streak }) =>
    streak &&
    css`
      width: 48px;
      background-color: ${styleConst.colors.primary};
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 480px;
  height: 190px;

  background-color: ${styleConst.colors.dark5};
  border-radius: 10px;
  padding: 24px 24px;
`;

export const Count = styled.div`
  color: ${styleConst.colors.black};
  font-size: 26px;
  font-weight: 600;
  font-family: ${styleConst.fonts.montserrat};
  color: ${styleConst.colors.dark};
  line-height: 48px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 70px;

  margin: 0 35px;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 400;
  font-family: ${styleConst.fonts.roboto};
  line-height: 18px;
  text-align: center;
  color: ${styleConst.colors.dark};
`;
