import styled, { css } from "styled-components";

import topBackgroundImage from "assets/images/svg/promote_popup_background.svg";

import styleConst from "constants/style";

interface IContent {
  top?: boolean;
  bottom?: boolean;
}

interface IIConWrapper {
  contentIcon?: boolean;
}

export const ButtonWrapper = styled.div`
  width: 306px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 570px;
  height: 408px;

  border-radius: 10px;
`;

export const Content = styled.div<IContent>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 204px;

  ${({ top }) =>
    top &&
    css`
      overflow: hidden;
      background-image: url(${topBackgroundImage});
      background-repeat: no-repeat;
      background-position: 50% 50%;
    `}

  ${({ bottom }) =>
    bottom &&
    css`
      padding: 43px 66px 40px;
    `}
`;

export const IconWrapper = styled.div<IIConWrapper>`
  margin-right: 20px;

  ${({ contentIcon }) =>
    contentIcon &&
    css`
      display: flex;

      margin-right: 6px;
    `}
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 34px;
`;

export const Possibilities = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 45px;

  width: 100%;
`;

export const Possibility = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-radius: 10px;

  background-color: ${styleConst.colors.white};
`;

export const PossibilityTitle = styled.p`
  font-family: ${styleConst.fonts.sfPro};
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`;

export const TextLogo = styled.div`
  margin: 20px 0 14px;
`;

export const Text = styled.div`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 18px;
  font-weight: 500;

  color: ${styleConst.colors.white};
`;
