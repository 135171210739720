import styled from "styled-components";

import Button from "components/Button";

import styleConst from "constants/style";

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-right: 50px;
  padding-bottom: 54px;
`;

export const ButtonWrapper = styled.div`
  width: 180px;

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 0 50px;

  max-height: calc(100vh - 280px);
  overflow-y: auto;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  width: 280px;
`;

export const SubmitButton = styled(Button)`
  background: radial-gradient(
      83.3% 179.17% at 56.29% -31.25%,
      ${styleConst.colors.primaryMedium} 0%,
      ${styleConst.colors.primary} 100%
    ),
    ${styleConst.colors.primary};

  box-shadow: 0px 20px 40px -10px rgba(97, 198, 194, 0.25);
  border-radius: 10px;
  border: none;
`;
