import { flow, types, applySnapshot } from "mobx-state-tree";

import api from "services/api";

import FoodManualStore from "./FoodManualStore";

import { Context } from "models/index";
import { Ingredient } from "models/Recipe";

import { MY_FOOD_LIST_PAGE_SIZE } from "constants/nutrition";

const FoodFavorites = types
  .model("FoodFavorites", {
    context: types.maybe(Context),
    items: types.array(Ingredient),
    details: types.maybe(Ingredient),
    isLoading: types.optional(types.boolean, false),
    isUpdating: types.optional(types.boolean, false),
  })
  .views(self => ({
    get needMore() {
      if (self.context) {
        return self.context.page < self.context.pages;
      }
    },
  }))
  .actions(self => ({
    updateDetails(details: any) {
      applySnapshot(self, { ...self, details });
    },
  }))
  .actions(self => ({
    fetch: flow(function* (page: number = 1) {
      try {
        if (page === 1) {
          self.isLoading = true;
        }

        const { items, context } = yield api.get(
          "/mobile/food/external/favorite/list",
          {
            page,
            size: MY_FOOD_LIST_PAGE_SIZE,
          },
        );

        applySnapshot(self, {
          ...self,
          context,
          items: page === 1 ? items : self.items.concat(items),
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    fetchDetails: flow(function* (id: number) {
      self.isLoading = true;

      try {
        const details = yield api.get(`/mobile/food/external/${id}`);
        self.updateDetails(details);
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),

    addToFavorites: flow(function* (item: any) {
      self.isUpdating = true;

      try {
        const details = yield api.post(`/mobile/v2/food/external/favorite`, {
          id: item.id,
        });

        FoodManualStore.updateItem(item.id, {
          isFavorite: true,
          favoriteId: details.favoriteId,
        });

        self.updateDetails(details);

        return details;
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isUpdating = false;
      }
    }),

    removeFromFavorites: flow(function* (item: any) {
      self.isUpdating = true;

      try {
        const id = typeof item === "number" ? item : item.favoriteId;

        yield api.delete(`/mobile/food/external/${id}/favorite`);

        FoodManualStore.updateItem(item.id, { isFavorite: false });

        if (self.details && self.details.favoriteId === id) {
          self.details.favoriteId = undefined;
        }
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isUpdating = false;
      }
    }),

    reset() {
      applySnapshot(self, { ...self, details: undefined });
    },
  }));

const FoodFavoritesStore = FoodFavorites.create();

export default FoodFavoritesStore;
