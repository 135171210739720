import React from "react";

import styleConst from "constants/style";

type Props = {
  color?: string;
};

const I24EyeClose = ({ color = styleConst.colors.white }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1405 4.70696C21.5311 5.09748 21.5311 5.73065 21.1405 6.12117L7.70552 19.5562C7.315 19.9467 6.68183 19.9467 6.29131 19.5562C5.90078 19.1657 5.90078 18.5325 6.29131 18.142L7.13724 17.296C6.39748 16.8057 5.73394 16.261 5.16317 15.7353C4.3041 14.9441 3.61195 14.156 3.13464 13.5665C2.89537 13.271 2.70849 13.0234 2.58003 12.8476C2.51576 12.7596 2.466 12.6895 2.4315 12.6402C2.4315 12.6402 2.40685 12.6048 2.39126 12.5821C2.38681 12.5755 2.37988 12.5654 2.37988 12.5654L2.3764 12.5602L2.37522 12.5585L2.37441 12.5573L2 11.9998L2.37477 11.4417L2.3764 11.4393L2.37988 11.4341L2.39126 11.4175C2.4008 11.4035 2.41425 11.384 2.4315 11.3594C2.466 11.31 2.51576 11.2399 2.58003 11.1519C2.70849 10.9761 2.89537 10.7285 3.13464 10.433C3.61195 9.84348 4.3041 9.05538 5.16317 8.26419C6.84558 6.7147 9.33408 4.99976 12.2046 4.99976C14.2224 4.99976 16.0515 5.84718 17.5444 6.88885L19.7263 4.70696C20.1169 4.31643 20.75 4.31643 21.1405 4.70696ZM16.1042 8.32912C14.9064 7.54642 13.5743 6.99976 12.2046 6.99976C10.1045 6.99976 8.09302 8.28481 6.51807 9.73532C5.74846 10.4441 5.12259 11.156 4.68904 11.6915C4.59896 11.8028 4.51746 11.9061 4.44497 11.9998C4.51746 12.0934 4.59896 12.1967 4.68904 12.308C5.12259 12.8435 5.74846 13.5554 6.51807 14.2642C7.1453 14.8419 7.84178 15.3933 8.58552 15.8478L9.49224 14.9411C8.69962 14.2102 8.20312 13.1629 8.20312 11.9998C8.20312 9.79062 9.99399 7.99976 12.2031 7.99976C13.3663 7.99976 14.4135 8.49625 15.1444 9.28887L16.1042 8.32912ZM13.7279 10.7054C13.361 10.2737 12.8141 9.99976 12.2031 9.99976C11.0986 9.99976 10.2031 10.8952 10.2031 11.9998C10.2031 12.6107 10.4771 13.1576 10.9088 13.5245L13.7279 10.7054Z"
      fill={color}
    />
    <path
      d="M20.5877 9.62714L19.1712 11.0437C19.3782 11.2773 19.5619 11.4961 19.7201 11.6915C19.8102 11.8028 19.8917 11.9061 19.9642 11.9998C19.8917 12.0934 19.8102 12.1967 19.7201 12.308C19.2866 12.8435 18.6607 13.5554 17.8911 14.2642C16.6014 15.452 15.019 16.5288 13.3349 16.8799L11.2745 18.9404C11.5796 18.9791 11.8898 18.9998 12.2046 18.9998C15.0751 18.9998 17.5636 17.2848 19.246 15.7353C20.1051 14.9441 20.7972 14.156 21.2745 13.5665C21.5138 13.271 21.7007 13.0234 21.8291 12.8476C21.8934 12.7596 21.9432 12.6895 21.9777 12.6402C21.9949 12.6155 22.0084 12.596 22.0179 12.5821L22.0293 12.5654L22.0328 12.5602L22.4092 11.9998L22.0328 11.4393L22.0293 11.4341L22.0179 11.4175C22.0084 11.4035 21.9949 11.384 21.9777 11.3594C21.9432 11.31 21.8934 11.2399 21.8291 11.1519C21.7007 10.9761 21.5138 10.7285 21.2745 10.433C21.0801 10.1929 20.8501 9.9199 20.5877 9.62714Z"
      fill={color}
    />
  </svg>
);

export default I24EyeClose;
