import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";

import I24Chevron2 from "assets/icons/I24Chevron2";

import styleConst from "constants/style";

import { Container, Header, IconContainer, Title, Content } from "./styles";

type Props = {
  title: string;
  children: React.ReactNode;
  isVisible?: boolean;
  withToggle?: boolean;
  className?: any;
};

@observer
class Accordion extends React.Component<Props> {
  @observable isVisible: boolean = this.props.isVisible ?? true;

  get withToggle() {
    return this.props.withToggle ?? true;
  }

  toggle = () => {
    if (this.withToggle) {
      this.isVisible = !this.isVisible;
    }
  };

  render() {
    const { className, children, title } = this.props;

    return (
      <Container className={className}>
        <Header onClick={this.toggle}>
          <Title>{title}</Title>
          {this.withToggle ? (
            <IconContainer isOpened={this.isVisible}>
              <I24Chevron2 color={styleConst.colors.black} />
            </IconContainer>
          ) : null}
        </Header>

        {this.isVisible ? <Content>{children}</Content> : null}
      </Container>
    );
  }
}

export default Accordion;
