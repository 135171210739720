import styled from "styled-components";

import styleConst from "constants/style";

interface IContent {
  top?: boolean;
  bottom?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 420px;
  height: 468px;

  overflow-y: hidden;

  border-radius: 10px;
`;

export const Content = styled.div<IContent>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  height: 100%;

  padding: 0 60px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 53px 0 10px 0;
`;

export const Title = styled.div`
  font-family: ${styleConst.fonts.montserrat};
  font-size: 26px;
  font-weight: 600;

  color: ${styleConst.colors.dark};
  padding-left: 12px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  padding: 10px 71px 72px 71px;
`;
