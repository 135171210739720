import { applySnapshot, flow, types } from "mobx-state-tree";

import api from "services/api";

import ResourcesContentStore from "stores/ResourcesContentStore";

import { Context } from "models";
import { Resource } from "models/Resource";

import {
  CATEGORY_CONTENT_VIEW_TYPE,
  CATEGORY_LIST_PAGE_SIZE,
} from "constants/resources";

const Resources = types
  .model("Resources", {
    context: types.maybe(Context),
    items: types.array(types.late(() => ResourcesContentStore)),
    categoryDetails: types.maybe(types.late(() => ResourcesContentStore)),
    resourceDetails: types.maybe(Resource),
    isLoading: types.optional(types.boolean, false),
    isMoreLoading: types.optional(types.boolean, false),
    isDetailsLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    fetch: flow(function* (
      page: number = 1,
      viewType: string = CATEGORY_CONTENT_VIEW_TYPE.CATEGORY_LIST,
    ) {
      try {
        if (page === 1) {
          self.isLoading = true;
        } else {
          self.isMoreLoading = true;
        }

        const { context, items } = yield api.get(
          "/mobile/quick-resource/category/list",
          {
            size: CATEGORY_LIST_PAGE_SIZE,
            page,
          },
        );

        const mappedItems = items.map((item: any) =>
          ResourcesContentStore.create({
            categoryId: item.id,
            title: item.title,
            viewType: viewType,
          }),
        );

        applySnapshot(self, {
          ...self,
          context,
          items: page === 1 ? mappedItems : self.items.concat(mappedItems),
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        if (page === 1) {
          self.isLoading = false;
        } else {
          self.isMoreLoading = false;
        }
      }
    }),

    fetchDetails: flow(function* ({
      categoryId,
      viewType,
    }: {
      categoryId: number;
      viewType: string;
    }) {
      self.isDetailsLoading = true;

      try {
        const { id, title } = yield api.get(
          `/mobile/quick-resource/category/${categoryId}`,
        );

        const categoryDetails = ResourcesContentStore.create({
          categoryId: id,
          title: title,
          viewType,
        });

        applySnapshot(self, { ...self, categoryDetails });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isDetailsLoading = false;
      }
    }),

    fetchResourceDetails: flow(function* (quickLinkId: number) {
      self.isDetailsLoading = true;

      try {
        const resourceDetails = yield api.get(
          `/mobile/quick-resource/${quickLinkId}`,
        );

        applySnapshot(self, { ...self, resourceDetails });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isDetailsLoading = false;
      }
    }),

    reset() {
      applySnapshot(self, {});
    },

    resetResourceDetails() {
      applySnapshot(self, { ...self, resourceDetails: undefined });
    },
  }));

const ResourcesStore = Resources.create();

export default ResourcesStore;
