import { applySnapshot, flow, types } from "mobx-state-tree";

import api from "services/api";

import { Context } from "models";

import {
  CATEGORY_DETAILS_CONTENT_PAGE_SIZE,
  CATEGORY_LIST_CONTENT_PAGE_SIZE,
  CATEGORY_CONTENT_VIEW_TYPE,
} from "constants/resources";

import { Resource } from "models/Resource";

const defaultParams = { excludeDescription: true };

const ResourcesContent = types
  .model("ResourcesContent", {
    categoryId: types.maybe(types.number),
    title: types.maybe(types.string),
    viewType: types.optional(
      types.enumeration(Object.values(CATEGORY_CONTENT_VIEW_TYPE)),
      CATEGORY_CONTENT_VIEW_TYPE.CATEGORY_DETAILS,
    ),
    items: types.array(Resource),
    context: types.maybe(Context),
    isLoading: types.optional(types.boolean, false),
    isMoreLoading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get totalRows() {
      return self.context?.totalRows ?? 0;
    },
    get totalPages() {
      return self.context?.pages ?? 0;
    },
    get page() {
      return self.context?.page ?? 0;
    },
    get needMore() {
      if (self.context) {
        return self.context.page < self.context.pages;
      }
    },

    get pageSize() {
      switch (self.viewType) {
        case CATEGORY_CONTENT_VIEW_TYPE.CATEGORY_DETAILS:
          return { size: CATEGORY_DETAILS_CONTENT_PAGE_SIZE };
        case CATEGORY_CONTENT_VIEW_TYPE.CATEGORY_LIST:
          return { size: CATEGORY_LIST_CONTENT_PAGE_SIZE };
        default:
          return {
            size: CATEGORY_DETAILS_CONTENT_PAGE_SIZE,
          };
      }
    },
  }))
  .views(self => ({
    get fetchUrl() {
      return `/mobile/quick-resource/category/${self.categoryId}/quick-resource/list`;
    },
    get fetchParams() {
      return {};
    },

    getFetchParams(page: number) {
      return { page, ...self.pageSize, ...defaultParams };
    },
  }))

  .actions(self => ({
    fetch: flow(function* (page: number = 1) {
      try {
        if (page === 1) {
          self.isLoading = true;
        } else {
          self.isMoreLoading = true;
        }

        const params = self.getFetchParams(page);

        const { items, context } = yield api.get(self.fetchUrl, params);

        applySnapshot(self, {
          ...self,
          context,
          items: page === 1 ? items : self.items.concat(items),
        });
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        if (page === 1) {
          self.isLoading = false;
        } else {
          self.isMoreLoading = false;
        }
      }
    }),
    setPage(page: number) {
      if (self.context) {
        self.context.page = page;
      }
    },
    resetPage() {
      if (self.context) {
        self.context.page = 1;
      }
    },
    reset() {
      applySnapshot(self, {});
    },
  }))
  .actions(self => ({
    afterCreate() {
      if (self.viewType === CATEGORY_CONTENT_VIEW_TYPE.RESOURCE_DETAILS) {
        return;
      }

      self.fetch();
    },
  }));

export default ResourcesContent;
